import React from "react";
import { compose} from "recompose";
import { withErrorHandling } from "~/hoc";
import { RichText, Image, Text } from "~/patches/components";
import { fieldHasValue } from "~/utilities";
import styles from "./AlignedTextWithImage.module.scss";
import  BrightcoveVideoPlayer from "../../components/BrightcoveVideoPlayer";


const _options = {
	classModifier: '',
	srcSet: "",
	sizes: ""
};

const AlignedTextWithImage = ({fields, options, sitecoreContext: { pageEditing }}) => {

	setOptions(options);
	const ooyalaHasValue = fields?.ooyalaContentId ? fields?.ooyalaContentId?.fields?.contentEmbeddedCode?.value : false;
	const ooyalaValue = ooyalaHasValue ? fields?.ooyalaContentId?.fields?.contentEmbeddedCode?.value : "";

	const ref = `ref:${ooyalaValue}`; 

	return (
		<>
			{(fields) && (
				<div className={`${styles.AlignedTextWithImage} ${styles[_options.classModifier]}`}>
					{(fieldHasValue(fields, "image") && !ooyalaHasValue || pageEditing && !ooyalaHasValue) &&
						<div className={styles.imageContainer}>
							<Image
								field={fields.image}
								srcSet={_options.srcSet}
								sizes={_options.sizes} />
						</div>
					}
					{ooyalaHasValue && (
						<div className={styles.videoContainer}>
							<BrightcoveVideoPlayer videoEmbedCode={ref} className={styles.video}></BrightcoveVideoPlayer>
						</div>
					)}
					<div className={styles.textContainer}>
						{(fieldHasValue(fields, "headline") || pageEditing) && (
							<h2 className={styles.headline}><Text field={fields.headline} /></h2>
						)}
						{(fieldHasValue(fields, "text") || pageEditing) && (
							<div><RichText field={fields.text} /></div>
						)}
					</div>
				</div>
			)}
		</>
	);
};

const setOptions = options => {
	_options.classModifier = options && options.classModifier ? options.classModifier : '';
	_options.srcSet = options && options.srcSet ? options.srcSet : '';
	_options.sizes = options && options.sizes ? options.sizes : '';
};

export default compose(
	withErrorHandling(),
)(AlignedTextWithImage);