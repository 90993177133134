exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".CompetitionsGrid-module__CompetitionsGrid--2Ypao {\n  -ms-flex-align: center;\n      align-items: center;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex: 0 0 auto;\n      flex: 0 0 auto;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n  -ms-flex-pack: center;\n      justify-content: center;\n  margin: 0 auto 5rem;\n  max-width: 1440px;\n  padding: 0 2.5rem; }\n", ""]);

// exports
exports.locals = {
	"CompetitionsGrid": "CompetitionsGrid-module__CompetitionsGrid--2Ypao"
};