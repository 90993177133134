import React from "react";
import { withErrorHandling, withSectionHeader } from "~/hoc";
import { compose } from "recompose";
import SharedMatchScheduleCarousel from "~/shared-components/MatchScheduleCarousel";

const MatchScheduleCarousel = ({fields, rendering: { upcomingMatches, matchResults } }) => (
	<>
		{fields && <SharedMatchScheduleCarousel {...{upcomingMatches, matchResults}} />}
	</>
);

export default compose(
	// other hocs goes here
	withSectionHeader(),
	withErrorHandling()
)(MatchScheduleCarousel);