exports = module.exports = require("../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ReactErrorHandler-module__reactErrorHandler--3YoQl {\n  background-color: #fffdcb;\n  padding: 20px; }\n  .ReactErrorHandler-module__reactErrorHandler--3YoQl h3 {\n    color: red;\n    font-family: Verdana;\n    font-size: 18pt;\n    font-weight: normal;\n    margin: 0 0 10px 0; }\n  .ReactErrorHandler-module__reactErrorHandler--3YoQl .ReactErrorHandler-module__message--21SOS {\n    color: maroon;\n    font-family: Verdana;\n    font-size: 14pt;\n    font-style: italic;\n    font-weight: normal; }\n", ""]);

// exports
exports.locals = {
	"reactErrorHandler": "ReactErrorHandler-module__reactErrorHandler--3YoQl",
	"message": "ReactErrorHandler-module__message--21SOS"
};