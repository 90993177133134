import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./Link.module.scss";
import { Text } from "~/patches/components";
import { compose } from "recompose";
import { withErrorHandling } from "~/hoc";

const Link = ({item, level, index, menuLevel, setState, children}) => {

	if (item.subMenuItems !== null && item.subMenuItems && item.subMenuItems.length > 0) {
		return (
			<button className={styles.goToNext} onClick={() => setState({
				goToMenu: `level-${level + 1}-${index}`,
				prevMenu: menuLevel,
				goNext: true,
				goBack: false
			})}>
				<Text field={item.menuTitleMobile}/>
				{children}
			</button>
		);
	}

	return (
		//eslint-disable-next-line
		<NavLink to={item.url} onClick={() => document.body.classList.remove("overflowHidden")}>
			<Text field={item.menuTitleMobile}/>
			{children}
		</NavLink>
	);
};
const LinkRedesign = ({item, level, index, menuLevel, setState, children}) => {

	if (item.subMenuItems !== null && item.subMenuItems && item.subMenuItems.length > 0) {
		return (
			<button className={styles.goToNextRedesign} onClick={() => setState({
				goToMenu: `level-${level + 1}-${index}`,
				prevMenu: menuLevel,
				goNext: true,
				goBack: false
			})}>
				<Text field={item.menuTitleMobile}/>
				{children}
			</button>
		);
	}

	return (
		//eslint-disable-next-line
		<NavLink className={styles.linkNoChildren} to={item.url} onClick={() => document.body.classList.remove("overflowHidden")}>
			<Text field={item.menuTitleMobile} />
			{children}
		</NavLink>
	);
};

const RenderLink = props => {
	return ( props.redesign ? <LinkRedesign {...props} /> : <Link {...props} />);
};

export default compose(
	withErrorHandling()
)(RenderLink);