import React, { Component } from "react";
import styles from "./DropdownFilter.module.scss";
import { isRedesign } from "~/utilities/helpers";

//TODO:  this is a generic version of a filter, consider swapping in for the component-specific Match and Table filter
class DropdownFilter extends Component {
	constructor(props) {
		super(props);
	
		this.state = {
			active: false
		};
	}

	render() {

		const { title, selectedFilter, filters, setFilter, defaultText} = this.props;
		const { active } = this.state;

		return (
			<div className={active ? styles.DropdownFilterActive : styles.DropdownFilter}>
				<span>{title}</span>
				<div className={styles.dropdownButton} onClick={() => this.setState({ active: !active })}>
					{selectedFilter ? selectedFilter : defaultText}
				</div>

				<div className={styles.filterSelect}>
					<ul className={`${isRedesign ? styles.itemContainerRedesign : styles.itemContainer}`}>
						<li className={`${isRedesign ? styles.itemRedesign : styles.item}`} onClick={() => { 
							setFilter(null); 
							this.setState({ active: false }); 
						}}>
							{defaultText}
						</li>
						{filters && filters.map((filter, index) =>
							<li className={`${isRedesign ? styles.itemRedesign : styles.item}`} key={index} onClick={() => { 
								setFilter(filter); 
								this.setState({ active: false });
							}}>
								{filter}
							</li>
						)}
					</ul>
				</div>
			</div>
		);
	}
}

export default DropdownFilter;
