import React from "react";
import { Text, Image } from "~/patches/components";
import { Carousel } from "~/shared-components";
import { withSectionHeader } from "~/hoc";

import styles from "./Trophies.module.scss";

const Trophies = ({ fields }) => (
	<div className={styles.Trophies}>
		{fields && fields.trophies && (
			<Carousel>
				{fields.trophies.map((trophyItem, index) => (
					<div key={`trophies-${index}`} className={styles.trophyItem}>
						<Image field={trophyItem.fields.image} imageParams={ {mh: 200} } />
						<span><Text field={trophyItem.fields.year} /></span>
						<span><Text field={trophyItem.fields.name} /></span>
					</div>
				))}
			</Carousel>
		)}
	</div>
);

export default withSectionHeader()(Trophies);