exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SocialLinks-module__SocialLinks--1COrY {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n  -ms-flex-pack: justify;\n      justify-content: space-between; }\n  .SocialLinks-module__SocialLinks--1COrY > a {\n    display: inline-block;\n    margin: 0 26px 8px 0;\n    text-align: center;\n    width: 1.8rem; }\n    .SocialLinks-module__SocialLinks--1COrY > a svg * {\n      fill: #BFBFBF; }\n  .SocialLinks-module__SocialLinks--1COrY a:hover {\n    cursor: pointer; }\n\n.SocialLinks-module__alignLeft--2y64T {\n  -ms-flex-pack: start;\n      justify-content: flex-start; }\n\n.SocialLinks-module__SocialLinksLight--2dJbm { }\n  .SocialLinks-module__SocialLinksLight--2dJbm > a svg * {\n    fill: #FFFFFF; }\n\n@media (min-width: 1025px) {\n  .SocialLinks-module__SocialLinks--1COrY {\n    -ms-flex-pack: start;\n        justify-content: flex-start; }\n    .SocialLinks-module__SocialLinks--1COrY > a {\n      margin: 0 1.41rem 1.5rem 0; } }\n\n.SocialLinks-module__iconImage--3UWRs {\n  width: 1.5rem;\n  height: 1.5rem; }\n\n.SocialLinks-module__discord--2g3PH {\n  padding-top: 1px;\n  width: 1.8rem; }\n", ""]);

// exports
exports.locals = {
	"SocialLinks": "SocialLinks-module__SocialLinks--1COrY",
	"alignLeft": "SocialLinks-module__alignLeft--2y64T",
	"SocialLinksLight": "SocialLinks-module__SocialLinksLight--2dJbm SocialLinks-module__SocialLinks--1COrY",
	"iconImage": "SocialLinks-module__iconImage--3UWRs",
	"discord": "SocialLinks-module__discord--2g3PH"
};