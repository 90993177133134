import React from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import styles from "./ProfileSidebarNav.module.scss";
import MultiLevelMenu from "~/shared-components/MultiLevelMenu";

const SidebarNav = ({ rendering: { sidebarNav, currentPageId } }) => (
	<div className={styles.container}>
		<MultiLevelMenu {...{...sidebarNav, currentPageId}} renderLogout={true} />

		<div className={styles.adContainer}>
			<div id="div-gpt-ad-sidebar" className={styles.ad} />
		</div>
	</div>
);

export default compose(
	// other hocs goes here
	withErrorHandling()
)(SidebarNav);