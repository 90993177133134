import React, { Component } from "react";
import Button from "../../../shared-components/Button"
import styles from "./RedesignCallToActionMenu.module.scss";
import { withGigya } from "~/hoc";

class RedesignCallToActionMenu extends Component {
	constructor(props) {
		super(props);
		const { gigya: { isLoggedIn }} = this.props;

		this.state = {
			isUserLoggedIn: isLoggedIn
		};
	}

	componentDidUpdate(prevProps) {
		const { gigya: prevGigya } = prevProps;
		const { gigya: { isLoggedIn } } = this.props;

		if (prevGigya.isLoggedIn !== isLoggedIn) {
			this.setState({
				isUserLoggedIn: isLoggedIn
			});
		}
	}

	render() {
		const {
			isPrimary,
			member,
			benefits,
			tickets
		} = this.props;
		const { isUserLoggedIn } = this.state;
		const signupLink = (isUserLoggedIn ? benefits : member);

		return (
			<div className={styles.callToActionMenu}>
				{signupLink &&
					<Button reactRouterLink
						link={signupLink.value.href}
						size={isPrimary ? 'largeCondensed' : 'smallCondensed'}
						text={signupLink.value.text}
						variant={isPrimary ? 'secondary' : 'secondaryReversed'}
						/>
				}
				{tickets &&
					<Button 
						link={tickets.value.href}
						size={isPrimary ? 'largeCondensed' : 'smallCondensed'}
						text={tickets.value.text}
						variant={isPrimary ? 'secondary' : 'secondaryReversed'}
						/>
				}
			</div>
		);
	}
}

export default withGigya()(RedesignCallToActionMenu);