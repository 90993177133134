import React from "react";
import { experienceEditorImages } from "~/utilities/constants";
import styles from "./HelperCommentEmptyPlaceholder.module.scss";

const HelperCommentEmptyPlaceholder = ({ comment }) => (
	<div className={styles.container}>
		<p>Add a component by clicking &quot;add a new component&quot; <img src={experienceEditorImages.addComponent} alt="Add component icon" title="Add component" /> then click add here on the corresponding component and select the desired component to insert into it. {comment}</p>
	</div>
);

export default HelperCommentEmptyPlaceholder;