import React, { useEffect, useState } from "react";
import MembershipCard from "../../MembershipCard";
import { withErrorHandling, withGigya } from "~/hoc";
import { compose } from "recompose";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { MembershipService } from "~/services/membershipService";
import styles from "./SmartMembershipCard.module.scss";

const SmartMembershipCard = ({gigya, t, sitecoreContext: { pageEditing }}) => {
	
	const [membershipData, setmembershipData] = useState(undefined);
	
	useEffect(() => {
		
		if (gigya.data && !membershipData) {

			const upgradeTier = !gigya.isLoggedIn && !gigya.data.membershipTier ? -1 : gigya.data.membershipTier;

			MembershipService.getMembershipBannerUpgrade(upgradeTier).then((membershipBanner) =>
			{
				if (!membershipBanner) {
					return;
				}

				setmembershipData({ 
					membershipData: {
						fields: {
							description: { value: `${membershipBanner.description}`},
							learnMoreCta: { value: {
								href: `${membershipBanner.learnMoreCta}`}
							},
							membershipLevel: {
								fields: {
									price: { value: `${membershipBanner.membershipLevel?.price}` }
								}
							},
							name: { value: `${membershipBanner.name}`},
							signupCta: { value: {
								href: `${membershipBanner.learnMoreCta}` }
							},
							text: `${membershipBanner.name}`,
							title: { value: `${membershipBanner.title}`}
						}
					}
				});
			});
		}
		if (pageEditing) {
			setmembershipData({ 
				membershipData: {
					fields: {
						description: { value: `Membership description`},
						learnMoreCta: { value: {
							href: `#`}
						},
						membershipLevel: {
							fields: {
								price: { value: `Level Price` }
							}
						},
						signupCta: { value: { href: `#`}},
						name: { value: `Membership Card Name`},
						text: { value: `Membership Name`},
						title: { value: `Membership Title`}
					}
				}
			});
		}
	});

	return (
		<>
		{(gigya.data || pageEditing) && membershipData ?
			<div className={styles.cardWrapper}>
				<MembershipCard fields={membershipData} gigya={gigya} t={t} />
			</div>
			: ""
		}
		</>
	);
};

export default compose(
	withErrorHandling(),
	withSitecoreContext(),
	withGigya()
)(SmartMembershipCard);