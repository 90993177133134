import React from "react";
import { Text, Link } from "~/patches/components";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import styles from "./RedesignAngleImageAlternate.module.scss";

const blankBG = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

const RedesignAngleImage = ({ fields, params }) => (
	<>
		{fields && (
			<Link className={styles.link} field={fields.link}>
				<img  className={styles.image} style={{backgroundImage: `url(${fields.image.value.src})`}} src={blankBG} alt={fields?.image?.value?.alt ? fields.image.value.alt : "US Soccer Image"} />
				<div className={styles.angle}>
					<Text tag="div" className={styles.heading} field={fields.heading} />
				</div>
			</Link>
		)}
	</>
);

export default compose(
	// other hocs goes here
	withErrorHandling()
)(RedesignAngleImage);