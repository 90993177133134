import React, { Component } from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import moment from "moment";
import P1Event from "~/shared-components/P1Event";
import JsonLd from "~/components/JsonLd";
import bgMaskMobile from "~/assets/svg/p1-bg-mask-extended.svg";
import bgMaskHome from "~/assets/img/p1-event-mask-home-desktop.png";
import styles from "./P1Competition.module.scss";
import { optaApi } from "~/utilities/constants";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import MatchInfo from "~/shared-components/MatchInfo";

class P1Competition extends Component {
	constructor(props) {
		super(props);

		const { rendering: { matchData } } = this.props;

		const goals = matchData?.goals?.length ? matchData?.goals?.length : 0;

		this.state = {
			totalGoals: goals
		};
	}

	totalGoals = goals => {
		this.setState({
			totalGoals: goals
		});
	}

	render() {
		const { rendering: { competitionData, competitionStatus, matchData }, sitecoreContext: { pageEditing }} = this.props;
		const { totalGoals } = this.state;

		if (!competitionData) return <></>;

		const { startDate, endDate, sitecoreData } = competitionData;

		const today = moment();
		const start = moment(startDate);
		const end = moment(endDate);

		const { matchStatus: { completed, upcoming, live } } = optaApi;

		const status = today.isBefore(start, "day") ? upcoming : today.isAfter(end, "day") ? completed : live;

		// since competition data is different from match data, we package it up so we can use the match buttons as competition buttons
		if (competitionStatus === optaApi.matchStatus.live && matchData) competitionData.startDate = matchData.dateTime;
		const matchButtonData = competitionData && { ...competitionData, status, previewLink: sitecoreData?.previewLink?.value, fullRecapLink: sitecoreData?.fullRecapLink?.value };


		const customCalendarEvent = competitionData && {
			title: competitionData.competitionName || "",
			description: competitionData.sitecoreData?.description?.value || "",
			location: competitionData.sitecoreData?.location?.value || "",
			startTime: startDate,
			endTime: endDate
		};

		const dataObject = {
			"@context": "http://schema.org",
			"@type": "SportsEvent",
			"name": customCalendarEvent.title,
			"description": customCalendarEvent.description,
			"startDate": customCalendarEvent.startTime,
			"endDate": customCalendarEvent.endTime,
			"location": {
				"@type": "Place",
				"name": competitionData.competitionName,
				"address": customCalendarEvent.location !== "" ? customCalendarEvent.location : "TBD"
			}
		};

		return (
			<div className={styles.P1Competition}>
				{competitionData &&
				<P1Event
					event={matchButtonData}
					type="competition"
					customCalendarEvent={customCalendarEvent}
					completed={(competitionStatus === 0)}
					goalTotal={totalGoals}>
					{matchData && (
						<MatchInfo match={matchData} competitionLogo={sitecoreData?.competitionLogo} completed={(competitionStatus === 0)} pageEditing={pageEditing} type="P1Competition" getTotalGoals={this.totalGoals} />
					)}
					{!matchData &&
						<div className={styles.competitionInfo}>
							{sitecoreData?.competitionLogo?.value?.src &&
								<img className={styles.logo} src={sitecoreData?.competitionLogo?.value?.src} alt={`${competitionData.competitionName} logo`} />
							}
							{!sitecoreData?.competitionLogo?.value?.src &&
								<h1>{competitionData.competitionName}</h1>
							}
							<span className={sitecoreData?.competitionLogo?.value?.src ? styles.sponsorWithLogo : styles.sponsor}>{competitionData.sitecoreData.sponsorshipText?.value}</span>
							<div className={styles.competitionTime}>
								{(competitionStatus === 0) /* eslint-disable-next-line */
									? `${moment.utc(competitionData.startDate).format('MMM DD - ')} ${moment.utc(competitionData.startDate).format('MMM DD YYYY')}`/* eslint-disable-next-line */
									: `${moment.utc(competitionData.startDate).format('MMM DD YYYY - h:mm')} ${moment.utc(competitionData.startDate).zoneAbbr()}`
								}
							</div>
							<img
								className={`${styles.bgMask} ${styles.desktop}`}
								src={bgMaskHome}
								alt="" />
							<img
								className={`${styles.bgMask} ${styles.mobile}`}
								src={bgMaskMobile}
								alt="" />
						</div>
					}
				</P1Event>
				}

				<JsonLd data={dataObject} />
			</div>
		);
	}
}

export default compose(
	// other hocs goes here
	withSitecoreContext(),
	withErrorHandling()
)(P1Competition);
