/**
 * Enum of result types
 * @readonly
 * @enum {number}
 * @property {{all, players, stories, galleries}}
 */
export const resultTypes = {
	all: 0,
	players: 1,
	stories: 2,
	galleries: 3
};