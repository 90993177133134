import React, { useState } from "react";
import moment from "moment";
import JsonLd from "~/components/JsonLd";
import styles from "~/shared-components/RedesignMiniFeaturedMatch/RedesignMiniFeaturedMatch.module.scss";
import { optaApi, USTeamNames } from "~/utilities/constants";
import { allFlags, openCupFlags } from "~/utilities/flags";
import { withErrorHandling } from "~/hoc";
import { useFetchLiveMatchData } from "~/hooks/match";
import { compose } from "recompose";
import { competitions } from "~/utilities/competitions";
import "moment-timezone";
import {getPenaltiesForMatch} from "~/utilities/getPenaltiesForMatch";

const MiniMatchInfo = ({ doubleHeaderMatches, getTotalGoals, match, type }) => {
	const [isValidMatchDateTime, setIsValidMatchDateTime] = useState(true);
	const [updatedMatch, setUpdatedMatch] = useState(null);
	useFetchLiveMatchData(match, setIsValidMatchDateTime, setUpdatedMatch, getTotalGoals);

	const buildMatchTeamData = (updatedMatch) => {
		const matchDate = updatedMatch?.date && moment(updatedMatch.date).isValid() ? moment(updatedMatch.date).format('MMM DD YYYY') : "TBD";
		const dataObjectStartDate = isValidMatchDateTime && updatedMatch ? updatedMatch.dateTime : updatedMatch?.date;
		const home = updatedMatch?.contestants[0] ? updatedMatch.contestants[0] : null;
		const away = updatedMatch?.contestants[1] ? updatedMatch.contestants[1] : null;
		const USTeam = updatedMatch?.contestants.filter(contestant => contestant.code === "USA")[0];
		const USTeamId = USTeam?.id;
		const USTeamName = USTeamNames[USTeamId];

		const dataObject = {
			"@context": "http://schema.org",
			"@type": "SportsEvent",
			"name": home?.id === USTeamId ? `${USTeamName} v. ${away?.name}` : `${home?.name} v. ${USTeamName}`,
			"description": updatedMatch?.competition?.name,
			"startDate": dataObjectStartDate,
			"location": {
				"@type": "Place",
				"name": `${updatedMatch?.venue?.longName}`,
				"address": `${updatedMatch?.venue?.location}, ${updatedMatch?.venue?.country}`
			},
			"competitor": [
				{
					"@type": "SportsTeam",
					"name": home?.id === USTeamId ? USTeamName : home?.name
				},
				{
					"@type": "SportsTeam",
					"name": away?.id === USTeamId ? USTeamName : away?.name
				}
			],
			"url": updatedMatch?.mathchFeedUrl
		};

		return {
			away,
			awayPenaltyShotsScored,
			dataObject,
			home,
			homePenaltyShotsScored,
			matchDate,
			USTeamName
		};
	};

	const builtMatchTeamData = buildMatchTeamData(updatedMatch);
	const stageName = updatedMatch?.stageName;
	const {
		away,
		awayPenaltyShotsScored,
		dataObject,
		home,
		homePenaltyShotsScored,
		matchDate,
		USTeamName
	} = builtMatchTeamData;

	const matchOpponentsDisplayProps = {
		away,
		awayPenaltyShotsScored,
		doubleHeaderMatches,
		home,
		homePenaltyShotsScored,
		matchDate,
		isValidMatchDateTime,
		type,
		updatedMatch,
		stageName,
		USTeamName
	};

	return (
		updatedMatch && (
			<>
				<MatchOpponentsDisplay {...matchOpponentsDisplayProps} />

				<JsonLd data={dataObject} />
			</>
		)
	);
};

const MatchOpponentsDisplay = ({ away, home, isValidMatchDateTime, updatedMatch, USTeamName, matchDate }) => {
	const matchTime = moment(updatedMatch.dateTime).tz(moment.tz.guess());
	const flagsRepository = updatedMatch.competition.id === competitions.openCup ? openCupFlags : allFlags;
	const flagsRepositoryStyling = updatedMatch.competition.id === competitions.openCup ? styles.openCupLogo : styles.flag;
	const penalties = getPenaltiesForMatch({match: updatedMatch, away, home});

	return (
		<>
			<div className={updatedMatch.status === optaApi.matchStatus.completed ? styles.matchDateSection : ""}>
				{updatedMatch.status !== optaApi.matchStatus.live && (
					<>
						<div className={styles.matchDateHeading}>{updatedMatch.status === optaApi.matchStatus.upcoming ? "Next Match" : "Full Time"}</div>
						<div className={styles.matchDate}>
							{isValidMatchDateTime ?
								<>
									{(moment(updatedMatch.dateTime) < moment() && updatedMatch.status === optaApi.matchStatus.upcoming) ? "Awaiting Kickoff"
										: updatedMatch.cancelled ? "Cancelled"
											: <>
												{matchTime.format('MMM DD')}
												{matchTime.format('YYYY') !== moment().format('YYYY') ? matchTime.format(' YYYY') : ''},&nbsp;
												{matchTime.format('h:mmA').replace(/(:00)/g, '')}&nbsp;
												{matchTime.zoneAbbr()}
											</>
									}
								</>
								:
								<>{matchDate ? <>{matchDate} - TBD</> : "TBD"}</>

							}
						</div>
					</>
				)}
				{updatedMatch.status === optaApi.matchStatus.live && (
					<div className={styles.liveText}>
						<span>LIVE&nbsp;-&nbsp;</span>
						{ updatedMatch.currentPeriod === optaApi.periodType.halfTime ? `HT`
							: <>{updatedMatch.lengthMin}&#8242;</>
						}
					</div>
				)}
			</div>
			<div className={`${styles.team} ${styles.homeTeam} ${updatedMatch.status !== optaApi.matchStatus.live ? "" : styles.live}`}>
				<div className={flagsRepositoryStyling}>{flagsRepository[home.code]}</div>
				<div className={styles.name}>{home?.code === 'USA' ? <>{USTeamName ? USTeamName : home.code} </> : <>{home.name}</>}</div>
				<div className={styles.shortName}>{home?.code === 'USA' ? <>{USTeamName ? USTeamName : home.code} </> : <>{home.code}</>}</div>
			</div>
			{updatedMatch?.status !== optaApi.matchStatus.upcoming && (
				<div className={styles.score}>
					<div className={styles.scoreInner}>
						{home.score}
						{penalties.shouldDisplay && (
							<div className={styles.penaltyScore}>({penalties.home})</div>
						)}
					</div>
					{updatedMatch.status === optaApi.matchStatus.completed ? <div className={styles.ft}>FT</div> : <div>-</div>}
					<div className={styles.scoreInner}>
						{away.score}
						{penalties.shouldDisplay && (
							<div className={styles.penaltyScore}>({penalties.home})</div>
						)}
					</div>
					{updatedMatch?.status === optaApi.matchStatus.live && (
						<div className={styles.liveTextMobile}>
							<span>LIVE&nbsp;-&nbsp;</span>
							{ updatedMatch.currentPeriod === optaApi.periodType.halfTime ? `HT`
								: updatedMatch.currentPeriod === optaApi.periodType.fullTime ? `FT`
									: <>{updatedMatch.lengthMin}&#8242;</>
							}
						</div>
					)}
				</div>
			)}
			<div className={`${styles.team} ${styles.awayTeam} ${updatedMatch.status !== optaApi.matchStatus.live ? "" : styles.live}`}>
				<div className={flagsRepositoryStyling}>{flagsRepository[away.code]}</div>
				<div className={styles.name}>{away?.code === 'USA' ? <>{USTeamName ? USTeamName : away.code}</> : <>{away.name}</>}</div>
				<div className={styles.shortName}>{away?.code === 'USA' ? <>{USTeamName ? USTeamName : away.code}</> : <>{away.code}</>}</div>
			</div>
		</>
	);
};

export default compose(
	// other hocs goes here
	withErrorHandling()
)(MiniMatchInfo);