exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".GalleryTemplate-module__header--3im_4 {\n  background-color: #F7F7F7;\n  padding: 4.16667rem 0; }\n  .GalleryTemplate-module__header--3im_4 h1 {\n    font-weight: 500;\n    margin: 0;\n    text-transform: uppercase; }\n", ""]);

// exports
exports.locals = {
	"header": "GalleryTemplate-module__header--3im_4"
};