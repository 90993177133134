exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".RedesignCallToActionMenu-module__callToActionMenu--1UEd4 {\n  -ms-flex-align: center;\n      align-items: center;\n  display: grid;\n  grid-gap: 20px;\n  gap: 20px;\n  grid-template-columns: auto auto; }\n", ""]);

// exports
exports.locals = {
	"callToActionMenu": "RedesignCallToActionMenu-module__callToActionMenu--1UEd4"
};