import styles from "./MultiLevelMenu.module.scss";
import { whichTransitionEndEvent } from "~/utilities/helpers";

export function setHeightByLevel(state, navigationRoot) {
	let currentActiveLevel;

	if (!navigationRoot) return {};

	if (state.goToMenu)
		currentActiveLevel = navigationRoot.querySelector(`.${state.goToMenu}`);

	if (!currentActiveLevel) return {};

	return {height: currentActiveLevel.clientHeight};
}

/**
 * Check if item has children
 *
 * @param {object} item
 */
export function hasSubMenuItems(item) {
	const hasSubItems = item.links && item.links.length > 0;
	return hasSubItems;
}

export function setInitialActiveLevel(state, setState, navigationRoot) {

	if (!navigationRoot) return;

	const currentActiveLevel = navigationRoot.querySelector(`#link-${state.activeLevelId}`)?.closest("ul");

	if (!currentActiveLevel) return;

	currentActiveLevel.classList.add(`${styles.current}`);
	setState({initialLoad: false});
}

export function handleLevelClasses(state, navigationRoot) {
	handleActiveLevelClasses(state, navigationRoot);
	handlePrevActiveLevelClasses(state, navigationRoot);
}

function handleActiveLevelClasses(state, navigationRoot) {
	if (!state.goToMenu || !navigationRoot) return;

	const activeLevel = navigationRoot.querySelector(`.${state.goToMenu}`);

	if (!activeLevel) return;

	activeLevel.classList.add(styles.current);

	if (state.goNext) {
		activeLevel.classList.add(styles.animateInFromRight);
	}

	if (state.goBack) {
		activeLevel.classList.add(styles.animateInFromLeft);
	}
}

function handlePrevActiveLevelClasses(state, navigationRoot) {
	if (!state.prevMenu || !navigationRoot) return;

	const prevActiveLevel = navigationRoot.querySelector(`.${state.prevMenu}`);

	if (!prevActiveLevel) return;

	const transitionEndEvent = whichTransitionEndEvent(prevActiveLevel);
	prevActiveLevel.classList.remove(styles.current, styles.animateInFromRight, styles.animateInFromLeft);


	if (state.goNext) {
		prevActiveLevel.classList.add(styles.animateOutToLeft);

		prevActiveLevel.addEventListener(transitionEndEvent, function _listener() {
			prevActiveLevel.classList.remove(styles.animateOutToLeft);

			prevActiveLevel.removeEventListener(transitionEndEvent, _listener, true);
		}, true);
	}

	if (state.goBack) {
		prevActiveLevel.classList.add(styles.animateOutToRight);

		prevActiveLevel.addEventListener(transitionEndEvent, function _listener() {
			prevActiveLevel.classList.remove(styles.animateOutToRight);

			prevActiveLevel.removeEventListener(transitionEndEvent, _listener, true);
		}, true);
	}

	/* Fallback if no transition event happened TODO: find a bette solution */
	setTimeout(() => {
		prevActiveLevel.classList.remove(styles.animateOutToRight, styles.animateOutToLeft);
	}, 1200);
}
