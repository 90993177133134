import React, { useRef, useEffect, useState } from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import { isServer } from "~/utilities";
import styles from "./HorizontalNav.module.scss";
import { throttled } from "~/utilities/helpers";
import { Arrow } from "~/shared-components/SvgIcons";
import HelperCommentContentEditor from "~/experience-editor/HelperCommentContentEditor";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { ScrollLink } from "~/patches/components/ScrollLink";
import { screensizes } from "~/site-settings.json";

/**
 * Placeholder for window position
 * @type {number}
 */
let windowTop = 0;


/**
 * @type {number}
 */
const mainNavbarHeight = 80;

/**
 * @type {HTMLElement}
 */
let jssNavigation;


/**
 * @type {number}
 */
const lgScreen = screensizes.lg2.replace("px", "");

let _scrollEvent;

let _resizeEvent;

const HorizontalNav = ({ rendering: { horizontalNav }, sitecoreContext: { pageEditing } }) => {
	const navWrapper = useRef(undefined);
	const navElement = useRef(undefined);
	const header = useRef(undefined);
	const navbarList = useRef(undefined);

	const [menuOpen, setMenuOpen] = useState(false);
	const [elementHeight, setElementHeight] = useState(undefined);
	const [activeName, setActiveName] = useState(undefined);

	useEffect(() => {

		setupHorizontalNav(navElement?.current);

		return () => {
				window.removeEventListener("scroll", _scrollEvent); //eslint-disable-line
				window.removeEventListener("resize", _resizeEvent); //eslint-disable-line
		};
	}, []);


	/**
	 * Inital setup - setting states, events and caching of elements for future use.
	 * @param {HtmlElement} element
	 */
	function setupHorizontalNav(element) {

		if (!element || isServer) return;

		//eslint-disable-next-line
		if (!jssNavigation) jssNavigation = document.querySelector(".navigationDesktopGlobal");

		handleElementHeightChange();
		_scrollEvent = throttled(() => isStickyEvent(), 25);
		_resizeEvent = throttled(() => resizeEvent(), 50);

		window.addEventListener("scroll", _scrollEvent); //eslint-disable-line
		window.addEventListener("resize", _resizeEvent); //eslint-disable-line
	}


	function handleActiveNameChange(name) {
		setActiveName(name);
	}


	/**
	 * Open or close mobile version of the navbar.
	 */
	function menuToggle() {
		setMenuOpen(!menuOpen);

		if (isServer) return;

		menuToggle
		? document.body.classList.remove("overflowHidden") //eslint-disable-line
		:	document.body.classList.add("overflowHidden"); //eslint-disable-line
	}


	/**
	 * Event to check if navbar is sticky or not.
	 * @param {Event} event
	 */
	function isStickyEvent() {
		if (!jssNavigation) return; //eslint-disable-line

		//eslint-disable-next-line
		if (window.innerWidth < lgScreen) {

			navWrapper?.current?.appendChild(navElement?.current);
			return;
		}

		windowTop = window.pageYOffset; //eslint-disable-line

		if (windowTop >= navWrapper?.current?.offsetTop - mainNavbarHeight) {
			jssNavigation?.appendChild(navElement?.current);
		} else {
			navWrapper?.current?.appendChild(navElement?.current);
		}
	}


	/**
	 * @param {Event} event
	 * @param {HtmlElement} element
	 */
	function resizeEvent() {
		handleElementHeightChange();
	}


	/**
 * Set element height and body padding
 */
	function handleElementHeightChange() {
		//eslint-disable-next-line
		if (window.innerWidth < lgScreen) {
			//eslint-disable-next-line
			document.body.style.paddingTop = `${header?.current?.clientHeight}px`;
			setElementHeight(0);
		} else {

			//eslint-disable-next-line
			document.body.style.paddingTop = `${0}px`;
			setElementHeight(navbarList?.current?.clientHeight);
		}
	}


	return (
		<>
			{horizontalNav && (
				<div ref={navWrapper} style={{height: `${elementHeight}px`}}>
					<aside
						ref={navElement}
						className={`${styles.container} ${menuOpen ? styles.openMenu : ""}`} >
						<div className={`${styles.containerInner}`}>
							<header ref={header} className={`${styles.header} ${activeName ? styles.active : ""}`}>
								<div>{activeName}</div>
								<button className={styles.menuToggle} onClick={() => menuToggle()}>
									<Arrow />
								</button>
							</header>
							<nav>
								<div className={styles.navInner}>
									<ul ref={navbarList}>
										{horizontalNav.map((item, index) => (
											<li key={index}>
												<ScrollLink
													activeClass={styles.active}
													to={`${item.uniqueId}`}
													spy={true}
													offset={-185}
													delay={100}
													isDynamic={true}
													onSetActive={() => {
														handleActiveNameChange(item.name);
														setMenuOpen(false);
													}}
													duration={300}>
													{item.name}
												</ScrollLink>
											</li>
										))}
									</ul>
								</div>
							</nav>
						</div>
					</aside>
					<div className={`${styles.background} ${menuOpen ? styles.showBackground : ""}`}></div>
				</div>
			)}
			{!horizontalNav && pageEditing && (
				<HelperCommentContentEditor comment="Here go to the Horizontal Nav tab and select the items you want to appear in the navigation. The name of an item selected will use its name to be displayed for the navigation." />
			)}
		</>
	);
};

export default compose(
	// other hocs goes here
	withSitecoreContext(),
	withErrorHandling()
)(HorizontalNav);