import React, { useState } from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import Helmet from "react-helmet";
import { withRouter } from "react-router-dom";
import styles from "./SearchResults.module.scss";
import { StoryCard } from "~/shared-components";
import SectionLabel from "~/shared-components/SectionLabel";
import PlayerThumbnail from "../PlayerThumbnail";
import { withNamespaces } from "react-i18next";
import Heading from "./Heading";
import Menu from "./Menu";
import GalleryThumbnail from "~/shared-components/GalleryThumbnail";
import { getGalleryThumbnailImages } from "~/utilities/helpers";
import SearchForm from "~/shared-components/SearchForm";
import { resultTypes } from "./resultTypes";

const optionsColumns = {
	modifierClassName: 'small',
};

const SearchResults = props => {
	const { rendering: { storiesCount, playersCount, galleriesCount, resultCount, searchQuery, /*tagSearch: boolean*/ }, t } = props;
	const [activeResultType, setActiveResultType] = useState(resultTypes.all);
	const [activeResultText, setActiveResultText] = useState(`${t("search_heading_all_results")}`);
	const searchTerm = searchQuery.replace("-", " ");

	/**
	 * Used to set the active result type
	 *
	 * @param {number} type
	 */
	function handlerActiveResultTypeChange(type) {
		setActiveResultType(type);
	}

	/**
	 * Get translated name from sitecore by type
	 *
	 * @param {number} text
	 */
	function getTypeName(type) {
		let text = "";

		switch (type) {
			case resultTypes.all:
				text = t("search_heading_all_results");
				break;
			case resultTypes.players:
				text = t("search_heading_players");
				break;
			case resultTypes.stories:
				text = t("search_heading_stories");
				break;
			case resultTypes.galleries:
				text = t("search_heading_galleries");
				break;
		}

		return text ;
	}

	/**
	 * Used to set the active text to appear on mobile dropdown
	 *
	 * @param {string} text
	 */
	function handlerActiveResultTextChange(text) {

		if (!text) return "";

		setActiveResultText(text);
	}

	return (
		<>
			{props.rendering && (
				<>
					<Helmet>
						<title>{searchTerm}</title>
					</Helmet>
					<div className={styles.searchInput}>
						<SearchForm forceOpen />
					</div>
					<div className={styles.container}>
						{searchQuery &&
							<>
								<div className={styles.sidebar}>
									<h1>{`“${searchTerm}”`}</h1>
									<Menu {...{
										activeResultType,
										handlerActiveResultTypeChange,
										handlerActiveResultTextChange,
										activeResultText,
										getTypeName,
										resultTypes,
										storiesCount,
										playersCount,
										galleriesCount,
										resultCount}} />
								</div>
								<div className={styles.content}>
									{resultCount && resultCount > 0 ?
										<Results {...{
											activeResultType,
											handlerActiveResultTypeChange,
											activeResultText,
											handlerActiveResultTextChange,
											...props}} />
										:
										<EmptySearchResult {...{t}} />
									}
								</div>
							</>
						}
					</div>
				</>
			)}
		</>
	);
};

const EmptySearchResult = ({t}) => (
	<div className={styles.row}>
		<div className={styles.colFullWidth}>
			<div className={styles.noResults}>
				<p>{t("search_empty_search_result")}.</p>
			</div>
		</div>
	</div>
);

const Results = props => {
	const {
		rendering: { stories, storiesCount, players, playersCount, galleries, galleriesCount, tagName },
		t,
		activeResultType,
		handlerActiveResultTypeChange,
		handlerActiveResultTextChange } = props;

	function handlerResultTypeActiveClasses(resultType) {

		if (!resultType) return "";

		return `${activeResultType === resultTypes.all || activeResultType === resultType ? "" : styles.hide} ${activeResultType === resultType ? styles.activeResultType : ""}`;
	}

	return (
		<>
			{tagName && <h1>{tagName}</h1>}
			{players && players.length > 0 && (
				<div className={`${styles.row} ${handlerResultTypeActiveClasses(resultTypes.players)}`}>
					<div className={styles.sectionLabel}>
						<SectionLabel plainText sectionLabel={t("search_heading_players")} />
					</div>
					<div className={`${styles.colFullWidth} ${styles.heading}`}>
						<Heading
							heading={t("search_heading_players")}
							buttonName={t("search_more_button")}
							showButton={players.length > 4}
							count={playersCount}
							type={resultTypes.players}
							{...{handlerActiveResultTypeChange, activeResultType, handlerActiveResultTextChange}} />
					</div>
					{players.slice(0, 4).map((player, index) => (
						<div key={index} className={styles.col}>
							<PlayerContainer {...player} />
						</div>
					))}
					{players.slice(4).map((player, index) => (
						<div key={index} className={styles.col}>
							<PlayerContainer {...player} />
						</div>
					))}
				</div>
			)}
			{stories && stories?.length > 0 && (
				<div className={`${styles.row} ${handlerResultTypeActiveClasses(resultTypes.stories)}`}>
					<div className={styles.sectionLabel}>
						<SectionLabel plainText sectionLabel={t("search_heading_stories")} />
					</div>
					<div className={`${styles.colFullWidth} ${styles.heading}`}>
						<Heading
							heading={t("search_heading_stories")}
							buttonName={t("search_more_button")}
							showButton={stories.length > 8}
							count={storiesCount}
							type={resultTypes.stories}
							{...{handlerActiveResultTypeChange, activeResultType, handlerActiveResultTextChange}}/>
					</div>
					{stories.slice(0, 8).map((story, index) => (
						<div key={index} className={styles.col}>
							<StoryContainer {...story} />
						</div>
					))}
					{activeResultType === resultTypes.stories && (
						stories.slice(8).map((story, index) => (
							<div key={index} className={styles.col}>
								<StoryContainer {...story} />
							</div>
						))
					)}

				</div>
			)}
			{galleries && galleries?.length > 0 && (
				<div className={`${styles.row} ${handlerResultTypeActiveClasses(resultTypes.galleries)}`}>
					<div className={styles.sectionLabel}>
						<SectionLabel plainText sectionLabel={t("search_heading_galleries")} />
					</div>
					<div className={`${styles.colFullWidth} ${styles.heading}`}>
						<Heading
							heading={t("search_heading_galleries")}
							buttonName={t("search_more_button")}
							showButton={galleries.length > 2}
							count={galleriesCount}
							type={resultTypes.galleries}
							{...{handlerActiveResultTypeChange, activeResultType, handlerActiveResultTextChange}}/>
					</div>
					{galleries.slice(0, 2).map((gallery, index) => (
						<div key={index} className={styles.colHalf}>
							<GalleryContainer {...gallery} />
						</div>
					))}
					{activeResultType === resultTypes.galleries && (
						galleries.slice(2).map((gallery, index) => (
							<div key={index} className={styles.colHalf}>
								<GalleryContainer {...gallery} />
							</div>
						))
					)}
				</div>
			)}
		</>
	);
};

const StoryContainer = story => (
	<StoryCard {...{
		options: {
			modifierClassName: optionsColumns.modifierClassName
		},
		fields: {
			heading: story.heading,
			image: story.image,
			date: story.date,
			categoryValue: story.category?.value,
			categoryKey: story.categoryKey,
			categoryUrl: story.categoryUrl,
			imageText: story.imageText,
			link: { value: { href: story.link } }
		}
	}} />
);

const PlayerContainer = player => (
	<PlayerThumbnail {...{
		fields: {
			itemLink: {
				fields: {
					firstName: player.firstName,
					lastName: player.lastName,
					age: player.age,
					number: player.number,
					club: player.club,
					homeTown: player.homeTown,
					image: player.image,
					position: {
						fields: {
							positionName: player.position
						}
					}
				},
				url: player.link
			}
		}
	}} />
);

const GalleryContainer = gallery => (
	<GalleryThumbnail
		{...{
			heading: gallery.heading,
			categoryValue: gallery.category?.value,
			categoryKey: gallery.categoryKey,
			date: gallery.date,
			images: getGalleryThumbnailImages(gallery.galleryItems),
			url: gallery.url
		}} />
);

export default compose(
	// other hocs goes here
	withRouter,
	withNamespaces(),
	withErrorHandling()
)(SearchResults);