import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import TagManager from 'react-gtm-module';
import { withGigya } from "~/hoc";
import { knownRoutes } from "~/utilities/constants";
import { BrazeService } from "~/services/brazeService";

class _PrivateRoute extends Component {
	render() {
		if (this.props.gigya.isLoading) {
			return <></>;
		}
	
		// Authenticated, render protected page (e.g.: /profile/my-profile)
		if (this.props.gigya.isLoggedIn) {
			const { render, ...rest } = this.props;
			const overrideRouteRender = props => {
				const newProps = props;
				newProps.match.params.sitecoreRoute = this.props.location.pathname;
				return render(newProps);
			};
			return <Route render={overrideRouteRender} {...rest} />; 
		}
		// Unauthenticated: Redirect to /login
		else { 
			localStorage.setItem("intendedRoute", this.props.location.pathname);
			return <Redirect to={knownRoutes.login} />;
		}
	}
}

class _LoginRoute extends Component {
	componentDidUpdate() {
		if (this.props.gigya.isLoggedIn && typeof window !== 'undefined') {
			BrazeService.changeUser(this.props.gigya?.profile?.email, this.props.gigya?.UID);
		}
	}

	render() {
		if (this.props.gigya.isLoading) {
			return <></>;
		}
		
		// Authenticated, but on the /login page. Redirect to intendedRoute or default profile page
		if (this.props.gigya.isLoggedIn) {
			const { gigya: { data } } = this.props;

			const insiderAccountLoginArgs = {
				dataLayer: {
					event: 'insiderAccountLogin',
					tier: data?.membershipTier
				}
			};

			TagManager.dataLayer(insiderAccountLoginArgs);

			const intendedRoute = localStorage.getItem("intendedRoute") || knownRoutes.profile;
			localStorage.setItem("intendedRoute", "");
			return <Redirect to={intendedRoute} />;
		}
		// Unauthenticated: Redirect to /login
		else { 
			const { render, ...rest } = this.props;
			const overrideRouteRender = props => {
				const newProps = props;
				newProps.match.params.sitecoreRoute = knownRoutes.login;
				return render(newProps);
			};
			return <Route render={overrideRouteRender} {...rest} />; 
		}
	}
}

export const PrivateRoute = withGigya()(_PrivateRoute);
export const LoginRoute = withGigya()(_LoginRoute);