import React from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import styles from "./CenteredFixedWidthLayout.module.scss";

const CenteredFixedWidthLayout = ({ rendering }) => (
	<div className={styles.centeredFixedWidth} >
		<Placeholder name="jss-layout-centered-fixed-width-content" rendering={rendering} />
	</div>
);

export default compose(
	// other hocs goes here
	withErrorHandling()
)(CenteredFixedWidthLayout);