exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".TagsBlock-module__mainContainer--2EGSh {\n  margin-top: 60px; }\n  @media screen and (max-width: 1239px) {\n    .TagsBlock-module__mainContainer--2EGSh {\n      margin-top: 30px;\n      padding: 0 12px; } }\n\n.TagsBlock-module__sectionName--l4_hc {\n  -ms-flex-align: center;\n      align-items: center;\n  color: #212844;\n  display: -ms-flexbox;\n  display: flex;\n  font-size: 18px;\n  font-weight: 600;\n  line-height: 28.32px;\n  letter-spacing: 0.001em;\n  text-transform: uppercase; }\n\n.TagsBlock-module__inner--2Bk5x {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n  height: auto;\n  margin: 16px auto 0;\n  max-width: 83.75rem;\n  position: relative;\n  -ms-flex-align: start;\n      align-items: flex-start; }\n  @media screen and (max-width: 1239px) {\n    .TagsBlock-module__inner--2Bk5x {\n      width: 100%;\n      overflow-x: auto;\n      display: -ms-flexbox;\n      display: flex;\n      -ms-flex-wrap: nowrap;\n          flex-wrap: nowrap;\n      padding-bottom: 10px; } }\n  .TagsBlock-module__inner--2Bk5x a {\n    margin: 0 16px 16px 0;\n    white-space: nowrap; }\n    @media screen and (max-width: 1239px) {\n      .TagsBlock-module__inner--2Bk5x a {\n        margin-bottom: 0; } }\n\n.TagsBlock-module__tag--MWKs_ {\n  -ms-flex-align: center;\n      align-items: center;\n  background: #C2002F;\n  border-radius: 0.3rem;\n  color: #FFFFFF;\n  font-size: 10px;\n  font-weight: 600;\n  line-height: 19px;\n  padding: 2px 10px 0;\n  text-transform: uppercase;\n  letter-spacing: 0.05em; }\n  .TagsBlock-module__tag--MWKs_ svg {\n    height: 0.8rem;\n    padding-left: 0.5rem;\n    stroke-width: 0.2rem;\n    margin-bottom: -1px; }\n    .TagsBlock-module__tag--MWKs_ svg path {\n      border-radius: 0.3rem;\n      stroke: #FFFFFF; }\n", ""]);

// exports
exports.locals = {
	"mainContainer": "TagsBlock-module__mainContainer--2EGSh",
	"sectionName": "TagsBlock-module__sectionName--l4_hc",
	"inner": "TagsBlock-module__inner--2Bk5x",
	"tag": "TagsBlock-module__tag--MWKs_"
};