import React from "react";
import { Placeholder, withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import RedesignSection from "~/shared-components/RedesignSection";
import { compose } from "recompose";
import { withErrorHandling } from "~/hoc";

const TitledSection = ({ fields, rendering }) => (
	<RedesignSection 
		title={fields?.title?.value} 
		link={fields?.link?.value?.href} 
		linkText={fields?.linkText?.value} 
		padding={fields?.heightPadding?.value}
		subHead={fields?.subHead?.value}
		background={fields?.background?.value}
		columns={fields?.columns?.value}
		desktopImage={fields?.backgroundImageDesktop?.value?.src}
		mobileImage={fields?.backgroundImageMobile?.value?.src}
		backgroundCover={fields?.backgroundCover?.value} >
		<Placeholder name="jss-titled-section" rendering={rendering} />
	</RedesignSection>		
);

export default compose(
	withSitecoreContext(),
	withErrorHandling()
)(TitledSection);