var escape = require("../../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SharedAccordionTab-module__container--2Fqb8 h3 {\n  color: #212844;\n  font-size: 1.4rem;\n  font-weight: 700;\n  margin: 0;\n  text-transform: uppercase; }\n\n.SharedAccordionTab-module__container--2Fqb8 small {\n  color: #808080;\n  display: block;\n  font-size: 1.4rem;\n  text-transform: uppercase; }\n\n.SharedAccordionTab-module__header--30ECX {\n  border-top: 1px solid rgba(0, 0, 0, 0.07);\n  cursor: pointer;\n  padding: 2rem 2rem;\n  position: relative; }\n  .SharedAccordionTab-module__header--30ECX:before {\n    background: transparent url(" + escape(require("../../../assets/svg/down-arrow.svg")) + ") no-repeat center center;\n    content: '';\n    -webkit-filter: grayscale(100%);\n            filter: grayscale(100%);\n    height: 2rem;\n    position: absolute;\n    right: 1rem;\n    top: 2rem;\n    -webkit-transition: -webkit-transform 200ms ease-in;\n    transition: -webkit-transform 200ms ease-in;\n    -o-transition: transform 200ms ease-in;\n    transition: transform 200ms ease-in;\n    transition: transform 200ms ease-in, -webkit-transform 200ms ease-in;\n    width: 2rem; }\n\n.SharedAccordionTab-module__content--2Lco- {\n  display: none;\n  font-size: 1.58rem;\n  line-height: 1.266rem;\n  overflow: hidden;\n  padding: 0 2rem; }\n  .pageEditing .SharedAccordionTab-module__content--2Lco- {\n    display: block; }\n\n.SharedAccordionTab-module__containerActive--3gfjP { }\n  .SharedAccordionTab-module__containerActive--3gfjP .SharedAccordionTab-module__header--30ECX:before {\n    -webkit-transform: rotate(180deg);\n            transform: rotate(180deg); }\n  .SharedAccordionTab-module__containerActive--3gfjP .SharedAccordionTab-module__content--2Lco- {\n    display: block; }\n\n@media (min-width: 1025px) {\n  .SharedAccordionTab-module__header--30ECX,\n  .SharedAccordionTab-module__content--2Lco- {\n    padding: 2rem 0; } }\n", ""]);

// exports
exports.locals = {
	"container": "SharedAccordionTab-module__container--2Fqb8",
	"header": "SharedAccordionTab-module__header--30ECX",
	"content": "SharedAccordionTab-module__content--2Lco-",
	"containerActive": "SharedAccordionTab-module__containerActive--3gfjP SharedAccordionTab-module__container--2Fqb8"
};