/* eslint-disable */
import React from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import Table from "~/shared-components/Table";
import styles from "./TablePlayers.module.scss";

const formatDataForTable = data => {
	const formattedData = [];
	data.map(p => {

		formattedData.push({
			player: {
				name: `${p.lastName}, ${p.firstName}`,
				image: p.sitecoreData?.thumbnail?.value?.src
			},
			position: p.position,
			appearances: p.stats.find(s => s.name === "Appearances") ? Math.round(p.stats.find(s => s.name === "Appearances").value) : "--",
			minutes: p.stats.find(s => s.name === "Time Played") ? Math.round(p.stats.find(s => s.name === "Time Played").value) : "--",
			starts: p.stats.find(s => s.name === "Starts") ? Math.round(p.stats.find(s => s.name === "Starts").value) : "--",
			foulsCommitted: p.stats.find(s => s.name === "Total Fouls Conceded") ? Math.round(p.stats.find(s => s.name === "Total Fouls Conceded").value) : "--",
			yellowCards: p.stats.find(s => s.name === "Yellow Cards") ? Math.round(p.stats.find(s => s.name === "Yellow Cards").value) : "--",
			redCards: p.stats.find(s => s.name === "Red Cards") ? Math.round(p.stats.find(s => s.name === "Red Cards").value) : "--",
			goals: p.stats.find(s => s.name === "Goals") ? Math.round(p.stats.find(s => s.name === "Goals").value) : "--",
			shots: p.stats.find(s => s.name === "Total Shots") ? Math.round(p.stats.find(s => s.name === "Total Shots").value) : "--",
			shotsOnTarget: p.stats.find(s => s.name === "Shots On Target ( inc goals )") ? Math.round(p.stats.find(s => s.name === "Shots On Target ( inc goals )").value) : "--",
			conversionRate: ((p.stats.find(s => s.name === "Goals")) && (p.stats.find(s => s.name === "Total Shots"))) ? Math.round(parseInt(p.stats.find(s => s.name === "Goals").value)/parseInt(p.stats.find(s => s.name === "Total Shots").value) * 100) + "%" : "--"
		})
	})

	return formattedData;
}

const TablePlayers = ({ heading, playerStats }) => (
	<div className={styles.TablePlayers}>
		<Table
			data={formatDataForTable(playerStats)}
			heading={heading}
			tableType={"players"} />
	</div>
);

export default compose(
	// other hocs goes here
	withErrorHandling()
)(TablePlayers);