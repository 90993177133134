import React from "react";
import { Text, RichText, Image } from "~/patches/components";
import TextLink from "~/shared-components/TextLink";
import Button from "~/shared-components/Button";
import styles from "./CallToActionBanner.module.scss";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import { useCallToActionBanner } from "~/hooks/useCallToActionBanner";
import { urlFor } from "~/utilities/sanityImage";


const CallToActionBanner = (props) => {
	const { fields, rendering } = props;
	const { data } = useCallToActionBanner({ sitecoreItemId: rendering.dataSource });
	let callToAction;

	{/* Call to Action Conditional Logic */ }
	if (fields.convertToLinkText?.value) {
		callToAction = <TextLink link={fields.link.value.href} text={fields.ctaText.value} variant="mediumRegularArrowRed" />
	} else if (!fields.convertToLinkText?.value && fields.link?.value?.href) {
		callToAction = <Button link={fields.link.value.href} text={fields.ctaText.value} size="large" variant="primary" />
	}




	if (data) {
		// will want to change this over to rich text from sanity. 		
		return (
			<div className='redesignGridContainer'>
				<>
					<div className={styles.CTABanner}>
						<div className={styles.textContainer}>
							<div className={styles.textWrapper}>
								<h2>
									<Text field={{ value: data.headline }} />
								</h2>

								<p className={styles.subheadline}>
									{data.subheadline }
								</p>
								<Button link={data.linkTo.internal?.slug?.current || data.linkTo.external || data.linkTo.sitecore} text={data.linkTo.title} size="large" variant="primary" />
							</div>
						</div>

						<div className={styles.desktopImage}>
							{/* <Image field={fields.desktopImage} /> */}
							<img src={urlFor(data.bannerImage).maxWidth(900).url()} />
						</div>

						<div className={styles.mobileImage}>
							{/* <Image field={fields.mobileImage} /> */}
							<img src={urlFor((data.mobileBannerImage ?? data.bannerImage)).width(462).height(540).auto('format').url()} />
						</div>
					</div>
				</>

			</div>
		)
	}

	return (
		<>
			{fields && (
				<div className='redesignGridContainer'>
					<>
						{ fields.gradientWithImage?.value && (
							<div className={styles.CTABannerGradient}>
								<div className={styles.textContainer}>
									<div className={styles.textWrapper}>
										<h2>
											<Text field={fields.heading} />
										</h2>

										<RichText field={fields.subtext} />

										{callToAction}
									</div>
								</div>

								<div className={styles.desktopImage}>
									<Image field={fields.desktopImage} />
								</div>

								<div className={styles.mobileImage}>
									<Image field={fields.mobileImage} />
								</div>
							</div>
						)}
						{ !fields.gradientWithImage?.value && (
							<div className={styles.CTABanner}>
								<div className={styles.textContainer}>
									<div className={styles.textWrapper}>
										<h2>
											<Text field={fields.heading} />
										</h2>

										<RichText field={fields.subtext} />

										{callToAction}
									</div>
								</div>

								<div className={styles.desktopImage}>
									<Image field={fields.desktopImage} />
								</div>

								<div className={styles.mobileImage}>
									<Image field={fields.mobileImage} />
								</div>
							</div>
						)}

					</>
				</div>
			)}

		</>
	);
};


export default compose(
	// other hocs goes here
	withErrorHandling()
)(CallToActionBanner);