/* eslint-disable no-restricted-globals */
import React from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import styles from "./PlayerThumbnailStory.module.scss";

const PlayerThumbnailStory = ({ player, visible }) => {
	const playerDetails = (
		<>
			<div className={styles.image} style={player?.Image ? {backgroundImage: `url(${player.Image})`} : {}} />
			<div className={styles.info}>
				<h3>{player.FirstName} {player.LastName}</h3>
				<div className={styles.position}>
					{player.Position}
				</div>
			</div>
		</>
	);

	if (player?.playerPageUrl) {
		return (
			<a className={`${styles.wrap} ${!visible ? styles.wrapHidden : ''}`} href={player.playerPageUrl}>
				{playerDetails}
			</a>
		);
	}

	return (
		<div className={styles.wrap}>
			{playerDetails}
		</div>
	);
};

export default compose(
	// other hocs goes here
	withErrorHandling()
)(PlayerThumbnailStory);