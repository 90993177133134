exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".withSectionHeader-module__sectionHeader--zV-vV {\n  position: relative;\n  background: #FFFFFF;\n  border-top: 1px solid rgba(0, 0, 0, 0.07);\n  position: relative; }\n  .withSectionHeader-module__sectionHeader--zV-vV:before {\n    background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.04)), to(rgba(0, 0, 0, 0)));\n    background: -o-linear-gradient(top, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0));\n    background: linear-gradient(180deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0));\n    content: \"\";\n    height: 3.3rem;\n    left: 0;\n    position: absolute;\n    top: 0;\n    width: 100%; }\n\n.withSectionHeader-module__content--3YEtB {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: justify;\n      justify-content: space-between;\n  margin: 0 auto 2rem;\n  max-width: 100%;\n  padding: 0 2rem;\n  width: 1440px; }\n\n.withSectionHeader-module__link--E7bmC {\n  margin-top: 3.384rem; }\n\n@media (min-width: 1025px) {\n  .withSectionHeader-module__content--3YEtB {\n    margin-bottom: 2.5rem;\n    padding: 0 8.333rem; } }\n\n@media (min-width: 1440px) {\n  .withSectionHeader-module__content--3YEtB {\n    width: 1640px; } }\n", ""]);

// exports
exports.locals = {
	"sectionHeader": "withSectionHeader-module__sectionHeader--zV-vV",
	"content": "withSectionHeader-module__content--3YEtB",
	"link": "withSectionHeader-module__link--E7bmC"
};