exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Heading-module__container--3PXej {\n  -ms-flex-align: end;\n      align-items: flex-end;\n  display: -ms-flexbox;\n  display: flex;\n  font-size: 2.5rem;\n  font-weight: 300;\n  -ms-flex-pack: justify;\n      justify-content: space-between;\n  margin: 0 0 2.5rem 0;\n  text-transform: uppercase; }\n  .Heading-module__container--3PXej button {\n    -ms-flex-align: center;\n        align-items: center;\n    color: #C2002F;\n    cursor: pointer;\n    display: -ms-inline-flexbox;\n    display: inline-flex;\n    font-family: \"USSF90MinDisplay\", helvetica, arial, sans-serif;\n    font-size: 1rem;\n    letter-spacing: 0.02rem;\n    text-transform: uppercase; }\n    .Heading-module__container--3PXej button svg {\n      height: calc(0.46296vw + 13.33333px);\n      margin-left: 0.9rem;\n      width: 0.8rem; }\n\n@media (min-width: 1025px) {\n  .Heading-module__container--3PXej button {\n    font-size: 1.2rem; } }\n", ""]);

// exports
exports.locals = {
	"container": "Heading-module__container--3PXej"
};