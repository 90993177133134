import React from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import styles from "./MembershipPerks.module.scss";

const MembershipPerks = ({ fields }) => {
	const {
		membershipData,
		perksIntro
	} = fields;

	const description = membershipData?.fields?.description?.value;
	const disclaimer = membershipData?.fields?.disclaimer?.value;
	const intro = perksIntro?.value !== "" ? perksIntro.value : membershipData?.fields?.membershipLevel;

	return (
		<div className={styles.membershipPerksContainer}>
			<div className={styles.membershipPerks}>
				<div className={styles.perksIntro}>
					{intro}
				</div>
				<div className={styles.description}>
					{description.split("\r\n").map((line, index) => (
						<div className={styles.paragraphBody} key={index}>{line}<br /></div>
					))}
				</div>
				<div className={styles.disclaimer}>
					{disclaimer}
				</div>
			</div>
		</div>
	);
};

export default compose(
	// other hocs goes here
	withErrorHandling()
)(MembershipPerks);