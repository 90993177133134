exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ScoreboardBanner-module__scoreboard--1eRrL {\n  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.04)), to(rgba(0, 0, 0, 0)));\n  background: -o-linear-gradient(top, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0));\n  background: linear-gradient(180deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0));\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-direction: column;\n      flex-direction: column;\n  margin: 0 auto;\n  width: 100%; }\n  .ScoreboardBanner-module__scoreboard--1eRrL.ScoreboardBanner-module__addTopMargin--2h_JG {\n    padding-top: 5rem; }\n  @media (min-width: 992px) {\n    .ScoreboardBanner-module__scoreboard--1eRrL {\n      background: none; } }\n  @media (min-width: 1025px) {\n    .ScoreboardBanner-module__scoreboard--1eRrL {\n      -ms-flex-direction: row;\n          flex-direction: row; } }\n\n.ScoreboardBanner-module__ad--1LJeS {\n  display: none; }\n\n.ad-loaded .ScoreboardBanner-module__adContainer--3_RDF {\n  padding: 0 2rem 5rem; }\n  @media (min-width: 1025px) {\n    .ad-loaded .ScoreboardBanner-module__adContainer--3_RDF {\n      padding: 0 6rem 0 0; } }\n  .ad-loaded .ScoreboardBanner-module__adContainer--3_RDF .ScoreboardBanner-module__ad--1LJeS {\n    background: #BFBFBF;\n    display: block;\n    height: 250px;\n    margin: 0 auto;\n    width: 300px; }\n", ""]);

// exports
exports.locals = {
	"scoreboard": "ScoreboardBanner-module__scoreboard--1eRrL",
	"addTopMargin": "ScoreboardBanner-module__addTopMargin--2h_JG",
	"ad": "ScoreboardBanner-module__ad--1LJeS",
	"adContainer": "ScoreboardBanner-module__adContainer--3_RDF"
};