exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ButtonRedFill-module__ButtonRedFill--2WLJI {\n  background-color: #C2002F;\n  border: 0.1rem solid #C2002F;\n  border-radius: 0.3rem;\n  color: #FFFFFF;\n  display: block;\n  font-size: 1.2rem;\n  font-weight: 700;\n  letter-spacing: 0.05em;\n  padding: 1.3rem 0 1.1rem;\n  text-align: center;\n  text-transform: uppercase; }\n  .ButtonRedFill-module__ButtonRedFill--2WLJI:hover {\n    background-color: #FFFFFF;\n    color: #C2002F; }\n\n@media (min-width: 1025px) {\n  .ButtonRedFill-module__ButtonRedFill--2WLJI {\n    font-size: 1rem;\n    padding: 1.1rem 0 0.9rem; } }\n", ""]);

// exports
exports.locals = {
	"ButtonRedFill": "ButtonRedFill-module__ButtonRedFill--2WLJI"
};