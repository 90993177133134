import React from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";

const PlayerYearTable = ({ yearStats }) => (
	<>
		{yearStats && (
			<div>
				{/* {console.log(yearStats)} */}
			</div>
		)}
	</>
);

export default compose(
	// other hocs goes here
	withErrorHandling()
)(PlayerYearTable);