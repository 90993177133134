import React, { Component } from "react";
import { compose } from "recompose";
import MatchFilter from "~/shared-components/MatchFilter";
import AllMatchesTab from "./../AllMatchesTab";
import { CalendarSync, MatchGridIcon, MatchListIcon } from "~/shared-components/SvgIcons";
import { isServer } from "~/utilities";
import { stanzaUrl } from "~/utilities/constants";
import styles from "./AllMatchesTabs.module.scss";

class AllMatchesTabs extends Component {
	constructor(props) {
		super(props);

		const { children } = this.props;

		const initialActiveTab = children[0]
			? children[0].props.label
			: children[1]
				? children[1].props.label
				: "";

		this.state = {
			activeTab: initialActiveTab,
			showCalendarSync: false
		};
	}

	componentDidMount() {
		this.setActiveTabFromHash();
	}

	onClickTabItem = tab => {
		if (isServer) return;

		window.location.hash = tab.toLowerCase().replace(/\s+/g, "-"); //eslint-disable-line

		this.setState({
			activeTab: tab
		});
	}

	setActiveTabFromHash = () => {
		const { children } = this.props;

		if (isServer) return;

		const hash = window.location.hash; //eslint-disable-line

		if (!hash) return;

		const hashValue = hash
			.replace("#", "")
			.replace("-", " ")
			.toLowerCase();

		if (hashValue === children[0].props.label) {
			this.setState({
				activeTab: children[0].props.label
			});
		} else if (hashValue === children[1].props.label) {
			this.setState({
				activeTab: children[1].props.label
			});
		}
	}

	render() {
		const {
			children,
			teams,
			years,
			defaultTeam,
			defaultYear,
			updatedFilters,
			isListView,
			showList,
			showStyleSelect
		} = this.props;
		const { activeTab } = this.state;

		return (
			<div className={styles.AllMatchesTabs}>
				<div className={styles.tabHeader} >
					<div className={styles.tabHeaderInner} >
						<ul className={styles.tabList} >
							{children.map(child => {
								if (child) {
									const { label } = child.props;

									return (
										<AllMatchesTab
											activeTab={activeTab}
											key={label}
											label={label}
											onClick={this.onClickTabItem}
										/>
									);
								}
							})}
						</ul>
						<div className={styles.matchFilterDesktop}>
							{teams &&
								<div className={styles.filterWrapper}>
									<MatchFilter
										defaultTeam={defaultTeam}
										teams={teams}
										updatedFilters={updatedFilters}
										autoWidth={true}
									/>
								</div>
							}

							{years && children[1] && activeTab === children[1]?.props?.label &&
								<div className={styles.filterWrapper}>
									<MatchFilter
										defaultYear={defaultYear}
										years={years}
										updatedFilters={updatedFilters}
										autoWidth={true}
									/>
								</div>
							}

							{
								// Buttons to select grid or list display
								showStyleSelect && <div className={styles.viewButtons}>
									<div className={`${styles.icon} ${isListView ? styles.grayOut : ""}`} onClick={() => showList(false)} >
										<MatchGridIcon />
									</div>
									<div className={`${styles.icon} ${!isListView ? styles.grayOut : ""}`} onClick={() => showList(true)} >
										<MatchListIcon />
									</div>
								</div>
							}
						</div>
						{this.state.activeTab === "upcoming schedule" && (
							<div className={this.state.showCalendarSync ? styles.calendarSync : styles.hidden}>
								<CalendarSync url={stanzaUrl} />
							</div>
						)}
					</div>
				</div>

				<div className={styles.matchFilterMobile}>
					{teams &&
						<div className={styles.filterWrapper}>
							<MatchFilter
								defaultTeam={defaultTeam}
								teams={teams}
								updatedFilters={updatedFilters}
							/>
						</div>
					}

					{years && children[1] && activeTab === children[1].props.label &&
						<div className={styles.filterWrapper}>
							<MatchFilter
								defaultYear={defaultYear}
								years={years}
								updatedFilters={updatedFilters}
							/>
						</div>
					}
				</div>

				<div className={styles.tabContent}>
					{children.map(child => {
						if (!child || child.props.label !== activeTab) {
							return undefined;
						}

						return child.props.children;
					})}
				</div>

			</div >
		);
	}
}

export default compose(
	// other hocs goes here
)(AllMatchesTabs);