import React, { PureComponent } from "react";
import { withErrorHandling, withSectionHeader } from "~/hoc";
import { compose } from "recompose";
import CompetitionStandingsTable from "./CompetitionStandingsTable";
import styles from "./CompetitionStandings.module.scss";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import HelperCommentEdit from "~/experience-editor/HelperCommentEdit";


/**
 * Function to determine if the setion header should render.
 * @param {object} props
 */
function isVisible(props) {
	const {rendering: { standingsData }, sitecoreContext: { pageEditing }} = props;

	if (pageEditing) return { visible: true };

	if (!standingsData || !standingsData.stages) return { visible: false };

	return {
		visible: true
	};
}

class CompetitionStandings extends PureComponent {
	render() {
		const {
			rendering: { standingsData },
			sitecoreContext: { pageEditing }
		} = this.props;

		if ((!standingsData || !standingsData.stages) && pageEditing) {
			return <HelperCommentEdit endingEditComment="and select a competition" />;
		}

		if (!standingsData || !standingsData.stages) {
			return <></>;
		}

		return (
			<div className={styles.Standings}>
				{standingsData?.stages?.reverse().map((stage, index) => 
					<CompetitionStandingsTable stage={stage} key={index}/>
				)}
			</div>
		);
	}
}

export default compose(
	// other hocs goes here
	withSitecoreContext(),
	withSectionHeader(isVisible),
	withErrorHandling()
)(CompetitionStandings);