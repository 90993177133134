import React, { PureComponent } from "react";
import styles from "./Hamburger.module.scss";

class Hamburger extends PureComponent {

	handleClick = () => {
		this.props.toggleState();

		document.body.classList.toggle('overflowHidden'); // eslint-disable-line no-restricted-globals
	};

	componentWillUnmount() {
		document.body.classList.remove('overflowHidden'); // eslint-disable-line no-restricted-globals
	}

	render() {
		const { isActive } = this.props;

		return (
			<div className={`${styles.hamburger} ${isActive && styles.isActive}`} onClick={this.handleClick}>
				<div className={styles.hamburgerBox}>
					<div className={styles.hamburgerInner} />
				</div>
			</div>
		);
	}
}

export default Hamburger;