import React, { useState, useEffect } from "react";
import { Placeholder, withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { compose } from "recompose";
import { withErrorHandling } from "~/hoc";
import styles from "./SplitPage.module.scss";

const SplitPage = ({fields, rendering, sitecoreContext: { pageEditing } }) => {
	const leftWidth = `${fields?.leftWidth?.value ? fields?.leftWidth?.value : 50}`;
	const rightWidth = `${100 - leftWidth}`;
	const leftBackground = fields?.leftBackground?.value ? fields.leftBackground.value : "";
	const rightBackground = fields?.rightBackground?.value ? fields.rightBackground.value : "";
	const desktopImage = fields.backgroundImageDesktop?.value?.src;
	const mobileImage = fields.backgroundImageMobile?.value?.src;
	const showMobileOnly = fields.setMobilePresentation.value;
	const showDesktopOnly = fields.setDesktopPresentation.value;
	const backgroundCover = fields.backgroundCover?.value ? `cover` : `contain`;
	const heightPadding = fields.heightPadding?.value ? fields.heightPadding.value : `3em`;

	const windowWidth = useWindowWidth();

	const displayOnlyStyle = (!pageEditing && showDesktopOnly === true && showMobileOnly === false) ? `${styles.displayDesktopOnly}`
		: (!pageEditing && showDesktopOnly === false && showMobileOnly === true) ? `${styles.displayMobileOnly}` : "";

	const leftStyle = {
		background: leftBackground,
		width: `${leftWidth}%`
	};
	const rightStyle = {
		background: rightBackground,
		width: `${rightWidth}%`
	};

	const backgroundUrl = windowWidth
		? windowWidth >= 768
			? desktopImage
			: mobileImage
		: "";

	const authoredStyling = {
		backgroundImage:`url(${backgroundUrl})`,
		backgroundSize: backgroundCover,
		padding: `${heightPadding} 0`
	};

	return (
		<section className={`${styles.splitPageMain} ${displayOnlyStyle}`} style={authoredStyling}>
			<div className={styles.splitPageLeft} style={leftStyle}>
				<Placeholder name="jss-split-page-left"
					rendering={rendering} style={{height: `100%`}}/>
			</div>
			<div className={styles.splitPageRight} style={rightStyle}>
				<Placeholder name="jss-split-page-right"
					rendering={rendering} style={{height: `100%`}}/>
			</div>
		</section>
	);
};

const useWindowWidth = () => {
	const [windowWidth, setWindowWidth] = useState(undefined); // eslint-disable-line

	useEffect(() => {

		const handleWindowResize = () => {
			setWindowWidth(window.innerWidth); // eslint-disable-line
		};

		window.addEventListener('resize', handleWindowResize); // eslint-disable-line

		handleWindowResize();

		return () => window.removeEventListener('resize', handleWindowResize); // eslint-disable-line

	},[]);

	return windowWidth;
};

export default compose(
	// other hocs goes here
	withSitecoreContext(),
	withErrorHandling()
)(SplitPage);