import {
    useQuery,
} from '@tanstack/react-query';
import axios from 'axios';

export const useSanity = ({ groqQuery, variablesQueryString='', queryKey }) => {
    const query = useQuery({
        queryKey, queryFn: async () => {
            const { data: { result } } = await axios.get(`https://oyf3dba6.apicdn.sanity.io/v2021-10-21/data/query/production?query=${groqQuery}&${variablesQueryString}`);
            return result;
        }
    });

    const {
        isError,
        isLoading,
        data
    } = query;

    return {
        isError,
        isLoading,
        data
    }
}