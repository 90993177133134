/* eslint-disable no-restricted-globals */
import React from "react";
import JsonLd from "~/components/JsonLd";
import styles from "./P1Story.module.scss";
import { Link as RouterLink } from "react-router-dom";
import { withSitecoreContext, DateField } from "@sitecore-jss/sitecore-jss-react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import { Image, Text } from "~/patches/components";
import moment from "moment";
import { fieldHasValue, fieldObjHasValue } from "~/utilities";
import { withNamespaces } from "react-i18next";
import 'moment-timezone';

const P1Story = ({ fields, rendering: { story }, sitecoreContext: { route, pageEditing } }) => {
	const trueDate = { value: route.fields.storyDate.value.replace(/Z/g, '') };
	const hasDate = (fieldObjHasValue(route.fields.storyDate, "date"));

	const dataObject = {
		"@context": "http://schema.org",
		"@type": "Article",
		"name": route.fields.storyHeading?.value,
		"author": route.fields.author?.value,
		"image": fields.imageDesktop?.value.src,
		"datePublished": trueDate?.value
	};

	const categoryUrl = story?.categoryUrl;
	const categoryValue = story?.category?.value;

	return (
		<>
			<div className={styles.wrap}>
				<article>
					{fields && (
						<div className={styles.P1Story}>
							<div className={styles.textContainer}>
								{categoryUrl && categoryValue && (
									<div className={styles.categoryWrap}>
										<RouterLink className={styles.category} to={categoryUrl}>
											{categoryValue}
										</RouterLink>
									</div>
								)}
								<Text tag="h1" field={route.fields.storyHeading} />
								<span><Text field={route.fields.subTitle} /></span>
								<div className={styles.meta}>
									{fieldObjHasValue(route.fields.author) && (
										<b>
											By: <Text field={route.fields.author} />
										</b>
									)}
									{(hasDate || pageEditing) && (
										<DateField tag="time" field={trueDate} render={(date) => moment(date)
											.format("MMMM D YYYY")}/>
									)}
								</div>
							</div>

							{/* TODO: advanced image loading */}
							{fieldHasValue(fields, "imageDesktop", "image") &&
								<div className={styles.desktopImageContainer}>
									<Image
										field={fields.imageDesktop}
										srcSet={[{mw: 2560}, {mw: 768}]}
										sizes={"(min-width: 768px) 2560px, 768px"}/>
								</div>
							}
							{fieldHasValue(fields, "imageMobile", "image") &&
								<div className={styles.mobileImageContainer}>
									<Image
										field={fields.imageMobile}
										srcSet={[{mw: 768}, {mw: 440}]}
										sizes={"(min-width: 440px) 768px, 440px"}/>
								</div>
							}

							<JsonLd data={dataObject} />
						</div>
					)}
				</article>
			</div>
		</>
	);
};

export default compose(
	// other hocs goes here
	withNamespaces(),
	withSitecoreContext(),
	withErrorHandling()
)(P1Story);