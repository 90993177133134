import React, { useState, useEffect } from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import { Image } from "~/patches/components";
import { Link as RouterLink } from "react-router-dom";
import { isServer } from "~/utilities";
import styles from "./FiveInterestingThings.module.scss";

const stringNumbers = ["One", "Two", "Three", "Four", "Five"];

const FiveInterestingThings = ({ fields }) => {
	const largeBG = fields.backgroundImageDesktop?.value?.src;
	const mediumBG = fields.backgroundImageTablet?.value?.src;
	const smallBG = fields.backgroundImageMobile?.value?.src;

	const windowWidth = useWindowWidth();

	const backgroundUrl = windowWidth >= 768 //media query sm: 768px
		? windowWidth >= 1440 //media query xl: 1440px
			? largeBG
			: mediumBG
		: smallBG;

	return (
		<>
			{fields && (
				<div className={styles.FiveInterestingThings} style={{ backgroundImage: `url(${backgroundUrl})`}} >
					<div className={styles.badges}>

						{stringNumbers.map(num => (
							<div className={styles.badgeContainer} key={`badge-${num}`}>
								<WithLink link={fields[`link${num}`]}>
									<div className={styles.badge} style={{ backgroundImage: `url(${fields[`useBadgeTwoForThing${num}`]?.value ? fields.badgeOne?.value?.src : fields.badgeTwo?.value?.src})` }} >
										<div className={styles.icon}>
											<Image field={fields[`icon${num}`]} />
										</div>

										<div className={styles.question} >
											{fields[`question${num}`]?.value}
										</div>

										<div className={styles.divider} />

										<div className={styles.answer} >
											{fields[`answer${num}`]?.value}
										</div>
									</div>
								</WithLink>
							</div>
						))}
					</div>
				</div>
			)}
		</>
	);
};

const useWindowWidth = () => {
	const [windowWidth, setWindowWidth] = useState(isServer ? 0 : window.innerWidth); // eslint-disable-line

	const handleWindowResize = () => {
		setWindowWidth(window.innerWidth); // eslint-disable-line
	};

	useEffect(() => {
		window.addEventListener('resize', handleWindowResize); // eslint-disable-line
		return () => window.removeEventListener('resize', handleWindowResize); // eslint-disable-line
	},[]);

	return windowWidth;
};

const WithLink = ({ link, className, children }) => (
	link?.value?.href
		? <RouterLink to={link.value.href} className={className}>
			{children}
		</RouterLink>
		: children
);


export default compose(
	// other hocs goes here
	withErrorHandling()
)(FiveInterestingThings);