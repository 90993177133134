import React, { useEffect, useState } from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import styles from "./WatchHub.module.scss";

const WatchHub = props => {
	const [matchId, setMatchId] = useState('')
	const url = props.fields.url.value;
	const w = typeof window !== 'undefined' && window;
	useEffect(() => {
		const onMatchId = (event) => {
			try {
				const data = JSON.parse(event.data);
				const matchIdFromChild = data?.matchId;
				if (matchIdFromChild) {
					// setMatchId(`/${matchIdFromChild}`)
					const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?matchId=${matchIdFromChild}`;
					console.log('changing URL', newUrl);
					window.history.pushState({
						path: newUrl
					}, '', newUrl);
				}
			} catch (e) {
				return null;
			}
			console.info("Recieved Match ID event", event.data)
		};

		window.addEventListener('message', onMatchId);

		return () => {
			window.removeEventListener('message', onMatchId);
		}
	}, []);
	useEffect(() => {
		const params = (new URL(document.location)).searchParams;
		const matchId = params.get("matchId");
		if (matchId) {
			setMatchId(`/${matchId}`)
		}
		console.log({matchId});
	},
	[])
	return (
		<div>
			<iframe
				src={`${url}${matchId}`}
				allowFullScreen
				allow="encrypted-media"
				className={styles.video}
			>
			</iframe>
		</div>
	);
};

export default compose(
	// other hocs goes here
	withErrorHandling()
)(WatchHub);