import React, { useState, useEffect } from "react";
import { Text, Placeholder, withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { compose } from "recompose";
import { withErrorHandling } from "~/hoc";
import styles from "./Section.module.scss";

const Section = ({ fields, rendering, sitecoreContext: { pageEditing } }) => {
	const backgroundCover = fields.backgroundCover?.value ? `cover` : `contain`;
	const heightPadding = fields.heightPadding?.value ? fields.heightPadding.value : `3em`;
	const desktopImage = fields.backgroundImageDesktop?.value?.src;
	const mobileImage = fields.backgroundImageMobile?.value?.src;

	const windowWidth = useWindowWidth();

	const backgroundUrl = windowWidth
		? windowWidth >= 768
			? desktopImage
			: mobileImage
		: "";

	const authoredStyling = {
		backgroundImage: `url(${backgroundUrl})`,
		backgroundSize: backgroundCover,
		padding: `${heightPadding} 0`
	};

	return (
		<section style={authoredStyling}>
			<Text className={styles.title} field={fields.title} />
			<br/>
			<Placeholder name="jss-section"
				rendering={rendering} style={{ height: `100%` }} />
		</section>
	);
};
const useWindowWidth = () => {
	const [windowWidth, setWindowWidth] = useState(undefined); // eslint-disable-line

	useEffect(() => {

		const handleWindowResize = () => {
			setWindowWidth(window.innerWidth); // eslint-disable-line
		};

		window.addEventListener('resize', handleWindowResize); // eslint-disable-line

		handleWindowResize();

		return () => window.removeEventListener('resize', handleWindowResize); // eslint-disable-line

	},[]);

	return windowWidth;
};

export default compose(
	// other hocs goes here
	withSitecoreContext(),
	withErrorHandling()
)(Section);