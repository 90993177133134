exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".P1Match-module__playerImageContainer--UoJ4Z {\n  display: inline-block;\n  margin-top: 4rem;\n  position: relative; }\n  .P1Match-module__playerImageContainer--UoJ4Z img {\n    z-index: 1; }\n\n.P1Match-module__playerImageContainerHome--1nY7G,\n.P1Match-module__playerImageContainerAway--1No-_ {\n  position: relative;\n  width: 50%; }\n\n.P1Match-module__playerImageContainerHome--1nY7G {\n  -ms-flex-order: 2;\n      order: 2; }\n\n.P1Match-module__playerImageContainerAway--1No-_ {\n  -ms-flex-order: 3;\n      order: 3; }\n\n@media (min-width: 768px) {\n  .P1Match-module__playerImageContainer--UoJ4Z {\n    display: block;\n    margin-top: 2rem;\n    max-width: 36.67rem;\n    width: 100%; }\n    .P1Match-module__playerImageContainer--UoJ4Z img {\n      left: 0;\n      position: absolute; }\n  .P1Match-module__playerImageContainerHome--1nY7G {\n    -ms-flex-order: 1;\n        order: 1; }\n  .P1Match-module__playerImageContainerAway--1No-_ {\n    -ms-flex-order: 2;\n        order: 2; } }\n\n@media (min-width: 1025px) {\n  .P1Match-module__playerImageContainer--UoJ4Z {\n    margin-top: 3rem; } }\n\n@media (min-width: 1240px) {\n  .P1Match-module__playerImageContainer--UoJ4Z {\n    margin-top: 1.67rem; } }\n", ""]);

// exports
exports.locals = {
	"playerImageContainer": "P1Match-module__playerImageContainer--UoJ4Z",
	"playerImageContainerHome": "P1Match-module__playerImageContainerHome--1nY7G P1Match-module__playerImageContainer--UoJ4Z",
	"playerImageContainerAway": "P1Match-module__playerImageContainerAway--1No-_ P1Match-module__playerImageContainer--UoJ4Z"
};