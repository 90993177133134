import React from "react";
import { Text } from "~/patches/components";
import { Arrow } from "~/shared-components/SvgIcons";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import { Link as RouterLink } from "react-router-dom";
import styles from "./LinksBlock.module.scss";

const LinksBlock = ({ fields }) => (
	<>
		{fields && (
			<div className={styles.LinksBlock}>
				<h2>
					<Text field={fields.heading} />
				</h2>
				<ul>
					{fields.links.map((item, index) => (
						<li key={index}>
							<RouterLink to={item.url}>
								<Text field={item.fields.menuTitle} />
								<Arrow />
							</RouterLink>
						</li>
					))}
				</ul>
			</div>
		)}
	</>
);

export default compose(
	// other hocs goes here
	withErrorHandling()
)(LinksBlock);
