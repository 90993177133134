import React, { PureComponent } from "react";
import { compose } from "recompose";
import { withGigya } from "~/hoc";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { isServer } from "~/utilities";


class GigyaScreenSet extends PureComponent {
	constructor(props) {
		super(props);
		this.domNodeRef = React.createRef();
		this.state = {
			divId:`gigya-screenset-host-${Math.random().toString(36).substring(7)}` // eslint-disable-line newline-per-chained-call
		};
	}

	componentDidMount = () => {
		this.mountScreenSet();
	}
	
	componentDidUpdate = prevProps => {
		const { gigya: prevGigya } = prevProps;
		const { gigya } = this.props;

		if (prevGigya !== gigya) {
			this.mountScreenSet();
		}
	}

	updateDomNodeLocator = () => {
		if (this.props.domNodeLocator && typeof this.props.domNodeLocator === "function") {
			this.props.domNodeLocator(this.domNodeRef.current);
		}
	}

	mountScreenSet = () => {
		const { gigya, screenSetId, startScreenId, handlers, sitecoreContext: { pageEditing }} = this.props;

		if (!pageEditing && !gigya.isLoading) {
			gigya.mountScreenSet(screenSetId, startScreenId, this.state.divId, {...handlers, onAfterScreenLoad: this.updateDomNodeLocator});
		}
	}

	render() {
		return isServer ? <></> : <div ref={this.domNodeRef} id={this.state.divId} />;
	}
}

export default compose(
	withSitecoreContext(),
	withGigya(),
)(GigyaScreenSet);