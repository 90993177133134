import React from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import { withNamespaces } from "react-i18next";
import HelperCommentEdit from "~/experience-editor/HelperCommentEdit";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import SectionLabel from "~/shared-components/SectionLabel";
import LineupThumbnail from "./LineupThumbnail";
import styles from "./LineupsGrid.module.scss";

// Endpoints:
// Matches by Year:  GET: /api/players/matches/{contestantId}/{year}
// Lineup By Match:  GET: /api/players/lineup/{contestantId}/{matchId}

const LineupsGrid = ({
	fields,
	rendering: { lineupsGrid },
	sitecoreContext: { pageEditing },
	t
}) => {
	
	const starters = lineupsGrid.lineup?.players.filter(player => player.position !== "Substitute");
	const subs = lineupsGrid.lineup?.players.filter(player => player.position === "Substitute");

	return (
		<>
			{!lineupsGrid && pageEditing && (
				<HelperCommentEdit />
			)}
			{lineupsGrid && (
				<section className={styles.LineupsGrid}>
					{fields.sectionLabel &&
						<div className={styles.sectionLabelOuter}>
							<div className={styles.sectionLabelContainer}>
								<SectionLabel sectionLabel={fields.sectionLabel} />
							</div>
						</div>
					}
					<div className={styles.outer}>
						<h2 className={styles.header}>{t("lineupsgrid_starters")}</h2>
						<div className={styles.inner}>
							<div className={styles.lineupContainer}>
								{starters?.length > 0 && starters.map((player, index) =>
									<div className={styles.thumbnailWrapper} key={index}>
										<LineupThumbnail player={player} />
									</div>
								)}
							</div>
						</div>
					</div>

					<div className={styles.outer}>
						<h2 className={styles.header}>{t("lineupsgrid_substitutes")}</h2>
						<div className={styles.inner}>
							<div className={styles.lineupContainer}>
								{subs?.length > 0 && subs.map((player, index) =>
									<div className={styles.thumbnailWrapper} key={index}>
										<LineupThumbnail player={player} />
									</div>
								)}
							</div>
						</div>
					</div>
				</section>
			)}
		</>
	);
};

export default compose(
	// other hocs goes here
	withNamespaces(),
	withSitecoreContext(),
	withErrorHandling()
)(LineupsGrid);