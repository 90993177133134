import React, { Fragment } from "react";
import Loadable from 'react-loadable';
import PropTypes from "prop-types";
import { isServer } from "~/utilities";
import "flickity/css/flickity.css";
import "./flickity-overrides.scss";

const CarouselMarkup = ({children}) => (
	<>
		{children}
	</>
);

const Flickity = Loadable({
	// For some reason, webpackChunkName doesn't work...
	loader: () => import("react-flickity-component"/* webpackChunkName: "react-flickity-component" */),
	// this is a react component shown while lazy loading. See the react-loadable docs for guidance on making a good one.
	// eslint-disable-next-line react/display-name
	loading: (props) => <CarouselMarkup {...props} />
});

const Carousel = (props) => (
	<Fragment>
		{/* Flickity uses the DOM (window etc.), does not work server side! */}
		{!isServer ? (
			<Flickity
				className="flickity"
				options={{
					...props,
					arrowShape: "M67.1,100c-0.8,0-1.6-0.4-2.1-1.1L31.7,51.5c-0.6-0.9-0.6-2.1,0-2.9L65,1.1c0.8-1.2,2.4-1.4,3.6-0.6 C69.8,1.3,70,2.9,69.2,4L36.9,50l32.3,46c0.8,1.2,0.5,2.8-0.6,3.6C68.2,99.8,67.6,100,67.1,100z",
					// eslint-disable-next-line no-restricted-globals
					draggable: "ontouchstart" in window, // Carousel is only draggable if the device has a touch screen
					dragThreshold: 10,
					friction: .5,
					groupCell: true,
					selectedAttraction: .05,
				}}>
				{props.children}
			</Flickity>
			
		) : (
			<CarouselMarkup {...props} />
		)}
	</Fragment>
);


const CarouselRedesign = (props) => (
	<Fragment>
		{/* Flickity uses the DOM (window etc.), does not work server side! */}
		{!isServer ? (
			<Flickity
				className="flickityRedesign"
				options={{
					...props,
					arrowShape: "M67.1,100c-0.8,0-1.6-0.4-2.1-1.1L31.7,51.5c-0.6-0.9-0.6-2.1,0-2.9L65,1.1c0.8-1.2,2.4-1.4,3.6-0.6 C69.8,1.3,70,2.9,69.2,4L36.9,50l32.3,46c0.8,1.2,0.5,2.8-0.6,3.6C68.2,99.8,67.6,100,67.1,100z",
					// eslint-disable-next-line no-restricted-globals
					draggable: "ontouchstart" in window ? "touchstart" : "mousedown", //Draggable on desktop and mobile
					dragThreshold: 10,
					friction: .5,
					groupCell: 1,
					selectedAttraction: .05,
					contain: false,
					wrapAround:true,
					initialIndex: 1
				}}>
				{props.children}
			</Flickity>
			
		) : (
			<CarouselMarkup {...props} />
		)}
	</Fragment>
);
Carousel.propTypes = {
	cellAlign: PropTypes.string,
	groupCells: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.bool
	]),
	percentPosition: PropTypes.bool,
	wrapAround: PropTypes.bool,
};

Carousel.defaultProps = {
	cellAlign: "left", // Consider changing this to "center" if groupCell is set to false
	groupCells: "115%", // A bit more than 100% to accommodate for item positions with decimals
	percentPosition: false,
	wrapAround: false,
};

CarouselRedesign.propTypes = {
	cellAlign: PropTypes.string,
	groupCells: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.bool
	]),
	percentPosition: PropTypes.bool,
	wrapAround: PropTypes.bool,
};
CarouselRedesign.defaultProps = {
	cellAlign: "left", // Consider changing this to "center" if groupCell is set to false
	groupCells: false, // A bit more than 100% to accommodate for item positions with decimals
	percentPosition: false,
	wrapAround: false,
};

export default Carousel;
export {CarouselRedesign};