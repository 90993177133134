import React, { useEffect } from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import { brightcoveExperienceUrl } from "~/utilities/constants";
import styles from "./RedesignBrightcoveFilmstrip.module.scss";


const BrightcoveFilmstrip = ({ fields, params }) => {

	useEffect(() => {
		if (typeof document !== 'undefined'
			&& fields?.bcExperienceId?.value
			&& fields.bcExperienceId.value !== '') {
			const brightcoveUrl = `${brightcoveExperienceUrl}/experience_${fields.bcExperienceId.value}/live.js`;

			const head = document.getElementsByTagName('head')[0];
			const script = document.createElement('script');
			script.type = 'text/javascript';
			script.src = brightcoveUrl;

			head.appendChild(script);
		}
	}, []);

	return (
		<>
			{fields && (
				<div className={styles.BrightcoveFilmstripContainer}>
					<div className={styles.FilmStrip}>
					<div data-experience={fields.bcExperienceId.value}></div>
					</div>
				</div>
			)}
		</>
	);
};

export default compose(
	// other hocs goes here
	withErrorHandling()
)(BrightcoveFilmstrip);