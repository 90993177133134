import React from "react";
import { Image, RichText, Text } from "~/patches/components";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import ButtonRedBorder from "~/shared-components/ButtonRedBorder";
import styles from "./PerkCard.module.scss";

const PerkCard = ({ fields, headingColor }) => (
	<>
		{fields && (
			<div className={styles.PerkCard}>
				<div className={`${styles.hero} ${headingColor ? styles[`${headingColor}`] : ""}`}>
					<Text tag="h3" field={fields.heading} />
					<Image
						field={fields.image}
						srcSet={[{ mw: 768 }, { mw: 440 }]}
						sizes={"(min-width: 440px) 768px, 440px"}
					/>
				</div>

				<div className={styles.info}>
					<div className={styles.headingContainer}>
						<Text tag="h4" field={fields.rate} />
					</div>
					<div className={styles.textContainer}>
						<RichText tag="p" field={fields.description} />
					</div>
					<ButtonRedBorder field={fields.link} />
				</div>
			</div>
		)}
	</>
);

export default compose(
	// other hocs goes here
	withErrorHandling()
)(PerkCard);