import React from "react";
import { Text } from "~/patches/components";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";

const GalleriesTemplate = ({ fields }) => (
	<>
		{fields && (
			<div>
				<p>GalleriesTemplate Component</p>
				<Text field={fields.heading} />
			</div>
		)}
	</>
);

export default compose(
	// other hocs goes here
	withErrorHandling()
)(GalleriesTemplate);