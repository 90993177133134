import React from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import styles from "./ExplicitGrid.module.scss";

const ExplicitGrid = ({ fields, rendering }) => {
	const colNumber = `${fields?.columnNumber?.value ? fields.columnNumber.value : 3}`;
	const colWidth = `minmax(195px, 450px)`;
	
	const explicitColumns = {
		gridTemplateColumns: `repeat(${colNumber}, ${colWidth})`
	};

	return (
		<div className={styles.grid}>
			<div className={styles.explicitGrid} style={explicitColumns}>
				<Placeholder name="jss-layout-grid-explicit" rendering={rendering}/>
			</div>
		</div>
	);
};
export default compose(
	// other hocs goes here
	withErrorHandling()
)(ExplicitGrid);