import React, { PureComponent } from "react";
import { compose } from "recompose";
import { Link as RouterLink, withRouter } from "react-router-dom";
import { Link } from "~/patches/components";
import { withErrorHandling, withGigya } from "~/hoc";
import { withNamespaces } from "react-i18next";
import { optaApi, validDateTimeFormat, USTeamNames } from "~/utilities/constants";
import { fieldObjHasValue } from "~/utilities";
import { DateField, withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import AddToCalendar from "react-add-to-calendar";
import moment from "moment";
import 'moment-timezone';
import styles from "./MatchButtonsRedesign.module.scss";

class MatchButtonsRedesign extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			showLinks: false
		};
	}

	componentDidMount() {
		const { location, event } = this.props;

		if (!location.pathname.includes(event.matchFeedUrl)) {
			this.setState({
				showLinks: true
			});
		}
	}

	calendarTitle = () => {
		const { event: { contestants, description } } = this.props;
		const USTeam = contestants.filter(contestant => contestant.code === "USA")[0];
		const USTeamId = USTeam?.id;
		const USTeamName = USTeamNames[USTeamId];

		//if not USWNT or USMNT then return description as is (for youth teams)
		return description
			? USTeamName
				? description.replace("United States", USTeamName)
				: description
			: "";
	}

	render() {
		const {
			event,
			pageEditing,
			t,
			type,
			eventType,
			customCalendarEvent,
			gigya: { isLoggedIn }
		} = this.props;

		const { showLinks } = this.state;

		return (
			<div className={`${type === "card" ? "" : styles.p1Buttons} ${(event.status === optaApi.matchStatus.live && eventType !== "competition") ? styles.MatchButtonsLive : styles.MatchButtons}`}>
				{fieldObjHasValue(event.sitecoreData?.presaleTicketsDate, "date") &&
					moment(event.sitecoreData?.presaleTicketsDate.value).diff(moment(), "days") > 0 &&
					moment(event.date).diff(moment(), "days") > 0 &&
					eventType !== "competition" &&
					type === "card" ||
					pageEditing
					? <DateField field={event?.sitecoreData?.presaleTicketsDate} render={(date) => (
						<>
							{!isLoggedIn &&
								<p className={styles.presaleText}>
									{t("match-card_presale-access")}&nbsp;<RouterLink to={"/insiders"}>{t("match-card_presale-find-out")}</RouterLink>
								</p>}
							<div className={styles.presaleTickets}>
								{false &&
									<AddToCalendar
										event={customCalendarEvent || {
											title: this.calendarTitle(),
											description: "",
											location: `${event.venue.shortName} ${event.venue.location}, ${event.venue.country}`,
											startTime: event.dateTime,
											endTime: moment(event.dateTime).add(2, "hours")
										}}
										listItems={[
											{ apple: "Apple" },
											{ outlook: "Outlook" },
											{ google: "Google" }
										]}
										buttonLabel={!isLoggedIn
											? t("match-card_tickets-available").replace("{date}", moment(date)
												.tz(moment.tz.guess())
												.format("MMM D YYYY"))
											: t("match-card_presale-available").replace("{date}", moment(date)
												.subtract(7, "days")
												.tz(moment.tz.guess())
												.format("MMM D YYYY"))}
									/>
								}
							</div>
						</>
					)} />
					: <>
						{eventType === "competition" ? (
							<CompetitionButtons {...this.props} />
						) : (
							<DefaultButtons {...this.props} showLinks={showLinks} calendarTitle={this.calendarTitle} />
						)}
					</>
				}
			</div>
		);
	}
}

const CompetitionButtons = ({ event, type, t }) => (
	<>
		{/* If the event is a competition, only display a link to the detail page */}
		{event.previewLink &&
			(event.status === optaApi.matchStatus.upcoming || event.status === optaApi.matchStatus.live) &&
			type === "card" &&
			<div>
				<RouterLink to={event.previewLink.href}>
					<>{t("competition-card_view-competition")}</>
				</RouterLink>
			</div>
		}
		{event.fullRecapLink &&
			event.status === optaApi.matchStatus.completed &&
			type === "card" &&
			<div>
				<RouterLink to={event.fullRecapLink.href}>
					<>{t("competition-card_competition-recap")}</>
				</RouterLink>
			</div>
		}
	</>
);

const DefaultButtons = ({
	event,
	t,
	sitecoreContext,
	showLinks,
	customCalendarEvent,
	calendarTitle,
	isListView
}) => (
	<>
		{/* If event is upcoming show CALENDAR BUTTON */}
		{event.status === optaApi.matchStatus.upcoming && false &&
			<div className={styles.calendarButton}>
				<AddToCalendar
					event={customCalendarEvent || {
						title: calendarTitle(),
						description: "",
						location: `${event.venue.shortName} ${event.venue.location}, ${event.venue.country}`,
						startTime: event.dateTime,
						endTime: moment(event.dateTime, validDateTimeFormat, true).add(2, "hours")
					}}
					listItems={[
						{ apple: "Apple" },
						{ outlook: "Outlook" },
						{ google: "Google" }
					]}
				/>
			</div>
		}

		{/* Story Preview Link - visible on the match page and before the game has started  */}
		{sitecoreContext?.itemId === event.sitecoreData?.currentItemId &&
			event?.status === optaApi.matchStatus.upcoming &&
			fieldObjHasValue(event?.sitecoreData?.matchPreviewLink, "link") && (
				<Link field={event.sitecoreData.matchPreviewLink}>{t("match-card_match-preview")}</Link>
			)}

		{/* Story Recap Link - visible on the match page and the match is completed  */}
		{(sitecoreContext?.itemId === event.sitecoreData?.currentItemId || isListView) &&
			event?.status === optaApi.matchStatus.completed &&
			fieldObjHasValue(event?.sitecoreData?.matchRecapLink, "link") && (
			<Link field={event.sitecoreData.matchRecapLink}>{t("match-card_recap")}</Link>
		)}

		{/* Presale tickets */}
		{new Date(event.sitecoreData?.presaleTicketsDate?.value) < Date.now() &&
			new Date(event.sitecoreData?.endDate?.value) > Date.now() &&
			<div>
				{event.sitecoreData.ticketsLink && (
					<RouterLink to={event.sitecoreData.ticketsLink}>
						<>{t("match-card_buy-tickets")}</>
					</RouterLink>
				)}
			</div>
		}

		{/* Preview  */}
		{event.matchFeedUrl &&
			event.status === optaApi.matchStatus.upcoming &&
			showLinks &&
			<div>
				<RouterLink to={event.matchFeedUrl}>
					{t("match-card_hub")}
				</RouterLink>
			</div>
		}

		{/* Live Match Feed */}
		{event.matchFeedUrl &&
			event.status === optaApi.matchStatus.live &&
			showLinks &&
			<div>
				<RouterLink to={event.matchFeedUrl}>
					<>{t("match-card_live-match-feed")}</>
				</RouterLink>
			</div>
		}

		{/* Recap link w/ matchFeedUrl */}
		{(event.matchFeedUrl || event.fullRecapLink) &&
			event.status === optaApi.matchStatus.completed &&
			showLinks &&
			<div>
				<RouterLink to={(event.matchFeedUrl || event.fullRecapLink.href)}>
					<>{t("match-card_full-match-recap")}</>
				</RouterLink>
			</div>
		}

		{/* MATCH CARD */}
		{/* Buy match tickets links */}
		{event.sitecoreData?.ticketsLink?.value?.href &&
			event.status === optaApi.matchStatus?.upcoming &&
			<div>
				<a href={event.sitecoreData.ticketsLink.value.href} target={event.sitecoreData.ticketsLink.value.target} >
					<>{t("match-card_buy-tickets")}</>
				</a>
			</div>
		}
	</>
);

export default compose(
	withGigya(),
	withNamespaces(),
	withRouter,
	withSitecoreContext(),
	withErrorHandling()
)(MatchButtonsRedesign);