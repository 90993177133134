import React from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import styles from "./Heading.module.scss";
import { Arrow } from "~/shared-components/SvgIcons";

const Heading = props => {
	const {
		heading,
		buttonName,
		showButton,
		count,
		type,
		handlerActiveResultTypeChange,
		activeResultType,
		handlerActiveResultTextChange} = props;

	const text = `${heading} (${count || 0})`;

	return (
		<h4 className={styles.container}>
			{`${heading} (${count})`}
			{showButton && activeResultType !== type && (
				<button onClick={() => {
					handlerActiveResultTypeChange(type);
					handlerActiveResultTextChange(text);}}>
					{buttonName}<Arrow/>
				</button>
			)}
		</h4>
	);
};

export default compose(
	// other hocs goes here
	withErrorHandling()
)(Heading);