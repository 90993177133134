exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ProfileSidebarNav-module__container--UlZRk {\n  width: 100%; }\n  .pageEditing .ProfileSidebarNav-module__container--UlZRk {\n    min-height: 17rem; }\n\n.ProfileSidebarNav-module__ad--3ul4c {\n  display: none; }\n\n@media (min-width: 1240px) {\n  .ad-loaded .ProfileSidebarNav-module__adContainer--3brsL .ProfileSidebarNav-module__ad--3ul4c {\n    display: block;\n    margin: 2rem auto;\n    width: 300px; } }\n", ""]);

// exports
exports.locals = {
	"container": "ProfileSidebarNav-module__container--UlZRk",
	"ad": "ProfileSidebarNav-module__ad--3ul4c",
	"adContainer": "ProfileSidebarNav-module__adContainer--3brsL"
};