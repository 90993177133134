import React, { Component, Fragment } from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import { TableArrow } from "~/shared-components/SvgIcons";
import styles from "./Table.module.scss";

class Table extends Component {
	constructor(props) {
		super(props);
		const { data } = this.props;

		this.tableElmRef = React.createRef();
		this.tableWrapperElmRef = React.createRef();
		this.state = {
			slideAmount: 0,
			currentSort: data,
			ascending: false,
			boundingWidth: 0
		};
	}

	_boundingWidth = 0;

	handleSlide = direction => {
		const tableWidth = this.tableElmRef.current.getBoundingClientRect().width;
		const tableWrapperWidth = this.tableWrapperElmRef.current.getBoundingClientRect().width;
		this.setState(prevState => ({
			slideAmount: (prevState.slideAmount + (direction === "left" ? 200 : -200)) > 0 
				? 0 
				: (prevState.slideAmount + (direction === "left" ? 200 : -200)) > -Math.abs(tableWidth - tableWrapperWidth) ? (prevState.slideAmount + (direction === "left" ? 200 : -200)) : -Math.abs(tableWidth - tableWrapperWidth)
		}));

		this._boundingWidth = -Math.abs(tableWidth - tableWrapperWidth);
	}

	setSort = attribute => {
		if (this.state.currentSort === attribute) {
			this.setState(prevState => ({ ascending: !prevState.ascending, currentSort: attribute }));
		}
		else {
			this.setState({ ascending: true, currentSort: attribute });
		}
	}

	getSortedData = () => {
		//sort data by the attribue in the direction you want it go 
		const { data } = this.props;
		const sortedData = [...data]; // sort works in place even though it returns the original array
		const { currentSort } = this.state;

		if (this.props.tableType === "players" && currentSort === "player") {
			sortedData.sort((a, b) => (a.player.name > b.player.name) ? 1 : (a.player.name < b.player.name ? -1 : 0));
		} else {
			sortedData.sort((a, b) => (a[currentSort] > b[currentSort]) ? 1 : (a[currentSort] < b[currentSort] || a[currentSort] === "--" ? -1 : 0));
		}
	
		if (!this.state.ascending) {
			sortedData.reverse();
		}

		return sortedData;
	};

	render() {

		const { data, heading, tableType } = this.props;
		const { slideAmount, currentSort, ascending } = this.state;
		const sortedData = this.getSortedData();
		const headings = sortedData.length > 0 ? Object.keys(sortedData[0]) : data;

		return (
			<Fragment>
				{sortedData.length > 0 && 
					<div className={styles.Table}>
						<div className={styles.headerContainer}>
							{heading}
						</div>

						<div className={styles.tableContainer}>
							<div className={`${styles.leftArrow} ${slideAmount === 0 ? styles.hide : ""}`} onClick={() => this.handleSlide("left")}>
								<TableArrow />
							</div>

							<div className={styles.tableWrapper} ref={this.tableWrapperElmRef}>
								<div className={styles[`leftEllipse-${tableType}`]}></div>

								<table className={`${styles.myTable} ${`table-${tableType}`}`} ref={this.tableElmRef}>
									<thead>
										<tr>
											<th onClick={() => this.setSort(headings[0])}>
												<div className={`
													${styles[`firstCellWidth-${tableType}`]} 
													${(currentSort === headings[0] && !ascending) ? styles.descending : ""}`}>
													<div className={currentSort === headings[0] ? styles.thContainer : ""}>{headings[0].replace(/([A-Z])/g, " $1")}</div>
												</div>
											</th>

											{headings.slice(1).map((heading, index) => (
												<th 
													onClick={() => this.setSort(heading)}
													className={index === 0 ? styles[`secondCellPadding-${tableType}`] : ""}
													key={index} 
													style={{ transform: `translateX(${slideAmount}px)` }} >
													<div className={`${(currentSort === heading && !ascending) ? styles.descending : ""}`}>
														<div className={currentSort === heading ? styles.thContainer : ""}>{heading.replace(/([A-Z])/g, " $1")}</div>
													</div>
												</th>
											))}
										</tr>
									</thead>

									<tbody>
										{sortedData.map((d, index) => (
											<tr className={styles.statsRow} key={index}>
												{tableType === "players" ?
													<td>
														<div className={styles[`firstCellWidth-${tableType}`]}>
															<img
																className={styles.playerImage}
																src={d[headings[0]].image}
																alt="Player headshot"
																title={d[headings[0]].name} />
															<span>{d[headings[0]].name}</span>
														</div>
													</td>
													:
													<td>
														<div className={styles[`firstCellWidth-${tableType}`]}>{d[headings[0]]}</div>
													</td>
												}

												{Object
													.values(d)
													.slice(1)
													.map((stat, index) =>
														<td className={index === 0 ? styles[`secondCellPadding-${tableType}`] : ""} style={{ transform: `translate3d(${slideAmount}px, 0, 0)` }} key={index}>
															{(tableType === "matches" && stat.constructor === Array)
																? <div className={styles.scorers} style={{ width: `calc(${stat.length} * 48px)` }}>
																	{stat.map((s, index) => (
																		<Fragment key={index}>
																			{(s?.sitecoreData?.thumbnail?.value?.src && s?.firstName && s?.lastName && s?.goals) &&
																				<div className={styles.scorer} key={`scorer-${index}`}>
																					<img
																						src={s.sitecoreData.thumbnail.value.src}
																						alt={`${s.firstName} ${s.lastName}`}
																						title={`${s.firstName} ${s.lastName}`} />
																					<div>x{s.goals}</div>
																				</div>
																			}
																		</Fragment>
																	))}
																</div>
																: stat
															}
														</td>
													)
												}
											</tr>
										))}
									</tbody>
								</table>

								<div className={styles.rightEllipse}></div>
							</div>

							<div className={`${styles.rightArrow} ${((slideAmount < 0) && (slideAmount === this._boundingWidth)) ? styles.hide : ""}`} onClick={() => this.handleSlide("right")}>
								<TableArrow />
							</div>
						</div>
					</div>
				}
			</Fragment>
		);
	}
}

export default compose(
	// other hocs goes here
	withErrorHandling()
)(Table);