exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".TicketOptions-module__TicketOptions--16ZDh {\n  overflow: hidden;\n  padding: 0 2rem; }\n  .TicketOptions-module__TicketOptions--16ZDh h3 {\n    color: #212844;\n    font-size: 2.5rem;\n    font-weight: 700;\n    letter-spacing: 0.05em;\n    line-height: 1.12;\n    margin: 3rem 0;\n    text-transform: uppercase; }\n\n@media (min-width: 1025px) {\n  .TicketOptions-module__TicketOptions--16ZDh {\n    padding: 0 8.75rem; }\n    .TicketOptions-module__TicketOptions--16ZDh h3 {\n      font-size: 2.083rem;\n      margin: 5.833rem 0; }\n  .TicketOptions-module__container--3lDX4 {\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-wrap: wrap;\n        flex-wrap: wrap;\n    -ms-flex-pack: start;\n        justify-content: flex-start;\n    margin: 0 -1.083rem; } }\n", ""]);

// exports
exports.locals = {
	"TicketOptions": "TicketOptions-module__TicketOptions--16ZDh",
	"container": "TicketOptions-module__container--3lDX4"
};