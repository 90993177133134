/* eslint-disable */
import React from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import Table from "~/shared-components/Table";
import styles from "./TableSeasonRecord.module.scss";


const formatDataForTable = data => {
	const keys = ["yearTotal", "home", "away", "friendlies", "competitions"];
	const formattedData = [];

	keys.map(k => {
		const match = data[k];

		formattedData.push({
			matches: k === "yearTotal" ? data.year.toString() : k,
			wins: match.wins,
			draws: match.draws,
			losses: match.losses,
			goals: match.goalsScored,
			conceded: match.goalsConceded,
			shots: match.totalShots,
			shotsOnTarget: match.shotsOnTarget,
			shotsConverted: Math.round(match.goalsScored/match.totalShots) && match.totalShots > 0 ? Math.round(match.goalsScored/match.totalShots) + "%" : "--",
			cleanSheets: match.cleanSheets
		})
	})

	return formattedData;
}

const TableSeasonRecord = ({ heading, seasonRecord }) => (
	<div className={styles.TableSeasonRecord}>
		{seasonRecord && 
			<Table
				data={formatDataForTable(seasonRecord)}
				heading={heading}
				tableType={"seasonRecord"} />
		}
	</div>
);

export default compose(
	// other hocs goes here
	withErrorHandling()
)(TableSeasonRecord);