import React, { Component } from "react";
import { Text } from "~/patches/components";
import { Link as RouterLink } from "react-router-dom";
import { MembershipService } from "~/services/membershipService";
import { withErrorHandling, withGigya } from "~/hoc";
import { compose } from "recompose";
import { withNamespaces } from "react-i18next";
import { Arrow } from "~/shared-components/SvgIcons";
import moment from "moment";
import styles from "./UserPresaleCodes.module.scss";
import "moment-timezone";

class UserPresaleCodes extends Component {
	constructor(props) {
		super(props);

		this.state = {
			presaleCodes: []
		};
	}

	getJWT = async () => new Promise((res, rej) =>
		this.props.gigya.getJWT(
			gigyaRes => gigyaRes.errorCode
				? rej(gigyaRes.errorMessage)
				: res(gigyaRes.id_token)
	)).catch(err => console.log("1: ",err)); //eslint-disable-line

	getPresaleCode = async (currentTier) => {
		const jwt = await this.getJWT();
		MembershipService.getMembershipPresaleCode(jwt, currentTier)
			.then(response => {
				this.setState({presaleCodes: response});
			});
	}

	getMembershipUpgrade = async (currentTier) => {
		MembershipService
			.getMembershipBannerUpgrade(currentTier)
			.then(response => this.setState({upgradeMembership: response}));
	}

	componentDidMount = () => {
		const { gigya: { data } } = this.props;
		const currentTier = data?.membershipTier;
		if (currentTier >= 0) {
			this.getPresaleCode(currentTier);
			this.getMembershipUpgrade(currentTier);
		}
	}

	render() {
		const {
			gigya,
			t,
			fields
		} = this.props;

		const {
			presaleCodes,
			upgradeMembership
		} = this.state;

		return (
			<>
				{ presaleCodes && (presaleCodes.length > 0) && gigya.isLoggedIn &&
					<div className={styles.container}>

						{ fields && <Text tag="h2" field={fields.heading} className={styles.presaleHeading}/> }

						{ presaleCodes?.map((presaleMatch, i) => (
							<div key={i} className={styles.presaleContainer}>
								<div className={styles.presaleCode}>
									<h3>{`${t("userpresalecodes__presale_code_label")}: `}<span>{presaleMatch.code}</span></h3>
									<p><strong>{`${t("userpresalecodes__start_date_label")}: `}</strong>{moment.utc(presaleMatch.startDate).tz(moment.tz.guess()).format('ddd, MMM DD | h:mm A')} {moment(presaleMatch.startDate).tz(moment.tz.guess()).zoneAbbr()}</p>
									<p><strong>{`${t("userpresalecodes__end_date_label")}: `}</strong> {moment.utc(presaleMatch.endDate).tz(moment.tz.guess()).format('ddd, MMM DD | h:mm A')} {moment(presaleMatch.endDate).tz(moment.tz.guess()).zoneAbbr()}</p>
									<button className={styles.ButtonRedBorder} onClick={() =>  navigator.clipboard.writeText(presaleMatch.code)}>{t("userpresalecodes__copy_presale_code_button")} &quot;{presaleMatch.code}&quot;</button>
								</div>

								<div className={styles.matchInfo}>
									<h4>{presaleMatch.contestant1} VS {presaleMatch.contestant2}</h4>
									<p>{moment.utc(presaleMatch.date).tz(moment.tz.guess()).format('MMM DD')} | {presaleMatch.venueLocation}, {presaleMatch.venueCountry} - {presaleMatch.venueName}</p>

									<div className={styles.groupTickets}>
										{ presaleMatch.ticketUrl &&
												<a href={presaleMatch.ticketUrl} target={presaleMatch.ticketUrl} >
													<>{t("userpresalecodes__buy_ticket_button")}</>
												</a>
										}
										{ presaleMatch.groupTicketUrl &&
											<a href={presaleMatch.groupTicketUrl} target={presaleMatch.groupTicketUrl} >
												<>{t("userpresalecodes__buy_group_ticket_button")}</>
											</a>
										}
									</div>

									{ presaleMatch.disclaimerText &&
										<p className={styles.disclaimerText}>{presaleMatch.disclaimerText}</p>
									}
								</div>
							</div>
						))}

						{ upgradeMembership?.learnMoreCta &&
							<div className={styles.presaleUpgrade}>
								<p>{t("userpresalecodes__upgrade_text")}<RouterLink to={upgradeMembership.learnMoreCta}>{t("membershipbanner__upgrade_label")}<Arrow/></RouterLink></p>
							</div>
						}

					</div>}
			</>
		);
	}
}

export default compose(
	withGigya(),
	withNamespaces(),
	withErrorHandling()
)(UserPresaleCodes);