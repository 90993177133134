exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".TextBlock-module__container--2Bp9C {\n  color: #4d4d4d;\n  font-family: \"USSF90MinText\", helvetica, arial, sans-serif;\n  font-size: 1.6rem;\n  letter-spacing: 0.03rem;\n  line-height: 1.5;\n  margin: 0 auto;\n  max-width: 1440px;\n  padding: 0 20px; }\n  .TextBlock-module__container--2Bp9C .TextBlock-module__heading--1LFFQ {\n    color: #15192B;\n    font-size: 2.5rem;\n    font-weight: 700;\n    margin: 0 0 2rem 0;\n    text-transform: uppercase; }\n\n.TextBlock-module__centerTextBlock--NA3dn {\n  -ms-flex-align: center;\n      align-items: center;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: center;\n      justify-content: center; }\n\n.TextBlock-module__wrapper--Ei5sk.TextBlock-module__withGrayGradientBg--1csXC {\n  background: -webkit-gradient(linear, left top, left bottom, from(#F7F7F7), to(#FFFFFF));\n  background: -o-linear-gradient(top, #F7F7F7 0%, #FFFFFF 100%);\n  background: linear-gradient(180deg, #F7F7F7 0%, #FFFFFF 100%);\n  padding-top: calc(2.31481vw + 26.66667px); }\n  @media (max-width: 576px) {\n    .TextBlock-module__wrapper--Ei5sk.TextBlock-module__withGrayGradientBg--1csXC {\n      padding-top: 40px; } }\n  @media (min-width: 1440px) {\n    .TextBlock-module__wrapper--Ei5sk.TextBlock-module__withGrayGradientBg--1csXC {\n      padding-top: 60px; } }\n\n.TextBlock-module__wrapper--Ei5sk.TextBlock-module__transparent--XkAwr {\n  background-color: rgba(0, 0, 0, 0); }\n\n.TextBlock-module__wrapper--Ei5sk .TextBlock-module__container--2Bp9C {\n  max-width: 68.333rem; }\n  @media screen and (max-width: 1239px) {\n    .TextBlock-module__wrapper--Ei5sk .TextBlock-module__container--2Bp9C {\n      max-width: 100%;\n      padding: 0 12px !important; } }\n\n@media (min-width: 1025px) {\n  .TextBlock-module__container--2Bp9C {\n    font-size: 1.334rem;\n    letter-spacing: 0.02rem; } }\n", ""]);

// exports
exports.locals = {
	"container": "TextBlock-module__container--2Bp9C",
	"heading": "TextBlock-module__heading--1LFFQ",
	"centerTextBlock": "TextBlock-module__centerTextBlock--NA3dn",
	"wrapper": "TextBlock-module__wrapper--Ei5sk",
	"withGrayGradientBg": "TextBlock-module__withGrayGradientBg--1csXC",
	"transparent": "TextBlock-module__transparent--XkAwr"
};