import { sha256 } from 'js-sha256';
import utf8 from 'utf8';

import { getEnv } from "~/utilities/helpers";

export class BrazeService {
	static changeUser = (email, gigyaID) => {
		const env = getEnv();

		// If not an env we care about (QA or PROD), just return.
		if (!env) {
			return;
		}

		// Braze SDK gets initialized in GTM. Will be sitting globally on the window.
		// Still doesn't hurt to check.
		if (window.appboy) {
			try {
				const braze = window.appboy;
				const hashedUserEmail = sha256.hex(utf8.encode(email.toLowerCase())).toLowerCase();

				braze.changeUser(hashedUserEmail);
				braze.getUser().addAlias(gigyaID, gigyaID);
			} catch (err) {
				console.error(`Error calling Braze SDK change user: ${err.message}`);
			}
		}
	}
}