import React, { Component } from "react";
import { compose } from "recompose";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { withScroll } from "~/hoc";
import PrimaryNav from "./PrimaryNav";
import SecondaryNav from "./SecondaryNav";
import NavProvider, { NavContext } from "./NavContext";
import TertiaryNav from "./TertiaryNav";
import styles from "./NavigationDesktop.module.scss";
import PageNavigation from "~/shared-components/PageNavigation";
import RedesignNavigationDesktop from "./RedesignNavigationDesktop";

class NavigationDesktopOld extends Component {
	constructor(props) {
		super(props);

		this.state = {
			adScriptLoaded: false,
			navigationShouldCollapse: false,
			scrollDepthReached: false,
			largeAd: false
		};

		this.GptAdDesktop = React.createRef();
	}

	componentDidMount() {
		window.addEventListener("gptLoaded", () => this.handleGPT(false)); //eslint-disable-line
		window.addEventListener("gptLoadedWithLargeAd", () => this.handleGPT(true)); //eslint-disable-line
		window.addEventListener("gptUnloaded", () => this.unloadGPT(false)); //eslint-disable-line
	}

	componentWillUnmount() {
		window.removeEventListener("gptLoaded", this.handleGPT); //eslint-disable-line
		window.removeEventListener("gptUnloaded", this.unloadGPT); //eslint-disable-line
	}

	componentDidUpdate(prevProps) {
		const { scroll } = this.props;
		const {
			scroll: prevScroll,
			sitecoreContext: {
				route: { name: prevRouteName }
			}
		} = prevProps;
		const {
			sitecoreContext: {
				route: { name: currentRouteName }
			}
		} = this.props;
		const { adScriptLoaded } = this.state;
		const scrollAmount = adScriptLoaded ? 300 : 200;

		if (typeof window !== 'undefined' && (currentRouteName !== prevRouteName)) {
			console.info("Navigation change detected, refreshing ads");
			window?.googletag?.pubads().refresh();
		}

		if (prevScroll !== scroll && scroll < scrollAmount) {
			this.setState({
				navigationShouldCollapse: false,
				scrollDepthReached: false
			});
		} else if (prevScroll !== scroll) {
			this.setState({
				navigationShouldCollapse: scroll > prevScroll,
				scrollDepthReached: adScriptLoaded ? scroll > scrollAmount : true
			});
		}
	}

	handleGPT = isLargeAd => { // eslint-disable-line
		//eslint-disable-next-line
		//const adElement = document.querySelector("#div-gpt-ad-nav-desktop");
		const adElement = this.GptAdDesktop.current;
		if (!adElement) return;
		const adStyle = getComputedStyle(adElement);

		if (adStyle.display !== "none") {
			document.body.classList.add("ad-loaded"); //eslint-disable-line
			this.setState({
				adScriptLoaded: true,
				largeAd: isLargeAd
			});
		}
	}

	unloadGPT = () => {
		document.body.classList.remove("ad-loaded"); //eslint-disable-line
		this.setState({ adScriptLoaded: false });
	}

	render() {
		const { fields, sitecoreContext, rendering: { primaryMenuItems, pageNavigation } } = this.props;
		const {	adScriptLoaded, largeAd, navigationShouldCollapse, scrollDepthReached } = this.state;
		const isEditing = sitecoreContext && sitecoreContext.pageEditing;

		return (
			<NavProvider>
				{fields && (
					<header ref={this.headerDesktop} className={`${adScriptLoaded ? largeAd ? styles.navigationDesktopJumboAd : styles.navigationDesktopWithAd : styles.navigationDesktop} ${navigationShouldCollapse ? styles.collapsedNav : styles.fullNav} ${scrollDepthReached ? styles.scrollDepthReached : ""}`}>
						<div id="ad-nav-desktop" className={styles.adContainer}>
							<div ref={this.GptAdDesktop} id="div-gpt-ad-nav-desktop" />
						</div>
						<div className="navigationDesktopGlobal">
							<nav className={`${styles.topBar} clearfix`}>
								<div className={styles.topBarInner}>
									<SecondaryNav
										menu1={fields.secondaryMenuItems1}
										menu2={fields.secondaryMenuItems2}
										feature={{image: fields.secondaryMenuFeaturedImage, link: fields.secondaryMenuFeaturedLink}}
										isEditing={isEditing}/>
									<TertiaryNav
										loginPage={fields.loginPage}
										profilePage={fields.profilePage}
										isEditing={isEditing}/>
								</div>
							</nav>
							<PrimaryNav
								nav={primaryMenuItems}
								logo={fields.logo}
								homeUrl={sitecoreContext.site.homeUrl}
								becomeAMemberLink={fields.becomeAMemberLink}
								memberBenefitsLink={fields.memberBenefitsLink}
								ticketsLink={fields.ticketsLink}
								adScriptLoaded={adScriptLoaded}
								membershipCallout={{
									image: fields.membershipCalloutImage,
									text: fields.membershipCalloutText,
									link: fields.membershipCalloutLink }}/>
						</div>
						<PageNavigation {...{pageNavigation}} />
					</header>
				)}
				<NavContext.Consumer>
					{(context) => (
						<div className={`${styles.background} ${context.state.isHovering && !context.state.menuItemClicked ? styles.showBackground : ''}`} />
					)}
				</NavContext.Consumer>
			</NavProvider>
		);
	}
}

const NavigationDesktop = props => {
	return ( props.fields.redesign.value ? <RedesignNavigationDesktop {...props} /> : <NavigationDesktopOld {...props} /> )
}

export default compose(
	withSitecoreContext(),
	withScroll()
)(NavigationDesktop);