/* eslint-disable react/jsx-key */
import React from "react";
import styles from "././CompetitionCard.module.scss";
import MatchButtons from "~/shared-components/MatchButtons";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import moment from "moment";
import { optaApi, USTeamNames } from "~/utilities/constants";

const CompetitionCardModule = ({ competitionData }) => {
	const { startDate, endDate, sitecoreData } = competitionData;

	const today = moment();
	const start = moment(startDate);
	const end = moment(endDate);

	const { matchStatus: { completed, upcoming, live } } = optaApi;

	const status = today.isBefore(start, "day") ? upcoming : today.isAfter(end, "day") ? completed : live;

	// since competition data is different from match data, we package it up so we can use the match buttons as competition buttons
	const matchButtonData = { ...competitionData, status, previewLink: sitecoreData?.previewLink?.value, fullRecapLink: sitecoreData?.fullRecapLink?.value };

	const customCalendarEvent = {
		title: competitionData.competitionName || "",
		description: competitionData.sitecoreData?.description?.value || "",
		location: competitionData.sitecoreData?.location?.value || "",
		startTime: startDate,
		endTime: endDate
	};

	return (
		<>
			<div
				style={competitionData.sitecoreData?.brandedBackgroundCard?.value?.src ? {backgroundImage: `url(${competitionData.sitecoreData?.brandedBackgroundCard?.value?.src})`} : {}}
				className={styles.CompetitionCard}>
				<div className={styles.competitionInfo}>
					{/* If you have a "presented by" sponsor image */}
					{competitionData.sitecoreData?.presentedByImage?.value?.src &&
						<div>
							<img 
								className={styles.presentedByImage}
								src={competitionData.sitecoreData.presentedByImage.value.src}
								alt={competitionData.sitecoreData.presentedByImage.value.alt}
								title={competitionData.sitecoreData.presentedByImage.value.title} />
						</div>}

					{/* If you have a logo else text */}
					{competitionData.sitecoreData?.competitionLogo?.value?.src ?
						<img
							className={styles.competitionLogo}
							src={competitionData.sitecoreData.competitionLogo.value.src}
							alt={competitionData.sitecoreData.competitionLogo.value.alt}
							title={competitionData.sitecoreData.competitionLogo.value.title} />
						:
						<div className={styles.cardHeader}>{competitionData.sitecoreData?.competitionName?.value}</div>
					}

					{/* If you have a sponsor text */}
					{competitionData.sitecoreData?.sponsorshipText?.value &&
						<div className={styles.sponsorshipText}>{competitionData.sitecoreData.sponsorshipText.value}</div>
					}

					{/* If you have a team name */}
					{competitionData.sitecoreData?.focusTeam?.teamName?.value &&
						<div className={styles.sponsorshipText}>{competitionData.sitecoreData.focusTeam.teamName.value}</div>
					}

					{/* Display Team */}
					{competitionData?.sitecoreData?.contestantOptaId &&
						<div className={styles.team}>{USTeamNames[competitionData.sitecoreData.contestantOptaId]}</div>
					}

					{/* If you have a start date */}
					{competitionData.startDate && (
						<div className={styles.dateText}>
							{moment.utc(competitionData.startDate).format("MMM Do")}
							{' - '}
							{moment.utc(competitionData.endDate).format("MMM Do YYYY")}
						</div>
					)}
				</div>

				<div className={styles.competitionButtonsContainer}>
					<MatchButtons event={matchButtonData} type="card" eventType="competition" customCalendarEvent={customCalendarEvent}/>
				</div>
			</div>
		</>
	);
};

export default compose(
	// other hocs goes here
	withErrorHandling()
)(CompetitionCardModule);

