import React from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import styles from "./ShowMoreButton.module.scss";
import { withNamespaces } from "react-i18next";
import { Arrow } from "~/shared-components/SvgIcons";

const ShowMoreButton = ({ showMoreHandler, hideButton, t }) => (
	<button className={`${styles.container} ${hideButton ? styles.hide : styles.visible}`} onClick={() => showMoreHandler()}>
		<span className={styles.name}>
			{t("show_more_button")}
		</span>
		<Arrow />
	</button>
);

export default compose(
	// other hocs goes here
	withNamespaces(),
	withErrorHandling()
)(ShowMoreButton);