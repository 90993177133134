import React from "react";
import { GigyaConsumer } from "~/lib/gigya";

export function withGigya() {
	return function withGigya(WrappedComponent) {
		return function withGigya(props) {
			const Component = <GigyaConsumer>{(gigya) => <WrappedComponent {...props} gigya={gigya}/>}</GigyaConsumer>;
			return Component;
		};
	};
}