import { rest } from 'msw';
import {
	matches as live10Matches,
	scoreboard as live10Scoreboard
} from "~/mocks/scoreboard/liveGameResponses/tenthMinute";
import {
	matches as live15Matches,
	scoreboard as live15Scoreboard
} from "~/mocks/scoreboard/liveGameResponses/fifteenthMinute";
import {
	matches as live40Matches,
	scoreboard as live40Scoreboard
} from "~/mocks/scoreboard/liveGameResponses/fortiethMinute";
import {
	matches as live46Matches,
	scoreboard as live46Scoreboard
} from "~/mocks/scoreboard/liveGameResponses/fortySixthMinute";
import {
	matches as liveHalfTimeMatches,
	scoreboard as liveHalfTimeScoreboard
} from "~/mocks/scoreboard/liveGameResponses/halftime";
import {
	matches as liveFullTimeMatches,
	scoreboard as liveFullTimeScoreboard
} from "~/mocks/scoreboard/liveGameResponses/fulltime";
import {optaApi} from "~/utilities/constants";
import allMatches from "~/mocks/scoreboard/allMatches";

const url = process.env.REACT_APP_LAYOUTSERVICEHOST ?? "https://author.ussoccer.com";

export const handlers = [

	/**
	 * This endpoint is called for featured matches.
	 *
	 * It affects the featured match displayed on red background.
	 */
	rest.get(`${url}/api/matches/:matchId`, async (req, res, ctx) => {
		// eslint-disable-next-line
		const queryString = window?.location?.search;
		switch (true) {
			// Live matches at various stages.
			case queryString.includes('scoreboard-banner=live-10-minute'):
				return res(ctx.status(200), ctx.json(live10Matches));
			case queryString.includes('scoreboard-banner=live-15-minute'):
				return res(ctx.status(200), ctx.json(live15Matches));
			case queryString.includes('scoreboard-banner=live-40-minute'):
				return res(ctx.status(200), ctx.json(live40Matches));
			case queryString.includes('scoreboard-banner=live-46-minute'):
				return res(ctx.status(200), ctx.json(live46Matches));
			case queryString.includes('scoreboard-banner=live-half-time'):
				return res(ctx.status(200), ctx.json(liveHalfTimeMatches));
			case queryString.includes('scoreboard-banner=live-full-time'):
				return res(ctx.status(200), ctx.json(liveFullTimeMatches));
			default: {
				const originalResponse = await ctx.fetch(req).then(r => r.json());
				return res(ctx.status(200), ctx.json(originalResponse));
			}
		}
	}),

	/**
	 * This endpoint is called for scoreboard contestant matches.
	 *
	 * It affects match tiles displayed in the carousel.
	 */
	rest.get(`${url}/api/scoreboard/contestant/:contestantIds`, async (req, res, ctx) => {

		// eslint-disable-next-line
		const queryString = window?.location?.search;

		/**
		 * IMPORTANT NOTE:
		 *
		 * MSW documentation suggests that the following line is enough to store
		 * the original response, so we can modify it before returning:
		 *
		 * 		const originalResponse = await ctx.fetch(req).then(r => r.json());
		 *
		 * However, for some reason this didn't work as expected. Full response
		 * was still being passed to the scoreboard component even though the code
		 * in the handler (in this file) was altering the available matches.
		 *
		 * For now, we will just store the list of matches in a file so this does
		 * not become a bottleneck.
		 *
		 * See this for more information on patching responses:
		 * https://mswjs.io/docs/recipes/response-patching
		 */

		switch (true) {

			// Live matches at various stages.
			case queryString.includes('scoreboard-banner=live-10-minute'):
				return res(ctx.status(200), ctx.json(live10Scoreboard));
			case queryString.includes('scoreboard-banner=live-15-minute'):
				return res(ctx.status(200), ctx.json(live15Scoreboard));
			case queryString.includes('scoreboard-banner=live-40-minute'):
				return res(ctx.status(200), ctx.json(live40Scoreboard));
			case queryString.includes('scoreboard-banner=live-46-minute'):
				return res(ctx.status(200), ctx.json(live46Scoreboard));
			case queryString.includes('scoreboard-banner=live-half-time'):
				return res(ctx.status(200), ctx.json(liveHalfTimeScoreboard));
			case queryString.includes('scoreboard-banner=live-full-time'):
				return res(ctx.status(200), ctx.json(liveFullTimeScoreboard));
			// No live matches.
			case queryString.includes('scoreboard-banner=no-live-matches'):
				return res(ctx.status(200), ctx.json(allMatches.filter(m => m.status !== optaApi.matchStatus.live)));
			// No upcoming matches.
			case queryString.includes('scoreboard-banner=no-upcoming-matches'): {
				return res(ctx.status(200), ctx.json(allMatches.filter(m => m.status !== optaApi.matchStatus.upcoming)));
			}
			// No recent matches.
			case queryString.includes('scoreboard-banner=no-recent-matches'):
				return res(ctx.status(200), ctx.json(allMatches.filter(m => m.status !== optaApi.matchStatus.completed)));

			default: {
				const originalResponse = await ctx.fetch(req).then(r => r.json());
				return res(ctx.status(200), ctx.json(originalResponse));
			}
		}
	}),
	rest.get(`https://signin.localhost/*`, async (req, res, ctx) => {
		console.log(req.url.href);
		const result = await fetch(req.url.href.replace('signin.localhost', 'signin.uat-8733871.ussoccer.com')).then(r => r.json());
		return res(
			ctx.status(200),
			ctx.json(result)
		);
	})
]