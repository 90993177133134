exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".WatchHub-module__video--32qeM {\n  border: none;\n  width: 100vw;\n  min-height: 1500px; }\n", ""]);

// exports
exports.locals = {
	"video": "WatchHub-module__video--32qeM"
};