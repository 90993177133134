import React, { useState } from "react";
import { Placeholder, withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import AnimateHeight from 'react-animate-height';
import styles from "./MembershipAccordionTab.module.scss";

const MembershipAccordionTab = ({ fields, rendering, sitecoreContext: { pageEditing } }) => {

	const [height, setHeight] = useState('0');

	const toggle = () => {		
		setHeight(height === '0' ? 'auto' : '0');
	};

	return (
		<>
			{fields &&
				<div className={styles.container}>
					<Header
						title={fields.header.value}
						price={fields.priceRange.value}
						description={fields.description.value}
						onClick={toggle}
						divHeight={height}
						pageEditing={pageEditing}
					/>
					<Accordion
						rendering={rendering}
						disclaimer={fields.disclaimer.value}
						divHeight={(pageEditing) ? 'auto' : height}
					/>
				</div>
			}
		</>);
};

const Header = ({title, price, description, onClick, divHeight, pageEditing}) =>
	<div className={styles.header}>
		<div className={styles.one}>
			<div className={styles.headerTitle}>{title}</div>
			<div className={styles.price}>{price}</div>
		</div>
		<div className={styles.two}>
			<div className={styles.descriptionText}>
				{description}
			</div>
		</div>
		<div className={styles.three}>
			<button onClick={() => onClick()}>{(divHeight === '0' && !pageEditing) ? "+" : "−"}</button>
		</div>
	</div>;

const Accordion = (props) =>
	<AnimateHeight
		id='example-panel'
		duration={ 500 }
		height={ props.divHeight === '0' ? '0' : 'auto' }
	>
		<div className={`${styles.accordion}`}>
			{/* cards can be added through the experience editor into this implicit grid */}
			<Placeholder name="jss-accordion-tab-grid" rendering={props.rendering} />
			<div className={styles.disclaimer}>{props.disclaimer}</div>
		</div>
	</AnimateHeight>;

export default compose(
	// other hocs goes here
	withSitecoreContext(),
	withErrorHandling()
)(MembershipAccordionTab);