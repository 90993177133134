exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".LinkBox-module__LinkBox--3MYPj {\n  border-bottom: 0.01rem solid #E6E6E6;\n  margin-top: 3rem;\n  padding-bottom: 1.8rem; }\n  .LinkBox-module__LinkBox--3MYPj a {\n    display: -ms-flexbox;\n    display: flex; }\n    .LinkBox-module__LinkBox--3MYPj a:hover h4,\n    .LinkBox-module__LinkBox--3MYPj a:hover p {\n      color: #C2002F; }\n  .LinkBox-module__LinkBox--3MYPj span {\n    display: -ms-flexbox;\n    display: flex; }\n  .LinkBox-module__LinkBox--3MYPj h4 {\n    color: #212844;\n    font-size: 1.4rem;\n    font-weight: 700;\n    letter-spacing: 0.05em;\n    line-height: 1.07;\n    margin: 0 0 1rem 0;\n    text-transform: uppercase; }\n  .LinkBox-module__LinkBox--3MYPj p {\n    color: #808080;\n    font-size: 1.5rem;\n    font-weight: 500;\n    letter-spacing: 0.01em;\n    line-height: 1.26;\n    margin: 0; }\n  .LinkBox-module__LinkBox--3MYPj img {\n    margin-right: 2.2rem;\n    -webkit-transform: translateY(-30%);\n            transform: translateY(-30%);\n    width: 2.4rem; }\n\n@media (min-width: 1025px) {\n  .LinkBox-module__LinkBox--3MYPj {\n    border-bottom: 0;\n    margin-top: 4rem;\n    padding-bottom: 5rem; }\n    .LinkBox-module__LinkBox--3MYPj a, .LinkBox-module__LinkBox--3MYPj span {\n      display: block; }\n    .LinkBox-module__LinkBox--3MYPj h4 {\n      line-height: 1.42; }\n    .LinkBox-module__LinkBox--3MYPj p {\n      font-size: 1.25rem;\n      font-weight: 500;\n      letter-spacing: 0.02em;\n      line-height: 1.46; } }\n", ""]);

// exports
exports.locals = {
	"LinkBox": "LinkBox-module__LinkBox--3MYPj"
};