import React from "react";
import styles from "./FeaturedLink.module.scss";
import { NavLink } from "react-router-dom";
import { Text, Image } from "~/patches/components";
import { compose } from "recompose";
import { withErrorHandling } from "~/hoc";

const FeaturedLink = ({ fields: { featuredLink, featuredLinkImage } }) => (
	<>
		{featuredLink && (
			<NavLink className={styles.featuredLink} to={featuredLink.url}>
				<Image field={featuredLinkImage} imageParams={ {mw: 22} } />
				<Text field={featuredLink?.fields?.menuTitleMobile} />
			</NavLink>
		)}
	</>
);

export default compose(
	withErrorHandling()
)(FeaturedLink);