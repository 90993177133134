/* eslint-disable no-restricted-globals */
import React, { PureComponent } from "react";
import { Text } from "~/patches/components";
import { compose } from "recompose";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import styles from "./InstagramWidget.module.scss";
import { isServer } from "~/utilities";

const RetryInterval = 100;
const RetryLimit = 60; // 100ms * 60 = 60 seconds

class InstagramWidget extends PureComponent {
	constructor(props) {
		super(props);
		this.state = { html: "" };
	}

	componentDidMount = () => {
		this._mounted = true;
		this.fetchEmbedCode();
	}

	componentWillUnmount = () => {
		this._mounted = false;
	}

	componentDidUpdate = () => {
		this.tryReprocess(0);
	}

	tryReprocess = retryCtr => {
		const { fields: { postUrl } } = this.props;
		if (isServer || !(postUrl?.value)) return;

		if (retryCtr > RetryLimit) {
			// eslint-disable-next-line no-console
			console.error("Could not find the Instagram Embeds script");
			return;
		}

		if (this._mounted && window?.instgrm?.Embeds) {
			window.instgrm.Embeds.process();
		}
		else {
			setTimeout(() => this._mounted && this.tryReprocess(retryCtr + 1), RetryInterval);
		}
	}
	
	fetchEmbedCode = () => {
		const { fields: { postUrl } } = this.props;
		if (isServer || !(postUrl?.value)) return;

		fetch(`https://api.instagram.com/oembed/?omitscript=1&url=${postUrl.value}`)
			.then(res => 
				res.json().then(json => this._mounted && this.setState({ html: json.html }))
			// eslint-disable-next-line no-console
			).catch(err => console.error(err));
	}

	render = () =>	{
		const { fields, sitecoreContext } = this.props;
		return <>
			<script async src="https://www.instagram.com/embed.js"></script>
			<div className={styles.container} >
				{sitecoreContext?.pageEditing && <p>Post Url: <Text field={fields.postUrl} /></p>}
				<div dangerouslySetInnerHTML={({ __html: this.state.html })}/>
			</div>
		</>;
	}
}

export default compose(
	withSitecoreContext()
)(InstagramWidget);