exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PlayerGrid-module__container--6xODf {\n  margin-bottom: 5rem; }\n  .PlayerGrid-module__container--6xODf .col {\n    -ms-flex: 0 0 50%;\n        flex: 0 0 50%; }\n\n.PlayerGrid-module__column--708KL {\n  margin-bottom: 2.4rem; }\n\n.PlayerGrid-module__carousel--2WXEC {\n  margin-bottom: 6rem; }\n  .PlayerGrid-module__carousel--2WXEC .flickity-viewport {\n    margin: 0 -1.3rem; }\n    .PlayerGrid-module__carousel--2WXEC .flickity-viewport:before, .PlayerGrid-module__carousel--2WXEC .flickity-viewport:after {\n      background: url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 100' preserveAspectRatio='none'%3E%3Cpolygon fill='%23FFFFFF' points='10,100 0,100 0,0 10,0 1,50'/%3E%3C/svg%3E\") left center/100% 100%;\n      bottom: 0;\n      content: '';\n      left: 0;\n      position: absolute;\n      top: 0;\n      width: 1.2rem;\n      z-index: 2; }\n    .PlayerGrid-module__carousel--2WXEC .flickity-viewport:after {\n      left: auto;\n      right: 0;\n      -webkit-transform: rotate(180deg);\n              transform: rotate(180deg); }\n\n.PlayerGrid-module__item--148WY {\n  padding: 0 1.3rem 0;\n  width: 50%; }\n  @media (min-width: 768px) {\n    .PlayerGrid-module__item--148WY {\n      width: 33.334%; } }\n  @media (min-width: 992px) {\n    .PlayerGrid-module__item--148WY {\n      width: 25%; } }\n  @media (min-width: 1025px) {\n    .PlayerGrid-module__item--148WY {\n      width: 20%; } }\n  @media (min-width: 1440px) {\n    .PlayerGrid-module__item--148WY {\n      width: 16.666%; } }\n\n@media (min-width: 1025px) {\n  .PlayerGrid-module__column--708KL {\n    margin-bottom: 2rem; }\n  .PlayerGrid-module__carousel--2WXEC {\n    margin-bottom: 5rem; }\n  .flickity-viewport {\n    margin: 0 -1.3rem; }\n    .flickity-viewport:before, .flickity-viewport:after {\n      width: 1rem; } }\n", ""]);

// exports
exports.locals = {
	"container": "PlayerGrid-module__container--6xODf",
	"column": "PlayerGrid-module__column--708KL",
	"carousel": "PlayerGrid-module__carousel--2WXEC",
	"item": "PlayerGrid-module__item--148WY"
};