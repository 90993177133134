import React from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import styles from "./BodyText.module.scss";
import { RichText, Text } from "~/patches/components";

const setColumnClass = (fields, pageEditing) => {
	const { columnOne, columnTwo, columnThree } = fields;

	//determines number of columns based on authored content
	if ((columnOne.value && columnTwo.value && columnThree.value) || pageEditing) {
		return "threeColumn";
	} else if (
		(columnOne.value && columnTwo.value) ||
		(columnOne.value && columnThree.value) ||
		(columnTwo.value && columnThree.value)
	) {
		return "twoColumn";
	} else {
		return "oneColumn";
	}
};

const BodyText = ({fields, sitecoreContext: { pageEditing }}) => (
	<>
		{fields && (
			<div className={`${styles.BodyText} ${fields.hideBackgroundColor?.value ? "" : styles.backgroundGradient}`}>
				<div className={styles[setColumnClass(fields, pageEditing)]}>
					<Text tag="h2" className={styles.heading} field={fields.heading} />
					<div className={styles.inner}>
						{fields.columnOne.value && <RichText field={fields.columnOne} />}
						{fields.columnTwo.value && <RichText field={fields.columnTwo} />}
						{fields.columnThree.value && <RichText field={fields.columnThree} />}
					</div>
				</div>
			</div>
		)}
	</>
);

export default compose(
	// other hocs goes here
	withSitecoreContext(),
	withErrorHandling()
)(BodyText);