import React, { PureComponent } from "react";
import { Form, Field, withFormik } from "formik";
import { Text } from "~/patches/components";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import { FormService } from "~/services/formService";
import { object, string } from "yup";
import { Arrow, CircledCheckMark } from "~/shared-components/SvgIcons";
import styles from "./FormContactUs.module.scss";
import { isRedesign } from "~/utilities/helpers";
class FormContactUsEl extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			isExpanded: false
		};
	}

	renderField = (fieldLabel, fieldName, type, placeholder) => {
		const fieldError = this.isFieldValid(fieldName);

		return (
			<div className={`${styles.formField} ${type === "textarea" ? styles.fullWidth : ""} ${fieldError ? styles.error : ""}`}>
				<label htmlFor={fieldName}>
					<Text field={fieldLabel} /> {fieldError}
				</label>
				<Field
					component={type}
					name={fieldName}
					placeholder={placeholder}
				/>
			</div>
		);
	}

	isFieldValid = fieldName => {
		const { errors, touched } = this.props;

		if (errors && errors[fieldName] && touched && touched[fieldName]) {
			return errors[fieldName];
		}

		return false;
	}

	renderSelectField = (fieldLabel, fieldName, options) => {
		const fieldError = this.isFieldValid(fieldName);

		return (
			<div className={`${styles.formField} ${styles.formSelect} ${fieldError ? styles.error : ""}`}>
				<label htmlFor={fieldName}>
					<Text field={fieldLabel} /> {fieldError}
				</label>
				<Field
					component={this.renderDropdown}
					name={fieldName}
					required
					placeholder={options[0]}
					options={options.slice(1)}
				/>
			</div>
		);
	}

	renderDropdown = ({
		field: { name, value },
		form: {
			setFieldTouched,
			setFieldValue,
		},
		options,
		placeholder
	}) => {

		const { isExpanded } = this.state;

		return (
			<div className={styles.dropdownWrapper}>
				<div className={`${styles.dropdown} ${isExpanded ? styles.expanded : ""}`}>
					<div
						className={styles.dropdownButton}
						onClick={() => {
							this.setState({ isExpanded: !isExpanded });
							if (isExpanded) {
								setFieldTouched(name, true);
							}
						}}
					>
						{value || placeholder}
						<Arrow />
					</div>

					<div className={`${styles.dropdownMenuCollapsed} ${isExpanded ? styles.showFilters : ""}`}>
						<ul className={`${isRedesign ? styles.itemContainerRedesign : styles.itemContainer}`}>
							{options.map((option, index) =>
								<li
									className={`${isRedesign ? styles.itemRedesign : styles.item}`}
									key={index}
									onClick={() => {
										setFieldValue(name, option);
										this.setState({ isExpanded: false });
									}}
								>
									{option}
								</li>
							)}
						</ul>
					</div>
				</div>
			</div>
		);
	}

	buildOptions(encodedSubjects) {
		return encodedSubjects && decodeURI(encodedSubjects).split("&")
			.map(str => {
				const items = str && str.split("=");
				return items?.length && items[items.length - 1];
			});
	}

	renderSubmittedModal = () => {
		const {
			fields,
			status: {
				submitSuccess = false,
				submitted = false
			}
		} = this.props;

		return submitted && (
			<div className={styles.modal}>
				<CircledCheckMark />
				<h2 className={styles.messageHeader}>
					<Text field={submitSuccess ? fields?.confirmationText : fields?.errorText} />
				</h2>
				<button className={styles.resetButton} onClick={this.resetForm}>
					<Text field={submitSuccess ? fields?.confirmationButtonText : fields?.errorButtonText} />
				</button>
			</div>
		);
	}

	resetForm = () => {
		const { setStatus, resetForm, setSubmitting } = this.props;

		setSubmitting(false);
		setStatus({ submitted: false, submitSuccess: false });

		resetForm();
	}

	render() {
		const {
			fields,
			handleSubmit,
			isSubmitting
		} = this.props;

		const options = this.buildOptions(fields?.messageSubjects?.value);

		return (
			<>
				{fields && (
					<div className={`${styles.contactForm} ${isSubmitting ? styles.submitting : ""}`}>
						<Form onSubmit={handleSubmit}>
							<h2 className={styles.messageHeader}>
								<Text field={fields.messageHeader} />
							</h2>
							<p className={styles.messageIntroduction}>
								<Text field={fields.messageIntroduction} />
							</p>

							{this.renderSelectField(fields.messageSubjectLabel, "subject", options)}
							{this.renderField(fields.messageLabel, "message", "textarea", fields.messageWatermark.value)}

							<h2 className={styles.contactHeader}>
								<Text field={fields.contactHeader} />
							</h2>
							<p className={styles.contactIntroduction}>
								<Text field={fields.contactIntroduction} />
							</p>

							<div className={styles.splitGroup}>
								{this.renderField(fields.contactFirstNameLabel, "firstName", "input")}
								{this.renderField(fields.contactLastNameLabel, "lastName", "input")}
							</div>

							<div className={styles.splitGroup}>
								{this.renderField(fields.contactEmailLabel, "email", "input")}
								{this.renderField(fields.contactPhoneLabel, "phone", "input")}
							</div>

							<button disabled={isSubmitting} className={styles.submitButton} type="submit">
								<Text field={fields.sendButtonText} />
							</button>
						</Form>
						{this.renderSubmittedModal()}
					</div>
				)}
			</>
		);
	}
}

FormContactUsEl.defaultProps = {
	status: {}
};

const errorMsg = "This is required";

const FormContactUs = withFormik({
	displayName: "FormContactUs",
	mapPropsToValues: () => ({
		"firstName": "",
		"lastName": "",
		"message": "",
		"subject": "",
		"email": "",
		"phone": ""
	}),
	validationSchema: object().shape({
		"firstName": string().required(errorMsg),
		"lastName": string().required(errorMsg),
		"message": string().required(errorMsg),
		"subject": string().required(errorMsg),
		"email": string().email()
			.required(errorMsg),
		"phone": string()
	}),
	handleSubmit: (values, { setStatus, setSubmitting }) => {
		setSubmitting(true);

		FormService.postGeneralForm("contact-us", values, "fakeToken")
			.then(response => {
				if (response.status === 200) {
					setStatus({ submitSuccess: true, submitted: true });
					setSubmitting(false);
				} else {
					throw new Error();
				}
			})
			.catch(() => {
				setStatus({ submitSuccess: false, submitted: true });
				setSubmitting(false);
			});
	}
})(FormContactUsEl);

export default compose(
	// other hocs goes here
	withErrorHandling()
)(FormContactUs);