var escape = require("../../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Link-module__goToNext--3POM7 {\n  position: relative;\n  font-weight: 600; }\n  .Link-module__goToNext--3POM7:after {\n    background: transparent url(" + escape(require("../../../assets/svg/arrow.svg")) + ") no-repeat center center;\n    bottom: 0;\n    content: \"\";\n    height: 16px;\n    left: auto;\n    padding: 0 2rem;\n    position: absolute;\n    right: 0;\n    top: 50%;\n    -webkit-transform: translateY(-50%) rotate(0);\n            transform: translateY(-50%) rotate(0);\n    width: 5px; }\n  .Link-module__goToNext--3POM7:after {\n    width: 6px; }\n\n.Link-module__goToNextRedesign--261S_ {\n  position: relative;\n  font-weight: 600; }\n  .Link-module__goToNextRedesign--261S_:after {\n    background: transparent url(" + escape(require("../../../assets/svg/arrow.svg")) + ") no-repeat center center;\n    bottom: 0;\n    content: \"\";\n    height: 16px;\n    left: auto;\n    padding: 0 2rem;\n    position: absolute;\n    right: 0;\n    top: 50%;\n    -webkit-transform: translateY(-50%) rotate(0);\n            transform: translateY(-50%) rotate(0);\n    width: 5px; }\n  .Link-module__goToNextRedesign--261S_:after {\n    width: 6px; }\n", ""]);

// exports
exports.locals = {
	"goToNext": "Link-module__goToNext--3POM7",
	"goToNextRedesign": "Link-module__goToNextRedesign--261S_"
};