exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".TweetWidget-module__container--CnIi0 {\n  margin: 0 auto;\n  max-width: 1440px;\n  padding: 0 20px; }\n  .TweetWidget-module__container--CnIi0 .twitter-tweet-rendered {\n    margin: 0 auto 2rem !important; }\n", ""]);

// exports
exports.locals = {
	"container": "TweetWidget-module__container--CnIi0"
};