import React, { PureComponent } from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { Text } from "~/patches/components";
import { Link, NavLink } from "react-router-dom";
import MegaMenu from "../MegaMenu";
import CallToActionMenu from "../CallToActionMenu";
import { NavContext } from "../NavContext";
import SponsorsLogo from "~/assets/img/sponsors-logo.svg";
import styles from "./PrimaryNav.module.scss";
import JsonLd from "~/components/JsonLd";

const PrimaryNav = props => {
	const { nav, becomeAMemberLink, memberBenefitsLink, ticketsLink, membershipCallout, sitecoreContext, adScriptLoaded } = props;
	const isEditing = sitecoreContext && sitecoreContext.pageEditing;
	const dataObject = {
		"@context": "http://schema.org",
		"@type": "Organization",
		"name": "U.S. Soccer Federation",
		"url": "https://www.ussoccer.com",
		"contactPoint": [
			{
				"@type": "ContactPoint",
				"telephone": "+1-(312) 808-1300",
				"contactType": "Customer Service"
			}
		],
	};

	return (
		<nav className={adScriptLoaded ? styles.primaryNavWithAd : styles.primaryNav}>
			<div className={styles.inner}>
				<Link className={styles.logo} to="/">
					<img src={SponsorsLogo}
						alt="US Soccer. Sponsored by Volkswagen"
						title="US Soccer. Sponsored by Volkswagen" />
				</Link>

				<NavLinks {...{nav, membershipCallout, sitecoreContext}}/>

				<CallToActionMenu
					member={becomeAMemberLink}
					benefits={memberBenefitsLink}
					tickets={ticketsLink}
					isEditing={isEditing}/>
			</div>
			<JsonLd data={dataObject} />
		</nav>

	);
};

class NavLinks extends PureComponent {
	constructor(props) {
		super(props);
	}

	render() {
		const { nav, membershipCallout } = this.props;
		return (
			<NavContext.Consumer>
				{context => (
					<ul className={`${context.state.isHovering && context.state.previouslyActiveKey ? styles.disableTransitions : ''} ${!context.state.menuItemClicked ? styles.canShowMegaMenu : ""}`} itemScope itemType="http://schema.org/SiteNavigationElement">
						{nav && nav.map((item, index) => {
							const primaryNavIndexKey = `nav-links-${index}`;
							// const animationType = context.state.isMouseLeaving ?  styles.slideOutToRight: styles.slideInFromLeft ;
							// const setSlideAnimation = context.state.activeKey === primaryNavIndexKey
							// 	? styles.slideInFromLeft
							// 	: context.state.wasHovering && context.state.previouslyActiveKey === primaryNavIndexKey
							// 		? styles.slideOutToRight
							// 		: '';
							return (
								<li
									className={`${styles.navItem} ${primaryNavIndexKey === context.state.activeKey ? styles.isHovering : ""}`}
									onMouseOver={context.isMouseEntering.bind(this, {primary: primaryNavIndexKey, sub: null})}
									onMouseLeave={context.isMouseLeaving}
									key={`nav-links-${index}`}>
									<NavLink
										activeClassName={styles.active}
										className={styles.primaryNavLink}
										onClick={() => context.linkClicked(true)}
										exact
										to={item.url}
										itemProp="url"
									>
										<div>
											<Text tag="span" field={item.menuTitle} itemProp="name"/>
											<Text tag="span" field={item.menuDescription}/>
										</div>
									</NavLink>
									<div className={styles.megaMenuWrapper}>
										<MegaMenu
											id={item.id}
											isHovering={(primaryNavIndexKey === context.state.activeKey) && !context.state.menuItemClicked}
											mega={item.megaMenu}
											membershipCallout={membershipCallout}
											subMenu={item.subNavItems}
											url={item.url}
										/>
									</div>
								</li>
							);
						})}
					</ul>
				)}
			</NavContext.Consumer>
		);
	}
}

export default withSitecoreContext()(PrimaryNav);