import { useSanity } from '../useSanity';


export const useQuadBox = (query) => {
	const { sitecoreItemId } = query;
	const groqQuery = `
    *[_type == "featuredStoriesHero" %26%26 sitecoreItemId == $sitecoreItemId][0]
	`;
	const queryKey = ['useQuadBox', query];
	const variablesQueryString = `$sitecoreItemId="${sitecoreItemId}"`;
	const {
		isError,
		loading,
		data
	} = useSanity({ groqQuery, queryKey, variablesQueryString });

	return {
		isError,
		loading,
		data
	}
}