/* eslint-disable react/jsx-key */
import React from "react";
import { compose } from "recompose";
import { withErrorHandling } from "~/hoc";
import { withSitecoreContext, Placeholder } from "@sitecore-jss/sitecore-jss-react";
import FeaturedMatch from "~/shared-components/RedesignFeaturedMatch";
import HelperCommentEdit from "~/experience-editor/HelperCommentEdit";

const RedesignFeaturedMatch = ({ fields, rendering, sitecoreContext: { pageEditing } }) => {
	
	return (
		<>
			{!fields?.reference?.fields?.optaMatchId?.value && pageEditing && (
				<HelperCommentEdit endingEditComment="and choose a reference item. Can either be a Competition Page or a Match Page" />
			)}
			{fields?.reference?.fields?.optaMatchId?.value && (
				<FeaturedMatch matchId={fields.reference.fields.optaMatchId.value} pageEditing={pageEditing} />
			)}
		</>
	);
};

export default compose(
	withSitecoreContext(),
	withErrorHandling()
)(RedesignFeaturedMatch);