/* eslint-disable */
import React from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import { getTableColumnWidth } from "~/utilities/helpers";
import moment from "moment";
import "moment-timezone";
import Table from "~/shared-components/Table";
import styles from "./TableMatches.module.scss";


const formatDataForTable = data => {
	const formattedData = [];
	data.map(m => {
		var dateTime = m.date.slice(0, 10) + "T" + m.time
		formattedData.push({
			date: moment(dateTime).tz(moment.tz.guess()).format('MM DD YYYY'),
			match: m.matchDescription,
			usa: m.position === "home" ? m.homeScore : m.awayScore,
			opp: m.position === "home" ? m.awayScore : m.homeScore,
			result: m.outcome,
			attendance: m.attendance ? m.attendance : "--",
			scorers: m.scorers.length > 0 ? m.scorers : "--"
		})
	})
	return formattedData;
}

const TableMatches = ({ heading, matches }) => (
	<div className={styles.TableMatches}>
		{matches && 
			<Table
				data={formatDataForTable(matches)}
				heading={heading}
				tableType={"matches"} />
		}
	</div>
);

export default compose(
	// other hocs goes here
	withErrorHandling()
)(TableMatches);