/* eslint-disable react/jsx-key */
import React from "react";
import { optaApi } from "~/utilities/constants";
import { useNextMatch } from "~/hooks/useNextMatch";
import MiniMatchInfo from "./MiniMatchInfo";
import MiniBroadcasterLinks from "./MiniBroadcasterLinks";
import MiniMatchButtons from "./MiniMatchButtons";
import { MiniMatchNextGame } from "./MiniMatchNextGame";
import styles from "./RedesignMiniFeaturedMatch.module.scss"
import { useFetchMatch } from "~/hooks/match";

const RedesignMiniFeaturedMatch = ({ matchId, miniVisible }) => {
	const match = useFetchMatch(matchId);
	const contestantId = match?.contestants?.find(contestant => contestant?.code === 'USA')?.id;
	const { nextMatch } = useNextMatch({ contestantId });
	const matchInfoStyles = {
		[optaApi.matchStatus.completed]: !nextMatch ? styles.completedMatchNoNextMatch : styles.completedMatch,
		[optaApi.matchStatus.live]: styles.liveMatch,
		[optaApi.matchStatus.upcoming]: styles.upcomingMatch
	};

	return (
		<div className={styles.scorebar}>
			{match && (
				<div style={{position: 'relative'}}>
					<div className={`${styles.miniFeaturedMatch} ${miniVisible ? styles.miniVisible : ''} ${[optaApi.matchStatus.live, optaApi.matchStatus.completed].includes(match?.status) ? styles.matchLive : ''}`}>
						<div className={`${styles.matchInfo} ${matchInfoStyles[match.status]}`}>
							<MiniMatchInfo match={match} />
							<MiniBroadcasterLinks match={match} />
							<MiniMatchButtons event={match} />
							{match.status === optaApi.matchStatus.completed && (
								<MiniMatchNextGame match={match} />
							)}
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default RedesignMiniFeaturedMatch;