import React from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import styles from "./LatestVideos.module.scss";
import {ArrowRight} from "~/shared-components/SvgIcons";
import BrightcoveVideo from "~/components/BrightcoveVideo";

const LatestVideos = ({videos}) => {
	return (
		<div className={styles.wrap}>
			<h2 className={styles.sectionTitle}>
				<a href={`https://ussoccer.gallery.video/`} target={`_blank`}>
					Latest Videos <ArrowRight />
				</a>
			</h2>
			<div className={styles.inner}>
				{videos.map(video => (
					<BrightcoveVideo key={video.EmbeddedCode} fields={{videoId: {value: video.EmbeddedCode}}} />
				))}
			</div>
		</div>
	);
};

export default compose(
	withErrorHandling()
)(LatestVideos);
