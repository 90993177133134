import React from "react";
import MatchInfo from "~/shared-components/MatchInfo";
import MatchButtons from "~/shared-components/MatchButtons";
import styles from "./MatchCard.module.scss";
import { compose } from "recompose";
import { withErrorHandling } from "~/hoc";
import { fieldObjHasValue } from "~/utilities";

const MatchCard = ({ match, doubleHeaderMatches, pageEditing, inCarousel,ticketMasterIcon }) => {
	let inlineBg = {};
	if (fieldObjHasValue(match.sitecoreData?.brandedBackgroundCard, "image")) {
		inlineBg = { backgroundImage: `url(${match.sitecoreData.brandedBackgroundCard.value.src})` };
	} else if (fieldObjHasValue(match.sitecoreData?.brandedBackground, "image")) {
		inlineBg = { backgroundImage: `url(${match.sitecoreData.brandedBackground.value.src})` };
	}

	return (
		<div style={!pageEditing ? inlineBg : {}} className={`${inCarousel ? styles.fullWidth : styles.MatchCard} ${doubleHeaderMatches ? styles.matchCardFooterDoubleHeader : ""}`}>
			<MatchInfo match={match} doubleHeaderMatches={doubleHeaderMatches} pageEditing={pageEditing} type="MatchCard" ticketMasterIcon={ticketMasterIcon}/>
			<div className={`${styles.matchButtonsContainer} ${!doubleHeaderMatches ? styles.matchButtonsContainerBackground : ""}`}>
				<MatchButtons event={match} pageEditing={pageEditing} type="card" eventType="match" />
			</div>
		</div>
	);
};

export default compose(
	withErrorHandling()
)(MatchCard);