var escape = require("../../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".EditionStoryGrid-module__EditionStoryGrid--1lhTm {\n  background: #15192B;\n  color: #FFFFFF;\n  padding-top: 2rem; }\n  .EditionStoryGrid-module__EditionStoryGrid--1lhTm:before {\n    border-top: 0.1rem solid rgba(255, 255, 255, 0.2);\n    content: \"\";\n    display: block;\n    padding-top: 3rem;\n    width: calc(100% - 2rem); }\n  .EditionStoryGrid-module__EditionStoryGrid--1lhTm h2 {\n    font-size: 1.4rem;\n    margin-bottom: 3rem;\n    text-transform: uppercase; }\n    .EditionStoryGrid-module__EditionStoryGrid--1lhTm h2:after {\n      background: transparent url(" + escape(require("../../../assets/svg/arrow.svg")) + ") center center no-repeat;\n      content: \"\";\n      display: block;\n      height: 2rem;\n      margin-top: 0.5rem;\n      -webkit-transform: rotate(90deg) translateY(0.3rem);\n              transform: rotate(90deg) translateY(0.3rem);\n      -webkit-transform-origin: bottom;\n              transform-origin: bottom;\n      width: 0.6rem; }\n\n.EditionStoryGrid-module__grid--2b3Km {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n  -ms-flex-pack: justify;\n      justify-content: space-between; }\n\n@media (min-width: 992px) {\n  .EditionStoryGrid-module__EditionStoryGrid--1lhTm {\n    padding-top: 10rem; }\n    .EditionStoryGrid-module__EditionStoryGrid--1lhTm:before {\n      width: 100%; }\n  .EditionStoryGrid-module__grid--2b3Km {\n    margin: 0 auto;\n    max-width: 100%;\n    width: 1440px; }\n  .EditionStoryGrid-module__storyItem--JrCe0 {\n    width: calc(33.333% - 2rem); } }\n\n@media (min-width: 1025px) {\n  .EditionStoryGrid-module__inner--jbkVV {\n    margin: 0 auto;\n    max-width: 100%;\n    width: 1440px; } }\n", ""]);

// exports
exports.locals = {
	"EditionStoryGrid": "EditionStoryGrid-module__EditionStoryGrid--1lhTm",
	"grid": "EditionStoryGrid-module__grid--2b3Km",
	"storyItem": "EditionStoryGrid-module__storyItem--JrCe0",
	"inner": "EditionStoryGrid-module__inner--jbkVV"
};