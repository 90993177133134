import React from "react";
import styles from "../MultiLevelMenu.module.scss";
import { Arrow } from "~/shared-components/SvgIcons";
import LinkName from "../LinkName";

const NextButton = ({setState, link, level, index, currentMenu}) => (
	<button
		className={styles.goToNext}
		onClick={() => setState({
			goToMenu: `level-${level + 1}-${index}`,
			prevMenu: currentMenu,
			goNext: true,
			goBack: false,
			activeLevelId: link.id
		})}>
		<LinkName link={link.sidebarMenuTitle} fallbackLinkName={link.menuTitle} />
		<Arrow />
	</button>
);

export default NextButton;