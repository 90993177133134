import React, { useEffect } from "react";
import * as ReactDOMServer from 'react-dom/server';
import { Link as RouterLink } from "react-router-dom";
import { compose } from "recompose";
import styles from "./RedesignFeaturedStoriesGrid.module.scss";
import moment from "moment";
import RedesignGoogleAd from "../RedesignGoogleAd";
import RedesignSection from "../../shared-components/RedesignSection";
import { Text, Link } from "~/patches/components";
import { Placeholder, DateField, withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { generateCateoryUrl } from "~/utilities";
import { useQuadBox } from "~/hooks/useQuadbox";
import { urlFor } from "~/utilities/sanityImage";

const RedesignFeaturedStoriesGrid = ({ rendering, rendering: { dataSource }, sitecoreContext: { pageEditing, settings } }) => {
	const { data } = useQuadBox({ sitecoreItemId: dataSource });
	const defaultGoogleAdProps = {
		fields: {
			id: { value: 'div-gpt-ad-scoreboard' },
			square: { value: true }
		}
	};
	return (
		<RedesignSection arrowLink title={rendering?.fields?.title?.value} link={rendering?.fields?.link?.value?.href} linkText={rendering?.fields?.linkText?.value}>
			<div className={`${styles.Grid}`}>
				{pageEditing ? (
					<Placeholder
						name="jss-redesign-featured-stories-grid-content"
						rendering={rendering}
						renderEach={(component, index) => (
							<span key={index}>{component}</span>
						)} />
				) : (
					<Placeholder
						name="jss-redesign-featured-stories-grid-content"
						rendering={rendering}
						render={components => {
							const thumbnailComponents = components?.filter((child) => child.props.rendering.componentName === "StoryThumbnail");
							return (
								<>
									{/* {thumbnailComponents?.map((child) => (
									(<Story {...child} key={child?.props?.rendering?.uniqueName} categoryUrl={generateCateoryUrl(child?.props?.fields?.category?.name, settings)} />)
								))} */}
									{thumbnailComponents?.[0] && <Story {...thumbnailComponents?.[0]} categoryUrl={generateCateoryUrl(thumbnailComponents?.[0]?.props?.fields?.category?.name, settings)} />}
									{thumbnailComponents?.[1] && <Story {...thumbnailComponents?.[1]} categoryUrl={generateCateoryUrl(thumbnailComponents?.[1]?.props?.fields?.category?.name, settings)} />}
									{thumbnailComponents?.[2] && <Story {...thumbnailComponents?.[2]} categoryUrl={generateCateoryUrl(thumbnailComponents?.[2]?.props?.fields?.category?.name, settings)} />}
									{thumbnailComponents?.[3] && <Story {...thumbnailComponents?.[3]} categoryUrl={generateCateoryUrl(thumbnailComponents?.[3]?.props?.fields?.category?.name, settings)} />}
									{thumbnailComponents?.[4] && <Story {...thumbnailComponents?.[4]} categoryUrl={generateCateoryUrl(thumbnailComponents?.[4]?.props?.fields?.category?.name, settings)} />}
									{thumbnailComponents?.[5] && <Story {...thumbnailComponents?.[5]} categoryUrl={generateCateoryUrl(thumbnailComponents?.[5]?.props?.fields?.category?.name, settings)} />}
									<div className={styles.aside}>
										<div className={styles.quadBox}>
											{data?.quadBox?.map((child) => <QuadBoxImage key={child._key} {...child} />)}
										</div>

										<div className={styles.googleAd}>
											{components?.map((child) => (child.props.rendering.componentName === "RedesignGoogleAd" ?
												(<RedesignGoogleAd {...child?.props} />) : null))}
											{components?.filter(child => child.props.rendering.componentName === "RedesignGoogleAd").length === 0 && <RedesignGoogleAd {...defaultGoogleAdProps} />}
										</div>
									</div>
								</>
							)
						}} />
				)}
			</div>
		</RedesignSection>
	);
};

const Story = st => {
	const [story, setStory] = React.useState(null);
	useEffect(() => {
		setStory(st);
		return undefined;
	}, []);
	if (!story) return <span><Text field={{ ...story?.props?.rendering?.fields?.heading }} /></span>;
	return (
		<Link className={styles.storyLink} field={{ ...story?.props?.rendering?.fields?.link }}>
			<img className={styles.imageContainer} src={story?.props?.rendering?.fields?.image.value.src} alt={story?.props?.rendering?.fields?.heading?.value} />
			<span>
				{story.categoryUrl && <RouterLink className={styles.category} to={story.categoryUrl}>
					<Text field={{ value: story?.props?.rendering?.fields?.category?.name }} />
				</RouterLink>}
			</span>
			<span className={styles.dateSpan}><DateField tag="time" field={story?.props?.rendering?.fields?.date} render={(date) => moment(date).format("MMMM D, YYYY")} /></span>
			<span data-for-img={story?.props?.rendering?.fields?.image.value.src}><Text field={{ ...story?.props?.rendering?.fields?.heading }} /></span>
		</Link>
	)
};

const QuadBoxImage = ({ image, title, linkTo }) => {
	if (linkTo?.external) {
		return (
			<a className={styles.quadBoxLink} href={linkTo.external} target="_blank" rel="noopener noreferrer">
				<div className={styles.quadBoxImageContainer} style={{ backgroundImage: `url(${urlFor(image).maxWidth(300).url()})` }}></div>
				<span className={styles.quadBoxHeading}>{title}</span>
			</a>
		);
	}
	return linkTo && (
		<RouterLink className={styles.quadBoxLink} to={linkTo?.sitecore ?? '/'}>
			<div className={styles.quadBoxImageContainer} style={{ backgroundImage: `url(${urlFor(image).maxWidth(300).url()})` }}></div>
			<span className={styles.quadBoxHeading}>{title}</span>
		</RouterLink>
	);
};
export default compose(
	withSitecoreContext()
)(RedesignFeaturedStoriesGrid);