import React from "react";
import { compose } from "recompose";
import { withErrorHandling } from "~/hoc";
import { Text } from "~/patches/components";
import styles from "./SharedTab.module.scss";
import { isServer } from "~/utilities";

const handleTabClick = (tab) => {

	if (!tab || isServer) return;

	tab.setActiveTab(tab.index);

	if (!tab.hashUrl) return;

	/* eslint-disable-next-line */
	window.location.hash = tab.hashUrl;
};

const SharedTab = tab => (
	<button id={`SharedTabs__${tab.hashUrl}`} className={`${styles.btn} ${tab.index === tab.activeTab ? styles.active : ""}`} onClick={() => handleTabClick(tab)}>
		<Text field={tab.label} />
	</button>
);

export default compose(
	withErrorHandling()
)(SharedTab);