import React from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import styles from "./BrightcoveVideoPlayer.module.scss";

const BrightcoveVideoPlayer = props => {
	const fields = props.fields;

	let videoEmbedCode;
	if (props?.videoEmbedCode) {
		videoEmbedCode = props.videoEmbedCode;
	} else {
		videoEmbedCode = fields?.pickedBrightcoveVideo?.fields?.embedCode?.value;
	}

	const playerId = fields?.pickedBrightcoveVideo?.fields?.videoPlayerId?.value ? fields?.pickedBrightcoveVideo?.fields?.videoPlayerId?.value : "default";

	//USSF BrightCove account ID
	const accountId = "6057277724001";


	const brightCoveSource = (playerId, videoEmbedCode) => `//players.brightcove.net/${accountId}/${playerId}_default/index.html?videoId=${videoEmbedCode}`;

	return (
		<div className={`${styles.container} brightcove-video-wrap`}>
			<div style={{ paddingTop: '56.25%' }}>
				<iframe
					title={`player- ${videoEmbedCode}`}
					src={brightCoveSource(playerId, videoEmbedCode)}
					allowFullScreen
					allow="encrypted-media"
					className={styles.video}
				>
				</iframe>
			</div>
		</div>
	);
};

export default compose(
	// other hocs goes here
	withErrorHandling()
)(BrightcoveVideoPlayer);