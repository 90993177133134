import { useQuery } from '@tanstack/react-query';

export const useLatestStories = ({ storyLink }) => {
	const { data, isLoading, isError } = useQuery({ 
		queryKey: ["useLatestStories", { storyLink }],
		queryFn: async () => {
			const latestStories = await fetch(`https://api.ussoccer.com/api/mobile/stories?limit=7&offset=0`).then((r) => r.json()).catch((e) => console.error(e));
				
			return latestStories
				.sort((a, b) => (new Date(b.PostedDate) - new Date(a.PostedDate)))
				.filter(story => story.Url.replace("https://www.ussoccer.com", "") !== storyLink)
				?.slice(0,6);
		}
	});

	return { 
		latestStories: data,
		isLoading,
		isError
	};
}
