exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".GalleryCarousel-module__GalleryCarousel--2ZTBr {\n  background: -webkit-gradient(linear, left top, left bottom, from(#F7F7F7), to(rgba(255, 255, 255, 0)));\n  background: -o-linear-gradient(top, #F7F7F7 0%, rgba(255, 255, 255, 0) 100%);\n  background: linear-gradient(180deg, #F7F7F7 0%, rgba(255, 255, 255, 0) 100%);\n  margin-bottom: 5rem;\n  position: relative; }\n\n.pageEditing .GalleryCarousel-module__item--1vh-K {\n  width: 100%; }\n\n.GalleryCarousel-module__item--1vh-K {\n  padding: 0 1rem;\n  width: 100%; }\n  .GalleryCarousel-module__item--1vh-K:first-of-type {\n    padding-left: 0; }\n  .GalleryCarousel-module__item--1vh-K:last-of-type {\n    padding-right: 0; }\n\n@media (min-width: 768px) {\n  .GalleryCarousel-module__columns--2F7DE {\n    margin-bottom: 2.6rem; }\n  .GalleryCarousel-module__item--1vh-K {\n    width: 50%; } }\n\n@media (min-width: 1025px) {\n  .GalleryCarousel-module__columns--2F7DE {\n    margin-bottom: 2.167rem; } }\n\n@media (min-width: 1440px) {\n  .GalleryCarousel-module__item--1vh-K {\n    width: 33%; } }\n", ""]);

// exports
exports.locals = {
	"GalleryCarousel": "GalleryCarousel-module__GalleryCarousel--2ZTBr",
	"item": "GalleryCarousel-module__item--1vh-K",
	"columns": "GalleryCarousel-module__columns--2F7DE"
};