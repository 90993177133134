import {useQuery} from '@tanstack/react-query';

export const usePlayerOfTheMatch = ({ matchId }) => {
	const { data, isLoading, isError } = useQuery({ 
		queryKey: ["usePlayerOfTheMatch", matchId],
		queryFn: async () => {
			const match = await fetch(`https://api.ussoccer.com/api/match/${matchId}`)
				.then((r) => r.json())
				.catch((e) => { console.error(e); throw new Error('Match endpoint did not fetch'); });
			const poll = match?.Poll;
			const closedPoll = poll?.Status === "Closed" && await fetch(`https://api.ussoccer.com/api/poll/${poll?.Id}`, {
				mode: 'cors'
			})
				.then((r) => r.json())
				.catch((e) => { console.error(e); throw new Error('Poll endpoint did not fetch') });
			const topResult = closedPoll && Object.entries(closedPoll.PollAnswerTotals).reduce((acc, [resultId, resultCount]) => {
				if (resultCount > acc.resultCount) {
					return { resultId, resultCount };
				}
				return acc;
			}, {resultId: null, resultCount: 0})
			const playerOfTheMatchId = poll?.Status === "Closed" && poll.Choices?.find(choice => choice.Id === topResult.resultId)?.Choice;
			const playerOfTheMatch = playerOfTheMatchId && await fetch(`https://api.ussoccer.com/api/playercareer/${playerOfTheMatchId}`)
				.then((r) => r.json())
				.catch((e) => { console.error(e); throw new Error('Player Career endpoint did not fetch'); });

			return {
				poll,
				closedPoll,
				topResult,
				playerOfTheMatch
			};
		}
	});

	return { 
		playerOfTheMatch: data?.playerOfTheMatch,
		poll: data?.poll,
		isLoading,
		isError
	};
}