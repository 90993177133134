import React, { PureComponent } from "react";
import { compose } from "recompose";
import { Link as RouterLink, withRouter } from "react-router-dom";
import { Link } from "~/patches/components";
import Button from  "~/shared-components/Button";
import { withErrorHandling, withGigya } from "~/hoc";
import { withNamespaces } from "react-i18next";
import { optaApi } from "~/utilities/constants";
import { fieldObjHasValue } from "~/utilities";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import moment from "moment";
import 'moment-timezone';
import styles from "./MatchButtons.module.scss";


class MatchButtons extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			showLinks: false
		};
	}

	componentDidMount() {
		const { location, event } = this.props;

		if (!location.pathname.includes(event.matchFeedUrl)) {
			this.setState({
				showLinks: true
			});
		}
	}

	render() {
		const {
			event,
			type,
			eventType
		} = this.props;

		const { showLinks } = this.state;

		return (
			<>
				{eventType === "competition" ? (
					<CompetitionButtons {...this.props} />
				) : (
					<DefaultButtons {...this.props} showLinks={showLinks} calendarTitle={this.calendarTitle} />
				)}
			</>
		);
	}
}

const CompetitionButtons = ({ event, type, t }) => (
	<>
		{/* If the event is a competition, only display a link to the detail page */}
		{event.previewLink &&
			(event.status === optaApi.matchStatus.upcoming || event.status === optaApi.matchStatus.live) &&
			type === "card" &&
			<div>
				<RouterLink to={event.previewLink.href}>
					<>{t("competition-card_view-competition")}</>
				</RouterLink>
			</div>
		}
		{event.fullRecapLink &&
			event.status === optaApi.matchStatus.completed &&
			type === "card" &&
			<div>
				<RouterLink to={event.fullRecapLink.href}>
					<>{t("competition-card_competition-recap")}</>
				</RouterLink>
			</div>
		}
	</>
);

const DefaultButtons = ({
	event,
	t,
	sitecoreContext,
	showLinks,
	isListView
}) => (
	<>

		{/* Story Preview Link - visible on the match page and before the game has started  */}
		{sitecoreContext?.itemId === event.sitecoreData?.currentItemId &&
			event?.status === optaApi.matchStatus.upcoming &&
			fieldObjHasValue(event?.sitecoreData?.matchPreviewLink, "link") && (
				<Link field={event.sitecoreData.matchPreviewLink}>{t("match-card_match-preview")}</Link>
			)}

		{/* Story Recap Link - visible on the match page and the match is completed  */}
		{(sitecoreContext?.itemId === event.sitecoreData?.currentItemId || isListView) &&
			event?.status === optaApi.matchStatus.completed &&
			fieldObjHasValue(event?.sitecoreData?.matchRecapLink, "link") && (
				<Link field={event.sitecoreData.matchRecapLink}>{t("match-card_recap")}</Link>
			)}

		{/* Presale tickets */}
		{new Date(event.sitecoreData?.presaleTicketsDate?.value) < Date.now() &&
			new Date(event.sitecoreData?.endDate?.value) > Date.now() &&
			<div>
				{event.sitecoreData.ticketsLink && (
					<RouterLink to={event.sitecoreData.ticketsLink}>
						<>{t("match-card_buy-tickets")}</>
					</RouterLink>
				)}
			</div>
		}

		{/* MATCH CARD */}
		{/* Buy match tickets links */}
		{event.sitecoreData?.ticketsLink?.value?.href &&
			event.status === optaApi.matchStatus?.upcoming &&
			<div>
				<Button size="smallCondensed" variant="secondaryReversed" link={event.sitecoreData.ticketsLink.value.href} text={t("match-card_buy-tickets")} />
			</div>
		}

		{/* Preview  */}
		{event.matchFeedUrl &&
			event.status === optaApi.matchStatus.upcoming &&
			showLinks &&
			<div>
				<Button size="smallCondensed" variant="secondaryReversed" link={event.matchFeedUrl} text={t("match-card_hub")} />
			</div>
		}

		{/* Live Match Feed */}
		{event.matchFeedUrl &&
			event.status === optaApi.matchStatus.live &&
			showLinks &&
			<>
				<div className={styles.liveText}>
					<span>LIVE&nbsp;-&nbsp;</span>
					{ event.currentPeriod === optaApi.periodType.halfTime ? `HT`
						: event.currentPeriod === optaApi.periodType.fullTime ? `FT`
							: <>{event.lengthMin}&#8242;</>
					}
				</div>
				<div>
					<Button size="smallCondensed" variant="secondaryReversed" link={event.matchFeedUrl} text={t("match-card_hub")} />
				</div>
			</>
		}

		{/* Recap link w/ matchFeedUrl */}
		{(event.matchFeedUrl || event.fullRecapLink) &&
			event.status === optaApi.matchStatus.completed &&
			showLinks &&
			<div>
				<Button size="smallCondensed" variant="secondaryReversed" link={(event.matchFeedUrl || event.fullRecapLink.href)} text={t("match-card_full-match-recap")} />
			</div>
		}
	</>
);

export default compose(
	withGigya(),
	withNamespaces(),
	withRouter,
	withSitecoreContext(),
	withErrorHandling()
)(MatchButtons);