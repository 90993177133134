exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".LinksBlock-module__LinksBlock--15M7x {\n  margin: 7rem 0; }\n  .LinksBlock-module__LinksBlock--15M7x h2 {\n    font-size: 1.4rem;\n    font-weight: 700;\n    letter-spacing: 0.05em;\n    line-height: 1.07;\n    margin: 0 2rem 2rem;\n    text-transform: uppercase; }\n  .LinksBlock-module__LinksBlock--15M7x ul {\n    background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.04)), to(rgba(0, 0, 0, 0)));\n    background: -o-linear-gradient(top, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0));\n    background: linear-gradient(180deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0));\n    border-top: 0.1rem solid rgba(0, 0, 0, 0.07); }\n  .LinksBlock-module__LinksBlock--15M7x li {\n    border-bottom: 0.1rem solid rgba(0, 0, 0, 0.07);\n    margin: 0 2rem; }\n  .LinksBlock-module__LinksBlock--15M7x a {\n    color: #4d4d4d;\n    display: block;\n    font-size: 1.4rem;\n    letter-spacing: 0.05em;\n    line-height: 1.21;\n    padding: 3.2rem 0;\n    text-transform: uppercase; }\n  .LinksBlock-module__LinksBlock--15M7x svg {\n    float: right;\n    width: 0.7rem; }\n  .LinksBlock-module__LinksBlock--15M7x path {\n    stroke: #808080; }\n\n@media (min-width: 1025px) {\n  .LinksBlock-module__LinksBlock--15M7x {\n    margin: 5.42rem 0; }\n    .LinksBlock-module__LinksBlock--15M7x h2 {\n      font-size: 1.8rem;\n      line-height: 1.3;\n      margin: 0 0 1.5rem; }\n    .LinksBlock-module__LinksBlock--15M7x li {\n      margin: 0;\n      padding-right: 4rem; }\n    .LinksBlock-module__LinksBlock--15M7x a {\n      letter-spacing: 0.05em;\n      line-height: 1.31;\n      padding: 1.6rem 0 1.44rem; } }\n", ""]);

// exports
exports.locals = {
	"LinksBlock": "LinksBlock-module__LinksBlock--15M7x"
};