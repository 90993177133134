import React, { useState, useRef } from "react";
import styles from "./SearchForm.module.scss";
import searchIcon from "~/assets/svg/search-icon.svg";
import { compose } from "recompose";
import { withErrorHandling } from "~/hoc";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { withNamespaces } from 'react-i18next';
import { withRouter } from "react-router";

const SearchForm = props => {
	const { sitecoreContext: { settings }, history, t, forceOpen } = props;
	const searchPage = settings?.fields?.searchPage;
	const searchInput = useRef(undefined);

	const [query, setQuery] = useState("");
	const  [activeSearch, setActiveSearch] = useState(false);
	const [placeholderText, setPlaceholderText] = useState(t("search_form_placeholder"));

	/**
	 * Handle changes in search input field
	 * @param {Event} event
	 */
	function handleQueryChange(event) {
		setQuery(event?.target?.value);
	}

	/**
	 * Open Search
	 * @param {Event} event
	 */
	function openSearch(event) {
		event?.preventDefault();

		searchInput.current.focus();

		if (!activeSearch)
			setActiveSearch(true);
		else
			handleSearch(event);
	}

	/**
	 * Open Search
	 * @param {Event} event
	 */
	function closeSearch(event) {
		event?.preventDefault();

		if (activeSearch) setActiveSearch(false);
	}

	function replaceInQuery(query, oldValue, newValue) {
		return query.split(oldValue).join(newValue);
	}

	/**
	 * Handler to create search route on search
	 * @param {Event} event
	 * @param {boolean} listenForKeyCode - option to listen for key event (enter key)
	 */
	function handleSearch(event, listenForKeyCode = false) {

		if (!activeSearch || !searchPage?.url) return; // keyCode 13 is the enter button

		/* If no search param exists */
		if (!query) {
			setPlaceholderText(t("search_form_placeholder_empty_input"));
			return;
		}

		let parsedQuery = query.toLowerCase().trim();
		parsedQuery = replaceInQuery(parsedQuery, " ", "-");
		parsedQuery = replaceInQuery(parsedQuery, "/", "-");
		parsedQuery = replaceInQuery(parsedQuery, "\\", "-");
		parsedQuery = replaceInQuery(parsedQuery, ".", "");
		parsedQuery = replaceInQuery(parsedQuery, ",", "");

		const searchRoute = `${searchPage.url}/${parsedQuery}`;

		if (event.keyCode === 13 && listenForKeyCode) history.push(searchRoute);

		if (!listenForKeyCode) {
			event?.preventDefault();
			history.push(searchRoute);
		}
	}

	return (
		<>
			{searchPage && (
				<form className={`${styles.container} ${activeSearch ? styles.active : ""} ${forceOpen ? styles.simpleInputs : ""}`} role="search">
					<div className={styles.searchField}>
						{!forceOpen && <CloseButton {...{closeSearch}} />}
						<input
							className={styles.input}
							ref={searchInput}
							type="text"
							value={query}
							onChange={handleQueryChange}
							onKeyDown={event => handleSearch(event, true)}
							placeholder={placeholderText}
							aria-label="Search"/>
					</div>
					<button
						className={styles.searchButton}
						type="submit"
						onClick={event => {
							handleSearch(event);
							openSearch(event);
						}}>
						<img
							src={searchIcon}
							alt="Search icon"
							title="Search" />
					</button>
				</form>

			)}
		</>
	);
};

const CloseButton = ({closeSearch}) => (
	<button className={styles.closeButton} onClick={event => closeSearch(event)}>
		<img
			src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAC0SURBVHgBpdKBCcQgDAXQ2L+La9wC7lRvJwfw1nCZu6Qg9FqN0X5IrREfKLoQQiROSukYV1OdjevLtdfGA2wXC6WUj/de+pFHx/M8YZ2xyKd8Q5qr6BWTHuriLNrC/sAZtIfdQAuqYU1QQ0eYxJESBo7NXHWzig3BC0ojTLLROK7z3wy0xfOdcWUyPClYMDmm9UnBgtW+BYUVs6KYwSwoZrERihVMQ7GK9VDw58WTvII1UPoBFKXLXpLodj4AAAAASUVORK5CYII="
			alt="Close icon"
			title="Close" />
	</button>
);

export default compose(
	withRouter,
	withNamespaces(),
	withSitecoreContext(),
	withErrorHandling()
)(SearchForm);