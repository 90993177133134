import React, { Fragment } from "react";
import { compose } from "recompose";
import { withErrorHandling, withSectionHeader } from "~/hoc";
import TeamThumbnail from "./TeamThumbnail";
import styles from "./TeamGrid.module.scss";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import HelperCommentEdit from "~/experience-editor/HelperCommentEdit";

const TeamGrid = ({ fields, sitecoreContext: { pageEditing} }) => (
	<Fragment>
		{fields && (
			<div className={styles.TeamGrid}>
				{fields.teamContentList && fields.teamContentList.length > 0 && (
					fields.teamContentList.map((team, index) => (
						<div className={styles.item} key={index}>
							<TeamThumbnail {...team.fields} url={team.url} />
						</div>
					))
				)}
				{fields.teamContentList?.length === 0 && pageEditing && (
					<HelperCommentEdit endingEditComment="and select the desired teams" />
				)}
			</div>
		)}
	</Fragment>
);

export default compose(
	withSitecoreContext(),
	withErrorHandling(),
	withSectionHeader()
)(TeamGrid);