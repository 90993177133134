import React, { PureComponent, Fragment } from "react";
import { DateField, withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment";
import { fieldHasValue, fieldObjHasValue } from "~/utilities";
import { withErrorHandling } from "~/hoc";
import { Image, Text } from "~/patches/components";
import { compose } from "recompose";
import placeholderSrc from "~/assets/img/story-thumbnail-placeholder.jpg";
import styles from "./StoryCard.module.scss";
import "moment-timezone";

class StoryCard extends PureComponent {

	constructor(props) {
		super(props);

		this.options = {
			modifierClassName: "",
			imageSizes: undefined,
			imageSrcSet: undefined
		};

		if (props.options) this.setOptionsValues(props.options);
	}

	setOptionsValues = options => {
		this.options.modifierClassName = options.modifierClassName ? styles[options.modifierClassName] : "";
		this.options.imageSizes = options.imageSizes && options.imageSizes;
		this.options.imageSrcSet = options.imageSrcSet && options.imageSrcSet;
	}

	render() {
		const { fields, sitecoreContext } = this.props;
		const isEditable = sitecoreContext && sitecoreContext.pageEditing;
		const itemLink = fields?.itemLink ? fields.itemLink[0] : {};
		const itemLinkFields = itemLink?.fields || {};
		const link = fieldHasValue(fields, "link", "link") ? fields.link.value.href : itemLink?.url || "";
		const date = fieldHasValue(fields, "date", "date") ? fields.date : itemLinkFields.storyDate || fields?.date;
		const heading = fieldHasValue(fields, "heading") ? fields.heading : itemLinkFields.storyHeading || fields?.heading;
		const { categoryValue, categoryKey, categoryUrl } = fields;
		const image = fieldHasValue(fields, "image", "image") ? fields.image : itemLinkFields.thumbnailImage || fields?.image;
		const imageText = fieldHasValue(fields, "imageText") ? fields.imageText : itemLinkFields.imageText || fields?.imageText;

		const trueDate = { value: date?.value.replace(/Z/g, '') };

		return (
			<Fragment>
				{(fields || link || isEditable) && (
					<section
						className={`${styles.Card} ${this.options.modifierClassName}`}>
						{link && (
							<RouterLink className={styles.link} to={link}>
								{image?.value?.src ?
									<Image field={image} srcSet={this.options.imageSrcSet} sizes={this.options.imageSizes}/>
									:
									<img
										src={placeholderSrc}
										alt="" />
								}
							</RouterLink>
						)}
						<div className={styles.content}>
							{(fieldObjHasValue(date, "date") || isEditable) && (
								<DateField tag="time" field={trueDate} render={(date) => moment(date).format("MMMM D YYYY")}/>
							)}
							{categoryUrl && categoryKey && categoryValue && (
								<RouterLink className={styles.category} to={categoryUrl} >
									{categoryValue}
								</RouterLink>
							)}
							<div className={styles.cardCopy}>
								{(heading || isEditable) && (
									<RouterLink className={styles.heading} to={link}>
										<Text tag="h4" field={heading}/>
									</RouterLink>
								)}
								{(imageText || isEditable) && (
									<div className={styles.sponsor}>
										<Text field={imageText} />
									</div>
								)}
							</div>
						</div>
					</section>
				)}
			</Fragment>
		);
	}
}

export default compose(
	withSitecoreContext(),
	withErrorHandling()
)(StoryCard);