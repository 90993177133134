import React, { Component } from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { ChevronButton } from "~/shared-components/SvgIcons";
import { fieldHasValue } from "~/utilities";
import { RichText, Image } from "~/patches/components";
import { compose } from "recompose";
import { withErrorHandling } from "~/hoc";
import styles from "./Bio.module.scss";

class Bio extends Component {
	constructor(props) {
		super(props);

		this.textContainerRef = React.createRef();
		this.state = {
			isTruncated: true
		};
	}

	componentDidMount() {
		const containerHeight = this.textContainerRef.current?.clientHeight || 0;
		const scrollHeight = this.textContainerRef.current?.scrollHeight || 0;

		if (scrollHeight <= containerHeight) {
			this.setState({ isTruncated: false });
		}
	}

	render() {
		const { fields, sitecoreContext: { pageEditing } } = this.props;
		const { isTruncated } = this.state;

		return (
			<>
				{(fieldHasValue(fields, "bio") || pageEditing) && (
					<div className={`${styles.Bio} ${(!isTruncated && fields.bio) ? styles.bioAutoHeight : styles.bioMaxHeight}`}>
						<div className={`${styles.contentWrapper} ${!fieldHasValue(fields, "bioPlayerImage", "image") ? styles.centerText : ""}`}>

							{(fieldHasValue(fields, "bioPlayerImage", "image") || pageEditing) && (
								<div className={styles.imageContainer}>
									<Image
										field={fields.bioPlayerImage}
										srcSet={[{mw: 440}, {mw: 300}]}
										sizes={"(min-width: 768px) 440px, 300px"} />
								</div>
							)}

							<div className={!fieldHasValue(fields, "bioPlayerImage", "image") ? styles.textFullWidth : ""}>
								<div className={isTruncated && !pageEditing ? styles.textTruncated : styles.text} ref={this.textContainerRef}>
									<RichText field={fields.bio} />
								</div>
								{!pageEditing && isTruncated && <div className={styles.chevButton} onClick={() => this.setState({ isTruncated: false })}>
									<ChevronButton />
								</div>}
							</div>
						</div>
					</div>
				)}
			</>
		);
	}
}

export default compose(
	withSitecoreContext(),
	withErrorHandling()
)(Bio);