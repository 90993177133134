import React from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";

const TabsTab = ({ rendering }) => (
	<>
		{rendering && (
			<>
				<Placeholder
					name="jss-tabs-tab-content"
					rendering={rendering}
					renderEach={component => (
						component
					)}
				/>
			</>
		)}
	</>
);

export default compose(
	// other hocs goes here
	withErrorHandling()
)(TabsTab);