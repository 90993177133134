import React from "react";
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import { fieldObjHasValue } from "~/utilities";
import styles from "./MembershipHero.module.scss";

const MembershipHero = ({ fields, sitecoreContext : { pageEditing } }) => {
	const {
		image,
		imageMobile,
		membershipLevel,
	} = fields;

	const backgroundStyleDesktop = fieldObjHasValue(image, "image")
		? { backgroundImage: `url(${image?.value?.src})`}
		: {};

	const backgroundStyleMobile = fieldObjHasValue(imageMobile, "image")
		? {backgroundImage: `url(${imageMobile?.value?.src})`}
		: {};

	const desktopStyle = `${styles.desktopHero}`;
	const mobileStyle = `${styles.mobileHero}`;

	const membershipCost = membershipLevel?.fields?.price?.value === 'Free' ? 'Free' : `${membershipLevel?.fields?.price?.value}/YR`;

	const getHero = (classNames, backgroundImage) =>
		<div className={`${styles.membershipHero} ${classNames}`} style={backgroundImage}>
			<div className={`${styles.textContainer} ${backgroundImage?.backgroundImage ? styles.whiteText : styles.blueText}`}>
				<div className={styles.membershipBracket}>
					<div className={styles.membershipDetails}>
						<div className={styles.membershipName}>{membershipLevel?.name}</div>
						<div className={styles.membershipCost}>{membershipCost}</div>
					</div>
				</div>
			</div>
		</div>;

	return (
	<>
		{(fields || pageEditing) && (
			<>
			{getHero(desktopStyle, backgroundStyleDesktop)}
			{getHero(mobileStyle, backgroundStyleMobile)}
			</>
		)}
	</>
	);
};

export default compose(
	withSitecoreContext(),
	withErrorHandling()
)(MembershipHero);