exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".TablePlayers-module__TablePlayers--1_zVY {\n  margin-bottom: calc(20px * 3); }\n\n.TablePlayers-module__playerName--12kmS {\n  -ms-flex-align: center;\n      align-items: center;\n  display: -ms-flexbox;\n  display: flex; }\n\n.TablePlayers-module__playerImage--3GSYI {\n  float: left;\n  height: 3.7rem;\n  margin-right: 1rem;\n  width: 3.7rem; }\n\n@media (min-width: 1025px) {\n  .TablePlayers-module__TablePlayers--1_zVY {\n    margin: 0 auto calc(20px * 3);\n    max-width: 83.75rem; }\n  .TablePlayers-module__playerImage--3GSYI {\n    height: 3.084rem;\n    width: 3.084rem; } }\n\n@media (min-width: 1440px) {\n  .TablePlayers-module__TablePlayers--1_zVY {\n    max-width: 117.8rem; } }\n", ""]);

// exports
exports.locals = {
	"TablePlayers": "TablePlayers-module__TablePlayers--1_zVY",
	"playerName": "TablePlayers-module__playerName--12kmS",
	"playerImage": "TablePlayers-module__playerImage--3GSYI"
};