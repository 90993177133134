exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".TextLink-module__TextLinkBlue--1CFpu {\n  color: #15192B; }\n\n.TextLink-module__TextLinkRed--62pgn {\n  color: #C2002F; }\n\n.TextLink-module__TextLinkBlack--dWdXK {\n  color: #000000; }\n\n.TextLink-module__TextLinkSmall--1jsEC {\n  font-size: 9.6px;\n  font-weight: 700;\n  letter-spacing: 0.07em;\n  line-height: 11px; }\n\n.TextLink-module__TextLinkMedium--aOZ6X {\n  font-size: 12px;\n  font-weight: 500;\n  letter-spacing: 0.05em;\n  line-height: 12px; }\n\n.TextLink-module__TextLinkLarge--1Tfi2 {\n  font-size: 14.4px;\n  font-weight: 700;\n  letter-spacing: 0.05em;\n  line-height: 14px; }\n\n.TextLink-module__TextLinkUpper--2t2bk {\n  text-transform: uppercase; }\n\n.TextLink-module__TextLink--1Wj5G {\n  font-family: 'USSF 90 Min Display', sans-serif;\n  text-transform: uppercase; }\n  .TextLink-module__TextLink--1Wj5G:hover {\n    border-bottom: 2px solid #C2002F; }\n", ""]);

// exports
exports.locals = {
	"TextLinkBlue": "TextLink-module__TextLinkBlue--1CFpu",
	"TextLinkRed": "TextLink-module__TextLinkRed--62pgn",
	"TextLinkBlack": "TextLink-module__TextLinkBlack--dWdXK",
	"TextLinkSmall": "TextLink-module__TextLinkSmall--1jsEC",
	"TextLinkMedium": "TextLink-module__TextLinkMedium--aOZ6X",
	"TextLinkLarge": "TextLink-module__TextLinkLarge--1Tfi2",
	"TextLinkUpper": "TextLink-module__TextLinkUpper--2t2bk",
	"TextLink": "TextLink-module__TextLink--1Wj5G"
};