exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".HelperCommentEdit-module__container--1ipcQ {\n  font-size: 1.3rem;\n  line-height: 1.2;\n  margin: 0 auto;\n  max-width: 19rem;\n  padding: 2rem 0; }\n  .HelperCommentEdit-module__container--1ipcQ img {\n    width: 15px; }\n", ""]);

// exports
exports.locals = {
	"container": "HelperCommentEdit-module__container--1ipcQ"
};