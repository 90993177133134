exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".pageEditing .MatchesResultsGrid-module__container--1EotY {\n  min-height: 16.666rem; }\n\n.MatchesResultsGrid-module__loading--vz2PK {\n  color: #C2002F;\n  font-size: 2rem;\n  margin: 10rem auto;\n  text-align: center;\n  text-transform: uppercase; }\n", ""]);

// exports
exports.locals = {
	"container": "MatchesResultsGrid-module__container--1EotY",
	"loading": "MatchesResultsGrid-module__loading--vz2PK"
};