import React, { Fragment } from "react";
import { Text, Image } from "~/patches/components";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import { withNamespaces } from 'react-i18next';
import ArrowLink from "~/shared-components/ArrowLink";
import styles from "./P1Announcement.module.scss";

const P1Announcement = ({ fields }) => (
	<Fragment>
		{fields && (
			<div className={styles.P1Announcement}>
				<div className={styles.textContainer}>
					<div className={styles.inner}>
						<div className={styles.title}>
							<Text field={fields.title} />
						</div>
						<div className={styles.subTitle}>
							<Text field={fields.subTitle} />
						</div>
						<div className={styles.arrowLink}>
							<ArrowLink link={fields.storyLink} linkText={fields.storyLink.value.text} />
						</div>
					</div>
				</div>
				<Image field={fields.desktopImage} className={styles.desktop} />
				<Image field={fields.mobileImage} className={styles.mobile} />
			</div>
		)}
	</Fragment>
);

export default compose(
	// other hocs goes here
	withNamespaces(),
	withErrorHandling()
)(P1Announcement);