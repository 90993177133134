import React from 'react';

import styles from "../../RedesignScoreboardBanner.module.scss";
import ScoreboardThumbnailContainer from "./../../ScoreboardThumbnailContainer";

export const CompletedMatches = ({ completedMatches, t, scoreboardCardRef }) => (
	<>
		{
			completedMatches.map((item) => (
				<div key={item.matchId} className={styles.item} ref={scoreboardCardRef} data-match-tile={true}>
					<ScoreboardThumbnailContainer redesign={true} {...{ ...item, feedUrlName: t("scoreboard_recent_feed_link_name") }} />
				</div>
			))
		}
	</>
);
