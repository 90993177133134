import React from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import { Link, RichText, Text } from "~/patches/components";
import styles from "./CallToActionBlock.module.scss";

const CallToActionBlock = ({ fields }) => (
	<>
		{fields && (
			<div className={styles.CallToActionBlock}>
				<Text tag="h2" field={fields.heading} />
				<RichText tag="p" field={fields.description} />
				<Link field={fields.link} />
			</div>
		)}
	</>
);

export default compose(
	// other hocs goes here
	withErrorHandling()
)(CallToActionBlock);