import React from "react";
import { Link } from "~/patches/components";

/**
 * This makes it posible to wrap components with an a tag,
 * if sitecore link has an href otherwiser the components will not be wrapped.
 *
 * @param {object} props - Component props
 */
const LinkWrapper = props => {

	if (props.link && props.link.value?.href) {
		return (
			<Link field={props.link}>
				{props.children}
			</Link>
		);
	}

	return (
		props.children
	);
};

export default LinkWrapper;