exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".InstagramWidget-module__container--35Oht {\n  margin: 0 auto;\n  max-width: 1440px;\n  padding: 0 20px; }\n  .InstagramWidget-module__container--35Oht .instagram-media-rendered {\n    margin: 0 auto 2rem !important; }\n", ""]);

// exports
exports.locals = {
	"container": "InstagramWidget-module__container--35Oht"
};