/* eslint-disable react/jsx-key */
import React from "react";
import { compose } from "recompose";
import { withErrorHandling } from "~/hoc";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import P1Event from "~/shared-components/P1Event";
import MatchInfo from "~/shared-components/MatchInfo";
import MatchListItem from "~/shared-components/MatchList/MatchListItem";
import MatchListItemRedesign from "~/shared-components/MatchList/MatchListItemRedesign";
import { Image } from "~/patches/components";
import styles from "./P1Match.module.scss";
import HelperCommentEdit from "~/experience-editor/HelperCommentEdit";
import { useFetchMatch } from "~/hooks/match";
import { isRedesign } from "~/utilities/helpers";

const P1Match = ({ rendering: { match }, sitecoreContext: { pageEditing } }) => {
	const doubleHeaderMatch = useFetchMatch(match?.sitecoreData?.doubleHeaderMatchId?.value);

	return (
		<>
			{!match && pageEditing && (
				<HelperCommentEdit endingEditComment="and choose a reference item. Can either be a Competition Page or a Match Page" />
			)}
			{match && (
				<P1Event event={match} type={match.sitecoreData?.playerCutoutHome?.value ? "matchWithPlayers" : "match"} pageEditing={pageEditing}>
					<MatchInfo match={match} pageEditing={pageEditing} type="P1"/>
					{(match.sitecoreData?.playerCutoutHome?.value && match.status !== 0 || pageEditing) &&
						<div className={styles.playerImageContainerHome}>
							<Image field={match.sitecoreData?.playerCutoutHome} />
						</div>
					}

					{(match.sitecoreData?.playerCutoutAway?.value && match.status !== 0 || pageEditing) &&
						<div className={styles.playerImageContainerAway}>
							<Image field={match.sitecoreData?.playerCutoutAway} />
						</div>
					}
				</P1Event>
			)}
			{/* Passing a single null/undefined double header match is currently necessary to set the right Match Status value without showing extra
			match list items. This is hacky, and we should fix it. */}
			{doubleHeaderMatch && !isRedesign && <MatchListItem match={doubleHeaderMatch} doubleHeaderMatches={[null]} showDynamicScore={false} showButtons={false} />}
			{doubleHeaderMatch && isRedesign && <MatchListItemRedesign match={doubleHeaderMatch} doubleHeaderMatches={[null]} showDynamicScore={false} showButtons={false} />}
		</>
	);
};

export default compose(
	// other hocs goes here
	withSitecoreContext(),
	withErrorHandling()
)(P1Match);