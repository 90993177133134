import React from 'react';
import styles from "../RedesignMiniFeaturedMatch.module.scss";
import { useNextMatch } from "~/hooks/useNextMatch";
import { allFlags } from "~/utilities/flags";
import { optaApi } from "~/utilities/constants";


export const MiniMatchNextGame = ({ match }) => {
	const contestantId = match?.contestants?.find(contestant => contestant?.code === 'USA')?.id;
	const { nextMatch } = useNextMatch({ contestantId });
	if (!nextMatch) return null;
	const homeTeam = nextMatch?.Contestants?.[0];
	const awayTeam = nextMatch?.Contestants?.[1];
	const usTeam = nextMatch?.Contestants?.find(contestant => contestant.Code === "USA");
	const USTeamNames = {
		"e70zl10x0ayu7y10ry0wi465a": "USWNT",
		"9vh2u1p4ppm597tjfahst2m3n": "USMNT",
	};
	const USTeamName = USTeamNames[usTeam.Id];
	const matchDateTime = new Date(nextMatch.Date);
	matchDateTime.setTime(matchDateTime.getTime() - matchDateTime.getTimezoneOffset() * 60 * 1000);
	const formattedMatchTime = new Intl.DateTimeFormat('en-US', {
		year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZoneName: 'short'
	}).format(matchDateTime).replace(':00 ', '').replaceAll(',', '');

	return (
		<div className={styles.miniMatchNextGame}>
			<div>
				<div className={styles.nextMatchDateLabel}>Next Match</div>
				<div className={styles.nextMatchDate}>{formattedMatchTime}</div>
			</div>
			<div className={`${styles.team} ${styles.homeTeam} ${nextMatch.Status !== optaApi.matchStatus.live ? "" : styles.live}`}>
				<div className={styles.flag}>{homeTeam?.Code === 'TBD' ? allFlags.PLACEHOLDER : allFlags[homeTeam.Code]}</div>
				<div className={styles.name}>{homeTeam?.Code === 'USA' ? <>{USTeamName ? USTeamName : homeTeam.Code} </> : <>{homeTeam.OfficialName}</>}</div>
				<div className={styles.shortName}>{homeTeam?.Code === 'USA' ? <>{USTeamName ? USTeamName : homeTeam.Code} </> : <>{homeTeam.Code}</>}</div>
			</div>
			<div className={`${styles.team} ${styles.awayTeam} ${nextMatch.Status !== optaApi.matchStatus.live ? "" : styles.live}`}>
				<div className={styles.flag}>{awayTeam?.Code === 'TBD' ? allFlags.PLACEHOLDER : allFlags[awayTeam.Code]}</div>
				<div className={styles.name}>{awayTeam?.Code === 'USA' ? <>{USTeamName ? USTeamName : awayTeam.Code} </> : <>{awayTeam.OfficialName}</>}</div>
				<div className={styles.shortName}>{awayTeam?.Code === 'USA' ? <>{USTeamName ? USTeamName : awayTeam.Code} </> : <>{awayTeam.Code}</>}</div>
			</div>
		</div>
	);
};