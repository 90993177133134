import React, { PureComponent } from "react";
import { Portal } from 'react-portal';

import styles from "./Modal.module.scss";

class Modal extends PureComponent {

	componentDidMount() {
		if (this.props.active) {
			document.body.classList.add("overflowHidden"); //eslint-disable-line
		}
	}
	
	componentWillUnmount() {
		document.body.classList.remove("overflowHidden"); //eslint-disable-line
	}

	render() {
		const { active, children, type } = this.props;

		return (
		<>
			{active && 
				<Portal>
					<div className={`${styles.Modal} ${styles[type]}`}>
						<div className={styles.content}>
							{children}
						</div>
					</div>
				</Portal>
			}
		</>
		);
	}
}

export default Modal;