import React, { PureComponent } from "react";
import styles from "./MultiLevelMenu.module.scss";
import { Text } from "~/patches/components";
import { Arrow } from "~/shared-components/SvgIcons";
import { compose } from "recompose";
import { withErrorHandling } from "~/hoc";
import MenuLevel from "./MenuLevel";
import { setHeightByLevel, handleLevelClasses, setInitialActiveLevel } from "./helpers";
import { withRouter } from "react-router";

class MultiLevelMenu extends PureComponent {

	constructor (props) {
		super(props);

		this.state = {
			goToMenu: undefined,
			prevMenu: undefined,
			goBack: false,
			goNext: true,
			menuToggle: false,
			activeLevelId: undefined,
			initialLoad: true
		};

		this.navigationRoot = React.createRef();
	}

	componentDidMount() {
		this.setState({ activeLevelId: this.props.currentPageId});
	}

	componentDidUpdate(prevProps) {
		if (this.props.currentPageId !== prevProps.currentPageId) {
			this.setState({
				goToMenu: undefined,
				prevMenu: undefined,
				goBack: false,
				goNext: true,
				menuToggle: false,
				activeLevelId: this.props.currentPageId,
				initialLoad: true
			});
			this.navigationRoot = React.createRef();
		}

		if (!this.state.initialLoad)
			handleLevelClasses(this.state, this.navigationRoot?.current);

		if (this.state.initialLoad)
			setInitialActiveLevel(this.state, this.setState.bind(this), this.navigationRoot?.current);

		if (this.props.location !== prevProps.location)
			this.setState({menuToggle: false});

	}

	componentWillUnmount() {
		document.body.classList.remove('overflowHidden'); // eslint-disable-line no-restricted-globals
	}

	menuToggle() {
		this.setState(prevState => ({menuToggle: !prevState.menuToggle}));
	}

	render() {
		const {
			heading,
			id,
			links,
			menuTitle,
			renderLogout,
			sidebarMenuTitle,
			url
		} = this.props;
		const { menuToggle } = this.state;
		const currentPage = {
			id,
			menuTitle,
			sidebarMenuTitle,
			url
		};

		return (
			<div className={styles.mobilePadding}>
				<aside className={`${styles.multiLevelMenu} ${menuToggle ? styles.openMenu : ""}`}>
					<header>
						<Text tag="h5" field={heading} />
						<button className={styles.menuToggle} onClick={() => this.menuToggle()}>
							<Arrow />
							<span className={styles.menuDropdown}>menu</span>
						</button>
					</header>
					<nav ref={this.navigationRoot} className={styles.container}>
						<div className={styles.inner} style={setHeightByLevel(this.state, this.navigationRoot?.current)}>
							<MenuLevel
								state={this.state}
								setState={this.setState.bind(this)}
								childs={links}
								currentPage={currentPage}
								renderLogout={renderLogout} />
						</div>
					</nav>
				</aside>
				<div className={`${styles.background} ${this.state.menuToggle ? styles.showBackground : ""}`}></div>
			</div>
		);
	}
}

export default compose(
	withRouter,
	withErrorHandling()
)(MultiLevelMenu);