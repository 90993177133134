exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".RedesignBrightcoveFilmstrip-module__BrightcoveFilmstripContainer--VvuUU .RedesignBrightcoveFilmstrip-module__FilmStrip--3ftGS {\n  margin: 0 auto;\n  width: 100%; }\n", ""]);

// exports
exports.locals = {
	"BrightcoveFilmstripContainer": "RedesignBrightcoveFilmstrip-module__BrightcoveFilmstripContainer--VvuUU",
	"FilmStrip": "RedesignBrightcoveFilmstrip-module__FilmStrip--3ftGS"
};