import React from "react";
import { Text } from "~/patches/components";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";

const MyTickets = ({ fields }) => (
	<>
		{fields && (
			<div>
				<Text tag="h3" field={fields.heading} />
			</div>
		)}
	</>
);

export default compose(
	// other hocs goes here
	withErrorHandling()
)(MyTickets);