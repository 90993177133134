import React, { Component } from "react";
import { compose } from "recompose";
import TagManager from 'react-gtm-module';
import { withNamespaces } from "react-i18next";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import moment from "moment";
import 'moment-timezone';
import { withGigya } from "~/hoc";
import { Image } from "~/patches/components";
import Modal from "~/shared-components/Modal";
import { membershipName } from "~/utilities/helpers";
import logo from "~/assets/img/logo-color.png";
import styles from "./BillingModal.module.scss";
import GigyaScreenSet from "~/shared-components/GigyaScreenSet";
import CheckoutForm from "~/shared-components/CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";


class BillingModal extends Component {
	constructor(props) {
		super(props);

		const { sitecoreContext: { membershipLevels }, selectedTier } = this.props;

		this.state = {
			errorMessage: null,
			isError: false,
			isProRate: false,
			proRateCredit: 0,
			proRatePayment: parseInt(membershipLevels[selectedTier]?.price?.value.replace(',', '').slice(1)),
			isSuccess: false,
			isAlreadyReceivingBenefits: false,
			isLoading: false,
			showCardOnFileRadioSet: false,
			useCardOnFile: true,
			cardBrand: null,
			cardLast4: null,
			showBillingInformation: true,
			stripePromise: null,
			disabled: false
		};
	}

	componentDidMount = () => {
		const { gigya: { isLoggedIn, data }, sitecoreContext: { stripe: { apiKey } }, selectedTier, updateBillingInfo } = this.props;

		if (isLoggedIn && !updateBillingInfo && data?.membershipTier >= selectedTier) {
			this.setState({ isAlreadyReceivingBenefits: true });
		}

		if (this.state.stripePromise === null) {
			const stripePromise = loadStripe(apiKey);
			this.setState({ stripePromise });
		}
	}

	componentDidUpdate = prevProps => {
		const { gigya: prevGigya } = prevProps;
		const { gigya: { isLoggedIn, data }, selectedTier, updateBillingInfo } = this.props;

		if (prevGigya.data !== data && isLoggedIn && !updateBillingInfo && data?.membershipTier >= selectedTier) {
			this.setState({ isAlreadyReceivingBenefits: true });
		}

		if (prevProps.updateBillingInfo !== updateBillingInfo && updateBillingInfo) {
			this.setState({ isAlreadyReceivingBenefits: false });
		}
	}

	setGTMRegistrationOrUpgradeEvent = (tier, isUpgrade = false) => {
		let eventName = '';
		const tierString = tier.toString();

		switch (tierString) {
			case '0':
				eventName = 'insiderStandard';
				break;
			case '1':
				eventName = 'insiderPremium';
				break;
			case '2':
				eventName = 'insiderPremiumFamily';
				break;
			case '3':
				eventName = 'insiderVip';
				break;
			case '4':
				eventName = 'insiderCircle';
				break;
			case '5':
				eventName = 'insiderSupporter';
				break;
			case '6':
				eventName = 'insiderCaptain';
				break;
			case '7':
				eventName = 'insiderCoach';
				break;
			case '8':
				eventName = 'insiderPresident';
				break;
		}

		if (isUpgrade) {
			eventName = `${eventName}Upgrade`;
		} else {
			eventName = `${eventName}Registration`;
		}

		const insiderAccountRegistrationOrUpgradeArgs = {
			dataLayer: {
				event: eventName,
				tier: tierString
			}
		};

		TagManager.dataLayer(insiderAccountRegistrationOrUpgradeArgs);
	}

	showErrorsInDialog = error => {
		console.log("Error: ", error); //eslint-disable-line
		this.setState({
			disabled: false,
			errorMessage: error.message,
			isError: true,
			isLoading: false
		});
	}

	gigyaHandlers = {
		onAfterSubmit: event => this.gigyaOnAfterSubmitCallback(event),
		onAfterValidation: event => {
			if (Object.entries(event.formErrors).length > 0) {
				this.showErrorsInDialog({ message: this.props.t("billingmodal_address-validation-error") });
			}
			else {
				this.setState({ isError: false });
			}
		}
	};

	gigyaAddressScreenSetDivLocator = domNode => {
		this.gigyaAddressScreenSetDivDOMNode = domNode;
	}

	// SUBMIT STEP 1 - bound to the submit.click()
	submit = async event => {
		event?.preventDefault();
		const gigyaAddressSubmitButton = this.gigyaAddressScreenSetDivDOMNode.getElementsByClassName('gigya-input-submit')[0];
		if (gigyaAddressSubmitButton) {
			this.setState({ isLoading: true, disabled: true });
			await gigyaAddressSubmitButton.click();	 // this simulates clicking the Gigya Screen-Set Submit button
		}

		return this.state.isError;
	}

	// SUBMIT STEP 2 - bound to the onAfterSubmit callback of the Gigya Screen-Set, invoked async via gigyaAddressSubmitButton.click() in the submit method above.
	gigyaOnAfterSubmitCallback = async event => {
		if (event?.response?.errorCode !== 0) {
			this.setState({ isLoading: false });
			// no need to set errors, the Gigya Screen-Set provides validation feedback
			return;
		}
	}

	close = () => {
		const { onClose, gigya: { refreshUserData } } = this.props;
		refreshUserData();
		onClose();
	}

	handleOptionChange = event => {
		if (event.target.value === "1") {
			this.setState({ showBillingInformation: false });
			this.setState({ useCardOnFile: true });
		} else if (event.target.value === "0") {
			this.setState({ showBillingInformation: true });
			this.setState({ useCardOnFile: false });
		} else {
			this.setState({ showBillingInformation: false });
			this.setState({ useCardOnFile: true });
		}
	}

	render() {
		const {
			selectedTier,
			showModal,
			updateBillingInfo,
			gigya: { isLoggedIn, data, userInfo },
			sitecoreContext: { membershipLevels },
			t
		} = this.props;

		const {
			proRatePayment,
			isSuccess,
			isAlreadyReceivingBenefits,
			useCardOnFile,
			showCardOnFileRadioSet,
			cardBrand,
			cardLast4,
			stripePromise
		} = this.state;

		const mode = updateBillingInfo ? 'setup' : 'payment';
		const amount = updateBillingInfo ? null : Math.ceil(proRatePayment * 100);
		const options = {
			mode,
			amount,
			currency: 'usd',
			setupFutureUsage: 'off_session',
			paymentMethodTypes: [
				"card",
				"us_bank_account"
			]
		};

		const endDate = data?.membership?.subscriptions?.end
			? moment(data.membership.subscriptions.end)
				.tz(moment.tz.guess())
				.format("MMM DD YYYY")
			: "";

		return (
			<Modal active={showModal} type={"billing"}>
				<div className={styles.BillingModal}>
					<button onClick={() => this.close()} className={`${styles.closeButton} ${selectedTier === 1 || selectedTier === 2 ? styles.darkColor : ""}`}></button>

					<div className={styles.p1}>
						<div className={`${styles.textContainer} ${selectedTier === 1 || selectedTier === 2 ? styles.darkColor : ""}`}>
							<div className={styles.shield}>
								<img
									src={logo}
									alt="U.S. Soccer logo"
									title="U.S. Soccer" />
							</div>
							<div className={styles.textWrapper}>
								<div>{membershipName(selectedTier)}</div>
								<div>{selectedTier !== 0 && `${t("billingmodal_auto-renew")} ${!updateBillingInfo ? moment().tz(moment.tz.guess()).add(1, "y").format("MMM DD YYYY") : endDate}`}</div> {/* eslint-disable-line */}
							</div>
						</div>
						<Image field={membershipLevels && membershipLevels[selectedTier]?.bannerImage} />
					</div>

					<div className={styles.mobileShield}>
						<img
							src={logo}
							alt="U.S. Soccer logo"
							title="U.S. Soccer" />
					</div>

					{isSuccess &&
						<>
							<div className={`${styles.alertCard} ${styles.success}`}>
								<h2>
									{updateBillingInfo
										? t("billingmodal_update-success")
										: userInfo //check if new sign up or upgrade, userInfo object does not exist until modal is closed when new signup on billing modal
											? t("billingmodal_upgrade-success")
											: t("billingmodal_signup-success")
									}
								</h2>
							</div>
							<a className={styles.viewProfileButton} href="/profile/my-profile">view profile</a>
						</>
					}

					{isLoggedIn
						? <>
							{!isAlreadyReceivingBenefits && !isSuccess
								? <>
									<div className={styles.addressInfo}>
										<div className={styles.formHeading}>
											<span>{t("billingmodal_address-info")}</span>
											<span>{t("billingmodal_address-info")}</span>
										</div>
										<GigyaScreenSet
											screenSetId={"Default-ProfileUpdate"}
											startScreenId={"shipping-address-screen"}
											handlers={this.gigyaHandlers}
											domNodeLocator={this.gigyaAddressScreenSetDivLocator} />
									</div>
									{showCardOnFileRadioSet ?
										<>
											<div className={styles.cardInfo}>
												<div className={styles.formHeading}>
													<span>&nbsp;</span>
													<span>Card on file: &nbsp;&nbsp;</span>
													<span className={styles.cardOnFile}>{cardBrand} <span className={styles.last4}> -ending in</span> {cardLast4}</span>
												</div>
												<form>
													<div>
														<label className={styles.containRadioInput}>
															Use card on file
															<input type="radio" name="useFileCard" onChange={this.handleOptionChange} value="1" checked={useCardOnFile} className={styles.radioInput} />
															<span className={styles.radioDot}></span>
														</label>
													</div>
													<div>
														<label className={styles.containRadioInput}>
															Enter new card information
															<input type="radio" name="useFileCard" onChange={this.handleOptionChange} value="0" checked={!useCardOnFile} className={styles.radioInput} />
															<span className={styles.radioDot}></span>
														</label>
													</div>
												</form>
											</div>
										</>
										:
										<>
										</>
									}
								</>
								: !isSuccess
									? data?.membershipTier === 0
										?
										<>
											<div className={`${styles.alertCard} ${styles.success}`}>
												<h2>{t("billingmodal_signup-success")}</h2>
											</div>
											<a className={styles.viewProfileButton} href="/profile/my-profile">view profile</a>
										</>
										: <>
											<div className={`${styles.alertCard} ${styles.success}`}>
												<h2>{t("billingmodal_already-receiving-benefits")}</h2>
											</div>
											<a className={styles.viewProfileButton} href="/profile/my-profile">view profile</a>
										</>
									: ""}
						</>
						: <GigyaScreenSet screenSetId={"USSF-RegistrationLogin"} startScreenId={"gigya-register-screen"} />
					}

					{stripePromise && options && 
						<Elements stripe={stripePromise} options={options}>
							<CheckoutForm 
								billingProps={{ ...this.props }} 
								state={{ ...this.state }} 
								setState={this.setState.bind(this)} 
								gigyaSubmitHandler={this.submit.bind(this)}
								GTMHandler={this.setGTMRegistrationOrUpgradeEvent.bind(this)}
							/>
						</Elements>
					}
				</div>
			</Modal>
		);
	}
}

export default compose(
	withGigya(),
	withNamespaces(),
	withSitecoreContext(),
)(BillingModal);