import React from "react";
import { withErrorHandling, withSectionHeader } from "~/hoc";
import { compose } from "recompose";
import PlayerMatchTable from "./PlayerMatchTable";

const PlayerMatchStats = ({ rendering: { matchStats, /*playerId, playerStatsUrl */} }) => (
	<>
		<PlayerMatchTable matchStats={matchStats} />
	</>
);

export default compose(
	withSectionHeader(({fields, rendering: { playerStatsUrl }}) => {
		if (!playerStatsUrl) return;
		fields.sectionLink.value.href = playerStatsUrl;
	}),
	withErrorHandling()
)(PlayerMatchStats);