exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".LatestVideos-module__wrap--1yxA_ {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-direction: column;\n      flex-direction: column;\n  width: 267px;\n  margin-left: 57px; }\n  .LatestVideos-module__wrap--1yxA_ .LatestVideos-module__sectionTitle--csIAV {\n    font-weight: 600;\n    font-size: 24px;\n    line-height: 31px;\n    letter-spacing: 0.02em;\n    text-transform: uppercase;\n    color: #000000;\n    margin: 0 0 14px 0; }\n    .LatestVideos-module__wrap--1yxA_ .LatestVideos-module__sectionTitle--csIAV a {\n      color: inherit;\n      text-decoration: none; }\n    .LatestVideos-module__wrap--1yxA_ .LatestVideos-module__sectionTitle--csIAV svg {\n      color: #C2002F;\n      height: .9em;\n      margin: 0 0 -0.05em 0.2em;\n      width: auto; }\n  @media screen and (min-width: 1640px) {\n    .LatestVideos-module__wrap--1yxA_ {\n      width: 362px; } }\n  @media screen and (max-width: 1239px) {\n    .LatestVideos-module__wrap--1yxA_ {\n      margin-left: 0;\n      width: 100%; } }\n  @media screen and (max-width: 833px) {\n    .LatestVideos-module__wrap--1yxA_ {\n      margin-left: 0;\n      margin-top: 30px; } }\n  .LatestVideos-module__wrap--1yxA_ .LatestVideos-module__inner--50e7Y {\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-direction: column;\n        flex-direction: column;\n    -ms-flex-pack: justify;\n        justify-content: space-between;\n    -ms-flex: 1 1;\n        flex: 1 1; }\n    .LatestVideos-module__wrap--1yxA_ .LatestVideos-module__inner--50e7Y > div {\n      margin: 0; }\n      .LatestVideos-module__wrap--1yxA_ .LatestVideos-module__inner--50e7Y > div iframe {\n        border: 0;\n        border-radius: 4px; }\n    @media screen and (max-width: 1239px) {\n      .LatestVideos-module__wrap--1yxA_ .LatestVideos-module__inner--50e7Y > div + div {\n        margin-top: 16px; } }\n", ""]);

// exports
exports.locals = {
	"wrap": "LatestVideos-module__wrap--1yxA_",
	"sectionTitle": "LatestVideos-module__sectionTitle--csIAV",
	"inner": "LatestVideos-module__inner--50e7Y"
};