import React from "react";
import { compose } from "recompose";
import { withErrorHandling } from "~/hoc";
import { Image, Text } from "~/patches/components";
import { DateField, withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import moment from "moment";
import styles from "./GalleryThumbnail.module.scss";
import { Link as RouterLink } from "react-router-dom";
import "moment-timezone";
import { generateCateoryUrl } from "~/utilities";

const GalleryThumbnail = props => {
	const { categoryValue, categoryKey, date, heading, url, images: [image1, image2, image3, image4, ...remainingImages], sitecoreContext: { settings } } = props;
	const trueDate = { value: date.value.replace(/Z/g, '') };
	const categoryUrl = generateCateoryUrl(categoryKey, settings);

	return (
		<section className={styles.GalleryThumbnail}>
			<RouterLink to={url}>
				{image1 && (
					<div className={styles.galleryContainer}>
						<div className={styles.largeImage}>
							<Image field={image1} />
						</div>
						<div className={styles.sideImageContainer}>
							<div className={styles.sideImage}>
								<Image field={image2} />
							</div>
							<div className={styles.sideImage}>
								<Image field={image3} />
							</div>
							<div className={styles.sideImage}>
								<Image field={image4} />
							</div>
							{remainingImages.length > 0 && (
								<div className={styles.sideImage}>
									<div className={styles.imagesRemaining}>
										+ {remainingImages.length}
									</div>
								</div>
							)}
						</div>
					</div>
				)}
			</RouterLink>
			<div className={styles.textContainer}>
				<div className={styles.dateCategoryContainer}>
					<DateField tag="time" className={styles.time} field={trueDate} render={(date) => moment(date).format("MMMM D YYYY")} />
					{categoryUrl && categoryValue && (
						<RouterLink className={styles.category} to={categoryUrl}>
							{categoryValue}
						</RouterLink>
					)}
				</div>
				<RouterLink to={url} >
					<Text tag={"h4"} className={styles.heading} field={heading} />
				</RouterLink>
				{/* TODO: wire up sponsor payload */}
				{/* <Text tag={"div"} className={styles.sponsor} field={} /> */}
			</div>
		</section>
	);
};

export default compose(
	withSitecoreContext(),
	withErrorHandling()
)(GalleryThumbnail);