exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "h2.VideoList-module__heading--3qlQF {\n  color: #212844;\n  font-size: 2.2em;\n  font-weight: 600;\n  text-transform: uppercase; }\n\n.VideoList-module__container--MYW-t {\n  margin: auto;\n  max-width: 50%;\n  padding: 0 2em; }\n  @media (max-width: 992px) {\n    .VideoList-module__container--MYW-t {\n      max-width: 100%; } }\n  .VideoList-module__container--MYW-t .VideoList-module__videoWrapper--1Ut4T {\n    -ms-flex-align: start;\n        align-items: flex-start;\n    border-bottom: 0.08rem solid rgba(191, 191, 191, 0.6);\n    display: -ms-flexbox;\n    display: flex;\n    margin-bottom: 30px; }\n    @media (max-width: 992px) {\n      .VideoList-module__container--MYW-t .VideoList-module__videoWrapper--1Ut4T {\n        -ms-flex-flow: column;\n            flex-flow: column; } }\n    .VideoList-module__container--MYW-t .VideoList-module__videoWrapper--1Ut4T .VideoList-module__videoText--3Zg7s {\n      font-family: USSF90MinDisplay, helvetica, arial, sans-serif;\n      padding-right: 1em;\n      width: 60%; }\n      @media (max-width: 992px) {\n        .VideoList-module__container--MYW-t .VideoList-module__videoWrapper--1Ut4T .VideoList-module__videoText--3Zg7s {\n          width: 100%; } }\n      .VideoList-module__container--MYW-t .VideoList-module__videoWrapper--1Ut4T .VideoList-module__videoText--3Zg7s h3 {\n        font-size: 1.8em;\n        font-weight: 700; }\n      .VideoList-module__container--MYW-t .VideoList-module__videoWrapper--1Ut4T .VideoList-module__videoText--3Zg7s p {\n        font-size: 1.2em; }\n    .VideoList-module__container--MYW-t .VideoList-module__videoWrapper--1Ut4T .VideoList-module__videoThumbnail--xavFd {\n      margin-bottom: -30px;\n      width: 40%; }\n      @media (max-width: 992px) {\n        .VideoList-module__container--MYW-t .VideoList-module__videoWrapper--1Ut4T .VideoList-module__videoThumbnail--xavFd {\n          width: 100%; } }\n", ""]);

// exports
exports.locals = {
	"heading": "VideoList-module__heading--3qlQF",
	"container": "VideoList-module__container--MYW-t",
	"videoWrapper": "VideoList-module__videoWrapper--1Ut4T",
	"videoText": "VideoList-module__videoText--3Zg7s",
	"videoThumbnail": "VideoList-module__videoThumbnail--xavFd"
};