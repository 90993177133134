import React, { Component } from "react";
import { compose } from "recompose";
import AllMatchesTab from "~/components/Matches/AllMatchesTabsWithFilter/AllMatchesTab";
import { MatchGridIcon, MatchListIcon } from "~/shared-components/SvgIcons";
import { isServer } from "~/utilities";
import styles from "./CompetitionMatchTabs.module.scss";

class CompetitionMatchTabs extends Component {
	constructor(props) {
		super(props);

		const { children } = this.props;

		const initialActiveTab = children[0]
			? children[0].props.label
			: children[1]
				? children[1].props.label
				: "";

		this.state = {
			activeTab: initialActiveTab
		};
	}

	componentDidMount() {
		this.setActiveTabFromHash();
	}

	onClickTabItem = tab => {
		if (isServer) return;

		window.location.hash = tab.toLowerCase().replace(/\s+/g, "-"); //eslint-disable-line

		this.setState({
			activeTab: tab
		});
	}

	setActiveTabFromHash = () => {
		const { children } = this.props;

		if (isServer) return;

		const hash = window.location.hash; //eslint-disable-line

		if (!hash) return;

		const hashValue = hash
			.replace("#", "")
			.replace("-", " ")
			.toLowerCase();

		if (hashValue === children[0].props.label) {
			this.setState({
				activeTab: children[0].props.label
			});
		} else if (hashValue === children[1].props.label) {
			this.setState({
				activeTab: children[1].props.label
			});
		}
	}

	render() {
		const {
			children,
			isListView,
			showList
		} = this.props;
		const { activeTab } = this.state;

		return (
			<div className={styles.AllMatchesTabs}>
				<div className={styles.tabHeader} >
					<div className={styles.tabHeaderInner} >
						<ul className={styles.tabList} >
							{children.map(child => {
								if (child) {
									const { label } = child.props;

									return (
										<AllMatchesTab
											activeTab={activeTab}
											key={label}
											label={label}
											onClick={this.onClickTabItem}
										/>
									);
								}
							})}
						</ul>
						<div className={styles.matchFilterDesktop}>
							<div className={styles.viewButtons}>
								<div className={`${styles.icon} ${isListView ? styles.grayOut : ""}`} onClick={() => showList(false)} >
									<MatchGridIcon />
								</div>
								<div className={`${styles.icon} ${!isListView ? styles.grayOut : ""}`} onClick={() => showList(true)} >
									<MatchListIcon />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={styles.tabContent}>
					{children.map(child => {
						if (!child || child.props.label !== activeTab) {
							return undefined;
						}

						return child.props.children;
					})}
				</div>
			</div>
		);
	}
}
export default compose(
)(CompetitionMatchTabs);