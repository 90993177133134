// REDESIGN 2022 //

import React from "react";
import { PropTypes } from "prop-types";
import { Text, Link } from "~/patches/components";
import { Link as RourterDomLink } from "react-router-dom";
import styles from "./Button.module.scss";

const Button = ({ link, size, text, variant, reactRouterLink }) => {

	const buttonStyles = [];
	buttonStyles.push(
		size === 'large' ? styles.LargeButton :
			size === 'largeCondensed' ? styles.LargeCondensedButton :
				size === 'small' ? styles.SmallButton :
					size === 'smallCondensed' ? styles.SmallCondensedButton : '');
	buttonStyles.push(
		variant === 'primary' ? styles.Primary :
			variant === 'primaryReversed' ? styles.PrimaryReversed :
				variant === 'secondary' ? styles.Secondary :
					variant === 'secondaryAlternate' ? styles.SecondaryAlternate :
						variant === 'secondaryReversed' ? styles.SecondaryReversed : '');

	if (reactRouterLink) {
		return (
			<RourterDomLink className={buttonStyles.join(' ')} to={link}>
				<Text field={{ value: text }} />
			</RourterDomLink>
		)
	}

	return (
		<Link className={buttonStyles.join(' ')} field={{ value: { href: link } }}>
			<Text field={{ value: text }} />
		</Link>
	)
};

Button.propTypes = {
	link: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	size: PropTypes.oneOf(['large', 'small', 'largeCondensed']).isRequired,
	variant: PropTypes.oneOf(['primary', 'primaryReversed', 'secondary', 'secondaryAlternate', 'secondaryReversed']).isRequired
}

export default Button;