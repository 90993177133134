import React from "react";
import { RichText as SitecoreRichText, isExperienceEditorActive } from "@sitecore-jss/sitecore-jss-react"; // eslint-disable-line no-restricted-imports
import { SetHyperlinksToRedirectWrapper } from "~/utilities";
import styles from "./RichText.module.scss";
/**
 * This Link handler is an extension of sitecore link.
 *
 * @param {object} props - Component props
 */
export const CustomRichText = (props) => {
	if (isExperienceEditorActive()) {
		return <SitecoreRichText className={styles.container} {...props} />;
	}

	return (
		<SetHyperlinksToRedirectWrapper>
			<SitecoreRichText className={styles.container} {...props} />
		</SetHyperlinksToRedirectWrapper>
	);
};