import { useSanity } from '../useSanity';


export const useCallToActionBanner = ({ sitecoreItemId }) => {
	const groqQuery = `
		*[_type == 'callToActionBannerElement' %26%26 sitecoreItemId == $sitecoreItemId]{
			...,
			linkTo {
			  ...,
			  internal->{ slug }
			}
		  }[0]
	`;
	const queryKey = ['useCallToActionBanner', sitecoreItemId];
	const variablesQueryString = `$sitecoreItemId="${sitecoreItemId}"`;
	const {
		isError,
		isLoading,
		data
	} = useSanity({ groqQuery, queryKey, variablesQueryString });

	return {
		isError,
		isLoading,
		data
	}
}