exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".AllMatchesTab-module__AllMatchesTab--YgGoD {\n  color: #BFBFBF;\n  cursor: pointer;\n  display: inline-block;\n  font-family: \"USSF90MinDisplay\", helvetica, arial, sans-serif;\n  font-size: 1.6rem;\n  font-weight: 700;\n  outline: none;\n  padding: 0 0 1rem 0;\n  position: relative;\n  text-transform: uppercase;\n  -webkit-transition: color 175ms cubic-bezier(0.4, 0, 0.2, 1);\n  -o-transition: color 175ms cubic-bezier(0.4, 0, 0.2, 1);\n  transition: color 175ms cubic-bezier(0.4, 0, 0.2, 1); }\n  .AllMatchesTab-module__AllMatchesTab--YgGoD:hover, .AllMatchesTab-module__AllMatchesTab--YgGoD:focus {\n    color: #C2002F; }\n  .AllMatchesTab-module__AllMatchesTab--YgGoD:before {\n    background-color: #FFFFFF;\n    bottom: 0;\n    content: \"\";\n    height: .2rem;\n    left: 0;\n    position: absolute;\n    right: 100%;\n    -webkit-transition: 200ms cubic-bezier(0.4, 0, 0.2, 1);\n    -o-transition: 200ms cubic-bezier(0.4, 0, 0.2, 1);\n    transition: 200ms cubic-bezier(0.4, 0, 0.2, 1);\n    -webkit-transition-property: left, right, background-color;\n    -o-transition-property: left, right, background-color;\n    transition-property: left, right, background-color;\n    will-change: left, right, background-color; }\n  @media (min-width: 480px) {\n    .AllMatchesTab-module__AllMatchesTab--YgGoD {\n      font-size: 1.7rem; } }\n  @media (min-width: 1025px) {\n    .AllMatchesTab-module__AllMatchesTab--YgGoD {\n      font-size: 1.33rem;\n      margin-top: 0.4rem; }\n      .AllMatchesTab-module__AllMatchesTab--YgGoD:before {\n        height: .167rem; } }\n\n.AllMatchesTab-module__AllMatchesTabActive--1lmvU {\n  color: #C2002F; }\n  .AllMatchesTab-module__AllMatchesTabActive--1lmvU:before {\n    background-color: #C2002F;\n    right: 0; }\n", ""]);

// exports
exports.locals = {
	"AllMatchesTab": "AllMatchesTab-module__AllMatchesTab--YgGoD",
	"AllMatchesTabActive": "AllMatchesTab-module__AllMatchesTabActive--1lmvU AllMatchesTab-module__AllMatchesTab--YgGoD"
};