import React from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import HelperCommentEdit from "~/experience-editor/HelperCommentEdit";
import BrightcoveVideoPlayer from "../BrightcoveVideoPlayer";

const VideoPlayer = props => {
	const { fields, sitecoreContext: { pageEditing } } = props;
	const pickedOoyalaHasValue = fields?.pickedOoyalaContentId ? fields?.pickedOoyalaContentId?.fields?.contentEmbeddedCode?.value : false;
	const pickedOoyalaValue = pickedOoyalaHasValue ? fields?.pickedOoyalaContentId?.fields?.contentEmbeddedCode?.value : "";
	const ooyalaId = pickedOoyalaHasValue ? pickedOoyalaValue : fields?.contentId?.value;
	const ref = `ref:${ooyalaId}`;

	/* When in the experience Editor and no content id is selected display helper text */
	if (pageEditing && !ooyalaId) return <HelperCommentEdit endingEditComment="and type in the Ooyala content ID or select a video" />;


	return (
		<>
			{ooyalaId && (
				<BrightcoveVideoPlayer videoEmbedCode={ref}></BrightcoveVideoPlayer>
			)}
		</>
	);
};
export default compose(
	// other hocs goes here
	withSitecoreContext(),
	withErrorHandling()
)(VideoPlayer);