exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Tabs-module__container--RPX1l {\n  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.04)), to(rgba(0, 0, 0, 0)));\n  background: -o-linear-gradient(top, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0));\n  background: linear-gradient(180deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0));\n  border-top: solid 0.1rem rgba(0, 0, 0, 0.07);\n  font-size: 1.7rem;\n  line-height: 2.4rem;\n  padding-top: 5rem; }\n\n.Tabs-module__sectionLabelContainer--gn3Yd {\n  margin: 0 auto 2rem;\n  max-width: 1440px;\n  padding: 0 2rem;\n  width: 100%; }\n\n.matches-tabs .Tabs-module__container--RPX1l {\n  padding-top: 0; }\n\n@media (min-width: 1025px) {\n  .Tabs-module__sectionLabelContainer--gn3Yd {\n    margin-bottom: 2.5rem;\n    padding: 0 8.333rem; } }\n\n@media (min-width: 1440px) {\n  .Tabs-module__sectionLabelContainer--gn3Yd {\n    max-width: 1640px; } }\n", ""]);

// exports
exports.locals = {
	"container": "Tabs-module__container--RPX1l",
	"sectionLabelContainer": "Tabs-module__sectionLabelContainer--gn3Yd"
};