import React from "react";
import { Arrow } from "~/shared-components/SvgIcons";
import { compose } from "recompose";
import { withNamespaces } from "react-i18next";
import { NavLink } from "react-router-dom";
import styles from "../MultiLevelMenu.module.scss";
import { withErrorHandling } from "~/hoc";

const PrevButton = props => (
	props.prevMenu?.renderAsLink ? (
		<BackWithLink {...props} />
	) : (
		<Back {...props} />
	)
);

const BackWithLink = ({ setState, prevMenu, menuLevel, goNext, goBack }) => (
	<div className={styles.backButtonWithLink}>
		<button onClick={() => setState({
			goToMenu: prevMenu.menuLevel,
			prevMenu: menuLevel,
			goNext,
			goBack,
			activeLevelId: prevMenu.id
		})}>
			<Arrow />
		</button>
		<NavLink
			id={`link-${prevMenu.id}`}
			activeClassName={styles.active}
			exact
			to={prevMenu.url}>
			{prevMenu.menuTitle}
		</NavLink>
	</div>
);

const Back = ({ setState, prevMenu, menuLevel, goNext, goBack }) => (
	<button onClick={() => setState({
		goToMenu: prevMenu.menuLevel,
		prevMenu: menuLevel,
		goNext,
		goBack,
		activeLevelId: prevMenu.id
	})}>
		<Arrow />
		{prevMenu.menuTitle}
	</button>
);




export default compose(
	withNamespaces(),
	withErrorHandling()
)(PrevButton);