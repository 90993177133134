import React, { useEffect, useState } from "react";
import styles from "./P1Membership.module.scss";
import { useStripe } from '@stripe/react-stripe-js';

export const PaymentIntentMessage = ({ membershipLevel }) => {
	const stripe = useStripe();
	const [clientSecret, setClientSecret] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [paymentIntentStatus, setPaymentIntentStatus] = useState('');
	
	useEffect(() => {
		setIsLoading(true);

		if (!stripe) {
			setIsLoading(false);
			return;
		}

		setClientSecret(new URLSearchParams(window.location.search).get(
			"payment_intent_client_secret"
		));

		if (!clientSecret) {
			setIsLoading(false);
			return;
		}

		stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
			let paymentMethodString = "";
			if (paymentIntent?.last_payment_error !== null) {
				paymentMethodString = getPaymentMethodInfo(paymentIntent?.last_payment_error?.payment_method);

				if (!paymentMethodString) {
					setIsLoading(false);
					return;
				}
			}

			switch (paymentIntent?.status) {
				case "processing" || "requires_confirmation":
					setPaymentIntentStatus(`Payment is still processing, but you'll see your new status reflected here soon.`);
					break;
				case "succeeded":
					setPaymentIntentStatus(null);
					break;
				case "requires_payment_method":
					setPaymentIntentStatus(
						`"${paymentIntent?.last_payment_error?.message}" - ${paymentMethodString}\n` +
						"Please try a different payment method!");
					break;
				default:
					setPaymentIntentStatus(
						`Supposedly your ${paymentIntent?.description} ${paymentIntent?.status.split('_').join(' ')}...\n` +
						`Reach out to the Insiders Team if this doesn't seem right.`);
					break;
			}
			setIsLoading(false);
		});

	}, [stripe, clientSecret]);

	function getPaymentMethodInfo(paymentMethod) {
		if (!paymentMethod) {
			return null;
		}

		const paymentMethodType = paymentMethod?.type;
		const paymentMethodString = `${paymentMethodType?.split('_').join(' ')} ending in ${paymentMethod[paymentMethodType]?.last4}`;

		return paymentMethodString;
	}

	return (
        <>
			{!isLoading
				? <div>
					{paymentIntentStatus}
				</div>
				: <div className={styles.loader}></div>
			}
        </>
	)
}