import React, { Component } from "react";
import { Link as RouterLink } from "react-router-dom";
import styles from "./CallToActionMenu.module.scss";
import { withGigya } from "~/hoc";

class CallToActionMenu extends Component {
	constructor(props) {
		super(props);
		const { gigya: { isLoggedIn }} = this.props;

		this.state = {
			isUserLoggedIn: isLoggedIn
		};
	}

	componentDidUpdate(prevProps) {
		const { gigya: prevGigya } = prevProps;
		const { gigya: { isLoggedIn } } = this.props;

		if (prevGigya.isLoggedIn !== isLoggedIn) {
			this.setState({
				isUserLoggedIn: isLoggedIn
			});
		}
	}

	render() {
		const {
			member,
			benefits,
			tickets
		} = this.props;
		const { isUserLoggedIn } = this.state;
		const signupLink = (isUserLoggedIn ? benefits : member);

		return (
			<div className={styles.callToActionMenu}>
				{signupLink &&
					<RouterLink className={styles.link} to={signupLink.value.href}>
						{signupLink.value.text}
					</RouterLink>
				}
				{tickets &&
					<RouterLink className={styles.button} to={tickets.value.href}>
						{tickets.value.text}
					</RouterLink>
				}
			</div>
		);
	}
}

export default withGigya()(CallToActionMenu);