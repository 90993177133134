export const matches = {
	"matchId": "5ura3gks64lr3qd4j4cwugdg4",
	"description": "Grenada vs United States",
	"tournamentCalendarId": "84lurtafp6kpmrh1tdrjwq1hw",
	"tournamentCalendarName": "2022/2023",
	"stageName": "League A",
	"status": 0,
	"goals": [{
		"contestantId": "9vh2u1p4ppm597tjfahst2m3n",
		"periodId": 1,
		"timeMin": 4,
		"goalType": 0,
		"scorerId": "emglxnqrlqskt8krglv7sziwa",
		"scorerName": "R. Pepi",
		"assistPlayerId": "93soe2rjn0z874cc9ofcesgkp",
		"assistPlayerName": "C. Pulisic"
	}, {
		"contestantId": "9vh2u1p4ppm597tjfahst2m3n",
		"periodId": 1,
		"timeMin": 20,
		"goalType": 0,
		"scorerId": "8rrcscn29rs1gk8mi1pwpu92i",
		"scorerName": "B. Aaronson",
		"assistPlayerId": "93soe2rjn0z874cc9ofcesgkp",
		"assistPlayerName": "C. Pulisic"
	}, {
		"contestantId": "9vh2u1p4ppm597tjfahst2m3n",
		"periodId": 1,
		"timeMin": 31,
		"goalType": 0,
		"scorerId": "288nn7pbq5ewriiyb9o7da86h",
		"scorerName": "W. McKennie",
		"assistPlayerId": null,
		"assistPlayerName": null
	}, {
		"contestantId": "88jwqq14es3obmtaxvj042tnv",
		"periodId": 1,
		"timeMin": 32,
		"goalType": 0,
		"scorerId": "c840oaaw59arinkzu4cqql9ed",
		"scorerName": "M. Hippolyte",
		"assistPlayerId": "1qgtx5es7s1lwfhcruy6c26p6",
		"assistPlayerName": "J. Berkeley-Agyepong"
	}, {
		"contestantId": "9vh2u1p4ppm597tjfahst2m3n",
		"periodId": 1,
		"timeMin": 34,
		"goalType": 0,
		"scorerId": "288nn7pbq5ewriiyb9o7da86h",
		"scorerName": "W. McKennie",
		"assistPlayerId": "3bvr4z5z4cyb8fadfwtgxrx0p",
		"assistPlayerName": "A. Trusty"
	}],
	"sponsor": null,
	"date": "2023-03-25T00:00:00",
	"time": "00:00:00Z",
	"cancelled": false,
	"lengthMin": 90,
	"lengthSec": 0,
	"addedTimeForCurrentPeriod": 1,
	"venue": {
		"id": "2d6b3wlp50spcm6ngprkidbkl",
		"longName": "Kirani James National Stadium",
		"shortName": "Kirani James National Stadium",
		"location": "St. George's",
		"country": "Grenada"
	},
	"attendance": 0,
	"competition": {
		"id": "cu0rmpyff5692eo06ltddjo8a",
		"name": "Concacaf Nations League",
		"code": "CNL",
		"format": "International cup",
		"startDate": "2022-06-02T00:00:00Z",
		"endDate": "2023-06-20T00:00:00Z",
		"isFriendly": false
	},
	"periods": [{
		"id": 1,
		"start": "2023-03-25T00:00:36Z",
		"end": "2023-03-25T00:46:37Z",
		"lengthMin": "90",
		"lengthSec": "1",
		"addedTime": 1
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "88jwqq14es3obmtaxvj042tnv",
		"name": "Grenada",
		"officialName": "Grenada",
		"shortName": "Grenada",
		"code": "GRN",
		"position": "home",
		"score": 1,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "9vh2u1p4ppm597tjfahst2m3n",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "away",
		"score": 4,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": [],
	"substitutions": [],
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/concacaf-nations-league-2022-2023/matches/grenada-vs-united-states-3-24-23",
	"sitecoreData": {
		"link1": {"value": {"href": ""}, "editable": null},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {
			"value": {
				"href": "https://www.ussoccer.com/stories/2023/03/2022-2023-concacaf-nations-league-usmnt-vs-grenada-preview-tv-channels-start-time",
				"linktype": "external",
				"url": "https://www.ussoccer.com/stories/2023/03/2022-2023-concacaf-nations-league-usmnt-vs-grenada-preview-tv-channels-start-time"
			}, "editable": null
		},
		"matchRecapLink": {"value": {"href": ""}, "editable": null},
		"ticketsLink": {"value": {"href": ""}, "editable": null},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/backgroundred.ashx?h=1200&la=en-US&w=3840&rev=a73df9006659468d9b7fc9fe45452d72&hash=99DD665448A183451AFA8B9117FE5205",
				"alt": "",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/cardred.ashx?h=720&la=en-US&w=670&rev=18fd56e215454fc9971223f6e38a577d&hash=F78F6ED6B0027EF6121A12133AF0F1E1",
				"alt": "Red Chevron Background Pattern",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/concacaf-nations-league-2022-2023"
		},
		"backgroundImage": null,
		"playerCutoutHome": {"value": {}, "editable": null},
		"playerCutoutAway": {"value": {}, "editable": null},
		"sponsorText": {"value": "", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "Kirani James National Stadium", "editable": null},
		"stadiumLocation": {"value": "St George's", "editable": null},
		"stadiumCountry": {"value": "Grenada", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/broadcasters/warnermedia_core_global_black_tnt_v2.ashx?h=128&la=en-US&w=128&rev=706093fb71854370a6ef0c9f389effbc&hash=563B8A11879DF1792A2155B49BA3962F",
					"alt": "",
					"width": "128",
					"height": "128"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.tntdrama.com/watchtnt", "editable": null},
			"iosUrl": {"value": "https://www.tntdrama.com/watchtnt", "editable": null},
			"androidUrl": {"value": "https://www.tntdrama.com/watchtnt", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogouniversoblack2x.ashx?h=52&la=en-US&w=120&rev=e8fd76c116cd408195c3374e4ecbc87c&hash=F88742E24C3283C03874ABD4661E786F",
					"alt": "UNIVERSO",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.nbc.com/networks/nbc-universo", "editable": null},
			"iosUrl": {"value": "https://apps.apple.com/us/app/universo-now/id869444353", "editable": null},
			"androidUrl": {
				"value": "https://play.google.com/store/apps/details?id=com.nbcu.tve.universo&hl=en_US&gl=US",
				"editable": null
			}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/broadcasters/peacocklogov2rgbblack.ashx?h=37&la=en-US&w=120&rev=0a57f2ea708449538077af736b08947d&hash=7E6EE2BA895192CB12E6E5D04882D721",
					"alt": "",
					"width": "120",
					"height": "37"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.peacocktv.com/watch/home", "editable": null},
			"iosUrl": {"value": "https://www.peacocktv.com/watch/home", "editable": null},
			"androidUrl": {"value": "https://www.peacocktv.com/watch/home", "editable": null}
		}],
		"currentItemId": "d28c99a3-9c79-46fc-a702-940afe203ba3"
	},
	"dateTime": "2023-03-25T00:00:00Z",
	"dateTimeObject": "2023-03-25T00:00:00+00:00"
};
export const scoreboard = [{
	"matchId": "6bwqh1p2x600c9od8emts0zys",
	"description": "Portugal vs United States",
	"tournamentCalendarId": "5mqc55tieqhbz2sech0fp70bu",
	"tournamentCalendarName": "2023 Australia / New Zealand",
	"stageName": "Group Stage",
	"status": 1,
	"goals": [],
	"sponsor": null,
	"date": "2023-08-01T07:00:00",
	"time": "07:00:00Z",
	"cancelled": false,
	"lengthMin": 0,
	"lengthSec": 0,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "8ojr7co7hri3derv7ydczd46r",
		"longName": "Eden Park",
		"shortName": "Eden Park",
		"location": "Auckland",
		"country": "New Zealand"
	},
	"attendance": 0,
	"competition": {
		"id": "8x62utr2uti3i7kk14isbnip6",
		"name": "FIFA Women's World Cup",
		"code": "WWC",
		"format": "International cup",
		"startDate": "2023-07-20T00:00:00Z",
		"endDate": "2023-08-20T00:00:00Z",
		"isFriendly": false
	},
	"periods": [],
	"currentPeriod": 16,
	"manager": null,
	"contestants": [{
		"id": "27z7xb0913v9ktnlazdhlspja",
		"name": "Portugal",
		"officialName": "Portugal",
		"shortName": "Portugal",
		"code": "POR",
		"position": "home",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "e70zl10x0ayu7y10ry0wi465a",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "away",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/8x62utr2uti3i7kk14isbnip6/matches/6bwqh1p2x600c9od8emts0zys",
	"sitecoreData": null,
	"dateTime": "2023-08-01T07:00:00Z",
	"dateTimeObject": "2023-08-01T07:00:00+00:00"
}, {
	"matchId": "5t8y4xodny91ehfymvu6u7mz8",
	"description": "United States vs Netherlands",
	"tournamentCalendarId": "5mqc55tieqhbz2sech0fp70bu",
	"tournamentCalendarName": "2023 Australia / New Zealand",
	"stageName": "Group Stage",
	"status": 1,
	"goals": [],
	"sponsor": null,
	"date": "2023-07-27T01:00:00",
	"time": "01:00:00Z",
	"cancelled": false,
	"lengthMin": 0,
	"lengthSec": 0,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "95pujh15ou6p13wxc8enxzd72",
		"longName": "Sky Stadium",
		"shortName": "Sky Stadium",
		"location": "Wellington",
		"country": "New Zealand"
	},
	"attendance": 0,
	"competition": {
		"id": "8x62utr2uti3i7kk14isbnip6",
		"name": "FIFA Women's World Cup",
		"code": "WWC",
		"format": "International cup",
		"startDate": "2023-07-20T00:00:00Z",
		"endDate": "2023-08-20T00:00:00Z",
		"isFriendly": false
	},
	"periods": [],
	"currentPeriod": 16,
	"manager": null,
	"contestants": [{
		"id": "e70zl10x0ayu7y10ry0wi465a",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "home",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "9x7z0xiidyp39vy69huxf6kim",
		"name": "Netherlands",
		"officialName": "Netherlands",
		"shortName": "Netherlands",
		"code": "NED",
		"position": "away",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/8x62utr2uti3i7kk14isbnip6/matches/5t8y4xodny91ehfymvu6u7mz8",
	"sitecoreData": null,
	"dateTime": "2023-07-27T01:00:00Z",
	"dateTimeObject": "2023-07-27T01:00:00+00:00"
}, {
	"matchId": "5gg2xnccx7dedyq39ht20n2tw",
	"description": "United States vs Vietnam",
	"tournamentCalendarId": "5mqc55tieqhbz2sech0fp70bu",
	"tournamentCalendarName": "2023 Australia / New Zealand",
	"stageName": "Group Stage",
	"status": 1,
	"goals": [],
	"sponsor": null,
	"date": "2023-07-22T01:00:00",
	"time": "01:00:00Z",
	"cancelled": false,
	"lengthMin": 0,
	"lengthSec": 0,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "8ojr7co7hri3derv7ydczd46r",
		"longName": "Eden Park",
		"shortName": "Eden Park",
		"location": "Auckland",
		"country": "New Zealand"
	},
	"attendance": 0,
	"competition": {
		"id": "8x62utr2uti3i7kk14isbnip6",
		"name": "FIFA Women's World Cup",
		"code": "WWC",
		"format": "International cup",
		"startDate": "2023-07-20T00:00:00Z",
		"endDate": "2023-08-20T00:00:00Z",
		"isFriendly": false
	},
	"periods": [],
	"currentPeriod": 16,
	"manager": null,
	"contestants": [{
		"id": "e70zl10x0ayu7y10ry0wi465a",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "home",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "9m8lm7wgoa1heegyhu9yhhjib",
		"name": "Vietnam",
		"officialName": "Vietnam",
		"shortName": "Vietnam",
		"code": "VIE",
		"position": "away",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/8x62utr2uti3i7kk14isbnip6/matches/5gg2xnccx7dedyq39ht20n2tw",
	"sitecoreData": null,
	"dateTime": "2023-07-22T01:00:00Z",
	"dateTimeObject": "2023-07-22T01:00:00+00:00"
}, {
	"matchId": "5trvmyjjujo7nwari60031y50",
	"description": "United States vs Republic of Ireland",
	"tournamentCalendarId": "5eajosh4nvgh02xpqwhg6x9uc",
	"tournamentCalendarName": "2023",
	"stageName": "Friendlies 1",
	"status": 1,
	"goals": [],
	"sponsor": null,
	"date": "2023-04-11T23:30:00",
	"time": "23:30:00Z",
	"cancelled": false,
	"lengthMin": 0,
	"lengthSec": 0,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "f3zjl2b5cqty5woe8purq000k",
		"longName": "CITYPARK",
		"shortName": "CITYPARK",
		"location": "St. Louis, Missouri",
		"country": "USA"
	},
	"attendance": 0,
	"competition": {
		"id": "dvtl8sf1262pd2aqgu641qa7u",
		"name": "International Friendly",
		"code": "FRW",
		"format": "International cup",
		"startDate": "2023-01-01T00:00:00Z",
		"endDate": "2023-12-31T00:00:00Z",
		"isFriendly": true
	},
	"periods": [],
	"currentPeriod": 16,
	"manager": null,
	"contestants": [{
		"id": "e70zl10x0ayu7y10ry0wi465a",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "home",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "7h2k693v6ha4hsct0np28vefm",
		"name": "Republic of Ireland",
		"officialName": "Republic of Ireland",
		"shortName": "Eire",
		"code": "IRL",
		"position": "away",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/dvtl8sf1262pd2aqgu641qa7u/matches/5trvmyjjujo7nwari60031y50",
	"sitecoreData": null,
	"dateTime": "2023-04-11T23:30:00Z",
	"dateTimeObject": "2023-04-11T23:30:00+00:00"
}, {
	"matchId": "5t6h8i5119y89vw8r56uic3dg",
	"description": "United States vs Republic of Ireland",
	"tournamentCalendarId": "5eajosh4nvgh02xpqwhg6x9uc",
	"tournamentCalendarName": "2023",
	"stageName": "Friendlies 1",
	"status": 1,
	"goals": [],
	"sponsor": null,
	"date": "2023-04-08T18:30:00",
	"time": "18:30:00Z",
	"cancelled": false,
	"lengthMin": 0,
	"lengthSec": 0,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "5ylpm7oz3c8tplmcb4nisv6dw",
		"longName": "Q2 Stadium",
		"shortName": "Q2 Stadium",
		"location": "Austin, Texas",
		"country": "USA"
	},
	"attendance": 0,
	"competition": {
		"id": "dvtl8sf1262pd2aqgu641qa7u",
		"name": "International Friendly",
		"code": "FRW",
		"format": "International cup",
		"startDate": "2023-01-01T00:00:00Z",
		"endDate": "2023-12-31T00:00:00Z",
		"isFriendly": true
	},
	"periods": [],
	"currentPeriod": 16,
	"manager": null,
	"contestants": [{
		"id": "e70zl10x0ayu7y10ry0wi465a",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "home",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "7h2k693v6ha4hsct0np28vefm",
		"name": "Republic of Ireland",
		"officialName": "Republic of Ireland",
		"shortName": "Eire",
		"code": "IRL",
		"position": "away",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/dvtl8sf1262pd2aqgu641qa7u/matches/5t6h8i5119y89vw8r56uic3dg",
	"sitecoreData": null,
	"dateTime": "2023-04-08T18:30:00Z",
	"dateTimeObject": "2023-04-08T18:30:00+00:00"
}, {
	"matchId": "8f0aldcguzzozjjzkwkmzogt0",
	"description": "United States vs Brazil",
	"tournamentCalendarId": "42ehnk2h65n4h5c0hnzdmge8k",
	"tournamentCalendarName": "2023",
	"stageName": "Group Stage",
	"status": 0,
	"goals": [{
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 1,
		"timeMin": 48,
		"goalType": 0,
		"scorerId": "l28lqh3f3ojx6kk2wvvq6nyt",
		"scorerName": "A. Morgan",
		"assistPlayerId": null,
		"assistPlayerName": null
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 2,
		"timeMin": 63,
		"goalType": 0,
		"scorerId": "dkk9sopglpjgnopxcxkn65gid",
		"scorerName": "M. Pugh",
		"assistPlayerId": "4j3kopvvd3iej6p4h9z36giqd",
		"assistPlayerName": "R. Lavelle"
	}, {
		"contestantId": "1n3ndydg1dl4405q55pyp4l3p",
		"periodId": 2,
		"timeMin": 90,
		"goalType": 0,
		"scorerId": "a7yq5hwmc27r615dak86yfoph",
		"scorerName": "Ludmila",
		"assistPlayerId": "co49l4m0my5li8yvcxgrvq0yy",
		"assistPlayerName": "Bruninha"
	}],
	"sponsor": "",
	"date": "2023-02-23T00:00:00",
	"time": "00:00:00Z",
	"cancelled": false,
	"lengthMin": 96,
	"lengthSec": 58,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "86i7ztgeobez2lcwxl282817y",
		"longName": "Toyota Stadium",
		"shortName": "Toyota Stadium",
		"location": "Frisco, TX",
		"country": "United States"
	},
	"attendance": 0,
	"competition": {
		"id": "dzdh9f7vyvzpq3nmvl22dyx2h",
		"name": "SheBelieves Cup 2023",
		"code": "SBC",
		"format": "International cup",
		"startDate": "2023-02-16T00:00:00Z",
		"endDate": "2023-02-23T00:00:00Z",
		"isFriendly": true
	},
	"periods": [{
		"id": 1,
		"start": "2023-02-23T00:07:32Z",
		"end": "2023-02-23T00:55:33Z",
		"lengthMin": "48",
		"lengthSec": "1",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2023-02-23T01:12:22Z",
		"end": "2023-02-23T02:01:19Z",
		"lengthMin": "48",
		"lengthSec": "57",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "e70zl10x0ayu7y10ry0wi465a",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "home",
		"score": 2,
		"heroFlairTitle": "",
		"heroFlairName": "",
		"heroFlairBody": ""
	}, {
		"id": "1n3ndydg1dl4405q55pyp4l3p",
		"name": "Brazil",
		"officialName": "Brazil",
		"shortName": "Brazil",
		"code": "BRA",
		"position": "away",
		"score": 1,
		"heroFlairTitle": "",
		"heroFlairName": "",
		"heroFlairBody": ""
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": "Buy Tickets",
		"url": "https://www.ticketmaster.com/event/22005D86ECA8F890",
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00Z",
		"generalAvailabilityDate": "0001-01-01T00:00:00Z"
	},
	"groupTicketUrl": "",
	"groupTicketText": "",
	"matchFeedUrl": "/competitions/shebelieves-cup-2023/matches/usa-vs-brazil-2-22-23",
	"sitecoreData": {
		"link1": {"value": {"href": ""}, "editable": null},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {"value": {"href": ""}, "editable": null},
		"matchRecapLink": {"value": {"href": ""}, "editable": null},
		"ticketsLink": {
			"value": {
				"href": "https://www.ticketmaster.com/event/22005D86ECA8F890",
				"text": "Ticketmaster",
				"linktype": "external",
				"url": "https://www.ticketmaster.com/event/22005D86ECA8F890",
				"anchor": "",
				"title": "Link to Ticketmaster",
				"target": "_blank"
			}, "editable": null
		},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/wwcfrance2019backgroundp1.ashx?h=1200&la=en-US&w=3840&rev=f45ce10739234f589f36d39aca7e9510&hash=A256648079CC6A02F8070F882ED0DB53",
				"alt": "",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/wwcfrance2019backgroundcard.ashx?h=720&la=en-US&w=670&rev=751c0234ecbd48bfa5f60b6104526e60&hash=62233C6BBFA006927A82260DC14A3EFB",
				"alt": "WWC Background",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "8ewuc2b5tqufvcrhe1e878ljo", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/shebelieves-cup-2023"
		},
		"backgroundImage": null,
		"playerCutoutHome": {"value": {}, "editable": null},
		"playerCutoutAway": {"value": {}, "editable": null},
		"sponsorText": {"value": "Presented by Visa", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "Toyota Stadium", "editable": null},
		"stadiumLocation": {"value": "Frisco, TX", "editable": null},
		"stadiumCountry": {"value": "United States", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/broadcasters/hbomaxv2rgbblack.ashx?h=37&la=en-US&w=120&rev=cdfacd5d8f714483af83ea12477c075d&hash=1B1FBC6CA32AA3ABD83B96A53E637AE9",
					"alt": "",
					"width": "120",
					"height": "37"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.hbomax.com/", "editable": null},
			"iosUrl": {"value": "https://www.hbomax.com/", "editable": null},
			"androidUrl": {"value": "https://www.hbomax.com/", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogouniversoblack2x.ashx?h=52&la=en-US&w=120&rev=e8fd76c116cd408195c3374e4ecbc87c&hash=F88742E24C3283C03874ABD4661E786F",
					"alt": "UNIVERSO",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.nbc.com/networks/nbc-universo", "editable": null},
			"iosUrl": {"value": "https://apps.apple.com/us/app/universo-now/id869444353", "editable": null},
			"androidUrl": {
				"value": "https://play.google.com/store/apps/details?id=com.nbcu.tve.universo&hl=en_US&gl=US",
				"editable": null
			}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/broadcasters/peacocklogov2rgbblack.ashx?h=37&la=en-US&w=120&rev=0a57f2ea708449538077af736b08947d&hash=7E6EE2BA895192CB12E6E5D04882D721",
					"alt": "",
					"width": "120",
					"height": "37"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.peacocktv.com/watch/home", "editable": null},
			"iosUrl": {"value": "https://www.peacocktv.com/watch/home", "editable": null},
			"androidUrl": {"value": "https://www.peacocktv.com/watch/home", "editable": null}
		}],
		"currentItemId": "a7367e17-b8c6-423a-8d42-4e685bf755cc"
	},
	"dateTime": "2023-02-23T00:00:00Z",
	"dateTimeObject": "2023-02-23T00:00:00+00:00"
}, {
	"matchId": "8ekiy1srznd1x0jymzyt6q1w4",
	"description": "United States vs Canada",
	"tournamentCalendarId": "42ehnk2h65n4h5c0hnzdmge8k",
	"tournamentCalendarName": "2023",
	"stageName": "Group Stage",
	"status": 0,
	"goals": [{
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 1,
		"timeMin": 7,
		"goalType": 0,
		"scorerId": "dkk9sopglpjgnopxcxkn65gid",
		"scorerName": "M. Pugh",
		"assistPlayerId": "l28lqh3f3ojx6kk2wvvq6nyt",
		"assistPlayerName": "A. Morgan"
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 1,
		"timeMin": 34,
		"goalType": 0,
		"scorerId": "dkk9sopglpjgnopxcxkn65gid",
		"scorerName": "M. Pugh",
		"assistPlayerId": null,
		"assistPlayerName": null
	}],
	"sponsor": "",
	"date": "2023-02-17T00:00:00",
	"time": "00:00:00Z",
	"cancelled": false,
	"lengthMin": 98,
	"lengthSec": 4,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "3udn5yhh0bcxwmjokvt4xnm5l",
		"longName": "Exploria Stadium",
		"shortName": "Exploria Stadium",
		"location": "Orlando, FL ",
		"country": "United States"
	},
	"attendance": 0,
	"competition": {
		"id": "dzdh9f7vyvzpq3nmvl22dyx2h",
		"name": "SheBelieves Cup 2023",
		"code": "SBC",
		"format": "International cup",
		"startDate": "2023-02-16T00:00:00Z",
		"endDate": "2023-02-23T00:00:00Z",
		"isFriendly": true
	},
	"periods": [{
		"id": 1,
		"start": "2023-02-17T00:09:52Z",
		"end": "2023-02-17T00:57:55Z",
		"lengthMin": "48",
		"lengthSec": "3",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2023-02-17T01:15:44Z",
		"end": "2023-02-17T02:05:45Z",
		"lengthMin": "50",
		"lengthSec": "1",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "e70zl10x0ayu7y10ry0wi465a",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "home",
		"score": 2,
		"heroFlairTitle": "",
		"heroFlairName": "",
		"heroFlairBody": ""
	}, {
		"id": "e3vi0exjm76v5bfnmoj15bg2i",
		"name": "Canada",
		"officialName": "Canada",
		"shortName": "Canada",
		"code": "CAN",
		"position": "away",
		"score": 0,
		"heroFlairTitle": "",
		"heroFlairName": "",
		"heroFlairBody": ""
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": "Buy Tickets",
		"url": "https://www.ticketmaster.com/event/22005D86ECA8F890",
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00Z",
		"generalAvailabilityDate": "0001-01-01T00:00:00Z"
	},
	"groupTicketUrl": "",
	"groupTicketText": "",
	"matchFeedUrl": "/competitions/shebelieves-cup-2023/matches/usa-vs-canada-2-16-23",
	"sitecoreData": {
		"link1": {"value": {"href": ""}, "editable": null},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {"value": {"href": ""}, "editable": null},
		"matchRecapLink": {"value": {"href": ""}, "editable": null},
		"ticketsLink": {
			"value": {
				"href": "https://www.ticketmaster.com/event/22005D86ECA8F890",
				"text": "Ticketmaster",
				"linktype": "external",
				"url": "https://www.ticketmaster.com/event/22005D86ECA8F890",
				"anchor": "",
				"title": "Link to Ticketmaster",
				"target": "_blank"
			}, "editable": null
		},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/wwcfrance2019backgroundp1.ashx?h=1200&la=en-US&w=3840&rev=f45ce10739234f589f36d39aca7e9510&hash=A256648079CC6A02F8070F882ED0DB53",
				"alt": "",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/wwcfrance2019backgroundcard.ashx?h=720&la=en-US&w=670&rev=751c0234ecbd48bfa5f60b6104526e60&hash=62233C6BBFA006927A82260DC14A3EFB",
				"alt": "WWC Background",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/shebelieves-cup-2023"
		},
		"backgroundImage": null,
		"playerCutoutHome": {"value": {}, "editable": null},
		"playerCutoutAway": {"value": {}, "editable": null},
		"sponsorText": {"value": "Presented by Visa", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "Exploria Stadium", "editable": null},
		"stadiumLocation": {"value": "Orlando, FL", "editable": null},
		"stadiumCountry": {"value": "United States", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/broadcasters/hbomaxv2rgbblack.ashx?h=37&la=en-US&w=120&rev=cdfacd5d8f714483af83ea12477c075d&hash=1B1FBC6CA32AA3ABD83B96A53E637AE9",
					"alt": "",
					"width": "120",
					"height": "37"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.hbomax.com/", "editable": null},
			"iosUrl": {"value": "https://www.hbomax.com/", "editable": null},
			"androidUrl": {"value": "https://www.hbomax.com/", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogouniversoblack2x.ashx?h=52&la=en-US&w=120&rev=e8fd76c116cd408195c3374e4ecbc87c&hash=F88742E24C3283C03874ABD4661E786F",
					"alt": "UNIVERSO",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.nbc.com/networks/nbc-universo", "editable": null},
			"iosUrl": {"value": "https://apps.apple.com/us/app/universo-now/id869444353", "editable": null},
			"androidUrl": {
				"value": "https://play.google.com/store/apps/details?id=com.nbcu.tve.universo&hl=en_US&gl=US",
				"editable": null
			}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/broadcasters/peacocklogov2rgbblack.ashx?h=37&la=en-US&w=120&rev=0a57f2ea708449538077af736b08947d&hash=7E6EE2BA895192CB12E6E5D04882D721",
					"alt": "",
					"width": "120",
					"height": "37"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.peacocktv.com/watch/home", "editable": null},
			"iosUrl": {"value": "https://www.peacocktv.com/watch/home", "editable": null},
			"androidUrl": {"value": "https://www.peacocktv.com/watch/home", "editable": null}
		}],
		"currentItemId": "8f6b95d8-1cd3-42fa-9e18-0174dda677ed"
	},
	"dateTime": "2023-02-17T00:00:00Z",
	"dateTimeObject": "2023-02-17T00:00:00+00:00"
}, {
	"matchId": "bz13ieq3icuk0dkyta2ppu2ok",
	"description": "United States vs Canada",
	"tournamentCalendarId": "43wuhb6z6pgrnsw779oe6ml4a",
	"tournamentCalendarName": "2023",
	"stageName": "Final",
	"status": 0,
	"goals": [{
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 2,
		"timeMin": 78,
		"goalType": 1,
		"scorerId": "l28lqh3f3ojx6kk2wvvq6nyt",
		"scorerName": "A. Morgan",
		"assistPlayerId": null,
		"assistPlayerName": null
	}],
	"sponsor": null,
	"date": "2022-07-19T02:10:00",
	"time": "02:10:00Z",
	"cancelled": false,
	"lengthMin": 98,
	"lengthSec": 15,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "647q7it5oqj8ieeo9hwcpjpih",
		"longName": "Estadio BBVA",
		"shortName": "Estadio BBVA",
		"location": "Guadalupe",
		"country": "Mexico"
	},
	"attendance": 0,
	"competition": {
		"id": "ecgvhmaf0m808fxsry941v8ak",
		"name": "Concacaf Women's World Cup Qualifiers",
		"code": "WWQ",
		"format": "International cup",
		"startDate": "2022-02-16T00:00:00Z",
		"endDate": "2022-07-31T00:00:00Z",
		"isFriendly": false
	},
	"periods": [{
		"id": 1,
		"start": "2022-07-19T02:12:27Z",
		"end": "2022-07-19T02:59:37Z",
		"lengthMin": "47",
		"lengthSec": "10",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2022-07-19T03:15:28Z",
		"end": "2022-07-19T04:06:33Z",
		"lengthMin": "51",
		"lengthSec": "5",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "e70zl10x0ayu7y10ry0wi465a",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "home",
		"score": 1,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "e3vi0exjm76v5bfnmoj15bg2i",
		"name": "Canada",
		"officialName": "Canada",
		"shortName": "Canada",
		"code": "CAN",
		"position": "away",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/ecgvhmaf0m808fxsry941v8ak/matches/bz13ieq3icuk0dkyta2ppu2ok",
	"sitecoreData": null,
	"dateTime": "2022-07-19T02:10:00Z",
	"dateTimeObject": "2022-07-19T02:10:00+00:00"
}, {
	"matchId": "5sodhq669u8zomwm9qddoz66s",
	"description": "United States vs Costa Rica",
	"tournamentCalendarId": "43wuhb6z6pgrnsw779oe6ml4a",
	"tournamentCalendarName": "2023",
	"stageName": "Semi-finals",
	"status": 0,
	"goals": [{
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 1,
		"timeMin": 34,
		"goalType": 0,
		"scorerId": "3aj7p478dcuat6tz8096sq8dl",
		"scorerName": "E. Sonnett",
		"assistPlayerId": null,
		"assistPlayerName": null
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 1,
		"timeMin": 48,
		"goalType": 0,
		"scorerId": "dkk9sopglpjgnopxcxkn65gid",
		"scorerName": "M. Pugh",
		"assistPlayerId": "4j3kopvvd3iej6p4h9z36giqd",
		"assistPlayerName": "R. Lavelle"
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 2,
		"timeMin": 95,
		"goalType": 0,
		"scorerId": "cwerr3zz6pqzzzqso9dx69fjd",
		"scorerName": "A. Sanchez",
		"assistPlayerId": null,
		"assistPlayerName": null
	}],
	"sponsor": null,
	"date": "2022-07-14T23:00:00",
	"time": "23:00:00Z",
	"cancelled": false,
	"lengthMin": 100,
	"lengthSec": 5,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "crqwf0rfcjff0jbi4w0axe95p",
		"longName": "Estadio Universitario de Nuevo León",
		"shortName": "Estadio Universitario de Nuevo León",
		"location": "San Nicolás de los Garza",
		"country": "Mexico"
	},
	"attendance": 0,
	"competition": {
		"id": "ecgvhmaf0m808fxsry941v8ak",
		"name": "Concacaf Women's World Cup Qualifiers",
		"code": "WWQ",
		"format": "International cup",
		"startDate": "2022-02-16T00:00:00Z",
		"endDate": "2022-07-31T00:00:00Z",
		"isFriendly": false
	},
	"periods": [{
		"id": 1,
		"start": "2022-07-14T23:00:33Z",
		"end": "2022-07-14T23:50:00Z",
		"lengthMin": "49",
		"lengthSec": "27",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2022-07-15T00:05:06Z",
		"end": "2022-07-15T00:55:44Z",
		"lengthMin": "50",
		"lengthSec": "38",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "e70zl10x0ayu7y10ry0wi465a",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "home",
		"score": 3,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "a0ggeczqwvygtb00gwi7vd0fl",
		"name": "Costa Rica",
		"officialName": "Costa Rica",
		"shortName": "Costa Rica",
		"code": "CRC",
		"position": "away",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/ecgvhmaf0m808fxsry941v8ak/matches/5sodhq669u8zomwm9qddoz66s",
	"sitecoreData": null,
	"dateTime": "2022-07-14T23:00:00Z",
	"dateTimeObject": "2022-07-14T23:00:00+00:00"
}, {
	"matchId": "770t48oj68tdxe5yyojx3am8k",
	"description": "United States vs Iceland",
	"tournamentCalendarId": "7nb6bv8hsrharzpv8t5gcaexg",
	"tournamentCalendarName": "2022",
	"stageName": "Group Stage",
	"status": 0,
	"goals": [{
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 1,
		"timeMin": 37,
		"goalType": 0,
		"scorerId": "5ibsbj2zqv62c94k5wijnayy2",
		"scorerName": "C. Macário",
		"assistPlayerId": "cwerr3zz6pqzzzqso9dx69fjd",
		"assistPlayerName": "A. Sanchez"
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 1,
		"timeMin": 45,
		"goalType": 0,
		"scorerId": "5ibsbj2zqv62c94k5wijnayy2",
		"scorerName": "C. Macário",
		"assistPlayerId": "dkk9sopglpjgnopxcxkn65gid",
		"assistPlayerName": "M. Pugh"
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 2,
		"timeMin": 60,
		"goalType": 0,
		"scorerId": "dkk9sopglpjgnopxcxkn65gid",
		"scorerName": "M. Pugh",
		"assistPlayerId": "cwerr3zz6pqzzzqso9dx69fjd",
		"assistPlayerName": "A. Sanchez"
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 2,
		"timeMin": 75,
		"goalType": 0,
		"scorerId": "dkk9sopglpjgnopxcxkn65gid",
		"scorerName": "M. Pugh",
		"assistPlayerId": "5ibsbj2zqv62c94k5wijnayy2",
		"assistPlayerName": "C. Macário"
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 2,
		"timeMin": 88,
		"goalType": 0,
		"scorerId": "4t91su0ns46r00vjynp0on5qt",
		"scorerName": "K. Mewis",
		"assistPlayerId": "56y5qt6c03t7f5t7eoebi0wyd",
		"assistPlayerName": "M. Williams-Purce"
	}],
	"sponsor": "",
	"date": "2022-02-24T02:00:00",
	"time": "02:00:00Z",
	"cancelled": false,
	"lengthMin": 93,
	"lengthSec": 57,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "86i7ztgeobez2lcwxl282817y",
		"longName": "Toyota Stadium",
		"shortName": "Toyota Stadium",
		"location": "Frisco, TX",
		"country": "USA"
	},
	"attendance": 0,
	"competition": {
		"id": "dzdh9f7vyvzpq3nmvl22dyx2h",
		"name": "SheBelieves Cup 2022",
		"code": "SBC",
		"format": "International cup",
		"startDate": "2022-02-18T00:00:00Z",
		"endDate": "2022-02-24T00:00:00Z",
		"isFriendly": true
	},
	"periods": [{
		"id": 1,
		"start": "2022-02-24T02:08:50Z",
		"end": "2022-02-24T02:55:51Z",
		"lengthMin": "47",
		"lengthSec": "1",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2022-02-24T03:12:55Z",
		"end": "2022-02-24T03:59:51Z",
		"lengthMin": "46",
		"lengthSec": "56",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "e70zl10x0ayu7y10ry0wi465a",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "home",
		"score": 5,
		"heroFlairTitle": "",
		"heroFlairName": "",
		"heroFlairBody": ""
	}, {
		"id": "aewmai6kvk2mousj7x17ivnc3",
		"name": "Iceland",
		"officialName": "Iceland",
		"shortName": "Iceland",
		"code": "ISL",
		"position": "away",
		"score": 0,
		"heroFlairTitle": "",
		"heroFlairName": "",
		"heroFlairBody": ""
	}],
	"broadcastLinks": [{
		"imageURL": "https://qa-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogoespnblack2x.ashx?h=52&la=en-US&w=120&rev=f93786ecec18465c8bc8666e1d446dcd&hash=EA1408D37C9B4E4CC09CF20AB15777A3",
		"imageAltText": "ESPN",
		"broadcastURLWeb": "https://www.espn.com/watch/",
		"broadcastURLAndroid": "https://www.espn.com/watch/",
		"broadcastURLiOS": "https://www.espn.com/watch/"
	}],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00Z",
		"generalAvailabilityDate": "0001-01-01T00:00:00Z"
	},
	"groupTicketUrl": "",
	"groupTicketText": "",
	"matchFeedUrl": "/competitions/shebelieves-cup-2022/matches/united-states-vs-iceland-2-23-22",
	"sitecoreData": {
		"link1": {"value": {"href": ""}, "editable": null},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {"value": {"href": ""}, "editable": null},
		"matchRecapLink": {"value": {"href": ""}, "editable": null},
		"ticketsLink": {
			"value": {
				"href": "https://tix.axs.com/x3mzIgAAAADKIXbAAgAAAACz%2fv%2f%2f%2fwD%2f%2f%2f%2f%2fCEZDRGFsbGFzAP%2f%2f%2f%2f%2f%2f%2f%2f%2f%2f",
				"text": "Tickets",
				"linktype": "external",
				"url": "https://tix.axs.com/x3mzIgAAAADKIXbAAgAAAACz%2fv%2f%2f%2fwD%2f%2f%2f%2f%2fCEZDRGFsbGFzAP%2f%2f%2f%2f%2f%2f%2f%2f%2f%2f",
				"anchor": "",
				"title": "Tickets",
				"target": "_blank"
			}, "editable": null
		},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/wwcfrance2019backgroundp1.ashx?h=1200&la=en-US&w=3840&rev=f45ce10739234f589f36d39aca7e9510&hash=A256648079CC6A02F8070F882ED0DB53",
				"alt": "",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/wwcfrance2019backgroundcard.ashx?h=720&la=en-US&w=670&rev=751c0234ecbd48bfa5f60b6104526e60&hash=62233C6BBFA006927A82260DC14A3EFB",
				"alt": "WWC Background",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "76xhd5kbwwefu1z53l96kal90", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/shebelieves-cup-2022"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/player-boxing-images/wnt/white/sauerbrunn-white-playernameboxingposterleft.ashx?h=1160&la=en-US&w=880&rev=a32ee89bbbef4d2b9f3087750fc95677&hash=10C133EF7F08BA9CC7EE1E8EE9AAF821",
				"alt": "BECKY SAUERBRUNN",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/player-boxing-images/wnt/rose-red-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=e0c8b573a84e407f80ba77d0809fcc21&hash=3A39453E97F054F498C1A384C382340A",
				"alt": "Rose Lavelle",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "Toyota Stadium", "editable": null},
		"stadiumLocation": {"value": "Frisco, TX", "editable": null},
		"stadiumCountry": {"value": "USA", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/espn2.ashx?h=52&la=en-US&w=120&rev=547b44525b9b46e9a4aed8018274a4db&hash=54CC19929BF7EE43F362F00AB31B024D",
					"alt": "ESPN2",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.espn.com/watch/?type=live&channel=espn2", "editable": null},
			"iosUrl": {"value": "https://apps.apple.com/us/app/espn-scorecenter/id317469184", "editable": null},
			"androidUrl": {"value": "https://play.google.com/store/apps/details?id=com.espn.score_center", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/prendetv_color_rgb.ashx?h=52&la=en-US&w=120&rev=438b824bbd104aaf86895b0406f7fd77&hash=361EA2010F704B11CDDCD9EF8C3A15EA",
					"alt": "PrendeTV",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {
				"value": "https://app.prende.tv/canales?utm_source=PrendeTV&utm_medium=referral&utm_campaign=launch&utm_content=StreamGratis&ko_click_id=ko_b27v6t5cgnpj9lc3h",
				"editable": null
			},
			"iosUrl": {
				"value": "https://app.prende.tv/canales?utm_source=PrendeTV&utm_medium=referral&utm_campaign=launch&utm_content=StreamGratis&ko_click_id=ko_b27v6t5cgnpj9lc3h",
				"editable": null
			},
			"androidUrl": {
				"value": "https://app.prende.tv/canales?utm_source=PrendeTV&utm_medium=referral&utm_campaign=launch&utm_content=StreamGratis&ko_click_id=ko_b27v6t5cgnpj9lc3h",
				"editable": null
			}
		}],
		"currentItemId": "f8f3d274-7730-47dc-8c06-6b1b9d1a6027"
	},
	"dateTime": "2022-02-24T02:00:00Z",
	"dateTimeObject": "2022-02-24T02:00:00+00:00"
}, {
	"matchId": "76r92mg3xo9h620q2he7y13is",
	"description": "United States vs New Zealand",
	"tournamentCalendarId": "7nb6bv8hsrharzpv8t5gcaexg",
	"tournamentCalendarName": "2022",
	"stageName": "Group Stage",
	"status": 0,
	"goals": [{
		"contestantId": "7ru6q1385r15uicntz025xrr1",
		"periodId": 1,
		"timeMin": 5,
		"goalType": 2,
		"scorerId": "6jglg8t2jvdmk12kc2eoo5ot1",
		"scorerName": "M. Moore",
		"assistPlayerId": null,
		"assistPlayerName": null
	}, {
		"contestantId": "7ru6q1385r15uicntz025xrr1",
		"periodId": 1,
		"timeMin": 6,
		"goalType": 2,
		"scorerId": "6jglg8t2jvdmk12kc2eoo5ot1",
		"scorerName": "M. Moore",
		"assistPlayerId": null,
		"assistPlayerName": null
	}, {
		"contestantId": "7ru6q1385r15uicntz025xrr1",
		"periodId": 1,
		"timeMin": 36,
		"goalType": 2,
		"scorerId": "6jglg8t2jvdmk12kc2eoo5ot1",
		"scorerName": "M. Moore",
		"assistPlayerId": null,
		"assistPlayerName": null
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 2,
		"timeMin": 51,
		"goalType": 0,
		"scorerId": "b9d4fqz6ezz2azklpghb9kyjt",
		"scorerName": "A. Hatch",
		"assistPlayerId": "dwwgrimn3ddecp5kllvsdga8l",
		"assistPlayerName": "S. Huerta"
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 2,
		"timeMin": 93,
		"goalType": 0,
		"scorerId": "dkk9sopglpjgnopxcxkn65gid",
		"scorerName": "M. Pugh",
		"assistPlayerId": "b9d4fqz6ezz2azklpghb9kyjt",
		"assistPlayerName": "A. Hatch"
	}],
	"sponsor": "",
	"date": "2022-02-20T20:00:00",
	"time": "20:00:00Z",
	"cancelled": false,
	"lengthMin": 98,
	"lengthSec": 37,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "l7dgi0nyxlitlqy6pmy0yi71",
		"longName": "Dignity Health Sports Park",
		"shortName": "Dignity Health Sports Park",
		"location": "Carson, CA",
		"country": "USA"
	},
	"attendance": 0,
	"competition": {
		"id": "dzdh9f7vyvzpq3nmvl22dyx2h",
		"name": "SheBelieves Cup 2022",
		"code": "SBC",
		"format": "International cup",
		"startDate": "2022-02-18T00:00:00Z",
		"endDate": "2022-02-24T00:00:00Z",
		"isFriendly": true
	},
	"periods": [{
		"id": 1,
		"start": "2022-02-20T20:20:36Z",
		"end": "2022-02-20T21:08:01Z",
		"lengthMin": "47",
		"lengthSec": "25",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2022-02-20T21:25:12Z",
		"end": "2022-02-20T22:16:24Z",
		"lengthMin": "51",
		"lengthSec": "12",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "e70zl10x0ayu7y10ry0wi465a",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "home",
		"score": 5,
		"heroFlairTitle": "",
		"heroFlairName": "",
		"heroFlairBody": ""
	}, {
		"id": "7ru6q1385r15uicntz025xrr1",
		"name": "New Zealand",
		"officialName": "New Zealand",
		"shortName": "New Zealand",
		"code": "NZL",
		"position": "away",
		"score": 0,
		"heroFlairTitle": "",
		"heroFlairName": "",
		"heroFlairBody": ""
	}],
	"broadcastLinks": [{
		"imageURL": "https://qa-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/broadcasters/abclogo.ashx?h=800&la=en-US&w=800&rev=e8312221be8c47a7a3a103c64a648ba5&hash=BE7E0664AD9D8396FAD6F2F8057D8177",
		"imageAltText": "ABC",
		"broadcastURLWeb": "https://abc.com/watch-live/abc",
		"broadcastURLAndroid": "https://abc.com/watch-live/abc",
		"broadcastURLiOS": "https://abc.com/watch-live/abc"
	}],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": "Tickets",
		"url": "https://www.axs.com/events/422553/2022-shebelieves-cup-tickets?skin=dhsp",
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00Z",
		"generalAvailabilityDate": "0001-01-01T00:00:00Z"
	},
	"groupTicketUrl": "",
	"groupTicketText": "",
	"matchFeedUrl": "/competitions/shebelieves-cup-2022/matches/united-states-vs-new-zealand-2-20-22",
	"sitecoreData": {
		"link1": {"value": {"href": ""}, "editable": null},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {"value": {"href": ""}, "editable": null},
		"matchRecapLink": {"value": {"href": ""}, "editable": null},
		"ticketsLink": {
			"value": {
				"href": "https://www.axs.com/events/422553/2022-shebelieves-cup-tickets?skin=dhsp",
				"text": "Tickets",
				"linktype": "external",
				"url": "https://www.axs.com/events/422553/2022-shebelieves-cup-tickets?skin=dhsp",
				"anchor": "",
				"title": "Tickets",
				"target": "_blank"
			}, "editable": null
		},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/wwcfrance2019backgroundp1.ashx?h=1200&la=en-US&w=3840&rev=f45ce10739234f589f36d39aca7e9510&hash=A256648079CC6A02F8070F882ED0DB53",
				"alt": "",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/wwcfrance2019backgroundcard.ashx?h=720&la=en-US&w=670&rev=751c0234ecbd48bfa5f60b6104526e60&hash=62233C6BBFA006927A82260DC14A3EFB",
				"alt": "WWC Background",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "76u19sr1ooou6hldn7d86mwwk", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/shebelieves-cup-2022"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/player-boxing-images/wnt/white/williams-white-playernameboxingposterleft.ashx?h=1160&la=en-US&w=880&rev=19c7c824a85d40aeb8b011d70ca9b645&hash=E2CA65EB5337742779BE87C5792DF6D1",
				"alt": "LYNN WILLIAMS",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/player-boxing-images/wnt/rose-red-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=e0c8b573a84e407f80ba77d0809fcc21&hash=3A39453E97F054F498C1A384C382340A",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "Dignity Health Sports Park", "editable": null},
		"stadiumLocation": {"value": "Carson, CA", "editable": null},
		"stadiumCountry": {"value": "USA", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {"value": {}, "editable": null},
			"webUrl": {"value": "https://abc.com/watch-live/abc", "editable": null},
			"iosUrl": {"value": "https://abc.com/watch-live/abc", "editable": null},
			"androidUrl": {"value": "https://abc.com/watch-live/abc", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/prendetv_color_rgb.ashx?h=52&la=en-US&w=120&rev=438b824bbd104aaf86895b0406f7fd77&hash=361EA2010F704B11CDDCD9EF8C3A15EA",
					"alt": "PrendeTV",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {
				"value": "https://app.prende.tv/canales?utm_source=PrendeTV&utm_medium=referral&utm_campaign=launch&utm_content=StreamGratis&ko_click_id=ko_b27v6t5cgnpj9lc3h",
				"editable": null
			},
			"iosUrl": {
				"value": "https://app.prende.tv/canales?utm_source=PrendeTV&utm_medium=referral&utm_campaign=launch&utm_content=StreamGratis&ko_click_id=ko_b27v6t5cgnpj9lc3h",
				"editable": null
			},
			"androidUrl": {
				"value": "https://app.prende.tv/canales?utm_source=PrendeTV&utm_medium=referral&utm_campaign=launch&utm_content=StreamGratis&ko_click_id=ko_b27v6t5cgnpj9lc3h",
				"editable": null
			}
		}],
		"currentItemId": "bd1cbfb8-6aa0-4040-815f-e9bed5f2efc4"
	},
	"dateTime": "2022-02-20T20:00:00Z",
	"dateTimeObject": "2022-02-20T20:00:00+00:00"
}, {
	"matchId": "76mo6clhgsm82niv8qiu14mxg",
	"description": "United States vs Czech Republic",
	"tournamentCalendarId": "7nb6bv8hsrharzpv8t5gcaexg",
	"tournamentCalendarName": "2022",
	"stageName": "Group Stage",
	"status": 0,
	"goals": [],
	"sponsor": "",
	"date": "2022-02-18T04:00:00",
	"time": "04:00:00Z",
	"cancelled": false,
	"lengthMin": 95,
	"lengthSec": 18,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "l7dgi0nyxlitlqy6pmy0yi71",
		"longName": "Dignity Health Sports Park",
		"shortName": "Dignity Health Sports Park",
		"location": "Carson, CA",
		"country": "USA"
	},
	"attendance": 0,
	"competition": {
		"id": "dzdh9f7vyvzpq3nmvl22dyx2h",
		"name": "SheBelieves Cup 2022",
		"code": "SBC",
		"format": "International cup",
		"startDate": "2022-02-18T00:00:00Z",
		"endDate": "2022-02-24T00:00:00Z",
		"isFriendly": true
	},
	"periods": [{
		"id": 1,
		"start": "2022-02-18T04:09:19Z",
		"end": "2022-02-18T04:55:28Z",
		"lengthMin": "46",
		"lengthSec": "9",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2022-02-18T05:12:05Z",
		"end": "2022-02-18T06:01:14Z",
		"lengthMin": "49",
		"lengthSec": "9",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "e70zl10x0ayu7y10ry0wi465a",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "home",
		"score": 0,
		"heroFlairTitle": "",
		"heroFlairName": "",
		"heroFlairBody": ""
	}, {
		"id": "s0sihta2mrpnug2yr3s3g2rk",
		"name": "Czech Republic",
		"officialName": "Czech Republic",
		"shortName": "Czech Republic",
		"code": "CZE",
		"position": "away",
		"score": 0,
		"heroFlairTitle": "",
		"heroFlairName": "",
		"heroFlairBody": ""
	}],
	"broadcastLinks": [{
		"imageURL": "https://qa-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogoespnblack2x.ashx?h=52&la=en-US&w=120&rev=f93786ecec18465c8bc8666e1d446dcd&hash=EA1408D37C9B4E4CC09CF20AB15777A3",
		"imageAltText": "ESPN",
		"broadcastURLWeb": "https://www.espn.com/watch/",
		"broadcastURLAndroid": "https://www.espn.com/watch/",
		"broadcastURLiOS": "https://www.espn.com/watch/"
	}],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": "Tickets",
		"url": "https://www.axs.com/events/422536/2022-shebelieves-cup-tickets?skin=dhsp",
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00Z",
		"generalAvailabilityDate": "0001-01-01T00:00:00Z"
	},
	"groupTicketUrl": "",
	"groupTicketText": "",
	"matchFeedUrl": "/competitions/shebelieves-cup-2022/matches/united-states-vs-czech-republic-2-17-22",
	"sitecoreData": {
		"link1": {"value": {"href": ""}, "editable": null},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {"value": {"href": ""}, "editable": null},
		"matchRecapLink": {"value": {"href": ""}, "editable": null},
		"ticketsLink": {
			"value": {
				"href": "https://www.axs.com/events/422536/2022-shebelieves-cup-tickets?skin=dhsp",
				"text": "Tickets",
				"linktype": "external",
				"url": "https://www.axs.com/events/422536/2022-shebelieves-cup-tickets?skin=dhsp",
				"anchor": "",
				"title": "Tickets",
				"target": "_blank"
			}, "editable": null
		},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/wwcfrance2019backgroundp1.ashx?h=1200&la=en-US&w=3840&rev=f45ce10739234f589f36d39aca7e9510&hash=A256648079CC6A02F8070F882ED0DB53",
				"alt": "",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/wwcfrance2019backgroundcard.ashx?h=720&la=en-US&w=670&rev=751c0234ecbd48bfa5f60b6104526e60&hash=62233C6BBFA006927A82260DC14A3EFB",
				"alt": "WWC Background",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "76hwbj72c9zvrzn4nyottnthw", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/shebelieves-cup-2022"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/player-boxing-images/wnt/white/williams-white-playernameboxingposterleft.ashx?h=1160&la=en-US&w=880&rev=19c7c824a85d40aeb8b011d70ca9b645&hash=E2CA65EB5337742779BE87C5792DF6D1",
				"alt": "LYNN WILLIAMS",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/player-boxing-images/wnt/rose-red-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=e0c8b573a84e407f80ba77d0809fcc21&hash=3A39453E97F054F498C1A384C382340A",
				"alt": "Rose Lavelle",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "Dignity Health Sports Park", "editable": null},
		"stadiumLocation": {"value": "Carson, CA", "editable": null},
		"stadiumCountry": {"value": "USA", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/espn.ashx?h=52&la=en-US&w=120&rev=3176000e4f444cf7817eee5eb04072f7&hash=4F5CD5F235763C6A7EDDFBA3F6B15536",
					"alt": "ESPN",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.espn.com/watch/", "editable": null},
			"iosUrl": {"value": "https://www.espn.com/watch/", "editable": null},
			"androidUrl": {"value": "https://www.espn.com/watch/", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tudngreen.ashx?h=52&la=en-US&w=120&rev=51f6d8916eab4e51b9e5a8d047a91f53&hash=351DEAC53BCEC7097D915EDC807CEA9C",
					"alt": "TUDN",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.tudn.com/", "editable": null},
			"iosUrl": {"value": "https://www.tudn.com/", "editable": null},
			"androidUrl": {"value": "https://www.tudn.com/", "editable": null}
		}],
		"currentItemId": "175e8dd5-2684-4240-abde-01a0c247f36b"
	},
	"dateTime": "2022-02-18T04:00:00Z",
	"dateTimeObject": "2022-02-18T04:00:00+00:00"
}, {
	"matchId": "c05pvy0r1cad1q6jc5b2bj5lg",
	"description": "Australia vs United States",
	"tournamentCalendarId": "4ppw8e12kkgzlzlfyfh5apnqi",
	"tournamentCalendarName": "2020 Tokyo",
	"stageName": "3rd Place Final",
	"status": 0,
	"goals": [{
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 1,
		"timeMin": 8,
		"goalType": 0,
		"scorerId": "29vjl1iba9rz14yd1g9qi4pn9",
		"scorerName": "M. Rapinoe",
		"assistPlayerId": null,
		"assistPlayerName": null
	}, {
		"contestantId": "7evyxk129dpli7omdned6cjzo",
		"periodId": 1,
		"timeMin": 17,
		"goalType": 0,
		"scorerId": "66f0rjs4c2yih8phn6meyj74l",
		"scorerName": "S. Kerr",
		"assistPlayerId": "ddagc0khq0zh1ywynyci8nzrp",
		"assistPlayerName": "C. Foord"
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 1,
		"timeMin": 21,
		"goalType": 0,
		"scorerId": "29vjl1iba9rz14yd1g9qi4pn9",
		"scorerName": "M. Rapinoe",
		"assistPlayerId": null,
		"assistPlayerName": null
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 1,
		"timeMin": 46,
		"goalType": 0,
		"scorerId": "exfa2ygjuuy08v50w65ddj2l1",
		"scorerName": "C. Lloyd",
		"assistPlayerId": "6ywkny98b9qzuvhqxwn9l0ogl",
		"assistPlayerName": "L. Horan"
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 2,
		"timeMin": 51,
		"goalType": 0,
		"scorerId": "exfa2ygjuuy08v50w65ddj2l1",
		"scorerName": "C. Lloyd",
		"assistPlayerId": null,
		"assistPlayerName": null
	}, {
		"contestantId": "7evyxk129dpli7omdned6cjzo",
		"periodId": 2,
		"timeMin": 54,
		"goalType": 0,
		"scorerId": "ddagc0khq0zh1ywynyci8nzrp",
		"scorerName": "C. Foord",
		"assistPlayerId": "1l9ywk95rm7fgpc8nrtsxos0l",
		"assistPlayerName": "K. Simon"
	}, {
		"contestantId": "7evyxk129dpli7omdned6cjzo",
		"periodId": 2,
		"timeMin": 90,
		"goalType": 0,
		"scorerId": "dcv449u8n6hvahqbgdjwuidw5",
		"scorerName": "E. Gielnik",
		"assistPlayerId": "ddagc0khq0zh1ywynyci8nzrp",
		"assistPlayerName": "C. Foord"
	}],
	"sponsor": null,
	"date": "2021-08-05T08:00:00",
	"time": "08:00:00Z",
	"cancelled": false,
	"lengthMin": 99,
	"lengthSec": 33,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "47i9r6k0ui5n0v0iixpops26p",
		"longName": "Kashima Soccer Stadium",
		"shortName": "Kashima Soccer Stadium",
		"location": "Kashima",
		"country": "Japan"
	},
	"attendance": 0,
	"competition": {
		"id": "cjmdp7ds2hanz0r7kdifi0tsl",
		"name": "Olympics Women",
		"code": "OLW",
		"format": "International cup",
		"startDate": "2021-07-21T00:00:00Z",
		"endDate": "2021-08-06T00:00:00Z",
		"isFriendly": false
	},
	"periods": [{
		"id": 1,
		"start": "2021-08-05T08:00:29Z",
		"end": "2021-08-05T08:48:31Z",
		"lengthMin": "48",
		"lengthSec": "2",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2021-08-05T09:03:36Z",
		"end": "2021-08-05T09:55:07Z",
		"lengthMin": "51",
		"lengthSec": "31",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "7evyxk129dpli7omdned6cjzo",
		"name": "Australia",
		"officialName": "Australia",
		"shortName": "Australia",
		"code": "AUS",
		"position": "home",
		"score": 3,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "e70zl10x0ayu7y10ry0wi465a",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "away",
		"score": 4,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/tokyo-olympics-2020/matches/australia-vs-united-states",
	"sitecoreData": {
		"link1": {"value": {"href": ""}, "editable": null},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {
			"value": {
				"href": "/stories/2021/07/2020-tokyo-olympics-uswnt-vs-australia-bronze-preview-schedule-tv-channels-start-time",
				"querystring": "",
				"target": "",
				"linktype": "internal",
				"id": "{628F5CD6-4EF7-4AD1-92CB-6FCDB378B4AC}",
				"url": "/USSF/Home/Stories/2021/07/2020 Tokyo Olympics uswnt vs Australia Bronze Preview Schedule TV Channels Start Time"
			}, "editable": null
		},
		"matchRecapLink": {"value": {"href": ""}, "editable": null},
		"ticketsLink": {"value": {"href": ""}, "editable": null},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/wnt/match-backgrounds/backgroundblue.ashx?h=1200&la=en-US&w=3840&rev=5f5bb9ef0ba345c1b9b013598f0e6758&hash=3107C04CB54A143F8EDCF691228D3EEA",
				"alt": "Blue Chevron Background Pattern",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/matchcarddefault.ashx?h=720&la=en-US&w=670&rev=2cfa098f22514cb582a559acc92fa48a&hash=1FC8EC20CB4F56C8C888C0C13B25D8D3",
				"alt": "",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/tokyo-olympics-2020"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/player-boxing-images/wnt/white/williams-white-playernameboxingposterleft.ashx?h=1160&la=en-US&w=880&rev=19c7c824a85d40aeb8b011d70ca9b645&hash=E2CA65EB5337742779BE87C5792DF6D1",
				"alt": "LYNN WILLIAMS",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/player-boxing-images/wnt/white/dunn-white-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=997d28d510844c04820a5a01ac526442&hash=C76E657006D3253708F93E03965C65EE",
				"alt": "CRYSTAL DUNN",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "KASHIMA SOCCER STADIUM", "editable": null},
		"stadiumLocation": {"value": "KASHIMA", "editable": null},
		"stadiumCountry": {"value": "Japan", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/usa-network-logo.ashx?h=140&la=en-US&w=279&rev=0eae4cd27c684bccb65981ffde0f45ca&hash=AD041DBB4CF0BC8A287504B2C0BDF93B",
					"alt": "USA",
					"width": "279",
					"height": "140"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.usanetwork.com/live", "editable": null},
			"iosUrl": {"value": "https://www.usanetwork.com/live", "editable": null},
			"androidUrl": {"value": "https://www.usanetwork.com/live", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/telomundo-app.ashx?h=158&la=en-US&w=332&rev=5480ce7e8b68482a810623b71e1b0cda&hash=1956C309818FE62CAC84CAD4545267B0",
					"alt": "Telemundo + Telemundo Deportes App",
					"width": "332",
					"height": "158"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.telemundodeportes.com/", "editable": null},
			"iosUrl": {"value": "https://www.telemundodeportes.com/", "editable": null},
			"androidUrl": {"value": "https://www.telemundodeportes.com/", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/nbc-olympics-logo.ashx?h=166&la=en-US&w=310&rev=6c77e01516d1418f8d65738503d44d3c&hash=872D3B363317DCC64DE8BBCD38ADA90C",
					"alt": "NBCOlympics.com",
					"width": "310",
					"height": "166"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.nbcolympics.com/", "editable": null},
			"iosUrl": {"value": "https://www.nbcolympics.com/", "editable": null},
			"androidUrl": {"value": "https://www.nbcolympics.com/", "editable": null}
		}],
		"currentItemId": "41278402-e097-4d15-ae02-0b1bdc149b30"
	},
	"dateTime": "2021-08-05T08:00:00Z",
	"dateTimeObject": "2021-08-05T08:00:00+00:00"
}, {
	"matchId": "7vzrdnpaanfy0ab2tnh0k65n8",
	"description": "United States vs Canada",
	"tournamentCalendarId": "4ppw8e12kkgzlzlfyfh5apnqi",
	"tournamentCalendarName": "2020 Tokyo",
	"stageName": "Semi-finals",
	"status": 0,
	"goals": [{
		"contestantId": "e3vi0exjm76v5bfnmoj15bg2i",
		"periodId": 2,
		"timeMin": 75,
		"goalType": 1,
		"scorerId": "ek8x7y3jt0xcebttt75qi5exh",
		"scorerName": "J. Fleming",
		"assistPlayerId": null,
		"assistPlayerName": null
	}],
	"sponsor": null,
	"date": "2021-08-02T08:00:00",
	"time": "08:00:00Z",
	"cancelled": false,
	"lengthMin": 100,
	"lengthSec": 12,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "47i9r6k0ui5n0v0iixpops26p",
		"longName": "Kashima Soccer Stadium",
		"shortName": "Kashima Soccer Stadium",
		"location": "Kashima",
		"country": "Japan"
	},
	"attendance": 0,
	"competition": {
		"id": "cjmdp7ds2hanz0r7kdifi0tsl",
		"name": "Olympics Women",
		"code": "OLW",
		"format": "International cup",
		"startDate": "2021-07-21T00:00:00Z",
		"endDate": "2021-08-06T00:00:00Z",
		"isFriendly": false
	},
	"periods": [{
		"id": 1,
		"start": "2021-08-02T08:00:31Z",
		"end": "2021-08-02T08:51:41Z",
		"lengthMin": "51",
		"lengthSec": "10",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2021-08-02T09:07:12Z",
		"end": "2021-08-02T09:56:14Z",
		"lengthMin": "49",
		"lengthSec": "2",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "e70zl10x0ayu7y10ry0wi465a",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "home",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "e3vi0exjm76v5bfnmoj15bg2i",
		"name": "Canada",
		"officialName": "Canada",
		"shortName": "Canada",
		"code": "CAN",
		"position": "away",
		"score": 1,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/tokyo-olympics-2020/matches/united-states-vs-canada",
	"sitecoreData": {
		"link1": {"value": {"href": ""}, "editable": null},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {"value": {"href": ""}, "editable": null},
		"matchRecapLink": {
			"value": {
				"href": "/stories/2021/07/2020-tokyo-olympics-semifinal-uswnt-0-vs-canada-1-match-report-stats-and-bracket",
				"querystring": "",
				"target": "",
				"linktype": "internal",
				"id": "{FA8C2468-1CFB-4853-992C-15DC1F560990}",
				"url": "/USSF/Home/Stories/2021/07/2020 Tokyo Olympics Semifinal uswnt 0 vs Canada 1 Match Report Stats and Bracket"
			}, "editable": null
		},
		"ticketsLink": {"value": {"href": ""}, "editable": null},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/wnt/match-backgrounds/backgroundblue.ashx?h=1200&la=en-US&w=3840&rev=5f5bb9ef0ba345c1b9b013598f0e6758&hash=3107C04CB54A143F8EDCF691228D3EEA",
				"alt": "Blue Chevron Background Pattern",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/matchcarddefault.ashx?h=720&la=en-US&w=670&rev=2cfa098f22514cb582a559acc92fa48a&hash=1FC8EC20CB4F56C8C888C0C13B25D8D3",
				"alt": "",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/tokyo-olympics-2020"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/wnt/carli-lloyd/lloyd-playernameboxingposterleft.ashx?h=1160&la=en-US&w=880&rev=f33a35da2ba34fb894542a5647416db8&hash=C2703EC4B7E9A144EFB059BB3083C246",
				"alt": "Carli Lloyd",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/wnt/megan-rapinoe/rapinoe-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=e38d9c35f77241b6a6a17e138d4e189b&hash=4C3D41F39198CEC528372C4219F4E7C2",
				"alt": "Megan Rapinoe",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "", "editable": null},
		"stadiumLocation": {"value": "", "editable": null},
		"stadiumCountry": {"value": "", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/usa-network-logo.ashx?h=140&la=en-US&w=279&rev=0eae4cd27c684bccb65981ffde0f45ca&hash=AD041DBB4CF0BC8A287504B2C0BDF93B",
					"alt": "USA",
					"width": "279",
					"height": "140"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.usanetwork.com/live", "editable": null},
			"iosUrl": {"value": "https://www.usanetwork.com/live", "editable": null},
			"androidUrl": {"value": "https://www.usanetwork.com/live", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/telomundo-app.ashx?h=158&la=en-US&w=332&rev=5480ce7e8b68482a810623b71e1b0cda&hash=1956C309818FE62CAC84CAD4545267B0",
					"alt": "Telemundo + Telemundo Deportes App",
					"width": "332",
					"height": "158"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.telemundodeportes.com/", "editable": null},
			"iosUrl": {"value": "https://www.telemundodeportes.com/", "editable": null},
			"androidUrl": {"value": "https://www.telemundodeportes.com/", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/nbc-olympics-logo.ashx?h=166&la=en-US&w=310&rev=6c77e01516d1418f8d65738503d44d3c&hash=872D3B363317DCC64DE8BBCD38ADA90C",
					"alt": "NBCOlympics.com",
					"width": "310",
					"height": "166"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.nbcolympics.com/", "editable": null},
			"iosUrl": {"value": "https://www.nbcolympics.com/", "editable": null},
			"androidUrl": {"value": "https://www.nbcolympics.com/", "editable": null}
		}],
		"currentItemId": "c0a5861d-4c23-4ce2-926e-3e884454b45c"
	},
	"dateTime": "2021-08-02T08:00:00Z",
	"dateTimeObject": "2021-08-02T08:00:00+00:00"
}, {
	"matchId": "7fw9ebqyjdz5x3pjfxs29ku1g",
	"description": "Netherlands vs United States",
	"tournamentCalendarId": "4ppw8e12kkgzlzlfyfh5apnqi",
	"tournamentCalendarName": "2020 Tokyo",
	"stageName": "Quarter-finals",
	"status": 0,
	"goals": [{
		"contestantId": "9x7z0xiidyp39vy69huxf6kim",
		"periodId": 1,
		"timeMin": 18,
		"goalType": 0,
		"scorerId": "wupxscyf4x7rsjidpmp0wnv9",
		"scorerName": "V. Miedema",
		"assistPlayerId": null,
		"assistPlayerName": null
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 1,
		"timeMin": 28,
		"goalType": 0,
		"scorerId": "4tcmt910n2lj88n5m0aqoddcl",
		"scorerName": "S. Mewis",
		"assistPlayerId": "cm6fzm8v38ti0aubu0q4v717t",
		"assistPlayerName": "L. Williams"
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 1,
		"timeMin": 31,
		"goalType": 0,
		"scorerId": "cm6fzm8v38ti0aubu0q4v717t",
		"scorerName": "L. Williams",
		"assistPlayerId": "4tcmt910n2lj88n5m0aqoddcl",
		"assistPlayerName": "S. Mewis"
	}, {
		"contestantId": "9x7z0xiidyp39vy69huxf6kim",
		"periodId": 2,
		"timeMin": 54,
		"goalType": 0,
		"scorerId": "wupxscyf4x7rsjidpmp0wnv9",
		"scorerName": "V. Miedema",
		"assistPlayerId": null,
		"assistPlayerName": null
	}],
	"sponsor": null,
	"date": "2021-07-30T11:00:00",
	"time": "11:00:00Z",
	"cancelled": false,
	"lengthMin": 130,
	"lengthSec": 19,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "6fwxkt6fc1g0nuxu6z43dl850",
		"longName": "Nissan Stadium",
		"shortName": "Nissan Stadium",
		"location": "Yokohama",
		"country": "Japan"
	},
	"attendance": 0,
	"competition": {
		"id": "cjmdp7ds2hanz0r7kdifi0tsl",
		"name": "Olympics Women",
		"code": "OLW",
		"format": "International cup",
		"startDate": "2021-07-21T00:00:00Z",
		"endDate": "2021-08-06T00:00:00Z",
		"isFriendly": false
	},
	"periods": [{
		"id": 1,
		"start": "2021-07-30T11:00:17Z",
		"end": "2021-07-30T11:47:20Z",
		"lengthMin": "47",
		"lengthSec": "3",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2021-07-30T12:03:07Z",
		"end": "2021-07-30T12:53:06Z",
		"lengthMin": "49",
		"lengthSec": "59",
		"addedTime": 0
	}, {
		"id": 3,
		"start": "2021-07-30T12:58:11Z",
		"end": "2021-07-30T13:15:12Z",
		"lengthMin": "17",
		"lengthSec": "1",
		"addedTime": 0
	}, {
		"id": 4,
		"start": "2021-07-30T13:18:17Z",
		"end": "2021-07-30T13:34:33Z",
		"lengthMin": "16",
		"lengthSec": "16",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "9x7z0xiidyp39vy69huxf6kim",
		"name": "Netherlands",
		"officialName": "Netherlands",
		"shortName": "Netherlands",
		"code": "NED",
		"position": "home",
		"score": 2,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "e70zl10x0ayu7y10ry0wi465a",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "away",
		"score": 2,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [{
		"contestantId": "9x7z0xiidyp39vy69huxf6kim",
		"outcome": "saved",
		"playerId": "wupxscyf4x7rsjidpmp0wnv9",
		"playerName": "V. Miedema"
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"outcome": "scored",
		"playerId": "4j3kopvvd3iej6p4h9z36giqd",
		"playerName": "R. Lavelle"
	}, {
		"contestantId": "9x7z0xiidyp39vy69huxf6kim",
		"outcome": "scored",
		"playerId": "dqpv2janh4g4qkiqx7h7vs8gl",
		"playerName": "D. Janssen"
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"outcome": "scored",
		"playerId": "l28lqh3f3ojx6kk2wvvq6nyt",
		"playerName": "A. Morgan"
	}, {
		"contestantId": "9x7z0xiidyp39vy69huxf6kim",
		"outcome": "scored",
		"playerId": "3izcnjpqeumq1mjfy1osc3qhh",
		"playerName": "S. van der Gragt"
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"outcome": "scored",
		"playerId": "bnxnrws4yintb6s76erzl9xw5",
		"playerName": "C. Press"
	}, {
		"contestantId": "9x7z0xiidyp39vy69huxf6kim",
		"outcome": "saved",
		"playerId": "1euxm106eodeidsr5gor1kbmd",
		"playerName": "A. Nouwen"
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"outcome": "scored",
		"playerId": "29vjl1iba9rz14yd1g9qi4pn9",
		"playerName": "M. Rapinoe"
	}],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/tokyo-olympics-2020/matches/netherlands-vs-united-states",
	"sitecoreData": {
		"link1": {"value": {"href": ""}, "editable": null},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {
			"value": {
				"href": "/stories/2021/07/2020-tokyo-olympics-uswnt-vs-netherlands-preview-schedule-tv-channels-start-time",
				"querystring": "",
				"target": "",
				"linktype": "internal",
				"id": "{13739165-2AC5-4023-816A-75B70DCC8FF9}",
				"url": "/USSF/Home/Stories/2021/07/2020 Tokyo Olympics uswnt vs Netherlands Preview Schedule TV Channels Start Time"
			}, "editable": null
		},
		"matchRecapLink": {
			"value": {
				"href": "/stories/2021/07/2020-tokyo-olympics-quarterfinal-uswnt-2-vs-netherlands-2-match-report-stats-and-bracket",
				"querystring": "",
				"target": "",
				"linktype": "internal",
				"id": "{9669D1B9-ABC1-4984-A8BD-E90CE3C41394}",
				"url": "/USSF/Home/Stories/2021/07/2020 Tokyo Olympics Quarterfinal uswnt 2 vs Netherlands 2 Match Report Stats and Bracket"
			}, "editable": null
		},
		"ticketsLink": {"value": {"href": ""}, "editable": null},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/wnt/match-backgrounds/backgroundblue.ashx?h=1200&la=en-US&w=3840&rev=5f5bb9ef0ba345c1b9b013598f0e6758&hash=3107C04CB54A143F8EDCF691228D3EEA",
				"alt": "Blue Chevron Background Pattern",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/matchcarddefault.ashx?h=720&la=en-US&w=670&rev=2cfa098f22514cb582a559acc92fa48a&hash=1FC8EC20CB4F56C8C888C0C13B25D8D3",
				"alt": "",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/tokyo-olympics-2020"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/wnt/carli-lloyd/lloyd-playernameboxingposterleft.ashx?h=1160&la=en-US&w=880&rev=f33a35da2ba34fb894542a5647416db8&hash=C2703EC4B7E9A144EFB059BB3083C246",
				"alt": "Carli Lloyd",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/wnt/megan-rapinoe/rapinoe-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=e38d9c35f77241b6a6a17e138d4e189b&hash=4C3D41F39198CEC528372C4219F4E7C2",
				"alt": "Megan Rapinoe",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "International Stadium Yokohama", "editable": null},
		"stadiumLocation": {"value": "Yokohama", "editable": null},
		"stadiumCountry": {"value": "Japan", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/nbcsn-logo.ashx?h=140&la=en-US&w=279&rev=f18f1699a39c4b00b6f530a2b7b31f53&hash=E356B4EC5A0E15E4EF439FD1BACE9679",
					"alt": "NBCSN",
					"width": "279",
					"height": "140"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.nbcsports.com/live", "editable": null},
			"iosUrl": {"value": "https://www.nbcsports.com/live", "editable": null},
			"androidUrl": {"value": "https://www.nbcsports.com/live", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/telomundo-app.ashx?h=158&la=en-US&w=332&rev=5480ce7e8b68482a810623b71e1b0cda&hash=1956C309818FE62CAC84CAD4545267B0",
					"alt": "Telemundo + Telemundo Deportes App",
					"width": "332",
					"height": "158"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.telemundodeportes.com/", "editable": null},
			"iosUrl": {"value": "https://www.telemundodeportes.com/", "editable": null},
			"androidUrl": {"value": "https://www.telemundodeportes.com/", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/nbc-olympics-logo.ashx?h=166&la=en-US&w=310&rev=6c77e01516d1418f8d65738503d44d3c&hash=872D3B363317DCC64DE8BBCD38ADA90C",
					"alt": "NBCOlympics.com",
					"width": "310",
					"height": "166"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.nbcolympics.com/", "editable": null},
			"iosUrl": {"value": "https://www.nbcolympics.com/", "editable": null},
			"androidUrl": {"value": "https://www.nbcolympics.com/", "editable": null}
		}],
		"currentItemId": "893ddd27-077d-4508-b865-c45fcdc62797"
	},
	"dateTime": "2021-07-30T11:00:00Z",
	"dateTimeObject": "2021-07-30T11:00:00+00:00"
}, {
	"matchId": "e4xlpzy4704yul794wo588hec",
	"description": "United States vs Australia",
	"tournamentCalendarId": "4ppw8e12kkgzlzlfyfh5apnqi",
	"tournamentCalendarName": "2020 Tokyo",
	"stageName": "Group Stage",
	"status": 0,
	"goals": [],
	"sponsor": null,
	"date": "2021-07-27T08:00:00",
	"time": "08:00:00Z",
	"cancelled": false,
	"lengthMin": 95,
	"lengthSec": 5,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "47i9r6k0ui5n0v0iixpops26p",
		"longName": "Kashima Soccer Stadium",
		"shortName": "Kashima Soccer Stadium",
		"location": "Kashima",
		"country": "Japan"
	},
	"attendance": 0,
	"competition": {
		"id": "cjmdp7ds2hanz0r7kdifi0tsl",
		"name": "Olympics Women",
		"code": "OLW",
		"format": "International cup",
		"startDate": "2021-07-21T00:00:00Z",
		"endDate": "2021-08-06T00:00:00Z",
		"isFriendly": false
	},
	"periods": [{
		"id": 1,
		"start": "2021-07-27T08:01:24Z",
		"end": "2021-07-27T08:47:25Z",
		"lengthMin": "46",
		"lengthSec": "1",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2021-07-27T09:02:58Z",
		"end": "2021-07-27T09:52:02Z",
		"lengthMin": "49",
		"lengthSec": "4",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "e70zl10x0ayu7y10ry0wi465a",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "home",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "7evyxk129dpli7omdned6cjzo",
		"name": "Australia",
		"officialName": "Australia",
		"shortName": "Australia",
		"code": "AUS",
		"position": "away",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/tokyo-olympics-2020/matches/united-states-vs-australia",
	"sitecoreData": {
		"link1": {
			"value": {
				"href": "https://www.foxsports.com/live/fs1",
				"text": "FS1",
				"linktype": "external",
				"url": "https://www.foxsports.com/live/fs1",
				"anchor": "",
				"title": "FS1",
				"target": "_blank"
			}, "editable": null
		},
		"link2": {
			"value": {
				"href": "https://www.tudn.com/",
				"text": "TUDN",
				"linktype": "external",
				"url": "https://www.tudn.com/",
				"anchor": "",
				"title": "TUDN",
				"target": "_blank"
			}, "editable": null
		},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {"value": {"href": ""}, "editable": null},
		"matchRecapLink": {
			"value": {
				"href": "/stories/2021/07/2020-tokyo-olympics-uswnt-0-vs-australia-0-match-report-stats-and-group-standings",
				"querystring": "",
				"target": "",
				"linktype": "internal",
				"id": "{AC817DB0-64B1-49E7-A1BD-5D3BC19C78CD}",
				"url": "/USSF/Home/Stories/2021/07/2020 Tokyo Olympics uswnt 0 vs Australia 0 Match Report Stats and group Standings"
			}, "editable": null
		},
		"ticketsLink": {"value": {"href": ""}, "editable": null},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/wnt/match-backgrounds/backgroundblue.ashx?h=1200&la=en-US&w=3840&rev=5f5bb9ef0ba345c1b9b013598f0e6758&hash=3107C04CB54A143F8EDCF691228D3EEA",
				"alt": "Blue Chevron Background Pattern",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/matchcarddefault.ashx?h=720&la=en-US&w=670&rev=2cfa098f22514cb582a559acc92fa48a&hash=1FC8EC20CB4F56C8C888C0C13B25D8D3",
				"alt": "",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/tokyo-olympics-2020"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/wnt/julie-ertz/julie-ertz-white-playernameboxingposterleft.ashx?h=1160&la=en-US&w=880&rev=dd9630a2fcf74270a3c1b7318cf1cf1e&hash=6973A3788C7E1970D8B9DDDAF38B6852",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/wnt/2019/dunn-white-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=d6e14c45aa3b4471b1b8ec5578311d05&hash=66839AB2F5569D2938351E617C0F37F3",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "", "editable": null},
		"stadiumLocation": {"value": "", "editable": null},
		"stadiumCountry": {"value": "", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/usa-network-logo.ashx?h=140&la=en-US&w=279&rev=0eae4cd27c684bccb65981ffde0f45ca&hash=AD041DBB4CF0BC8A287504B2C0BDF93B",
					"alt": "USA",
					"width": "279",
					"height": "140"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.usanetwork.com/live", "editable": null},
			"iosUrl": {"value": "https://www.usanetwork.com/live", "editable": null},
			"androidUrl": {"value": "https://www.usanetwork.com/live", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/telomundo-app.ashx?h=158&la=en-US&w=332&rev=5480ce7e8b68482a810623b71e1b0cda&hash=1956C309818FE62CAC84CAD4545267B0",
					"alt": "Telemundo + Telemundo Deportes App",
					"width": "332",
					"height": "158"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.telemundodeportes.com/", "editable": null},
			"iosUrl": {"value": "https://www.telemundodeportes.com/", "editable": null},
			"androidUrl": {"value": "https://www.telemundodeportes.com/", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/nbc-olympics-logo.ashx?h=166&la=en-US&w=310&rev=6c77e01516d1418f8d65738503d44d3c&hash=872D3B363317DCC64DE8BBCD38ADA90C",
					"alt": "NBCOlympics.com",
					"width": "310",
					"height": "166"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.nbcolympics.com/", "editable": null},
			"iosUrl": {"value": "https://www.nbcolympics.com/", "editable": null},
			"androidUrl": {"value": "https://www.nbcolympics.com/", "editable": null}
		}],
		"currentItemId": "afa7acaf-71ca-4f36-8c34-2c36cc2589cd"
	},
	"dateTime": "2021-07-27T08:00:00Z",
	"dateTimeObject": "2021-07-27T08:00:00+00:00"
}, {
	"matchId": "e4tebsy1fnl64dkfen2cqb1g4",
	"description": "New Zealand vs United States",
	"tournamentCalendarId": "4ppw8e12kkgzlzlfyfh5apnqi",
	"tournamentCalendarName": "2020 Tokyo",
	"stageName": "Group Stage",
	"status": 0,
	"goals": [{
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 1,
		"timeMin": 9,
		"goalType": 0,
		"scorerId": "4j3kopvvd3iej6p4h9z36giqd",
		"scorerName": "R. Lavelle",
		"assistPlayerId": "cxl4bxs35ifb8o5cq9jxi1ec5",
		"assistPlayerName": "T. Heath"
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 1,
		"timeMin": 45,
		"goalType": 0,
		"scorerId": "6ywkny98b9qzuvhqxwn9l0ogl",
		"scorerName": "L. Horan",
		"assistPlayerId": "dve5pao9dmobh167wb8g45g45",
		"assistPlayerName": "J. Ertz"
	}, {
		"contestantId": "7ru6q1385r15uicntz025xrr1",
		"periodId": 2,
		"timeMin": 63,
		"goalType": 2,
		"scorerId": "5rtm5d6v7nfqjh9ahayyc1bkl",
		"scorerName": "A. Erceg",
		"assistPlayerId": null,
		"assistPlayerName": null
	}, {
		"contestantId": "7ru6q1385r15uicntz025xrr1",
		"periodId": 2,
		"timeMin": 72,
		"goalType": 0,
		"scorerId": "1e045aj0z1gyj1k6fxa4xxp1",
		"scorerName": "B. Hassett",
		"assistPlayerId": "m2t6uj0c31exgxlx4f6qgiqd",
		"assistPlayerName": "P. Satchell"
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 2,
		"timeMin": 80,
		"goalType": 0,
		"scorerId": "bnxnrws4yintb6s76erzl9xw5",
		"scorerName": "C. Press",
		"assistPlayerId": "dve5pao9dmobh167wb8g45g45",
		"assistPlayerName": "J. Ertz"
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 2,
		"timeMin": 88,
		"goalType": 0,
		"scorerId": "l28lqh3f3ojx6kk2wvvq6nyt",
		"scorerName": "A. Morgan",
		"assistPlayerId": "bnxnrws4yintb6s76erzl9xw5",
		"assistPlayerName": "C. Press"
	}, {
		"contestantId": "7ru6q1385r15uicntz025xrr1",
		"periodId": 2,
		"timeMin": 93,
		"goalType": 2,
		"scorerId": "6k1s7y9dnzdpppppdco7bgudx",
		"scorerName": "C. Bott",
		"assistPlayerId": null,
		"assistPlayerName": null
	}],
	"sponsor": null,
	"date": "2021-07-24T11:30:00",
	"time": "11:30:00Z",
	"cancelled": false,
	"lengthMin": 94,
	"lengthSec": 21,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "6v9w7jkac4jbarkwiga3rfiwh",
		"longName": "Saitama Stadium 2002",
		"shortName": "Saitama Stadium 2002",
		"location": "Saitama",
		"country": "Japan"
	},
	"attendance": 0,
	"competition": {
		"id": "cjmdp7ds2hanz0r7kdifi0tsl",
		"name": "Olympics Women",
		"code": "OLW",
		"format": "International cup",
		"startDate": "2021-07-21T00:00:00Z",
		"endDate": "2021-08-06T00:00:00Z",
		"isFriendly": false
	},
	"periods": [{
		"id": 1,
		"start": "2021-07-24T11:30:49Z",
		"end": "2021-07-24T12:17:06Z",
		"lengthMin": "46",
		"lengthSec": "17",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2021-07-24T12:32:15Z",
		"end": "2021-07-24T13:20:19Z",
		"lengthMin": "48",
		"lengthSec": "4",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "7ru6q1385r15uicntz025xrr1",
		"name": "New Zealand",
		"officialName": "New Zealand",
		"shortName": "New Zealand",
		"code": "NZL",
		"position": "home",
		"score": 1,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "e70zl10x0ayu7y10ry0wi465a",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "away",
		"score": 6,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/tokyo-olympics-2020/matches/new-zealand-vs-united-states",
	"sitecoreData": {
		"link1": {"value": {"href": ""}, "editable": null},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {"value": {"href": ""}, "editable": null},
		"matchRecapLink": {
			"value": {
				"href": "/stories/2021/07/2020-tokyo-olympics-uswnt-6-vs-new-zealand-1-match-report-stats-and-group-standings",
				"querystring": "",
				"target": "",
				"linktype": "internal",
				"id": "{9172F60D-5C2A-402C-B2D7-0185BE7BF2C2}",
				"url": "/USSF/Home/Stories/2021/07/2020 Tokyo Olympics uswnt 6 vs New Zealand 1 Match Report Stats and group Standings"
			}, "editable": null
		},
		"ticketsLink": {"value": {"href": ""}, "editable": null},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/wnt/match-backgrounds/backgroundblue.ashx?h=1200&la=en-US&w=3840&rev=5f5bb9ef0ba345c1b9b013598f0e6758&hash=3107C04CB54A143F8EDCF691228D3EEA",
				"alt": "Blue Chevron Background Pattern",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/matchcarddefault.ashx?h=720&la=en-US&w=670&rev=2cfa098f22514cb582a559acc92fa48a&hash=1FC8EC20CB4F56C8C888C0C13B25D8D3",
				"alt": "",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/tokyo-olympics-2020"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/wnt/carli-lloyd/lloyd-playernameboxingposterleft.ashx?h=1160&la=en-US&w=880&rev=f33a35da2ba34fb894542a5647416db8&hash=C2703EC4B7E9A144EFB059BB3083C246",
				"alt": "Carli Lloyd",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/wnt/megan-rapinoe/rapinoe-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=e38d9c35f77241b6a6a17e138d4e189b&hash=4C3D41F39198CEC528372C4219F4E7C2",
				"alt": "Megan Rapinoe",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "Saitama Stadium", "editable": null},
		"stadiumLocation": {"value": "Saitama", "editable": null},
		"stadiumCountry": {"value": "Japan", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/nbcsn-logo.ashx?h=140&la=en-US&w=279&rev=f18f1699a39c4b00b6f530a2b7b31f53&hash=E356B4EC5A0E15E4EF439FD1BACE9679",
					"alt": "NBCSN",
					"width": "279",
					"height": "140"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.nbcsports.com/live", "editable": null},
			"iosUrl": {"value": "https://www.nbcsports.com/live", "editable": null},
			"androidUrl": {"value": "https://www.nbcsports.com/live", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/telomundo-app.ashx?h=158&la=en-US&w=332&rev=5480ce7e8b68482a810623b71e1b0cda&hash=1956C309818FE62CAC84CAD4545267B0",
					"alt": "Telemundo + Telemundo Deportes App",
					"width": "332",
					"height": "158"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.telemundodeportes.com/", "editable": null},
			"iosUrl": {"value": "https://www.telemundodeportes.com/", "editable": null},
			"androidUrl": {"value": "https://www.telemundodeportes.com/", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/nbc-olympics-logo.ashx?h=166&la=en-US&w=310&rev=6c77e01516d1418f8d65738503d44d3c&hash=872D3B363317DCC64DE8BBCD38ADA90C",
					"alt": "NBCOlympics.com",
					"width": "310",
					"height": "166"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.nbcolympics.com/", "editable": null},
			"iosUrl": {"value": "https://www.nbcolympics.com/", "editable": null},
			"androidUrl": {"value": "https://www.nbcolympics.com/", "editable": null}
		}],
		"currentItemId": "70705b0a-235e-4bde-b79d-ce6e870cd9af"
	},
	"dateTime": "2021-07-24T11:30:00Z",
	"dateTimeObject": "2021-07-24T11:30:00+00:00"
}, {
	"matchId": "e4jx7tdp1i3sbpqb8avl5hw5w",
	"description": "Sweden vs United States",
	"tournamentCalendarId": "4ppw8e12kkgzlzlfyfh5apnqi",
	"tournamentCalendarName": "2020 Tokyo",
	"stageName": "Group Stage",
	"status": 0,
	"goals": [{
		"contestantId": "52rvkys9wse9ip8a181ik4697",
		"periodId": 1,
		"timeMin": 25,
		"goalType": 0,
		"scorerId": "ec1vrqeqni3lmibeazgq62eol",
		"scorerName": "S. Blackstenius",
		"assistPlayerId": "1i4e7ehhib7vt3tx5xmd9efv9",
		"assistPlayerName": "S. Jakobsson"
	}, {
		"contestantId": "52rvkys9wse9ip8a181ik4697",
		"periodId": 2,
		"timeMin": 54,
		"goalType": 0,
		"scorerId": "ec1vrqeqni3lmibeazgq62eol",
		"scorerName": "S. Blackstenius",
		"assistPlayerId": null,
		"assistPlayerName": null
	}, {
		"contestantId": "52rvkys9wse9ip8a181ik4697",
		"periodId": 2,
		"timeMin": 72,
		"goalType": 0,
		"scorerId": "3i4ljm3hqc6wg8s6l4onlagut",
		"scorerName": "L. Hurtig",
		"assistPlayerId": "e6lfu5kktkjhzou72epnwl13p",
		"assistPlayerName": "H. Glas"
	}],
	"sponsor": null,
	"date": "2021-07-21T08:30:00",
	"time": "08:30:00Z",
	"cancelled": false,
	"lengthMin": 96,
	"lengthSec": 18,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "4ht31nxzpcizr1c3e8nal1o1x",
		"longName": "Ajinomoto Stadium",
		"shortName": "Ajinomoto Stadium",
		"location": "Chōfu",
		"country": "Japan"
	},
	"attendance": 0,
	"competition": {
		"id": "cjmdp7ds2hanz0r7kdifi0tsl",
		"name": "Olympics Women",
		"code": "OLW",
		"format": "International cup",
		"startDate": "2021-07-21T00:00:00Z",
		"endDate": "2021-08-06T00:00:00Z",
		"isFriendly": false
	},
	"periods": [{
		"id": 1,
		"start": "2021-07-21T08:30:31Z",
		"end": "2021-07-21T09:17:39Z",
		"lengthMin": "47",
		"lengthSec": "8",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2021-07-21T09:33:48Z",
		"end": "2021-07-21T10:22:58Z",
		"lengthMin": "49",
		"lengthSec": "10",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "52rvkys9wse9ip8a181ik4697",
		"name": "Sweden",
		"officialName": "Sweden",
		"shortName": "Sweden",
		"code": "SWE",
		"position": "home",
		"score": 3,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "e70zl10x0ayu7y10ry0wi465a",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "away",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/tokyo-olympics-2020/matches/sweden-vs-united-states",
	"sitecoreData": {
		"link1": {"value": {"href": ""}, "editable": null},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {"value": {"href": ""}, "editable": null},
		"matchRecapLink": {
			"value": {
				"href": "/stories/2021/07/2020-tokyo-olympics-uswnt-0-vs-sweden-3-match-report-stats-and-group-standings",
				"querystring": "",
				"target": "",
				"linktype": "internal",
				"id": "{C10C5CB2-08D1-42EA-98E1-9D9DC1F8F00C}",
				"url": "/USSF/Home/Stories/2021/07/2020 Tokyo Olympics uswnt 0 vs Sweden 3 Match Report Stats and group Standings"
			}, "editable": null
		},
		"ticketsLink": {"value": {"href": ""}, "editable": null},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/wnt/match-backgrounds/backgroundblue.ashx?h=1200&la=en-US&w=3840&rev=5f5bb9ef0ba345c1b9b013598f0e6758&hash=3107C04CB54A143F8EDCF691228D3EEA",
				"alt": "Blue Chevron Background Pattern",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/matchcarddefault.ashx?h=720&la=en-US&w=670&rev=2cfa098f22514cb582a559acc92fa48a&hash=1FC8EC20CB4F56C8C888C0C13B25D8D3",
				"alt": "",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/tokyo-olympics-2020"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/wnt/carli-lloyd/lloyd-playernameboxingposterleft.ashx?h=1160&la=en-US&w=880&rev=f33a35da2ba34fb894542a5647416db8&hash=C2703EC4B7E9A144EFB059BB3083C246",
				"alt": "Carli Lloyd",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/wnt/megan-rapinoe/rapinoe-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=e38d9c35f77241b6a6a17e138d4e189b&hash=4C3D41F39198CEC528372C4219F4E7C2",
				"alt": "Megan Rapinoe",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "Tokyo Stadium", "editable": null},
		"stadiumLocation": {"value": "Tokyo", "editable": null},
		"stadiumCountry": {"value": "Japan", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/usa-network-logo.ashx?h=140&la=en-US&w=279&rev=0eae4cd27c684bccb65981ffde0f45ca&hash=AD041DBB4CF0BC8A287504B2C0BDF93B",
					"alt": "USA",
					"width": "279",
					"height": "140"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.usanetwork.com/live", "editable": null},
			"iosUrl": {"value": "https://www.usanetwork.com/live", "editable": null},
			"androidUrl": {"value": "https://www.usanetwork.com/live", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/telomundo-app.ashx?h=158&la=en-US&w=332&rev=5480ce7e8b68482a810623b71e1b0cda&hash=1956C309818FE62CAC84CAD4545267B0",
					"alt": "Telemundo + Telemundo Deportes App",
					"width": "332",
					"height": "158"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.telemundodeportes.com/", "editable": null},
			"iosUrl": {"value": "https://www.telemundodeportes.com/", "editable": null},
			"androidUrl": {"value": "https://www.telemundodeportes.com/", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/nbc-olympics-logo.ashx?h=166&la=en-US&w=310&rev=6c77e01516d1418f8d65738503d44d3c&hash=872D3B363317DCC64DE8BBCD38ADA90C",
					"alt": "NBCOlympics.com",
					"width": "310",
					"height": "166"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.nbcolympics.com/", "editable": null},
			"iosUrl": {"value": "https://www.nbcolympics.com/", "editable": null},
			"androidUrl": {"value": "https://www.nbcolympics.com/", "editable": null}
		}],
		"currentItemId": "a5776618-7787-418a-8939-5c69b1282672"
	},
	"dateTime": "2021-07-21T08:30:00Z",
	"dateTimeObject": "2021-07-21T08:30:00+00:00"
}, {
	"matchId": "50emj6384b7dlbqpusnmcc7pw",
	"description": "United States vs Mexico",
	"tournamentCalendarId": "ag87g3nigmrjyjowr5zz2ymtw",
	"tournamentCalendarName": "2021",
	"stageName": "Friendlies 1",
	"status": 0,
	"goals": [{
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 1,
		"timeMin": 6,
		"goalType": 0,
		"scorerId": "6ywkny98b9qzuvhqxwn9l0ogl",
		"scorerName": "L. Horan",
		"assistPlayerId": "exfa2ygjuuy08v50w65ddj2l1",
		"assistPlayerName": "C. Lloyd"
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 1,
		"timeMin": 11,
		"goalType": 0,
		"scorerId": "exfa2ygjuuy08v50w65ddj2l1",
		"scorerName": "C. Lloyd",
		"assistPlayerId": "4b13osk9pzrosvzqt55y7o34l",
		"assistPlayerName": "C. Dunn"
	}, {
		"contestantId": "55bze92ms0rb7jwno3jx0ldwo",
		"periodId": 1,
		"timeMin": 37,
		"goalType": 2,
		"scorerId": "615vz7uoe3eiozddbe206io6h",
		"scorerName": "R. Reyes",
		"assistPlayerId": null,
		"assistPlayerName": null
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 1,
		"timeMin": 39,
		"goalType": 0,
		"scorerId": "cxl4bxs35ifb8o5cq9jxi1ec5",
		"scorerName": "T. Heath",
		"assistPlayerId": "4tcmt910n2lj88n5m0aqoddcl",
		"assistPlayerName": "S. Mewis"
	}],
	"sponsor": null,
	"date": "2021-07-05T21:00:00",
	"time": "21:00:00Z",
	"cancelled": false,
	"lengthMin": 90,
	"lengthSec": 7,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "bvr7bbqs2rv1793ms8l449wpm",
		"longName": "Pratt & Whitney Stadium at Rentschler Field",
		"shortName": "Pratt & Whitney Stadium at Rentschler Field",
		"location": "East Hartford, Connecticut",
		"country": "USA"
	},
	"attendance": 0,
	"competition": {
		"id": "dvtl8sf1262pd2aqgu641qa7u",
		"name": "International Friendly",
		"code": "FRW",
		"format": "International cup",
		"startDate": "2021-01-01T00:00:00Z",
		"endDate": "2021-12-31T00:00:00Z",
		"isFriendly": true
	},
	"periods": [{
		"id": 1,
		"start": "2021-07-05T21:09:40Z",
		"end": "2021-07-05T21:54:41Z",
		"lengthMin": "45",
		"lengthSec": "1",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2021-07-05T22:11:15Z",
		"end": "2021-07-05T22:56:21Z",
		"lengthMin": "45",
		"lengthSec": "6",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "e70zl10x0ayu7y10ry0wi465a",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "home",
		"score": 4,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "55bze92ms0rb7jwno3jx0ldwo",
		"name": "Mexico",
		"officialName": "Mexico",
		"shortName": "Mexico",
		"code": "MEX",
		"position": "away",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/uswnt-friendlies-2021/matches/united-states-vs-mexico-7-5-2021",
	"sitecoreData": {
		"link1": {"value": {"href": ""}, "editable": null},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {"value": {"href": ""}, "editable": null},
		"matchRecapLink": {
			"value": {
				"href": "/stories/2021/07/2021-send-off-series-uswnt-vs-mexico-july-5th-lineup-starting-xi-and-tv-channels",
				"querystring": "",
				"target": "",
				"linktype": "internal",
				"id": "{70CC3B33-9AAC-46DB-98CB-98AE09F1C535}",
				"url": "/USSF/Home/Stories/2021/07/2021 Send Off Series USWNT vs Mexico July 5th Lineup starting XI and TV Channels"
			}, "editable": null
		},
		"ticketsLink": {
			"value": {
				"href": "https://xlcenter.evenue.net/cgi-bin/ncommerce3/SEGetEventInfo?ticketCode=GS:HARTFORD:RF2021:RF0705:&linkID=global-hartford&RSRC=WEB_PR&RDAT=WNT",
				"linktype": "external",
				"url": "https://xlcenter.evenue.net/cgi-bin/ncommerce3/SEGetEventInfo?ticketCode=GS:HARTFORD:RF2021:RF0705:&linkID=global-hartford&RSRC=WEB_PR&RDAT=WNT",
				"anchor": "",
				"target": "_blank"
			}, "editable": null
		},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/wwcfrance2019backgroundp1.ashx?h=1200&la=en-US&w=3840&rev=f45ce10739234f589f36d39aca7e9510&hash=A256648079CC6A02F8070F882ED0DB53",
				"alt": "",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/wwcfrance2019backgroundcard.ashx?h=720&la=en-US&w=670&rev=751c0234ecbd48bfa5f60b6104526e60&hash=62233C6BBFA006927A82260DC14A3EFB",
				"alt": "WWC Background",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/uswnt-friendlies-2021"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/player-boxing-images/wnt/white/sauerbrunn-white-playernameboxingposterleft.ashx?h=1160&la=en-US&w=880&rev=a32ee89bbbef4d2b9f3087750fc95677&hash=10C133EF7F08BA9CC7EE1E8EE9AAF821",
				"alt": "BECKY SAUERBRUNN",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/player-boxing-images/wnt/white/lloyd-white-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=a17fbe502d384a2f8889cc77cb489482&hash=F318E3466D3FE7E2D4128D0B98230A36",
				"alt": "Carli Lloyd",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "", "editable": null},
		"stadiumLocation": {"value": "", "editable": null},
		"stadiumCountry": {"value": "", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogoespnblack2x.ashx?h=52&la=en-US&w=120&rev=e6ec6948535648d79104664ada0ef697&hash=3457DC4C562E33004039F6E0D5F7E94B",
					"alt": "ESPN",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.espn.com/watch/", "editable": null},
			"iosUrl": {"value": "https://www.espn.com/watch/", "editable": null},
			"androidUrl": {"value": "https://www.espn.com/watch/", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tund-app.ashx?h=178&la=en-US&w=211&rev=1bf7c6f43f13410fba68929350407bc5&hash=5721A8CE442B14984B6C60E9D45D63FB",
					"alt": "TUDN App",
					"width": "211",
					"height": "178"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.tudn.com/aplicacion-de-deportes", "editable": null},
			"iosUrl": {"value": "https://apps.apple.com/us/app/tudn-tu-deportes-network/id353665650", "editable": null},
			"androidUrl": {
				"value": "https://play.google.com/store/apps/details?id=com.july.univision&hl=en_US&gl=US",
				"editable": null
			}
		}, {
			"broadcasterLogo": {"value": {}, "editable": null},
			"webUrl": {"value": "tudn.tv", "editable": null},
			"iosUrl": {"value": "tudn.tv", "editable": null},
			"androidUrl": {"value": "tudn.tv", "editable": null}
		}],
		"currentItemId": "bc9d7422-1a36-4ca4-a173-214ae26b1544"
	},
	"dateTime": "2021-07-05T21:00:00Z",
	"dateTimeObject": "2021-07-05T21:00:00+00:00"
}, {
	"matchId": "50a42iwkwp8z52tjhl99s6fx0",
	"description": "United States vs Mexico",
	"tournamentCalendarId": "ag87g3nigmrjyjowr5zz2ymtw",
	"tournamentCalendarName": "2021",
	"stageName": "Friendlies 1",
	"status": 0,
	"goals": [{
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 1,
		"timeMin": 21,
		"goalType": 0,
		"scorerId": "4tcmt910n2lj88n5m0aqoddcl",
		"scorerName": "S. Mewis",
		"assistPlayerId": "4t91su0ns46r00vjynp0on5qt",
		"assistPlayerName": "K. Mewis"
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 1,
		"timeMin": 39,
		"goalType": 0,
		"scorerId": "bnxnrws4yintb6s76erzl9xw5",
		"scorerName": "C. Press",
		"assistPlayerId": "29vjl1iba9rz14yd1g9qi4pn9",
		"assistPlayerName": "M. Rapinoe"
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 2,
		"timeMin": 74,
		"goalType": 0,
		"scorerId": "cxl4bxs35ifb8o5cq9jxi1ec5",
		"scorerName": "T. Heath",
		"assistPlayerId": "l28lqh3f3ojx6kk2wvvq6nyt",
		"assistPlayerName": "A. Morgan"
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 2,
		"timeMin": 85,
		"goalType": 0,
		"scorerId": "bnxnrws4yintb6s76erzl9xw5",
		"scorerName": "C. Press",
		"assistPlayerId": "6bbodw3m9bstocysxvzfuo3my",
		"assistPlayerName": "T. Davidson"
	}],
	"sponsor": null,
	"date": "2021-07-01T23:30:00",
	"time": "23:30:00Z",
	"cancelled": false,
	"lengthMin": 90,
	"lengthSec": 26,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "bvr7bbqs2rv1793ms8l449wpm",
		"longName": "Pratt & Whitney Stadium at Rentschler Field",
		"shortName": "Pratt & Whitney Stadium at Rentschler Field",
		"location": "East Hartford, Connecticut",
		"country": "USA"
	},
	"attendance": 0,
	"competition": {
		"id": "dvtl8sf1262pd2aqgu641qa7u",
		"name": "International Friendly",
		"code": "FRW",
		"format": "International cup",
		"startDate": "2021-01-01T00:00:00Z",
		"endDate": "2021-12-31T00:00:00Z",
		"isFriendly": true
	},
	"periods": [{
		"id": 1,
		"start": "2021-07-01T23:38:29Z",
		"end": "2021-07-02T00:23:50Z",
		"lengthMin": "45",
		"lengthSec": "21",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2021-07-02T00:39:25Z",
		"end": "2021-07-02T01:24:30Z",
		"lengthMin": "45",
		"lengthSec": "5",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "e70zl10x0ayu7y10ry0wi465a",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "home",
		"score": 4,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "55bze92ms0rb7jwno3jx0ldwo",
		"name": "Mexico",
		"officialName": "Mexico",
		"shortName": "Mexico",
		"code": "MEX",
		"position": "away",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/uswnt-friendlies-2021/matches/united-states-vs-mexico-7-1-2021",
	"sitecoreData": {
		"link1": {
			"value": {
				"href": "https://www.foxsports.com/live/fs1",
				"text": "FS1",
				"linktype": "external",
				"url": "https://www.foxsports.com/live/fs1",
				"anchor": "",
				"title": "FS1",
				"target": "_blank"
			}, "editable": null
		},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {
			"value": {
				"href": "https://www.ussoccer.com/stories/2021/06/send-off-series-uswnt-vs-mexico-july-1st-preview-schedule-tv-channels-and-start-time",
				"linktype": "external",
				"url": "https://www.ussoccer.com/stories/2021/06/send-off-series-uswnt-vs-mexico-july-1st-preview-schedule-tv-channels-and-start-time",
				"anchor": "",
				"target": ""
			}, "editable": null
		},
		"matchRecapLink": {
			"value": {
				"href": "/stories/2021/07/send-off-series-uswnt-4-mexico-0-july-1st-match-report-stats",
				"querystring": "",
				"target": "",
				"linktype": "internal",
				"id": "{7A4CFD20-7DB5-4F77-861D-ECF73862F82C}",
				"url": "/USSF/Home/Stories/2021/07/Send Off Series USWNT 4 Mexico 0 July 1st Match Report Stats"
			}, "editable": null
		},
		"ticketsLink": {
			"value": {
				"href": "https://xlcenter.evenue.net/cgi-bin/ncommerce3/SEGetEventInfo?ticketCode=GS:HARTFORD:RF2021:RF0701:&linkID=global-hartford&RSRC=WEB_PR&RDAT=WNT",
				"linktype": "external",
				"url": "https://xlcenter.evenue.net/cgi-bin/ncommerce3/SEGetEventInfo?ticketCode=GS:HARTFORD:RF2021:RF0701:&linkID=global-hartford&RSRC=WEB_PR&RDAT=WNT",
				"anchor": "",
				"target": "_blank"
			}, "editable": null
		},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/wwcfrance2019backgroundp1.ashx?h=1200&la=en-US&w=3840&rev=f45ce10739234f589f36d39aca7e9510&hash=A256648079CC6A02F8070F882ED0DB53",
				"alt": "",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/wwcfrance2019backgroundcard.ashx?h=720&la=en-US&w=670&rev=751c0234ecbd48bfa5f60b6104526e60&hash=62233C6BBFA006927A82260DC14A3EFB",
				"alt": "WWC Background",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/uswnt-friendlies-2021"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/player-boxing-images/wnt/white/sauerbrunn-white-playernameboxingposterleft.ashx?h=1160&la=en-US&w=880&rev=a32ee89bbbef4d2b9f3087750fc95677&hash=10C133EF7F08BA9CC7EE1E8EE9AAF821",
				"alt": "BECKY SAUERBRUNN",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/player-boxing-images/wnt/white/lloyd-white-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=a17fbe502d384a2f8889cc77cb489482&hash=F318E3466D3FE7E2D4128D0B98230A36",
				"alt": "Carli Lloyd",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "", "editable": null},
		"stadiumLocation": {"value": "", "editable": null},
		"stadiumCountry": {"value": "", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogofs1black2x.ashx?h=52&la=en-US&w=120&rev=79d1fc98d79c4aa582431482dc9188aa&hash=222581B287E6DC17C26116C6B3C28131",
					"alt": "FS1",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.foxsports.com/live/fs1", "editable": null},
			"iosUrl": {"value": "https://www.foxsports.com/mobile", "editable": null},
			"androidUrl": {"value": "https://www.foxsports.com/mobile", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogounimasblack2x.ashx?h=52&la=en-US&w=120&rev=f13300dc0e3e48ae9888634da0ce427a&hash=C91D2E6B043C1E965DAB151D3EA3B031",
					"alt": "UniMÁS",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.univisionnow.com/channels", "editable": null},
			"iosUrl": {"value": "https://www.univisionnow.com/channels", "editable": null},
			"androidUrl": {
				"value": "https://www.univisionnow.com/channelshttps://www.univisionnow.com/channels",
				"editable": null
			}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tundlogo.ashx?h=52&la=en-US&w=120&rev=9cc1f63328c54407a1efc533b869bdc5&hash=8A3D704527709F5BB4D4149320DFB4DC",
					"alt": "TUDN",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.tudn.com/", "editable": null},
			"iosUrl": {"value": "https://www.tudn.com/", "editable": null},
			"androidUrl": {"value": "https://www.tudn.com/", "editable": null}
		}],
		"currentItemId": "9b15bcd8-442c-4deb-99ec-7d62bd308481"
	},
	"dateTime": "2021-07-01T23:30:00Z",
	"dateTimeObject": "2021-07-01T23:30:00+00:00"
}, {
	"matchId": "aby09i1itwxxrrsqyj1hc251w",
	"description": "United States vs Nigeria",
	"tournamentCalendarId": "ag87g3nigmrjyjowr5zz2ymtw",
	"tournamentCalendarName": "2021",
	"stageName": "Friendlies 1",
	"status": 0,
	"goals": [{
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 1,
		"timeMin": 47,
		"goalType": 0,
		"scorerId": "bnxnrws4yintb6s76erzl9xw5",
		"scorerName": "C. Press",
		"assistPlayerId": "exfa2ygjuuy08v50w65ddj2l1",
		"assistPlayerName": "C. Lloyd"
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 2,
		"timeMin": 94,
		"goalType": 0,
		"scorerId": "cm6fzm8v38ti0aubu0q4v717t",
		"scorerName": "L. Williams",
		"assistPlayerId": "bnxnrws4yintb6s76erzl9xw5",
		"assistPlayerName": "C. Press"
	}],
	"sponsor": null,
	"date": "2021-06-17T01:00:00",
	"time": "01:00:00Z",
	"cancelled": false,
	"lengthMin": 98,
	"lengthSec": 38,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "5ylpm7oz3c8tplmcb4nisv6dw",
		"longName": "Q2 Stadium",
		"shortName": "Q2 Stadium",
		"location": "Austin, Texas",
		"country": "USA"
	},
	"attendance": 0,
	"competition": {
		"id": "dvtl8sf1262pd2aqgu641qa7u",
		"name": "International Friendly",
		"code": "FRW",
		"format": "International cup",
		"startDate": "2021-01-01T00:00:00Z",
		"endDate": "2021-12-31T00:00:00Z",
		"isFriendly": true
	},
	"periods": [{
		"id": 1,
		"start": "2021-06-17T01:08:29Z",
		"end": "2021-06-17T01:57:36Z",
		"lengthMin": "49",
		"lengthSec": "7",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2021-06-17T02:13:15Z",
		"end": "2021-06-17T03:02:46Z",
		"lengthMin": "49",
		"lengthSec": "31",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "e70zl10x0ayu7y10ry0wi465a",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "home",
		"score": 2,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "ddhjicq33fc8mfavld34x1iva",
		"name": "Nigeria",
		"officialName": "Nigeria",
		"shortName": "Nigeria",
		"code": "NGA",
		"position": "away",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/uswnt-friendlies-2021/matches/united-states-vs-nigeria-6-16-2021",
	"sitecoreData": {
		"link1": {
			"value": {
				"href": "https://www.espn.com/watch/?type=live&channel=espn2",
				"text": "ESPN2",
				"linktype": "external",
				"url": "https://www.espn.com/watch/?type=live&channel=espn2",
				"anchor": "",
				"title": "ESPN2",
				"target": "_blank"
			}, "editable": null
		},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {
			"value": {
				"href": "https://www.ussoccer.com/stories/2021/06/summer-series-uswnt-vs-nigeria-preview-schedule-tv-channels-start-time",
				"linktype": "external",
				"url": "https://www.ussoccer.com/stories/2021/06/summer-series-uswnt-vs-nigeria-preview-schedule-tv-channels-start-time",
				"anchor": "",
				"target": ""
			}, "editable": null
		},
		"matchRecapLink": {"value": {"href": ""}, "editable": null},
		"ticketsLink": {
			"value": {
				"href": "https://www.ticketmaster.com/event/3A005AB1BF1D3117",
				"linktype": "external",
				"url": "https://www.ticketmaster.com/event/3A005AB1BF1D3117",
				"anchor": "",
				"target": "_blank"
			}, "editable": null
		},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/wwcfrance2019backgroundp1.ashx?h=1200&la=en-US&w=3840&rev=f45ce10739234f589f36d39aca7e9510&hash=A256648079CC6A02F8070F882ED0DB53",
				"alt": "",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/wwcfrance2019backgroundcard.ashx?h=720&la=en-US&w=670&rev=751c0234ecbd48bfa5f60b6104526e60&hash=62233C6BBFA006927A82260DC14A3EFB",
				"alt": "WWC Background",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/uswnt-friendlies-2021"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/player-boxing-images/wnt/white/sauerbrunn-white-playernameboxingposterleft.ashx?h=1160&la=en-US&w=880&rev=a32ee89bbbef4d2b9f3087750fc95677&hash=10C133EF7F08BA9CC7EE1E8EE9AAF821",
				"alt": "BECKY SAUERBRUNN",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/player-boxing-images/wnt/white/lloyd-white-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=a17fbe502d384a2f8889cc77cb489482&hash=F318E3466D3FE7E2D4128D0B98230A36",
				"alt": "Carli Lloyd",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "Presented by AT&T 5G", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "", "editable": null},
		"stadiumLocation": {"value": "", "editable": null},
		"stadiumCountry": {"value": "", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/app/broadcasters/espn2_logo.ashx?h=52&la=en-US&w=120&rev=8b583676054f469c997298102fc43568&hash=495D7043006AA51910F46539C08D4624",
					"alt": "ESPN2",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.espn.com/watch/?type=live&channel=espn2", "editable": null},
			"iosUrl": {"value": "https://apps.apple.com/us/app/espn-scorecenter/id317469184", "editable": null},
			"androidUrl": {"value": "https://play.google.com/store/apps/details?id=com.espn.score_center", "editable": null}
		}],
		"currentItemId": "c0266fff-1e54-4647-837a-a47691c65297"
	},
	"dateTime": "2021-06-17T01:00:00Z",
	"dateTimeObject": "2021-06-17T01:00:00+00:00"
}, {
	"matchId": "6qb84oys2uwn38sj8za29ycr8",
	"description": "United States vs Jamaica",
	"tournamentCalendarId": "ag87g3nigmrjyjowr5zz2ymtw",
	"tournamentCalendarName": "2021",
	"stageName": "Friendlies 1",
	"status": 0,
	"goals": [{
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 1,
		"timeMin": 1,
		"goalType": 0,
		"scorerId": "exfa2ygjuuy08v50w65ddj2l1",
		"scorerName": "C. Lloyd",
		"assistPlayerId": "4j3kopvvd3iej6p4h9z36giqd",
		"assistPlayerName": "R. Lavelle"
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 1,
		"timeMin": 7,
		"goalType": 1,
		"scorerId": "6ywkny98b9qzuvhqxwn9l0ogl",
		"scorerName": "L. Horan",
		"assistPlayerId": null,
		"assistPlayerName": null
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 1,
		"timeMin": 22,
		"goalType": 0,
		"scorerId": "56y5qt6c03t7f5t7eoebi0wyd",
		"scorerName": "M. Williams-Purce",
		"assistPlayerId": "bnxnrws4yintb6s76erzl9xw5",
		"assistPlayerName": "C. Press"
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 2,
		"timeMin": 91,
		"goalType": 0,
		"scorerId": "l28lqh3f3ojx6kk2wvvq6nyt",
		"scorerName": "A. Morgan",
		"assistPlayerId": "4tcmt910n2lj88n5m0aqoddcl",
		"assistPlayerName": "S. Mewis"
	}],
	"sponsor": null,
	"date": "2021-06-14T02:00:00",
	"time": "02:00:00Z",
	"cancelled": false,
	"lengthMin": 98,
	"lengthSec": 6,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "8whv5an4m1lrhqng92x3m4l3f",
		"longName": "PNC Stadium",
		"shortName": "PNC Stadium",
		"location": "Houston, Texas",
		"country": "USA"
	},
	"attendance": 0,
	"competition": {
		"id": "dvtl8sf1262pd2aqgu641qa7u",
		"name": "International Friendly",
		"code": "FRW",
		"format": "International cup",
		"startDate": "2021-01-01T00:00:00Z",
		"endDate": "2021-12-31T00:00:00Z",
		"isFriendly": true
	},
	"periods": [{
		"id": 1,
		"start": "2021-06-14T02:15:38Z",
		"end": "2021-06-14T03:05:29Z",
		"lengthMin": "49",
		"lengthSec": "51",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2021-06-14T03:21:38Z",
		"end": "2021-06-14T04:09:53Z",
		"lengthMin": "48",
		"lengthSec": "15",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "e70zl10x0ayu7y10ry0wi465a",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "home",
		"score": 4,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "6a6z9hvqktu4ug8p15kdbov7x",
		"name": "Jamaica",
		"officialName": "Jamaica",
		"shortName": "Jamaica",
		"code": "JAM",
		"position": "away",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/uswnt-friendlies-2021/matches/united-states-vs-jamaica-6-13-2021",
	"sitecoreData": {
		"link1": {
			"value": {
				"href": "https://www.foxsports.com/live/fs1",
				"text": "FS1",
				"linktype": "external",
				"url": "https://www.foxsports.com/live/fs1",
				"anchor": "",
				"title": "FS1",
				"target": "_blank"
			}, "editable": null
		},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {"value": {"href": ""}, "editable": null},
		"matchRecapLink": {"value": {"href": ""}, "editable": null},
		"ticketsLink": {
			"value": {
				"href": "https://www.ussoccer.com/competitions/uswnt-friendlies-2021/matches/united-states-vs-jamaica-6-13-2021#ticket-info",
				"linktype": "external",
				"url": "https://www.ussoccer.com/competitions/uswnt-friendlies-2021/matches/united-states-vs-jamaica-6-13-2021#ticket-info",
				"anchor": "",
				"target": ""
			}, "editable": null
		},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/wwcfrance2019backgroundp1.ashx?h=1200&la=en-US&w=3840&rev=f45ce10739234f589f36d39aca7e9510&hash=A256648079CC6A02F8070F882ED0DB53",
				"alt": "",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/wwcfrance2019backgroundcard.ashx?h=720&la=en-US&w=670&rev=751c0234ecbd48bfa5f60b6104526e60&hash=62233C6BBFA006927A82260DC14A3EFB",
				"alt": "WWC Background",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/uswnt-friendlies-2021"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/player-boxing-images/wnt/blue/rapinoe-blue-playernameboxingposterleft.ashx?h=1160&la=en-US&w=880&rev=0be9c39141444ce38f62e6d64acd2dd1&hash=A596ADF3C27D289A3E2B8D36119D54AC",
				"alt": "Megan Rapinoe",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/player-boxing-images/wnt/blue/dunn-blue-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=a1dfb9a01eaa4b10a435d8fbef226bc8&hash=5DF77F888E6A6B90C53E13EAB6C7D2B6",
				"alt": "Crystal Dunn",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "Presented by AT&T 5G", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "", "editable": null},
		"stadiumLocation": {"value": "", "editable": null},
		"stadiumCountry": {"value": "", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogofs1black2x.ashx?h=52&la=en-US&w=120&rev=79d1fc98d79c4aa582431482dc9188aa&hash=222581B287E6DC17C26116C6B3C28131",
					"alt": "FS1",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.foxsports.com/live/fs1", "editable": null},
			"iosUrl": {"value": "https://www.foxsports.com/mobile", "editable": null},
			"androidUrl": {"value": "https://www.foxsports.com/mobile", "editable": null}
		}],
		"currentItemId": "c8a2d579-129f-4172-84fd-8ad35e6f80ca"
	},
	"dateTime": "2021-06-14T02:00:00Z",
	"dateTimeObject": "2021-06-14T02:00:00+00:00"
}, {
	"matchId": "3bzfbhl8gvtta2s6i0ji7vthw",
	"description": "United States vs Portugal",
	"tournamentCalendarId": "ag87g3nigmrjyjowr5zz2ymtw",
	"tournamentCalendarName": "2021",
	"stageName": "Friendlies 1",
	"status": 0,
	"goals": [{
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 2,
		"timeMin": 76,
		"goalType": 0,
		"scorerId": "4tcmt910n2lj88n5m0aqoddcl",
		"scorerName": "S. Mewis",
		"assistPlayerId": "bnxnrws4yintb6s76erzl9xw5",
		"assistPlayerName": "C. Press"
	}],
	"sponsor": null,
	"date": "2021-06-11T00:30:00",
	"time": "00:30:00Z",
	"cancelled": false,
	"lengthMin": 97,
	"lengthSec": 21,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "8whv5an4m1lrhqng92x3m4l3f",
		"longName": "PNC Stadium",
		"shortName": "PNC Stadium",
		"location": "Houston, Texas",
		"country": "USA"
	},
	"attendance": 0,
	"competition": {
		"id": "dvtl8sf1262pd2aqgu641qa7u",
		"name": "International Friendly",
		"code": "FRW",
		"format": "International cup",
		"startDate": "2021-01-01T00:00:00Z",
		"endDate": "2021-12-31T00:00:00Z",
		"isFriendly": true
	},
	"periods": [{
		"id": 1,
		"start": "2021-06-11T00:38:32Z",
		"end": "2021-06-11T01:26:43Z",
		"lengthMin": "48",
		"lengthSec": "11",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2021-06-11T01:43:18Z",
		"end": "2021-06-11T02:32:28Z",
		"lengthMin": "49",
		"lengthSec": "10",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "e70zl10x0ayu7y10ry0wi465a",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "home",
		"score": 1,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "27z7xb0913v9ktnlazdhlspja",
		"name": "Portugal",
		"officialName": "Portugal",
		"shortName": "Portugal",
		"code": "POR",
		"position": "away",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/uswnt-friendlies-2021/matches/united-states-vs-portgual-6-10-2021",
	"sitecoreData": {
		"link1": {
			"value": {
				"href": "https://www.foxsports.com/live/fs1",
				"text": "FS1",
				"linktype": "external",
				"url": "https://www.foxsports.com/live/fs1",
				"anchor": "",
				"title": "FS1",
				"target": "_blank"
			}, "editable": null
		},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {"value": {"href": ""}, "editable": null},
		"matchRecapLink": {
			"value": {
				"href": "/stories/2021/06/summer-series-uswnt-1-vs-portugal-0-match-report-stats",
				"querystring": "",
				"target": "",
				"linktype": "internal",
				"id": "{83EE2DA4-C055-46EC-9885-3050877B7608}",
				"url": "/USSF/Home/Stories/2021/06/Summer Series uswnt 1 vs Portugal 0 Match Report Stats"
			}, "editable": null
		},
		"ticketsLink": {
			"value": {
				"href": "https://www.ussoccer.com/competitions/uswnt-friendlies-2021/matches/united-states-vs-portgual-6-10-2021#ticket-info",
				"linktype": "external",
				"url": "https://www.ussoccer.com/competitions/uswnt-friendlies-2021/matches/united-states-vs-portgual-6-10-2021#ticket-info",
				"anchor": "",
				"target": ""
			}, "editable": null
		},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/wwcfrance2019backgroundp1.ashx?h=1200&la=en-US&w=3840&rev=f45ce10739234f589f36d39aca7e9510&hash=A256648079CC6A02F8070F882ED0DB53",
				"alt": "",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/wwcfrance2019backgroundcard.ashx?h=720&la=en-US&w=670&rev=751c0234ecbd48bfa5f60b6104526e60&hash=62233C6BBFA006927A82260DC14A3EFB",
				"alt": "WWC Background",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/uswnt-friendlies-2021"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/player-boxing-images/wnt/white/sauerbrunn-white-playernameboxingposterleft.ashx?h=1160&la=en-US&w=880&rev=a32ee89bbbef4d2b9f3087750fc95677&hash=10C133EF7F08BA9CC7EE1E8EE9AAF821",
				"alt": "BECKY SAUERBRUNN",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/player-boxing-images/wnt/white/lloyd-white-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=a17fbe502d384a2f8889cc77cb489482&hash=F318E3466D3FE7E2D4128D0B98230A36",
				"alt": "Carli Lloyd",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "Presented by AT&T 5G", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "", "editable": null},
		"stadiumLocation": {"value": "", "editable": null},
		"stadiumCountry": {"value": "", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogofs1black2x.ashx?h=52&la=en-US&w=120&rev=79d1fc98d79c4aa582431482dc9188aa&hash=222581B287E6DC17C26116C6B3C28131",
					"alt": "FS1",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.foxsports.com/live/fs1", "editable": null},
			"iosUrl": {"value": "https://www.foxsports.com/mobile", "editable": null},
			"androidUrl": {"value": "https://www.foxsports.com/mobile", "editable": null}
		}],
		"currentItemId": "854e4ef6-71a0-4881-9da4-3d4d374d3780"
	},
	"dateTime": "2021-06-11T00:30:00Z",
	"dateTimeObject": "2021-06-11T00:30:00+00:00"
}, {
	"matchId": "dnytppcwxeb8ovvj9uq35zxn8",
	"description": "France vs United States",
	"tournamentCalendarId": "ag87g3nigmrjyjowr5zz2ymtw",
	"tournamentCalendarName": "2021",
	"stageName": "Friendlies 1",
	"status": 0,
	"goals": [{
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 1,
		"timeMin": 5,
		"goalType": 1,
		"scorerId": "29vjl1iba9rz14yd1g9qi4pn9",
		"scorerName": "M. Rapinoe",
		"assistPlayerId": null,
		"assistPlayerName": null
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 1,
		"timeMin": 19,
		"goalType": 0,
		"scorerId": "l28lqh3f3ojx6kk2wvvq6nyt",
		"scorerName": "A. Morgan",
		"assistPlayerId": "bnxnrws4yintb6s76erzl9xw5",
		"assistPlayerName": "C. Press"
	}],
	"sponsor": null,
	"date": "2021-04-13T19:10:00",
	"time": "19:10:00Z",
	"cancelled": false,
	"lengthMin": 93,
	"lengthSec": 6,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "1dwt8yu0bybehs78rvjoflmij",
		"longName": "Stade Océane",
		"shortName": "Stade Océane",
		"location": "Le Havre",
		"country": "France"
	},
	"attendance": 0,
	"competition": {
		"id": "dvtl8sf1262pd2aqgu641qa7u",
		"name": "International Friendly",
		"code": "FRW",
		"format": "International cup",
		"startDate": "2021-01-01T00:00:00Z",
		"endDate": "2021-12-31T00:00:00Z",
		"isFriendly": true
	},
	"periods": [{
		"id": 1,
		"start": "2021-04-13T19:10:49Z",
		"end": "2021-04-13T19:55:51Z",
		"lengthMin": "45",
		"lengthSec": "2",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2021-04-13T20:11:50Z",
		"end": "2021-04-13T20:59:54Z",
		"lengthMin": "48",
		"lengthSec": "4",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "185xqd7s06sm2t85sxzfkrl",
		"name": "France",
		"officialName": "France",
		"shortName": "France",
		"code": "FRA",
		"position": "home",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "e70zl10x0ayu7y10ry0wi465a",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "away",
		"score": 2,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/uswnt-friendlies-2021/matches/france-vs-united-states-4-13-2021",
	"sitecoreData": {
		"link1": {
			"value": {
				"href": "https://www.espn.com/watch/?type=live&channel=espn2",
				"text": "ESPN2",
				"linktype": "external",
				"url": "https://www.espn.com/watch/?type=live&channel=espn2",
				"anchor": "",
				"title": "ESPN2",
				"target": "_blank"
			}, "editable": null
		},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {
			"value": {
				"href": "/stories/2021/04/international-friendly-uswnt-vs-france-preview-schedule-tv-channels-start-time",
				"querystring": "",
				"target": "",
				"linktype": "internal",
				"id": "{1E88AFEF-89B6-4812-B720-BC5DE23E8C4D}",
				"url": "/USSF/Home/Stories/2021/04/International Friendly USWNT vs France Preview Schedule TV Channels Start Time"
			}, "editable": null
		},
		"matchRecapLink": {"value": {"href": ""}, "editable": null},
		"ticketsLink": {"value": {"href": ""}, "editable": null},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/wwcfrance2019backgroundp1.ashx?h=1200&la=en-US&w=3840&rev=f45ce10739234f589f36d39aca7e9510&hash=A256648079CC6A02F8070F882ED0DB53",
				"alt": "",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/wwcfrance2019backgroundcard.ashx?h=720&la=en-US&w=670&rev=751c0234ecbd48bfa5f60b6104526e60&hash=62233C6BBFA006927A82260DC14A3EFB",
				"alt": "WWC Background",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/uswnt-friendlies-2021"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/player-boxing-images/wnt/white/sauerbrunn-white-playernameboxingposterleft.ashx?h=1160&la=en-US&w=880&rev=a32ee89bbbef4d2b9f3087750fc95677&hash=10C133EF7F08BA9CC7EE1E8EE9AAF821",
				"alt": "BECKY SAUERBRUNN",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/player-boxing-images/wnt/white/lloyd-white-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=a17fbe502d384a2f8889cc77cb489482&hash=F318E3466D3FE7E2D4128D0B98230A36",
				"alt": "Carli Lloyd",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "", "editable": null},
		"stadiumLocation": {"value": "", "editable": null},
		"stadiumCountry": {"value": "", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/app/broadcasters/espn2_logo.ashx?h=52&la=en-US&w=120&rev=8b583676054f469c997298102fc43568&hash=495D7043006AA51910F46539C08D4624",
					"alt": "ESPN2",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.espn.com/watch/?type=live&channel=espn2", "editable": null},
			"iosUrl": {"value": "https://apps.apple.com/us/app/espn-scorecenter/id317469184", "editable": null},
			"androidUrl": {"value": "https://play.google.com/store/apps/details?id=com.espn.score_center", "editable": null}
		}],
		"currentItemId": "09ab6022-a3e4-4191-b455-a70296372108"
	},
	"dateTime": "2021-04-13T19:10:00Z",
	"dateTimeObject": "2021-04-13T19:10:00+00:00"
}, {
	"matchId": "90hqry2v40qdks7j77bjijg2c",
	"description": "Sweden vs United States",
	"tournamentCalendarId": "ag87g3nigmrjyjowr5zz2ymtw",
	"tournamentCalendarName": "2021",
	"stageName": "Friendlies 1",
	"status": 0,
	"goals": [{
		"contestantId": "52rvkys9wse9ip8a181ik4697",
		"periodId": 1,
		"timeMin": 38,
		"goalType": 0,
		"scorerId": "3i4ljm3hqc6wg8s6l4onlagut",
		"scorerName": "L. Hurtig",
		"assistPlayerId": "yobsx8qzjrdt67z3vbzygd79",
		"assistPlayerName": "K. Asllani"
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 2,
		"timeMin": 87,
		"goalType": 1,
		"scorerId": "29vjl1iba9rz14yd1g9qi4pn9",
		"scorerName": "M. Rapinoe",
		"assistPlayerId": null,
		"assistPlayerName": null
	}],
	"sponsor": null,
	"date": "2021-04-10T17:10:00",
	"time": "17:10:00Z",
	"cancelled": false,
	"lengthMin": 95,
	"lengthSec": 4,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "6qv56cq8m56uq104fa4geubf9",
		"longName": "Friends Arena",
		"shortName": "Friends Arena",
		"location": "Solna",
		"country": "Sweden"
	},
	"attendance": 0,
	"competition": {
		"id": "dvtl8sf1262pd2aqgu641qa7u",
		"name": "International Friendly",
		"code": "FRW",
		"format": "International cup",
		"startDate": "2021-01-01T00:00:00Z",
		"endDate": "2021-12-31T00:00:00Z",
		"isFriendly": true
	},
	"periods": [{
		"id": 1,
		"start": "2021-04-10T17:12:23Z",
		"end": "2021-04-10T17:59:21Z",
		"lengthMin": "46",
		"lengthSec": "58",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2021-04-10T18:14:58Z",
		"end": "2021-04-10T19:03:04Z",
		"lengthMin": "48",
		"lengthSec": "6",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "52rvkys9wse9ip8a181ik4697",
		"name": "Sweden",
		"officialName": "Sweden",
		"shortName": "Sweden",
		"code": "SWE",
		"position": "home",
		"score": 1,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "e70zl10x0ayu7y10ry0wi465a",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "away",
		"score": 1,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/uswnt-friendlies-2021/matches/sweden-vs-united-states-4-10-2021",
	"sitecoreData": {
		"link1": {
			"value": {
				"href": "https://www.foxsports.com/mobile",
				"text": "FOX",
				"linktype": "external",
				"url": "https://www.foxsports.com/mobile",
				"anchor": "",
				"title": "FOX",
				"target": "_blank"
			}, "editable": null
		},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {
			"value": {
				"href": "/stories/2021/04/international-friendly-uswnt-vs-sweden-preview-schedule-tv-channels-start-time",
				"querystring": "",
				"target": "",
				"linktype": "internal",
				"id": "{8EE80541-7357-4C2D-B73C-A3E3AFBB01A7}",
				"url": "/USSF/Home/Stories/2021/04/International Friendly USWNT vs Sweden Preview Schedule TV Channels Start Time"
			}, "editable": null
		},
		"matchRecapLink": {"value": {"href": ""}, "editable": null},
		"ticketsLink": {"value": {"href": ""}, "editable": null},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/wwcfrance2019backgroundp1.ashx?h=1200&la=en-US&w=3840&rev=f45ce10739234f589f36d39aca7e9510&hash=A256648079CC6A02F8070F882ED0DB53",
				"alt": "",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/wwcfrance2019backgroundcard.ashx?h=720&la=en-US&w=670&rev=751c0234ecbd48bfa5f60b6104526e60&hash=62233C6BBFA006927A82260DC14A3EFB",
				"alt": "WWC Background",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/uswnt-friendlies-2021"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/player-boxing-images/wnt/white/williams-white-playernameboxingposterleft.ashx?h=1160&la=en-US&w=880&rev=19c7c824a85d40aeb8b011d70ca9b645&hash=E2CA65EB5337742779BE87C5792DF6D1",
				"alt": "LYNN WILLIAMS",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/player-boxing-images/wnt/white/dunn-white-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=997d28d510844c04820a5a01ac526442&hash=C76E657006D3253708F93E03965C65EE",
				"alt": "CRYSTAL DUNN",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "", "editable": null},
		"stadiumLocation": {"value": "", "editable": null},
		"stadiumCountry": {"value": "", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/app/broadcasters/tvlogofoxblack2x.ashx?h=52&la=en-US&w=120&rev=1dd8458ff07b4d02a856c09c91032169&hash=8D532373DF96AA9D153CC4CF0A527D8A",
					"alt": "FOX",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.foxsports.com/mobile", "editable": null},
			"iosUrl": {"value": "https://www.foxsports.com/mobile", "editable": null},
			"androidUrl": {"value": "https://www.foxsports.com/mobile", "editable": null}
		}],
		"currentItemId": "4107bfbb-18d6-4cd7-bc34-c6b915cc7179"
	},
	"dateTime": "2021-04-10T17:10:00Z",
	"dateTimeObject": "2021-04-10T17:10:00+00:00"
}, {
	"matchId": "cansl631zldqcg7zao4pqtpuc",
	"description": "United States vs Argentina",
	"tournamentCalendarId": "65u8mk4htqfxjmoijpt6cs9d6",
	"tournamentCalendarName": "2021",
	"stageName": "Group Stage",
	"status": 0,
	"goals": [{
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 1,
		"timeMin": 16,
		"goalType": 0,
		"scorerId": "29vjl1iba9rz14yd1g9qi4pn9",
		"scorerName": "M. Rapinoe",
		"assistPlayerId": "4j3kopvvd3iej6p4h9z36giqd",
		"assistPlayerName": "R. Lavelle"
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 1,
		"timeMin": 26,
		"goalType": 0,
		"scorerId": "29vjl1iba9rz14yd1g9qi4pn9",
		"scorerName": "M. Rapinoe",
		"assistPlayerId": "exfa2ygjuuy08v50w65ddj2l1",
		"assistPlayerName": "C. Lloyd"
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 1,
		"timeMin": 35,
		"goalType": 0,
		"scorerId": "exfa2ygjuuy08v50w65ddj2l1",
		"scorerName": "C. Lloyd",
		"assistPlayerId": "4t91su0ns46r00vjynp0on5qt",
		"assistPlayerName": "K. Mewis"
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 1,
		"timeMin": 41,
		"goalType": 0,
		"scorerId": "4t91su0ns46r00vjynp0on5qt",
		"scorerName": "K. Mewis",
		"assistPlayerId": "4ullol3wa4qdi9ydgrxgxbcgl",
		"assistPlayerName": "C. Short"
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 2,
		"timeMin": 84,
		"goalType": 0,
		"scorerId": "l28lqh3f3ojx6kk2wvvq6nyt",
		"scorerName": "A. Morgan",
		"assistPlayerId": "f05vaz2h7sxozmvi641cf7qi1",
		"assistPlayerName": "S. Smith"
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 2,
		"timeMin": 88,
		"goalType": 0,
		"scorerId": "bnxnrws4yintb6s76erzl9xw5",
		"scorerName": "C. Press",
		"assistPlayerId": "6ywkny98b9qzuvhqxwn9l0ogl",
		"assistPlayerName": "L. Horan"
	}],
	"sponsor": null,
	"date": "2021-02-25T00:00:00",
	"time": "00:00:00Z",
	"cancelled": false,
	"lengthMin": 90,
	"lengthSec": 4,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "3udn5yhh0bcxwmjokvt4xnm5l",
		"longName": "Exploria Stadium",
		"shortName": "Exploria Stadium",
		"location": "Orlando, Florida",
		"country": "USA"
	},
	"attendance": 0,
	"competition": {
		"id": "dzdh9f7vyvzpq3nmvl22dyx2h",
		"name": "SheBelieves Cup",
		"code": "SBC",
		"format": "International cup",
		"startDate": "2021-02-18T00:00:00Z",
		"endDate": "2021-02-25T00:00:00Z",
		"isFriendly": true
	},
	"periods": [{
		"id": 1,
		"start": "2021-02-25T00:05:20Z",
		"end": "2021-02-25T00:50:21Z",
		"lengthMin": "45",
		"lengthSec": "1",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2021-02-25T01:05:54Z",
		"end": "2021-02-25T01:50:57Z",
		"lengthMin": "45",
		"lengthSec": "3",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "e70zl10x0ayu7y10ry0wi465a",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "home",
		"score": 6,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "3btsze7bdibe9vm0to62hecrv",
		"name": "Argentina",
		"officialName": "Argentina",
		"shortName": "Argentina",
		"code": "ARG",
		"position": "away",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/shebelieves-cup-2021/matches/united-states-vs-argentina",
	"sitecoreData": {
		"link1": {
			"value": {
				"href": "https://www.foxsports.com/live/fs1",
				"text": "FS1",
				"linktype": "external",
				"url": "https://www.foxsports.com/live/fs1",
				"anchor": "",
				"title": "FS1",
				"target": "_blank"
			}, "editable": null
		},
		"link2": {
			"value": {
				"href": "https://www.tudn.com/",
				"text": "TUDN",
				"linktype": "external",
				"url": "https://www.tudn.com/",
				"anchor": "",
				"title": "TUDN",
				"target": "_blank"
			}, "editable": null
		},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {"value": {"href": ""}, "editable": null},
		"matchRecapLink": {"value": {"href": ""}, "editable": null},
		"ticketsLink": {
			"value": {
				"href": "https://www.ticketmaster.com/event/22005A3A9DA49559",
				"text": "Tickets",
				"linktype": "external",
				"url": "https://www.ticketmaster.com/event/22005A3A9DA49559",
				"anchor": "",
				"title": "Tickets",
				"target": "_blank"
			}, "editable": null
		},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/wnt/match-backgrounds/backgroundblue.ashx?h=1200&la=en-US&w=3840&rev=5f5bb9ef0ba345c1b9b013598f0e6758&hash=3107C04CB54A143F8EDCF691228D3EEA",
				"alt": "Blue Chevron Background Pattern",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/matchcarddefault.ashx?h=720&la=en-US&w=670&rev=2cfa098f22514cb582a559acc92fa48a&hash=1FC8EC20CB4F56C8C888C0C13B25D8D3",
				"alt": "",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/shebelieves-cup-2021"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/wnt/carli-lloyd/lloyd-playernameboxingposterleft.ashx?h=1160&la=en-US&w=880&rev=f33a35da2ba34fb894542a5647416db8&hash=C2703EC4B7E9A144EFB059BB3083C246",
				"alt": "Carli Lloyd",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/wnt/megan-rapinoe/rapinoe-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=e38d9c35f77241b6a6a17e138d4e189b&hash=4C3D41F39198CEC528372C4219F4E7C2",
				"alt": "Megan Rapinoe",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "Presented by VISA", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "", "editable": null},
		"stadiumLocation": {"value": "", "editable": null},
		"stadiumCountry": {"value": "", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogofs1black2x.ashx?h=52&la=en-US&w=120&rev=79d1fc98d79c4aa582431482dc9188aa&hash=222581B287E6DC17C26116C6B3C28131",
					"alt": "FS1",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.foxsports.com/live/fs1", "editable": null},
			"iosUrl": {"value": "https://www.foxsports.com/mobile", "editable": null},
			"androidUrl": {"value": "https://www.foxsports.com/mobile", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tundlogo.ashx?h=52&la=en-US&w=120&rev=9cc1f63328c54407a1efc533b869bdc5&hash=8A3D704527709F5BB4D4149320DFB4DC",
					"alt": "TUDN",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.tudn.com/", "editable": null},
			"iosUrl": {"value": "https://www.tudn.com/", "editable": null},
			"androidUrl": {"value": "https://www.tudn.com/", "editable": null}
		}],
		"currentItemId": "0d8a6870-6412-438f-bf5c-f262d32f0387"
	},
	"dateTime": "2021-02-25T00:00:00Z",
	"dateTimeObject": "2021-02-25T00:00:00+00:00"
}, {
	"matchId": "caf5930cugcxh3y92uvd88e8k",
	"description": "United States vs Brazil",
	"tournamentCalendarId": "65u8mk4htqfxjmoijpt6cs9d6",
	"tournamentCalendarName": "2021",
	"stageName": "Group Stage",
	"status": 0,
	"goals": [{
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 1,
		"timeMin": 11,
		"goalType": 0,
		"scorerId": "bnxnrws4yintb6s76erzl9xw5",
		"scorerName": "C. Press",
		"assistPlayerId": "6ywkny98b9qzuvhqxwn9l0ogl",
		"assistPlayerName": "L. Horan"
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 2,
		"timeMin": 88,
		"goalType": 0,
		"scorerId": "29vjl1iba9rz14yd1g9qi4pn9",
		"scorerName": "M. Rapinoe",
		"assistPlayerId": "6ywkny98b9qzuvhqxwn9l0ogl",
		"assistPlayerName": "L. Horan"
	}],
	"sponsor": null,
	"date": "2021-02-21T20:00:00",
	"time": "20:00:00Z",
	"cancelled": false,
	"lengthMin": 94,
	"lengthSec": 0,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "3udn5yhh0bcxwmjokvt4xnm5l",
		"longName": "Exploria Stadium",
		"shortName": "Exploria Stadium",
		"location": "Orlando, Florida",
		"country": "USA"
	},
	"attendance": 0,
	"competition": {
		"id": "dzdh9f7vyvzpq3nmvl22dyx2h",
		"name": "SheBelieves Cup",
		"code": "SBC",
		"format": "International cup",
		"startDate": "2021-02-18T00:00:00Z",
		"endDate": "2021-02-25T00:00:00Z",
		"isFriendly": true
	},
	"periods": [{
		"id": 1,
		"start": "2021-02-21T20:05:20Z",
		"end": "2021-02-21T20:51:24Z",
		"lengthMin": "46",
		"lengthSec": "4",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2021-02-21T21:08:06Z",
		"end": "2021-02-21T21:56:02Z",
		"lengthMin": "47",
		"lengthSec": "56",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "e70zl10x0ayu7y10ry0wi465a",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "home",
		"score": 2,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "1n3ndydg1dl4405q55pyp4l3p",
		"name": "Brazil",
		"officialName": "Brazil",
		"shortName": "Brazil",
		"code": "BRA",
		"position": "away",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/shebelieves-cup-2021/matches/united-states-vs-brazil",
	"sitecoreData": {
		"link1": {
			"value": {
				"href": "https://www.foxsports.com/live/fs1",
				"text": "FS1",
				"linktype": "external",
				"url": "https://www.foxsports.com/live/fs1",
				"anchor": "",
				"title": "FS1",
				"target": "_blank"
			}, "editable": null
		},
		"link2": {
			"value": {
				"href": "https://www.tudn.com/",
				"text": "TUDN",
				"linktype": "external",
				"url": "https://www.tudn.com/",
				"anchor": "",
				"title": "TUDN",
				"target": "_blank"
			}, "editable": null
		},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {"value": {"href": ""}, "editable": null},
		"matchRecapLink": {"value": {"href": ""}, "editable": null},
		"ticketsLink": {
			"value": {
				"href": "https://www.ticketmaster.com/event/22005A3A953A90BE",
				"text": "Tickets",
				"linktype": "external",
				"url": "https://www.ticketmaster.com/event/22005A3A953A90BE",
				"anchor": "",
				"title": "Tickets",
				"target": ""
			}, "editable": null
		},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/wnt/match-backgrounds/backgroundblue.ashx?h=1200&la=en-US&w=3840&rev=5f5bb9ef0ba345c1b9b013598f0e6758&hash=3107C04CB54A143F8EDCF691228D3EEA",
				"alt": "Blue Chevron Background Pattern",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/matchcarddefault.ashx?h=720&la=en-US&w=670&rev=2cfa098f22514cb582a559acc92fa48a&hash=1FC8EC20CB4F56C8C888C0C13B25D8D3",
				"alt": "",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/shebelieves-cup-2021"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/wnt/carli-lloyd/lloyd-playernameboxingposterleft.ashx?h=1160&la=en-US&w=880&rev=f33a35da2ba34fb894542a5647416db8&hash=C2703EC4B7E9A144EFB059BB3083C246",
				"alt": "Carli Lloyd",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/wnt/megan-rapinoe/rapinoe-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=e38d9c35f77241b6a6a17e138d4e189b&hash=4C3D41F39198CEC528372C4219F4E7C2",
				"alt": "Megan Rapinoe",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "Presented by VISA", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "", "editable": null},
		"stadiumLocation": {"value": "", "editable": null},
		"stadiumCountry": {"value": "", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogofs1black2x.ashx?h=52&la=en-US&w=120&rev=79d1fc98d79c4aa582431482dc9188aa&hash=222581B287E6DC17C26116C6B3C28131",
					"alt": "FS1",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.foxsports.com/live/fs1", "editable": null},
			"iosUrl": {"value": "https://www.foxsports.com/mobile", "editable": null},
			"androidUrl": {"value": "https://www.foxsports.com/mobile", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tundlogo.ashx?h=52&la=en-US&w=120&rev=9cc1f63328c54407a1efc533b869bdc5&hash=8A3D704527709F5BB4D4149320DFB4DC",
					"alt": "TUDN",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.tudn.com/", "editable": null},
			"iosUrl": {"value": "https://www.tudn.com/", "editable": null},
			"androidUrl": {"value": "https://www.tudn.com/", "editable": null}
		}],
		"currentItemId": "2b63b7d3-3c2b-4091-98c3-a487fea8c8bb"
	},
	"dateTime": "2021-02-21T20:00:00Z",
	"dateTimeObject": "2021-02-21T20:00:00+00:00"
}, {
	"matchId": "cabgznntvl2ow2lji6ftfaadw",
	"description": "United States vs Canada",
	"tournamentCalendarId": "65u8mk4htqfxjmoijpt6cs9d6",
	"tournamentCalendarName": "2021",
	"stageName": "Group Stage",
	"status": 0,
	"goals": [{
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 2,
		"timeMin": 79,
		"goalType": 0,
		"scorerId": "4j3kopvvd3iej6p4h9z36giqd",
		"scorerName": "R. Lavelle",
		"assistPlayerId": "6ywkny98b9qzuvhqxwn9l0ogl",
		"assistPlayerName": "L. Horan"
	}],
	"sponsor": null,
	"date": "2021-02-19T00:00:00",
	"time": "00:00:00Z",
	"cancelled": false,
	"lengthMin": 98,
	"lengthSec": 31,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "3udn5yhh0bcxwmjokvt4xnm5l",
		"longName": "Exploria Stadium",
		"shortName": "Exploria Stadium",
		"location": "Orlando, Florida",
		"country": "USA"
	},
	"attendance": 0,
	"competition": {
		"id": "dzdh9f7vyvzpq3nmvl22dyx2h",
		"name": "SheBelieves Cup",
		"code": "SBC",
		"format": "International cup",
		"startDate": "2021-02-18T00:00:00Z",
		"endDate": "2021-02-25T00:00:00Z",
		"isFriendly": true
	},
	"periods": [{
		"id": 1,
		"start": "2021-02-19T00:05:22Z",
		"end": "2021-02-19T00:54:23Z",
		"lengthMin": "49",
		"lengthSec": "1",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2021-02-19T01:11:55Z",
		"end": "2021-02-19T02:01:25Z",
		"lengthMin": "49",
		"lengthSec": "30",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "e70zl10x0ayu7y10ry0wi465a",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "home",
		"score": 1,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "e3vi0exjm76v5bfnmoj15bg2i",
		"name": "Canada",
		"officialName": "Canada",
		"shortName": "Canada",
		"code": "CAN",
		"position": "away",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/shebelieves-cup-2021/matches/united-states-vs-canada",
	"sitecoreData": {
		"link1": {
			"value": {
				"href": "https://www.foxsports.com/live/fs1",
				"text": "FS1",
				"linktype": "external",
				"url": "https://www.foxsports.com/live/fs1",
				"anchor": "",
				"title": "FS1",
				"target": "_blank"
			}, "editable": null
		},
		"link2": {
			"value": {
				"href": "https://www.tudn.com/",
				"text": "TUDN",
				"linktype": "external",
				"url": "https://www.tudn.com/",
				"anchor": "",
				"title": "TUDN",
				"target": "_blank"
			}, "editable": null
		},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {"value": {"href": ""}, "editable": null},
		"matchRecapLink": {"value": {"href": ""}, "editable": null},
		"ticketsLink": {
			"value": {
				"href": "https://www.ticketmaster.com/event/22005A31E6F29472",
				"text": "Tickets",
				"linktype": "external",
				"url": "https://www.ticketmaster.com/event/22005A31E6F29472",
				"anchor": "",
				"title": "Tickets",
				"target": "_blank"
			}, "editable": null
		},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/wnt/match-backgrounds/backgroundblue.ashx?h=1200&la=en-US&w=3840&rev=5f5bb9ef0ba345c1b9b013598f0e6758&hash=3107C04CB54A143F8EDCF691228D3EEA",
				"alt": "Blue Chevron Background Pattern",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/matchcarddefault.ashx?h=720&la=en-US&w=670&rev=2cfa098f22514cb582a559acc92fa48a&hash=1FC8EC20CB4F56C8C888C0C13B25D8D3",
				"alt": "",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/shebelieves-cup-2021"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/wnt/carli-lloyd/lloyd-playernameboxingposterleft.ashx?h=1160&la=en-US&w=880&rev=f33a35da2ba34fb894542a5647416db8&hash=C2703EC4B7E9A144EFB059BB3083C246",
				"alt": "Carli Lloyd",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/wnt/megan-rapinoe/rapinoe-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=e38d9c35f77241b6a6a17e138d4e189b&hash=4C3D41F39198CEC528372C4219F4E7C2",
				"alt": "Megan Rapinoe",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "Presented by VISA", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "", "editable": null},
		"stadiumLocation": {"value": "", "editable": null},
		"stadiumCountry": {"value": "", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogofs1black2x.ashx?h=52&la=en-US&w=120&rev=79d1fc98d79c4aa582431482dc9188aa&hash=222581B287E6DC17C26116C6B3C28131",
					"alt": "FS1",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.foxsports.com/live/fs1", "editable": null},
			"iosUrl": {"value": "https://www.foxsports.com/mobile", "editable": null},
			"androidUrl": {"value": "https://www.foxsports.com/mobile", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tundlogo.ashx?h=52&la=en-US&w=120&rev=9cc1f63328c54407a1efc533b869bdc5&hash=8A3D704527709F5BB4D4149320DFB4DC",
					"alt": "TUDN",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.tudn.com/", "editable": null},
			"iosUrl": {"value": "https://www.tudn.com/", "editable": null},
			"androidUrl": {"value": "https://www.tudn.com/", "editable": null}
		}],
		"currentItemId": "7ed9ef02-549d-4296-badc-12a73abf30f6"
	},
	"dateTime": "2021-02-19T00:00:00Z",
	"dateTimeObject": "2021-02-19T00:00:00+00:00"
}, {
	"matchId": "47ba91pobmxrqripvkv2pcems",
	"description": "United States vs Colombia",
	"tournamentCalendarId": "ag87g3nigmrjyjowr5zz2ymtw",
	"tournamentCalendarName": "2021",
	"stageName": "Friendlies 1",
	"status": 0,
	"goals": [{
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 1,
		"timeMin": 3,
		"goalType": 0,
		"scorerId": "5ibsbj2zqv62c94k5wijnayy2",
		"scorerName": "C. Macário",
		"assistPlayerId": null,
		"assistPlayerName": null
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 1,
		"timeMin": 35,
		"goalType": 0,
		"scorerId": "29vjl1iba9rz14yd1g9qi4pn9",
		"scorerName": "M. Rapinoe",
		"assistPlayerId": "3aj7p478dcuat6tz8096sq8dl",
		"assistPlayerName": "E. Sonnett"
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 1,
		"timeMin": 44,
		"goalType": 1,
		"scorerId": "29vjl1iba9rz14yd1g9qi4pn9",
		"scorerName": "M. Rapinoe",
		"assistPlayerId": null,
		"assistPlayerName": null
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 2,
		"timeMin": 60,
		"goalType": 0,
		"scorerId": "cm6fzm8v38ti0aubu0q4v717t",
		"scorerName": "L. Williams",
		"assistPlayerId": "4tcmt910n2lj88n5m0aqoddcl",
		"assistPlayerName": "S. Mewis"
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 2,
		"timeMin": 73,
		"goalType": 0,
		"scorerId": "6ywkny98b9qzuvhqxwn9l0ogl",
		"scorerName": "L. Horan",
		"assistPlayerId": "4b13osk9pzrosvzqt55y7o34l",
		"assistPlayerName": "C. Dunn"
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 2,
		"timeMin": 86,
		"goalType": 0,
		"scorerId": "56y5qt6c03t7f5t7eoebi0wyd",
		"scorerName": "M. Williams-Purce",
		"assistPlayerId": "exfa2ygjuuy08v50w65ddj2l1",
		"assistPlayerName": "C. Lloyd"
	}],
	"sponsor": null,
	"date": "2021-01-23T00:00:00",
	"time": "00:00:00Z",
	"cancelled": false,
	"lengthMin": 94,
	"lengthSec": 31,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "3udn5yhh0bcxwmjokvt4xnm5l",
		"longName": "Exploria Stadium",
		"shortName": "Exploria Stadium",
		"location": "Orlando, Florida",
		"country": "USA"
	},
	"attendance": 0,
	"competition": {
		"id": "dvtl8sf1262pd2aqgu641qa7u",
		"name": "International Friendly",
		"code": "FRW",
		"format": "International cup",
		"startDate": "2021-01-01T00:00:00Z",
		"endDate": "2021-12-31T00:00:00Z",
		"isFriendly": true
	},
	"periods": [{
		"id": 1,
		"start": "2021-01-23T00:08:50Z",
		"end": "2021-01-23T00:55:11Z",
		"lengthMin": "46",
		"lengthSec": "21",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2021-01-23T01:11:26Z",
		"end": "2021-01-23T01:59:36Z",
		"lengthMin": "48",
		"lengthSec": "10",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "e70zl10x0ayu7y10ry0wi465a",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "home",
		"score": 6,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "3yv8fkf4u1t0fplhv7ihxwilq",
		"name": "Colombia",
		"officialName": "Colombia",
		"shortName": "Colombia",
		"code": "COL",
		"position": "away",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/uswnt-friendlies-2021/matches/united-states-vs-colombia-1-22-2021",
	"sitecoreData": {
		"link1": {
			"value": {
				"href": "https://www.espn.com/watch/?type=live&channel=espn2",
				"text": "ESPN2",
				"linktype": "external",
				"url": "https://www.espn.com/watch/?type=live&channel=espn2",
				"anchor": "",
				"title": "ESPN2",
				"target": "_blank"
			}, "editable": null
		},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {"value": {"href": ""}, "editable": null},
		"matchRecapLink": {"value": {"href": ""}, "editable": null},
		"ticketsLink": {
			"value": {
				"href": "https://www.ticketmaster.com/event/2200599E77638209",
				"text": "Tickets",
				"linktype": "external",
				"url": "https://www.ticketmaster.com/event/2200599E77638209",
				"anchor": "",
				"target": "_blank"
			}, "editable": null
		},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/wwcfrance2019backgroundp1.ashx?h=1200&la=en-US&w=3840&rev=f45ce10739234f589f36d39aca7e9510&hash=A256648079CC6A02F8070F882ED0DB53",
				"alt": "",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/wwcfrance2019backgroundcard.ashx?h=720&la=en-US&w=670&rev=751c0234ecbd48bfa5f60b6104526e60&hash=62233C6BBFA006927A82260DC14A3EFB",
				"alt": "WWC Background",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/uswnt-friendlies-2021"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/player-boxing-images/wnt/white/williams-white-playernameboxingposterleft.ashx?h=1160&la=en-US&w=880&rev=19c7c824a85d40aeb8b011d70ca9b645&hash=E2CA65EB5337742779BE87C5792DF6D1",
				"alt": "LYNN WILLIAMS",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/player-boxing-images/wnt/white/dunn-white-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=997d28d510844c04820a5a01ac526442&hash=C76E657006D3253708F93E03965C65EE",
				"alt": "CRYSTAL DUNN",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "", "editable": null},
		"stadiumLocation": {"value": "", "editable": null},
		"stadiumCountry": {"value": "", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/app/broadcasters/espn2_logo.ashx?h=52&la=en-US&w=120&rev=8b583676054f469c997298102fc43568&hash=495D7043006AA51910F46539C08D4624",
					"alt": "",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.espn.com/watch/?type=live&channel=espn2", "editable": null},
			"iosUrl": {"value": "https://apps.apple.com/us/app/espn-scorecenter/id317469184", "editable": null},
			"androidUrl": {"value": "https://play.google.com/store/apps/details?id=com.espn.score_center", "editable": null}
		}],
		"currentItemId": "8380b3b6-50db-4acd-9d24-f684487142a5"
	},
	"dateTime": "2021-01-23T00:00:00Z",
	"dateTimeObject": "2021-01-23T00:00:00+00:00"
}, {
	"matchId": "476i20k3o3hk3o6p8l02vf3f8",
	"description": "United States vs Colombia",
	"tournamentCalendarId": "ag87g3nigmrjyjowr5zz2ymtw",
	"tournamentCalendarName": "2021",
	"stageName": "Friendlies 1",
	"status": 0,
	"goals": [{
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 1,
		"timeMin": 4,
		"goalType": 0,
		"scorerId": "4tcmt910n2lj88n5m0aqoddcl",
		"scorerName": "S. Mewis",
		"assistPlayerId": "29vjl1iba9rz14yd1g9qi4pn9",
		"assistPlayerName": "M. Rapinoe"
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 1,
		"timeMin": 33,
		"goalType": 0,
		"scorerId": "4tcmt910n2lj88n5m0aqoddcl",
		"scorerName": "S. Mewis",
		"assistPlayerId": "exfa2ygjuuy08v50w65ddj2l1",
		"assistPlayerName": "C. Lloyd"
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 2,
		"timeMin": 46,
		"goalType": 1,
		"scorerId": "4tcmt910n2lj88n5m0aqoddcl",
		"scorerName": "S. Mewis",
		"assistPlayerId": null,
		"assistPlayerName": null
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 2,
		"timeMin": 85,
		"goalType": 0,
		"scorerId": "4t91su0ns46r00vjynp0on5qt",
		"scorerName": "K. Mewis",
		"assistPlayerId": "exfa2ygjuuy08v50w65ddj2l1",
		"assistPlayerName": "C. Lloyd"
	}],
	"sponsor": null,
	"date": "2021-01-19T00:00:00",
	"time": "00:00:00Z",
	"cancelled": false,
	"lengthMin": 94,
	"lengthSec": 14,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "3udn5yhh0bcxwmjokvt4xnm5l",
		"longName": "Exploria Stadium",
		"shortName": "Exploria Stadium",
		"location": "Orlando, Florida",
		"country": "USA"
	},
	"attendance": 0,
	"competition": {
		"id": "dvtl8sf1262pd2aqgu641qa7u",
		"name": "International Friendly",
		"code": "FRW",
		"format": "International cup",
		"startDate": "2021-01-01T00:00:00Z",
		"endDate": "2021-12-31T00:00:00Z",
		"isFriendly": true
	},
	"periods": [{
		"id": 1,
		"start": "2021-01-19T00:25:27Z",
		"end": "2021-01-19T01:12:35Z",
		"lengthMin": "47",
		"lengthSec": "8",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2021-01-19T01:29:28Z",
		"end": "2021-01-19T02:16:34Z",
		"lengthMin": "47",
		"lengthSec": "6",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "e70zl10x0ayu7y10ry0wi465a",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "home",
		"score": 4,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "3yv8fkf4u1t0fplhv7ihxwilq",
		"name": "Colombia",
		"officialName": "Colombia",
		"shortName": "Colombia",
		"code": "COL",
		"position": "away",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/uswnt-friendlies-2021/matches/united-states-vs-colombia-1-18-2021",
	"sitecoreData": {
		"link1": {
			"value": {
				"href": "https://www.foxsports.com/live/fs1",
				"text": "FS1",
				"linktype": "external",
				"url": "https://www.foxsports.com/live/fs1",
				"anchor": "",
				"title": "FS1",
				"target": "_blank"
			}, "editable": null
		},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {"value": {"href": ""}, "editable": null},
		"matchRecapLink": {"value": {"href": ""}, "editable": null},
		"ticketsLink": {
			"value": {
				"href": "https://www.ticketmaster.com/event/220059970CF193ED",
				"text": "Tickets",
				"linktype": "external",
				"url": "https://www.ticketmaster.com/event/220059970CF193ED",
				"anchor": "",
				"target": "_blank"
			}, "editable": null
		},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/wwcfrance2019backgroundp1.ashx?h=1200&la=en-US&w=3840&rev=f45ce10739234f589f36d39aca7e9510&hash=A256648079CC6A02F8070F882ED0DB53",
				"alt": "",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/wwcfrance2019backgroundcard.ashx?h=720&la=en-US&w=670&rev=751c0234ecbd48bfa5f60b6104526e60&hash=62233C6BBFA006927A82260DC14A3EFB",
				"alt": "WWC Background",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/uswnt-friendlies-2021"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/player-boxing-images/wnt/white/sauerbrunn-white-playernameboxingposterleft.ashx?h=1160&la=en-US&w=880&rev=a32ee89bbbef4d2b9f3087750fc95677&hash=10C133EF7F08BA9CC7EE1E8EE9AAF821",
				"alt": "BECKY SAUERBRUNN",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/player-boxing-images/wnt/white/lloyd-white-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=a17fbe502d384a2f8889cc77cb489482&hash=F318E3466D3FE7E2D4128D0B98230A36",
				"alt": "Carli Lloyd",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "", "editable": null},
		"stadiumLocation": {"value": "", "editable": null},
		"stadiumCountry": {"value": "", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogofs1black2x.ashx?h=52&la=en-US&w=120&rev=79d1fc98d79c4aa582431482dc9188aa&hash=222581B287E6DC17C26116C6B3C28131",
					"alt": "FS1",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.foxsports.com/live/fs1", "editable": null},
			"iosUrl": {"value": "https://www.foxsports.com/mobile", "editable": null},
			"androidUrl": {"value": "https://www.foxsports.com/mobile", "editable": null}
		}],
		"currentItemId": "903f19dd-f99e-4cba-abf0-95a22d7e3417"
	},
	"dateTime": "2021-01-19T00:00:00Z",
	"dateTimeObject": "2021-01-19T00:00:00+00:00"
}, {
	"matchId": "1wt3tpebc36y9j1yszmrgvb4k",
	"description": "Netherlands vs United States",
	"tournamentCalendarId": "6tal7j3d7o0xufv960e56m2y2",
	"tournamentCalendarName": "2020",
	"stageName": "Friendlies 1",
	"status": 0,
	"goals": [{
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 1,
		"timeMin": 41,
		"goalType": 0,
		"scorerId": "4j3kopvvd3iej6p4h9z36giqd",
		"scorerName": "R. Lavelle",
		"assistPlayerId": "bnxnrws4yintb6s76erzl9xw5",
		"assistPlayerName": "C. Press"
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 2,
		"timeMin": 70,
		"goalType": 0,
		"scorerId": "4t91su0ns46r00vjynp0on5qt",
		"scorerName": "K. Mewis",
		"assistPlayerId": "cm6fzm8v38ti0aubu0q4v717t",
		"assistPlayerName": "L. Williams"
	}],
	"sponsor": null,
	"date": "2020-11-27T17:35:00",
	"time": "17:35:00Z",
	"cancelled": false,
	"lengthMin": 94,
	"lengthSec": 25,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "7acsy88gm5ze9b1l7jajtnceq",
		"longName": "Rat Verlegh Stadion",
		"shortName": "Rat Verlegh Stadion",
		"location": "Breda",
		"country": "Netherlands"
	},
	"attendance": 0,
	"competition": {
		"id": "dvtl8sf1262pd2aqgu641qa7u",
		"name": "International Friendly",
		"code": "FRW",
		"format": "International cup",
		"startDate": "2020-01-01T00:00:00Z",
		"endDate": "2020-12-31T00:00:00Z",
		"isFriendly": true
	},
	"periods": [{
		"id": 1,
		"start": "2020-11-27T17:39:11Z",
		"end": "2020-11-27T18:24:16Z",
		"lengthMin": "45",
		"lengthSec": "5",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2020-11-27T18:40:19Z",
		"end": "2020-11-27T19:29:39Z",
		"lengthMin": "49",
		"lengthSec": "20",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "9x7z0xiidyp39vy69huxf6kim",
		"name": "Netherlands",
		"officialName": "Netherlands",
		"shortName": "Netherlands",
		"code": "NED",
		"position": "home",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "e70zl10x0ayu7y10ry0wi465a",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "away",
		"score": 2,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/uswnt-friendlies-2020/matches/united-states-vs-netherlands",
	"sitecoreData": {
		"link1": {"value": {"href": ""}, "editable": null},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {"value": {"href": ""}, "editable": null},
		"matchRecapLink": {"value": {"href": ""}, "editable": null},
		"ticketsLink": {"value": {"href": ""}, "editable": null},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/wnt/match-backgrounds/backgroundblue.ashx?h=1200&la=en-US&w=3840&rev=5f5bb9ef0ba345c1b9b013598f0e6758&hash=3107C04CB54A143F8EDCF691228D3EEA",
				"alt": "Blue Chevron Background Pattern",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/matchcarddefault.ashx?h=720&la=en-US&w=670&rev=2cfa098f22514cb582a559acc92fa48a&hash=1FC8EC20CB4F56C8C888C0C13B25D8D3",
				"alt": "",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/uswnt-friendlies-2020"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/wnt/2019/press-white-playernameboxingposterleft.ashx?h=1160&la=en-US&w=880&rev=46d9837412a142649626f0163230a82e&hash=0FE4B4FB3BFBA27A43A3BAB6DF3BFD1F",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/wnt/2019/dunn-white-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=d6e14c45aa3b4471b1b8ec5578311d05&hash=66839AB2F5569D2938351E617C0F37F3",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "", "editable": null},
		"stadiumLocation": {"value": "", "editable": null},
		"stadiumCountry": {"value": "", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/app/broadcasters/espn2_logo.ashx?h=52&la=en-US&w=120&rev=8b583676054f469c997298102fc43568&hash=495D7043006AA51910F46539C08D4624",
					"alt": "ESPN2",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.espn.com/watch/?type=live&channel=espn2", "editable": null},
			"iosUrl": {"value": "https://apps.apple.com/us/app/espn-scorecenter/id317469184", "editable": null},
			"androidUrl": {"value": "https://play.google.com/store/apps/details?id=com.espn.score_center", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tundlogo.ashx?h=52&la=en-US&w=120&rev=9cc1f63328c54407a1efc533b869bdc5&hash=8A3D704527709F5BB4D4149320DFB4DC",
					"alt": "TUDN",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.tudn.com/", "editable": null},
			"iosUrl": {"value": "https://www.tudn.com/", "editable": null},
			"androidUrl": {"value": "https://www.tudn.com/", "editable": null}
		}],
		"currentItemId": "4a03ac43-2a14-454d-b75f-8093d112df9a"
	},
	"dateTime": "2020-11-27T17:35:00Z",
	"dateTimeObject": "2020-11-27T17:35:00+00:00"
}, {
	"matchId": "dfnzpf7rbcmv3a4tmi696ihd6",
	"description": "England vs United States",
	"tournamentCalendarId": "abnlagjzugmnpdbr35eg5bh7t",
	"tournamentCalendarName": "2019 France",
	"stageName": "Test Two",
	"status": 0,
	"goals": [{
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 1,
		"timeMin": 10,
		"goalType": 0,
		"scorerId": "bnxnrws4yintb6s76erzl9xw5",
		"scorerName": "C. Press",
		"assistPlayerId": "7qw3tekwnmsv6i4u3i0qcpjv9",
		"assistPlayerName": "K. O'Hara"
	}, {
		"contestantId": "b5hjnvaafo5plphdcoeozkw49",
		"periodId": 1,
		"timeMin": 19,
		"goalType": 0,
		"scorerId": "5wob73qv36q7d73zzzu57x8lx",
		"scorerName": "E. White",
		"assistPlayerId": "3sn815dsm0i0rnw9nigjyb0et",
		"assistPlayerName": "B. Mead"
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 1,
		"timeMin": 31,
		"goalType": 0,
		"scorerId": "l28lqh3f3ojx6kk2wvvq6nyt",
		"scorerName": "A. Morgan",
		"assistPlayerId": "6ywkny98b9qzuvhqxwn9l0ogl",
		"assistPlayerName": "L. Horan"
	}],
	"sponsor": "",
	"date": "2020-06-06T11:00:00",
	"time": "11:00:00Z",
	"cancelled": false,
	"lengthMin": 98,
	"lengthSec": 14,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "2cqcab4wnq3jeiz0nq69ds6h",
		"longName": "zzzeeabb",
		"shortName": "zzzeeabb",
		"location": "Décines-Charpieu",
		"country": "France"
	},
	"attendance": 53512,
	"competition": {
		"id": "8x62utr2uti3i7kk14isbnip6",
		"name": "2019 FIFA Women's World Cup ",
		"code": "WWC",
		"format": "International cup",
		"startDate": "2019-06-07T00:00:00Z",
		"endDate": "2019-07-07T00:00:00Z",
		"isFriendly": false
	},
	"periods": [{
		"id": 1,
		"start": "2019-07-02T19:00:14Z",
		"end": "2019-07-02T19:46:21Z",
		"lengthMin": "46",
		"lengthSec": "7",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2019-07-02T20:01:26Z",
		"end": "2019-07-02T20:53:33Z",
		"lengthMin": "52",
		"lengthSec": "7",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "b5hjnvaafo5plphdcoeozkw49",
		"name": "England",
		"officialName": "England",
		"shortName": "England",
		"code": "ENG",
		"position": "home",
		"score": 1,
		"heroFlairTitle": "",
		"heroFlairName": "",
		"heroFlairBody": ""
	}, {
		"id": "e70zl10x0ayu7y10ry0wi465a",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "away",
		"score": 2,
		"heroFlairTitle": "",
		"heroFlairName": "",
		"heroFlairBody": ""
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": "Tickets",
		"url": "https://www.fanhq.ussoccer.com/conditional-final-tickets-1",
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00Z",
		"generalAvailabilityDate": "0001-01-01T00:00:00Z"
	},
	"groupTicketUrl": "",
	"groupTicketText": "",
	"matchFeedUrl": "/competitions/fifa-womens-world-cup-2019/matches/united-states-vs-england",
	"sitecoreData": {
		"link1": {
			"value": {
				"href": "https://www.foxsports.com/mobile",
				"text": "FOX",
				"linktype": "external",
				"url": "https://www.foxsports.com/mobile",
				"anchor": "",
				"title": "FOX",
				"target": "_blank"
			}, "editable": null
		},
		"link2": {
			"value": {
				"href": "http://now.telemundo.com/live",
				"text": "TEL",
				"linktype": "external",
				"url": "http://now.telemundo.com/live",
				"anchor": "",
				"target": "_blank"
			}, "editable": null
		},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {"value": {"href": ""}, "editable": null},
		"matchRecapLink": {
			"value": {
				"href": "https://www.ussoccer.com/stories/2019/07/world-cup-2019-uswnt-vs-england-match-report-stats-standings-bracket",
				"linktype": "external",
				"url": "https://www.ussoccer.com/stories/2019/07/world-cup-2019-uswnt-vs-england-match-report-stats-standings-bracket"
			}, "editable": null
		},
		"ticketsLink": {
			"value": {
				"href": "https://www.fanhq.ussoccer.com/conditional-final-tickets-1",
				"text": "Tickets",
				"linktype": "external",
				"url": "https://www.fanhq.ussoccer.com/conditional-final-tickets-1",
				"anchor": "",
				"target": "_blank"
			}, "editable": null
		},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/wwcfrance2019backgroundp1.ashx?h=1200&la=en-US&w=3840&rev=f45ce10739234f589f36d39aca7e9510&hash=A256648079CC6A02F8070F882ED0DB53",
				"alt": "",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/wwcfrance2019backgroundcard.ashx?h=720&la=en-US&w=670&rev=751c0234ecbd48bfa5f60b6104526e60&hash=62233C6BBFA006927A82260DC14A3EFB",
				"alt": "WWC Background",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/fifa-womens-world-cup-2019"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/wnt/2019/wntveng-lef-playernameboxingposterleft.ashx?h=1160&la=en-US&w=880&rev=f11eef350312f67fe2d8e5cbbc4439b2&hash=ACD46455A8F8D089D967DACF292DD6AF",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/wnt/2019/wnt-vs-mex/lhboxingposterright.ashx?h=1160&la=en-US&w=880&rev=0bd76428ae7c4a7bbe37981e26e601ea&hash=31472609A63057D9F863B4019BD34EB7",
				"alt": "Lindsey Horan",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "", "editable": null},
		"stadiumLocation": {"value": "", "editable": null},
		"stadiumCountry": {"value": "", "editable": null},
		"broadcasterLinks": null,
		"currentItemId": "8013483b-bcfc-4843-83b9-bf4be308f1d1"
	},
	"dateTime": "2020-06-06T11:00:00Z",
	"dateTimeObject": "2020-06-06T11:00:00+00:00"
}, {
	"matchId": "95b567cthlr4yzvp2nk4fub1m",
	"description": "France vs United States",
	"tournamentCalendarId": "abnlagjzugmnpdbr35eg5bh7t",
	"tournamentCalendarName": "2019 France",
	"stageName": "Quarter-finals",
	"status": 0,
	"goals": [{
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 1,
		"timeMin": 5,
		"goalType": 0,
		"scorerId": "29vjl1iba9rz14yd1g9qi4pn9",
		"scorerName": "M. Rapinoe",
		"assistPlayerId": null,
		"assistPlayerName": null
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 2,
		"timeMin": 65,
		"goalType": 0,
		"scorerId": "29vjl1iba9rz14yd1g9qi4pn9",
		"scorerName": "M. Rapinoe",
		"assistPlayerId": "cxl4bxs35ifb8o5cq9jxi1ec5",
		"assistPlayerName": "T. Heath"
	}, {
		"contestantId": "185xqd7s06sm2t85sxzfkrl",
		"periodId": 2,
		"timeMin": 81,
		"goalType": 0,
		"scorerId": "3bo8lbyohd3djey9pbd08fvf9",
		"scorerName": "W. Renard",
		"assistPlayerId": "1ck8f8510t6ei80haqe2vqbpx",
		"assistPlayerName": "G. Thiney"
	}],
	"sponsor": "",
	"date": "2020-06-06T11:00:00",
	"time": "11:00:00Z",
	"cancelled": false,
	"lengthMin": 97,
	"lengthSec": 40,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "efcoydloex7y04q29pac9s67c",
		"longName": "Parc des Princes",
		"shortName": "Parc des Princes",
		"location": "Paris",
		"country": "France"
	},
	"attendance": 45595,
	"competition": {
		"id": "8x62utr2uti3i7kk14isbnip6",
		"name": "2019 FIFA Women's World Cup ",
		"code": "WWC",
		"format": "International cup",
		"startDate": "2019-06-07T00:00:00Z",
		"endDate": "2019-07-07T00:00:00Z",
		"isFriendly": false
	},
	"periods": [{
		"id": 1,
		"start": "2019-06-28T19:00:08Z",
		"end": "2019-06-28T19:47:14Z",
		"lengthMin": "47",
		"lengthSec": "6",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2019-06-28T20:02:29Z",
		"end": "2019-06-28T20:53:03Z",
		"lengthMin": "50",
		"lengthSec": "34",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "185xqd7s06sm2t85sxzfkrl",
		"name": "France",
		"officialName": "France",
		"shortName": "France",
		"code": "FRA",
		"position": "home",
		"score": 1,
		"heroFlairTitle": "",
		"heroFlairName": "",
		"heroFlairBody": ""
	}, {
		"id": "e70zl10x0ayu7y10ry0wi465a",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "away",
		"score": 2,
		"heroFlairTitle": "",
		"heroFlairName": "",
		"heroFlairBody": ""
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": "Tickets",
		"url": "https://www.fanhq.ussoccer.com/conditional-quarterfinal-tickets",
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00Z",
		"generalAvailabilityDate": "0001-01-01T00:00:00Z"
	},
	"groupTicketUrl": "",
	"groupTicketText": "",
	"matchFeedUrl": "/competitions/fifa-womens-world-cup-2019/matches/united-states-vs-france",
	"sitecoreData": {
		"link1": {
			"value": {
				"href": "https://www.foxsports.com/mobile",
				"text": "FOX",
				"linktype": "external",
				"url": "https://www.foxsports.com/mobile",
				"anchor": "",
				"title": "FOX",
				"target": "_blank"
			}, "editable": null
		},
		"link2": {
			"value": {
				"href": "http://www.nbcuniverso.com/now/live",
				"text": "UNIVERSO",
				"linktype": "external",
				"url": "http://www.nbcuniverso.com/now/live",
				"anchor": "",
				"target": "_blank"
			}, "editable": null
		},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {"value": {"href": ""}, "editable": null},
		"matchRecapLink": {
			"value": {
				"href": "https://www.ussoccer.com/stories/2019/06/world-cup-2019-uswnt-vs-france-match-report-stats-standings-bracket",
				"linktype": "external",
				"url": "https://www.ussoccer.com/stories/2019/06/world-cup-2019-uswnt-vs-france-match-report-stats-standings-bracket"
			}, "editable": null
		},
		"ticketsLink": {
			"value": {
				"href": "https://www.fanhq.ussoccer.com/conditional-quarterfinal-tickets",
				"text": "Tickets",
				"linktype": "external",
				"url": "https://www.fanhq.ussoccer.com/conditional-quarterfinal-tickets",
				"anchor": "",
				"target": "_blank"
			}, "editable": null
		},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/wwcfrance2019backgroundp1.ashx?h=1200&la=en-US&w=3840&rev=f45ce10739234f589f36d39aca7e9510&hash=A256648079CC6A02F8070F882ED0DB53",
				"alt": "",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/wwcfrance2019backgroundcard.ashx?h=720&la=en-US&w=670&rev=751c0234ecbd48bfa5f60b6104526e60&hash=62233C6BBFA006927A82260DC14A3EFB",
				"alt": "WWC Background",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/fifa-womens-world-cup-2019"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/wnt/2019/wnt-france-playernameboxingposterleft.ashx?h=1160&la=en-US&w=880&rev=f75e026f4ad5f97a2cc24d678a05bc68&hash=F03B6C3B4234A84524C15A623F512F15",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/wnt/2019/wnt-dunn-playernameboxingposterright/wnt-dunn2-playernameboxingposterright.ashx?h=1000&la=en-US&w=750&rev=a23e9ec2b2764f0085742d58333b5f52&hash=C5732449785FA79076DF5FD0841AC50C",
				"alt": "",
				"width": "750",
				"height": "1000"
			}, "editable": null
		},
		"sponsorText": {"value": "", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "", "editable": null},
		"stadiumLocation": {"value": "", "editable": null},
		"stadiumCountry": {"value": "", "editable": null},
		"broadcasterLinks": null,
		"currentItemId": "94304fcf-16ae-4b8b-8eed-2d3076f5a22d"
	},
	"dateTime": "2020-06-06T11:00:00Z",
	"dateTimeObject": "2020-06-06T11:00:00+00:00"
}, {
	"matchId": "w42nkbjvev3x4mzfpghpuhre",
	"description": "United States vs Chile",
	"tournamentCalendarId": "abnlagjzugmnpdbr35eg5bh7t",
	"tournamentCalendarName": "2019 France",
	"stageName": "Group Stage",
	"status": 0,
	"goals": [{
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 1,
		"timeMin": 11,
		"goalType": 0,
		"scorerId": "exfa2ygjuuy08v50w65ddj2l1",
		"scorerName": "C. Lloyd",
		"assistPlayerId": null,
		"assistPlayerName": null
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 1,
		"timeMin": 26,
		"goalType": 0,
		"scorerId": "dve5pao9dmobh167wb8g45g45",
		"scorerName": "J. Ertz",
		"assistPlayerId": "6bbodw3m9bstocysxvzfuo3my",
		"assistPlayerName": "T. Davidson"
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 1,
		"timeMin": 35,
		"goalType": 0,
		"scorerId": "exfa2ygjuuy08v50w65ddj2l1",
		"scorerName": "C. Lloyd",
		"assistPlayerId": "6bbodw3m9bstocysxvzfuo3my",
		"assistPlayerName": "T. Davidson"
	}],
	"sponsor": "",
	"date": "2020-06-06T11:00:00",
	"time": "11:00:00Z",
	"cancelled": false,
	"lengthMin": 97,
	"lengthSec": 7,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "efcoydloex7y04q29pac9s67c",
		"longName": "Parc des Princes",
		"shortName": "Parc des Princes",
		"location": "Paris",
		"country": "France"
	},
	"attendance": 45594,
	"competition": {
		"id": "8x62utr2uti3i7kk14isbnip6",
		"name": "2019 FIFA Women's World Cup ",
		"code": "WWC",
		"format": "International cup",
		"startDate": "2019-06-07T00:00:00Z",
		"endDate": "2019-07-07T00:00:00Z",
		"isFriendly": false
	},
	"periods": [{
		"id": 1,
		"start": "2019-06-16T16:00:22Z",
		"end": "2019-06-16T16:47:23Z",
		"lengthMin": "47",
		"lengthSec": "1",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2019-06-16T17:03:08Z",
		"end": "2019-06-16T17:53:14Z",
		"lengthMin": "50",
		"lengthSec": "6",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "e70zl10x0ayu7y10ry0wi465a",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "home",
		"score": 3,
		"heroFlairTitle": "",
		"heroFlairName": "",
		"heroFlairBody": ""
	}, {
		"id": "ccnqvjwhoznpaakq2d4laefud",
		"name": "Chile",
		"officialName": "Chile",
		"shortName": "Chile",
		"code": "CHI",
		"position": "away",
		"score": 0,
		"heroFlairTitle": "",
		"heroFlairName": "",
		"heroFlairBody": ""
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": "Tickets",
		"url": "https://ussfwwc2019.squarespace.com/group-stage-match-23",
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00Z",
		"generalAvailabilityDate": "0001-01-01T00:00:00Z"
	},
	"groupTicketUrl": "",
	"groupTicketText": "",
	"matchFeedUrl": "/competitions/fifa-womens-world-cup-2019/matches/united-states-vs-chile",
	"sitecoreData": {
		"link1": {
			"value": {
				"href": "https://www.foxsports.com/mobile",
				"text": "FOX",
				"linktype": "external",
				"url": "https://www.foxsports.com/mobile",
				"anchor": "",
				"title": "FOX",
				"target": "_blank"
			}, "editable": null
		},
		"link2": {
			"value": {
				"href": "http://now.telemundo.com/live",
				"text": "TEL",
				"linktype": "external",
				"url": "http://now.telemundo.com/live",
				"anchor": "",
				"title": "https://www.telemundo.com",
				"target": "_blank"
			}, "editable": null
		},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {"value": {"href": ""}, "editable": null},
		"matchRecapLink": {
			"value": {
				"href": "https://www.ussoccer.com/stories/2019/06/usa-through-to-world-cup-knockout-rounds-with-3-0-victory-against-chile",
				"linktype": "external",
				"url": "https://www.ussoccer.com/stories/2019/06/usa-through-to-world-cup-knockout-rounds-with-3-0-victory-against-chile"
			}, "editable": null
		},
		"ticketsLink": {
			"value": {
				"href": "https://ussfwwc2019.squarespace.com/group-stage-match-23",
				"text": "Tickets",
				"linktype": "external",
				"url": "https://ussfwwc2019.squarespace.com/group-stage-match-23",
				"anchor": "",
				"title": "Tickets",
				"target": "_blank"
			}, "editable": null
		},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/wwcfrance2019backgroundp1.ashx?h=1200&la=en-US&w=3840&rev=f45ce10739234f589f36d39aca7e9510&hash=A256648079CC6A02F8070F882ED0DB53",
				"alt": "",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/wwcfrance2019backgroundcard.ashx?h=720&la=en-US&w=670&rev=751c0234ecbd48bfa5f60b6104526e60&hash=62233C6BBFA006927A82260DC14A3EFB",
				"alt": "WWC Background",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/fifa-womens-world-cup-2019"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/wnt/2019/jeboxingposterleft.ashx?h=1160&la=en-US&w=880&rev=6f0501cb5d4c46fc8d5c105d6664e6fb&hash=74EE43DA64C6FC7B08C5AEBC56A4E394",
				"alt": "Julie Ertz",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/wnt/2019/wnt-chile2-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=b85713ecdf904b1fad9954b28ce1ca31&hash=10C15F14A6ECBC8B33A8A73E839ACF21",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "", "editable": null},
		"stadiumLocation": {"value": "", "editable": null},
		"stadiumCountry": {"value": "", "editable": null},
		"broadcasterLinks": null,
		"currentItemId": "29183379-969e-456d-9d1b-5bca8bc360bc"
	},
	"dateTime": "2020-06-06T11:00:00Z",
	"dateTimeObject": "2020-06-06T11:00:00+00:00"
}, {
	"matchId": "2kdu4yc5c3tmjbdy5yi3cai5m",
	"description": "United States vs Japan",
	"tournamentCalendarId": "9ezrfzktloxtgs287zinindka",
	"tournamentCalendarName": "2020",
	"stageName": "Group Stage",
	"status": 0,
	"goals": [{
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 1,
		"timeMin": 7,
		"goalType": 0,
		"scorerId": "29vjl1iba9rz14yd1g9qi4pn9",
		"scorerName": "M. Rapinoe",
		"assistPlayerId": null,
		"assistPlayerName": null
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 1,
		"timeMin": 26,
		"goalType": 0,
		"scorerId": "bnxnrws4yintb6s76erzl9xw5",
		"scorerName": "C. Press",
		"assistPlayerId": "29vjl1iba9rz14yd1g9qi4pn9",
		"assistPlayerName": "M. Rapinoe"
	}, {
		"contestantId": "br892lrczd8pow3crz8nain5d",
		"periodId": 2,
		"timeMin": 58,
		"goalType": 0,
		"scorerId": "e6pdkq17etv9k527m94sqqu39",
		"scorerName": "M. Iwabuchi",
		"assistPlayerId": "e9chc4tsfvtb8xuj2t42gl51x",
		"assistPlayerName": "E. Nakajima"
	}, {
		"contestantId": "e70zl10x0ayu7y10ry0wi465a",
		"periodId": 2,
		"timeMin": 83,
		"goalType": 0,
		"scorerId": "6ywkny98b9qzuvhqxwn9l0ogl",
		"scorerName": "L. Horan",
		"assistPlayerId": "dkk9sopglpjgnopxcxkn65gid",
		"assistPlayerName": "M. Pugh"
	}],
	"sponsor": null,
	"date": "2020-03-12T00:00:00",
	"time": "00:00:00Z",
	"cancelled": false,
	"lengthMin": 93,
	"lengthSec": 22,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "86i7ztgeobez2lcwxl282817y",
		"longName": "Toyota Stadium",
		"shortName": "Toyota Stadium",
		"location": "Frisco, Texas",
		"country": "USA"
	},
	"attendance": 0,
	"competition": {
		"id": "dzdh9f7vyvzpq3nmvl22dyx2h",
		"name": "SheBelieves Cup",
		"code": "SBC",
		"format": "International cup",
		"startDate": "2020-03-05T00:00:00Z",
		"endDate": "2020-03-12T00:00:00Z",
		"isFriendly": true
	},
	"periods": [{
		"id": 1,
		"start": "2020-03-12T00:08:34Z",
		"end": "2020-03-12T00:53:34Z",
		"lengthMin": "45",
		"lengthSec": "0",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2020-03-12T01:08:43Z",
		"end": "2020-03-12T01:57:05Z",
		"lengthMin": "48",
		"lengthSec": "22",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "e70zl10x0ayu7y10ry0wi465a",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "home",
		"score": 3,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "br892lrczd8pow3crz8nain5d",
		"name": "Japan",
		"officialName": "Japan",
		"shortName": "Japan",
		"code": "JPN",
		"position": "away",
		"score": 1,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/shebelieves-cup-2020/matches/united-states-vs-japan",
	"sitecoreData": {
		"link1": {
			"value": {
				"href": "https://www.espn.com/watch/?type=live&channel=espnews",
				"text": "ESPNews",
				"linktype": "external",
				"url": "https://www.espn.com/watch/?type=live&channel=espnews",
				"anchor": "",
				"title": "ESPNews",
				"target": "_blank"
			}, "editable": null
		},
		"link2": {
			"value": {
				"href": "https://www.tudn.com/",
				"text": "TUDN XTRA 1",
				"linktype": "external",
				"url": "https://www.tudn.com/",
				"anchor": "",
				"title": "TUDN XTRA 1",
				"target": "_blank"
			}, "editable": null
		},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {"value": {"href": ""}, "editable": null},
		"matchRecapLink": {"value": {"href": ""}, "editable": null},
		"ticketsLink": {
			"value": {
				"href": "https://tix.axs.com/x3mzIgAAAADo6YC0AgAAAAC7%2fv%2f%2f%2fwD%2f%2f%2f%2f%2fCEZDRGFsbGFzAP%2f%2f%2f%2f%2f%2f%2f%2f%2f%2f",
				"text": "Tickets",
				"linktype": "external",
				"url": "https://tix.axs.com/x3mzIgAAAADo6YC0AgAAAAC7%2fv%2f%2f%2fwD%2f%2f%2f%2f%2fCEZDRGFsbGFzAP%2f%2f%2f%2f%2f%2f%2f%2f%2f%2f",
				"anchor": "",
				"title": "Tickets",
				"target": ""
			}, "editable": null
		},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/wwcfrance2019backgroundp1.ashx?h=1200&la=en-US&w=3840&rev=f45ce10739234f589f36d39aca7e9510&hash=A256648079CC6A02F8070F882ED0DB53",
				"alt": "",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/competitions/wwcfrance2019backgroundcard.ashx?h=720&la=en-US&w=670&rev=751c0234ecbd48bfa5f60b6104526e60&hash=62233C6BBFA006927A82260DC14A3EFB",
				"alt": "WWC Background",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/shebelieves-cup-2020"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/wnt/2019/press-white-playernameboxingposterleft.ashx?h=1160&la=en-US&w=880&rev=46d9837412a142649626f0163230a82e&hash=0FE4B4FB3BFBA27A43A3BAB6DF3BFD1F",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/wnt/2019/dunn-white-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=d6e14c45aa3b4471b1b8ec5578311d05&hash=66839AB2F5569D2938351E617C0F37F3",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "Presented by Visa", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "", "editable": null},
		"stadiumLocation": {"value": "", "editable": null},
		"stadiumCountry": {"value": "", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogoespnewsblack2x.ashx?h=52&la=en-US&w=120&rev=9db0f295b5a34103ae7b56162830e964&hash=EF6F03B7E632AB1AFD83051477FFBCF9",
					"alt": "ESPNEWS",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.espn.com/watch/?type=live&channel=espnews", "editable": null},
			"iosUrl": {"value": "https://apps.apple.com/us/app/espn-scorecenter/id317469184", "editable": null},
			"androidUrl": {"value": "https://play.google.com/store/apps/details?id=com.espn.score_center", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/app/broadcasters/tdnextra1logo.ashx?h=52&la=en-US&w=120&rev=e7cc33f2fea64dbbbdd27543a289ebff&hash=9A57C0DE7CB46E8E5EDBBCBE337979C7",
					"alt": "",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.tudn.com/", "editable": null},
			"iosUrl": {"value": "https://www.tudn.com/", "editable": null},
			"androidUrl": {"value": "https://www.tudn.com/", "editable": null}
		}],
		"currentItemId": "f2cf2d13-9d5a-426f-b175-6f8885350cec"
	},
	"dateTime": "2020-03-12T00:00:00Z",
	"dateTimeObject": "2020-03-12T00:00:00+00:00"
}, {
	"matchId": "7hs4mkg9gtxga5scit1a2um8k",
	"description": "United States vs Honduras",
	"tournamentCalendarId": "8atuetifgnlt3q6oprwn7x9pm",
	"tournamentCalendarName": "2022 Qatar",
	"stageName": "Final Round",
	"status": 2,
	"goals": [{
		"contestantId": "9vh2u1p4ppm597tjfahst2m3n",
		"periodId": 1,
		"timeMin": 8,
		"goalType": 0,
		"scorerId": "288nn7pbq5ewriiyb9o7da86h",
		"scorerName": "W. McKennie",
		"assistPlayerId": "6k22ppzw6wv0c4gskzxk28fyt",
		"assistPlayerName": "K. Acosta"
	}, {
		"contestantId": "9vh2u1p4ppm597tjfahst2m3n",
		"periodId": 1,
		"timeMin": 37,
		"goalType": 0,
		"scorerId": "eooivgc2bc7bdbl1gq784ehn9",
		"scorerName": "W. Zimmerman",
		"assistPlayerId": null,
		"assistPlayerName": null
	}, {
		"contestantId": "9vh2u1p4ppm597tjfahst2m3n",
		"periodId": 2,
		"timeMin": 67,
		"goalType": 0,
		"scorerId": "93soe2rjn0z874cc9ofcesgkp",
		"scorerName": "C. Pulisic",
		"assistPlayerId": "eooivgc2bc7bdbl1gq784ehn9",
		"assistPlayerName": "W. Zimmerman"
	}],
	"sponsor": "Presented by VW",
	"date": "2023-12-03T00:30:00",
	"time": "00:30:00Z",
	"cancelled": false,
	"lengthMin": 91,
	"lengthSec": 53,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "4r577lkiom42o39a9llb0jm22",
		"longName": "Allianz Field",
		"shortName": "Allianz Field",
		"location": "St Paul, Minnesota",
		"country": "United States"
	},
	"attendance": 19202,
	"competition": {
		"id": "3a0j0giz3c3ajw9h59evv7lqt",
		"name": "Concacaf FIFA World Cup Qualifying 2022",
		"code": "WQC",
		"format": "International cup",
		"startDate": "2021-03-24T00:00:00Z",
		"endDate": "2022-03-31T00:00:00Z",
		"isFriendly": false
	},
	"periods": [{
		"id": 1,
		"start": "2022-02-03T00:31:38Z",
		"end": "2022-02-03T01:18:03Z",
		"lengthMin": "46",
		"lengthSec": "25",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2022-02-03T01:37:06Z",
		"end": "2022-02-03T02:22:34Z",
		"lengthMin": "45",
		"lengthSec": "28",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "9vh2u1p4ppm597tjfahst2m3n",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "home",
		"score": 3,
		"heroFlairTitle": "",
		"heroFlairName": "",
		"heroFlairBody": ""
	}, {
		"id": "6ngz19ekzqqufux1sswnhzlsw",
		"name": "Honduras",
		"officialName": "Honduras",
		"shortName": "Honduras",
		"code": "HON",
		"position": "away",
		"score": 0,
		"heroFlairTitle": "",
		"heroFlairName": "",
		"heroFlairBody": ""
	}],
	"broadcastLinks": [{
		"imageURL": "https://qa-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogofs1black2x.ashx?h=52&la=en-US&w=120&rev=86ca98edbfb2498a9436c283052a960f&hash=84125839BDAFADF0C8E80090E3A99D28",
		"imageAltText": "FS1",
		"broadcastURLWeb": "https://www.foxsports.com/live/fs1",
		"broadcastURLAndroid": "https://www.foxsports.com/mobile",
		"broadcastURLiOS": "https://www.foxsports.com/mobile"
	}, {
		"imageURL": "https://qa-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogounivisionblack2x.ashx?h=52&la=en-US&w=120&rev=612fe1da7a9a4ed19e8923d2a765fca2&hash=AEDDB054FE5D9EB526C26D5FEFDB97DE",
		"imageAltText": "UNIVISION",
		"broadcastURLWeb": "https://www.univisionnow.com/channels",
		"broadcastURLAndroid": "https://www.univisionnow.com/channels",
		"broadcastURLiOS": "https://www.univisionnow.com/channels"
	}, {
		"imageURL": null,
		"imageAltText": "Watch",
		"broadcastURLWeb": "https://www.tudn.com/",
		"broadcastURLAndroid": "https://www.tudn.com/",
		"broadcastURLiOS": "https://www.tudn.com/"
	}],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": "Tickets",
		"url": "https://www.ticketmaster.com/event/06005B819FB11F40",
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00Z",
		"generalAvailabilityDate": "0001-01-01T00:00:00Z"
	},
	"groupTicketUrl": "",
	"groupTicketText": "",
	"matchFeedUrl": "/competitions/concacaf-world-cup-qualifying-2022/matches/united-states-vs-honduras-2-2-22",
	"sitecoreData": {
		"link1": {"value": {"href": ""}, "editable": null},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {"value": {"href": ""}, "editable": null},
		"matchRecapLink": {"value": {"href": ""}, "editable": null},
		"ticketsLink": {
			"value": {
				"href": "https://www.ticketmaster.com/event/06005B819FB11F40",
				"text": "Tickets",
				"linktype": "external",
				"url": "https://www.ticketmaster.com/event/06005B819FB11F40",
				"anchor": "",
				"target": "_blank"
			}, "editable": null
		},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/backgroundred.ashx?h=1200&la=en-US&w=3840&rev=a73df9006659468d9b7fc9fe45452d72&hash=99DD665448A183451AFA8B9117FE5205",
				"alt": "",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/cardred.ashx?h=720&la=en-US&w=670&rev=18fd56e215454fc9971223f6e38a577d&hash=F78F6ED6B0027EF6121A12133AF0F1E1",
				"alt": "Red Chevron Background Pattern",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/concacaf-world-cup-qualifying-2022"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/cutouts/mnt/weston-white-boxing-left-png.ashx?h=1160&la=en-US&w=880&rev=295eeec8ee044710acc7b3a42defe2d5&hash=1F7B2B6925B4136ACFAB5A0058EDFA20",
				"alt": "Weston McKennie",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/cutouts/mnt/walker-zimmerman-red-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=32333ace7c704eaaaa87c7f0b1447521&hash=97DC8C2E517955EC983E398A9F1FA9ED",
				"alt": "Walker Zimmerman",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "Presented by VW", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "Allianz Field", "editable": null},
		"stadiumLocation": {"value": "St Paul, Minnesota", "editable": null},
		"stadiumCountry": {"value": "United States", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogofs1black2x.ashx?h=52&la=en-US&w=120&rev=79d1fc98d79c4aa582431482dc9188aa&hash=222581B287E6DC17C26116C6B3C28131",
					"alt": "FS1",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.foxsports.com/live/fs1", "editable": null},
			"iosUrl": {"value": "https://www.foxsports.com/mobile", "editable": null},
			"androidUrl": {"value": "https://www.foxsports.com/mobile", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogounivisionblack2x.ashx?h=164&la=en-US&w=460&rev=0b74caaf17624eba9f02762e01758751&hash=1D2B578C26EF9C8B3CA5607C76C51DDD",
					"alt": "UNIVISION",
					"width": "460",
					"height": "164"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.univisionnow.com/channels", "editable": null},
			"iosUrl": {"value": "https://www.univisionnow.com/channels", "editable": null},
			"androidUrl": {"value": "https://www.univisionnow.com/channels", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tundlogo.ashx?h=52&la=en-US&w=120&rev=9cc1f63328c54407a1efc533b869bdc5&hash=8A3D704527709F5BB4D4149320DFB4DC",
					"alt": "TUDN",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.tudn.com/", "editable": null},
			"iosUrl": {"value": "https://www.tudn.com/", "editable": null},
			"androidUrl": {"value": "https://www.tudn.com/", "editable": null}
		}],
		"currentItemId": "51f45e22-a315-4ee1-a9ca-14caf00bf09e"
	},
	"dateTime": "2023-12-03T00:30:00Z",
	"dateTimeObject": "2023-12-03T00:30:00+00:00"
}, {
	"matchId": "7eviy1t4d9ulukx8jvdgm4e1g",
	"description": "United States vs Jamaica",
	"tournamentCalendarId": "8atuetifgnlt3q6oprwn7x9pm",
	"tournamentCalendarName": "2022 Qatar",
	"stageName": "Final Round",
	"status": 2,
	"goals": [{
		"contestantId": "9vh2u1p4ppm597tjfahst2m3n",
		"periodId": 2,
		"timeMin": 49,
		"goalType": 0,
		"scorerId": "emglxnqrlqskt8krglv7sziwa",
		"scorerName": "R. Pepi",
		"assistPlayerId": "b0me6l44boibnwpbtasxoy02x",
		"assistPlayerName": "S. Dest"
	}, {
		"contestantId": "9vh2u1p4ppm597tjfahst2m3n",
		"periodId": 2,
		"timeMin": 62,
		"goalType": 0,
		"scorerId": "emglxnqrlqskt8krglv7sziwa",
		"scorerName": "R. Pepi",
		"assistPlayerId": "8rrcscn29rs1gk8mi1pwpu92i",
		"assistPlayerName": "B. Aaronson"
	}],
	"sponsor": "",
	"date": "2023-10-07T23:30:00",
	"time": "23:30:00Z",
	"cancelled": false,
	"lengthMin": 95,
	"lengthSec": 3,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "5ylpm7oz3c8tplmcb4nisv6dw",
		"longName": "Q2 Stadium",
		"shortName": "Q2 Stadium",
		"location": "Austin, Texas",
		"country": "USA"
	},
	"attendance": 0,
	"competition": {
		"id": "3a0j0giz3c3ajw9h59evv7lqt",
		"name": "WC Qualification Concacaf",
		"code": "WQC",
		"format": "International cup",
		"startDate": "2021-03-24T00:00:00Z",
		"endDate": "2022-03-30T00:00:00Z",
		"isFriendly": false
	},
	"periods": [{
		"id": 1,
		"start": "2021-10-07T23:45:43Z",
		"end": "2021-10-08T00:32:41Z",
		"lengthMin": "46",
		"lengthSec": "58",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2021-10-08T00:48:13Z",
		"end": "2021-10-08T01:36:18Z",
		"lengthMin": "48",
		"lengthSec": "5",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "9vh2u1p4ppm597tjfahst2m3n",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "home",
		"score": 2,
		"heroFlairTitle": "",
		"heroFlairName": "",
		"heroFlairBody": ""
	}, {
		"id": "8yhjl13p7luh3v905btbvz42t",
		"name": "Jamaica",
		"officialName": "Jamaica",
		"shortName": "Jamaica",
		"code": "JAM",
		"position": "away",
		"score": 0,
		"heroFlairTitle": "",
		"heroFlairName": "",
		"heroFlairBody": ""
	}],
	"broadcastLinks": [{
		"imageURL": "https://cdn.ussoccer.com/-/media/project/ussf/app/broadcasters/espn2_logo.ashx?h=52&la=en-US&w=120&rev=8b583676054f469c997298102fc43568&hash=495D7043006AA51910F46539C08D4624",
		"imageAltText": "ESPN2",
		"broadcastURLWeb": "https://www.espn.com/watch/?type=live&channel=espn2",
		"broadcastURLAndroid": "https://play.google.com/store/apps/details?id=com.espn.score_center",
		"broadcastURLiOS": "https://apps.apple.com/us/app/espn-scorecenter/id317469184"
	}, {
		"imageURL": "https://cdn.ussoccer.com/-/media/project/ussf/logos/ussf-logos/broadcasters/tundlogo.ashx?h=52&la=en-US&w=120&rev=43a69b8a0ca94cccbfcd2facca429e4f&hash=3C32097BB45E1945D77E39AC0FE13B8D",
		"imageAltText": "TUDN",
		"broadcastURLWeb": "https://www.tudn.com/",
		"broadcastURLAndroid": "https://www.tudn.com/",
		"broadcastURLiOS": "https://www.tudn.com/"
	}, {
		"imageURL": "https://cdn.ussoccer.com/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogounivisionblack2x.ashx?h=52&la=en-US&w=120&rev=74afa315b7004bcd9eca46492207982c&hash=9111B157D46AB86A17876503A61DB415",
		"imageAltText": "UniMAS",
		"broadcastURLWeb": "https://www.univisionnow.com/channels",
		"broadcastURLAndroid": "https://www.univisionnow.com/channels",
		"broadcastURLiOS": "https://www.univisionnow.com/channels"
	}],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": "tickets",
		"url": "https://www.ticketmaster.com/usmnt-v-jamaica-2022-fifa-world-austin-texas-10-07-2021/event/3A005AFDB5D964B0",
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00Z",
		"generalAvailabilityDate": "0001-01-01T00:00:00Z"
	},
	"groupTicketUrl": "",
	"groupTicketText": "",
	"matchFeedUrl": "/competitions/concacaf-world-cup-qualifying-2022/matches/united-states-vs-jamaica",
	"sitecoreData": {
		"link1": {"value": {"href": ""}, "editable": null},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {
			"value": {
				"href": "/stories/2021/10/2022-concacaf-world-cup-qualifying-usa-vs-jamaica-preview-schedule-tv-channels-start-time",
				"querystring": "",
				"target": "",
				"linktype": "internal",
				"id": "{2E89EADD-1AEC-420F-8BFF-BB79B064654E}",
				"url": "/USSF/Home/Stories/2021/10/2022 Concacaf World Cup Qualifying USA vs Jamaica Preview Schedule TV Channels Start Time"
			}, "editable": null
		},
		"matchRecapLink": {
			"value": {
				"href": "/stories/2021/10/2022-concacaf-world-cup-qualifying-usa-2-jamaica-0-match-report-stats-standings",
				"querystring": "",
				"target": "",
				"linktype": "internal",
				"id": "{CE3F9BD1-8F0E-4EF3-9AAD-FDD0986D2FBD}",
				"url": "/USSF/Home/Stories/2021/10/2022 Concacaf World Cup Qualifying USA 2 Jamaica 0 Match Report Stats Standings"
			}, "editable": null
		},
		"ticketsLink": {
			"value": {
				"href": "https://www.ticketmaster.com/usmnt-v-jamaica-2022-fifa-world-austin-texas-10-07-2021/event/3A005AFDB5D964B0",
				"text": "tickets",
				"linktype": "external",
				"url": "https://www.ticketmaster.com/usmnt-v-jamaica-2022-fifa-world-austin-texas-10-07-2021/event/3A005AFDB5D964B0",
				"anchor": "",
				"target": "_blank"
			}, "editable": null
		},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/backgroundred.ashx?h=1200&la=en-US&w=3840&rev=a73df9006659468d9b7fc9fe45452d72&hash=99DD665448A183451AFA8B9117FE5205",
				"alt": "",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/cardred.ashx?h=720&la=en-US&w=670&rev=18fd56e215454fc9971223f6e38a577d&hash=F78F6ED6B0027EF6121A12133AF0F1E1",
				"alt": "Red Chevron Background Pattern",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/concacaf-world-cup-qualifying-2022"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/cutouts/mnt/aaronson-white-playernameboxingposterleft.ashx?h=1160&la=en-US&w=880&rev=a55073365717424f8cfa329ff60db0ed&hash=488F99C9ACAAD77C021EF6EC073DE417",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/cutouts/mnt/adams-white-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=4d1d57e1522f424a96b24862418268ab&hash=1B8D42E508857F8F422370CBB4C20C3B",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "Q2 Stadium", "editable": null},
		"stadiumLocation": {"value": "Austin, Texas", "editable": null},
		"stadiumCountry": {"value": "USA", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/app/broadcasters/espn2_logo.ashx?h=52&la=en-US&w=120&rev=8b583676054f469c997298102fc43568&hash=495D7043006AA51910F46539C08D4624",
					"alt": "ESPN2",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.espn.com/watch/?type=live&channel=espn2", "editable": null},
			"iosUrl": {"value": "https://apps.apple.com/us/app/espn-scorecenter/id317469184", "editable": null},
			"androidUrl": {"value": "https://play.google.com/store/apps/details?id=com.espn.score_center", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/app/broadcasters/espn-plus-logo.ashx?h=102&la=en-US&w=279&rev=38498fb15530436eb56e83bd2ef0401c&hash=4AC50301D2633C6CA0C0FC0D52A695E4",
					"alt": "ESPN+",
					"width": "279",
					"height": "102"
				}, "editable": null
			},
			"webUrl": {
				"value": "https://plus.espn.com/?ex_cid=DSS-Search-Google-71700000068344301-&s_kwcid=AL!8468!3!495087148015!e!!g!!espn%20plus&cid=DSS-Search-Google-71700000068344301-&gclid=CjwKCAjwkvWKBhB4EiwA-GHjFmIe-aWoIQXmQA3zfn56VqWP--ySFd41sgrOR8S3hUQWsY-5nYAwBhoCwVQQAvD_BwE&gclsrc=aw.ds",
				"editable": null
			},
			"iosUrl": {"value": "https://apps.apple.com/us/app/espn-scorecenter/id317469184", "editable": null},
			"androidUrl": {"value": "https://play.google.com/store/apps/details?id=com.espn.score_center", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogounivisionblack2x.ashx?h=164&la=en-US&w=460&rev=0b74caaf17624eba9f02762e01758751&hash=1D2B578C26EF9C8B3CA5607C76C51DDD",
					"alt": "UniMAS",
					"width": "460",
					"height": "164"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.univisionnow.com/channels", "editable": null},
			"iosUrl": {"value": "https://www.univisionnow.com/channels", "editable": null},
			"androidUrl": {"value": "https://www.univisionnow.com/channels", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tundlogo.ashx?h=52&la=en-US&w=120&rev=9cc1f63328c54407a1efc533b869bdc5&hash=8A3D704527709F5BB4D4149320DFB4DC",
					"alt": "TUDN",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.tudn.com/", "editable": null},
			"iosUrl": {"value": "https://www.tudn.com/", "editable": null},
			"androidUrl": {"value": "https://www.tudn.com/", "editable": null}
		}],
		"currentItemId": "e05793a7-77ca-4e16-97a6-b87e69c91fb2"
	},
	"dateTime": "2023-10-07T23:30:00Z",
	"dateTimeObject": "2023-10-07T23:30:00+00:00"
}, {
	"matchId": "5ura3gks64lr3qd4j4cwugdg4",
	"description": "Grenada vs United States",
	"tournamentCalendarId": "84lurtafp6kpmrh1tdrjwq1hw",
	"tournamentCalendarName": "2022/2023",
	"stageName": "League A",
	"status": 0,
	"goals": [{
		"contestantId": "9vh2u1p4ppm597tjfahst2m3n",
		"periodId": 1,
		"timeMin": 4,
		"goalType": 0,
		"scorerId": "emglxnqrlqskt8krglv7sziwa",
		"scorerName": "R. Pepi",
		"assistPlayerId": "93soe2rjn0z874cc9ofcesgkp",
		"assistPlayerName": "C. Pulisic"
	}, {
		"contestantId": "9vh2u1p4ppm597tjfahst2m3n",
		"periodId": 1,
		"timeMin": 20,
		"goalType": 0,
		"scorerId": "8rrcscn29rs1gk8mi1pwpu92i",
		"scorerName": "B. Aaronson",
		"assistPlayerId": "93soe2rjn0z874cc9ofcesgkp",
		"assistPlayerName": "C. Pulisic"
	}, {
		"contestantId": "9vh2u1p4ppm597tjfahst2m3n",
		"periodId": 1,
		"timeMin": 31,
		"goalType": 0,
		"scorerId": "288nn7pbq5ewriiyb9o7da86h",
		"scorerName": "W. McKennie",
		"assistPlayerId": null,
		"assistPlayerName": null
	}, {
		"contestantId": "88jwqq14es3obmtaxvj042tnv",
		"periodId": 1,
		"timeMin": 32,
		"goalType": 0,
		"scorerId": "c840oaaw59arinkzu4cqql9ed",
		"scorerName": "M. Hippolyte",
		"assistPlayerId": "1qgtx5es7s1lwfhcruy6c26p6",
		"assistPlayerName": "J. Berkeley-Agyepong"
	}, {
		"contestantId": "9vh2u1p4ppm597tjfahst2m3n",
		"periodId": 1,
		"timeMin": 34,
		"goalType": 0,
		"scorerId": "288nn7pbq5ewriiyb9o7da86h",
		"scorerName": "W. McKennie",
		"assistPlayerId": "3bvr4z5z4cyb8fadfwtgxrx0p",
		"assistPlayerName": "A. Trusty"
	}],
	"sponsor": null,
	"date": "2023-03-25T00:00:00",
	"time": "00:00:00Z",
	"cancelled": false,
	"lengthMin": 90,
	"lengthSec": 0,
	"addedTimeForCurrentPeriod": 1,
	"venue": {
		"id": "2d6b3wlp50spcm6ngprkidbkl",
		"longName": "Kirani James National Stadium",
		"shortName": "Kirani James National Stadium",
		"location": "St. George's",
		"country": "Grenada"
	},
	"attendance": 0,
	"competition": {
		"id": "cu0rmpyff5692eo06ltddjo8a",
		"name": "Concacaf Nations League",
		"code": "CNL",
		"format": "International cup",
		"startDate": "2022-06-02T00:00:00Z",
		"endDate": "2023-06-20T00:00:00Z",
		"isFriendly": false
	},
	"periods": [{
		"id": 1,
		"start": "2023-03-25T00:00:36Z",
		"end": "2023-03-25T00:46:37Z",
		"lengthMin": "46",
		"lengthSec": "1",
		"addedTime": 1
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "88jwqq14es3obmtaxvj042tnv",
		"name": "Grenada",
		"officialName": "Grenada",
		"shortName": "Grenada",
		"code": "GRN",
		"position": "home",
		"score": 1,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "9vh2u1p4ppm597tjfahst2m3n",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "away",
		"score": 4,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/concacaf-nations-league-2022-2023/matches/grenada-vs-united-states-3-24-23",
	"sitecoreData": {
		"link1": {"value": {"href": ""}, "editable": null},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {
			"value": {
				"href": "https://www.ussoccer.com/stories/2023/03/2022-2023-concacaf-nations-league-usmnt-vs-grenada-preview-tv-channels-start-time",
				"linktype": "external",
				"url": "https://www.ussoccer.com/stories/2023/03/2022-2023-concacaf-nations-league-usmnt-vs-grenada-preview-tv-channels-start-time"
			}, "editable": null
		},
		"matchRecapLink": {"value": {"href": ""}, "editable": null},
		"ticketsLink": {"value": {"href": ""}, "editable": null},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/backgroundred.ashx?h=1200&la=en-US&w=3840&rev=a73df9006659468d9b7fc9fe45452d72&hash=99DD665448A183451AFA8B9117FE5205",
				"alt": "",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/cardred.ashx?h=720&la=en-US&w=670&rev=18fd56e215454fc9971223f6e38a577d&hash=F78F6ED6B0027EF6121A12133AF0F1E1",
				"alt": "Red Chevron Background Pattern",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/concacaf-nations-league-2022-2023"
		},
		"backgroundImage": null,
		"playerCutoutHome": {"value": {}, "editable": null},
		"playerCutoutAway": {"value": {}, "editable": null},
		"sponsorText": {"value": "", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "Kirani James National Stadium", "editable": null},
		"stadiumLocation": {"value": "St George's", "editable": null},
		"stadiumCountry": {"value": "Grenada", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/broadcasters/warnermedia_core_global_black_tnt_v2.ashx?h=128&la=en-US&w=128&rev=706093fb71854370a6ef0c9f389effbc&hash=563B8A11879DF1792A2155B49BA3962F",
					"alt": "",
					"width": "128",
					"height": "128"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.tntdrama.com/watchtnt", "editable": null},
			"iosUrl": {"value": "https://www.tntdrama.com/watchtnt", "editable": null},
			"androidUrl": {"value": "https://www.tntdrama.com/watchtnt", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogouniversoblack2x.ashx?h=52&la=en-US&w=120&rev=e8fd76c116cd408195c3374e4ecbc87c&hash=F88742E24C3283C03874ABD4661E786F",
					"alt": "UNIVERSO",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.nbc.com/networks/nbc-universo", "editable": null},
			"iosUrl": {"value": "https://apps.apple.com/us/app/universo-now/id869444353", "editable": null},
			"androidUrl": {
				"value": "https://play.google.com/store/apps/details?id=com.nbcu.tve.universo&hl=en_US&gl=US",
				"editable": null
			}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/broadcasters/peacocklogov2rgbblack.ashx?h=37&la=en-US&w=120&rev=0a57f2ea708449538077af736b08947d&hash=7E6EE2BA895192CB12E6E5D04882D721",
					"alt": "",
					"width": "120",
					"height": "37"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.peacocktv.com/watch/home", "editable": null},
			"iosUrl": {"value": "https://www.peacocktv.com/watch/home", "editable": null},
			"androidUrl": {"value": "https://www.peacocktv.com/watch/home", "editable": null}
		}],
		"currentItemId": "d28c99a3-9c79-46fc-a702-940afe203ba3"
	},
	"dateTime": "2023-03-25T00:00:00Z",
	"dateTimeObject": "2023-03-25T00:00:00+00:00"
}, {
	"matchId": "3yeo9n9dkr75f70bo9lcosewa",
	"description": "Netherlands vs United States",
	"tournamentCalendarId": "2a8elwzsufmguwymxbshcx756",
	"tournamentCalendarName": "2022 Qatar",
	"stageName": "ROUND OF 16",
	"status": 0,
	"goals": [{
		"contestantId": "657oha8nkne1ybba2bouzkbo7",
		"periodId": 1,
		"timeMin": 10,
		"goalType": 0,
		"scorerId": "b37h0rc96jh4e34x6iru2plud",
		"scorerName": "M. Depay",
		"assistPlayerId": "2r6hs8npijvt4wekd059t6tx",
		"assistPlayerName": "D. Dumfries"
	}, {
		"contestantId": "657oha8nkne1ybba2bouzkbo7",
		"periodId": 1,
		"timeMin": 46,
		"goalType": 0,
		"scorerId": "3kx4mltrob4yta5g6kr2u8ic5",
		"scorerName": "D. Blind",
		"assistPlayerId": "2r6hs8npijvt4wekd059t6tx",
		"assistPlayerName": "D. Dumfries"
	}, {
		"contestantId": "9vh2u1p4ppm597tjfahst2m3n",
		"periodId": 2,
		"timeMin": 76,
		"goalType": 0,
		"scorerId": "xvcq4pdg6xecz1cwhj7qvhm1",
		"scorerName": "H. Wright",
		"assistPlayerId": "93soe2rjn0z874cc9ofcesgkp",
		"assistPlayerName": "C. Pulisic"
	}, {
		"contestantId": "657oha8nkne1ybba2bouzkbo7",
		"periodId": 2,
		"timeMin": 81,
		"goalType": 0,
		"scorerId": "2r6hs8npijvt4wekd059t6tx",
		"scorerName": "D. Dumfries",
		"assistPlayerId": "3kx4mltrob4yta5g6kr2u8ic5",
		"assistPlayerName": "D. Blind"
	}],
	"sponsor": null,
	"date": "2022-12-03T15:00:00",
	"time": "15:00:00Z",
	"cancelled": false,
	"lengthMin": 97,
	"lengthSec": 51,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "x2io384gn9dj0kya08mj5k1u",
		"longName": "Khalifa International Stadium",
		"shortName": "Khalifa International Stadium",
		"location": "Al Rayyan (Ar-Rayyan)",
		"country": "Qatar"
	},
	"attendance": 44846,
	"competition": {
		"id": "70excpe1synn9kadnbppahdn7",
		"name": "FIFA World Cup Qatar 2022",
		"code": "WOC",
		"format": "International cup",
		"startDate": "2022-11-20T00:00:00Z",
		"endDate": "2022-12-18T00:00:00Z",
		"isFriendly": false
	},
	"periods": [{
		"id": 1,
		"start": "2022-12-03T15:00:11Z",
		"end": "2022-12-03T15:46:51Z",
		"lengthMin": "46",
		"lengthSec": "40",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2022-12-03T16:01:52Z",
		"end": "2022-12-03T16:53:03Z",
		"lengthMin": "51",
		"lengthSec": "11",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "657oha8nkne1ybba2bouzkbo7",
		"name": "Netherlands",
		"officialName": "Netherlands",
		"shortName": "Netherlands",
		"code": "NED",
		"position": "home",
		"score": 3,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "9vh2u1p4ppm597tjfahst2m3n",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "away",
		"score": 1,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/fifa-world-cup-qatar-2022/matches/netherlands-vs-united-states-12-3-22",
	"sitecoreData": {
		"link1": {"value": {"href": ""}, "editable": null},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {"value": {"href": ""}, "editable": null},
		"matchRecapLink": {"value": {"href": ""}, "editable": null},
		"ticketsLink": {"value": {"href": ""}, "editable": null},
		"brandedBackground": {"value": {}, "editable": null},
		"brandedBackgroundCard": {"value": {}, "editable": null},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/fifa-world-cup-qatar-2022"
		},
		"backgroundImage": null,
		"playerCutoutHome": {"value": {}, "editable": null},
		"playerCutoutAway": {"value": {}, "editable": null},
		"sponsorText": {"value": "", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "", "editable": null},
		"stadiumLocation": {"value": "", "editable": null},
		"stadiumCountry": {"value": "", "editable": null},
		"broadcasterLinks": null,
		"currentItemId": "ebacd890-146c-433b-84e2-6785d0fee0f7"
	},
	"dateTime": "2022-12-03T15:00:00Z",
	"dateTimeObject": "2022-12-03T15:00:00+00:00"
}, {
	"matchId": "2m2oexgxa7jsm96xazvx2gf10",
	"description": "Iran vs United States",
	"tournamentCalendarId": "2a8elwzsufmguwymxbshcx756",
	"tournamentCalendarName": "2022 Qatar",
	"stageName": "Group Stage",
	"status": 0,
	"goals": [{
		"contestantId": "9vh2u1p4ppm597tjfahst2m3n",
		"periodId": 1,
		"timeMin": 38,
		"goalType": 0,
		"scorerId": "93soe2rjn0z874cc9ofcesgkp",
		"scorerName": "C. Pulisic",
		"assistPlayerId": "b0me6l44boibnwpbtasxoy02x",
		"assistPlayerName": "S. Dest"
	}],
	"sponsor": null,
	"date": "2022-11-29T19:00:00",
	"time": "19:00:00Z",
	"cancelled": false,
	"lengthMin": 106,
	"lengthSec": 57,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "53k2jruppictgbhfjjbf0yg6i",
		"longName": "Al Thumama Stadium",
		"shortName": "Al Thumama Stadium",
		"location": "ad-Dōha (Doha)",
		"country": "Qatar"
	},
	"attendance": 42127,
	"competition": {
		"id": "70excpe1synn9kadnbppahdn7",
		"name": "FIFA World Cup Qatar 2022",
		"code": "WOC",
		"format": "International cup",
		"startDate": "2022-11-20T00:00:00Z",
		"endDate": "2022-12-18T00:00:00Z",
		"isFriendly": false
	},
	"periods": [{
		"id": 1,
		"start": "2022-11-29T19:00:39Z",
		"end": "2022-11-29T19:52:43Z",
		"lengthMin": "52",
		"lengthSec": "4",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2022-11-29T20:07:52Z",
		"end": "2022-11-29T21:02:45Z",
		"lengthMin": "54",
		"lengthSec": "53",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "cubclbw5k5gdqdau95bnav0nn",
		"name": "Iran",
		"officialName": "Iran",
		"shortName": "Iran",
		"code": "IRN",
		"position": "home",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "9vh2u1p4ppm597tjfahst2m3n",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "away",
		"score": 1,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/fifa-world-cup-qatar-2022/matches/iran-vs-united-states-11-29-22",
	"sitecoreData": {
		"link1": {"value": {"href": ""}, "editable": null},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {"value": {"href": ""}, "editable": null},
		"matchRecapLink": {"value": {"href": ""}, "editable": null},
		"ticketsLink": {"value": {"href": ""}, "editable": null},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/backgroundred.ashx?h=1200&la=en-US&w=3840&rev=a73df9006659468d9b7fc9fe45452d72&hash=99DD665448A183451AFA8B9117FE5205",
				"alt": "",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/cardred.ashx?h=720&la=en-US&w=670&rev=18fd56e215454fc9971223f6e38a577d&hash=F78F6ED6B0027EF6121A12133AF0F1E1",
				"alt": "Red Chevron Background Pattern",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/fifa-world-cup-qatar-2022"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/cutouts/mnt/weston-white-boxing-left-png.ashx?h=1160&la=en-US&w=880&rev=295eeec8ee044710acc7b3a42defe2d5&hash=1F7B2B6925B4136ACFAB5A0058EDFA20",
				"alt": "Weston McKennie",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/cutouts/mnt/adams-white-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=4d1d57e1522f424a96b24862418268ab&hash=1B8D42E508857F8F422370CBB4C20C3B",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "Al Thumama", "editable": null},
		"stadiumLocation": {"value": "Doha", "editable": null},
		"stadiumCountry": {"value": "Qatar", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/app/broadcasters/tvlogofoxblack2x.ashx?h=52&la=en-US&w=120&rev=1dd8458ff07b4d02a856c09c91032169&hash=8D532373DF96AA9D153CC4CF0A527D8A",
					"alt": "FOX",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.foxsports.com/live", "editable": null},
			"iosUrl": {"value": "https://www.foxsports.com/mobile", "editable": null},
			"androidUrl": {"value": "https://www.foxsports.com/mobile", "editable": null}
		}],
		"currentItemId": "7d0ff62d-3c0a-4bf0-80fd-57cdec90aa84"
	},
	"dateTime": "2022-11-29T19:00:00Z",
	"dateTimeObject": "2022-11-29T19:00:00+00:00"
}, {
	"matchId": "dfpxwqzmpkuhaqmkw14d9vaxg",
	"description": "England vs United States",
	"tournamentCalendarId": "2a8elwzsufmguwymxbshcx756",
	"tournamentCalendarName": "2022 Qatar",
	"stageName": "Group Stage",
	"status": 0,
	"goals": [],
	"sponsor": null,
	"date": "2022-11-25T19:00:00",
	"time": "19:00:00Z",
	"cancelled": false,
	"lengthMin": 95,
	"lengthSec": 55,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "4qp4xb6wue95zgjwkt9tx0rmx",
		"longName": "Al Bayt Stadium",
		"shortName": "Al Bayt Stadium",
		"location": "Al Khor",
		"country": "Qatar"
	},
	"attendance": 68463,
	"competition": {
		"id": "70excpe1synn9kadnbppahdn7",
		"name": "FIFA World Cup Qatar 2022",
		"code": "WOC",
		"format": "International cup",
		"startDate": "2022-11-20T00:00:00Z",
		"endDate": "2022-12-18T00:00:00Z",
		"isFriendly": false
	},
	"periods": [{
		"id": 1,
		"start": "2022-11-25T19:00:10Z",
		"end": "2022-11-25T19:46:31Z",
		"lengthMin": "46",
		"lengthSec": "21",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2022-11-25T20:02:19Z",
		"end": "2022-11-25T20:51:53Z",
		"lengthMin": "49",
		"lengthSec": "34",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "ck8m1cn23sukwsurgx5qakttk",
		"name": "England",
		"officialName": "England",
		"shortName": "England",
		"code": "ENG",
		"position": "home",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "9vh2u1p4ppm597tjfahst2m3n",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "away",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/fifa-world-cup-qatar-2022/matches/england-vs-united-states-11-25-22",
	"sitecoreData": {
		"link1": {"value": {"href": ""}, "editable": null},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {"value": {"href": ""}, "editable": null},
		"matchRecapLink": {"value": {"href": ""}, "editable": null},
		"ticketsLink": {"value": {"href": ""}, "editable": null},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/backgroundred.ashx?h=1200&la=en-US&w=3840&rev=a73df9006659468d9b7fc9fe45452d72&hash=99DD665448A183451AFA8B9117FE5205",
				"alt": "",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/cardred.ashx?h=720&la=en-US&w=670&rev=18fd56e215454fc9971223f6e38a577d&hash=F78F6ED6B0027EF6121A12133AF0F1E1",
				"alt": "Red Chevron Background Pattern",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/fifa-world-cup-qatar-2022"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/cutouts/mnt/weston-white-boxing-left-png.ashx?h=1160&la=en-US&w=880&rev=295eeec8ee044710acc7b3a42defe2d5&hash=1F7B2B6925B4136ACFAB5A0058EDFA20",
				"alt": "Weston McKennie",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/cutouts/mnt/adams-white-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=4d1d57e1522f424a96b24862418268ab&hash=1B8D42E508857F8F422370CBB4C20C3B",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "Al Bayt Stadium", "editable": null},
		"stadiumLocation": {"value": "Al Khor", "editable": null},
		"stadiumCountry": {"value": "Qatar", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/app/broadcasters/tvlogofoxblack2x.ashx?h=52&la=en-US&w=120&rev=1dd8458ff07b4d02a856c09c91032169&hash=8D532373DF96AA9D153CC4CF0A527D8A",
					"alt": "FOX",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.foxsports.com/live", "editable": null},
			"iosUrl": {"value": "https://www.foxsports.com/mobile", "editable": null},
			"androidUrl": {"value": "https://www.foxsports.com/mobile", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/app/broadcasters/tvlogotelemundoblack2x.ashx?h=82&la=en-US&w=189&rev=dee0eb69b786485fa8bf57f05a577746&hash=928F9B2CCF345FD4E303F06E5560C310",
					"alt": "Telemundo",
					"width": "189",
					"height": "82"
				}, "editable": null
			},
			"webUrl": {"value": "http://now.telemundo.com/live", "editable": null},
			"iosUrl": {"value": "http://now.telemundo.com/live", "editable": null},
			"androidUrl": {"value": "http://now.telemundo.com/live", "editable": null}
		}],
		"currentItemId": "e70715cb-d4af-4046-8deb-3035ba424ca1"
	},
	"dateTime": "2022-11-25T19:00:00Z",
	"dateTimeObject": "2022-11-25T19:00:00+00:00"
}, {
	"matchId": "cqjyb3x9pe0qo4c42e95hxgd0",
	"description": "United States vs Wales",
	"tournamentCalendarId": "2a8elwzsufmguwymxbshcx756",
	"tournamentCalendarName": "2022 Qatar",
	"stageName": "Group Stage",
	"status": 0,
	"goals": [{
		"contestantId": "9vh2u1p4ppm597tjfahst2m3n",
		"periodId": 1,
		"timeMin": 36,
		"goalType": 0,
		"scorerId": "e5xxqwz0sdlwkkmp8bgs1k6w9",
		"scorerName": "T. Weah",
		"assistPlayerId": "93soe2rjn0z874cc9ofcesgkp",
		"assistPlayerName": "C. Pulisic"
	}, {
		"contestantId": "eyhp0bgsz2deg91xaw4zotn5c",
		"periodId": 2,
		"timeMin": 82,
		"goalType": 1,
		"scorerId": "cxvl0khnpfpb0csjxsatcfnit",
		"scorerName": "G. Bale",
		"assistPlayerId": null,
		"assistPlayerName": null
	}],
	"sponsor": null,
	"date": "2022-11-21T19:00:00",
	"time": "19:00:00Z",
	"cancelled": false,
	"lengthMin": 104,
	"lengthSec": 30,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "292la5hvc3yu9otu0vuw9f5gq",
		"longName": "Ahmad bin Ali Stadium",
		"shortName": "Ahmad bin Ali Stadium",
		"location": "Al Rayyan (Al-Rayyan)",
		"country": "Qatar"
	},
	"attendance": 43418,
	"competition": {
		"id": "70excpe1synn9kadnbppahdn7",
		"name": "FIFA World Cup Qatar 2022",
		"code": "WOC",
		"format": "International cup",
		"startDate": "2022-11-20T00:00:00Z",
		"endDate": "2022-12-18T00:00:00Z",
		"isFriendly": false
	},
	"periods": [{
		"id": 1,
		"start": "2022-11-21T19:01:13Z",
		"end": "2022-11-21T19:50:11Z",
		"lengthMin": "48",
		"lengthSec": "58",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2022-11-21T20:05:16Z",
		"end": "2022-11-21T21:00:48Z",
		"lengthMin": "55",
		"lengthSec": "32",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "9vh2u1p4ppm597tjfahst2m3n",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "home",
		"score": 1,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "eyhp0bgsz2deg91xaw4zotn5c",
		"name": "Wales",
		"officialName": "Wales",
		"shortName": "Wales",
		"code": "WAL",
		"position": "away",
		"score": 1,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/70excpe1synn9kadnbppahdn7/matches/cqjyb3x9pe0qo4c42e95hxgd0",
	"sitecoreData": null,
	"dateTime": "2022-11-21T19:00:00Z",
	"dateTimeObject": "2022-11-21T19:00:00+00:00"
}, {
	"matchId": "462szm1rk14i0yrj4iwz77ro",
	"description": "Saudi Arabia vs United States",
	"tournamentCalendarId": "40sncpbsyexdrmedcwjz1j0gk",
	"tournamentCalendarName": "2022",
	"stageName": "Friendlies 1",
	"status": 0,
	"goals": [],
	"sponsor": null,
	"date": "2022-09-27T18:00:00",
	"time": "18:00:00Z",
	"cancelled": false,
	"lengthMin": 95,
	"lengthSec": 45,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "3y7xn7eq5u4rzcabgq3xb3v9y",
		"longName": "Estadio Enrique Roca de Murcia",
		"shortName": "Estadio Enrique Roca de Murcia",
		"location": "Murcia",
		"country": "Spain"
	},
	"attendance": 364,
	"competition": {
		"id": "cesdwwnxbc5fmajgroc0hqzy2",
		"name": "International Friendly",
		"code": "FRI",
		"format": "International cup",
		"startDate": "2022-01-01T00:00:00Z",
		"endDate": "2022-12-31T00:00:00Z",
		"isFriendly": true
	},
	"periods": [{
		"id": 1,
		"start": "2022-09-27T18:00:59Z",
		"end": "2022-09-27T18:47:04Z",
		"lengthMin": "46",
		"lengthSec": "5",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2022-09-27T19:02:57Z",
		"end": "2022-09-27T19:52:37Z",
		"lengthMin": "49",
		"lengthSec": "40",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "9l4imoomrnyceg5u3kdxf5l5r",
		"name": "Saudi Arabia",
		"officialName": "Saudi Arabia",
		"shortName": "Saudi Arabia",
		"code": "KSA",
		"position": "home",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "9vh2u1p4ppm597tjfahst2m3n",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "away",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/usmnt-friendlies-2022/matches/united-states-vs-saudi-arabia-9-27-2022",
	"sitecoreData": {
		"link1": {"value": {"href": ""}, "editable": null},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {"value": {"href": ""}, "editable": null},
		"matchRecapLink": {"value": {"href": ""}, "editable": null},
		"ticketsLink": {"value": {"href": ""}, "editable": null},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/backgroundred.ashx?h=1200&la=en-US&w=3840&rev=a73df9006659468d9b7fc9fe45452d72&hash=99DD665448A183451AFA8B9117FE5205",
				"alt": "",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/cardred.ashx?h=720&la=en-US&w=670&rev=18fd56e215454fc9971223f6e38a577d&hash=F78F6ED6B0027EF6121A12133AF0F1E1",
				"alt": "Red Chevron Background Pattern",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/usmnt-friendlies-2022"
		},
		"backgroundImage": null,
		"playerCutoutHome": {"value": {}, "editable": null},
		"playerCutoutAway": {"value": {}, "editable": null},
		"sponsorText": {"value": "", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "Estadio Nueva Condomina", "editable": null},
		"stadiumLocation": {"value": "Murcia", "editable": null},
		"stadiumCountry": {"value": "Spain", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogofs1black2x.ashx?h=52&la=en-US&w=120&rev=79d1fc98d79c4aa582431482dc9188aa&hash=222581B287E6DC17C26116C6B3C28131",
					"alt": "FS1",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.foxsports.com/live/fs1", "editable": null},
			"iosUrl": {"value": "https://www.foxsports.com/mobile", "editable": null},
			"androidUrl": {"value": "https://www.foxsports.com/mobile", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/unimasblack.ashx?h=52&la=en-US&w=120&rev=2e20532d88ed460b9d490cc10d459987&hash=CF359114F757CD1499BC00347A2B8F32",
					"alt": "UniMAS",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.univisionnow.com/channels", "editable": null},
			"iosUrl": {"value": "https://www.univisionnow.com/channels", "editable": null},
			"androidUrl": {"value": "https://www.univisionnow.com/channels", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tundlogo.ashx?h=52&la=en-US&w=120&rev=9cc1f63328c54407a1efc533b869bdc5&hash=8A3D704527709F5BB4D4149320DFB4DC",
					"alt": "TUDN",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.tudn.com/", "editable": null},
			"iosUrl": {"value": "https://www.tudn.com/", "editable": null},
			"androidUrl": {"value": "https://www.tudn.com/", "editable": null}
		}],
		"currentItemId": "27518b53-20b8-491d-840c-507235218337"
	},
	"dateTime": "2022-09-27T18:00:00Z",
	"dateTimeObject": "2022-09-27T18:00:00+00:00"
}, {
	"matchId": "7jeablkqhm3duvtjwcx3cv8r8",
	"description": "Costa Rica vs United States",
	"tournamentCalendarId": "8atuetifgnlt3q6oprwn7x9pm",
	"tournamentCalendarName": "2022 Qatar",
	"stageName": "Final Round",
	"status": 0,
	"goals": [{
		"contestantId": "bfgqfm4qa3mal7fll74wd04mu",
		"periodId": 2,
		"timeMin": 51,
		"goalType": 0,
		"scorerId": "cz51ia9ovw0adlfq56ld6cbu1",
		"scorerName": "J. Vargas",
		"assistPlayerId": "dszuj3a23wso9ffm3w6mp4qy2",
		"assistPlayerName": "B. Aguilera"
	}, {
		"contestantId": "bfgqfm4qa3mal7fll74wd04mu",
		"periodId": 2,
		"timeMin": 59,
		"goalType": 0,
		"scorerId": "56u0ykouaw86f3385b5onozjd",
		"scorerName": "A. Contreras",
		"assistPlayerId": "25qmad3kcnapvbf4fb9fw6ctm",
		"assistPlayerName": "J. Bennette"
	}],
	"sponsor": "",
	"date": "2022-03-31T01:05:00",
	"time": "01:05:00Z",
	"cancelled": false,
	"lengthMin": 94,
	"lengthSec": 1,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "1kt2sh5qr716oi55i1mzz9lep",
		"longName": "Estadio Nacional de Costa Rica",
		"shortName": "Estadio Nacional de Costa Rica",
		"location": "San José",
		"country": "Costa Rica"
	},
	"attendance": 0,
	"competition": {
		"id": "3a0j0giz3c3ajw9h59evv7lqt",
		"name": "Concacaf FIFA World Cup Qualifying 2022",
		"code": "WQC",
		"format": "International cup",
		"startDate": "2021-03-24T00:00:00Z",
		"endDate": "2022-03-31T00:00:00Z",
		"isFriendly": false
	},
	"periods": [{
		"id": 1,
		"start": "2022-03-31T01:05:33Z",
		"end": "2022-03-31T01:50:33Z",
		"lengthMin": "45",
		"lengthSec": "0",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2022-03-31T02:05:56Z",
		"end": "2022-03-31T02:54:57Z",
		"lengthMin": "49",
		"lengthSec": "1",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "bfgqfm4qa3mal7fll74wd04mu",
		"name": "Costa Rica",
		"officialName": "Costa Rica",
		"shortName": "Costa Rica",
		"code": "CRC",
		"position": "home",
		"score": 2,
		"heroFlairTitle": "",
		"heroFlairName": "",
		"heroFlairBody": ""
	}, {
		"id": "9vh2u1p4ppm597tjfahst2m3n",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "away",
		"score": 0,
		"heroFlairTitle": "",
		"heroFlairName": "",
		"heroFlairBody": ""
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00Z",
		"generalAvailabilityDate": "0001-01-01T00:00:00Z"
	},
	"groupTicketUrl": "",
	"groupTicketText": "",
	"matchFeedUrl": "/competitions/concacaf-world-cup-qualifying-2022/matches/costa-rica-vs-united-states-3-30-22",
	"sitecoreData": {
		"link1": {"value": {"href": ""}, "editable": null},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {"value": {"href": ""}, "editable": null},
		"matchRecapLink": {"value": {"href": ""}, "editable": null},
		"ticketsLink": {"value": {"href": ""}, "editable": null},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/backgroundred.ashx?h=1200&la=en-US&w=3840&rev=a73df9006659468d9b7fc9fe45452d72&hash=99DD665448A183451AFA8B9117FE5205",
				"alt": "",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/cardred.ashx?h=720&la=en-US&w=670&rev=18fd56e215454fc9971223f6e38a577d&hash=F78F6ED6B0027EF6121A12133AF0F1E1",
				"alt": "Red Chevron Background Pattern",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/concacaf-world-cup-qualifying-2022"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/cutouts/mnt/adams-white-playernameboxingposterleft.ashx?h=1160&la=en-US&w=880&rev=03b39e40920247fd9e9135e115394a95&hash=8C1B7FA925206B392C7C88AA5FCFE339",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/cutouts/mnt/pulisic-white-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=befa3a4e97c24807a3d1def15be9a54c&hash=CA5AA67430A4D6F7AC1D46E9CFDBA134",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "Estadio Nacional de Costa Rica", "editable": null},
		"stadiumLocation": {"value": "San Jose", "editable": null},
		"stadiumCountry": {"value": "Costa Rica", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/app/broadcasters/cbssn-paramount-logo.ashx?h=207&la=en-US&w=1299&rev=56e7fee96b8a46d6b05d858bc22b5256&hash=158D9C121F41930B0D4DEDD38730F9F0",
					"alt": "CBSSN & Paramount+",
					"width": "1299",
					"height": "207"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.paramountplus.com/", "editable": null},
			"iosUrl": {"value": "https://www.paramountplus.com/", "editable": null},
			"androidUrl": {"value": "https://www.paramountplus.com/", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogouniversoblack2x.ashx?h=52&la=en-US&w=120&rev=e8fd76c116cd408195c3374e4ecbc87c&hash=F88742E24C3283C03874ABD4661E786F",
					"alt": "UNIVERSO",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.nbc.com/networks/nbc-universo", "editable": null},
			"iosUrl": {"value": "https://apps.apple.com/us/app/universo-now/id869444353", "editable": null},
			"androidUrl": {
				"value": "https://play.google.com/store/apps/details?id=com.nbcu.tve.universo&hl=en_US&gl=US",
				"editable": null
			}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/peacock.ashx?h=52&la=en-US&w=120&rev=fd6dd508632e469ca1bb7d0949160d31&hash=E6650BBAD5615F7998A7982839A481E4",
					"alt": "Peacock",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.peacocktv.com/watch/", "editable": null},
			"iosUrl": {"value": "https://www.peacocktv.com/watch/", "editable": null},
			"androidUrl": {"value": "https://www.peacocktv.com/watch/", "editable": null}
		}],
		"currentItemId": "3527e9d6-3961-48af-becb-0d01fb0632ae"
	},
	"dateTime": "2022-03-31T01:05:00Z",
	"dateTimeObject": "2022-03-31T01:05:00+00:00"
}, {
	"matchId": "7ix4t4i28jcpj3t1thv4v9638",
	"description": "United States vs Panama",
	"tournamentCalendarId": "8atuetifgnlt3q6oprwn7x9pm",
	"tournamentCalendarName": "2022 Qatar",
	"stageName": "Final Round",
	"status": 0,
	"goals": [{
		"contestantId": "9vh2u1p4ppm597tjfahst2m3n",
		"periodId": 1,
		"timeMin": 17,
		"goalType": 1,
		"scorerId": "93soe2rjn0z874cc9ofcesgkp",
		"scorerName": "C. Pulisic",
		"assistPlayerId": null,
		"assistPlayerName": null
	}, {
		"contestantId": "9vh2u1p4ppm597tjfahst2m3n",
		"periodId": 1,
		"timeMin": 23,
		"goalType": 0,
		"scorerId": "6glxg9gvqn1uc1jmwisnml68l",
		"scorerName": "P. Arriola ",
		"assistPlayerId": "bgc1psqiv7vagmphfl920nbah",
		"assistPlayerName": "A. Robinson"
	}, {
		"contestantId": "9vh2u1p4ppm597tjfahst2m3n",
		"periodId": 1,
		"timeMin": 27,
		"goalType": 0,
		"scorerId": "5wttdjpftd57c2t5959sm2yg9",
		"scorerName": "J. Ferreira",
		"assistPlayerId": "6glxg9gvqn1uc1jmwisnml68l",
		"assistPlayerName": "P. Arriola "
	}, {
		"contestantId": "9vh2u1p4ppm597tjfahst2m3n",
		"periodId": 1,
		"timeMin": 49,
		"goalType": 1,
		"scorerId": "93soe2rjn0z874cc9ofcesgkp",
		"scorerName": "C. Pulisic",
		"assistPlayerId": null,
		"assistPlayerName": null
	}, {
		"contestantId": "9vh2u1p4ppm597tjfahst2m3n",
		"periodId": 2,
		"timeMin": 65,
		"goalType": 0,
		"scorerId": "93soe2rjn0z874cc9ofcesgkp",
		"scorerName": "C. Pulisic",
		"assistPlayerId": "bgc1psqiv7vagmphfl920nbah",
		"assistPlayerName": "A. Robinson"
	}, {
		"contestantId": "8856r86rattc8kxn6a0l9tb2t",
		"periodId": 2,
		"timeMin": 86,
		"goalType": 0,
		"scorerId": "4ajhe29nxseyo6wqg3hjdghcl",
		"scorerName": "A. Godoy",
		"assistPlayerId": "6fmrelr9khhkywqr8goutjkr9",
		"assistPlayerName": "É. Davis"
	}],
	"sponsor": "Presented by VW",
	"date": "2022-03-27T05:00:00",
	"time": "TBD",
	"cancelled": false,
	"lengthMin": 99,
	"lengthSec": 40,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "3udn5yhh0bcxwmjokvt4xnm5l",
		"longName": "Exploria Stadium",
		"shortName": "Exploria Stadium",
		"location": "Orlando, Florida",
		"country": "USA"
	},
	"attendance": 0,
	"competition": {
		"id": "3a0j0giz3c3ajw9h59evv7lqt",
		"name": "Concacaf FIFA World Cup Qualifying 2022",
		"code": "WQC",
		"format": "International cup",
		"startDate": "2021-03-24T00:00:00Z",
		"endDate": "2022-03-31T00:00:00Z",
		"isFriendly": false
	},
	"periods": [{
		"id": 1,
		"start": "2022-03-27T23:00:47Z",
		"end": "2022-03-27T23:52:12Z",
		"lengthMin": "51",
		"lengthSec": "25",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2022-03-28T00:08:48Z",
		"end": "2022-03-28T00:57:03Z",
		"lengthMin": "48",
		"lengthSec": "15",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "9vh2u1p4ppm597tjfahst2m3n",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "home",
		"score": 5,
		"heroFlairTitle": "",
		"heroFlairName": "",
		"heroFlairBody": ""
	}, {
		"id": "8856r86rattc8kxn6a0l9tb2t",
		"name": "Panama",
		"officialName": "Panama",
		"shortName": "Panama",
		"code": "PAN",
		"position": "away",
		"score": 1,
		"heroFlairTitle": "",
		"heroFlairName": "",
		"heroFlairBody": ""
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00Z",
		"generalAvailabilityDate": "0001-01-01T00:00:00Z"
	},
	"groupTicketUrl": "",
	"groupTicketText": "",
	"matchFeedUrl": "/competitions/concacaf-world-cup-qualifying-2022/matches/united-states-vs-panama-3-27-22",
	"sitecoreData": {
		"link1": {"value": {"href": ""}, "editable": null},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {"value": {"href": ""}, "editable": null},
		"matchRecapLink": {"value": {"href": ""}, "editable": null},
		"ticketsLink": {
			"value": {
				"href": "https://www.ticketmaster.com/event/22005C25FABEBC78",
				"text": "Tickets",
				"linktype": "external",
				"url": "https://www.ticketmaster.com/event/22005C25FABEBC78",
				"anchor": "",
				"title": "Tickets",
				"target": "_blank"
			}, "editable": null
		},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/backgroundred.ashx?h=1200&la=en-US&w=3840&rev=a73df9006659468d9b7fc9fe45452d72&hash=99DD665448A183451AFA8B9117FE5205",
				"alt": "",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/cardred.ashx?h=720&la=en-US&w=670&rev=18fd56e215454fc9971223f6e38a577d&hash=F78F6ED6B0027EF6121A12133AF0F1E1",
				"alt": "Red Chevron Background Pattern",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/concacaf-world-cup-qualifying-2022"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/cutouts/mnt/adams-white-bigger-playernameboxingposterleft.ashx?h=1160&la=en-US&w=880&rev=f6473ff1d5cd4b6e912ee3518ab671fa&hash=39124D95D361940A467DB48DDCD2FF3E",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/cutouts/mnt/gio-reyna-white-bigger-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=dfd596dfcadc4713891b7dc1f438b25a&hash=93D0BC7C9C4EAD716F44D3EA67BBAC38",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "Presented by VW", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "Exploria Stadium", "editable": null},
		"stadiumLocation": {"value": "Orlando, Florida", "editable": null},
		"stadiumCountry": {"value": "United States", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogofs1black2x.ashx?h=52&la=en-US&w=120&rev=79d1fc98d79c4aa582431482dc9188aa&hash=222581B287E6DC17C26116C6B3C28131",
					"alt": "FS1",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.foxsports.com/live/fs1", "editable": null},
			"iosUrl": {"value": "https://www.foxsports.com/mobile", "editable": null},
			"androidUrl": {"value": "https://www.foxsports.com/mobile", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/unimasblack.ashx?h=52&la=en-US&w=120&rev=2e20532d88ed460b9d490cc10d459987&hash=CF359114F757CD1499BC00347A2B8F32",
					"alt": "UniMAS",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.univision.com/unimas", "editable": null},
			"iosUrl": {"value": "https://www.univision.com/unimas", "editable": null},
			"androidUrl": {"value": "https://www.univision.com/unimas", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tundlogo.ashx?h=52&la=en-US&w=120&rev=9cc1f63328c54407a1efc533b869bdc5&hash=8A3D704527709F5BB4D4149320DFB4DC",
					"alt": "TUDN",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.tudn.com/", "editable": null},
			"iosUrl": {"value": "https://www.tudn.com/", "editable": null},
			"androidUrl": {"value": "https://www.tudn.com/", "editable": null}
		}],
		"currentItemId": "90d818b9-8add-4829-8769-528922d735c8"
	},
	"dateTime": "2022-03-27TTBD",
	"dateTimeObject": "0001-01-01T00:00:00"
}, {
	"matchId": "7ifhl0api90q31p1qyl6vc3ys",
	"description": "Mexico vs United States",
	"tournamentCalendarId": "8atuetifgnlt3q6oprwn7x9pm",
	"tournamentCalendarName": "2022 Qatar",
	"stageName": "Final Round",
	"status": 0,
	"goals": [],
	"sponsor": "",
	"date": "2022-03-25T02:00:00",
	"time": "02:00:00Z",
	"cancelled": false,
	"lengthMin": 97,
	"lengthSec": 44,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "1z26ptjg3kg7fpitq54sj7z44",
		"longName": "Estadio Azteca",
		"shortName": "Estadio Azteca",
		"location": "Ciudad de México (D.F.)",
		"country": "Mexico"
	},
	"attendance": 0,
	"competition": {
		"id": "3a0j0giz3c3ajw9h59evv7lqt",
		"name": "Concacaf FIFA World Cup Qualifying 2022",
		"code": "WQC",
		"format": "International cup",
		"startDate": "2021-03-24T00:00:00Z",
		"endDate": "2022-03-31T00:00:00Z",
		"isFriendly": false
	},
	"periods": [{
		"id": 1,
		"start": "2022-03-25T02:02:38Z",
		"end": "2022-03-25T02:50:11Z",
		"lengthMin": "47",
		"lengthSec": "33",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2022-03-25T03:06:36Z",
		"end": "2022-03-25T03:56:47Z",
		"lengthMin": "50",
		"lengthSec": "11",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "4vofb84dzb5fyc81n2ssws6ah",
		"name": "Mexico",
		"officialName": "Mexico",
		"shortName": "Mexico",
		"code": "MEX",
		"position": "home",
		"score": 0,
		"heroFlairTitle": "",
		"heroFlairName": "",
		"heroFlairBody": ""
	}, {
		"id": "9vh2u1p4ppm597tjfahst2m3n",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "away",
		"score": 0,
		"heroFlairTitle": "",
		"heroFlairName": "",
		"heroFlairBody": ""
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00Z",
		"generalAvailabilityDate": "0001-01-01T00:00:00Z"
	},
	"groupTicketUrl": "",
	"groupTicketText": "",
	"matchFeedUrl": "/competitions/concacaf-world-cup-qualifying-2022/matches/mexico-vs-united-states-3-24-22",
	"sitecoreData": {
		"link1": {"value": {"href": ""}, "editable": null},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {"value": {"href": ""}, "editable": null},
		"matchRecapLink": {"value": {"href": ""}, "editable": null},
		"ticketsLink": {"value": {"href": ""}, "editable": null},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/backgroundred.ashx?h=1200&la=en-US&w=3840&rev=a73df9006659468d9b7fc9fe45452d72&hash=99DD665448A183451AFA8B9117FE5205",
				"alt": "",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/cardred.ashx?h=720&la=en-US&w=670&rev=18fd56e215454fc9971223f6e38a577d&hash=F78F6ED6B0027EF6121A12133AF0F1E1",
				"alt": "Red Chevron Background Pattern",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/concacaf-world-cup-qualifying-2022"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/cutouts/mnt/adams-white-playernameboxingposterleft.ashx?h=1160&la=en-US&w=880&rev=03b39e40920247fd9e9135e115394a95&hash=8C1B7FA925206B392C7C88AA5FCFE339",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/cutouts/mnt/pulisic-white-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=befa3a4e97c24807a3d1def15be9a54c&hash=CA5AA67430A4D6F7AC1D46E9CFDBA134",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "Estadio Azteca", "editable": null},
		"stadiumLocation": {"value": "Mexico City", "editable": null},
		"stadiumCountry": {"value": "Mexico", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogounivisionblack2x.ashx?h=164&la=en-US&w=460&rev=0b74caaf17624eba9f02762e01758751&hash=1D2B578C26EF9C8B3CA5607C76C51DDD",
					"alt": "UNIVISION",
					"width": "460",
					"height": "164"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.univisionnow.com/channels", "editable": null},
			"iosUrl": {"value": "https://www.univisionnow.com/channels", "editable": null},
			"androidUrl": {"value": "https://www.univisionnow.com/channels", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tundlogo.ashx?h=52&la=en-US&w=120&rev=9cc1f63328c54407a1efc533b869bdc5&hash=8A3D704527709F5BB4D4149320DFB4DC",
					"alt": "TUDN",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.tudn.com/", "editable": null},
			"iosUrl": {"value": "https://www.tudn.com/", "editable": null},
			"androidUrl": {"value": "https://www.tudn.com/", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/app/broadcasters/cbssn-paramount-logo.ashx?h=207&la=en-US&w=1299&rev=56e7fee96b8a46d6b05d858bc22b5256&hash=158D9C121F41930B0D4DEDD38730F9F0",
					"alt": "CBSSN & Paramount+",
					"width": "1299",
					"height": "207"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.paramountplus.com/", "editable": null},
			"iosUrl": {"value": "https://www.paramountplus.com/", "editable": null},
			"androidUrl": {"value": "https://www.paramountplus.com/", "editable": null}
		}],
		"currentItemId": "4543ea14-7b8e-4cae-a642-bd995878647f"
	},
	"dateTime": "2022-03-25T02:00:00Z",
	"dateTimeObject": "2022-03-25T02:00:00+00:00"
}, {
	"matchId": "7h7gzjga8s5ai9bl7kyvxpc0k",
	"description": "Canada vs United States",
	"tournamentCalendarId": "8atuetifgnlt3q6oprwn7x9pm",
	"tournamentCalendarName": "2022 Qatar",
	"stageName": "Final Round",
	"status": 1,
	"goals": [],
	"sponsor": null,
	"date": "2022-01-30T00:00:00",
	"time": "TBD",
	"cancelled": false,
	"lengthMin": 0,
	"lengthSec": 0,
	"addedTimeForCurrentPeriod": 0,
	"venue": {"id": "TBD", "longName": "TBD", "shortName": "TBD", "location": "TBD", "country": "TBD"},
	"attendance": 0,
	"competition": {
		"id": "3a0j0giz3c3ajw9h59evv7lqt",
		"name": "WC Qualification Concacaf",
		"code": "WQC",
		"format": "International cup",
		"startDate": "2021-03-24T00:00:00Z",
		"endDate": "2022-03-30T00:00:00Z",
		"isFriendly": false
	},
	"periods": [],
	"currentPeriod": 16,
	"manager": null,
	"contestants": [{
		"id": "eg7vduna0h3vis1wd47s41za7",
		"name": "Canada",
		"officialName": "Canada",
		"shortName": "Canada",
		"code": "CAN",
		"position": "home",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "9vh2u1p4ppm597tjfahst2m3n",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "away",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [{
		"imageURL": "https://cdn.ussoccer.com/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogofs1black2x.ashx?h=52&la=en-US&w=120&rev=9bdbe3bc73f1427780ec22723ac09fda&hash=951EA39E69069D93FCC92F0E9028970D",
		"imageAltText": "FS1",
		"broadcastURLWeb": "https://www.foxsports.com/live/fs1",
		"broadcastURLAndroid": "https://www.foxsports.com/mobile",
		"broadcastURLiOS": "https://www.foxsports.com/mobile"
	}, {
		"imageURL": "https://cdn.ussoccer.com/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogounimasblack2x.ashx?h=52&la=en-US&w=120&rev=f395ee5595a540f081b0c224662544a7&hash=EB2DE9D597B8B15B51AB03B77F2DF717",
		"imageAltText": "UniMAS",
		"broadcastURLWeb": "https://www.univisionnow.com/channels",
		"broadcastURLAndroid": "https://www.univisionnow.com/channels",
		"broadcastURLiOS": "https://www.univisionnow.com/channels"
	}, {
		"imageURL": "https://cdn.ussoccer.com/-/media/project/ussf/logos/ussf-logos/broadcasters/tundlogo.ashx?h=52&la=en-US&w=120&rev=43a69b8a0ca94cccbfcd2facca429e4f&hash=3C32097BB45E1945D77E39AC0FE13B8D",
		"imageAltText": "TUDN",
		"broadcastURLWeb": "https://www.tudn.com/",
		"broadcastURLAndroid": "https://www.tudn.com/",
		"broadcastURLiOS": "https://www.tudn.com/"
	}],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/concacaf-world-cup-qualifying-2022/matches/canada-vs-united-states-1-30-22",
	"sitecoreData": {
		"link1": {"value": {"href": ""}, "editable": null},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {"value": {"href": ""}, "editable": null},
		"matchRecapLink": {"value": {"href": ""}, "editable": null},
		"ticketsLink": {"value": {"href": ""}, "editable": null},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/backgroundred.ashx?h=1200&la=en-US&w=3840&rev=a73df9006659468d9b7fc9fe45452d72&hash=99DD665448A183451AFA8B9117FE5205",
				"alt": "",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/cardred.ashx?h=720&la=en-US&w=670&rev=18fd56e215454fc9971223f6e38a577d&hash=F78F6ED6B0027EF6121A12133AF0F1E1",
				"alt": "Red Chevron Background Pattern",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/concacaf-world-cup-qualifying-2022"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/cutouts/mnt/adams-white-bigger-playernameboxingposterleft.ashx?h=1160&la=en-US&w=880&rev=f6473ff1d5cd4b6e912ee3518ab671fa&hash=39124D95D361940A467DB48DDCD2FF3E",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/cutouts/mnt/antonee-robinson-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=79fc426b7dcb4419a9fae64ce73e8c5a&hash=C0AF7FD14C68F2B886B48142E233AAAC",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "Tim Hortons Field", "editable": null},
		"stadiumLocation": {"value": "Hamilton, Ontario", "editable": null},
		"stadiumCountry": {"value": "Canada", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/paramountplusblack.ashx?h=156&la=en-US&w=360&rev=c142b55dd9c741238f5893c710d569ab&hash=C0EB49327131989E1589E10E3DC55393",
					"alt": "Paramount +",
					"width": "360",
					"height": "156"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.paramountplus.com/", "editable": null},
			"iosUrl": {"value": "https://www.paramountplus.com/", "editable": null},
			"androidUrl": {"value": "https://www.paramountplus.com/ ", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/app/broadcasters/tvlogotelemundoblack2x.ashx?h=82&la=en-US&w=189&rev=dee0eb69b786485fa8bf57f05a577746&hash=928F9B2CCF345FD4E303F06E5560C310",
					"alt": "Telemundo",
					"width": "189",
					"height": "82"
				}, "editable": null
			},
			"webUrl": {"value": "http://now.telemundo.com/live", "editable": null},
			"iosUrl": {"value": "http://now.telemundo.com/live", "editable": null},
			"androidUrl": {"value": "http://now.telemundo.com/live", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogouniversoblack2x.ashx?h=52&la=en-US&w=120&rev=e8fd76c116cd408195c3374e4ecbc87c&hash=F88742E24C3283C03874ABD4661E786F",
					"alt": "UNIVERSO",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.nbc.com/networks/nbc-universo", "editable": null},
			"iosUrl": {"value": "https://apps.apple.com/us/app/universo-now/id869444353", "editable": null},
			"androidUrl": {
				"value": "https://play.google.com/store/apps/details?id=com.nbcu.tve.universo&hl=en_US&gl=US",
				"editable": null
			}
		}],
		"currentItemId": "7ed5d2cb-cf7c-4fc6-ad78-2e65a016379f"
	},
	"dateTime": "2022-01-30TTBD",
	"dateTimeObject": "0001-01-01T00:00:00"
}, {
	"matchId": "7gmsyb7x1pit26f5jd38lti50",
	"description": "Jamaica vs United States",
	"tournamentCalendarId": "8atuetifgnlt3q6oprwn7x9pm",
	"tournamentCalendarName": "2022 Qatar",
	"stageName": "Final Round",
	"status": 0,
	"goals": [{
		"contestantId": "9vh2u1p4ppm597tjfahst2m3n",
		"periodId": 1,
		"timeMin": 11,
		"goalType": 0,
		"scorerId": "e5xxqwz0sdlwkkmp8bgs1k6w9",
		"scorerName": "T. Weah",
		"assistPlayerId": "emglxnqrlqskt8krglv7sziwa",
		"assistPlayerName": "R. Pepi"
	}, {
		"contestantId": "8yhjl13p7luh3v905btbvz42t",
		"periodId": 1,
		"timeMin": 22,
		"goalType": 0,
		"scorerId": "3c7vnmec038bhz5rq36ywi2fp",
		"scorerName": "M. Antonio",
		"assistPlayerId": "2yflerah2shma2hzhm99msez9",
		"assistPlayerName": "D. Williams"
	}],
	"sponsor": null,
	"date": "2021-11-16T22:00:00",
	"time": "22:00:00Z",
	"cancelled": false,
	"lengthMin": 93,
	"lengthSec": 55,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "c4xzw4pu0xeljntmgmnx4h8zk",
		"longName": "Independence Park",
		"shortName": "Independence Park",
		"location": "Kingston",
		"country": "Jamaica"
	},
	"attendance": 0,
	"competition": {
		"id": "3a0j0giz3c3ajw9h59evv7lqt",
		"name": "WC Qualification Concacaf",
		"code": "WQC",
		"format": "International cup",
		"startDate": "2021-03-24T00:00:00Z",
		"endDate": "2022-03-30T00:00:00Z",
		"isFriendly": false
	},
	"periods": [{
		"id": 1,
		"start": "2021-11-16T22:00:51Z",
		"end": "2021-11-16T22:46:46Z",
		"lengthMin": "45",
		"lengthSec": "55",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2021-11-16T23:02:19Z",
		"end": "2021-11-16T23:50:19Z",
		"lengthMin": "48",
		"lengthSec": "0",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "8yhjl13p7luh3v905btbvz42t",
		"name": "Jamaica",
		"officialName": "Jamaica",
		"shortName": "Jamaica",
		"code": "JAM",
		"position": "home",
		"score": 1,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "9vh2u1p4ppm597tjfahst2m3n",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "away",
		"score": 1,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/concacaf-world-cup-qualifying-2022/matches/jamaica-vs-united-states-11-16-21",
	"sitecoreData": {
		"link1": {"value": {"href": ""}, "editable": null},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {"value": {"href": ""}, "editable": null},
		"matchRecapLink": {"value": {"href": ""}, "editable": null},
		"ticketsLink": {"value": {"href": ""}, "editable": null},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/backgroundred.ashx?h=1200&la=en-US&w=3840&rev=a73df9006659468d9b7fc9fe45452d72&hash=99DD665448A183451AFA8B9117FE5205",
				"alt": "",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/cardred.ashx?h=720&la=en-US&w=670&rev=18fd56e215454fc9971223f6e38a577d&hash=F78F6ED6B0027EF6121A12133AF0F1E1",
				"alt": "Red Chevron Background Pattern",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/concacaf-world-cup-qualifying-2022"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/cutouts/mnt/aaronson-white-playernameboxingposterleft.ashx?h=1160&la=en-US&w=880&rev=a55073365717424f8cfa329ff60db0ed&hash=488F99C9ACAAD77C021EF6EC073DE417",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/cutouts/mnt/adams-white-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=4d1d57e1522f424a96b24862418268ab&hash=1B8D42E508857F8F422370CBB4C20C3B",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "", "editable": null},
		"stadiumLocation": {"value": "", "editable": null},
		"stadiumCountry": {"value": "", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/paramount_plus_logo.ashx?h=207&la=en-US&w=628&rev=8b46ffd2502043f1bbbad3dd8c32f183&hash=79D1F3FFA9177BC1B09380B983F168AF",
					"alt": "Paramount+",
					"width": "628",
					"height": "207"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.paramountplus.com/", "editable": null},
			"iosUrl": {"value": "https://www.paramountplus.com/", "editable": null},
			"androidUrl": {"value": "https://www.paramountplus.com/", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogouniversoblack2x.ashx?h=52&la=en-US&w=120&rev=e8fd76c116cd408195c3374e4ecbc87c&hash=F88742E24C3283C03874ABD4661E786F",
					"alt": "UNIVERSO",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.nbc.com/networks/nbc-universo", "editable": null},
			"iosUrl": {"value": "https://apps.apple.com/us/app/universo-now/id869444353", "editable": null},
			"androidUrl": {
				"value": "https://play.google.com/store/apps/details?id=com.nbcu.tve.universo&hl=en_US&gl=US",
				"editable": null
			}
		}],
		"currentItemId": "bd8579bf-9a56-4815-b3d9-cc54799885a7"
	},
	"dateTime": "2021-11-16T22:00:00Z",
	"dateTimeObject": "2021-11-16T22:00:00+00:00"
}, {
	"matchId": "7g81yyb7e9nubw0ae34j4s7pw",
	"description": "United States vs Mexico",
	"tournamentCalendarId": "8atuetifgnlt3q6oprwn7x9pm",
	"tournamentCalendarName": "2022 Qatar",
	"stageName": "Final Round",
	"status": 0,
	"goals": [{
		"contestantId": "9vh2u1p4ppm597tjfahst2m3n",
		"periodId": 2,
		"timeMin": 74,
		"goalType": 0,
		"scorerId": "93soe2rjn0z874cc9ofcesgkp",
		"scorerName": "C. Pulisic",
		"assistPlayerId": "e5xxqwz0sdlwkkmp8bgs1k6w9",
		"assistPlayerName": "T. Weah"
	}, {
		"contestantId": "9vh2u1p4ppm597tjfahst2m3n",
		"periodId": 2,
		"timeMin": 85,
		"goalType": 0,
		"scorerId": "288nn7pbq5ewriiyb9o7da86h",
		"scorerName": "W. McKennie",
		"assistPlayerId": null,
		"assistPlayerName": null
	}],
	"sponsor": null,
	"date": "2021-11-13T02:10:00",
	"time": "02:10:00Z",
	"cancelled": false,
	"lengthMin": 95,
	"lengthSec": 21,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "22ez7eqaji3nw09r5b9dyyvo",
		"longName": "TQL Stadium",
		"shortName": "TQL Stadium",
		"location": "Cincinnati, Ohio",
		"country": "USA"
	},
	"attendance": 26000,
	"competition": {
		"id": "3a0j0giz3c3ajw9h59evv7lqt",
		"name": "WC Qualification Concacaf",
		"code": "WQC",
		"format": "International cup",
		"startDate": "2021-03-24T00:00:00Z",
		"endDate": "2022-03-30T00:00:00Z",
		"isFriendly": false
	},
	"periods": [{
		"id": 1,
		"start": "2021-11-13T02:11:23Z",
		"end": "2021-11-13T02:57:28Z",
		"lengthMin": "46",
		"lengthSec": "5",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2021-11-13T03:14:11Z",
		"end": "2021-11-13T04:03:27Z",
		"lengthMin": "49",
		"lengthSec": "16",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "9vh2u1p4ppm597tjfahst2m3n",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "home",
		"score": 2,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "4vofb84dzb5fyc81n2ssws6ah",
		"name": "Mexico",
		"officialName": "Mexico",
		"shortName": "Mexico",
		"code": "MEX",
		"position": "away",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/concacaf-world-cup-qualifying-2022/matches/united-states-vs-mexico-11-12-21",
	"sitecoreData": {
		"link1": {"value": {"href": ""}, "editable": null},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {"value": {"href": ""}, "editable": null},
		"matchRecapLink": {"value": {"href": ""}, "editable": null},
		"ticketsLink": {
			"value": {
				"href": "https://www.ticketmaster.com/usmnt-v-mexico-presented-by-allstate-cincinnati-ohio-11-12-2021/event/16005B321F5D59D4",
				"text": "tickets",
				"linktype": "external",
				"url": "https://www.ticketmaster.com/usmnt-v-mexico-presented-by-allstate-cincinnati-ohio-11-12-2021/event/16005B321F5D59D4",
				"anchor": "",
				"target": "_blank"
			}, "editable": null
		},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/backgroundred.ashx?h=1200&la=en-US&w=3840&rev=a73df9006659468d9b7fc9fe45452d72&hash=99DD665448A183451AFA8B9117FE5205",
				"alt": "",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/cardred.ashx?h=720&la=en-US&w=670&rev=18fd56e215454fc9971223f6e38a577d&hash=F78F6ED6B0027EF6121A12133AF0F1E1",
				"alt": "Red Chevron Background Pattern",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/concacaf-world-cup-qualifying-2022"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/cutouts/mnt/weston-white-boxing-left-png.ashx?h=1160&la=en-US&w=880&rev=295eeec8ee044710acc7b3a42defe2d5&hash=1F7B2B6925B4136ACFAB5A0058EDFA20",
				"alt": "Weston McKennie",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/cutouts/mnt/adams-white-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=4d1d57e1522f424a96b24862418268ab&hash=1B8D42E508857F8F422370CBB4C20C3B",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "Presented by Allstate", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "TQL Stadium", "editable": null},
		"stadiumLocation": {"value": "Cincinnati, Ohio", "editable": null},
		"stadiumCountry": {"value": "USA", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/app/broadcasters/espn2_logo.ashx?h=52&la=en-US&w=120&rev=8b583676054f469c997298102fc43568&hash=495D7043006AA51910F46539C08D4624",
					"alt": "ESPN2",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.espn.com/watch/?type=live&channel=espn2", "editable": null},
			"iosUrl": {"value": "https://apps.apple.com/us/app/espn-scorecenter/id317469184", "editable": null},
			"androidUrl": {"value": "https://play.google.com/store/apps/details?id=com.espn.score_center", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogounivisionblack2x.ashx?h=164&la=en-US&w=460&rev=0b74caaf17624eba9f02762e01758751&hash=1D2B578C26EF9C8B3CA5607C76C51DDD",
					"alt": "UniMAS",
					"width": "460",
					"height": "164"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.univisionnow.com/channels", "editable": null},
			"iosUrl": {"value": "https://www.univisionnow.com/channels", "editable": null},
			"androidUrl": {"value": "https://www.univisionnow.com/channels", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tundlogo.ashx?h=52&la=en-US&w=120&rev=9cc1f63328c54407a1efc533b869bdc5&hash=8A3D704527709F5BB4D4149320DFB4DC",
					"alt": "TUDN",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.tudn.com/", "editable": null},
			"iosUrl": {"value": "https://www.tudn.com/", "editable": null},
			"androidUrl": {"value": "https://www.tudn.com/", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/app/broadcasters/espn-plus-logo.ashx?h=102&la=en-US&w=279&rev=38498fb15530436eb56e83bd2ef0401c&hash=4AC50301D2633C6CA0C0FC0D52A695E4",
					"alt": "ESPN+",
					"width": "279",
					"height": "102"
				}, "editable": null
			},
			"webUrl": {"value": "https://plus.espn.com/", "editable": null},
			"iosUrl": {"value": "https://plus.espn.com/", "editable": null},
			"androidUrl": {"value": "https://plus.espn.com/", "editable": null}
		}],
		"currentItemId": "0e6c3315-8425-4f74-a68e-9a6948210358"
	},
	"dateTime": "2021-11-13T02:10:00Z",
	"dateTimeObject": "2021-11-13T02:10:00+00:00"
}, {
	"matchId": "7fs2rsshyo01l31rhbyvt9jx0",
	"description": "United States vs Costa Rica",
	"tournamentCalendarId": "8atuetifgnlt3q6oprwn7x9pm",
	"tournamentCalendarName": "2022 Qatar",
	"stageName": "Final Round",
	"status": 0,
	"goals": [{
		"contestantId": "bfgqfm4qa3mal7fll74wd04mu",
		"periodId": 1,
		"timeMin": 1,
		"goalType": 0,
		"scorerId": "373320n20gpzgazgw4i06dl62",
		"scorerName": "K. Fuller",
		"assistPlayerId": "66zgg8oyv7c7cvkujkan5ptxx",
		"assistPlayerName": "R. Matarrita"
	}, {
		"contestantId": "9vh2u1p4ppm597tjfahst2m3n",
		"periodId": 1,
		"timeMin": 25,
		"goalType": 0,
		"scorerId": "b0me6l44boibnwpbtasxoy02x",
		"scorerName": "S. Dest",
		"assistPlayerId": "b2e12l3s3dxgyr8d2rk5qb9ey",
		"assistPlayerName": "Y. Musah"
	}, {
		"contestantId": "bfgqfm4qa3mal7fll74wd04mu",
		"periodId": 2,
		"timeMin": 66,
		"goalType": 2,
		"scorerId": "8k3z7zd8xy0rhyvxzhmygiuvp",
		"scorerName": "L. Moreira",
		"assistPlayerId": null,
		"assistPlayerName": null
	}],
	"sponsor": null,
	"date": "2021-10-13T23:00:00",
	"time": "23:00:00Z",
	"cancelled": false,
	"lengthMin": 96,
	"lengthSec": 28,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "1vetrskjzuh2n1ma50ln6dzbo",
		"longName": "Lower.com Field",
		"shortName": "Lower.com Field",
		"location": "Columbus, Ohio",
		"country": "USA"
	},
	"attendance": 20165,
	"competition": {
		"id": "3a0j0giz3c3ajw9h59evv7lqt",
		"name": "WC Qualification Concacaf",
		"code": "WQC",
		"format": "International cup",
		"startDate": "2021-03-24T00:00:00Z",
		"endDate": "2022-03-30T00:00:00Z",
		"isFriendly": false
	},
	"periods": [{
		"id": 1,
		"start": "2021-10-13T23:01:25Z",
		"end": "2021-10-13T23:48:35Z",
		"lengthMin": "47",
		"lengthSec": "10",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2021-10-14T00:05:23Z",
		"end": "2021-10-14T00:54:41Z",
		"lengthMin": "49",
		"lengthSec": "18",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "9vh2u1p4ppm597tjfahst2m3n",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "home",
		"score": 2,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "bfgqfm4qa3mal7fll74wd04mu",
		"name": "Costa Rica",
		"officialName": "Costa Rica",
		"shortName": "Costa Rica",
		"code": "CRC",
		"position": "away",
		"score": 1,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/concacaf-world-cup-qualifying-2022/matches/united-states-vs-costa-rica-10-13-21",
	"sitecoreData": {
		"link1": {"value": {"href": ""}, "editable": null},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {
			"value": {
				"href": "/stories/2021/10/2022-concacaf-world-cup-qualifying-usa-vs-costa-rica-preview-schedule-tv-channels-start-time",
				"querystring": "",
				"target": "",
				"linktype": "internal",
				"id": "{32413F11-1603-44FB-8233-826C66C521D7}",
				"url": "/USSF/Home/Stories/2021/10/2022 Concacaf World Cup Qualifying USA vs Costa Rica Preview Schedule TV Channels Start Time"
			}, "editable": null
		},
		"matchRecapLink": {
			"value": {
				"href": "/stories/2021/10/2022-concacaf-world-cup-qualifying-usa-2-costa-rica-1-match-report-stats-standings",
				"querystring": "",
				"target": "",
				"linktype": "internal",
				"id": "{28239F21-6DD7-4C47-AA32-C6164AAAC8F4}",
				"url": "/USSF/Home/Stories/2021/10/2022 Concacaf World Cup Qualifying USA 2 Costa Rica 1 Match Report Stats Standings"
			}, "editable": null
		},
		"ticketsLink": {
			"value": {
				"href": "https://www.ticketmaster.com/usmnt-v-costa-rica-presented-by-columbus-ohio-10-13-2021/event/05005B11FBA250CA",
				"text": "Tickets",
				"linktype": "external",
				"url": "https://www.ticketmaster.com/usmnt-v-costa-rica-presented-by-columbus-ohio-10-13-2021/event/05005B11FBA250CA",
				"anchor": "",
				"title": "Tickets",
				"target": "_blank"
			}, "editable": null
		},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/backgroundred.ashx?h=1200&la=en-US&w=3840&rev=a73df9006659468d9b7fc9fe45452d72&hash=99DD665448A183451AFA8B9117FE5205",
				"alt": "",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/cardred.ashx?h=720&la=en-US&w=670&rev=18fd56e215454fc9971223f6e38a577d&hash=F78F6ED6B0027EF6121A12133AF0F1E1",
				"alt": "Red Chevron Background Pattern",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/concacaf-world-cup-qualifying-2022"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/cutouts/mnt/aaronson-white-playernameboxingposterleft.ashx?h=1160&la=en-US&w=880&rev=a55073365717424f8cfa329ff60db0ed&hash=488F99C9ACAAD77C021EF6EC073DE417",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/cutouts/mnt/antonee-robinson-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=79fc426b7dcb4419a9fae64ce73e8c5a&hash=C0AF7FD14C68F2B886B48142E233AAAC",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "Presented by VW", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "Lower.com Field", "editable": null},
		"stadiumLocation": {"value": "Columbus, Ohio", "editable": null},
		"stadiumCountry": {"value": "USA", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/app/broadcasters/espn2_logo.ashx?h=52&la=en-US&w=120&rev=8b583676054f469c997298102fc43568&hash=495D7043006AA51910F46539C08D4624",
					"alt": "ESPN2",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.espn.com/watch/?type=live&channel=espn2", "editable": null},
			"iosUrl": {"value": "https://apps.apple.com/us/app/espn-scorecenter/id317469184", "editable": null},
			"androidUrl": {"value": "https://play.google.com/store/apps/details?id=com.espn.score_center", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/app/broadcasters/espn-plus-logo.ashx?h=102&la=en-US&w=279&rev=38498fb15530436eb56e83bd2ef0401c&hash=4AC50301D2633C6CA0C0FC0D52A695E4",
					"alt": "ESPN+",
					"width": "279",
					"height": "102"
				}, "editable": null
			},
			"webUrl": {
				"value": "https://plus.espn.com/?ex_cid=DSS-Search-Google-71700000068344301-&s_kwcid=AL!8468!3!495087148015!e!!g!!espn%20plus&cid=DSS-Search-Google-71700000068344301-&gclid=CjwKCAjwkvWKBhB4EiwA-GHjFmIe-aWoIQXmQA3zfn56VqWP--ySFd41sgrOR8S3hUQWsY-5nYAwBhoCwVQQAvD_BwE&gclsrc=aw.ds",
				"editable": null
			},
			"iosUrl": {"value": "https://apps.apple.com/us/app/espn-scorecenter/id317469184", "editable": null},
			"androidUrl": {"value": "https://play.google.com/store/apps/details?id=com.espn.score_center", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogounimasblack2x.ashx?h=52&la=en-US&w=120&rev=f13300dc0e3e48ae9888634da0ce427a&hash=C91D2E6B043C1E965DAB151D3EA3B031",
					"alt": "UniMAS",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.univisionnow.com/channels", "editable": null},
			"iosUrl": {"value": "https://www.univisionnow.com/channels", "editable": null},
			"androidUrl": {"value": "https://www.univisionnow.com/channels", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tundlogo.ashx?h=52&la=en-US&w=120&rev=9cc1f63328c54407a1efc533b869bdc5&hash=8A3D704527709F5BB4D4149320DFB4DC",
					"alt": "TUDN",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.tudn.com/", "editable": null},
			"iosUrl": {"value": "https://www.tudn.com/", "editable": null},
			"androidUrl": {"value": "https://www.tudn.com/", "editable": null}
		}],
		"currentItemId": "a1a4ef85-ed0c-4a72-88e8-171099811464"
	},
	"dateTime": "2021-10-13T23:00:00Z",
	"dateTimeObject": "2021-10-13T23:00:00+00:00"
}, {
	"matchId": "7fc44zzd7t4bkdax6g4jl4f10",
	"description": "Panama vs United States",
	"tournamentCalendarId": "8atuetifgnlt3q6oprwn7x9pm",
	"tournamentCalendarName": "2022 Qatar",
	"stageName": "Final Round",
	"status": 0,
	"goals": [{
		"contestantId": "8856r86rattc8kxn6a0l9tb2t",
		"periodId": 2,
		"timeMin": 54,
		"goalType": 0,
		"scorerId": "4ajhe29nxseyo6wqg3hjdghcl",
		"scorerName": "A. Godoy",
		"assistPlayerId": "6fmrelr9khhkywqr8goutjkr9",
		"assistPlayerName": "É. Davis"
	}],
	"sponsor": null,
	"date": "2021-10-10T22:00:00",
	"time": "22:00:00Z",
	"cancelled": false,
	"lengthMin": 98,
	"lengthSec": 24,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "eo2jzsyy614ulym1rlj90xe6v",
		"longName": "Estadio Rommel Fernández Gutiérrez",
		"shortName": "Estadio Rommel Fernández Gutiérrez",
		"location": "Ciudad de Panamá",
		"country": "Panama"
	},
	"attendance": 21000,
	"competition": {
		"id": "3a0j0giz3c3ajw9h59evv7lqt",
		"name": "WC Qualification Concacaf",
		"code": "WQC",
		"format": "International cup",
		"startDate": "2021-03-24T00:00:00Z",
		"endDate": "2022-03-30T00:00:00Z",
		"isFriendly": false
	},
	"periods": [{
		"id": 1,
		"start": "2021-10-10T22:06:55Z",
		"end": "2021-10-10T22:53:13Z",
		"lengthMin": "46",
		"lengthSec": "18",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2021-10-10T23:09:53Z",
		"end": "2021-10-11T00:01:59Z",
		"lengthMin": "52",
		"lengthSec": "6",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "8856r86rattc8kxn6a0l9tb2t",
		"name": "Panama",
		"officialName": "Panama",
		"shortName": "Panama",
		"code": "PAN",
		"position": "home",
		"score": 1,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "9vh2u1p4ppm597tjfahst2m3n",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "away",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/concacaf-world-cup-qualifying-2022/matches/panama-vs-united-states-10-10-21",
	"sitecoreData": {
		"link1": {"value": {"href": ""}, "editable": null},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {
			"value": {
				"href": "/stories/2021/10/2022-concacaf-world-cup-qualifying-usa-vs-panama-preview-schedule-tv-channels-start-time-standings",
				"querystring": "",
				"target": "",
				"linktype": "internal",
				"id": "{DBED4530-8452-465E-B44C-0AAA203F3A81}",
				"url": "/USSF/Home/Stories/2021/10/2022 Concacaf World Cup Qualifying USA vs Panama Preview Schedule TV Channels Start Time Standings"
			}, "editable": null
		},
		"matchRecapLink": {
			"value": {
				"href": "/stories/2021/10/2022-concacaf-world-cup-qualifying-usa-0-panama-1-match-report-stats-standings",
				"querystring": "",
				"target": "",
				"linktype": "internal",
				"id": "{6F534F9B-A064-46AA-98F7-55D4F04D1AB0}",
				"url": "/USSF/Home/Stories/2021/10/2022 Concacaf World Cup Qualifying USA 0 Panama 1 Match Report Stats Standings"
			}, "editable": null
		},
		"ticketsLink": {"value": {"href": ""}, "editable": null},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/backgroundred.ashx?h=1200&la=en-US&w=3840&rev=a73df9006659468d9b7fc9fe45452d72&hash=99DD665448A183451AFA8B9117FE5205",
				"alt": "",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/cardred.ashx?h=720&la=en-US&w=670&rev=18fd56e215454fc9971223f6e38a577d&hash=F78F6ED6B0027EF6121A12133AF0F1E1",
				"alt": "Red Chevron Background Pattern",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/concacaf-world-cup-qualifying-2022"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/cutouts/mnt/dest-white-bigger-playernameboxingposterleft.ashx?h=1160&la=en-US&w=880&rev=367f0eb6bdae44be8461c5e7823ff27b&hash=FA71B3ABE073FB1C0DD2CBD795B80C5D",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/cutouts/mnt/adams-white-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=4d1d57e1522f424a96b24862418268ab&hash=1B8D42E508857F8F422370CBB4C20C3B",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "Estadio Rommel Fernández", "editable": null},
		"stadiumLocation": {"value": "Panama City", "editable": null},
		"stadiumCountry": {"value": "Panama", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/paramount_plus_logo.ashx?h=207&la=en-US&w=628&rev=8b46ffd2502043f1bbbad3dd8c32f183&hash=79D1F3FFA9177BC1B09380B983F168AF",
					"alt": "Paramount+",
					"width": "628",
					"height": "207"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.paramountplus.com/", "editable": null},
			"iosUrl": {"value": "https://www.paramountplus.com/", "editable": null},
			"androidUrl": {"value": "https://www.paramountplus.com/", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogouniversoblack2x.ashx?h=52&la=en-US&w=120&rev=e8fd76c116cd408195c3374e4ecbc87c&hash=F88742E24C3283C03874ABD4661E786F",
					"alt": "UNIVERSO",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.nbc.com/networks/nbc-universo", "editable": null},
			"iosUrl": {"value": "https://apps.apple.com/us/app/universo-now/id869444353", "editable": null},
			"androidUrl": {
				"value": "https://play.google.com/store/apps/details?id=com.nbcu.tve.universo&hl=en_US&gl=US",
				"editable": null
			}
		}],
		"currentItemId": "bb71b59e-3522-4e6f-8773-e8aebde18314"
	},
	"dateTime": "2021-10-10T22:00:00Z",
	"dateTimeObject": "2021-10-10T22:00:00+00:00"
}, {
	"matchId": "MatchStub_6a1c3b18-13a3-4a18-b78c-693eb54dd760",
	"description": "United States vs Jamaica",
	"tournamentCalendarId": "8atuetifgnlt3q6oprwn7x9pm",
	"tournamentCalendarName": "2022 Qatar",
	"stageName": "Final Round",
	"status": 1,
	"goals": [],
	"sponsor": "",
	"date": "2021-10-07T23:30:00",
	"time": "23:30:00Z",
	"cancelled": false,
	"lengthMin": 0,
	"lengthSec": 0,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "5ylpm7oz3c8tplmcb4nisv6dw",
		"longName": "Q2 Stadium",
		"shortName": "Q2 Stadium",
		"location": "Austin, Texas",
		"country": "USA"
	},
	"attendance": 0,
	"competition": {
		"id": "3a0j0giz3c3ajw9h59evv7lqt",
		"name": "Concacaf FIFA World Cup Qualifying 2022",
		"code": "WQC",
		"format": "International cup",
		"startDate": "2021-03-24T00:00:00Z",
		"endDate": "2022-03-30T00:00:00Z",
		"isFriendly": false
	},
	"periods": [],
	"currentPeriod": 16,
	"manager": null,
	"contestants": [{
		"id": "9vh2u1p4ppm597tjfahst2m3n",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "home",
		"score": 0,
		"heroFlairTitle": "",
		"heroFlairName": "",
		"heroFlairBody": ""
	}, {
		"id": "8yhjl13p7luh3v905btbvz42t",
		"name": "Jamaica",
		"officialName": "Jamaica",
		"shortName": "Jamaica",
		"code": "JAM",
		"position": "away",
		"score": 0,
		"heroFlairTitle": "",
		"heroFlairName": "",
		"heroFlairBody": ""
	}],
	"broadcastLinks": [{
		"imageURL": "https://cdn.ussoccer.com/-/media/project/ussf/app/broadcasters/espn2_logo.ashx?h=52&la=en-US&w=120&rev=8b583676054f469c997298102fc43568&hash=495D7043006AA51910F46539C08D4624",
		"imageAltText": "ESPN2",
		"broadcastURLWeb": "https://www.espn.com/watch/?type=live&channel=espn2",
		"broadcastURLAndroid": "https://play.google.com/store/apps/details?id=com.espn.score_center",
		"broadcastURLiOS": "https://apps.apple.com/us/app/espn-scorecenter/id317469184"
	}, {
		"imageURL": "https://cdn.ussoccer.com/-/media/project/ussf/logos/ussf-logos/broadcasters/tundlogo.ashx?h=52&la=en-US&w=120&rev=43a69b8a0ca94cccbfcd2facca429e4f&hash=3C32097BB45E1945D77E39AC0FE13B8D",
		"imageAltText": "TUDN",
		"broadcastURLWeb": "https://www.tudn.com/",
		"broadcastURLAndroid": "https://www.tudn.com/",
		"broadcastURLiOS": "https://www.tudn.com/"
	}, {
		"imageURL": "https://cdn.ussoccer.com/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogounivisionblack2x.ashx?h=52&la=en-US&w=120&rev=74afa315b7004bcd9eca46492207982c&hash=9111B157D46AB86A17876503A61DB415",
		"imageAltText": "UniMAS",
		"broadcastURLWeb": "https://www.univisionnow.com/channels",
		"broadcastURLAndroid": "https://www.univisionnow.com/channels",
		"broadcastURLiOS": "https://www.univisionnow.com/channels"
	}],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": "tickets",
		"url": "https://www.ticketmaster.com/usmnt-v-jamaica-2022-fifa-world-austin-texas-10-07-2021/event/3A005AFDB5D964B0",
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00Z",
		"generalAvailabilityDate": "0001-01-01T00:00:00Z"
	},
	"groupTicketUrl": "",
	"groupTicketText": "",
	"matchFeedUrl": "/competitions/3a0j0giz3c3ajw9h59evv7lqt/matches/MatchStub_6a1c3b18-13a3-4a18-b78c-693eb54dd760",
	"sitecoreData": null,
	"dateTime": "2021-10-07T23:30:00Z",
	"dateTimeObject": "2021-10-07T23:30:00+00:00"
}, {
	"matchId": "MatchStub_6a1c3b18-13a3-4a18-b78c-693eb54dd76c",
	"description": null,
	"tournamentCalendarId": null,
	"tournamentCalendarName": null,
	"stageName": "Andrew Test TBD",
	"status": 1,
	"goals": [],
	"sponsor": null,
	"date": "2021-10-02T21:00:00",
	"time": "21:00:00Z",
	"cancelled": false,
	"lengthMin": 0,
	"lengthSec": 0,
	"addedTimeForCurrentPeriod": 0,
	"venue": {"id": null, "longName": "Miller Park", "shortName": null, "location": "Milwaukee", "country": "USA"},
	"attendance": 0,
	"competition": {
		"id": "",
		"name": "International Friendly",
		"code": null,
		"format": null,
		"startDate": "2021-06-28T13:24:59.2111762Z",
		"endDate": "2021-06-28T13:24:59.2111765Z",
		"isFriendly": true
	},
	"periods": [],
	"currentPeriod": 0,
	"manager": null,
	"contestants": [{
		"id": "9vh2u1p4ppm597tjfahst2m3n",
		"name": null,
		"officialName": null,
		"shortName": null,
		"code": "USA",
		"position": "home",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": null,
		"name": null,
		"officialName": null,
		"shortName": null,
		"code": "MEX",
		"position": "away",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [{
		"imageURL": "https://cdn.ussoccer.com/-/media/project/ussf/app/broadcasters/espn2_logo.ashx?h=52&la=en-US&w=120&rev=8b583676054f469c997298102fc43568&hash=495D7043006AA51910F46539C08D4624",
		"imageAltText": "ESPN2",
		"broadcastURLWeb": "https://www.espn.com/watch/?type=live&channel=espn2",
		"broadcastURLAndroid": "https://play.google.com/store/apps/details?id=com.espn.score_center",
		"broadcastURLiOS": "https://apps.apple.com/us/app/espn-scorecenter/id317469184"
	}, {
		"imageURL": "https://cdn.ussoccer.com/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogounimasblack2x.ashx?h=52&la=en-US&w=120&rev=f395ee5595a540f081b0c224662544a7&hash=EB2DE9D597B8B15B51AB03B77F2DF717",
		"imageAltText": "UniMAS",
		"broadcastURLWeb": "https://www.univisionnow.com/channels",
		"broadcastURLAndroid": "https://www.univisionnow.com/channels",
		"broadcastURLiOS": "https://www.univisionnow.com/channels"
	}, {
		"imageURL": "https://cdn.ussoccer.com/-/media/project/ussf/logos/ussf-logos/broadcasters/tundlogo.ashx?h=52&la=en-US&w=120&rev=43a69b8a0ca94cccbfcd2facca429e4f&hash=3C32097BB45E1945D77E39AC0FE13B8D",
		"imageAltText": "TUDN",
		"broadcastURLWeb": "https://www.tudn.com/",
		"broadcastURLAndroid": "https://www.tudn.com/",
		"broadcastURLiOS": "https://www.tudn.com/"
	}],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": "Tickets",
		"url": "https://www.ticketmaster.com/usmnt-v-costa-rica-presented-by-columbus-ohio-10-13-2021/event/05005B11FBA250CA",
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00Z",
		"generalAvailabilityDate": "0001-01-01T00:00:00Z"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": null,
	"sitecoreData": null,
	"dateTime": "2021-10-02T21:00:00Z",
	"dateTimeObject": "2021-10-02T21:00:00+00:00"
}, {
	"matchId": "MatchStub_c27432e7-fcd7-47ed-b8d3-6e76c4e23ab2",
	"description": null,
	"tournamentCalendarId": null,
	"tournamentCalendarName": null,
	"stageName": "Andrew Test TBD",
	"status": 1,
	"goals": [],
	"sponsor": null,
	"date": "2021-10-02T21:00:00",
	"time": "TBD",
	"cancelled": false,
	"lengthMin": 0,
	"lengthSec": 0,
	"addedTimeForCurrentPeriod": 0,
	"venue": {"id": null, "longName": "Miller Park", "shortName": null, "location": "Milwaukee", "country": "USA"},
	"attendance": 0,
	"competition": {
		"id": "",
		"name": "International Friendly",
		"code": null,
		"format": null,
		"startDate": "2021-06-28T13:25:36.9386118Z",
		"endDate": "2021-06-28T13:25:36.938612Z",
		"isFriendly": true
	},
	"periods": [],
	"currentPeriod": 0,
	"manager": null,
	"contestants": [{
		"id": "9vh2u1p4ppm597tjfahst2m3n",
		"name": null,
		"officialName": null,
		"shortName": null,
		"code": "USA",
		"position": "home",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": null,
		"name": null,
		"officialName": null,
		"shortName": null,
		"code": "TBD",
		"position": "away",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": null,
	"sitecoreData": null,
	"dateTime": "2021-10-02TTBD",
	"dateTimeObject": "0001-01-01T00:00:00"
}, {
	"matchId": "7e95bj124bfcemcj8pbs794ic",
	"description": "Honduras vs United States",
	"tournamentCalendarId": "8atuetifgnlt3q6oprwn7x9pm",
	"tournamentCalendarName": "2022 Qatar",
	"stageName": "Final Round",
	"status": 0,
	"goals": [{
		"contestantId": "6ngz19ekzqqufux1sswnhzlsw",
		"periodId": 1,
		"timeMin": 27,
		"goalType": 0,
		"scorerId": "d6dnizold4iyou8697q8adwq1",
		"scorerName": "B. Moya",
		"assistPlayerId": "9gissbisipdwaqf8l9jv9x65m",
		"assistPlayerName": "D. Rodríguez"
	}, {
		"contestantId": "9vh2u1p4ppm597tjfahst2m3n",
		"periodId": 2,
		"timeMin": 48,
		"goalType": 0,
		"scorerId": "bgc1psqiv7vagmphfl920nbah",
		"scorerName": "A. Robinson",
		"assistPlayerId": "emglxnqrlqskt8krglv7sziwa",
		"assistPlayerName": "R. Pepi"
	}, {
		"contestantId": "9vh2u1p4ppm597tjfahst2m3n",
		"periodId": 2,
		"timeMin": 75,
		"goalType": 0,
		"scorerId": "emglxnqrlqskt8krglv7sziwa",
		"scorerName": "R. Pepi",
		"assistPlayerId": "269gnyszrb8ezl57m1ampsdlh",
		"assistPlayerName": "D. Yedlin"
	}, {
		"contestantId": "9vh2u1p4ppm597tjfahst2m3n",
		"periodId": 2,
		"timeMin": 86,
		"goalType": 0,
		"scorerId": "8rrcscn29rs1gk8mi1pwpu92i",
		"scorerName": "B. Aaronson",
		"assistPlayerId": "emglxnqrlqskt8krglv7sziwa",
		"assistPlayerName": "R. Pepi"
	}, {
		"contestantId": "9vh2u1p4ppm597tjfahst2m3n",
		"periodId": 2,
		"timeMin": 93,
		"goalType": 0,
		"scorerId": "7shrs6664saem601vmahjw7h1",
		"scorerName": "S. Lletget",
		"assistPlayerId": null,
		"assistPlayerName": null
	}],
	"sponsor": null,
	"date": "2021-09-09T02:30:00",
	"time": "02:30:00Z",
	"cancelled": false,
	"lengthMin": 98,
	"lengthSec": 2,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "lr0mrzubq14s80rkr8ycvo5t",
		"longName": "Estadio Olímpico Metropolitano",
		"shortName": "Estadio Olímpico Metropolitano",
		"location": "San Pedro Sula",
		"country": "Honduras"
	},
	"attendance": 31000,
	"competition": {
		"id": "3a0j0giz3c3ajw9h59evv7lqt",
		"name": "WC Qualification Concacaf",
		"code": "WQC",
		"format": "International cup",
		"startDate": "2021-03-24T00:00:00Z",
		"endDate": "2022-03-30T00:00:00Z",
		"isFriendly": false
	},
	"periods": [{
		"id": 1,
		"start": "2021-09-09T02:30:09Z",
		"end": "2021-09-09T03:19:06Z",
		"lengthMin": "48",
		"lengthSec": "57",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2021-09-09T03:36:48Z",
		"end": "2021-09-09T04:25:53Z",
		"lengthMin": "49",
		"lengthSec": "5",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "6ngz19ekzqqufux1sswnhzlsw",
		"name": "Honduras",
		"officialName": "Honduras",
		"shortName": "Honduras",
		"code": "HON",
		"position": "home",
		"score": 1,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "9vh2u1p4ppm597tjfahst2m3n",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "away",
		"score": 4,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/concacaf-world-cup-qualifying-2022/matches/honduras-vs-united-states-9-8-21",
	"sitecoreData": {
		"link1": {"value": {"href": ""}, "editable": null},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {"value": {"href": ""}, "editable": null},
		"matchRecapLink": {
			"value": {
				"href": "/stories/2021/09/2022-concacaf-world-cup-qualifying-usa-4-honduras-1-match-report-stats-standings",
				"querystring": "",
				"target": "",
				"linktype": "internal",
				"id": "{9DA896E4-C20D-42F5-9499-50FC40099905}",
				"url": "/USSF/Home/Stories/2021/09/2022 Concacaf World Cup Qualifying USA 4 Honduras 1 Match Report Stats Standings"
			}, "editable": null
		},
		"ticketsLink": {"value": {"href": ""}, "editable": null},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/backgroundred.ashx?h=1200&la=en-US&w=3840&rev=a73df9006659468d9b7fc9fe45452d72&hash=99DD665448A183451AFA8B9117FE5205",
				"alt": "",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/cardred.ashx?h=720&la=en-US&w=670&rev=18fd56e215454fc9971223f6e38a577d&hash=F78F6ED6B0027EF6121A12133AF0F1E1",
				"alt": "Red Chevron Background Pattern",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/concacaf-world-cup-qualifying-2022"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/cutouts/mnt/adams-white-playernameboxingposterleft.ashx?h=1160&la=en-US&w=880&rev=03b39e40920247fd9e9135e115394a95&hash=8C1B7FA925206B392C7C88AA5FCFE339",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/cutouts/mnt/pulisic-white-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=befa3a4e97c24807a3d1def15be9a54c&hash=CA5AA67430A4D6F7AC1D46E9CFDBA134",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "Estadio Olimpico Metropolitano", "editable": null},
		"stadiumLocation": {"value": "San Pedro Sula", "editable": null},
		"stadiumCountry": {"value": "Honduras", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/paramount_plus_logo.ashx?h=207&la=en-US&w=628&rev=8b46ffd2502043f1bbbad3dd8c32f183&hash=79D1F3FFA9177BC1B09380B983F168AF",
					"alt": "Paramount+",
					"width": "628",
					"height": "207"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.paramountplus.com/", "editable": null},
			"iosUrl": {"value": "https://www.paramountplus.com/", "editable": null},
			"androidUrl": {"value": "https://www.paramountplus.com/", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/app/broadcasters/tvlogotelemundoblack2x.ashx?h=82&la=en-US&w=189&rev=dee0eb69b786485fa8bf57f05a577746&hash=928F9B2CCF345FD4E303F06E5560C310",
					"alt": "Telemundo",
					"width": "189",
					"height": "82"
				}, "editable": null
			},
			"webUrl": {"value": "http://now.telemundo.com/live", "editable": null},
			"iosUrl": {"value": "http://now.telemundo.com/live", "editable": null},
			"androidUrl": {"value": "http://now.telemundo.com/live", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogouniversoblack2x.ashx?h=52&la=en-US&w=120&rev=e8fd76c116cd408195c3374e4ecbc87c&hash=F88742E24C3283C03874ABD4661E786F",
					"alt": "UNIVERSO",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.nbc.com/networks/nbc-universo", "editable": null},
			"iosUrl": {"value": "https://apps.apple.com/us/app/universo-now/id869444353", "editable": null},
			"androidUrl": {
				"value": "https://play.google.com/store/apps/details?id=com.nbcu.tve.universo&hl=en_US&gl=US",
				"editable": null
			}
		}],
		"currentItemId": "e1011691-470e-47d1-99f7-04169e5f0e28"
	},
	"dateTime": "2021-09-09T02:30:00Z",
	"dateTimeObject": "2021-09-09T02:30:00+00:00"
}, {
	"matchId": "7dpqfrk4vydha1585e2tdu1w4",
	"description": "United States vs Canada",
	"tournamentCalendarId": "8atuetifgnlt3q6oprwn7x9pm",
	"tournamentCalendarName": "2022 Qatar",
	"stageName": "Final Round",
	"status": 0,
	"goals": [{
		"contestantId": "9vh2u1p4ppm597tjfahst2m3n",
		"periodId": 2,
		"timeMin": 55,
		"goalType": 0,
		"scorerId": "8rrcscn29rs1gk8mi1pwpu92i",
		"scorerName": "B. Aaronson",
		"assistPlayerId": "bgc1psqiv7vagmphfl920nbah",
		"assistPlayerName": "A. Robinson"
	}, {
		"contestantId": "eg7vduna0h3vis1wd47s41za7",
		"periodId": 2,
		"timeMin": 62,
		"goalType": 0,
		"scorerId": "3rrges782prcprsn207dk4zit",
		"scorerName": "C. Larin",
		"assistPlayerId": "582uvj0i3dvm0zwbou9q68pgp",
		"assistPlayerName": "A. Davies"
	}],
	"sponsor": null,
	"date": "2021-09-06T00:10:00",
	"time": "00:10:00Z",
	"cancelled": false,
	"lengthMin": 95,
	"lengthSec": 21,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "e074yxkrjbdeyo2acc1asvovr",
		"longName": "Nissan Stadium",
		"shortName": "Nissan Stadium",
		"location": "Nashville, Tennessee",
		"country": "USA"
	},
	"attendance": 43028,
	"competition": {
		"id": "3a0j0giz3c3ajw9h59evv7lqt",
		"name": "WC Qualification Concacaf",
		"code": "WQC",
		"format": "International cup",
		"startDate": "2021-03-24T00:00:00Z",
		"endDate": "2022-03-30T00:00:00Z",
		"isFriendly": false
	},
	"periods": [{
		"id": 1,
		"start": "2021-09-06T00:14:23Z",
		"end": "2021-09-06T01:01:43Z",
		"lengthMin": "47",
		"lengthSec": "20",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2021-09-06T01:17:51Z",
		"end": "2021-09-06T02:05:52Z",
		"lengthMin": "48",
		"lengthSec": "1",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "9vh2u1p4ppm597tjfahst2m3n",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "home",
		"score": 1,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "eg7vduna0h3vis1wd47s41za7",
		"name": "Canada",
		"officialName": "Canada",
		"shortName": "Canada",
		"code": "CAN",
		"position": "away",
		"score": 1,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/concacaf-world-cup-qualifying-2022/matches/united-states-vs-canada",
	"sitecoreData": {
		"link1": {"value": {"href": ""}, "editable": null},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {"value": {"href": ""}, "editable": null},
		"matchRecapLink": {
			"value": {
				"href": "/stories/2021/09/2022-concacaf-world-cup-qualifying-usa1-canada-1-match-report-stats-standings",
				"querystring": "",
				"target": "",
				"linktype": "internal",
				"id": "{04997CBB-4286-4E50-BFEE-56B68FDD899C}",
				"url": "/USSF/Home/Stories/2021/09/2022 Concacaf World Cup Qualifying USA1 Canada 1 Match Report Stats Standings"
			}, "editable": null
		},
		"ticketsLink": {
			"value": {
				"href": "https://www.ticketmaster.com/usmnt-v-canada-presented-by-volkswagen-nashville-tennessee-09-05-2021/event/1B005ADB291557A4",
				"text": "Tickets",
				"linktype": "external",
				"url": "https://www.ticketmaster.com/usmnt-v-canada-presented-by-volkswagen-nashville-tennessee-09-05-2021/event/1B005ADB291557A4",
				"anchor": "",
				"title": "Tickets",
				"target": "_blank"
			}, "editable": null
		},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/backgroundred.ashx?h=1200&la=en-US&w=3840&rev=a73df9006659468d9b7fc9fe45452d72&hash=99DD665448A183451AFA8B9117FE5205",
				"alt": "",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/cardred.ashx?h=720&la=en-US&w=670&rev=18fd56e215454fc9971223f6e38a577d&hash=F78F6ED6B0027EF6121A12133AF0F1E1",
				"alt": "Red Chevron Background Pattern",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/concacaf-world-cup-qualifying-2022"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/cutouts/mnt/dest-white-playernameboxingposterleft.ashx?h=1160&la=en-US&w=880&rev=b7fbdd6fe133495c82107e37c9321c83&hash=77772DEEBBC16DA21232EEAD100D163E",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/cutouts/mnt/pulisic-white-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=befa3a4e97c24807a3d1def15be9a54c&hash=CA5AA67430A4D6F7AC1D46E9CFDBA134",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "Presented by VW", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "", "editable": null},
		"stadiumLocation": {"value": "", "editable": null},
		"stadiumCountry": {"value": "", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogofs1black2x.ashx?h=52&la=en-US&w=120&rev=79d1fc98d79c4aa582431482dc9188aa&hash=222581B287E6DC17C26116C6B3C28131",
					"alt": "FS1",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.foxsports.com/live", "editable": null},
			"iosUrl": {"value": "https://www.foxsports.com/mobile", "editable": null},
			"androidUrl": {"value": "https://www.foxsports.com/mobile", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/app/broadcasters/tvlogounimasblack2x.ashx?h=52&la=en-US&w=120&rev=880a13daf95f48b0a093ce7ea3b2bfd1&hash=5DF6DBCB80CD061330B3AE7E18D1374F",
					"alt": "UniMAS",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.univisionnow.com/page/landing", "editable": null},
			"iosUrl": {"value": "https://www.univisionnow.com/page/landing", "editable": null},
			"androidUrl": {"value": "https://www.univisionnow.com/page/landing", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tundlogo.ashx?h=52&la=en-US&w=120&rev=9cc1f63328c54407a1efc533b869bdc5&hash=8A3D704527709F5BB4D4149320DFB4DC",
					"alt": "TUDN",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.tudn.com/", "editable": null},
			"iosUrl": {"value": "https://www.tudn.com/", "editable": null},
			"androidUrl": {"value": "https://www.tudn.com/", "editable": null}
		}],
		"currentItemId": "5b29dd68-180f-4550-840e-ce1c724bd1ba"
	},
	"dateTime": "2021-09-06T00:10:00Z",
	"dateTimeObject": "2021-09-06T00:10:00+00:00"
}, {
	"matchId": "7d8m06ttd79rlgs5ujtfwbwno",
	"description": "El Salvador vs United States",
	"tournamentCalendarId": "8atuetifgnlt3q6oprwn7x9pm",
	"tournamentCalendarName": "2022 Qatar",
	"stageName": "Final Round",
	"status": 0,
	"goals": [],
	"sponsor": null,
	"date": "2021-09-03T02:05:00",
	"time": "02:05:00Z",
	"cancelled": false,
	"lengthMin": 93,
	"lengthSec": 51,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "bzwg9oghjtawn5barea922byr",
		"longName": "Estadio Cuscatlán",
		"shortName": "Estadio Cuscatlán",
		"location": "San Salvador",
		"country": "El Salvador"
	},
	"attendance": 29000,
	"competition": {
		"id": "3a0j0giz3c3ajw9h59evv7lqt",
		"name": "WC Qualification Concacaf",
		"code": "WQC",
		"format": "International cup",
		"startDate": "2021-03-24T00:00:00Z",
		"endDate": "2022-03-30T00:00:00Z",
		"isFriendly": false
	},
	"periods": [{
		"id": 1,
		"start": "2021-09-03T02:05:59Z",
		"end": "2021-09-03T02:51:50Z",
		"lengthMin": "45",
		"lengthSec": "51",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2021-09-03T03:06:53Z",
		"end": "2021-09-03T03:54:53Z",
		"lengthMin": "48",
		"lengthSec": "0",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "avc9qnhswi33ld54pvpp4fmkg",
		"name": "El Salvador",
		"officialName": "El Salvador",
		"shortName": "El Salvador",
		"code": "SLV",
		"position": "home",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "9vh2u1p4ppm597tjfahst2m3n",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "away",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/concacaf-world-cup-qualifying-2022/matches/el-salvador-vs-united-states-9-2-21",
	"sitecoreData": {
		"link1": {"value": {"href": ""}, "editable": null},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {"value": {"href": ""}, "editable": null},
		"matchRecapLink": {
			"value": {
				"href": "/stories/2021/09/2022-concacaf-world-cup-qualifying-usa-0-el-salvador-0-match-report-stats-standings",
				"querystring": "",
				"target": "",
				"linktype": "internal",
				"id": "{A6290EF6-D215-421F-AC10-D67BD371C995}",
				"url": "/USSF/Home/Stories/2021/09/2022 Concacaf World Cup Qualifying USA 0 El Salvador 0 Match Report Stats Standings"
			}, "editable": null
		},
		"ticketsLink": {"value": {"href": ""}, "editable": null},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/backgroundred.ashx?h=1200&la=en-US&w=3840&rev=a73df9006659468d9b7fc9fe45452d72&hash=99DD665448A183451AFA8B9117FE5205",
				"alt": "",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/cardred.ashx?h=720&la=en-US&w=670&rev=18fd56e215454fc9971223f6e38a577d&hash=F78F6ED6B0027EF6121A12133AF0F1E1",
				"alt": "Red Chevron Background Pattern",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/concacaf-world-cup-qualifying-2022"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/cutouts/mnt/weston-white-boxing-left-png.ashx?h=1160&la=en-US&w=880&rev=295eeec8ee044710acc7b3a42defe2d5&hash=1F7B2B6925B4136ACFAB5A0058EDFA20",
				"alt": "Weston McKennie",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/cutouts/mnt/gio-reyna-white-bigger-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=dfd596dfcadc4713891b7dc1f438b25a&hash=93D0BC7C9C4EAD716F44D3EA67BBAC38",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "Presented by VW", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "Estadio Cuscatlán", "editable": null},
		"stadiumLocation": {"value": "San Salvador", "editable": null},
		"stadiumCountry": {"value": "El Salvador", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/app/broadcasters/cbssn-paramount-logo.ashx?h=207&la=en-US&w=1299&rev=56e7fee96b8a46d6b05d858bc22b5256&hash=158D9C121F41930B0D4DEDD38730F9F0",
					"alt": "CBSSN & Paramount+",
					"width": "1299",
					"height": "207"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.paramountplus.com/", "editable": null},
			"iosUrl": {"value": "https://www.paramountplus.com/", "editable": null},
			"androidUrl": {"value": "https://www.paramountplus.com/", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogouniversoblack2x.ashx?h=52&la=en-US&w=120&rev=e8fd76c116cd408195c3374e4ecbc87c&hash=F88742E24C3283C03874ABD4661E786F",
					"alt": "UNIVERSO",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.nbc.com/networks/nbc-universo", "editable": null},
			"iosUrl": {"value": "https://apps.apple.com/us/app/universo-now/id869444353", "editable": null},
			"androidUrl": {
				"value": "https://play.google.com/store/apps/details?id=com.nbcu.tve.universo&hl=en_US&gl=US",
				"editable": null
			}
		}],
		"currentItemId": "a8b83793-6880-4be3-8856-af7bbcea60a7"
	},
	"dateTime": "2021-09-03T02:05:00Z",
	"dateTimeObject": "2021-09-03T02:05:00+00:00"
}, {
	"matchId": "MatchStub_4b4fc07f-7f67-4a60-9ce7-bc3db8d389ea",
	"description": null,
	"tournamentCalendarId": null,
	"tournamentCalendarName": null,
	"stageName": "Andrew Test TBD",
	"status": 1,
	"goals": [],
	"sponsor": null,
	"date": "2021-08-10T21:00:00",
	"time": "21:00:00Z",
	"cancelled": false,
	"lengthMin": 0,
	"lengthSec": 0,
	"addedTimeForCurrentPeriod": 0,
	"venue": {"id": null, "longName": "Miller Park", "shortName": null, "location": "Milwaukee", "country": "USA"},
	"attendance": 0,
	"competition": {
		"id": "",
		"name": "International Friendly",
		"code": null,
		"format": null,
		"startDate": "2021-08-06T13:09:51.1007054Z",
		"endDate": "2021-08-06T13:09:51.1007058Z",
		"isFriendly": true
	},
	"periods": [],
	"currentPeriod": 0,
	"manager": null,
	"contestants": [{
		"id": "9vh2u1p4ppm597tjfahst2m3n",
		"name": null,
		"officialName": null,
		"shortName": null,
		"code": "USA",
		"position": "home",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": null,
		"name": null,
		"officialName": null,
		"shortName": null,
		"code": "JAM",
		"position": "away",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [{
		"imageURL": "string",
		"imageAltText": "string",
		"broadcastURLWeb": "string",
		"broadcastURLAndroid": "string",
		"broadcastURLiOS": "string"
	}],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": null,
	"sitecoreData": null,
	"dateTime": "2021-08-10T21:00:00Z",
	"dateTimeObject": "2021-08-10T21:00:00+00:00"
}, {
	"matchId": "MatchStub_d3457f08-92bc-48db-b80d-1f5fd97819f0",
	"description": null,
	"tournamentCalendarId": null,
	"tournamentCalendarName": null,
	"stageName": "Andrew Test TBD",
	"status": 1,
	"goals": [],
	"sponsor": null,
	"date": "2021-08-09T21:00:00",
	"time": "TBD",
	"cancelled": false,
	"lengthMin": 0,
	"lengthSec": 0,
	"addedTimeForCurrentPeriod": 0,
	"venue": {"id": null, "longName": "Miller Park", "shortName": null, "location": "Milwaukee", "country": "USA"},
	"attendance": 0,
	"competition": {
		"id": "",
		"name": "International Friendly",
		"code": null,
		"format": null,
		"startDate": "2021-08-06T13:09:23.699801Z",
		"endDate": "2021-08-06T13:09:23.6998013Z",
		"isFriendly": true
	},
	"periods": [],
	"currentPeriod": 0,
	"manager": null,
	"contestants": [{
		"id": "9vh2u1p4ppm597tjfahst2m3n",
		"name": null,
		"officialName": null,
		"shortName": null,
		"code": "USA",
		"position": "home",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": null,
		"name": null,
		"officialName": null,
		"shortName": null,
		"code": "JAM",
		"position": "away",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [{
		"imageURL": "string",
		"imageAltText": "string",
		"broadcastURLWeb": "string",
		"broadcastURLAndroid": "string",
		"broadcastURLiOS": "string"
	}],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": null,
	"sitecoreData": null,
	"dateTime": "2021-08-09TTBD",
	"dateTimeObject": "0001-01-01T00:00:00"
}, {
	"matchId": "1d70djbt834r2omfy96u9r2fo",
	"description": "United States vs Mexico",
	"tournamentCalendarId": "ecveqbaggkm48s6ty8fuz90q",
	"tournamentCalendarName": "2021",
	"stageName": "Final",
	"status": 0,
	"goals": [{
		"contestantId": "9vh2u1p4ppm597tjfahst2m3n",
		"periodId": 4,
		"timeMin": 117,
		"goalType": 0,
		"scorerId": "bjpjsx12xdi3bwsv35746arqh",
		"scorerName": "M. Robinson",
		"assistPlayerId": "6k22ppzw6wv0c4gskzxk28fyt",
		"assistPlayerName": "K. Acosta"
	}],
	"sponsor": null,
	"date": "2021-08-02T01:00:00",
	"time": "01:00:00Z",
	"cancelled": false,
	"lengthMin": 130,
	"lengthSec": 33,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "6i1ovuff5msnh6bt2h0ajbh1w",
		"longName": "Allegiant Stadium",
		"shortName": "Allegiant Stadium",
		"location": "Las Vegas, Nevada",
		"country": "USA"
	},
	"attendance": 61114,
	"competition": {
		"id": "f51991ex45qhp1p3iu74u4d4e",
		"name": "Concacaf Gold Cup",
		"code": "CGC",
		"format": "International cup",
		"startDate": "2021-07-11T00:00:00Z",
		"endDate": "2021-08-02T00:00:00Z",
		"isFriendly": false
	},
	"periods": [{
		"id": 1,
		"start": "2021-08-02T01:00:30Z",
		"end": "2021-08-02T01:50:43Z",
		"lengthMin": "50",
		"lengthSec": "13",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2021-08-02T02:05:47Z",
		"end": "2021-08-02T02:53:44Z",
		"lengthMin": "47",
		"lengthSec": "57",
		"addedTime": 0
	}, {
		"id": 3,
		"start": "2021-08-02T02:58:47Z",
		"end": "2021-08-02T03:13:52Z",
		"lengthMin": "15",
		"lengthSec": "5",
		"addedTime": 0
	}, {
		"id": 4,
		"start": "2021-08-02T03:16:41Z",
		"end": "2021-08-02T03:33:59Z",
		"lengthMin": "17",
		"lengthSec": "18",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "9vh2u1p4ppm597tjfahst2m3n",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "home",
		"score": 1,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "4vofb84dzb5fyc81n2ssws6ah",
		"name": "Mexico",
		"officialName": "Mexico",
		"shortName": "Mexico",
		"code": "MEX",
		"position": "away",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/concacaf-gold-cup-2021/matches/united-states-vs-mexico",
	"sitecoreData": {
		"link1": {"value": {"href": ""}, "editable": null},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {
			"value": {
				"href": "/stories/2021/07/2021-concacaf-gold-cup-final-usa-vs-mexico-preview-schedule-tv-channels-start-time",
				"querystring": "",
				"target": "",
				"linktype": "internal",
				"id": "{FBC5997E-4DC3-425A-AC4E-E4CCFD039F60}",
				"url": "/USSF/Home/Stories/2021/07/2021 Concacaf Gold Cup final USA vs Mexico Preview Schedule TV Channels Start Time"
			}, "editable": null
		},
		"matchRecapLink": {
			"value": {
				"href": "/stories/2021/07/2021-concacaf-gold-cup-final-usmnt-1-mexico-0-match-report-stats-and-bracket",
				"querystring": "",
				"target": "",
				"linktype": "internal",
				"id": "{1131E45C-5641-43D1-8BB4-B04B6CC1CEE1}",
				"url": "/USSF/Home/Stories/2021/07/2021 Concacaf Gold Cup Final USMNT 1 Mexico 0 Match Report Stats and Bracket"
			}, "editable": null
		},
		"ticketsLink": {
			"value": {
				"href": "https://www.ticketmaster.com/event/17005AC21FE5621F",
				"text": "Tickets",
				"linktype": "external",
				"url": "https://www.ticketmaster.com/event/17005AC21FE5621F",
				"anchor": "",
				"title": "Tickets",
				"target": "_blank"
			}, "editable": null
		},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/backgroundred.ashx?h=1200&la=en-US&w=3840&rev=a73df9006659468d9b7fc9fe45452d72&hash=99DD665448A183451AFA8B9117FE5205",
				"alt": "",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/cardred.ashx?h=720&la=en-US&w=670&rev=18fd56e215454fc9971223f6e38a577d&hash=F78F6ED6B0027EF6121A12133AF0F1E1",
				"alt": "Red Chevron Background Pattern",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/concacaf-gold-cup-2021"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/cutouts/mnt/dike-red-playernameboxingposterleft.ashx?h=1160&la=en-US&w=880&rev=117b29f11a1541769c60d03aaf609726&hash=BF370D3E5E4B8035E932719C6882C692",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/cutouts/mnt/vines-red-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=2c78b78fb59440dfb617f89c9a2d43e2&hash=7EDDE8034494B39BA0053BAFB3883DD0",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "", "editable": null},
		"stadiumLocation": {"value": "", "editable": null},
		"stadiumCountry": {"value": "", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogofs1black2x.ashx?h=52&la=en-US&w=120&rev=79d1fc98d79c4aa582431482dc9188aa&hash=222581B287E6DC17C26116C6B3C28131",
					"alt": "FS1",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.foxsports.com/live", "editable": null},
			"iosUrl": {"value": "https://www.foxsports.com/mobile", "editable": null},
			"androidUrl": {"value": "https://www.foxsports.com/mobile", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogounivisionblack2x.ashx?h=164&la=en-US&w=460&rev=0b74caaf17624eba9f02762e01758751&hash=1D2B578C26EF9C8B3CA5607C76C51DDD",
					"alt": "Univision",
					"width": "460",
					"height": "164"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.univisionnow.com/channels", "editable": null},
			"iosUrl": {"value": "https://www.univisionnow.com/channels", "editable": null},
			"androidUrl": {"value": "https://www.univisionnow.com/channels", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tundlogo.ashx?h=52&la=en-US&w=120&rev=9cc1f63328c54407a1efc533b869bdc5&hash=8A3D704527709F5BB4D4149320DFB4DC",
					"alt": "TUDN",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.tudn.com/", "editable": null},
			"iosUrl": {"value": "https://www.tudn.com/", "editable": null},
			"androidUrl": {"value": "https://www.tudn.com/", "editable": null}
		}],
		"currentItemId": "ea1c8b96-d4ab-486b-8ef8-2635617546b1"
	},
	"dateTime": "2021-08-02T01:00:00Z",
	"dateTimeObject": "2021-08-02T01:00:00+00:00"
}, {
	"matchId": "d5hob9zqx13kkkq7t6zin9yj8",
	"description": "Qatar vs United States",
	"tournamentCalendarId": "ecveqbaggkm48s6ty8fuz90q",
	"tournamentCalendarName": "2021",
	"stageName": "Semi-finals",
	"status": 0,
	"goals": [{
		"contestantId": "9vh2u1p4ppm597tjfahst2m3n",
		"periodId": 2,
		"timeMin": 86,
		"goalType": 0,
		"scorerId": "61y263ybiykc566mrl9w2bowl",
		"scorerName": "G. Zardes",
		"assistPlayerId": "6817xzrpglzo7g2mfzlnq90tm",
		"assistPlayerName": "N. Gioacchini"
	}],
	"sponsor": null,
	"date": "2021-07-29T23:30:00",
	"time": "23:30:00Z",
	"cancelled": false,
	"lengthMin": 102,
	"lengthSec": 11,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "5ylpm7oz3c8tplmcb4nisv6dw",
		"longName": "Q2 Stadium",
		"shortName": "Q2 Stadium",
		"location": "Austin, Texas",
		"country": "USA"
	},
	"attendance": 20500,
	"competition": {
		"id": "f51991ex45qhp1p3iu74u4d4e",
		"name": "Concacaf Gold Cup",
		"code": "CGC",
		"format": "International cup",
		"startDate": "2021-07-11T00:00:00Z",
		"endDate": "2021-08-02T00:00:00Z",
		"isFriendly": false
	},
	"periods": [{
		"id": 1,
		"start": "2021-07-29T23:30:28Z",
		"end": "2021-07-30T00:17:42Z",
		"lengthMin": "47",
		"lengthSec": "14",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2021-07-30T00:32:48Z",
		"end": "2021-07-30T01:27:45Z",
		"lengthMin": "54",
		"lengthSec": "57",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "ea68amhsn28ijs7bzjuzeqrz6",
		"name": "Qatar",
		"officialName": "Qatar",
		"shortName": "Qatar",
		"code": "QAT",
		"position": "home",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "9vh2u1p4ppm597tjfahst2m3n",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "away",
		"score": 1,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/concacaf-gold-cup-2021/matches/qatar-vs-united-states",
	"sitecoreData": {
		"link1": {"value": {"href": ""}, "editable": null},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {
			"value": {
				"href": "/stories/2021/07/2021-concacaf-gold-cup-semifinal-usa-vs-qatar-preview-schedule-tv-channels-start-time",
				"querystring": "",
				"target": "",
				"linktype": "internal",
				"id": "{1CDC9C22-D362-4352-AC1E-7C2888A77DE7}",
				"url": "/USSF/Home/Stories/2021/07/2021 Concacaf Gold Cup Semifinal USA vs Qatar Preview Schedule TV Channels Start Time"
			}, "editable": null
		},
		"matchRecapLink": {
			"value": {
				"href": "/stories/2021/07/2021-concacaf-gold-cup-semifinal-usmnt-1-qatar-0-match-report-stats-and-bracket",
				"querystring": "",
				"target": "",
				"linktype": "internal",
				"id": "{61198A99-FC34-416E-B22E-6C7426BB7AED}",
				"url": "/USSF/Home/Stories/2021/07/2021 Concacaf Gold Cup Semifinal USMNT 1 Qatar 0 Match Report Stats and Bracket"
			}, "editable": null
		},
		"ticketsLink": {
			"value": {
				"href": "https://seatgeek.com/concacaf-gold-cup-semifinal-tickets/soccer/2021-07-29-6-30-pm/5407521",
				"text": "Tickets",
				"linktype": "external",
				"url": "https://seatgeek.com/concacaf-gold-cup-semifinal-tickets/soccer/2021-07-29-6-30-pm/5407521",
				"anchor": "",
				"title": "Tickets",
				"target": "_blank"
			}, "editable": null
		},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/backgroundred.ashx?h=1200&la=en-US&w=3840&rev=a73df9006659468d9b7fc9fe45452d72&hash=99DD665448A183451AFA8B9117FE5205",
				"alt": "",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/cardred.ashx?h=720&la=en-US&w=670&rev=18fd56e215454fc9971223f6e38a577d&hash=F78F6ED6B0027EF6121A12133AF0F1E1",
				"alt": "Red Chevron Background Pattern",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/concacaf-gold-cup-2021"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/cutouts/mnt/matt-hoppe-red-playernameboxingposterleft.ashx?h=1160&la=en-US&w=880&rev=c33f561f125b452b94de42cdccf08016&hash=D503749D4B95F134B8A0294653188414",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/cutouts/mnt/miles-robinson-red2-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=212c954378cc412db10324bf7da7027a&hash=C60BED7E109C473ED6CE99D8036DC8C0",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "", "editable": null},
		"stadiumLocation": {"value": "", "editable": null},
		"stadiumCountry": {"value": "", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogofs1black2x.ashx?h=52&la=en-US&w=120&rev=79d1fc98d79c4aa582431482dc9188aa&hash=222581B287E6DC17C26116C6B3C28131",
					"alt": "FS1",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.foxsports.com/live", "editable": null},
			"iosUrl": {"value": "https://www.foxsports.com/mobile", "editable": null},
			"androidUrl": {"value": "https://www.foxsports.com/mobile", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogounivisionblack2x.ashx?h=164&la=en-US&w=460&rev=0b74caaf17624eba9f02762e01758751&hash=1D2B578C26EF9C8B3CA5607C76C51DDD",
					"alt": "Univision",
					"width": "460",
					"height": "164"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.univisionnow.com/channels", "editable": null},
			"iosUrl": {"value": "https://www.univisionnow.com/channels", "editable": null},
			"androidUrl": {"value": "https://www.univisionnow.com/channels", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tundlogo.ashx?h=52&la=en-US&w=120&rev=9cc1f63328c54407a1efc533b869bdc5&hash=8A3D704527709F5BB4D4149320DFB4DC",
					"alt": "TUDN",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.tudn.com/", "editable": null},
			"iosUrl": {"value": "https://www.tudn.com/", "editable": null},
			"androidUrl": {"value": "https://www.tudn.com/", "editable": null}
		}],
		"currentItemId": "f62aee10-eb95-46ab-97ae-03f15f6adec5"
	},
	"dateTime": "2021-07-29T23:30:00Z",
	"dateTimeObject": "2021-07-29T23:30:00+00:00"
}, {
	"matchId": "37gkcgruqektr98j51s1yf3mc",
	"description": "United States vs Jamaica",
	"tournamentCalendarId": "ecveqbaggkm48s6ty8fuz90q",
	"tournamentCalendarName": "2021",
	"stageName": "Quarter-finals",
	"status": 0,
	"goals": [{
		"contestantId": "9vh2u1p4ppm597tjfahst2m3n",
		"periodId": 2,
		"timeMin": 83,
		"goalType": 0,
		"scorerId": "9ki0zmbky1zluxnz63008ozmy",
		"scorerName": "M. Hoppe",
		"assistPlayerId": "1n0l8r7kj88y4k7tfaolsrkkp",
		"assistPlayerName": "C. Roldan"
	}],
	"sponsor": null,
	"date": "2021-07-26T01:30:00",
	"time": "01:30:00Z",
	"cancelled": false,
	"lengthMin": 99,
	"lengthSec": 7,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "9bjmhvh9ed7edzrnuuqb3lbof",
		"longName": "AT&T Stadium",
		"shortName": "AT&T Stadium",
		"location": "Arlington, Texas",
		"country": "USA"
	},
	"attendance": 41318,
	"competition": {
		"id": "f51991ex45qhp1p3iu74u4d4e",
		"name": "Concacaf Gold Cup",
		"code": "CGC",
		"format": "International cup",
		"startDate": "2021-07-11T00:00:00Z",
		"endDate": "2021-08-02T00:00:00Z",
		"isFriendly": false
	},
	"periods": [{
		"id": 1,
		"start": "2021-07-26T02:00:22Z",
		"end": "2021-07-26T02:49:26Z",
		"lengthMin": "49",
		"lengthSec": "4",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2021-07-26T03:04:27Z",
		"end": "2021-07-26T03:54:30Z",
		"lengthMin": "50",
		"lengthSec": "3",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "9vh2u1p4ppm597tjfahst2m3n",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "home",
		"score": 1,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "8yhjl13p7luh3v905btbvz42t",
		"name": "Jamaica",
		"officialName": "Jamaica",
		"shortName": "Jamaica",
		"code": "JAM",
		"position": "away",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/concacaf-gold-cup-2021/matches/united-states-vs-jamaica",
	"sitecoreData": {
		"link1": {"value": {"href": ""}, "editable": null},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {
			"value": {
				"href": "/stories/2021/07/2021-concacaf-gold-cup-usa-vs-jamaica-preview-schedule-tv-channels-start-time",
				"querystring": "",
				"target": "",
				"linktype": "internal",
				"id": "{899BC122-9D18-4164-95BF-7AE63608DA53}",
				"url": "/USSF/Home/Stories/2021/07/2021 Concacaf Gold Cup USA vs Jamaica Preview Schedule TV Channels Start Time"
			}, "editable": null
		},
		"matchRecapLink": {
			"value": {
				"href": "/stories/2021/07/2021-concacaf-gold-cup-quarterfinal-usmnt-1-jamaica-0-match-report-stats-and-group-standings",
				"querystring": "",
				"target": "",
				"linktype": "internal",
				"id": "{FB53C803-D10E-4498-BA3D-C67ED528A049}",
				"url": "/USSF/Home/Stories/2021/07/2021 Concacaf Gold Cup Quarterfinal USMNT 1 Jamaica 0 Match Report Stats and group Standings"
			}, "editable": null
		},
		"ticketsLink": {
			"value": {
				"href": "https://seatgeek.com/concacaf-gold-cup-quarterfinal-tickets/soccer/2021-07-25-6-pm/5415670",
				"text": "Tickets",
				"linktype": "external",
				"url": "https://seatgeek.com/concacaf-gold-cup-quarterfinal-tickets/soccer/2021-07-25-6-pm/5415670",
				"anchor": "",
				"title": "Tickets",
				"target": "_blank"
			}, "editable": null
		},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/backgroundred.ashx?h=1200&la=en-US&w=3840&rev=a73df9006659468d9b7fc9fe45452d72&hash=99DD665448A183451AFA8B9117FE5205",
				"alt": "",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/cardred.ashx?h=720&la=en-US&w=670&rev=18fd56e215454fc9971223f6e38a577d&hash=F78F6ED6B0027EF6121A12133AF0F1E1",
				"alt": "Red Chevron Background Pattern",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/concacaf-gold-cup-2021"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/cutouts/mnt/dike-red-playernameboxingposterleft.ashx?h=1160&la=en-US&w=880&rev=117b29f11a1541769c60d03aaf609726&hash=BF370D3E5E4B8035E932719C6882C692",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/cutouts/mnt/vines-red-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=2c78b78fb59440dfb617f89c9a2d43e2&hash=7EDDE8034494B39BA0053BAFB3883DD0",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "", "editable": null},
		"stadiumLocation": {"value": "", "editable": null},
		"stadiumCountry": {"value": "", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogofs1black2x.ashx?h=52&la=en-US&w=120&rev=79d1fc98d79c4aa582431482dc9188aa&hash=222581B287E6DC17C26116C6B3C28131",
					"alt": "FS1",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.foxsports.com/live", "editable": null},
			"iosUrl": {"value": "https://www.foxsports.com/mobile", "editable": null},
			"androidUrl": {"value": "https://www.foxsports.com/mobile", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogounivisionblack2x.ashx?h=164&la=en-US&w=460&rev=0b74caaf17624eba9f02762e01758751&hash=1D2B578C26EF9C8B3CA5607C76C51DDD",
					"alt": "Univision",
					"width": "460",
					"height": "164"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.univisionnow.com/channels", "editable": null},
			"iosUrl": {"value": "https://www.univisionnow.com/channels", "editable": null},
			"androidUrl": {"value": "https://www.univisionnow.com/channels", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tundlogo.ashx?h=52&la=en-US&w=120&rev=9cc1f63328c54407a1efc533b869bdc5&hash=8A3D704527709F5BB4D4149320DFB4DC",
					"alt": "TUDN",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.tudn.com/", "editable": null},
			"iosUrl": {"value": "https://www.tudn.com/", "editable": null},
			"androidUrl": {"value": "https://www.tudn.com/", "editable": null}
		}],
		"currentItemId": "fdfa7a7e-7762-4da0-927f-4651e9a6558a"
	},
	"dateTime": "2021-07-26T01:30:00Z",
	"dateTimeObject": "2021-07-26T01:30:00+00:00"
}, {
	"matchId": "8mzbr6qh8wufg74b71a5s9yc4",
	"description": "United States vs Canada",
	"tournamentCalendarId": "ecveqbaggkm48s6ty8fuz90q",
	"tournamentCalendarName": "2021",
	"stageName": "Group Stage",
	"status": 0,
	"goals": [{
		"contestantId": "9vh2u1p4ppm597tjfahst2m3n",
		"periodId": 1,
		"timeMin": 1,
		"goalType": 0,
		"scorerId": "bn2h28fwjh9j3dcwyf92wii8l",
		"scorerName": "S. Moore",
		"assistPlayerId": "7shrs6664saem601vmahjw7h1",
		"assistPlayerName": "S. Lletget"
	}],
	"sponsor": null,
	"date": "2021-07-18T21:00:00",
	"time": "21:00:00Z",
	"cancelled": false,
	"lengthMin": 98,
	"lengthSec": 12,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "duapp260gx6pst1uw9lq3aemn",
		"longName": "Children's Mercy Park",
		"shortName": "Children's Mercy Park",
		"location": "Kansas City, Kansas",
		"country": "USA"
	},
	"attendance": 18467,
	"competition": {
		"id": "f51991ex45qhp1p3iu74u4d4e",
		"name": "Concacaf Gold Cup",
		"code": "CGC",
		"format": "International cup",
		"startDate": "2021-07-11T00:00:00Z",
		"endDate": "2021-08-02T00:00:00Z",
		"isFriendly": false
	},
	"periods": [{
		"id": 1,
		"start": "2021-07-18T21:15:36Z",
		"end": "2021-07-18T22:03:39Z",
		"lengthMin": "48",
		"lengthSec": "3",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2021-07-18T22:19:42Z",
		"end": "2021-07-18T23:09:51Z",
		"lengthMin": "50",
		"lengthSec": "9",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "9vh2u1p4ppm597tjfahst2m3n",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "home",
		"score": 1,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "eg7vduna0h3vis1wd47s41za7",
		"name": "Canada",
		"officialName": "Canada",
		"shortName": "Canada",
		"code": "CAN",
		"position": "away",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/concacaf-gold-cup-2021/matches/united-states-vs-canada",
	"sitecoreData": {
		"link1": {"value": {"href": ""}, "editable": null},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {
			"value": {
				"href": "/stories/2021/07/2021-concacaf-gold-cup-usa-vs-canada-preview-schedule-tv-channels-start-time",
				"querystring": "",
				"target": "",
				"linktype": "internal",
				"id": "{5EDED40C-A2C1-4B3E-BB2A-70E012678F8F}",
				"url": "/USSF/Home/Stories/2021/07/2021 Concacaf Gold Cup USA vs Canada Preview Schedule TV Channels Start Time"
			}, "editable": null
		},
		"matchRecapLink": {
			"value": {
				"href": "/stories/2021/07/2021-concacaf-gold-cup-usmnt-1-canada-0-match-report-stats-and-group-standings",
				"querystring": "",
				"target": "",
				"linktype": "internal",
				"id": "{75D21233-EF47-41EC-AB98-D2DCB752E9DC}",
				"url": "/USSF/Home/Stories/2021/07/2021 Concacaf Gold Cup USMNT 1 Canada 0 Match Report Stats and group Standings"
			}, "editable": null
		},
		"ticketsLink": {
			"value": {
				"href": "https://seatgeek.com/concacaf-gold-cup-group-b-tickets/soccer/2021-07-18-4-pm/5403144",
				"text": "Tickets",
				"linktype": "external",
				"url": "https://seatgeek.com/concacaf-gold-cup-group-b-tickets/soccer/2021-07-18-4-pm/5403144",
				"anchor": "",
				"title": "Tickets",
				"target": "_blank"
			}, "editable": null
		},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/backgroundred.ashx?h=1200&la=en-US&w=3840&rev=a73df9006659468d9b7fc9fe45452d72&hash=99DD665448A183451AFA8B9117FE5205",
				"alt": "",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/cardred.ashx?h=720&la=en-US&w=670&rev=18fd56e215454fc9971223f6e38a577d&hash=F78F6ED6B0027EF6121A12133AF0F1E1",
				"alt": "Red Chevron Background Pattern",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/concacaf-gold-cup-2021"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/cutouts/mnt/dike-red-playernameboxingposterleft.ashx?h=1160&la=en-US&w=880&rev=117b29f11a1541769c60d03aaf609726&hash=BF370D3E5E4B8035E932719C6882C692",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/cutouts/mnt/vines-red-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=2c78b78fb59440dfb617f89c9a2d43e2&hash=7EDDE8034494B39BA0053BAFB3883DD0",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "", "editable": null},
		"stadiumLocation": {"value": "", "editable": null},
		"stadiumCountry": {"value": "", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/app/broadcasters/tvlogofoxblack2x.ashx?h=52&la=en-US&w=120&rev=1dd8458ff07b4d02a856c09c91032169&hash=8D532373DF96AA9D153CC4CF0A527D8A",
					"alt": "FOX",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.foxsports.com/mobile", "editable": null},
			"iosUrl": {"value": "https://www.foxsports.com/mobile", "editable": null},
			"androidUrl": {"value": "https://www.foxsports.com/mobile", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogounivisionblack2x.ashx?h=164&la=en-US&w=460&rev=0b74caaf17624eba9f02762e01758751&hash=1D2B578C26EF9C8B3CA5607C76C51DDD",
					"alt": "Univision",
					"width": "460",
					"height": "164"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.univisionnow.com/channels", "editable": null},
			"iosUrl": {"value": "https://www.univisionnow.com/channels", "editable": null},
			"androidUrl": {"value": "https://www.univisionnow.com/channels", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tundlogo.ashx?h=52&la=en-US&w=120&rev=9cc1f63328c54407a1efc533b869bdc5&hash=8A3D704527709F5BB4D4149320DFB4DC",
					"alt": "TUDN",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.tudn.com/", "editable": null},
			"iosUrl": {"value": "https://www.tudn.com/", "editable": null},
			"androidUrl": {"value": "https://www.tudn.com/", "editable": null}
		}],
		"currentItemId": "02d9b9a2-7f57-4315-bb50-e86b313c5843"
	},
	"dateTime": "2021-07-18T21:00:00Z",
	"dateTimeObject": "2021-07-18T21:00:00+00:00"
}, {
	"matchId": "6s0jqynm5mhgnp7888ah9uc5w",
	"description": "Martinique vs United States",
	"tournamentCalendarId": "ecveqbaggkm48s6ty8fuz90q",
	"tournamentCalendarName": "2021",
	"stageName": "Group Stage",
	"status": 0,
	"goals": [{
		"contestantId": "9vh2u1p4ppm597tjfahst2m3n",
		"periodId": 1,
		"timeMin": 14,
		"goalType": 0,
		"scorerId": "95i6b7d8vs250s7o4myqpyicq",
		"scorerName": "D. Dike",
		"assistPlayerId": "9ki0zmbky1zluxnz63008ozmy",
		"assistPlayerName": "M. Hoppe"
	}, {
		"contestantId": "40tyvoz1vs7drd192u010oiqr",
		"periodId": 1,
		"timeMin": 23,
		"goalType": 2,
		"scorerId": "3twbl53nl7011hrxkwy7mwxqt",
		"scorerName": "S. Camille",
		"assistPlayerId": null,
		"assistPlayerName": null
	}, {
		"contestantId": "9vh2u1p4ppm597tjfahst2m3n",
		"periodId": 2,
		"timeMin": 50,
		"goalType": 0,
		"scorerId": "bjpjsx12xdi3bwsv35746arqh",
		"scorerName": "M. Robinson",
		"assistPlayerId": "4hmqtr4zzogg0yw2w9dibv5k9",
		"assistPlayerName": "E. Williamson"
	}, {
		"contestantId": "9vh2u1p4ppm597tjfahst2m3n",
		"periodId": 2,
		"timeMin": 59,
		"goalType": 0,
		"scorerId": "95i6b7d8vs250s7o4myqpyicq",
		"scorerName": "D. Dike",
		"assistPlayerId": "1n0l8r7kj88y4k7tfaolsrkkp",
		"assistPlayerName": "C. Roldan"
	}, {
		"contestantId": "40tyvoz1vs7drd192u010oiqr",
		"periodId": 2,
		"timeMin": 64,
		"goalType": 1,
		"scorerId": "5eibpbzh51qyfgfoyl8nkct1",
		"scorerName": "E. Rivière",
		"assistPlayerId": null,
		"assistPlayerName": null
	}, {
		"contestantId": "9vh2u1p4ppm597tjfahst2m3n",
		"periodId": 2,
		"timeMin": 70,
		"goalType": 0,
		"scorerId": "61y263ybiykc566mrl9w2bowl",
		"scorerName": "G. Zardes",
		"assistPlayerId": null,
		"assistPlayerName": null
	}, {
		"contestantId": "9vh2u1p4ppm597tjfahst2m3n",
		"periodId": 2,
		"timeMin": 90,
		"goalType": 0,
		"scorerId": "6817xzrpglzo7g2mfzlnq90tm",
		"scorerName": "N. Gioacchini",
		"assistPlayerId": "3haxsyfrccsgw6x5jgbwdjze",
		"assistPlayerName": "G. Busio"
	}],
	"sponsor": null,
	"date": "2021-07-16T01:30:00",
	"time": "01:30:00Z",
	"cancelled": false,
	"lengthMin": 95,
	"lengthSec": 10,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "duapp260gx6pst1uw9lq3aemn",
		"longName": "Children's Mercy Park",
		"shortName": "Children's Mercy Park",
		"location": "Kansas City, Kansas",
		"country": "USA"
	},
	"attendance": 7511,
	"competition": {
		"id": "f51991ex45qhp1p3iu74u4d4e",
		"name": "Concacaf Gold Cup",
		"code": "CGC",
		"format": "International cup",
		"startDate": "2021-07-11T00:00:00Z",
		"endDate": "2021-08-02T00:00:00Z",
		"isFriendly": false
	},
	"periods": [{
		"id": 1,
		"start": "2021-07-16T02:00:23Z",
		"end": "2021-07-16T02:45:31Z",
		"lengthMin": "45",
		"lengthSec": "8",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2021-07-16T03:00:55Z",
		"end": "2021-07-16T03:50:57Z",
		"lengthMin": "50",
		"lengthSec": "2",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "40tyvoz1vs7drd192u010oiqr",
		"name": "Martinique",
		"officialName": "Martinique",
		"shortName": "Martinique",
		"code": "MTQ",
		"position": "home",
		"score": 1,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "9vh2u1p4ppm597tjfahst2m3n",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "away",
		"score": 6,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/concacaf-gold-cup-2021/matches/martinique-vs-united-states",
	"sitecoreData": {
		"link1": {"value": {"href": ""}, "editable": null},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {
			"value": {
				"href": "/stories/2021/07/2021-concacaf-gold-cup-usa-vs-martinique-preview-schedule-tv-channels-start-time",
				"id": "{B278BE28-7E62-4332-B986-0ADE6D9F0557}",
				"querystring": "",
				"target": "",
				"url": "/USSF/Home/Stories/2021/07/2021 Concacaf Gold Cup USA vs Martinique Preview Schedule TV Channels Start Time",
				"linktype": "internal"
			}, "editable": null
		},
		"matchRecapLink": {
			"value": {
				"href": "/stories/2021/07/2021-concacaf-gold-cup-usmnt-6-martinique-1-match-report-stats-and-group-standings",
				"querystring": "",
				"target": "",
				"linktype": "internal",
				"id": "{2DA12CB5-CDC1-4B0D-B5B1-6ACEB39B6312}",
				"url": "/USSF/Home/Stories/2021/07/2021 Concacaf Gold Cup USMNT 6 Martinique 1 Match Report Stats and group Standings"
			}, "editable": null
		},
		"ticketsLink": {
			"value": {
				"href": "https://seatgeek.com/concacaf-gold-cup-group-b-doubleheader-single-admission-doubleheader-tickets/soccer/2021-07-15-6-30-pm/5403140",
				"text": "Tickets",
				"linktype": "external",
				"url": "https://seatgeek.com/concacaf-gold-cup-group-b-doubleheader-single-admission-doubleheader-tickets/soccer/2021-07-15-6-30-pm/5403140",
				"anchor": "",
				"title": "Tickets",
				"target": "_blank"
			}, "editable": null
		},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/backgroundred.ashx?h=1200&la=en-US&w=3840&rev=a73df9006659468d9b7fc9fe45452d72&hash=99DD665448A183451AFA8B9117FE5205",
				"alt": "",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/cardred.ashx?h=720&la=en-US&w=670&rev=18fd56e215454fc9971223f6e38a577d&hash=F78F6ED6B0027EF6121A12133AF0F1E1",
				"alt": "Red Chevron Background Pattern",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/concacaf-gold-cup-2021"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/cutouts/mnt/nicholas-gioacchini-red-playernameboxingposterleft.ashx?h=1160&la=en-US&w=880&rev=d0535ba0b98c440f91128d21cb7a7fb2&hash=47C394B3D12F640386D0F86B7C43B121",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/cutouts/mnt/walker-zimmerman-red-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=32333ace7c704eaaaa87c7f0b1447521&hash=97DC8C2E517955EC983E398A9F1FA9ED",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "", "editable": null},
		"stadiumLocation": {"value": "", "editable": null},
		"stadiumCountry": {"value": "", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogofs1black2x.ashx?h=52&la=en-US&w=120&rev=79d1fc98d79c4aa582431482dc9188aa&hash=222581B287E6DC17C26116C6B3C28131",
					"alt": "FS1",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.foxsports.com/live", "editable": null},
			"iosUrl": {"value": "https://www.foxsports.com/mobile", "editable": null},
			"androidUrl": {"value": "https://www.foxsports.com/mobile", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/app/broadcasters/tvlogounimasblack2x.ashx?h=52&la=en-US&w=120&rev=880a13daf95f48b0a093ce7ea3b2bfd1&hash=5DF6DBCB80CD061330B3AE7E18D1374F",
					"alt": "UniMAS",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.univisionnow.com/page/landing", "editable": null},
			"iosUrl": {"value": "https://www.univisionnow.com/page/landing", "editable": null},
			"androidUrl": {"value": "https://www.univisionnow.com/page/landing", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tundlogo.ashx?h=52&la=en-US&w=120&rev=9cc1f63328c54407a1efc533b869bdc5&hash=8A3D704527709F5BB4D4149320DFB4DC",
					"alt": "TUDN",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.tudn.com/", "editable": null},
			"iosUrl": {"value": "https://www.tudn.com/", "editable": null},
			"androidUrl": {"value": "https://www.tudn.com/", "editable": null}
		}],
		"currentItemId": "44093169-4a17-4404-9f79-b98e00e6d233"
	},
	"dateTime": "2021-07-16T01:30:00Z",
	"dateTimeObject": "2021-07-16T01:30:00+00:00"
}, {
	"matchId": "229jvc30smxbbbr7s8qfpm7tg",
	"description": "United States vs Haiti",
	"tournamentCalendarId": "ecveqbaggkm48s6ty8fuz90q",
	"tournamentCalendarName": "2021",
	"stageName": "Group Stage",
	"status": 0,
	"goals": [{
		"contestantId": "9vh2u1p4ppm597tjfahst2m3n",
		"periodId": 1,
		"timeMin": 8,
		"goalType": 0,
		"scorerId": "5u6h6cptsad9e6n4uv76q0fl5",
		"scorerName": "S. Vines",
		"assistPlayerId": "61y263ybiykc566mrl9w2bowl",
		"assistPlayerName": "G. Zardes"
	}],
	"sponsor": null,
	"date": "2021-07-12T01:00:00",
	"time": "01:00:00Z",
	"cancelled": false,
	"lengthMin": 96,
	"lengthSec": 5,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "duapp260gx6pst1uw9lq3aemn",
		"longName": "Children's Mercy Park",
		"shortName": "Children's Mercy Park",
		"location": "Kansas City, Kansas",
		"country": "USA"
	},
	"attendance": 12664,
	"competition": {
		"id": "f51991ex45qhp1p3iu74u4d4e",
		"name": "Concacaf Gold Cup",
		"code": "CGC",
		"format": "International cup",
		"startDate": "2021-07-11T00:00:00Z",
		"endDate": "2021-08-02T00:00:00Z",
		"isFriendly": false
	},
	"periods": [{
		"id": 1,
		"start": "2021-07-12T01:00:27Z",
		"end": "2021-07-12T01:48:02Z",
		"lengthMin": "47",
		"lengthSec": "35",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2021-07-12T02:03:22Z",
		"end": "2021-07-12T02:51:52Z",
		"lengthMin": "48",
		"lengthSec": "30",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "9vh2u1p4ppm597tjfahst2m3n",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "home",
		"score": 1,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "151psmk1h1c846f2lqmflkx75",
		"name": "Haiti",
		"officialName": "Haiti",
		"shortName": "Haiti",
		"code": "HAI",
		"position": "away",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/concacaf-gold-cup-2021/matches/united-states-vs-haiti",
	"sitecoreData": {
		"link1": {"value": {"href": ""}, "editable": null},
		"link2": {"value": {"href": ""}, "editable": null},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {"value": {"href": ""}, "editable": null},
		"matchRecapLink": {
			"value": {
				"href": "/stories/2021/07/2021-concacaf-gold-cup-usmnt-1-haiti-0-match-report-stats-and-group-standings",
				"querystring": "",
				"target": "",
				"linktype": "internal",
				"id": "{E8B52E53-78DB-45BB-BCC3-6D1BE0BB70B5}",
				"url": "/USSF/Home/Stories/2021/07/2021 Concacaf Gold Cup USMNT 1 Haiti 0 Match Report Stats and group Standings"
			}, "editable": null
		},
		"ticketsLink": {
			"value": {
				"href": "https://seatgeek.com/concacaf-gold-cup-group-b-doubleheader-single-admission-doubleheader-tickets/soccer/2021-07-11-5-30-pm/5403137",
				"text": "Tickets",
				"linktype": "external",
				"url": "https://seatgeek.com/concacaf-gold-cup-group-b-doubleheader-single-admission-doubleheader-tickets/soccer/2021-07-11-5-30-pm/5403137",
				"anchor": "",
				"title": "Tickets",
				"target": "_blank"
			}, "editable": null
		},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/backgroundred.ashx?h=1200&la=en-US&w=3840&rev=a73df9006659468d9b7fc9fe45452d72&hash=99DD665448A183451AFA8B9117FE5205",
				"alt": "",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/cardred.ashx?h=720&la=en-US&w=670&rev=18fd56e215454fc9971223f6e38a577d&hash=F78F6ED6B0027EF6121A12133AF0F1E1",
				"alt": "Red Chevron Background Pattern",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/concacaf-gold-cup-2021"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/cutouts/mnt/jackson-yueill--white-playernameboxingposterleft.ashx?h=1160&la=en-US&w=880&rev=ac17206ee5864f3ca3883278594e426e&hash=79B817F5F2B748F01D342E3A8566E7F4",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/player-boxing-images/mnt/lletgetblueplayernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=5f729d192c95488e8ae437c0a072197e&hash=2E58704F9D50012369AEF5E4D86B1537",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "", "editable": null},
		"stadiumLocation": {"value": "", "editable": null},
		"stadiumCountry": {"value": "", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogofs1black2x.ashx?h=52&la=en-US&w=120&rev=79d1fc98d79c4aa582431482dc9188aa&hash=222581B287E6DC17C26116C6B3C28131",
					"alt": "FS1",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.foxsports.com/live", "editable": null},
			"iosUrl": {"value": "https://www.foxsports.com/mobile", "editable": null},
			"androidUrl": {"value": "https://www.foxsports.com/mobile", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogounivisionblack2x.ashx?h=164&la=en-US&w=460&rev=0b74caaf17624eba9f02762e01758751&hash=1D2B578C26EF9C8B3CA5607C76C51DDD",
					"alt": "Univision",
					"width": "460",
					"height": "164"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.univisionnow.com/channels", "editable": null},
			"iosUrl": {"value": "https://www.univisionnow.com/channels", "editable": null},
			"androidUrl": {"value": "https://www.univisionnow.com/channels", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tundlogo.ashx?h=52&la=en-US&w=120&rev=9cc1f63328c54407a1efc533b869bdc5&hash=8A3D704527709F5BB4D4149320DFB4DC",
					"alt": "TUDN",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.tudn.com/", "editable": null},
			"iosUrl": {"value": "https://www.tudn.com/", "editable": null},
			"androidUrl": {"value": "https://www.tudn.com/", "editable": null}
		}],
		"currentItemId": "b15d4d94-6fb3-47db-84a7-6cf655557411"
	},
	"dateTime": "2021-07-12T01:00:00Z",
	"dateTimeObject": "2021-07-12T01:00:00+00:00"
}, {
	"matchId": "6u21l99vgtaq0y1427oe5a4iy",
	"description": "Canada vs United States",
	"tournamentCalendarId": "2hsuy6ov9qaywjzz6lujb8xgq",
	"tournamentCalendarName": "2019/2020",
	"stageName": "League A",
	"status": 0,
	"goals": [{
		"contestantId": "eg7vduna0h3vis1wd47s41za7",
		"periodId": 2,
		"timeMin": 63,
		"goalType": 0,
		"scorerId": "582uvj0i3dvm0zwbou9q68pgp",
		"scorerName": "A. Davies",
		"assistPlayerId": "4f2bppk56sa7dqgw652x0fzdh",
		"assistPlayerName": "S. Arfield"
	}, {
		"contestantId": "eg7vduna0h3vis1wd47s41za7",
		"periodId": 2,
		"timeMin": 91,
		"goalType": 0,
		"scorerId": "cdeomoi09osphftlezrqa7qol",
		"scorerName": "L. Cavallini",
		"assistPlayerId": "a31s7t25qa1llzl3v79x2iimt",
		"assistPlayerName": "J. Osorio"
	}],
	"sponsor": "",
	"date": "2021-07-08T17:00:00",
	"time": "17:00:00Z",
	"cancelled": false,
	"lengthMin": 94,
	"lengthSec": 8,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "6bnxwxygeyhx36kq28xs0qk8s",
		"longName": "BMO Field",
		"shortName": "BMO Field",
		"location": "Toronto, Ontario",
		"country": "Canada"
	},
	"attendance": 0,
	"competition": {
		"id": "cu0rmpyff5692eo06ltddjo8a",
		"name": "Concacaf Nations League",
		"code": "CNL",
		"format": "International cup",
		"startDate": "2019-09-05T00:00:00Z",
		"endDate": "2021-06-07T00:00:00Z",
		"isFriendly": false
	},
	"periods": [{
		"id": 1,
		"start": "2019-10-15T23:36:24Z",
		"end": "2019-10-16T00:22:24Z",
		"lengthMin": "46",
		"lengthSec": "0",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2019-10-16T00:37:58Z",
		"end": "2019-10-16T01:26:06Z",
		"lengthMin": "48",
		"lengthSec": "8",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "eg7vduna0h3vis1wd47s41za7",
		"name": "Canada",
		"officialName": "Canada",
		"shortName": "Canada",
		"code": "CAN",
		"position": "home",
		"score": 2,
		"heroFlairTitle": "",
		"heroFlairName": "",
		"heroFlairBody": ""
	}, {
		"id": "9vh2u1p4ppm597tjfahst2m3n",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "away",
		"score": 0,
		"heroFlairTitle": "",
		"heroFlairName": "",
		"heroFlairBody": ""
	}],
	"broadcastLinks": [{
		"imageURL": "https://qa-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/broadcasters/espn.ashx?h=104&la=en-US&w=240&rev=f677dbb9e9814230af8d303e322b8046&hash=6B7CE17D2ABC2DBD3969FD45AC9FDF7C",
		"imageAltText": "ESPN",
		"broadcastURLWeb": "https://www.espn.com",
		"broadcastURLAndroid": "https://www.espn.com",
		"broadcastURLiOS": "https://www.espn.com"
	}, {
		"imageURL": "https://qa-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/broadcasters/nbcsn.ashx?h=78&la=en-US&w=180&rev=7881d779378249339aa606d97cefd1f8&hash=D67B6B5DBF51109765701733D85C8819",
		"imageAltText": "NBCSN",
		"broadcastURLWeb": "https://www.nbcsports.com",
		"broadcastURLAndroid": "https://www.nbcsports.com",
		"broadcastURLiOS": "https://www.nbcsports.com"
	}, {
		"imageURL": "https://qa-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/broadcasters/tvlogofoxblack2x.ashx?h=52&la=en-US&w=120&rev=45e5f7d3f844416ca80d9a7636b7e330&hash=ED5A3949DBD13977C9A1323600C79D7A",
		"imageAltText": "",
		"broadcastURLWeb": "https://www.foxsports.com",
		"broadcastURLAndroid": "https://www.foxsports.com",
		"broadcastURLiOS": "https://www.foxsports.com"
	}, {
		"imageURL": "https://qa-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogoespn2black2x.ashx?h=52&la=en-US&w=120&rev=51f324b0ce2542faa5caba729dab6291&hash=57C7B1D50DA40379D79717D7BBEFA548",
		"imageAltText": "ESPN 2",
		"broadcastURLWeb": "http://www.espn.com/watch/collections/2/live",
		"broadcastURLAndroid": "http://www.espn.com/watch/collections/2/live",
		"broadcastURLiOS": ""
	}, {
		"imageURL": null,
		"imageAltText": "Watch",
		"broadcastURLWeb": "https://www.tudn.com/",
		"broadcastURLAndroid": "https://www.tudn.com/",
		"broadcastURLiOS": "https://www.tudn.com/"
	}, {
		"imageURL": "https://qa-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/app/broadcasters/tvlogounimasblack2x.ashx?h=52&la=en-US&w=120&rev=0323fd48c92815b7dd7eec2ccaf2294f&hash=30D55EF834CC0A7761B572A223DC8A24",
		"imageAltText": "",
		"broadcastURLWeb": "https://www.univisionnow.com/page/landing",
		"broadcastURLAndroid": "https://www.univisionnow.com/page/landing",
		"broadcastURLiOS": "https://www.univisionnow.com/page/landing"
	}],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": "Tickets",
		"url": "https://oss.ticketmaster.com/aps/ussoccer/EN/link/buy/details/19m1015",
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00Z",
		"generalAvailabilityDate": "0001-01-01T00:00:00Z"
	},
	"groupTicketUrl": "",
	"groupTicketText": "",
	"matchFeedUrl": "/competitions/concacaf-nations-league-2019-2020/matches/canada-vs-united-states",
	"sitecoreData": {
		"link1": {
			"value": {
				"href": "http://www.espn.com/watch/collections/2/live",
				"text": "ESPN2",
				"linktype": "external",
				"url": "http://www.espn.com/watch/collections/2/live",
				"anchor": "",
				"title": "ESPN2",
				"target": "_blank"
			}, "editable": null
		},
		"link2": {
			"value": {
				"href": "https://www.univisionnow.com/page/landing",
				"text": "UniMas",
				"linktype": "external",
				"url": "https://www.univisionnow.com/page/landing",
				"anchor": "",
				"title": "UniMas",
				"target": "_blank"
			}, "editable": null
		},
		"link3": {"value": {"href": ""}, "editable": null},
		"matchHubLink": null,
		"matchPreviewLink": {
			"value": {
				"href": "/stories/2019/10/concacaf-nations-league-2019-usmnt-vs-canada-preview-schedule-tv-channels-start-time-and-standings",
				"querystring": "",
				"target": "",
				"linktype": "internal",
				"id": "{14EF860B-8087-4ACB-A6DE-EFC29741DC6F}",
				"url": "/USSF/Home/Stories/2019/10/Concacaf Nations League 2019 usmnt vs Canada Preview Schedule TV Channels Start Time and Standings"
			}, "editable": null
		},
		"matchRecapLink": {
			"value": {
				"href": "/stories/2019/10/concacaf-nations-league-2019-20-usmnt-vs-canada-match-report-stats-and-standings",
				"querystring": "",
				"target": "",
				"linktype": "internal",
				"id": "{8E522F1A-85B9-4E7A-9742-82489ACDC345}",
				"url": "/USSF/Home/Stories/2019/10/Concacaf Nations League 2019 20 usmnt vs Canada Match Report Stats and Standings"
			}, "editable": null
		},
		"ticketsLink": {
			"value": {
				"href": "https://oss.ticketmaster.com/aps/ussoccer/EN/link/buy/details/19m1015",
				"text": "Tickets",
				"linktype": "external",
				"url": "https://oss.ticketmaster.com/aps/ussoccer/EN/link/buy/details/19m1015",
				"anchor": "",
				"target": "_blank"
			}, "editable": null
		},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/backgroundred.ashx?h=1200&la=en-US&w=3840&rev=a73df9006659468d9b7fc9fe45452d72&hash=99DD665448A183451AFA8B9117FE5205",
				"alt": "",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/cardred.ashx?h=720&la=en-US&w=670&rev=18fd56e215454fc9971223f6e38a577d&hash=F78F6ED6B0027EF6121A12133AF0F1E1",
				"alt": "Red Chevron Background Pattern",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/concacaf-nations-league-2019-2020"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/2019-stories/10/mntvcan/davies-can2-playernameboxingposterleft.ashx?h=1160&la=en-US&w=880&rev=8118dc9779144fe3bb1b95263af26a81&hash=8D5CEC08BC5B0FB9B0C57AEFA0F825FB",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/2019/mnt-vs-guy/mnt-mckennie-playernameboxingposterright.ashx?h=1160&la=en-US&w=880&rev=6b721c4e824348c09d8cae3769c42f19&hash=4436B4DC86AC1B2D8F469189DEE24293",
				"alt": "Weston McKennie",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "", "editable": null},
		"stadiumLocation": {"value": "", "editable": null},
		"stadiumCountry": {"value": "", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogoespn2black2x.ashx?h=52&la=en-US&w=120&rev=4af41fa42b4b436789e7755af53cee7e&hash=18B188223D16A20E4B7E04C46AC9A6D5",
					"alt": "ESPN 2",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "http://www.espn.com/watch/collections/2/live", "editable": null},
			"iosUrl": {"value": "", "editable": null},
			"androidUrl": {"value": "http://www.espn.com/watch/collections/2/live", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tundlogo.ashx?h=52&la=en-US&w=120&rev=9cc1f63328c54407a1efc533b869bdc5&hash=8A3D704527709F5BB4D4149320DFB4DC",
					"alt": "",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.tudn.com/", "editable": null},
			"iosUrl": {"value": "https://www.tudn.com/", "editable": null},
			"androidUrl": {"value": "https://www.tudn.com/", "editable": null}
		}],
		"currentItemId": "ea39b59a-495b-4c64-a2cb-c9621b3b436b"
	},
	"dateTime": "2021-07-08T17:00:00Z",
	"dateTimeObject": "2021-07-08T17:00:00+00:00"
}, {
	"matchId": "23bonexqeuh7zmyxcf0hszuok",
	"description": "United States vs Costa Rica",
	"tournamentCalendarId": "m1cpiectqfks4egahjhqbdas",
	"tournamentCalendarName": "2021",
	"stageName": "Friendlies 1",
	"status": 0,
	"goals": [{
		"contestantId": "9vh2u1p4ppm597tjfahst2m3n",
		"periodId": 1,
		"timeMin": 8,
		"goalType": 0,
		"scorerId": "8rrcscn29rs1gk8mi1pwpu92i",
		"scorerName": "B. Aaronson",
		"assistPlayerId": null,
		"assistPlayerName": null
	}, {
		"contestantId": "9vh2u1p4ppm597tjfahst2m3n",
		"periodId": 1,
		"timeMin": 42,
		"goalType": 0,
		"scorerId": "95i6b7d8vs250s7o4myqpyicq",
		"scorerName": "D. Dike",
		"assistPlayerId": "5enjhxrx2jrjqtgb9wwq0baax",
		"assistPlayerName": "M. McKenzie"
	}, {
		"contestantId": "9vh2u1p4ppm597tjfahst2m3n",
		"periodId": 2,
		"timeMin": 52,
		"goalType": 0,
		"scorerId": "3ebyf23azgbktpf7dgwlphkvd",
		"scorerName": "R. Cannon",
		"assistPlayerId": null,
		"assistPlayerName": null
	}, {
		"contestantId": "9vh2u1p4ppm597tjfahst2m3n",
		"periodId": 2,
		"timeMin": 77,
		"goalType": 1,
		"scorerId": "2we9yak4ipkr1ljigj20puv6y",
		"scorerName": "G. Reyna",
		"assistPlayerId": null,
		"assistPlayerName": null
	}],
	"sponsor": null,
	"date": "2021-06-09T23:00:00",
	"time": "23:00:00Z",
	"cancelled": false,
	"lengthMin": 94,
	"lengthSec": 17,
	"addedTimeForCurrentPeriod": 0,
	"venue": {
		"id": "dz36ymlbudghwjl794efxv8kh",
		"longName": "Rio Tinto Stadium",
		"shortName": "Rio Tinto Stadium",
		"location": "Sandy, Utah",
		"country": "USA"
	},
	"attendance": 19007,
	"competition": {
		"id": "cesdwwnxbc5fmajgroc0hqzy2",
		"name": "International Friendly",
		"code": "FRI",
		"format": "International cup",
		"startDate": "2021-01-01T00:00:00Z",
		"endDate": "2021-12-31T00:00:00Z",
		"isFriendly": true
	},
	"periods": [{
		"id": 1,
		"start": "2021-06-09T23:05:32Z",
		"end": "2021-06-09T23:51:36Z",
		"lengthMin": "46",
		"lengthSec": "4",
		"addedTime": 0
	}, {
		"id": 2,
		"start": "2021-06-10T00:10:13Z",
		"end": "2021-06-10T00:58:26Z",
		"lengthMin": "48",
		"lengthSec": "13",
		"addedTime": 0
	}],
	"currentPeriod": 14,
	"manager": null,
	"contestants": [{
		"id": "9vh2u1p4ppm597tjfahst2m3n",
		"name": "United States",
		"officialName": "United States",
		"shortName": "United States",
		"code": "USA",
		"position": "home",
		"score": 4,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}, {
		"id": "bfgqfm4qa3mal7fll74wd04mu",
		"name": "Costa Rica",
		"officialName": "Costa Rica",
		"shortName": "Costa Rica",
		"code": "CRC",
		"position": "away",
		"score": 0,
		"heroFlairTitle": null,
		"heroFlairName": null,
		"heroFlairBody": null
	}],
	"broadcastLinks": [],
	"cards": null,
	"substitutions": null,
	"penaltyShots": [],
	"tickets": {
		"name": null,
		"url": null,
		"eventCode": null,
		"presaleDate": "0001-01-01T00:00:00",
		"generalAvailabilityDate": "0001-01-01T00:00:00"
	},
	"groupTicketUrl": null,
	"groupTicketText": null,
	"matchFeedUrl": "/competitions/usmnt-friendlies-2021/matches/united-states-vs-costa-rica",
	"sitecoreData": {
		"link1": {
			"value": {
				"href": "https://www.espn.com/watch/?type=live&channel=espn2",
				"text": "ESPN2",
				"linktype": "external",
				"url": "https://www.espn.com/watch/?type=live&channel=espn2",
				"anchor": "",
				"title": "ESPN2",
				"target": "_blank"
			}, "editable": null
		},
		"link2": {
			"value": {
				"href": "https://www.univisionnow.com/channels",
				"text": "UniMas",
				"linktype": "external",
				"url": "https://www.univisionnow.com/channels",
				"anchor": "",
				"title": "UniMas",
				"target": "_blank"
			}, "editable": null
		},
		"link3": {
			"value": {
				"href": "https://www.tudn.com/",
				"text": "TUDN",
				"linktype": "external",
				"url": "https://www.tudn.com/",
				"anchor": "",
				"title": "TUDN",
				"target": "_blank"
			}, "editable": null
		},
		"matchHubLink": null,
		"matchPreviewLink": {"value": {"href": ""}, "editable": null},
		"matchRecapLink": {"value": {"href": ""}, "editable": null},
		"ticketsLink": {
			"value": {
				"href": "https://tix.axs.com/Tps5FwAAAAAtYk92AgAAAADs%2fv%2f%2f%2fwD%2f%2f%2f%2f%2fA3NjcAD%2f%2f%2f%2f%2f%2f%2f%2f%2f%2fw%3d%3d?skin=rsl",
				"text": "Buy Tickets",
				"linktype": "external",
				"url": "https://tix.axs.com/Tps5FwAAAAAtYk92AgAAAADs%2fv%2f%2f%2fwD%2f%2f%2f%2f%2fA3NjcAD%2f%2f%2f%2f%2f%2f%2f%2f%2f%2fw%3d%3d?skin=rsl",
				"anchor": "",
				"title": "Tickets",
				"target": "_blank"
			}, "editable": null
		},
		"brandedBackground": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/backgroundred.ashx?h=1200&la=en-US&w=3840&rev=a73df9006659468d9b7fc9fe45452d72&hash=99DD665448A183451AFA8B9117FE5205",
				"alt": "",
				"width": "3840",
				"height": "1200"
			}, "editable": null
		},
		"brandedBackgroundCard": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/matches/mnt/match-backgrounds/cardred.ashx?h=720&la=en-US&w=670&rev=18fd56e215454fc9971223f6e38a577d&hash=F78F6ED6B0027EF6121A12133AF0F1E1",
				"alt": "Red Chevron Background Pattern",
				"width": "670",
				"height": "720"
			}, "editable": null
		},
		"doubleHeaderMatchId": {"value": "", "editable": null},
		"presaleTicketsDate": {"value": "0001-01-01T00:00:00Z", "editable": null},
		"competition": {
			"competitionLogo": {"value": {}, "editable": null},
			"competitionUrl": "/competitions/usmnt-friendlies-2021"
		},
		"backgroundImage": null,
		"playerCutoutHome": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/cutouts/mnt/adams-white-bigger-playernameboxingposterleft.ashx?h=1160&la=en-US&w=880&rev=f6473ff1d5cd4b6e912ee3518ab671fa&hash=39124D95D361940A467DB48DDCD2FF3E",
				"alt": "",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"playerCutoutAway": {
			"value": {
				"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/players/cutouts/mnt/musah-white-boxing-right-png.ashx?h=1160&la=en-US&w=880&rev=c828bddb6f464dc391dd49d2831fbdac&hash=1E4170E8FCE954F882FB25A2D87575D4",
				"alt": "Yunus Musah",
				"width": "880",
				"height": "1160"
			}, "editable": null
		},
		"sponsorText": {"value": "", "editable": null},
		"listViewLogo": {"value": {}, "editable": null},
		"matchStageName": {"value": "", "editable": null},
		"matchLogo": {"value": {}, "editable": null},
		"matchFeedUrl": null,
		"stadiumName": {"value": "", "editable": null},
		"stadiumLocation": {"value": "", "editable": null},
		"stadiumCountry": {"value": "", "editable": null},
		"broadcasterLinks": [{
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/app/broadcasters/espn2_logo.ashx?h=52&la=en-US&w=120&rev=8b583676054f469c997298102fc43568&hash=495D7043006AA51910F46539C08D4624",
					"alt": "ESPN2",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.espn.com/watch/?type=live&channel=espn2", "editable": null},
			"iosUrl": {"value": "https://apps.apple.com/us/app/espn-scorecenter/id317469184", "editable": null},
			"androidUrl": {"value": "https://play.google.com/store/apps/details?id=com.espn.score_center", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tvlogounimasblack2x.ashx?h=52&la=en-US&w=120&rev=f13300dc0e3e48ae9888634da0ce427a&hash=C91D2E6B043C1E965DAB151D3EA3B031",
					"alt": "UniMAS",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.univisionnow.com/channels", "editable": null},
			"iosUrl": {"value": "https://www.univisionnow.com/channels", "editable": null},
			"androidUrl": {"value": "https://www.univisionnow.com/channels", "editable": null}
		}, {
			"broadcasterLogo": {
				"value": {
					"src": "https://uat-ussf-pdx-cdn.azureedge.net/-/media/project/ussf/logos/ussf-logos/broadcasters/tundlogo.ashx?h=52&la=en-US&w=120&rev=9cc1f63328c54407a1efc533b869bdc5&hash=8A3D704527709F5BB4D4149320DFB4DC",
					"alt": "TUDN",
					"width": "120",
					"height": "52"
				}, "editable": null
			},
			"webUrl": {"value": "https://www.tudn.com/", "editable": null},
			"iosUrl": {"value": "https://www.tudn.com/", "editable": null},
			"androidUrl": {"value": "https://www.tudn.com/", "editable": null}
		}],
		"currentItemId": "f75423f9-6ba1-4711-967b-3e35864eaf2e"
	},
	"dateTime": "2021-06-09T23:00:00Z",
	"dateTimeObject": "2021-06-09T23:00:00+00:00"
}];