import React from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import styles from "./LatestStoryThumbSmall.module.scss";
import moment from "moment";
import {Link} from "react-router-dom";

const LatestStoryThumbSmall = ({story, showImage = true, withSpacer = true}) => {
	return (
		<>
			<div className={styles.wrap}>
				{showImage && story.ThumbnailImage && (
					<Link to={story.Url.replace('https://www.ussoccer.com', '')} style={{backgroundImage: `url(${story.ThumbnailImage})`}} aria-label={story.Title} className={styles.imageLink} />
				)}
				<div className={styles.right}>
					<div className={styles.meta}>
						{story?.Category && (
							<div className={styles.category}>{story.Category}</div>
						)}
						<div className={styles.postedDate}>
							{moment(story.PostedDate).format("MMMM D, YYYY")}
						</div>
					</div>
					<Link to={story.Url.replace('https://www.ussoccer.com', '')}>
						<h3>{story.StoryHeading ?? story.Title}</h3>
					</Link>
				</div>
			</div>
			{withSpacer && (
				<hr />
			)}
		</>
	);
};

export default compose(
	withErrorHandling()
)(LatestStoryThumbSmall);
