exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".FourColumnLinks-module__FourColumnLinks--10MeD {\n  letter-spacing: 0.05em;\n  text-transform: uppercase; }\n\n.FourColumnLinks-module__columns--2MAiD {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n  margin: 0 auto;\n  max-width: 1440px;\n  padding: 0 2rem; }\n  @media (min-width: 1025px) {\n    .FourColumnLinks-module__columns--2MAiD {\n      -ms-flex-wrap: nowrap;\n          flex-wrap: nowrap;\n      padding: 5rem 2rem 0; } }\n\n.FourColumnLinks-module__column--1WHFc {\n  font-size: 1.2rem;\n  padding: 3rem 0;\n  width: 50%; }\n  .FourColumnLinks-module__column--1WHFc a {\n    color: #212844; }\n  @media (min-width: 1025px) {\n    .FourColumnLinks-module__column--1WHFc {\n      padding: 0 0 5rem;\n      width: 25%; } }\n\n.FourColumnLinks-module__heading--2jz4O {\n  font-weight: 700; }\n\n.FourColumnLinks-module__links--2aaAS {\n  font-weight: 500;\n  padding-left: 1.5rem; }\n\n.FourColumnLinks-module__link--3VymU {\n  margin-bottom: 1.4rem; }\n", ""]);

// exports
exports.locals = {
	"FourColumnLinks": "FourColumnLinks-module__FourColumnLinks--10MeD",
	"columns": "FourColumnLinks-module__columns--2MAiD",
	"column": "FourColumnLinks-module__column--1WHFc",
	"heading": "FourColumnLinks-module__heading--2jz4O",
	"links": "FourColumnLinks-module__links--2aaAS",
	"link": "FourColumnLinks-module__link--3VymU"
};