import React from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import { isPlaceholderEmptyOrUndefined } from "~/utilities";
import HelperCommentEmptyPlaceholder from "~/experience-editor/HelperCommentEmptyPlaceholder";

const AccordionTab = ({ rendering }) => (
	<>
		{rendering && (
			<>
				{isPlaceholderEmptyOrUndefined(rendering, "jss-accordion-tab-content") && (
					<HelperCommentEmptyPlaceholder />
				)}
				<Placeholder
					name="jss-accordion-tab-content"
					rendering={rendering}
					renderEach={component => (
						component
					)}
				/>
			</>
		)}
	</>
);

export default compose(
	// other hocs goes here
	withErrorHandling()
)(AccordionTab);