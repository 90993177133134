import React, { useEffect } from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import styles from "./TextBlock.module.scss";
import { RichText, Text } from "~/patches/components";

const TextBlock = ({ fields, sitecoreContext: { pageEditing } }) => {
	const classStyle = fields.setTransparentBackground.value ? `${styles.wrapper} ${styles.transparent}` : `${styles.wrapper} ${styles.withGrayGradientBg}`;
	const headerStyle = {
		color: fields.headerColor.value
	};
	const centerStyle = fields.centerTextBlock.value ? `${styles.centerTextBlock}` : "";

	useEffect(() => {
		if (typeof document !== 'undefined') {
			const twitterWidgetUrl = 'https://platform.twitter.com/widgets.js';
			const twitterWidgetScripts = document.querySelectorAll(`script[src="${twitterWidgetUrl}"]`);
			const textNeedsWidget = fields?.bodyText?.value?.includes(twitterWidgetUrl);

			// The sitecore text using this TextBlock component has
			// a Twitter widget dependency. This is a workaround for
			// 14115 in which the widget is not always loading.
			// We will force it to load here and clean up any extra scripts.
			if (textNeedsWidget) {
				twitterWidgetScripts.forEach((twitterWidgetScript) => {
					twitterWidgetScript.remove();
				});
				const head = document.getElementsByTagName('head')[0];
				const script = document.createElement('script');
				script.type = 'text/javascript';
				script.src = twitterWidgetUrl;

				head.appendChild(script);
			}
		}
	}, []);

	return (
		<>
			{(fields || pageEditing) && (
				<section className={`${classStyle} ${centerStyle}`}>
					<div className={`${styles.container}`}>
						<Text className={styles.heading} tag="h2" field={fields.heading} style={headerStyle} />
						<RichText field={fields.bodyText} />
					</div>
				</section>
			)}
		</>
	);
};

export default compose(
	// other hocs goes here
	withSitecoreContext(),
	withErrorHandling()
)(TextBlock);