exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Parallaxer-module__Parallaxer--3ti0v {\n  -webkit-transition: -webkit-transform cubic-bezier(0.075, 0.82, 0.165, 1) 100ms;\n  transition: -webkit-transform cubic-bezier(0.075, 0.82, 0.165, 1) 100ms;\n  -o-transition: transform cubic-bezier(0.075, 0.82, 0.165, 1) 100ms;\n  transition: transform cubic-bezier(0.075, 0.82, 0.165, 1) 100ms;\n  transition: transform cubic-bezier(0.075, 0.82, 0.165, 1) 100ms, -webkit-transform cubic-bezier(0.075, 0.82, 0.165, 1) 100ms; }\n", ""]);

// exports
exports.locals = {
	"Parallaxer": "Parallaxer-module__Parallaxer--3ti0v"
};