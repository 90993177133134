import React from "react";
import { Text } from "~/patches/components";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import styles from "./P1Text.module.scss";

const P1Text = ({ fields, sitecoreContext: { pageEditing } }) => (
	<>
		{(fields || pageEditing) && (
			<Text tag="h1" className={styles.heading} field={fields.heading} />
		)}
	</>
);

export default compose(
	// other hocs goes here
	withSitecoreContext(),
	withErrorHandling()
)(P1Text);