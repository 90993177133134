exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".CenteredFixedWidthLayout-module__centeredFixedWidth--TQnks {\n  margin: 0 auto;\n  max-width: 42rem;\n  padding: 1rem 2rem; }\n", ""]);

// exports
exports.locals = {
	"centeredFixedWidth": "CenteredFixedWidthLayout-module__centeredFixedWidth--TQnks"
};