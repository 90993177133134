exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".TableMatches-module__TableMatches--3t6Tc {\n  margin-bottom: calc(20px * 3); }\n\n@media (min-width: 1025px) {\n  .TableMatches-module__TableMatches--3t6Tc {\n    margin: 0 auto calc(20px * 3);\n    max-width: 83.75rem; } }\n\n@media (min-width: 1440px) {\n  .TableMatches-module__TableMatches--3t6Tc {\n    max-width: 117.8rem; } }\n", ""]);

// exports
exports.locals = {
	"TableMatches": "TableMatches-module__TableMatches--3t6Tc"
};