import React from "react";
import { NavLink } from "react-router-dom";
import styles from "../MultiLevelMenu.module.scss";
import NextButton from "../NextButton";
import { hasSubMenuItems } from "../helpers";
import LinkName from "../LinkName";

const Link = ({setState, link, level, index, currentMenu}) => {
	if (hasSubMenuItems(link)) {
		return (
			<NextButton {...{setState, link, level, index, currentMenu}} />
		);
	}

	return (
		<>
			{link?.url ?
				<NavLink
					id={`link-${link.id}`}
					activeClassName={styles.active}
					exact to={link.url}>
					<LinkName link={link.sidebarMenuTitle} fallbackLinkName={link.menuTitle} />
				</NavLink>
				:
				<LinkName link={link.sidebarMenuTitle} fallbackLinkName={link.menuTitle} />
			}
		</>
	);
};

export default Link;