import React from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import { Image, Text } from "~/patches/components";
import { Link as RouterLink } from "react-router-dom";
import styles from "./EditionThumbnail.module.scss";

const EditionThumbnail = ({ fields }) => {
	const item = fields && fields.itemLink;
	const linkTitle = fields && fields.linkTitle;

	return (
		<div className={styles.EditionThumbnail}>
			{item && item.fields && (
				<RouterLink to={item.url}>
					<div className={styles.imageContainer}>
						<Image
							field={item.fields.thumbnailImage}
							srcSet={[{ mw: 1500 }, { mw: 440 }]}
							sizes={"(min-width: 1024px) 1500, 440px"}/>
					</div>
					<div className={styles.textContainer}>
						<Text tag="h5" field={item.fields.storyHeading}/>
						<Text tag="p" field={item.fields.subTitle}/>
						<Text tag="span" className={styles.cta} field={linkTitle}/>
					</div>
				</RouterLink>
			)}
		</div>
	);
};

export default compose(
	// other hocs goes here
	withErrorHandling()
)(EditionThumbnail);