import React from "react";
import { Text, Image } from "~/patches/components";
import { withErrorHandling } from "~/hoc";
import ArrowLink from "~/shared-components/ArrowLink";
import { compose } from "recompose";
import styles from "./CallToActionBlockWithIcon.module.scss";

const CallToActionBlockWithIcon = ({ fields }) => (
	<>
		{fields && (
			<section className={styles.CallToActionBlockWithIcon}>
				<div className={styles.image}>
					<Image field={fields.icon} />
				</div>
				<div className={styles.content}>
					<Text tag="h4" field={fields.heading} />
					<div dangerouslySetInnerHTML={{ __html: fields.description.value }} />
					<ArrowLink link={fields.link} linkText={fields.link?.value?.text}/>
				</div>
			</section>
		)}
	</>
);

export default compose(
	// other hocs goes here
	withErrorHandling()
)(CallToActionBlockWithIcon);