import React from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import TicketOptionsCard from "~/shared-components/TicketOptionsCard";
import { fieldHasValue } from "~/utilities";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import styles from "./TicketOptions.module.scss";
import { withNamespaces } from "react-i18next";
import { Text } from "~/patches/components";

/**
 * Placeholder for Ticket options Items
 */
let ticketOptionsItems = [];

/**
 * Populate ticketOptionsItems with Sitecore fields split into sections
 * @param {Object} fields
 */
const populateTicketOptionsItems = fields => {

	ticketOptionsItems = [];

	for (let index = 0; index < 6; index++) {
		const indexName = index + 1;

		ticketOptionsItems.push({
			image: fields[`image${indexName}`],
			heading: fields[`heading${indexName}`],
			description: fields[`description${indexName}`],
			link: fields[`link${indexName}`],
			renderLinkAsButton: fields[`renderLinkAsButton${indexName}`]
		});
	}
};

const TicketOptions = ({ fields, sitecoreContext: { pageEditing } }) => {
	if (!fields) return <></>;

	populateTicketOptionsItems(fields);

	return (
		<section className={styles.TicketOptions}>
			{(fieldHasValue(fields.heading) || pageEditing) && (
				<Text tag="h3" field={fields.heading} />
			)}

			<div className={styles.container}>
				{ticketOptionsItems.map(
					(card, index) =>
						(fieldHasValue(card, "heading") || pageEditing) && ( //Only render this card if a heading is set
							<TicketOptionsCard key={index} {...card} />
						)
				)}
			</div>
		</section>
	);
};

export default compose(
	// other hocs goes here
	withNamespaces(),
	withSitecoreContext(),
	withErrorHandling()
)(TicketOptions);
