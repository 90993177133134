import React, { useState, Component } from "react";
import { Text } from "~/patches/components";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import styles from "./ScoreBarFull.module.scss";
import { MatchService } from "~/services/matchService";
import { optaApi, validDateTimeFormat } from "~/utilities/constants";
import { allFlags } from "~/utilities/flags";
import greenLight from "~/assets/img/GreenLight.png";
import instagram from "~/assets/img/Instagram.png";
import twitter from "~/assets/img/Twitter.png";

class ScoreBarFull extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isDataLoaded: false,
			teamMatches: []
		};
	}

	componentDidMount = () => {
		if(this.props.fields.matchLink)
		{
			MatchService.getScoreboardByMatches(this.props.fields.matchLink)
				.then(response => {
					if (response && response.length > 0) {
						const matches = this.state.matches.slice();
						response.map(match => {
							matches.push(match);
						});
						this.setState({ matches: matches, isDataLoaded: true });
					}
				});
		}
		else
		{
			if (this.props.fields) {
					this.props.fields.teams.map(team => {
						MatchService.getScoreboardByContestants(team.fields.optaTeamId.value)
							.then(response => {
								if (response && response.length > 0) {
									const teamMatches = this.state.teamMatches.slice();
									response.map(match => {
										teamMatches.push({match: match, team: team} );
									});
									this.setState({ teamMatches: teamMatches, isDataLoaded: true });
								}
							});
					});
				}
			}
	}

	componentWillUnmount = () => {
	}

	render() {
		const { isDataLoaded, teamMatches } = this.state;

		return (
			<>
				{isDataLoaded && teamMatches && (
					<>
						{teamMatches.map((teamMatch, index) =>
							<React.Fragment key={index}>
								{teamMatch.match.status === optaApi.matchStatus.live &&
									(
										<>
											<br />
											<LiveBar match={teamMatch.match} team={teamMatch.team} />
										</>
									)
								}
								{teamMatch.match.status === optaApi.matchStatus.upcoming &&
									(
										<>
											<br />
											<UpcomingBar match={teamMatch.match} />
										</>
									)
								}
							</React.Fragment>
						)};
					</>
				)}
			</>
		);
	}
}

const LiveBar = ({ match, team }) => {
	return (
		<>
			<ul className={styles.liveBarArea}>
				<li className={styles.liveBarLeft}>
					<Broadcast broadcastLinks={match.broadcastLinks} status={match.status} />
				</li>
				<li className={styles.liveBarMiddle}>
					<MatchDisplayLive match={match} />
				</li>
				<li className={styles.liveBarRight}>
					<ScoreBarRight team={team} />
				</li>
			</ul>
		</>
	);
};

const UpcomingBar = ({ match }) => {
	return (
		<>
			<div>
				<ul className={styles.upcomingBarArea}>
					<li className={styles.upcomingBarLeft}>
						<MatchDisplayUpcoming match={match} />
					</li>
					<li className={styles.upcomingBarRight}>
						<ShowTimer match={match} />
					</li>
				</ul>
				<BroadcastUpcoming broadcastLinks={match.broadcastLinks} />
			</div>
		</>
	);
};

const ShowTimer = ({ match }) => {
	let md = new Date(match.dateTime.replace(/TBD/gi, '00:00:00Z'));
	const td = new Date();
	if (location.href.indexOf('localhost') > 0) {
		while (md < td) {
			md = new Date(md.setMonth(md.getMonth() + 1));
		}
	}
	let ts = md.getTime() - td.getTime();
	const tso = ts;
	let totalDays = Math.floor(ts / (1000 * 60 * 60 * 24));
	if (totalDays < 0)
		totalDays = 0;
	ts = ts - totalDays * (1000 * 60 * 60 * 24);
	let totalHours = Math.floor(ts / (1000 * 60 * 60));
	if (totalHours < 0)
		totalHours = 0;
	ts = ts - totalHours * (1000 * 60 * 60);
	let totalMinutes = Math.floor(ts / (1000 * 60));
	if (totalMinutes < 0)
		totalMinutes = 0;
	ts = ts - totalMinutes * (1000 * 60);
	let totalSeconds = Math.floor(ts / (1000));
	if (totalSeconds < 0)
		totalSeconds = 0;
	ts = ts - totalSeconds * (1000);

	return (
		<>
			<ul className={styles.showTimerBoxArea}>
				<li>
					<ul className={styles.showTimerArea}>
						<li className={`${styles.showTimerDays} ${styles.showTimerHead}`}>
							DAYS
						</li>
						<li className={`${styles.showTimerHours} ${styles.showTimerHead}`}>
							HOURS
						</li>
						<li className={`${styles.showTimerMinutes} ${styles.showTimerHead}`}>
							MINUTES
						</li>
						<li className={`${styles.showTimerSeconds} ${styles.showTimerHead}`}>
							SECONDS
						</li>
					</ul>
				</li>
				<li>
					<ul className={styles.showTimerArea}>
						<li className={`${styles.showTimerDays} ${styles.showTimerTime}`}>
							{totalDays}
						</li>
						<li className={`${styles.showTimerHours} ${styles.showTimerTime}`}>
							{totalHours}
						</li>
						<li className={`${styles.showTimerMinutes} ${styles.showTimerTime}`}>
							{totalMinutes}
						</li>
						<li className={`${styles.showTimerSeconds} ${styles.showTimerTime}`}>
							{totalSeconds}
						</li>
					</ul>
				</li>
				<li>
					<ul className={styles.showTimerAreaTickets}>
						<li className={styles.showTimerBuyTickets}>
							BUY TICKETS
						</li>
						<li key='99997' className={styles.showTimerMatchHub}>
							MATCH HUB
						</li>
					</ul>
				</li>
			</ul>
		</>
	)
}

const BroadcastUpcoming = ({ broadcastLinks }) => {
	return (
		<>
			<div className={styles.broadcastAreaUpcoming}>
				<ul className={styles.broadcastLinksUpcoming}>
					<li className={styles.broadcastHeaderUpcoming}>WHERE TO WATCH</li>
					{broadcastLinks.filter(broadcasterLink => broadcasterLink.imageURL !== 'string').map((broadcasterLink, index) =>
						<li key={index} className={styles.broadcastLinkBlack}>
							<a href={broadcasterLink.broadcastURLWeb} target="_blank" rel="noopener noreferrer">
								<img src={broadcasterLink.imageURL} alt={broadcasterLink.imageAltText} />
							</a>
						</li>
					)}
				</ul>
			</div>
		</>
	);
};

const Broadcast = ({ broadcastLinks, status }) => {
	return (
		<>
			<div className={styles.broadcastArea}>
				<div className={styles.broadcastHeader}>Watch Live</div>
				<ul className={styles.broadcastLinks}>
					{broadcastLinks.filter(broadcasterLink => broadcasterLink.imageURL !== 'string').map((broadcasterLink, index) =>
						<li key={index} className={status === optaApi.matchStatus.upcoming ? styles.broadcastLinkBlack : styles.broadcastLink}>
							<a href={broadcasterLink.broadcastURLWeb} target="_blank" rel="noopener noreferrer">
								{broadcasterLink.imageURL
										? <img src={broadcasterLink.imageURL} alt={broadcasterLink.imageAltText} />
										: <span>{broadcasterLink.imageAltText}</span>
									}
							</a>
						</li>
					)}
				</ul>
			</div>
		</>
	);
};
const MatchDisplayLive = ({ match }) => {
	return (
		<>
			<ul className={styles.matchDisplayArea}>
				<li className={styles.matchDisplayDescription}>
					{match.description}
				</li>
				<li className={styles.matchDisplaySponsor}>
					{match.sponsor}
				</li>
				<li className={styles.matchDisplayTeams}>
					<MatchStatusLive match={match} />
				</li>
				<li className={styles.matchDisplayTime}>
					<LiveTime time={match.time} />
				</li>
			</ul>
		</>
	)
}

const MatchDisplayUpcoming = ({ match }) => {
	return (
		<>
			<ul className={styles.matchDisplayArea}>
				<li className={styles.matchDisplayTeams}>
					<MatchStatusUpcoming match={match} />
				</li>
			</ul>
		</>
	)
}

const ScoreBarRight = ({ team }) => {
	return (
		<>
			<ul className={styles.scoreBarRightArea}>
				<li>
					<ul className={styles.socialMediaArea}>
						<li>
							<a target='_blank' rel="noopener noreferrer" className={styles.socialMediaLink} href={`https://twitter.com/${team.name}`}><img src={twitter} /></a>
						</li>
						<li>
							<a target='_blank' rel="noopener noreferrer" className={styles.socialMediaLink} href={`https://twitter.com/${team.name}`}>&nbsp;@{team.name}</a>
						</li>
					</ul>
					<ul className={styles.socialMediaArea}>
						<li>
							<a target='_blank' rel="noopener noreferrer" className={styles.socialMediaLink} href={`https://www.instagram.com/${team.name}`}><img src={instagram} /></a>
						</li>
						<li>
							<a target='_blank' rel="noopener noreferrer" className={styles.socialMediaLink} href={`https://www.instagram.com/${team.name}`}>&nbsp;@{team.name}</a>
						</li>
					</ul>
				</li>
				<li className={styles.socialMediaInsights}>
					TRACK ALL<br />
					THE ACTION<br />
					WITH MATCH<br />
					INSIGHTS<br />
				</li>
			</ul>
		</>
	)
}

const MatchStatusLive = ({ match }) => {
	return (
		<>
			<ul className={styles.matchStatusArea}>
				<li>
					<TeamInfoLive team={match.contestants[0]} homeAway='home' />
				</li>
				<li className={styles.matchStatusDash}>-</li>
				<li>
					<TeamInfoLive team={match.contestants[1]} homeAway='away' />
				</li>
			</ul>
		</>
	)

}

const MatchStatusUpcoming = ({ match }) => {
	const venue = `${match.venue.longName}, ${match.venue.location}`

	const d = new Date(match.dateTime.replace(/TBD/gi, '00:00:00Z'));
	const month = d.toLocaleString('default', { month: 'long' }).substring(0, 3).toUpperCase();
	const day = `0${d.getDay()}`.substring(-2, 2);
	const year = d.getFullYear();
	let ampm = 'AM';
	let h = d.getHours();
	if (h > 11) {
		if (h > 12)
			h = h - 12;
		ampm = 'PM';
	}
	const hour = `0${h}`.substring(-2, 2);
	const minute = `0${d.getMinutes()}`.substring(-2, 2);
	let time = `${month} ${day} ${year}`;
	if (match.dateTime.indexOf('TBD') > 0)
		time = `${time} TBD`;
	else if (minute === 0)
		time = `${time} ${hour} ${ampm} CST`;
	else if (minute === 0)
		time = `${time} ${hour}:${minute} ${ampm} CST`;
	else if (minute === 0)
		time = `${time} ${hour}:${minute} ${ampm} CST`;

	return (
		<>
			<ul className={styles.matchStatusArea}>
				<li>
					<TeamInfoUpcoming team={match.contestants[0]} />
				</li>
				<li>
					<ul className={styles.matchStatusAreaUpcoming}>
						<li className={styles.matchDisplayDescriptionUpcoming}>
							{match.description}
						</li>
						<li className={styles.matchDisplayDateUpcoming}>
							{time}
						</li>
						<li className={styles.matchDisplaySponsorUpcoming}>
							{venue}
						</li>
						<li className={styles.matchDisplaySponsorUpcoming}>
							{match.sponsor}
						</li>
					</ul>

				</li>
				<li>
					<TeamInfoUpcoming team={match.contestants[1]} />
				</li>
			</ul>
		</>
	)

}

const LiveTime = ({ time }) => {
	const t = time.substring(0, 8).split(':');
	t[1] = +t[1] + (60 * +t[0])
	return (
		<>
			<ul className={styles.liveTimeArea}>
				<li className={styles.liveTime}>
					<img className={styles.liveTimeGreenLight} src={greenLight} />
					<span>LIVE&nbsp;-&nbsp;</span>
					{t[1] > 0 && (
						<span>{t[1]}&apos;&nbsp;</span>
					)}
					{t[2] > 0 && (
						<span>{t[2]}&quot;&nbsp;</span>
					)}
					<span className={styles.liveTeamMatchHub}>MATCH HUB</span>
				</li>
			</ul>
		</>
	);
}

const TeamInfoLive = ({ team, homeAway }) => {
	return (
		<>
			<ul className={homeAway === 'home' ? styles.teamInfoHome : styles.teamInfoAway}>
				<li className={`${homeAway === 'home' ? styles.teamInfoNameHome : styles.teamInfoNameAway} ${styles.teamInfoName}`}>{team.name}</li>
				<li className={styles.teamInfoFlag}>{allFlags[team.code]}</li>
				<li className={styles.teamInfoScore}>{team.score}</li>
			</ul>
		</>
	);
};

const TeamInfoUpcoming = ({ team }) => {
	return (
		<>
			<ul className={styles.TeamInfoUpcoming}>
				<li className={styles.teamInfoName}>{team.name}</li>
				<li className={styles.teamInfoFlag}>{allFlags[team.code]}</li>
			</ul>
		</>
	);
};

export default compose(
	withErrorHandling()
)(ScoreBarFull);