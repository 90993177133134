import React from "react";
import { Text } from "~/patches/components";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import SectionLabel from "~/shared-components/SectionLabel";
import MatchScheduleCarousel from "~/shared-components/MatchScheduleCarousel";
import ScoreboardThumbnailContainer from "~/shared-components/ScoreboardBanner/ScoreboardThumbnailContainer";
import { withNamespaces } from "react-i18next";
import { Carousel } from "~/shared-components";
import styles from "./MatchModule.module.scss";

const MatchModule = ({ fields, rendering: { upcomingMatches, matchResults, scoreboardBanner }, t }) => (
	<>
		{fields && (
			<div>
				<div className={styles.sectionLabelContainer}>
					<SectionLabel sectionLabel={fields.sectionLabel} />
				</div>
				<Text field={fields.heading} />
				<MatchScheduleCarousel {...{upcomingMatches, matchResults}} />
				{scoreboardBanner && (
					<div>
						<Text field={fields.scoreboardHeading} />
						<Carousel>
							{scoreboardBanner.map((item, index) => (
								<div key={index}>
									<ScoreboardThumbnailContainer {...{...item, feedUrlName: t("scoreboard_recent_feed_link_name")}}/>
								</div>
							))}
						</Carousel>
					</div>
				)}
			</div>
		)}
	</>
);

export default compose(
	// other hocs goes here
	withNamespaces(),
	withErrorHandling()
)(MatchModule);