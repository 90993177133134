exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".TeamGrid-module__TeamGrid--R-YeS {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n  -ms-flex-pack: justify;\n      justify-content: space-between;\n  padding: 0 2rem; }\n\n.TeamGrid-module__item--2hQzu {\n  margin-bottom: 3rem;\n  width: 100%; }\n\n@media (min-width: 1025px) {\n  .TeamGrid-module__TeamGrid--R-YeS {\n    margin: 0 auto;\n    max-width: 100%;\n    padding: 0 8.333rem;\n    width: 1440px; }\n  .TeamGrid-module__item--2hQzu {\n    margin-bottom: 2.5rem;\n    width: calc(50% - 1.125rem); } }\n\n@media (min-width: 1640px) {\n  .TeamGrid-module__TeamGrid--R-YeS {\n    width: 1640px; } }\n", ""]);

// exports
exports.locals = {
	"TeamGrid": "TeamGrid-module__TeamGrid--R-YeS",
	"item": "TeamGrid-module__item--2hQzu"
};