import React from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import styles from "./ImplicitGrid.module.scss";

const ImplicitGrid = ({ rendering, fields }) => {

	const threeColumnGrid = fields?.threeColumn?.value ? `${styles.threeCol}` : "";

	return (
		<div className={styles.grid}>
			<div className={`${styles.implicitGrid} ${threeColumnGrid}`}>
				<Placeholder name="jss-layout-grid" rendering={rendering} />
			</div>
		</div>
	);
};

export default compose(
	// other hocs goes here
	withErrorHandling()
)(ImplicitGrid);