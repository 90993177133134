import React, { PureComponent } from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import CompetitionsFilter from "~/shared-components/CompetitionsFilter";
import CompetitionCardModule from "~/shared-components/CompetitionCardModule";
import styles from "./CompetitionsGrid.module.scss";

class CompetitionsGrid extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			filteredCompetitions: []
		};
	}

	render() {
		const { rendering: { competitions }} = this.props;
		const { filteredCompetitions } = this.state;

		return (
			<>
				<CompetitionsFilter allCompetitions={competitions} updatedFilteredCompetitions={competitions => this.setState({ filteredCompetitions: competitions })} />

				<div className={styles.CompetitionsGrid}>
					{filteredCompetitions.map((competition, i) => (
						<CompetitionCardModule key={i} competitionData={competition} />
					))}
				</div>
			</>
		);
	}
}

export default compose(
	// other hocs goes here
	withErrorHandling()
)(CompetitionsGrid);