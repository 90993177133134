import React, { Fragment } from "react";
import { Text } from "~/patches/components";
import { Star } from "~/shared-components/SvgIcons";
import styles from "./HighlightStats.module.scss";
import { fieldObjHasValue } from "~/utilities";

const HighlightStats = ({ stats, heading, params }) => {
	// params is undefined if no field has a value
	// param properties only has raw values in Sitecore (strings)
	const hideHeading = params?.hideHeading === "1";

	return (
		<Fragment>
			{stats && stats.length > 0 && (
				<aside className={styles.container}>
					<div>
						{!hideHeading && fieldObjHasValue(heading) && (
							<header>
								<Text field={heading} tag="h5" />
							</header>
						)}
						<div className={styles.figures}>
							{	stats.map((stat, index) =>
								<figure key={`highlight-stat-${index}`}>
									<figcaption>
										<Text field={stat?.fields?.statType?.fields?.statName} />
									</figcaption>
									<div className={styles.value}>
										{/** Check using the Opta ID is better, since statName can be translated. */}
										{stat?.fields?.statType?.fields?.optaId.value.localeCompare("numberofWorldCupWins", "en", { sensitivity: 'base'}) === 0 ?
											<div className={styles.starContainer}>
												{[...Array(parseInt(stat?.fields?.statValue?.value))].map(
													(star, index) => <Star key={index} />
												)}
											</div>
											:
											<Text field={stat?.fields?.statValue} />
										}
									</div>
								</figure>
							)}
						</div>
					</div>
				</aside>
			)}
		</Fragment>
	);
};

export default HighlightStats;
