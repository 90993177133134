exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".LoginAvatar-module__loginAvatar--2Yrb7 {\n  margin-right: 0.83rem;\n  position: relative;\n  z-index: 14; }\n  .LoginAvatar-module__loginAvatar--2Yrb7 img {\n    width: 2.33rem; }\n  .LoginAvatar-module__loginAvatar--2Yrb7:hover {\n    cursor: pointer; }\n", ""]);

// exports
exports.locals = {
	"loginAvatar": "LoginAvatar-module__loginAvatar--2Yrb7"
};