import React, { PureComponent } from "react";
import { withErrorHandling } from "~/hoc";
import { allFlags } from "~/utilities/flags";
import { compose } from "recompose";
import styles from "./CompetitionStandingsTable.module.scss";

class CompetitionStandingsTable extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			currentGroupIndex: 0
		};
	}

	componentDidMount() {
		const { stage } = this.props;
		const USAIndex = stage.divisions.findIndex(division => division.rankings.find(team => team.contestantCode === 'USA'));

		if (USAIndex > 0) {
			this.setState({
				currentGroupIndex: USAIndex
			});
		}
	}

	render() {
		const { stage } = this.props;
		const { currentGroupIndex } = this.state;
		const rankings = stage?.divisions[currentGroupIndex]?.rankings;

		return (
			<div className={styles.CompetitionStandingsTable}>
				{stage.divisions?.length > 1 &&
					<>
						<div className={styles.stageName}>
							{stage.name}
						</div>
						<div className={styles.groupContainer}>
							<div className={styles.inner}>
								{stage.divisions.map((division, index) =>
									<button
										key={index}
										className={index === currentGroupIndex ? styles.active : styles.inactive }
										onClick={() => this.setState({ currentGroupIndex: index })}>
										{division.groupName}
									</button>
								)}
							</div>
						</div>
					</>
				}

				<div className={styles.groupTableContainer}>
					<table>
						<tbody>
							<tr>
								<th></th>
								<th className={styles.desktop}><span className={styles.desktop}>Matches Played</span></th>
								<th>
									<span className={styles.mobile}>W</span>
									<span className={styles.desktop}>Wins</span>
								</th>
								<th>
									<span className={styles.mobile}>D</span>
									<span className={styles.desktop}>Draws</span>
								</th>
								<th>
									<span className={styles.mobile}>L</span>
									<span className={styles.desktop}>Losses</span>
								</th>
								<th className={styles.desktop}><span className={styles.desktop}>Goals For</span></th>
								<th className={styles.desktop}><span className={styles.desktop}>Goals Against</span></th>
								<th>
									<span className={styles.mobile}>GD</span>
									<span className={styles.desktop}>Goal Differential</span>
								</th>
								<th>
									<span className={styles.mobile}>P</span>
									<span className={styles.desktop}>Points</span>
								</th>
							</tr>
							{rankings.map((team, index) =>
								<tr key={index}>
									<td className={styles.team}>{(allFlags[team.contestantCode] ? allFlags[team.contestantCode] : allFlags.PLACEHOLDER)} {team.contestantCode}</td>
									<td className={styles.desktop}>{team.matchesPlayed}</td>
									<td>{team.wins}</td>
									<td>{team.draws}</td>
									<td>{team.losses}</td>
									<td className={styles.desktop}>{team.goalsFor}</td>
									<td className={styles.desktop}>{team.goalsAgainst}</td>
									<td>{team.goalDifference}</td>
									<td>{team.points}</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}

export default compose(
	// other hocs goes here
	withErrorHandling()
)(CompetitionStandingsTable);