exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".RedesignCarousel-module__carousel--5sgn0 {\n  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.02)), to(#FFFFFF));\n  background: -o-linear-gradient(top, rgba(0, 0, 0, 0.02) 0%, #FFFFFF 100%);\n  background: linear-gradient(180deg, rgba(0, 0, 0, 0.02) 0%, #FFFFFF 100%);\n  mix-blend-mode: multiply; }\n\n.pageEditing .RedesignCarousel-module__flickity-slider--16P7F > * {\n  width: 100%; }\n\n.flickity-prev-next-button .flickity-button-icon {\n  fill: #C2002F; }\n\n.RedesignCarousel-module__item--3U2yC {\n  width: 100%; }\n\n@media (min-width: 768px) {\n  .RedesignCarousel-module__columns--2_M48 {\n    margin-bottom: 2.6rem; }\n  .RedesignCarousel-module__item--3U2yC {\n    padding: 0 0rem;\n    padding-right: 2rem;\n    margin-left: 2px;\n    width: 50%; } }\n\n@media screen and (min-width: 834px) and (max-width: 1439px) {\n  .RedesignCarousel-module__columns--2_M48 {\n    margin-bottom: 2.6rem; }\n  .RedesignCarousel-module__item--3U2yC {\n    padding: 0 0rem;\n    padding-right: 2rem;\n    margin-left: 2px;\n    width: 50%; } }\n\n@media (min-width: 1025px) {\n  .RedesignCarousel-module__columns--2_M48 {\n    margin-bottom: 2.167rem; }\n  .RedesignCarousel-module__item--3U2yC {\n    padding-right: 2rem;\n    width: 100%; } }\n\n@media (min-width: 1440px) {\n  .RedesignCarousel-module__item--3U2yC {\n    padding: 0 1.4rem;\n    width: 100%; } }\n", ""]);

// exports
exports.locals = {
	"carousel": "RedesignCarousel-module__carousel--5sgn0",
	"flickity-slider": "RedesignCarousel-module__flickity-slider--16P7F",
	"item": "RedesignCarousel-module__item--3U2yC",
	"columns": "RedesignCarousel-module__columns--2_M48"
};