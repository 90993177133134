import {optaApi} from "~/utilities/constants";

export const getPenaltiesForMatch = ({ match, home, away }) => {
	const penaltyShotsScored = (contestantId) => {
		const { penaltyShots } = match;
		return penaltyShots.filter(pk => pk.contestantId === contestantId && pk.outcome === 'scored').length;
	};

	const homePenalties = match?.penaltyShots?.length > 0
		? penaltyShotsScored(home?.id)
		: null;

	const awayPenalties = match?.penaltyShots?.length > 0
		? penaltyShotsScored(away?.id)
		: null;

	return {
		home: homePenalties,
		away: awayPenalties,
		shouldDisplay: (
			// Make sure the match has correct status or is in the penalty shootout
			// period.
			(
				match.status === optaApi.matchStatus.completed ||
				match.currentPeriod === optaApi.periodType.penaltyShootout
			) &&
			// Make sure we have penalties to show.
			homePenalties !== null &&
			awayPenalties !== null
		),
	};
};