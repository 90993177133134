exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".MemberLink-module__memberLink--3B1tR {\n  margin: 4.4rem auto;\n  padding: 0 2rem; }\n  .MemberLink-module__memberLink--3B1tR a {\n    border: 0.1rem solid #FFFFFF;\n    border-radius: 3px;\n    font-size: 1.2rem;\n    padding: 2.2rem 0;\n    text-align: center; }\n", ""]);

// exports
exports.locals = {
	"memberLink": "MemberLink-module__memberLink--3B1tR"
};