import React, { useState } from "react";
import moment from "moment";
import BroadcasterLinks from "~/shared-components/BroadcasterLinks";
import { Image, Text } from "~/patches/components";
import JsonLd from "~/components/JsonLd";
import styles from "./MatchInfo.module.scss";
import { optaApi, USTeamNames, validDateTimeFormat } from "~/utilities/constants";
import {allFlags, openCupFlags} from "~/utilities/flags";
import { withErrorHandling } from "~/hoc";
import { useFetchLiveMatchData } from "~/hooks/match";
import { fieldObjHasValue } from "~/utilities";
import { getDoubleHeaderMessage } from "~/utilities/matches";
import ball from "~/assets/img/GoalAndGoalReplay.png";
import bgMaskMobile from "~/assets/svg/p1-bg-mask-extended.svg";
import bgMaskMobileShort from "~/assets/img/p1-bg-mask.png";
import bgMaskHome from "~/assets/img/p1-event-mask-home-desktop.png";
import bgMaskAway from "~/assets/img/p1-event-mask-away-desktop.png";
import { compose } from "recompose";
import "moment-timezone";
import {competitions} from "~/utilities/competitions";

const MatchInfo = ({ completed, competitionLogo, doubleHeaderMatches, getTotalGoals, match, pageEditing, type,ticketMasterIcon }) => {
	const [isValidMatchDateTime, setIsValidMatchDateTime] = useState(true);
	const [updatedMatch, setUpdatedMatch] = useState(null);

	useFetchLiveMatchData(match, setIsValidMatchDateTime, setUpdatedMatch, getTotalGoals);

	const buildMatchTeamData = (updatedMatch) => {
		const matchDate = updatedMatch?.date && moment(updatedMatch.date).isValid() ? moment(updatedMatch.date).format('MMM DD YYYY') : "TBD";
		const dataObjectStartDate = isValidMatchDateTime && updatedMatch ? updatedMatch.dateTime : updatedMatch?.date;
		const home = updatedMatch?.contestants[0] ? updatedMatch.contestants[0] : null;
		const away = updatedMatch?.contestants[1] ? updatedMatch.contestants[1] : null;
		const USTeam = updatedMatch?.contestants.filter(contestant => contestant.code === "USA")[0];
		const USTeamId = USTeam?.id;
		const USTeamName = USTeamNames[USTeamId];
		const homePenaltyShotsScored = updatedMatch?.currentPeriod === optaApi.periodType.penaltyShootout ||
			updatedMatch?.penaltyShots?.length > 0
			? penaltyShotsScored(home.id)
			: null;
		const awayPenaltyShotsScored = updatedMatch?.currentPeriod === optaApi.periodType.penaltyShootout ||
			updatedMatch?.penaltyShots?.length > 0
			? penaltyShotsScored(away.id)
			: null;

		const mobileBgMask = completed ? bgMaskMobileShort : bgMaskMobile;
		const desktopBgMask = match.contestants && match.contestants[0].code !== "USA" ? bgMaskAway : bgMaskHome;

		const dataObject = {
			"@context": "http://schema.org",
			"@type": "SportsEvent",
			"name": home?.id === USTeamId ? `${USTeamName} v. ${away?.name}` : `${home?.name} v. ${USTeamName}`,
			"description": updatedMatch?.competition?.name,
			"startDate": dataObjectStartDate,
			"location": {
				"@type": "Place",
				"name": `${updatedMatch?.venue?.longName}`,
				"address": `${updatedMatch?.venue?.location}, ${updatedMatch?.venue?.country}`
			},
			"competitor": [
				{
					"@type": "SportsTeam",
					"name": home?.id === USTeamId ? USTeamName : home?.name
				},
				{
					"@type": "SportsTeam",
					"name": away?.id === USTeamId ? USTeamName : away?.name
				}
			],
			"url": updatedMatch?.mathchFeedUrl
		};

		return {
			away,
			awayPenaltyShotsScored,
			dataObject,
			desktopBgMask,
			home,
			homePenaltyShotsScored,
			matchDate,
			mobileBgMask,
			USTeamName
		};
	};

	const penaltyShotsScored = (contestantId) => {
		const { penaltyShots } = updatedMatch;
		const penaltyShotsScored = penaltyShots.filter(pk => pk.contestantId === contestantId && pk.outcome === "scored").length;

		return penaltyShotsScored;
	};

	const builtMatchTeamData = buildMatchTeamData(updatedMatch);
	const hasBroadcastLinks = match?.sitecoreData?.broadcasterLinks?.length || match?.broadcastLinks?.length;
	const isDoubleHeader = doubleHeaderMatches?.length;
	const stageName = doubleHeaderMatches?.length ? getDoubleHeaderMessage(doubleHeaderMatches.length) : updatedMatch?.stageName;

	const {
		dataObject,
		desktopBgMask,
		mobileBgMask,
		USTeamName
	} = builtMatchTeamData;

	let {
		away,
		awayPenaltyShotsScored,
		home,
		homePenaltyShotsScored,
		matchDate,
	} = builtMatchTeamData;

	let matchOpponentsDisplayProps = {
		away,
		awayPenaltyShotsScored,
		doubleHeaderMatches,
		home,
		homePenaltyShotsScored,
		matchDate,
		isValidMatchDateTime,
		type,
		updatedMatch
	};

	return (
		updatedMatch && (
			<div className={`${(type === "P1" || type === "P1Competition") ? styles.MatchInfoP1 : styles.MatchInfo} ${competitionLogo || (fieldObjHasValue(updatedMatch.sitecoreData?.matchLogo, "image")) ? styles.withLogos : styles.noLogos}`}>
				<div className={isDoubleHeader ? styles.topTextDoubleHeader : styles.topText}>
					{competitionLogo || (fieldObjHasValue(updatedMatch.sitecoreData?.matchLogo, "image") || pageEditing)
						? type === "P1" &&
							updatedMatch?.sitecoreData?.competition?.competitionUrl
							? <a href={updatedMatch.sitecoreData?.competition?.competitionUrl}>
								<Image field={competitionLogo ? competitionLogo : updatedMatch.sitecoreData?.matchLogo} className={competitionLogo ? styles.competitionLogo : styles.matchLogo} />
							</a>
							: <Image field={competitionLogo ? competitionLogo : updatedMatch.sitecoreData?.matchLogo} className={competitionLogo ? styles.competitionLogo : styles.matchLogo} />
						: type === "P1" &&
							updatedMatch?.sitecoreData?.competition?.competitionUrl
							? <a href={updatedMatch.sitecoreData?.competition?.competitionUrl}>
								<div className={styles.title}>{updatedMatch.competition.name}</div>
							</a>
							: <div className={styles.title}>{updatedMatch.competition.name}</div>
					}
				</div>

				<div className={isDoubleHeader ? styles.subTitleDoubleHeader : styles.subTitle}>
					{updatedMatch.competition.isFriendly && updatedMatch.competition.name === "International Friendly"
						? USTeamName
						: USTeamName ? `${USTeamName} - ${stageName}` : stageName}
				</div>

				{/*
					If we have a double header at all, then we know this first one is the initial double header.
				    So pass a prop (isInitialDoubleHeader) to notate that because we need some different styling
					and other logic for the top match vs. the bottom match on the match card.
				*/}
				<MatchOpponentsDisplay {...matchOpponentsDisplayProps} isInitialDoubleHeader={isDoubleHeader} ticketMasterIcon ={ticketMasterIcon}/>
				{doubleHeaderMatches?.map((doubleHeaderMatch, i) => {
					matchOpponentsDisplayProps = {
						away,
						awayPenaltyShotsScored,
						home,
						homePenaltyShotsScored,
						matchDate
					} = buildMatchTeamData(doubleHeaderMatch);
					matchOpponentsDisplayProps.pageEditing = pageEditing;
					matchOpponentsDisplayProps.type = type;
					matchOpponentsDisplayProps.updatedMatch = doubleHeaderMatch;

					return (
						<>
							<div className={`${styles.doubleHeaderBorder} ${hasBroadcastLinks ? styles.doubleHeaderBorderTop : ""}`} />
							<br />
							<MatchOpponentsDisplay key={i} {...matchOpponentsDisplayProps} isDoubleHeader isValidMatchDateTime={moment(doubleHeaderMatch.dateTime, validDateTimeFormat, true).isValid()} ticketMasterIcon={ticketMasterIcon}/>
						</>
					);
				})}

				{/* Bottom triangle here */}
				<div className={styles.mask}>
					<img
						className={`${styles.bgMask} ${styles.desktop}`}
						src={desktopBgMask}
						alt="" />
					<img
						className={`${styles.bgMask} ${styles.mobile} ${type === "P1Competition" ? styles.p1Competition : ""}`}
						src={mobileBgMask}
						alt="" />
				</div>

				{((type === "P1" || type === "P1Competition") && updatedMatch.status !== optaApi.matchStatus.upcoming) &&
					<div className={updatedMatch.status === optaApi.matchStatus.live ? styles.goalsContainerLive : styles.goalsContainerCompleted}>
						{updatedMatch.goals.length > 0 &&
							<img
								className={styles.scoreIcon}
								src={ball}
								alt="" />}
						{updatedMatch.goals.map((goal, index) => (
							<div key={index} className={goal.contestantId === home.id && goal.goalType !== 2 || goal.contestantId === away.id && goal.goalType === 2 ? styles.scorerHome : styles.scorerAway}>
								<div className={styles.scorerName}>
									{goal.goalType === 2
										? `${goal.scorerName} (O.G.)`
										: goal.goalType === 1
											? `${goal.scorerName} (PEN)`
											: goal.scorerName
									}
									{goal.assistPlayerName && <span className={styles.assistedByName}>Assist {goal.assistPlayerName}</span>}
								</div>
								{/* eslint-disable-next-line */}
								<div className={styles.goalTime}>{goal.timeMin}'</div>
							</div>
						))}
					</div>
				}
				<JsonLd data={dataObject} />
			</div>
		)
	);
};

const MatchOpponentsDisplay = (
	{
		away,
		awayPenaltyShotsScored,
		home,
		homePenaltyShotsScored,
		isDoubleHeader,
		isInitialDoubleHeader,
		matchDate,
		isValidMatchDateTime,
		pageEditing,
		type,
		updatedMatch,
		ticketMasterIcon
	}) => {
	const flagsRepository = updatedMatch.competition.id === competitions.openCup ? openCupFlags : allFlags;

	return (
		<>
			<div className={isInitialDoubleHeader ? styles.doubleHeaderLift : ""}>
				<div className={updatedMatch.status === optaApi.matchStatus.upcoming ? styles.teamVsUpcoming : styles.teamVs}>

					<div className={styles.teamFlag}>
						{flagsRepository[home.code]}
					</div>

					<div className={styles.team}>{home.code}</div>

					<div className={styles.vs}>
						{updatedMatch.status === optaApi.matchStatus.upcoming
							? "VS"
							: (
								<>
									{home.score}
									{updatedMatch.status === optaApi.matchStatus.completed ? <div className={styles.ft}>FT</div> : <div>-</div>}
									{away.score}
								</>
							)
						}
					</div>

					<div className={styles.team}>{away.code}</div>

					<div className={styles.teamFlag}>
						{flagsRepository[away.code]}
					</div>
				</div>

				{(updatedMatch?.currentPeriod === optaApi.periodType.penaltyShootout ||
						updatedMatch?.currentPeriod === optaApi.periodType.fullTime &&
						updatedMatch?.penaltyShots?.length > 0) &&
					<div className={(type === "P1" || type === "P1Competition") ? styles.penaltyScoresP1 : styles.penaltyScoresMatchCard}>
						{updatedMatch?.currentPeriod === optaApi.periodType.penaltyShootout
							? `(${home.code} ${homePenaltyShotsScored} - ${awayPenaltyShotsScored} ${away.code})`
							: homePenaltyShotsScored > awayPenaltyShotsScored
								? `(${home.code} wins ${homePenaltyShotsScored} - ${awayPenaltyShotsScored} on penalties)`
								: `(${away.code} wins ${awayPenaltyShotsScored} - ${homePenaltyShotsScored} on penalties)`
						}
					</div>
				}

				<div className={styles.sponsorText}>
					<div className={updatedMatch?.penaltyShots?.length > 0 && type === "MatchCard" ? styles.sponsorWithPenaltyShotsMatchCard : styles.sponsor}>
						{updatedMatch.sitecoreData && (
							<>
								{(fieldObjHasValue(updatedMatch.sitecoreData?.sponsorText) || pageEditing) ? (
									<Text field={updatedMatch.sitecoreData.sponsorText} />
								) : (
									<>{updatedMatch.sponsor}</>
								)}
							</>
						)}
					</div>

					{/* TODO: translate "Live" using t(). Refine dateTime/timezone handling, check accuracy */}
					{updatedMatch.status === optaApi.matchStatus.live ?
						<div className={`${styles.matchMinute} ${isDoubleHeader || isInitialDoubleHeader ? styles.matchMinuteDoubleHeader : ""}`}>
							Live - {updatedMatch.currentPeriod === optaApi.periodType.halfTime
								? `Halftime`
								: updatedMatch.currentPeriod === optaApi.periodType.endSecondHalfBeforeExtraTime ||
								updatedMatch.currentPeriod === optaApi.periodType.extraTimeHalfTime ||
								updatedMatch.currentPeriod === optaApi.periodType.endExtraTimeBeforePenalties
									? `Extra Time`
									: updatedMatch.currentPeriod === optaApi.periodType.penaltyShootout
										? `Penalties`
										: `${updatedMatch.lengthMin} ${updatedMatch.addedTimeForCurrentPeriod > 0 ? ` + ${updatedMatch.addedTimeForCurrentPeriod}` : ""}'`
							}
						</div>
						:
						isValidMatchDateTime ?
							<>
								{(moment(updatedMatch.dateTime) < moment() && updatedMatch.status === optaApi.matchStatus.upcoming)
									? <div className={styles.awaiting}>Awaiting Kickoff</div>
									: <div className={updatedMatch.status !== optaApi.matchStatus.completed ? styles.matchStartTime : styles.completedMatchDate}>
										{updatedMatch.status !== optaApi.matchStatus.completed
											?
											updatedMatch.cancelled
												? <><span>Cancelled</span></>
												: `${moment(updatedMatch.dateTime).tz(moment.tz.guess()).format('MMM DD YYYY - h:mmA')} ${moment(updatedMatch.dateTime).tz(moment.tz.guess()).zoneAbbr()}` /*eslint-disable-line*/
											: moment(updatedMatch.dateTime).tz(moment.tz.guess()).format('MMM DD YYYY') /*eslint-disable-line*/
										}
									</div>
								}
							</>
							:
							<>
								<div className={updatedMatch.status !== optaApi.matchStatus.completed ? styles.matchStartTime : styles.completedMatchDate}>
									{updatedMatch.status !== optaApi.matchStatus.completed ?
										<>
											{matchDate
												?
												<>
													<span>{matchDate} - TBD</span>
												</>
												:
												<>
													<span>TBD</span>
												</>
											}
										</>
										:
										<>
											<span>TBD</span>
										</>
									}
								</div>
							</>
					}
					{type === "P1" && <BroadcasterLinks match={updatedMatch} isInitialDoubleHeader={isInitialDoubleHeader} matchType={type} />}
					{updatedMatch.status !== optaApi.matchStatus.live && !isInitialDoubleHeader &&
						<div className={styles.location}>
							{!updatedMatch?.venue?.longName ||
							updatedMatch?.venue?.longName === "TBD"
								? "TBD"
								: <>{updatedMatch?.venue?.longName},<br /> {updatedMatch?.venue?.location}, {updatedMatch?.venue?.country}</>
							}
						</div>
					}
				</div>
			</div>
			{type !== "P1" && <BroadcasterLinks match={updatedMatch} isInitialDoubleHeader={isInitialDoubleHeader} matchType={type} ticketMasterIcon={ticketMasterIcon} />}
		</>
	);
};

export default compose(
	// other hocs goes here
	withErrorHandling()
)(MatchInfo);