import React from "react";
import { Text } from "~/patches/components";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import styles from "./RedesignGoogleAd.module.scss";

const RedesignGoogleAd = ({ fields, params }) => {
	if (fields.square?.value) {
		return (
			<div className={styles.square}>
				<div id={fields.id?.value}  />
			</div>
		);
	}
	return (
		<>
			{fields && (
				<>
					<div className={styles.googleAdWrap728}>
						<div id={fields.id?.value}  />
					</div>
					<div className={styles.googleAdWrap320}>
						<div id={`${fields.id?.value}-small`}  />
					</div>
				</>
			)}
		</>
	);
}

export default compose(
	// other hocs goes here
	withErrorHandling()
)(RedesignGoogleAd);