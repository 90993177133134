import React from "react";
import { isExperienceEditorActive } from "@sitecore-jss/sitecore-jss-react";
import { fieldObjHasValue } from "~/utilities";
import styles from "./withSectionHeader.module.scss";
import SectionLabel from "~/shared-components/SectionLabel";
import ArrowLink from "../../shared-components/ArrowLink";
import TagLink from "./TagLink";

/**
 * @param {function} func - Callback function
 */
export function withSectionHeader(func = undefined) {
	return function withSectionHeaderHoc(WrappedComponent) {
		return function WithSectionHeader(props) {
			if (func) {
				try {
					const obj = func(props);
					if (obj?.visible === false) {
						return <>{Component}</>;
					}
				} catch (e) {
					console.error(e); // eslint-disable-line
				}
			}

			const { rendering: { uid, tagLink }, fields, params, } = props;
			const Component = <WrappedComponent {...props} />;

			if (params && (params.hideSectionHeader === "1" || params.hideSectionHeader === true)) return Component;
			if (!fields) return Component;

			const pageEditing = isExperienceEditorActive();
			const hasSectionLink = fieldObjHasValue(fields.sectionLink, "link");

			return (
				<section id={`section_${uid}`} className={styles.sectionHeader}>

					<div className={styles.content}>
						{(fieldObjHasValue(fields.sectionLabel)  || fieldObjHasValue(fields.sectionHeaderLabel) || pageEditing) && (
							<SectionLabel sectionLabel={fields.sectionLabel || fields.sectionHeaderLabel} pageEditing={pageEditing} />
						)}
						{(hasSectionLink || pageEditing) &&
							<div className={styles.link}>
								<ArrowLink link={fields.sectionLink}/>
							</div>
						}
						{(!hasSectionLink && tagLink && !pageEditing) && (
							<div className={styles.link}>
								<TagLink link={tagLink}/>
							</div>
						)}
					</div>
					{Component}
				</section>
			);
		};
	};
}