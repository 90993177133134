import React, { Component } from "react";
import { compose } from "recompose";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { withScroll } from "~/hoc";
import RedesignPrimaryNav from "./RedesignPrimaryNav";
import RedesignProfile from "./RedesignProfile";
import RedesignSearchForm from "~/shared-components/RedesignSearchForm";
import RedesignCallToActionMenu from "./RedesignCallToActionMenu";
import NavProvider from "./NavContext";
import styles from "./RedesignNavigationDesktop.module.scss";
import { Image, Text, Link } from "~/patches/components";
import { FacebookAlt, InstagramAlt, TikTok, SwooshAlt, TwitterAlt, VWAlt } from "~/shared-components/SvgIcons";
import { SocialLinksFooter } from "~/shared-components/SocialLinks";
class RedesignNavigationDesktop extends Component {

	constructor(props) {
		super(props);

		this.state = {
			adScriptLoaded: false,
			navigationShouldCollapse: false,
			scrollDepthReached: false,
			largeAd: false
		};

		this.GptAdDesktop = React.createRef();
	}

	componentDidMount() {
		window.addEventListener("gptLoaded", () => this.handleGPT(false)); //eslint-disable-line
		window.addEventListener("gptLoadedWithLargeAd", () => this.handleGPT(true)); //eslint-disable-line
		window.addEventListener("gptUnloaded", () => this.unloadGPT(false)); //eslint-disable-line
	}

	componentWillUnmount() {
		window.removeEventListener("gptLoaded", this.handleGPT); //eslint-disable-line
		window.removeEventListener("gptUnloaded", this.unloadGPT); //eslint-disable-line
	}

	componentDidUpdate(prevProps) {
		const { scroll } = this.props;
		const {
			scroll: prevScroll,
			sitecoreContext: {
				route: { name: prevRouteName }
			}
		} = prevProps;
		const {
			sitecoreContext: {
				route: { name: currentRouteName }
			}
		} = this.props;
		const { adScriptLoaded } = this.state;
		const scrollAmount = adScriptLoaded ? 300 : 200;

		if (typeof window !== 'undefined' && (currentRouteName !== prevRouteName)) {
			window?.googletag?.pubads().refresh()
		}

		if (prevScroll !== scroll && scroll < scrollAmount) {
			this.setState({
				navigationShouldCollapse: false,
				scrollDepthReached: false
			});
		} else if (prevScroll !== scroll) {
			this.setState({
				navigationShouldCollapse: scroll > prevScroll,
				scrollDepthReached: adScriptLoaded ? scroll > scrollAmount : true
			});
		}
	}

	handleGPT = isLargeAd => { // eslint-disable-line
		//eslint-disable-next-line
		//const adElement = document.querySelector("#div-gpt-ad-nav-desktop");
		const adElement = this.GptAdDesktop.current;
		if (!adElement) return;
		const adStyle = getComputedStyle(adElement);

		if (adStyle.display !== "none") {
			document.body.classList.add("ad-loaded"); //eslint-disable-line
			this.setState({
				adScriptLoaded: true,
				largeAd: isLargeAd
			});
		}

	}

	unloadGPT = () => {
		document.body.classList.remove("ad-loaded"); //eslint-disable-line
		this.setState({ adScriptLoaded: false });
	}

	render() {
		const { fields, sitecoreContext, rendering: { primaryMenuItems, pageNavigation } } = this.props;
		const {	adScriptLoaded, largeAd, navigationShouldCollapse, scrollDepthReached } = this.state;
		const isEditing = sitecoreContext && sitecoreContext.pageEditing;
		const settings = this.props.sitecoreContext.settings;

		return (
			<NavProvider>
				<nav className={styles.Nav}>
					<div className={styles.TopBar}>
						<div className={`redesignGridContainer ${styles.TopBarGrid}`}>
							{fields.currentEventImage1.value.src && fields.currentEventLink1.value.href ? (
								<Link field={fields.currentEventLink1} className={styles.eventLink1}>
									<Image field={fields.currentEventImage1} />
									<span><Text field={{value: fields.currentEventLink1.value.text}} /></span>
								</Link>
							) : <div></div>}
							{fields.currentEventImage2.value.src && fields.currentEventLink2.value.href ? (
								<Link field={fields.currentEventLink2} className={styles.eventLink2}>
									<Image field={fields.currentEventImage2} />
									<span><Text field={{value: fields.currentEventLink2.value.text}} /></span>
								</Link>
							) : <div></div>}
							<div className={styles.socialLinks}>
								<SocialLinksFooter />
							</div>
							<div className={styles.sponsorLink1}>
								{fields.sponsorLink1 && (
									<Link field={fields.sponsorLink1} aria-label={fields.sponsorLink1.value.text} data-title={fields.sponsorLink1.value.text}>
										<VWAlt />
									</Link>
								)}
							</div>
							<div className={styles.sponsorLink2}>
								{fields.sponsorLink2 && (
									<Link field={fields.sponsorLink2} aria-label={fields.sponsorLink2.value.text} data-title={fields.sponsorLink2.value.text}>
										<SwooshAlt />
									</Link>
								)}
							</div>
							<div className={styles.ctaMobile}>
								<RedesignCallToActionMenu
									member={fields.becomeAMemberLink}
									benefits={fields.memberBenefitsLink}
									tickets={fields.ticketsLink}
									isEditing={isEditing}
								/>
							</div>
							<div className={styles.profileLink}>
								<RedesignProfile
									loginPage={fields.loginPage}
									profilePage={fields.profilePage} />
							</div>
							<div className={styles.searchMobile}>
								<RedesignSearchForm />
							</div>
						</div>
					</div>

					<RedesignPrimaryNav
						nav={primaryMenuItems}
						logo={fields.logo}
						homeUrl={sitecoreContext.site.homeUrl}
						becomeAMemberLink={fields.becomeAMemberLink}
						memberBenefitsLink={fields.memberBenefitsLink}
						ticketsLink={fields.ticketsLink}
						adScriptLoaded={adScriptLoaded}
						membershipCallout={{
							image: fields.membershipCalloutImage,
							text: fields.membershipCalloutText,
							link: fields.membershipCalloutLink }}/>
				</nav>
			</NavProvider>
		);
	}
}

export default compose(
	withSitecoreContext(),
	withScroll()
)(RedesignNavigationDesktop);