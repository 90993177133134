exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SidebarNav-module__container--3WZgO {\n  width: 100%; }\n  .pageEditing .SidebarNav-module__container--3WZgO {\n    min-height: 17rem; }\n\n.SidebarNav-module__ad--nVrqg {\n  display: none; }\n\n@media (min-width: 1240px) {\n  .ad-loaded .SidebarNav-module__adContainer--3CytR .SidebarNav-module__ad--nVrqg {\n    display: block;\n    margin: 2rem auto;\n    width: 300px; } }\n", ""]);

// exports
exports.locals = {
	"container": "SidebarNav-module__container--3WZgO",
	"ad": "SidebarNav-module__ad--nVrqg",
	"adContainer": "SidebarNav-module__adContainer--3CytR"
};