/* eslint-disable no-restricted-globals */
import React, { PureComponent } from "react";
import { Text } from "~/patches/components";
import { compose } from "recompose";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import styles from "./TweetWidget.module.scss";
import { isServer } from "~/utilities";

const RetryInterval = 100;
const RetryLimit = 60; // 100ms * 60 = 60 seconds

class TweetWidget extends PureComponent {
	constructor(props) {
		super(props);
		this.domNodeRef = React.createRef();
	}

	componentDidMount = () => {
		this._mounted = true;
		this.tryMountTweet(0);
	}

	componentWillUnmount = () => {
		this._mounted = false;
	}

	tryMountTweet = retryCtr => {
		const { fields: { tweetId } } = this.props;
		if (isServer || !(tweetId?.value)) return;
		if (retryCtr > RetryLimit) {
			// eslint-disable-next-line no-console
			console.error("Could not find the Twitter widgets.js script");
			return;
		}

		if (this._mounted && window?.twttr?.widgets) {
			window.twttr.widgets.createTweet(tweetId.value, this.domNodeRef.current);
		}
		else {
			setTimeout(() => this._mounted && this.tryMountTweet(retryCtr + 1), RetryInterval);
		}
	}

	render = () =>	{
		const { fields, sitecoreContext } = this.props;
		return <>		
			<script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
			<div className={styles.container} >
				{sitecoreContext?.pageEditing && <p>Tweet ID: <Text field={fields.tweetId} /></p>}
				<div ref={this.domNodeRef} />
			</div>
		</>;
	}
}

export default compose(
	withSitecoreContext()
)(TweetWidget);