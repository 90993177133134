exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".StoryGrid-module__StoryGrid--2rL_M {\n  margin-bottom: 20px; }\n  .StoryGrid-module__StoryGrid--2rL_M .StoryGrid-module__hideOnMobile--1fbh3 {\n    display: none; }\n  .pageEditing .StoryGrid-module__StoryGrid--2rL_M > div:first-of-type {\n    display: -ms-flexbox;\n    display: flex; }\n\n.StoryGrid-module__carousel--1oXvt {\n  display: block;\n  margin-bottom: 5rem; }\n  .StoryGrid-module__carousel--1oXvt .flickity-viewport:before, .StoryGrid-module__carousel--1oXvt .flickity-viewport:after {\n    background: url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 100' preserveAspectRatio='none'%3E%3Cpolygon fill='%23FFFFFF' points='10,100 0,100 0,0 10,0 1,50'/%3E%3C/svg%3E\") left center/100% 100%;\n    bottom: 0;\n    content: '';\n    left: 0;\n    position: absolute;\n    top: 0;\n    width: 1rem;\n    z-index: 2; }\n  .StoryGrid-module__carousel--1oXvt .flickity-viewport:after {\n    left: auto;\n    right: 0;\n    -webkit-transform: rotate(180deg);\n            transform: rotate(180deg); }\n\n.StoryGrid-module__item--1U1QP {\n  padding: 0 2rem 0;\n  width: 100%; }\n\n.StoryGrid-module__columns--1JLiA {\n  display: -ms-flexbox;\n  display: flex;\n  height: 50rem; }\n\n@media (min-width: 768px) {\n  .StoryGrid-module__StoryGrid--2rL_M .StoryGrid-module__hideOnMobile--1fbh3 {\n    display: -ms-flexbox;\n    display: flex; }\n  .StoryGrid-module__columns--1JLiA {\n    margin-bottom: 2.6rem; }\n  .StoryGrid-module__carousel--1oXvt {\n    display: none;\n    margin-bottom: 6rem; }\n  .flickity-viewport:before, .flickity-viewport:after {\n    width: 1.2rem; } }\n\n@media (min-width: 992px) {\n  .StoryGrid-module__item--1U1QP {\n    width: 25%; } }\n\n@media (min-width: 1025px) {\n  .StoryGrid-module__columns--1JLiA {\n    margin-bottom: 2.167rem; }\n  .StoryGrid-module__item--1U1QP {\n    width: 20%; } }\n\n@media (min-width: 1440px) {\n  .StoryGrid-module__item--1U1QP {\n    width: 16.666%; } }\n", ""]);

// exports
exports.locals = {
	"StoryGrid": "StoryGrid-module__StoryGrid--2rL_M",
	"hideOnMobile": "StoryGrid-module__hideOnMobile--1fbh3",
	"carousel": "StoryGrid-module__carousel--1oXvt",
	"item": "StoryGrid-module__item--1U1QP",
	"columns": "StoryGrid-module__columns--1JLiA"
};