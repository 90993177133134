import React, { useEffect, useRef } from "react";
import { compose } from "recompose";
import { withErrorHandling } from "~/hoc";
import { Text } from "~/patches/components";
import styles from "./PageNavigation.module.scss";
import { ScrollLink } from "~/patches/components/ScrollLink";
import { throttled } from "~/utilities/helpers";
import { scrollToSmooth } from "~/utilities";
import { withRouter } from "react-router-dom";

/**
 * Placeholder for scroll dureation
 */
const scrollDuration = 300;

/**
 * Placeholder for scroll offset
 */
const scrollOffset = -185;

let height;

let _resizeEvent;

const PageNavigation = props => {
	const {pageNavigation, location} = props;
	const navRef = useRef(undefined);

	/**
	 * Handler height setup and add resize event listener
	 */
	function setupHeight() {

		if (!navRef || !pageNavigation) return;

		if (!height) {
			height = navRef?.current?.clientHeight;
			//eslint-disable-next-line
			document.body.style.paddingTop = `${50}px`;
		}

		if (!_resizeEvent) {

			_resizeEvent = throttled(() => setElementHeight(navRef?.current), 100);

			//eslint-disable-next-line
			window.addEventListener("resize", _resizeEvent);
		}
	}

	/**
	 *
		* @param {HTMLElement} element
	 */
	function setElementHeight(element) {

		if (!element) return;

		//eslint-disable-next-line
		document.body.style.paddingTop = `${element?.clientHeight}px`;
	}

	function hashSpy() {

		if (!location?.hash) return;

		/* Give other components a chance to load before triggering the scroll. */
		setTimeout(() => {
			scrollToSmooth(location.hash, {duration: scrollDuration, offset: scrollOffset});
		}, 500);
	}

	/**
	 * Effect to run on specified object change
	 */
	useEffect(() => {
		hashSpy();
		setupHeight();

		return (() => {
			//eslint-disable-next-line
			window.removeEventListener("resize", _resizeEvent);
			//eslint-disable-next-line
			document.body.style.paddingTop = `${0}`;
		});
	}, [pageNavigation]);


	return (
		<>
			{pageNavigation && (
				<nav className={styles.container}>
					<ul ref={navRef} className={styles.inner}>
						{pageNavigation.map((item, index) => (
							<li key={index}>
								<ScrollLink
									activeClass={styles.active}
									to={`${item.renderingId}`}
									spy={true}
									offset={scrollOffset}
									delay={100}
									isDynamic={true}
									duration={scrollDuration}>
									<Text field={item.sectionLabel} />
								</ScrollLink>
							</li>
						))}
					</ul>
				</nav>
			)}
		</>
	);
};

export default compose(
	withRouter,
	withErrorHandling()
)(PageNavigation);