import React, { useState, useEffect } from "react";
import { compose } from "recompose";
import { withErrorHandling } from "~/hoc";
import styles from "./SharedTabs.module.scss";
import SharedTab from "./SharedTab";
import { scrollToSmooth, isServer } from "~/utilities";

/**
 * Placeholder for rendered tabs with index for later use
 *
 * @typedef {{label: string, index: number, hashUrl: string}} tab
 * @type {Array<tab>}
 */
let _tabsLabels = [];



function SharedTabs(props) {

	const [activeTab, setActiveTab] = useState(0);



	populateTabsLabels();



	useEffect(() => {
		setActiveTabFromHash();
	}, []);



	function setActiveTabFromHash() {

		if (isServer) return;

		/* eslint-disable-next-line */
		const hash = window.location.hash;

		if (!hash) return;
		
		const hashValue = hash.replace("#", "");
		const filteredTabs = _tabsLabels.filter(item => item.hashUrl?.toLowerCase() === hashValue);

		if (filteredTabs && filteredTabs.length < 1) return;

		const tab = filteredTabs[0];

		setActiveTab(tab.index);

		/* Give other components a chance to load before triggering the scroll. */
		setTimeout(() => {
			scrollToSmooth(`#SharedTabs__${tab.hashUrl}`, {duration: 300, offset: -200, windowOffset: 300});
		}, 300);
	}



	function populateTabsLabels() {

		if (!props?.labels) return;

		/* Make sure to reset before populating */
		_tabsLabels = [];

		props.labels.forEach((label, index) => _tabsLabels.push({label, index, hashUrl: label?.value.toLowerCase().replace(/\s+/g, "-")}));
	}



	return (
		<>
			<div className={styles.SharedTabs}>
				<div className={styles.tabHeader}>
					<div className={styles.inner}>
						{_tabsLabels && _tabsLabels.length > 0 && (
							_tabsLabels.map((item, index) => (
								<SharedTab
									key={index}
									label={item.label}
									index={item.index}
									hashUrl={item.hashUrl}
									{...{activeTab, setActiveTab}} />
							))
						)}
					</div>
				</div>
				{props.render({
					activeTab,
					setActiveTab
				})}
			</div>
		</>
	);
}

export default compose(
	withErrorHandling()
)(SharedTabs);