// REDESIGN 2022 //

import {getPlatform, isRedesign} from "~/utilities/helpers";
import React from "react";

const PlatformSwitch = ({platforms, children}) => {
	const [platform, setPlatform] = React.useState(getPlatform());

	React.useEffect(() => {
		window.addEventListener('resize', () => {
			const newPlatform = getPlatform();
			if (newPlatform === platform) return;

			setPlatform(newPlatform);
		});
	});

	// Intentionally did not check children or platforms for null.
	// They are required, and I want an error thrown during testing if they're omitted.
	return isRedesign && React.isValidElement(children) && platforms.includes(platform)
		? <>{children}</>
		: <></>;
};

export default PlatformSwitch;
