exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "ul.TertiaryNav-module__tertiaryNav--2w9LQ {\n  display: -ms-flexbox;\n  display: flex;\n  list-style: none;\n  margin: 0; }\n  ul.TertiaryNav-module__tertiaryNav--2w9LQ li {\n    -ms-flex-align: center;\n        align-items: center;\n    display: -ms-inline-flexbox;\n    display: inline-flex;\n    -ms-flex-pack: center;\n        justify-content: center;\n    padding: 0.83rem; }\n    ul.TertiaryNav-module__tertiaryNav--2w9LQ li:empty {\n      display: none; }\n    ul.TertiaryNav-module__tertiaryNav--2w9LQ li.TertiaryNav-module__login--1lL87 {\n      padding-right: 0; }\n    ul.TertiaryNav-module__tertiaryNav--2w9LQ li a {\n      height: 100%; }\n    ul.TertiaryNav-module__tertiaryNav--2w9LQ li img {\n      vertical-align: bottom;\n      width: 2.75rem; }\n", ""]);

// exports
exports.locals = {
	"tertiaryNav": "TertiaryNav-module__tertiaryNav--2w9LQ",
	"login": "TertiaryNav-module__login--1lL87"
};