exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".HelperCommentContentEditor-module__container--2OiDO {\n  font-size: 1.3rem;\n  line-height: 1.2;\n  margin: 0 auto;\n  max-width: 500px;\n  padding: 2rem 0; }\n  .HelperCommentContentEditor-module__container--2OiDO img {\n    width: 17px; }\n", ""]);

// exports
exports.locals = {
	"container": "HelperCommentContentEditor-module__container--2OiDO"
};