import React from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import styles from "./CategoriesSidebar.module.scss";
import MultiLevelMenu from "~/shared-components/MultiLevelMenu";

const CategoriesSidebar = ({ fields, rendering: { sidebarNav, currentPageId } }) => (
	<>
		{fields && sidebarNav && (
			<div className={styles.container}>
				<MultiLevelMenu
					{...sidebarNav}
					currentPageId={currentPageId}
					heading={fields.heading}/>

				<div className={styles.adContainer}>
					<div id="div-gpt-ad-sidebar" className={styles.ad} />
				</div>
			</div>
		)}
	</>
);

export default compose(
	// other hocs goes here
	withErrorHandling()
)(CategoriesSidebar);