import React, { useState, useEffect } from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import { StoryCard } from "~/shared-components";
import styles from "./CategoryPage.module.scss";
import { StoryService } from "~/services/storyService";
import ShowMoreButton from "~/shared-components/ShowMoreButton";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import HelperCommentWildCardItem from "~/experience-editor/HelperCommentWildCardItem";

/**
 * Placeholder for the amount of stories to be fetched per page
 */
const storiesPerPage = 12;

const optionsColumns = {
	modifierClassName: 'small',
};

const CategoryPage = props => {
	const { rendering: { storiesByCategory, category, totalStories }, sitecoreContext: { pageEditing } } = props;
	const [stories, setStories] = useState([]);
	const [pageNumber, setPageNumber] = useState(1);
	const [hideButton, setHideButton] = useState(totalStories <= storiesPerPage);

	/**
	 * Handler to concat new fetched stories to stories state
	 */
	function handleStoriesChange() {

		if (!category) return;

		const newPageNumber = pageNumber + 1;
		const cumulativeStories = newPageNumber * storiesPerPage;

		setPageNumber(newPageNumber);

		StoryService.getStoriesByCategory(category, storiesPerPage, newPageNumber)
			.then(response => {

				if (response?.stories) {

					setStories(stories.concat(response.stories));
					setHideButton(totalStories <= cumulativeStories);
				}
			});
	}

	useEffect(() => {
		setStories(storiesByCategory);
		return () => {
			setStories([]);
		};
	}, [storiesByCategory]);

	//resets hideButton boolean and pageNumber to 1 when category prop changes
	useEffect(() => {
		setPageNumber(1);
		setHideButton(totalStories <= storiesPerPage);
	}, [category]);

	return  (
		<>
			{pageEditing && (
				<HelperCommentWildCardItem comment={"This module will not render as it is used on a wildcard page. But you have inserted it correctly and can be viewed on the client. Why it won't render? A wildcard item is a dynamic page that takes paremeters from the url, kinda. These parameters can't exist in the experience editor and therefor this message instead."} />
			)}
			{stories && (
				<div className={styles.container}>
					<div className={styles.row}>
						{stories.map((story, index) => (
							<div key={index} className={styles.col}>
								<StoryCardContainer {...story} />
							</div>
						))}
					</div>
					<ShowMoreButton showMoreHandler={() => handleStoriesChange()} {...{hideButton}} />
				</div>
			)}
		</>
	);
};

const StoryCardContainer = story => (
	<StoryCard {...{
		options: {
			modifierClassName: optionsColumns.modifierClassName
		},
		fields: {
			categoryValue: story.category?.value,
			categoryKey: story.categoryKey,
			categoryUrl: story.categoryUrl,
			date: story.date,
			heading: story.heading,
			image: story.image,
			imageText: story.imageText,
			link: { value: { href: story?.link }}
		}}
	} />
);


export default compose(
	// other hocs goes here
	withSitecoreContext(),
	withErrorHandling()
)(CategoryPage);