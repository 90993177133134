import React from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import SharedGrid from "~/shared-components/SharedGrid";
import { withMembershipVisibility } from "~/hoc/withMembershipVisibility";

const TwoColumnsGrid = ({ rendering }) => (
	<SharedGrid rendering={rendering} name="jss-two-columns-grid-content" mobileColumns={2} tabletColumns={2} desktopColumns={2} />
);

export default compose(
	withMembershipVisibility(),
	withErrorHandling()
)(TwoColumnsGrid);