
import React from "react";
import { compose } from "recompose";
import { withErrorHandling } from "~/hoc";
import { optaApi } from "~/utilities/constants";
import styles from "./BroadcasterLinks.module.scss";


const BroadcasterLinks = ({ match, isInitialDoubleHeader, isListView, matchType }) => {
	const isP1 = matchType === "P1" || matchType === "P1Competition";
	const blankBG = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

	return (
		<div className={match?.status !== optaApi.matchStatus.live ? styles.broadcastersContainer : styles.liveBroadcastersContainer}>
			{!!((match?.sitecoreData?.broadcasterLinks &&
				match?.sitecoreData?.broadcasterLinks.length) ||
				(match?.broadcastLinks &&
				match?.broadcastLinks.length)) && (
					match?.status === optaApi.matchStatus.upcoming && (
					<div className={styles.broadcastLinksHeading}>
						<span>Where </span> 
						<span>To Watch</span>
					</div>
				)) ||
				(match?.status === optaApi.matchStatus.live && (
					<div className={styles.liveBroadcastLinksHeading}>
						<span>Watch Live</span>
					</div>
				))}
			
			{/* Broadcaster links */}
			<div className={match?.status !== optaApi.matchStatus.live ? styles.broadcastLinks : styles.liveBroadcastLinks}>
				{!!(match?.sitecoreData?.broadcasterLinks &&
					match?.sitecoreData?.broadcasterLinks.length &&
					match?.status !== optaApi.matchStatus.completed) && (
						<>
							{match?.sitecoreData?.broadcasterLinks.map((broadcaster, index) =>
								broadcaster?.webUrl?.value ? 
									<a ket={index} href={broadcaster.webUrl.value} target="_blank" rel="noopener noreferrer" className={match.status !== optaApi.matchStatus.live ? styles.broadcastLink :styles.liveBroadcastLink}>
										<img style={{backgroundImage: `url(${broadcaster.broadcasterLogo.value.src})`}} src={blankBG} alt={broadcaster.broadcasterLogo.value.alt ? broadcaster.broadcasterLogo.value.alt : "broadcast logo"} />
									</a>
									: broadcaster?.broadcasterLogo?.value?.src
										? <span key={index} className={match.status !== optaApi.matchStatus.live ? styles.broadcastLink :styles.liveBroadcastLink}>
											<img style={{backgroundImage: `url(${broadcaster.broadcasterLogo.value.src})`}} src={blankBG} alt={broadcaster.broadcasterLogo.value.alt ? broadcaster.broadcasterLogo.value.alt : "broadcast logo"} />
										</span>
										: ""
							)}
						</>
				)
				}
				{/*matchStub broadcasters*/}
				{!!(match?.broadcastLinks &&
					match?.broadcastLinks.length
					&& match.matchId && match.matchId.toString().includes("MatchStub") && match.status !== optaApi.matchStatus.completed) && (
						<>
							{match?.broadcastLinks.map((broadcaster,index) =>
								broadcaster?.broadcastURLWeb ?
									<a key={index} href={broadcaster.broadcastURLWeb} target="_blank" rel="noopener noreferrer" className={styles.broadcastLink}>
										<img style={{backgroundImage: `url(${broadcaster.imageURL})`}} src={blankBG} alt={broadcaster.imageAltText ? broadcaster.imageAltText : "broadcast logo"} />
									</a>
									: broadcaster?.imageURL
										? <span key={index} className={styles.broadcastLink}>
											<img style={{backgroundImage: `url(${broadcaster.imageURL})`}} src={blankBG} alt={broadcaster.imageAltText ? broadcaster.imageAltText : "broadcast logo"} />
										</span>
										: ""
							)}
						</>
				)
				}
			</div>
		</div>
	);
};

export default compose(
	withErrorHandling()
)(BroadcasterLinks);