exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ExplicitGrid-module__grid--2SkdK {\n  padding: 5em 1em;\n  width: 100%; }\n  .ExplicitGrid-module__grid--2SkdK .ExplicitGrid-module__explicitGrid--8aoSz {\n    -ms-flex-align: normal;\n        align-items: normal;\n    grid-column-gap: 20px;\n    -webkit-column-gap: 20px;\n       -moz-column-gap: 20px;\n            column-gap: 20px;\n    display: grid;\n    grid-auto-rows: auto;\n    grid-template-rows: auto;\n    -ms-flex-pack: center;\n        justify-content: center;\n    justify-items: center;\n    grid-row-gap: 20px;\n    row-gap: 20px; }\n    .ExplicitGrid-module__grid--2SkdK .ExplicitGrid-module__explicitGrid--8aoSz > * {\n      border-right: solid 0.08rem rgba(191, 191, 191, 0.6);\n      padding-right: 1.1em; }\n      @media (max-width: 768px) {\n        .ExplicitGrid-module__grid--2SkdK .ExplicitGrid-module__explicitGrid--8aoSz > * {\n          border-bottom: solid 0.08rem rgba(191, 191, 191, 0.6);\n          border-right: none;\n          margin: 0 2em;\n          padding-bottom: 2em; } }\n      .ExplicitGrid-module__grid--2SkdK .ExplicitGrid-module__explicitGrid--8aoSz > *:last-child {\n        border: none;\n        padding-bottom: 0; }\n    @media (max-width: 768px) {\n      .ExplicitGrid-module__grid--2SkdK .ExplicitGrid-module__explicitGrid--8aoSz {\n        grid-template-columns: 1fr !important;\n        justify-items: normal; } }\n", ""]);

// exports
exports.locals = {
	"grid": "ExplicitGrid-module__grid--2SkdK",
	"explicitGrid": "ExplicitGrid-module__explicitGrid--8aoSz"
};