import React, { PureComponent } from "react";
import { compose } from "recompose";
import { Link as RouterLink, withRouter } from "react-router-dom";
import Button from "~/shared-components/Button";
import { withErrorHandling, withGigya } from "~/hoc";
import { withNamespaces } from "react-i18next";
import { optaApi } from "~/utilities/constants";
import 'moment-timezone';
import styles from "~/shared-components/RedesignMiniFeaturedMatch/RedesignMiniFeaturedMatch.module.scss";


class MatchButtons extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			showLinks: false
		};
	}

	componentDidMount() {
		const { location, event } = this.props;
		if (!location.pathname.includes(event.matchFeedUrl)) {
			this.setState({ showLinks: true });
		}
	}

	render() {
		const { showLinks } = this.state;

		return <DefaultButtons {...this.props} showLinks={showLinks} calendarTitle={this.calendarTitle} />
	}
}


const DefaultButtons = ({ event, t, showLinks }) => {
	return (
	<div className={(event?.sitecoreData?.broadcasterLinks?.length === 0 || event?.sitecoreData?.broadcasterLinks?.length === undefined) ? styles.buttonsSectionNoBroadcast : styles.buttonsSection}>
		{/* Presale tickets */}
		{new Date(event.sitecoreData?.presaleTicketsDate?.value) < Date.now() &&
			new Date(event.sitecoreData?.endDate?.value) > Date.now() &&
			<div>
				{event.sitecoreData.ticketsLink && (
					<RouterLink className={styles.button} to={event.sitecoreData.ticketsLink}>
						<>{t("match-card_buy-tickets")}</>
					</RouterLink>
				)}
			</div>
		}

		{/* Buy match tickets links */}
		{event.sitecoreData?.ticketsLink?.value?.href && event.status === optaApi.matchStatus?.upcoming &&
			<div>
				<Button size="smallCondensed" variant="secondaryReversed" link={event.sitecoreData.ticketsLink.value.href} text={t("match-card_buy-tickets")} />
			</div>
		}

		{/* Preview  */}
		{event.matchFeedUrl && event.status === optaApi.matchStatus.upcoming && showLinks &&
			<div>
				<Button size="smallCondensed" variant="secondaryReversed" link={event.matchFeedUrl} text={t("match-card_hub")} />
			</div>
		}

		{/* Live Match Feed */}
		{event.matchFeedUrl &&
			event.status === optaApi.matchStatus.live && showLinks &&
				<div>
					<Button size="smallCondensed" variant="secondaryReversed" link={event.matchFeedUrl} text={t("match-card_hub")} />
				</div>
		}

		{/* Recap link w/ matchFeedUrl */}
		{(event.matchFeedUrl || event.fullRecapLink) &&
			event.status === optaApi.matchStatus.completed &&
			showLinks &&
			<div>
				<Button size="smallCondensed" variant="secondaryReversed" link={(event.matchFeedUrl || event.fullRecapLink.href)} text={t("match-card_full-match-recap")} />
			</div>
		}
	</div>
);
}

export default compose(
	withGigya(),
	withNamespaces(),
	withRouter,
	withErrorHandling()
)(MatchButtons);