exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".MatchGrid-module__MatchGrid--2-2Xy {\n  margin: 0 auto 5rem;\n  max-width: 1440px; }\n\n.MatchGrid-module__matchesContainer--2ZCvq {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n  -ms-flex-pack: center;\n      justify-content: center; }\n\n.MatchGrid-module__viewMoreContainer--MCGKB {\n  font-size: 1rem; }\n", ""]);

// exports
exports.locals = {
	"MatchGrid": "MatchGrid-module__MatchGrid--2-2Xy",
	"matchesContainer": "MatchGrid-module__matchesContainer--2ZCvq",
	"viewMoreContainer": "MatchGrid-module__viewMoreContainer--MCGKB"
};