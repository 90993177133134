/* eslint-disable */
import React, { Component } from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { compose } from "recompose";
import { withNamespaces } from "react-i18next";
import { Arrow } from "~/shared-components/SvgIcons";
import styles from "./TableFilter.module.scss";

class TableFilter extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isYearExpanded: false,
			filterYear: this.props.years ? this.props.years[0] : null
		};
	}

	toggleYearDropdown = () => {
		this.setState(prevState => ({ isYearExpanded: !prevState.isYearExpanded, isTeamExpanded: false }));
	}

	getFilterYear = year => {
		this.setState({ filterYear: year, isYearExpanded: false });
		this.props.updatedFilteredYear(year);
	}

	clearFilter = () => {
		const { years } = this.props;

		{years && this.setState({ filterYear: years[0] });}
	}

	render() {
		const { years, t } = this.props;
		const { isYearExpanded, filterYear } = this.state;
		return (
			<div className={styles.TableFilterWrapper} >
				<div className={styles.TableFilter}>

					{years && (
						<div className={`${styles.filterDropdown} ${isYearExpanded ? styles.expanded : ""}`}>
							<span>{t("tablefilter_year")}</span>
							<div className={styles.dropdownButton} onClick={() => this.toggleYearDropdown()}>
								{filterYear}
								<Arrow />
							</div>

							<div className={`${styles.dropdownMenuCollapsed} ${isYearExpanded ? styles.showFilters : ""}`}>
								<ul className={styles.itemContainer}>
									{years.map((year, index) =>
										<li className={styles.item} key={index} onClick={() => this.getFilterYear(year)}>
											{year}
										</li>
									)}
								</ul>
							</div>
						</div>
					)}
					<div className={styles.clearButton} onClick={() => this.clearFilter()}><span>{t("tablefilter_clear")}</span></div>
				</div>
			</div>
		);
	}
};

export default compose(
	// other hocs goes here
	withNamespaces(),
	withSitecoreContext()
)(TableFilter);