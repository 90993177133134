import React from "react";
import { Link as SitecoreLink, isExperienceEditorActive } from "@sitecore-jss/sitecore-jss-react"; // eslint-disable-line no-restricted-imports
import { SetHyperlinksToRedirectWrapper } from "~/utilities";
import { sitecoreLinkTypes } from "~/utilities/constants";

/**
 * This Link handler is an extension of sitecore link.
 *
 * @param {object} link - Component props
 */
export const CustomLink = link => {
	if (!link) return <></>;

	if (!link.field?.value?.href && !isExperienceEditorActive()) {
		return <span>{link.children}</span>;
	}

	if (link.field?.value?.href) {
		const href = link.field.value.href;
		if (href.indexOf("http://tel:") > -1) {
			link.field.value.href = href.substring("http://".length);
		}
	}

	if (isExperienceEditorActive() || link.field?.value?.linktype === sitecoreLinkTypes.external)
		return <SitecoreLink {...link} />;

	return (
		<SetHyperlinksToRedirectWrapper>
			<SitecoreLink {...link}/>
		</SetHyperlinksToRedirectWrapper>
	);
};