exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".IconCard-module__iconCardsWrapper--2wqA7 {\n  height: 100%; }\n  .IconCard-module__iconCardsWrapper--2wqA7 .IconCard-module__iconCards--3zdB1 {\n    -ms-flex-align: start;\n        align-items: flex-start;\n    display: -ms-flexbox;\n    display: flex; }\n    .IconCard-module__iconCardsWrapper--2wqA7 .IconCard-module__iconCards--3zdB1 .IconCard-module__contentContainer--3g5wr {\n      -ms-flex: 1 1;\n          flex: 1 1; }\n    .IconCard-module__iconCardsWrapper--2wqA7 .IconCard-module__iconCards--3zdB1 h2 {\n      color: #212844;\n      font-family: USSF90MinDisplay, helvetica, arial, sans-serif;\n      font-size: 1.8em;\n      font-weight: 700;\n      -webkit-margin-after: 0.5em;\n              margin-block-end: 0.5em;\n      -webkit-margin-before: 0;\n              margin-block-start: 0;\n      text-transform: uppercase; }\n    .IconCard-module__iconCardsWrapper--2wqA7 .IconCard-module__iconCards--3zdB1 p {\n      color: #4d4d4d;\n      font-size: 1.2rem;\n      line-height: 1.3;\n      -webkit-margin-after: 0em;\n              margin-block-end: 0em; }\n  .IconCard-module__iconCardsWrapper--2wqA7 .IconCard-module__ticketmasterWrap--1wNgq {\n    width: 100%;\n    margin: 16px 0 0 0; }\n    .IconCard-module__iconCardsWrapper--2wqA7 .IconCard-module__ticketmasterWrap--1wNgq > div {\n      -ms-flex-pack: start;\n          justify-content: flex-start; }\n", ""]);

// exports
exports.locals = {
	"iconCardsWrapper": "IconCard-module__iconCardsWrapper--2wqA7",
	"iconCards": "IconCard-module__iconCards--3zdB1",
	"contentContainer": "IconCard-module__contentContainer--3g5wr",
	"ticketmasterWrap": "IconCard-module__ticketmasterWrap--1wNgq"
};