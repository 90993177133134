import React, { Component } from "react";
import { withErrorHandling, withGigya } from "~/hoc";
import { compose } from "recompose";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { Text, Link } from "~/patches/components";
import CancelMembershipModal from "~/shared-components/CancelMembershipModal";
import BillingModal from "~/shared-components/BillingModal";
import { CheckMark } from "~/shared-components/SvgIcons";
import { withMembershipVisibility } from "~/hoc/withMembershipVisibility";
import { withNamespaces } from "react-i18next";
import { membershipName } from "~/utilities/helpers";
import moment from "moment";
import 'moment-timezone';
import styles from "./MembershipBanner.module.scss";
import { MembershipService } from "~/services/membershipService";

class MembershipBanner extends Component {
	constructor(props) {
		super(props);

		const selectedTier = parseInt(props.fields.membershipLevel?.fields?.level?.value);

		this.state = {
			showCancelModal: false,
			showBillingModal: false,
			selectedTier,
			updateBillingInfo: false,
			isProRate: false,
			proRateCredit: 0,
			proRatePayment: selectedTier > 0 ? parseInt(props.fields.membershipLevel?.fields?.price?.value.replace(',', '').slice(1)) : 0,
		};
	}

	getJWT = async () => new Promise((res, rej) =>
		this.props.gigya.getJWT(
			gigyaRes => gigyaRes.errorCode
				? rej(gigyaRes.errorMessage)
				: res(gigyaRes.id_token)
		)).catch(err => console.log("1: ",err)); //eslint-disable-line


	getProration = async (bannersLevel) => {
		const jwt = await this.getJWT();

		if (this.state.selectedTier !== "1" && parseInt(this.props.gigya.data?.membershipTier) >= 1) {
			const proRate = await MembershipService.getProrationPreview(jwt, bannersLevel);

			const proRateCreditAmount = proRate?.content?.CreditAmount || 0;
			const proRateNewPlanAmount = proRate?.content?.PaymentAmount || 0;

			if (proRateCreditAmount > 0) {
				this.setState({
					proRateCredit: (proRateCreditAmount / 100).toFixed(2),
					proRatePayment: (Math.ceil(proRateNewPlanAmount - proRateCreditAmount) / 100).toFixed(2),
					isProRate: this.props?.gigya?.data?.membershipTier > 0,
				});
			}
		}
	}

	static getDerivedStateFromProps = nextProps => {
		const { gigya: { isLoggedIn, data }, fields } = nextProps;
		const bannersLevel = parseInt(fields.membershipLevel?.fields?.level?.value);
		if (isLoggedIn && data.membershipTier > 0 && bannersLevel > data.membershipTier) {
			return {
				isProRate: true,
			};
		}
		return null;
	}

	componentDidMount = () => {
		const fields = this.props;
		const bannersLevel = parseInt(fields?.fields.membershipLevel?.fields?.level?.value);
		if (this.state.isProRate && bannersLevel !== 4) {
			this.getProration(bannersLevel);
		}
	}

	componentDidUpdate = prevProps => {
		const { gigya: prevGigya } = prevProps;
		const { gigya: { isLoggedIn, data }, fields } = this.props;
		const bannersLevel = parseInt(fields.membershipLevel?.fields?.level?.value);

		if (prevGigya.data !== data && isLoggedIn && bannersLevel !== 4 && bannersLevel > data.membershipTier) {
			this.getProration(bannersLevel);
		}
	}

	render() {
		const {
			fields,
			params,
			sitecoreContext: { pageEditing, route },
			gigya: { isLoggedIn, data },
			t
		} = this.props;

		const {
			showCancelModal,
			showBillingModal,
			selectedTier,
			updateBillingInfo,
			isProRate,
			proRatePayment
		} = this.state;

		const bg = fields.backgroundImage?.value?.src;
		let bannersLevel = parseInt(fields.membershipLevel?.fields?.level?.value);
		bannersLevel = isNaN(bannersLevel) ? 0 : bannersLevel;
		
		const regex = /{price}.*/gi;
		const price = fields.membershipLevel?.fields?.price.value;
		const addSpacing = params?.spacing === "1";

		const buttonText = isLoggedIn ? t("membershipbanner__upgrade_label") : t("membershipbanner__signup_label");
		const currentMembershipLevel = isLoggedIn && data?.membershipTier;
		const currentPage = route.fields.pageTitle.value.toUpperCase();
		const levelName = membershipName(bannersLevel).toUpperCase();
		const endDate = data?.membershipSubscription?.end
			? moment(data.membershipSubscription.end)
				.tz(moment.tz.guess())
				.format("MMM DD YYYY")
			: "";

		return (
			<>
				{fields && (!isLoggedIn || currentMembershipLevel === 0 || currentMembershipLevel !== 0 && bannersLevel !== 0) && (
					<>
						<div className={`${styles.mainContainer} ${addSpacing ? styles.spacing : ""}`} style={{ backgroundImage: `url(${bg}?mw=800)` }}>
							<div className={styles.contentContainer}>
								<div className={`${styles.headingContainer}`}>

									{fields.membershipLevel?.fields?.level?.value &&
										<div className={`${styles.header} ${(bannersLevel === 1 || bannersLevel === 2) ? styles.blueText : styles.whiteText}`}>
											<Text field={fields.name} />
										</div>
									}

									<div className={`${styles.ctaContainer} ${(bannersLevel === 1 || bannersLevel === 2) ? styles.redButton : styles.whiteButton}`}>
										{currentMembershipLevel === bannersLevel && !pageEditing
											? <>
												{currentPage !== levelName && currentMembershipLevel !== 0 && (!data.membershipCanceled ?
												<>
													<div className={styles.cta}>
														<button onClick={() => this.setState({ showCancelModal: true })}>{t("membershipbanner__cancel_label")}</button>
													</div>
												</>
													:
												<>
													<div className={styles.cta}>
														<button onClick={() => this.setState({ showBillingModal: true, selectedTier: currentMembershipLevel, updateBillingInfo: true })}>{t("membershipbanner__renew_label")}</button>
													</div>
												</>)}
												<div className={styles.currentLevel}>
													{t("membershipbanner__currentlevel_label")} <CheckMark />
												</div>
												{bannersLevel !== 0 &&
													<div className={`${styles.membershipStatus} ${(bannersLevel === 1 || bannersLevel === 2) ? styles.blueText : styles.whiteText}`}>
														{!data?.membershipCanceled
															? <>{t("membershipbanner__auto-renew")} {endDate}. <span className={styles.updateBilling} onClick={() => this.setState({ showBillingModal: true, selectedTier: currentMembershipLevel, updateBillingInfo: true })}>{t("membershipbanner__update-billing")}</span></>
															: <>{t("membershipbanner__become-standard")} {endDate}</>}
													</div>}
											</>
											: <>
												{fields?.learnMoreCta?.value?.href && currentPage !== levelName &&
													<div className={styles.cta}>
														<Link field={fields.learnMoreCta} />
													</div>
												}
												{fields.signupCta && (!currentMembershipLevel || currentMembershipLevel < bannersLevel) &&
													<div className={styles.cta}>
														{bannersLevel !== 4
															? <button onClick={() => this.setState({ showBillingModal: true, selectedTier: bannersLevel })}>
																{!pageEditing &&
																	<span>
																		{ isProRate ?
																			<>{fields.signupCta.value?.text?.replace("{buttonText}", buttonText).replace(regex, '$'.concat(proRatePayment))}</>
																			:
																			<>{fields.signupCta.value?.text?.replace("{buttonText}", buttonText).replace("{price}", price)}</>
																		}
																	</span>
																}
															</button>
															: <Link field={fields.signupCta} />}
													</div>
												}
												{fields.signupCta && (!currentMembershipLevel || currentMembershipLevel < bannersLevel) &&
													<div className={`${styles.membershipStatus} ${(bannersLevel === 1 || bannersLevel === 2) ? styles.blueText : styles.whiteText}`}>
														{!data?.membershipCanceled ?
															<span>
																{isProRate ?
																	<>{t("membershipbanner__normal_upgrade")} {price}.</>
																	:
																	<>&nbsp;</>
																}
															</span> : <>{t("membershipbanner__become-standard")} {endDate}</>}
													</div>
												}
											</>
										}
									</div>
								</div>

								<div className={`${styles.description} ${(bannersLevel === 1 || bannersLevel === 2) ? styles.blueText : styles.whiteText}`}>
									<div className={styles.subheader}>
										<Text field={fields.title} />
									</div>
									<hr />
									<div className={styles.descriptionText}>
										{fields?.description?.value.split("\r\n").map((line, index) => (
											<p key={index}>
												<Text field={{ value: line }}  />
											</p>
										))}
									</div>
									<div className={styles.disclaimer}>
										<Text field={fields.disclaimer} />
									</div>
								</div>

								<div className={styles.ctaContainerMobile}>
									{currentMembershipLevel === bannersLevel && !pageEditing
										? <>
											<div className={styles.currentLevel}>
												{t("membershipbanner__currentlevel_label")} <CheckMark />
												{bannersLevel !== 0 &&
													<>
													<br/>
													<br/>
													{!data?.membershipCanceled
														? <>{t("membershipbanner__auto-renew")} {endDate}. <span className={styles.updateBilling} onClick={() => this.setState({ showBillingModal: true, selectedTier: currentMembershipLevel, updateBillingInfo: true })}>{t("membershipbanner__update-billing")}</span>	</>
														: <>{t("membershipbanner__become-standard")} {endDate}</>}
													</>}
											</div>
											{currentPage !== levelName && currentMembershipLevel !== 0 && (!data.membershipCanceled ?
											<>
												<div className={styles.cta}>
													<button onClick={() => this.setState({ showCancelModal: true })}>{t("membershipbanner__cancel_label")}</button>
												</div>
												</>
												:
												<>
													<div className={styles.cta}>
														<button onClick={() => this.setState({ showBillingModal: true, selectedTier: currentMembershipLevel, updateBillingInfo: true })}>{t("membershipbanner__renew_label")}</button>
													</div>
												</>)}
										</>
										: <>
											{fields.signupCta && (!isLoggedIn || currentMembershipLevel < bannersLevel) &&
												<div className={styles.cta}>
													{bannersLevel !== 4
														? <button onClick={() => this.setState({ showBillingModal: true, selectedTier: bannersLevel })}>
															{!pageEditing &&
																<>{fields.signupCta.value?.text?.replace("{buttonText}", buttonText).replace("{price}", price)}</>
															}
														</button>
														: <Link field={fields.signupCta} />}
												</div>
											}
											{fields.learnMoreCta && currentPage !== levelName &&
												<div className={styles.cta}>
													<Link field={fields.learnMoreCta} />
												</div>
											}
										</>
									}
								</div>
							</div>
						</div>

						<CancelMembershipModal active={showCancelModal} onClose={() => this.setState({ showCancelModal: false, updateBillingInfo: false })} />
						<BillingModal
							showModal={showBillingModal}
							onClose={() => this.setState({ showBillingModal: false, updateBillingInfo: false })}
							selectedTier={selectedTier}
							isLoggedIn={isLoggedIn}
							data={data}
							updateBillingInfo={updateBillingInfo} />
					</>
				)}
			</>
		);
	}
}

export default compose(
	withMembershipVisibility(),
	withNamespaces(),
	withSitecoreContext(),
	withErrorHandling(),
	withGigya()
)(MembershipBanner);