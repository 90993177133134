import React, { Component, useMemo } from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import { filterUnique, isServer } from "~/utilities";
import moment from "moment";
import { MatchService } from "~/services/matchService";
import SectionLabel from "~/shared-components/SectionLabel";
import MatchesGrid from "~/shared-components/MatchesGrid";
import MatchList from "~/shared-components/MatchList";
import AllMatchesTabs from "./AllMatchesTabs";
import styles from "./AllMatchesTabsWithFilter.module.scss";
import { isMobile } from "react-device-detect";
import PoweredByTicketmaster from "~/shared-components/PoweredByTicketmaster";


const isLargeScreen = () => !isMobile && !isServer && window.innerWidth > 780;

const MatchCollection = ({ isLargeScreen, isListView, matches, type }) =>
	useMemo(() => isLargeScreen && isListView, [isLargeScreen, isListView])
		? <MatchList matches={matches} type={type} />
		: <MatchesGrid matches={matches} type={type} />;

class AllMatchesTabsWithFilter extends Component {
	constructor(props) {
		super(props);

		const { fields: { defaultToListView }, rendering: { years, teams } } = this.props;

		//create array of teams with "All" at index 0
		const allTeamOption = [{ name: "All Teams" }];
		const allTeams = allTeamOption.concat(teams);
		years.push({value: 2022},{value: 2023});

		const intYears = this.createArrayOfIntegerYears(years);		//Not sure where the years are being made/hard coded.
		this.state = {
			filteredTeam: { name: "All Teams" },
			filteredYear: intYears[0],
			filteredUpcomingMatches: [],
			filteredResultsMatches: [],
			teams: allTeams,
			years: intYears,
			loadingResultsMatches: false,
			loadingUpcomingMatches: false,
			isListView: defaultToListView?.value
		};
	}

	componentDidMount = () => {
		//get initial upcoming matches
		this.showAllUpcomingMatches();

		//get initial results matches
		this.showAllResultsMatchesByYear();

		// Update screen size now and whenever window resized
		if (!isServer) {
			this.respondToWindowResize();
			window.addEventListener("resize", this.respondToWindowResize);
		}
	}

	componentWillUnmount = () => {
		if (!isServer)
			window.removeEventListener("resize", this.respondToWindowResize);
	}

	//create an array of integer years to set as initial state for filteredYear and years
	createArrayOfIntegerYears = years => {
		const intYears = years.map(year => {
			if (year?.value) {
				return year.value;
			}
		});

		intYears.sort().reverse();

		intYears.unshift("All Years");
		return intYears;
	}

	respondToWindowResize = () => {
		this.setState({ isLargeScreen: isLargeScreen() });
	}

	//UPCOMING ONLY: show all upcoming matches when "ALL TEAMS" is selected in team filter
	showAllUpcomingMatches = () => {
		const { rendering: { competitions, teams } } = this.props;
		this.setState({
			loadingUpcomingMatches: true
		});

		const allUpcomingMatches = [];

		const promises = [];
		if (competitions)
			promises.push(
				Promise.all(competitions
					.filter(competition => competition?.tournament?.tournamentOptaId)
					.map(competition => MatchService.getTournamentUpcomingMatches(competition?.tournament?.tournamentOptaId)))
					.then(response => {
						response.map(tournamentMatches => tournamentMatches.map(match => allUpcomingMatches.push(match)));
					}));
		if (teams)
			promises.push(
				Promise.all(teams
					.filter(team => team.optaId)
					.map(team => MatchService.getTeamUpcomingMatches(team.optaId)))
					.then(response => {
						response.map(teamMatches => teamMatches.map(match => allUpcomingMatches.push(match)));
					})
			);
		Promise.all(promises).then(() => {
			const uniqueUpcomingMatches = filterUnique(allUpcomingMatches, "matchId");
			this.handleUpcomingMatches(uniqueUpcomingMatches);
			this.setState({
				loadingUpcomingMatches: false
			});
		});
	}

	//RESULTS ONLY: show all results matches by year when "All" is selected in team filter
	showAllResultsMatchesByYear = () => {
		const { rendering: { competitions, teams } } = this.props;
		const { filteredYear } = this.state;

		this.setState({
			loadingResultsMatches: true
		});

		const allResultsMatches = [];

		const promises = [];
		if (competitions)
			promises.push(
				Promise.all(competitions
					.filter(competition => competition?.tournament?.tournamentOptaId)
					.map(competition => MatchService.getTournamentResultsMatches(competition?.tournament?.tournamentOptaId, filteredYear)))
					.then(response => {
						response.map(tournamentMatches => tournamentMatches.matches.map(match => allResultsMatches.push(match)));
					}));
		if (teams)
			promises.push(
				Promise.all(teams
					.filter(team => team.optaId)
					.map(team => MatchService.getTeamResultsMatches(team.optaId, filteredYear)))
					.then(response => {
						response.filter(teamMatches => teamMatches?.matches).map(teamMatches => teamMatches.matches.map(match => allResultsMatches.push(match)));
					}));

		Promise.all(promises).then(() => {
			const uniqueResultsMatches = filterUnique(allResultsMatches, "matchId");
			this.handleResultsMatches(uniqueResultsMatches);
			this.setState({
				loadingResultsMatches: false
			});
		});
	}

	//UPCOMING ONLY: sort upcoming matches and set as state
	handleUpcomingMatches = matches => {
		const sortedMatches = this.sortMatches(matches, "ascending");

		this.setState({
			filteredUpcomingMatches: sortedMatches
		});
	}

	//RESULTS ONLY: sort results matches and set as state
	handleResultsMatches = matches => {
		const sortedMatches = this.sortMatches(matches, "descending");

		this.setState({
			filteredResultsMatches: sortedMatches
		});
	}

	//updatedFilters() is passed as a prop down to grandchild MatchFilter to receive updated filter selections and set as state. The team filter will return a null value for year and the year filter will return a null value for team.
	updatedFilters = filters => {
		const { team, year } = filters;

		if (team === null || team === undefined) {
			this.setState({
				filteredYear: year ?? "All Years"
			});
		} else {
			this.setState({
				filteredTeam: team
			});
		}
	}

	componentDidUpdate = (_prevProps, prevState) => {
		const { filteredTeam: prevFilteredTeam, filteredYear: prevFilteredYear } = prevState;
		const { filteredTeam, filteredYear } = this.state;

		if (prevFilteredTeam !== filteredTeam) {
			this.updatedFilteredUpcomingMatches(filteredTeam);
		}

		if (prevFilteredTeam !== filteredTeam || prevFilteredYear !== filteredYear) {
			this.updatedFilteredResultsMatches(filteredTeam, filteredYear);
		}
	}

	//UPCOMING ONLY: get upcoming matches based on selected team filter
	updatedFilteredUpcomingMatches = team => {
		if (team) {
			const { name, optaId } = team;

			if (optaId) {
				this.setState({
					loadingUpcomingMatches: true
				});

				MatchService.getTeamUpcomingMatches(optaId)
					.then(response => {
						this.handleUpcomingMatches(response);
						this.setState({
							loadingUpcomingMatches: false
						});
					});
			} else if (name === "All Teams") {
				this.showAllUpcomingMatches();
			}
		}
		if (!team) {
			this.showAllUpcomingMatches();
		}

	}

	//RESULTS ONLY: get results matches based on selected filters
	updatedFilteredResultsMatches = (team, year) => {
		if (team) {
			const { name, optaId } = team;

			if (optaId && year) {
				this.setState({
					loadingResultsMatches: true
				});

				MatchService.getTeamResultsMatches(optaId, year)
					.then(response => {
						this.handleResultsMatches(response.matches);
						this.setState({
							loadingResultsMatches: false
						});
					});

			} else if (name === "All Teams" && year) {
				this.showAllResultsMatchesByYear(year);
			}
		}
		if (!team) {
			year && this.showAllResultsMatchesByYear(year);
		}
	}

	//sort matches in ascending order by date
	sortMatches = (matches, direction) => matches.sort((a, b) => {
		const aDate = moment(a.date);
		const bDate = moment(b.date);

		if (direction === "ascending") {
			return aDate - bDate;
		}

		if (direction === "descending") {
			return bDate - aDate;
		}
	})

	showList = boolean => {
		const { isListView } = this.state;

		if (boolean && !isListView) {
			this.setState({
				isListView: true
			});
		}

		if (!boolean && isListView) {
			this.setState({
				isListView: false
			});
		}
	}

	render() {
		const { fields: { showResults, showUpcomming } } = this.props;
		const {
			filteredTeam,
			filteredYear,
			teams,
			years,
			filteredUpcomingMatches,
			filteredResultsMatches,
			loadingResultsMatches,
			loadingUpcomingMatches,
			isLargeScreen,
			isListView
		} = this.state;
		return (
			<>
				{(showUpcomming?.value || showResults?.value) && (
					<section className={styles.AllMatchesTabsWithFilter}>
						<div className={styles.sectionLabelContainer}>
							<SectionLabel sectionLabel={{ value: "matches" }} />
							<div className={styles.ticketmasterWrap}>
								<PoweredByTicketmaster showPoweredByText showFullLogo />
							</div>
						</div>

						<AllMatchesTabs
							teams={teams}
							years={years}
							defaultTeam={filteredTeam}
							defaultYear={filteredYear}
							updatedFilters={this.updatedFilters}
							isListView={isListView}
							showStyleSelect={isLargeScreen}
							showList={this.showList}
						>
							{showUpcomming?.value &&
								<div label="upcoming schedule">
									{!loadingUpcomingMatches
										? filteredUpcomingMatches.length > 0
											? <MatchCollection isLargeScreen={isLargeScreen}
												isListView={isListView}
												matches={filteredUpcomingMatches}
												type="upcoming" />
											: <div className={styles.text}>no matches found</div>
										: <div className={styles.text}>loading...</div>
									}
								</div>
							}
							{showResults?.value &&
								<div label="results">
									{!loadingResultsMatches
										? filteredResultsMatches.length > 0
											? <MatchCollection
												isLargeScreen={isLargeScreen}
												isListView={isListView}
												matches={filteredResultsMatches}
												type="results" />
											: <div className={styles.text}>no matches found</div>
										: <div className={styles.text}>loading...</div>
									}
								</div>
							}
						</AllMatchesTabs>
					</section>
				)}
			</>
		);
	}
}

export default compose(
	// other HOCs goes here
	withErrorHandling()
)(AllMatchesTabsWithFilter);