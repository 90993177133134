import React from "react";
import ReactErrorHandler from "~/ReactErrorHandler";
import config from "~/temp/config";

export function withErrorHandling(options = { ignoreStateless: false, ignoreStateful: false }) {
	return function withErrorHandlingHoc(WrappedComponent) {
		return function WithErrorHandling(props) {
			// check if it a stateless component

			if (!WrappedComponent.prototype.render && !options.ignoreStateless) {
				if (config.mode === "integrated") { // only handle errors this way in integrated mode
					try {
						return WrappedComponent(props);
					} catch (e) {
						return <ReactErrorHandler error={e} component={WrappedComponent} />;
					}
				} else {
					return WrappedComponent(props);
				}
			} else if (!WrappedComponent.prototype.__originalRenderMethod__ && !options.ignoreStateful) {
				if (config.mode === "integrated") {
					WrappedComponent.prototype.__originalRenderMethod__ = WrappedComponent.prototype.render;
					WrappedComponent.prototype.render = function render() {
						try {
							return this.__originalRenderMethod__();
						}
						catch (e) {
							return <ReactErrorHandler error={e} component={WrappedComponent} />;
						}
					};
				}
				return <WrappedComponent {...props} />;
			}

			return <WrappedComponent {...props} />;
		};
	};
}