import React, { Fragment } from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { Arrow } from "~/shared-components/SvgIcons";
import { compose } from "recompose";
//import { withErrorHandling } from "~/hoc";
import styles from "./ArrowLink.module.scss";
import { Link } from "~/patches/components";

const ArrowLink = ({ link, linkText, sitecoreContext: { pageEditing } }) => (
	<Fragment>
		{(link || pageEditing) && (
			<div className={styles.link}>
				{linkText && !pageEditing ? (
					<Link field={link}>
						{linkText}
					</Link>
				) : (
					<Link field={link} />
				)}
				<div className={styles.arrow}>
					<Arrow />
				</div>
			</div>
		)}
	</Fragment>
);

export default compose(
	//withErrorHandling()
	withSitecoreContext()
)(ArrowLink);