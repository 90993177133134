import React, { Fragment } from "react";
import styles from "./MegaMenu.module.scss";
import { Link, Text } from "~/patches/components";
import { NavLink } from "react-router-dom";
import SubMenu from "../SubMenu";
import { SetHyperlinksToRedirectWrapper, isServer } from "~/utilities";
import MembershipCallout from "../MembershipCallout";
import { NavContext } from "../NavContext";

/**
 * The height of the mega menu. This will be calculated every time the mega menu is opened.
 * The variable is placed here so it doesn't have to be calculated when going from one mega menu to another.
 *
 * @type {number}
 */
let megaMenuHeight = 0;

const MegaMenu = ({ id, mega, membershipCallout, subMenu, url, primaryNavIndexKey, isHovering }) => (
	<NavContext.Consumer>
		{context => {
			const isCurrentMegaMenu = isHovering ? styles.columnsFadeIn : '';
			const setSlideAnimation = isCurrentMegaMenu ?
				styles.clipContainerActive
				: context.state.wasHovering && context.state.previouslyActiveKey === primaryNavIndexKey ?
					styles.clipContainerWasActive
					: '';
			// Find the height of the highest mega menu so all the mega menus can have the same height
			if (!context.state.previouslyActiveKey && !isServer) {
				megaMenuHeight = 0;
				const megaMenus = document.querySelectorAll(`.${styles.megaMenu}`); // eslint-disable-line no-restricted-globals

				if (megaMenus) {
					for (let i = 0; i < megaMenus.length ; i += 1) {
						const menu = megaMenus[i];
						megaMenuHeight = Math.max(megaMenuHeight, menu.offsetHeight);
					}
				}
			}

			// Reset mega menu height if it is not in use
			else if (!context.state.activeKey) {
				megaMenuHeight = 0;
			}

			return (
				<section className={`${styles.megaMenu} ${isCurrentMegaMenu} ${(url === "/teams" || url === "/teams/") ? styles.condensedLayout : ""}`}>
					<div
						style={{minHeight: `${megaMenuHeight}px`}}
						className={`${styles.clipContainer} ${context.state.isHovering && context.state.previouslyActiveKey ? styles.disableTransitions : ''} ${setSlideAnimation}`}>
						<SubMenu {...{id}} subMenu={subMenu} url={url}/>
						{mega && mega.sections && mega.sections.length > 0 && (
							<div className={styles.inner}>
								{mega.sections.map((section, index) => (
									<div className={styles.section} key={`section-key-${index}`}>
										<Text tag="h5" field={section.title}/>
										<MegaMenuColumns columns={section.columns} />
									</div>))}
							</div>
						)}
						<MegaMenuFooter bottomLink={(mega || {}).bottomLink}/>
					</div>
					<MembershipCallout membershipCallout={membershipCallout}/>
				</section>
			);
		}}
	</NavContext.Consumer>
);

const MegaMenuColumns = ({ columns }) => (
	columns !== null && columns.length > 0 &&
	<div className={styles.columns}>
		{columns.map((column, index) => (
			<MegaMenuColumn key={`column-key-${index}`} column={column} />
		))}
	</div>
);


const MegaMenuColumn = ({ column }) => (
	<NavContext.Consumer>
		{context => (
			<section className={styles.column}>
				{column && column.links !== null &&
				<ul itemScope itemType="http://schema.org/SiteNavigationElement">
					<li><Text tag="h6" field={column.title}/></li>
					{column.links.map((link, index) => (
						<li key={`mega-menu-link-${index}`} itemProp="name">
							<NavLink to={link.url} onClick={() => context.linkClicked()} itemProp="url">
								<Text field={link.text} />
							</NavLink>
						</li>
					))}
				</ul>
				}
			</section>
		)}
	</NavContext.Consumer>
);

const MegaMenuFooter = ({bottomLink}) => (
	<NavContext.Consumer>
		{context => (
			<Fragment>
				{bottomLink && bottomLink.value.href !== "" && (
					<div className={styles.footer}>
						<SetHyperlinksToRedirectWrapper>
							<Link field={bottomLink} onClick={() => context.linkClicked()} />
							<div className={styles.arrow}>
								<svg width="8" height="22" viewBox="0 0 8 22" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M1 1L7 11L1 21" stroke="white"/>
								</svg>
							</div>
						</SetHyperlinksToRedirectWrapper>
					</div>
				)}
			</Fragment>
		)}
	</NavContext.Consumer>
);

export default MegaMenu;
