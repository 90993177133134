exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ImageBlock-module__ImageBlock--1Qcsg {\n  background-color: linear-gradient(180deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0));\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: justify;\n      justify-content: space-between; }\n\n.ImageBlock-module__imageGrid--1ZLmu {\n  margin: 0 auto;\n  padding: 2rem; }\n\n.ImageBlock-module__imageMobile--1NO80 img {\n  display: block;\n  margin: 2rem auto;\n  margin-top: 9rem; }\n\n.ImageBlock-module__imageText--jLnt9 {\n  color: #808080;\n  font-family: \"USSF90MinText\";\n  font-size: 1.25rem; }\n\n.ImageBlock-module__image--vuFHC img {\n  display: none; }\n\n@media (min-width: 768px) {\n  .ImageBlock-module__imageMobile--1NO80 img {\n    display: none; }\n  .ImageBlock-module__image--vuFHC img {\n    display: block;\n    margin: 1.66rem auto; } }\n", ""]);

// exports
exports.locals = {
	"ImageBlock": "ImageBlock-module__ImageBlock--1Qcsg",
	"imageGrid": "ImageBlock-module__imageGrid--1ZLmu",
	"imageMobile": "ImageBlock-module__imageMobile--1NO80",
	"imageText": "ImageBlock-module__imageText--jLnt9",
	"image": "ImageBlock-module__image--vuFHC"
};