import React, { Fragment } from "react";
import styles from "./PlayerCarousel.module.scss";
import { Carousel } from "~/shared-components";
import { withSectionHeader, withErrorHandling } from "~/hoc";
import { Placeholder, withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { compose } from "recompose";
import PlayerThumbnail from "~/components/PlayerThumbnail"; // eslint-disable-line

const options = {
	modifierClassName: 'imagePaddingHeight',
	imageSrcSet: [{mw: 300}, {mw: 768}],
	imageSizes: '(min-width: 768px) 300px, 768px',
	showNumberOfStats: 2
};

const PlayerCarousel = ({ fields, rendering, sitecoreContext: { pageEditing }}) => (
	<section className={styles.container}>
		{/* Render this if a team has been choosen */}
		{fields?.teamLink ? (
			<Fragment>
				{/* If page editing don't render carousel */}
				{pageEditing ? (
					<div className="grid gridContainer">
						<div className="row">
							{fields.teamLink.fields.members.map((player, index) => (
								<div className="col col--sm-3" key={index}>
									<div className={styles.item} >
										<PlayerThumbnail fields={{ itemLink: player }} options={options} />
									</div>
								</div>
							))}
						</div>
					</div>
				) : (
					<div className="grid gridContainer">
						<div className="row">
							<div className="col">
								<Carousel>
									{fields.teamLink.fields.members.map((player, index) => (
										<div className={styles.item} key={index}>
											<PlayerThumbnail fields={{ itemLink: player }} options={options} />
										</div>
									))}
								</Carousel>
							</div>
						</div>
					</div>
				)}
			</Fragment>
		) : (
			<Fragment>
				{/* If single players are choosen and not a team render placeholder */}
				{/* If page editing don't render carousel  */}
				{pageEditing ? (
					<div className="grid gridContainer">
						<div className="row">
							<Placeholder
								name="jss-player-carousel-content"
								rendering={rendering}
								renderEach={(component, index) => (
									<div className="col col--sm-3" key={index}>
										<CarouselItem component={component} />
									</div>
								)}/>
						</div>
					</div>
				) : (
					<Placeholder
						name="jss-player-carousel-content"
						rendering={rendering}
						render={components => {
							if (components.length === 0) return <div></div>;

							return (
								<div className="grid gridContainer">
									<div className="row">
										<div className="col">
											<Carousel>
												{components.map((component, index) => (
													<Fragment key={index}>
														<CarouselItem component={component} />
													</Fragment>
												))}
											</Carousel>
										</div>
									</div>
								</div>
							);
						}}/>
				)}
			</Fragment>
		)}
	</section>
);

/**
 * Single carousel item.
 *
 * @param {object} component - The component object.
 */
const CarouselItem = ({component}) => {
	const newProps = {options, ...component.props};
	const newComponent = React.cloneElement(component, newProps);

	return (
		<div className={styles.item}>{newComponent}</div>
	);
};

export default compose(
	withSitecoreContext(),
	withSectionHeader(),
	withErrorHandling()
)(PlayerCarousel);