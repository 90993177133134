import React from "react";
import { withSectionHeader } from "~/hoc";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import TabularData from "~/shared-components/TabularData";
import { compose } from "recompose";
import HelperCommentEdit from "~/experience-editor/HelperCommentEdit";

const Competitions = ({ fields, sitecoreContext: { pageEditing } }) => {

	if (!fields?.Competitions && pageEditing) return <HelperCommentEdit endingEditComment="and select the competitions that should be shown" />;

	const content = fields?.competitions?.map(({ url, fields }) => (
		{
			url,
			content: [fields?.competitionName?.value, fields?.standing ? fields?.standing?.fields?.standing?.value : "--"]
		}
	));
	return (
		<>
			{fields &&
				<TabularData
					headings={[fields?.nameLabel?.value, fields?.standingLabel?.value]}
					rows={content}
				/>
			}
		</>
	);
};

export default compose(
	withSitecoreContext(),
	withSitecoreContext(),
	withSectionHeader()
)(Competitions);
