import React from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";

function calculateGridColumns(columns) {
	return Math.ceil(12 / (columns || 2));
}

const SharedGrid = ({ name, mobileColumns, tabletColumns, desktopColumns, rendering }) => (
	<section className="grid gridContainer">
		<div className="row">
			<Placeholder
				name={name}
				rendering={rendering}
				renderEach={(component, index) => (
					<div className={`col col--xs-${calculateGridColumns(mobileColumns)} col--md-${calculateGridColumns(tabletColumns)} col--lg-${calculateGridColumns(desktopColumns)}`} key={index}>
						{component}
					</div>
				)}
			/>
		</div>
	</section>
);

export default compose(
	// other hocs goes here
	withErrorHandling()
)(SharedGrid);