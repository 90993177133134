import React, { PureComponent } from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { Image, Text } from "~/patches/components";
import { Link } from "react-router-dom";
import { ArrowDown, ArrowRight } from "~/shared-components/SvgIcons";
import MegaMenu from "../RedesignMegaMenu";
import RedesignCallToActionMenu from "../RedesignCallToActionMenu";
import { NavContext } from "../NavContext";
import styles from "./RedesignPrimaryNav.module.scss";
import JsonLd from "~/components/JsonLd";
import RedesignSearchForm from "~/shared-components/RedesignSearchForm";

const RedesignPrimaryNav = props => {
	const { nav, logo, becomeAMemberLink, memberBenefitsLink, ticketsLink, membershipCallout, sitecoreContext } = props;
	const isEditing = sitecoreContext && sitecoreContext.pageEditing;
	const dataObject = {
		"@context": "http://schema.org",
		"@type": "Organization",
		"name": "U.S. Soccer Federation",
		"url": "https://www.ussoccer.com",
		"contactPoint": [
			{
				"@type": "ContactPoint",
				"telephone": "+1-(312) 808-1300",
				"contactType": "Customer Service"
			}
		],
	};

	return (
		<nav className={styles.redesignPrimaryNav}>
			<div className={`redesignGridContainer ${styles.primaryNavGrid}`}>
				<Link className={styles.logo} to="/">
					<Image field={logo} />
				</Link>
				
				<RedesignNavLinks {...{nav, membershipCallout, sitecoreContext}}/>

				<div className={styles.searchContainer}>
					<RedesignSearchForm />
				</div>
				
				<RedesignCallToActionMenu
					member={becomeAMemberLink}
					benefits={memberBenefitsLink}
					tickets={ticketsLink}
					isEditing={isEditing}
					isPrimary={true} />
			</div>
			<JsonLd data={dataObject} />
		</nav>

	);
};

class RedesignNavLinks extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			menuStates: {
				[props.index]: false
			}
		};
		
		this.handleMenuClick = this.handleMenuClick.bind(this);
	}
	
	handleMenuClick(index) {
		this.setState(prevState => ({
			menuStates: {
				...prevState.menuStates,
				[index]: !prevState.menuStates[index],
			},
		}), () => {
		});
	}

	handleClose() {
		this.setState({
			menuStates: Object.keys(this.state.menuStates).reduce((acc, key) => {
				acc[key] = false;
				return acc;
			}, {})
		});
	}
	

	render() {
		const { nav, membershipCallout } = this.props;
		return (
			<NavContext.Consumer>
				{context => (
					<ul className={`${context.state.isHovering && context.state.previouslyActiveKey ? styles.disableTransitions : ''} ${!context.state.menuItemClicked ? styles.canShowMegaMenu : ""}`} itemScope itemType="http://schema.org/SiteNavigationElement">
						{nav && nav.map((item, index) => {
							const primaryNavIndexKey = `nav-links-${index}`;
							return (
								<li
									className={`${styles.navItem} ${primaryNavIndexKey === context.state.activeKey ? styles.isHovering : ""}`}
									// onMouseEnter={context.isMouseEntering.bind(this, {primary: primaryNavIndexKey, sub: 'teal'})}
									onMouseLeave = {() => this.handleClose()}
									key={`nav-links-${index}`}>
									<div
										activeClassName={styles.active}
										className={styles.primaryNavItem}
										onClick={() => this.handleMenuClick(index)}
									>
										<>
											<div>
												<Text tag="span" field={item.menuTitle} itemProp="name"/>
											</div>
											
											{ item.megaMenu && (<ArrowDown />) }
										</>
									</div>
									<div className={this.state.menuStates[index] ? styles.megaMenuWrapper : styles.hiddenMegaMenu} 
										onMouseLeave = {() => this.handleMenuClick(index)}
									>
										<MegaMenu
											title={nav[2].menuTitle.value}
											index = {index}
											id={item.id}
											isHovering={(primaryNavIndexKey === context.state.activeKey) && !context.state.menuItemClicked}
											mega={item.megaMenu}
											membershipCallout={membershipCallout}
											subMenu={item.subNavItems}
											url={item.url}
										/>
									</div>
								</li>
							);
						})}
					</ul>
				)}
			</NavContext.Consumer>
		);
	}
}


export default withSitecoreContext()(RedesignPrimaryNav);