exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SharedTab-module__btn--3_LLc {\n  color: #BFBFBF;\n  cursor: pointer;\n  font-family: \"USSF90MinDisplay\", helvetica, arial, sans-serif;\n  font-size: 1.6rem;\n  font-weight: 700;\n  outline: none;\n  padding: 0 0 2.1rem 0;\n  position: relative;\n  text-transform: uppercase;\n  -webkit-transition: color 175ms cubic-bezier(0.4, 0, 0.2, 1);\n  -o-transition: color 175ms cubic-bezier(0.4, 0, 0.2, 1);\n  transition: color 175ms cubic-bezier(0.4, 0, 0.2, 1); }\n  .SharedTab-module__btn--3_LLc:hover, .SharedTab-module__btn--3_LLc:focus {\n    color: #C2002F; }\n  .SharedTab-module__btn--3_LLc:before {\n    background-color: #FFFFFF;\n    bottom: 0;\n    content: \"\";\n    height: .3rem;\n    left: 0;\n    position: absolute;\n    right: 100%;\n    -webkit-transition: 200ms cubic-bezier(0.4, 0, 0.2, 1);\n    -o-transition: 200ms cubic-bezier(0.4, 0, 0.2, 1);\n    transition: 200ms cubic-bezier(0.4, 0, 0.2, 1);\n    -webkit-transition-property: left, right, background-color;\n    -o-transition-property: left, right, background-color;\n    transition-property: left, right, background-color;\n    will-change: left, right, background-color; }\n  .SharedTab-module__btn--3_LLc.SharedTab-module__active--S2itT {\n    color: #C2002F; }\n    .SharedTab-module__btn--3_LLc.SharedTab-module__active--S2itT:before {\n      background-color: #C2002F;\n      right: 0; }\n  @media (min-width: 480px) {\n    .SharedTab-module__btn--3_LLc {\n      font-size: 1.7rem; } }\n  @media (min-width: 1025px) {\n    .SharedTab-module__btn--3_LLc {\n      font-size: 1.33rem;\n      padding: 0 0 1.75rem 0; }\n      .SharedTab-module__btn--3_LLc:before {\n        height: .25rem; } }\n", ""]);

// exports
exports.locals = {
	"btn": "SharedTab-module__btn--3_LLc",
	"active": "SharedTab-module__active--S2itT"
};