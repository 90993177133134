exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".FeaturedLink-module__featuredLink--3FmdT img {\n  margin-right: 1rem;\n  -webkit-transform: translate3d(0, 25%, 0);\n          transform: translate3d(0, 25%, 0);\n  width: 1.83333rem; }\n", ""]);

// exports
exports.locals = {
	"featuredLink": "FeaturedLink-module__featuredLink--3FmdT"
};