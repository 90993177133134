exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".TableSeasonRecord-module__TableSeasonRecord--2Np29 {\n  margin-bottom: calc(20px * 3); }\n\n@media (min-width: 1025px) {\n  .TableSeasonRecord-module__TableSeasonRecord--2Np29 {\n    margin: 0 auto calc(20px * 3);\n    max-width: 83.75rem; } }\n\n@media (min-width: 1440px) {\n  .TableSeasonRecord-module__TableSeasonRecord--2Np29 {\n    max-width: 117.8rem; } }\n", ""]);

// exports
exports.locals = {
	"TableSeasonRecord": "TableSeasonRecord-module__TableSeasonRecord--2Np29"
};