/* eslint-disable no-mixed-operators */
import React, { Component } from "react";
import { compose } from "recompose";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { withScroll, withGigya } from "~/hoc";
import { Text } from "~/patches/components";
import { Link as RouterLink } from "react-router-dom";
import Hamburger from "./Hamburger";
import MobileMegaNav from "./MobileMegaNav";
import SponsorsLogo from "~/assets/img/logo-color.png";
import styles from "./NavigationMobile.module.scss";
import PageNavigation from "~/shared-components/PageNavigation";
import { GigyaConsumer } from "~/lib/gigya";
import RedesignSearchForm from "~/shared-components/RedesignSearchForm";
import {UserProfile} from "~/shared-components/SvgIcons";
import  {Image}  from "~/patches/components/";

class NavigationMobileOld extends Component {

	constructor(props) {
		super(props);
		const { gigya: { isLoggedIn }} = this.props;

		this.state = {
			isActive: false,
			adScriptLoaded: false,
			navigationShouldCollapse: false,
			isUserLoggedIn: isLoggedIn
		};

		this.GptAdMobile = React.createRef();
	}
	
	componentDidMount() {
		window.addEventListener("gptLoaded", this.handleGPT); //eslint-disable-line
		window.addEventListener("gptUnloaded", this.unloadGPT); //eslint-disable-line
	}

	componentWillUnmount() {
		window.removeEventListener("gptLoaded", this.handleGPT); //eslint-disable-line
		window.removeEventListener("gptUnloaded", this.unloadGPT); //eslint-disable-line
	}

	componentDidUpdate(prevProps) {
		const {
			sitecoreContext: prevSitecoreContext,
			scroll: prevScroll,
			gigya: prevGigya
		} = prevProps;
		const {
			scroll,
			sitecoreContext,
			gigya: { isLoggedIn }
		} = this.props;
		const { adScriptLoaded } = this.state;

		if (scroll !== prevScroll && adScriptLoaded) {
			this.setState({
				navigationShouldCollapse: scroll > 200,
			});
		}

		if (prevSitecoreContext.route.name !== sitecoreContext.route.name) {
			// route has changed
			this.setState({isActive: false});
		}

		if (prevGigya.isLoggedIn !== isLoggedIn) {
			this.setState({
				isUserLoggedIn: isLoggedIn
			});
		}
	}

	handleGPT = () => {
		//const adElement = document.querySelector("#div-gpt-ad-nav-mobile"); //eslint-disable-line
		const adElement = this.GptAdMobile.current;
		if (!adElement) return;
		const adStyle = getComputedStyle(adElement);
		
		if (adStyle.display !== "none") {
			document.body.classList.add("ad-loaded"); //eslint-disable-line
			this.setState({ adScriptLoaded: true });
		}
	}

	unloadGPT = () => {
		document.body.classList.remove("ad-loaded"); //eslint-disable-line
		this.setState({ adScriptLoaded: false });
	}

	toggleState = () => {
		this.setState(prevState => ({isActive: !prevState.isActive}));
	}

	collapseNavigation = () => {
		this.setState({ isActive: false });
	}

	render() {
		const {
			fields,

			rendering: {
				primaryMenuItems,
				burgerMenuItemsTop,
				burgerMenuItemsBottom,
				pageNavigation
			}
		} = this.props;
		const {
			isActive,
			adScriptLoaded,
			navigationShouldCollapse,
			isUserLoggedIn
		} = this.state;
		const signupLink = (isUserLoggedIn ? fields.memberBenefitsLink : fields.becomeAMemberLink);
		
		return (
			<>
				{fields && (
					<header className={`${adScriptLoaded ? styles.NavigationMobileWithAd : styles.NavigationMobile} ${navigationShouldCollapse ? styles.collapsedNav : ""}`}>
						<div id="ad-nav-mobile" className={styles.adContainer}>
							<div ref={this.GptAdMobile} id="div-gpt-ad-nav-mobile"></div>
						</div>
						<nav className={isActive ? styles.topBarActive : styles.topBar}>

							<div className={styles.navBar}>
								<div className={styles.menu}>

									<Hamburger
										toggleState={this.toggleState}
										isActive={isActive} />

									<RouterLink className={isActive ? styles.logoActive : styles.logo} to="/">
										<img
											src={SponsorsLogo}
											alt="US Soccer. Sponsored by Volkswagen"
											title="US Soccer. Sponsored by Volkswagen" />
									</RouterLink>

									<svg width="125" height="148" viewBox="0 0 125 148" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M-58 -130.143V157.429L125 61.5714V-226L-58 -130.143Z" fill="white"/>
									</svg>
								</div>

								<div className={styles.primary}>
									{primaryMenuItems && primaryMenuItems.map((item, index) => (
										<RouterLink key={`primary-menu-${index}`} to={item.url}>
											<span className={styles.smallScreen}><Text field={item.menuTitleMobile} /></span>
											<div className={styles.mediumScreen}>
												<Text field={item.menuTitle} />
												<span className={styles.description}><Text field={item.menuDescription} /></span>
											</div>
										</RouterLink>
									))}
								</div>

								<div className={styles.tertiaryMenu}>
									<RouterLink to={signupLink.value.href} className={styles.insiderLink}>
										{signupLink.value.text}
									</RouterLink>
									<RouterLink to={fields.ticketsLink.value.href} className={styles.tickets}>
										<svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M20.2765 16.5936L21.6959 15.1233C22.1014 14.7032 22.1014 14.0731 21.6959 13.653L17.7419 9.55708L17.3364 9.97717C17.1336 10.1872 16.8295 10.1872 16.6267 9.97717C16.5253 9.76712 16.5253 9.45206 16.7281 9.24201L17.0323 8.82192L8.82028 0.315068C8.41475 -0.105023 7.80645 -0.105023 7.40092 0.315068L5.98157 1.78539C7.19816 3.04566 7.19816 5.0411 5.98157 6.19635C4.76498 7.3516 2.83871 7.45662 1.7235 6.19635L0.304149 7.66667C-0.10138 8.08676 -0.10138 8.71689 0.304149 9.13699L8.51613 17.6438L8.82028 17.4338C9.02304 17.2237 9.32719 17.2237 9.52995 17.4338C9.73272 17.6438 9.73272 17.9589 9.52995 18.1689L9.12442 18.589L13.0783 22.6849C13.4839 23.105 14.0922 23.105 14.4977 22.6849L15.9171 21.2146C14.7005 19.9543 14.7005 17.9589 15.9171 16.8037C17.1336 15.6484 19.0599 15.4384 20.2765 16.5936ZM10.9493 16.5936C10.7465 16.8037 10.4424 16.8037 10.2396 16.5936C10.0369 16.3836 10.0369 16.0685 10.2396 15.8584L10.9493 15.1233C11.1521 14.9132 11.4562 14.9132 11.659 15.1233C11.8618 15.3333 11.8618 15.6484 11.659 15.8584L10.9493 16.5936ZM13.0783 14.3881C12.8756 14.5982 12.5714 14.5982 12.3687 14.3881C12.1659 14.1781 12.1659 13.863 12.3687 13.653L13.0783 12.9178C13.2811 12.7078 13.5853 12.7078 13.788 12.9178C13.9908 13.1279 13.9908 13.4429 13.788 13.653L13.0783 14.3881ZM15.2074 12.1826C15.0046 12.3927 14.7005 12.3927 14.4977 12.1826C14.2949 11.9726 14.2949 11.6575 14.4977 11.4475L15.2074 10.7123C15.4101 10.5023 15.7143 10.5023 15.9171 10.7123C16.1198 10.9224 16.1198 11.2374 15.9171 11.4475L15.2074 12.1826Z" fill="white"/>
										</svg>
										<span>{fields.ticketsLink.value.text}</span>
									</RouterLink>
								</div>
							</div>
							<div className={styles.mobileMegaMenuWrapper}>
								<MobileMegaNav fields={fields} burgerMenuItemsTop={burgerMenuItemsTop} burgerMenuItemsBottom={burgerMenuItemsBottom} isActive={isActive} collapseNavigation={this.collapseNavigation}/>
							</div>
						</nav>
						<PageNavigation {...{pageNavigation}} />
					</header>
				)}
			</>
		);
	}
}

class NavigationMobileRedesign extends Component {

	constructor(props) {
		super(props);
		const { gigya: { isLoggedIn }} = this.props;

		this.state = {
			isActive: false,
			adScriptLoaded: false,
			navigationShouldCollapse: false,
			isUserLoggedIn: isLoggedIn
		};

		this.GptAdMobile = React.createRef();
	}

	componentDidMount() {
		window.addEventListener("gptLoaded", this.handleGPT); //eslint-disable-line
		window.addEventListener("gptUnloaded", this.unloadGPT); //eslint-disable-line
	}

	componentWillUnmount() {
		window.removeEventListener("gptLoaded", this.handleGPT); //eslint-disable-line
		window.removeEventListener("gptUnloaded", this.unloadGPT); //eslint-disable-line
	}

	componentDidUpdate(prevProps) {
		const {
			sitecoreContext: prevSitecoreContext,
			scroll: prevScroll,
			gigya: prevGigya
		} = prevProps;
		const {
			scroll,
			sitecoreContext,
			gigya: { isLoggedIn }
		} = this.props;
		const { adScriptLoaded } = this.state;

		if (scroll !== prevScroll && adScriptLoaded) {
			this.setState({
				navigationShouldCollapse: scroll > 200,
			});
		}

		if (prevSitecoreContext.route.name !== sitecoreContext.route.name) {
			// route has changed
			this.setState({isActive: false});
		}

		if (prevGigya.isLoggedIn !== isLoggedIn) {
			this.setState({
				isUserLoggedIn: isLoggedIn
			});
		}
	}

	handleGPT = () => {
		//const adElement = document.querySelector("#div-gpt-ad-nav-mobile"); //eslint-disable-line
		const adElement = this.GptAdMobile.current;
		if (!adElement) return;
		const adStyle = getComputedStyle(adElement);
		if (adStyle.display !== "none") {
			document.body.classList.add("ad-loaded"); //eslint-disable-line
			this.setState({ adScriptLoaded: true });
		}
	}

	unloadGPT = () => {
		document.body.classList.remove("ad-loaded"); //eslint-disable-line
		this.setState({ adScriptLoaded: false });
	}

	toggleState = () => {
		this.setState(prevState => ({isActive: !prevState.isActive}));
	}

	collapseNavigation = () => {
		this.setState({ isActive: false });
	}

	render() {
		const {
			fields,
			rendering: {
				primaryMenuItems,
				burgerMenuItemsTop,
				burgerMenuItemsBottom,
				pageNavigation
			}
		} = this.props;
		const {
			isActive,
			adScriptLoaded,
			navigationShouldCollapse,
			isUserLoggedIn
		} = this.state;
		const signupLink = (isUserLoggedIn ? fields.memberBenefitsLink : fields.becomeAMemberLink);
		return (
			<>
				{fields && (
					<header className={`${styles.NavigationMobileRedesign} ${navigationShouldCollapse ? styles.collapsedNav : ""}`}>
						<nav className={isActive ? styles.topBarActive : styles.topBar}>

							<div className={styles.navBar}>
								<div className={styles.menu}>
									<RouterLink className={styles.logo} to="/">
										<Image field={fields.logo} />
									</RouterLink>
								</div>
								<div>
									{/* {fields.searchLink.value.href && (
										//eslint-disable-next-line
										<RouterLink to={fields.searchLink.value.href} onClick={() => {document.body.classList.remove("overflowHidden"); isActive = false;}}>
											<svg width="28" height="28" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12.3636" cy="12.3636" r="6.6136" stroke="#000" strokeWidth="1.5"/><path d="M17.0908 17.0908l5.9091 5.9091" stroke="#000" strokeWidth="1.5" strokeLinecap="round"/></svg>
										</RouterLink>
									)} */}
									<RedesignSearchForm />
								</div>
								<div className={styles.userProfile}>
									<GigyaConsumer>
										{({ isLoggedIn, profile }) => (
											<RouterLink to={isLoggedIn ? fields.profileLink.value.href : fields.loginPage.value.href}>
												{/* Logged-in users with first/last names. */}
												{isLoggedIn && profile?.firstName && profile?.lastName && (
													<span>
														{`${profile.firstName[0].toUpperCase()}${profile.lastName[0].toUpperCase()}`}
													</span>
												)}
												{/* Anonymous users and logged-in users without first/last names. */}
												{(!isLoggedIn || (isLoggedIn && (profile.firstName === undefined || profile.lastName === undefined))) && (
													<UserProfile />
												)}
											</RouterLink>
										)}
									</GigyaConsumer>
								</div>

								<Hamburger
									toggleState={this.toggleState}
									isActive={isActive} />
							</div>
							<div className={styles.mobileMegaMenuWrapper}>
								<MobileMegaNav fields={fields} burgerMenuItemsTop={burgerMenuItemsTop} burgerMenuItemsBottom={burgerMenuItemsBottom} isActive={isActive} collapseNavigation={this.collapseNavigation}/>
							</div>
						</nav>
						<PageNavigation {...{pageNavigation}} />
					</header>
				)}
			</>
		);
	}
}

const NavigationMobile = props => ( props.fields.redesign.value ? <NavigationMobileRedesign {...props} /> : <NavigationMobileOld {...props} />);

export default compose(
	withGigya(),
	withSitecoreContext(),
	withScroll()
)(NavigationMobile);