exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ArrowLink-module__link--2r7fa {\n  -ms-flex-align: center;\n      align-items: center;\n  display: -ms-flexbox;\n  display: flex; }\n  .ArrowLink-module__link--2r7fa span {\n    padding-top: 0; }\n  .ArrowLink-module__link--2r7fa a {\n    color: #C2002F;\n    display: inline-block;\n    font-size: 1.3rem;\n    letter-spacing: 0.02rem;\n    line-height: 1;\n    text-transform: uppercase; }\n\n.ArrowLink-module__arrow--3mjYk {\n  display: -ms-inline-flexbox;\n  display: inline-flex;\n  margin-left: 0.9rem;\n  stroke-linecap: round;\n  stroke-linejoin: round; }\n  .ArrowLink-module__arrow--3mjYk svg {\n    height: calc(0.46296vw + 13.33333px);\n    width: 0.8rem; }\n    @media (max-width: 576px) {\n      .ArrowLink-module__arrow--3mjYk svg {\n        height: 16px; } }\n    @media (min-width: 1440px) {\n      .ArrowLink-module__arrow--3mjYk svg {\n        height: 20px; } }\n\n@media (min-width: 1025px) {\n  .ArrowLink-module__link--2r7fa a {\n    font-size: 1.2rem; } }\n", ""]);

// exports
exports.locals = {
	"link": "ArrowLink-module__link--2r7fa",
	"arrow": "ArrowLink-module__arrow--3mjYk"
};