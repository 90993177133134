/* eslint-disable no-restricted-globals */
import React, { PureComponent } from "react";
import { isServer } from "~/utilities";

const getScroll = isServer
	? () => ({ scroll: 0 })
	: () => ({ scroll: window.scrollY });

export const ScrollContext = React.createContext();

class ScrollProvider extends PureComponent {
	state = getScroll();

	eventListener = () => {
		this.setState(getScroll());
	}

	componentDidMount() {
		if (!isServer) {
			window.addEventListener('scroll', this.eventListener);
		}
	}

	componentWillUnmount() {
		if (!isServer) {
			window.removeEventListener('scroll', this.eventListener);
		}
	}

	render() {
		return (
			<ScrollContext.Provider value={this.state}>
				{this.props.children}
			</ScrollContext.Provider>
		);
	}
}

export default ScrollProvider;