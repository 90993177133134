import { useSanity } from '../useSanity';

export const useTeam = ({ optaTeamId }) => {
	const groqQuery = `
    *[_type == 'team' %26%26 optaTeamId == $optaTeamId]{
		_id, socialPlatforms
	  }
      
	`;
	const queryKey = ['useTeam', {optaTeamId}];
	const variablesQueryString = `$optaTeamId="${optaTeamId}"`;
	const {
		isError,
		loading,
		data
	} = useSanity({ groqQuery, queryKey, variablesQueryString });

	let socialLinks = [];

	if (data && data[0]?.socialPlatforms) {

		const socialLinksObject = data[0].socialPlatforms;

		socialLinks = Object.keys(socialLinksObject).map((key) => ({
			name: socialLinksObject[key].title,
			title: socialLinksObject[key].title,
			link: {
				title: socialLinksObject[key].title,
				text: socialLinksObject[key].text,
				external: socialLinksObject[key].external,
				_type: 'link'
			},
			_type: "socialLink",
			icons: [socialLinksObject[key].icon]
		}));
	}

	return {
		isError,
		loading,
		data,
		socialLinks
	};
};