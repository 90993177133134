import React from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import { StoryCard, Carousel } from "~/shared-components";
import styles from "./MoreStoriesGrid.module.scss";
import SectionLabel from "~/shared-components/SectionLabel";
import ArrowLink from "~/shared-components/ArrowLink";
import { withNamespaces } from "react-i18next";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { wildcardUrlDecode } from "~/utilities";
import HelperCommentEdit from "~/experience-editor/HelperCommentEdit";

const MoreStoriesGrid = ({fields, rendering: { stories }, t, sitecoreContext}) => (
	<>
		{stories &&
			<section className={styles.wrapper}>
				<div className={styles.container}>
					<div className={styles.section}>
						<SectionLabel sectionLabel={fields?.sectionLabel} />
					</div>
				</div>
				{stories.length === 0 && sitecoreContext.pageEditing && (
					<HelperCommentEdit endingEditComment="and select the desired categories" />
				)}
				<StoriesByCategory
					stories={stories}
					t={t}
					settings={sitecoreContext.settings} />
			</section>
		}
	</>
);

const StoriesByCategory = ({stories, t, settings}) => (
	<>
		{stories  && (
			stories.map(({ key, value }, index) => (
				key && value?.length !== 0 && (
					<section className={styles.container} key={index}>
						<div className={styles.section}>
							<div className={styles.row}>
								<div className={styles.sectionHeader}>
									<h3 className={styles.categoryHeading}>{key}</h3>
										<>
										{value.length > 3 &&
										<div className={styles.desktopOnly}>
											<ArrowLink link={{ value: { href: `${settings?.fields?.categoryPage?.url}/${wildcardUrlDecode(key)}`}}} linkText={t("arrow_link_view_all")} />
										</div>}
										{value.length > 10 &&
										<div className={styles.mobileOnly}>
											<ArrowLink link={{ value: { href: `${settings?.fields?.categoryPage?.url}/${wildcardUrlDecode(key)}`}}} linkText={t("arrow_link_view_all")} />
										</div>}
										</>
								</div>
								<div className={`${styles.col} ${styles.mobileOnly}`}>
									<Carousel>
										<StoriesCarousel stories={value} />
									</Carousel>
								</div>
								<div className={`${styles.col} ${styles.desktopOnly}`}>
									<StoriesCarousel stories={value} />
								</div>
							</div>
						</div>
					</section>
				)
			))
		)}
	</>
);

const StoriesCarousel = ({stories}) => (
	<>
		{stories && (
			stories.map((story, index) => (
				<Story key={index} story={story} />
			))
		)}
	</>
);

const Story = ({story}) => (
	<>
		{story && (
			<div className={styles.item}>
				<StoryCard {...{
					fields: {
						heading: story.heading,
						image: story.image,
						date: story.date,
						categoryValue: story.category?.value,
						categoryKey: story.categoryKey,
						categoryUrl: story.categoryUrl,
						imageText: story.imageText,
						link: { value: { href: story.link } }
					},
					options: { modifierClassName: "moreStories" }
				}} />
			</div>
		)}
	</>
);

export default compose(
	// other hocs goes here
	withSitecoreContext(),
	withNamespaces(),
	withErrorHandling()
)(MoreStoriesGrid);