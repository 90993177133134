import React from "react";
import { Text } from "~/patches/components";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import LinkBox from "./LinkBox";
import styles from "./ContactUs.module.scss";

function populateLinkBoxes(fields) {
	const linkboxes = [];

	for (let i = 1; i <= 4; i++) {
		linkboxes.push({
			icon: fields[`icon${i}`],
			title: fields[`title${i}`],
			text: fields[`text${i}`],
			link: fields[`link${i}`]
		});
	}

	return linkboxes;
}

const ContactUs = ({ fields }) => {
	const linkBoxes = fields ? populateLinkBoxes(fields) : null;

	return (
		<>
			{fields && (
				<section className={styles.ContactUs}>
					<div className={styles.container}>
						<h3>
							<Text field={fields.heading} />
						</h3>
						<ul>
							{linkBoxes.map((linkBox, i) => (
								<LinkBox key={i} fields={linkBox} />
							))}
						</ul>
					</div>
				</section>
			)}
		</>
	);
};

export default compose(
	// other hocs goes here
	withErrorHandling()
)(ContactUs);
