import React from "react";
import { Text } from "~/patches/components";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";

const RedesignQuadBoxImage = ({ fields, params }) => (
	<>
		{fields && (
			<div>
				<p>RedesignQuadBoxImage Component</p>
			</div>
		)}
	</>
);

export default compose(
	// other hocs goes here
	withErrorHandling()
)(RedesignQuadBoxImage);