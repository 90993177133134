import React, { useState } from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { Text } from "~/patches/components";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import AlertCard from "../AlertCard";
import styles from "./EmailVerificationAlertCard.module.scss";

const EmailVerificationAlertCard = ({ fields, sitecoreContext: { pageEditing } }) => {
	const [sentEmail, setSentEmail] = useState(false);

	function sendEmailVerificationEmail() {
		// TODO: some sort of call here
		setSentEmail(true);
	}

	const verifiedEmail = false; // TODO: comes from GIGYA

	return (
		<>
			{(!verifiedEmail || pageEditing) && (
				<AlertCard fields={fields}>
					{(!sentEmail || pageEditing) && (
						<p>
							<Text field={fields.resendText} />
							<Text tag="a" field={fields.resendLinkText} onClick={() => sendEmailVerificationEmail()} />
						</p>
					)}
					{(sentEmail || pageEditing) && (
						<Text tag="p" className={styles.success} field={fields.resendSuccessText} />
					)}
				</AlertCard>
			)}
		</>
	);
};

export default compose(
	withSitecoreContext(),
	withErrorHandling()
)(EmailVerificationAlertCard);