import { FetchService } from "./fetchService";

export class MembershipService {
	
	static cancelSubscription(jwt) {
		return FetchService.delete(`/api/membership/subscription`, { "Authorization": `Bearer ${jwt}` });
	}
	
	static upgradeMembershipTier(jwt, newTier) {
		return FetchService.post(`/api/membership/upgrade`, { newTier }, { "Authorization": `Bearer ${jwt}` });
	}

	static createSetupIntent(jwt) {
		return FetchService.post(`/api/membership/createSetupIntent`, { }, { "Authorization": `Bearer ${jwt}` });
	}

	static getProrationPreview(jwt, newTier) {
		return FetchService.get(`/api/membership/proration/${newTier}`, { "Authorization": `Bearer ${jwt}` });
	}

	static getMembershipBannerUpgrade(currentTier) {
		return FetchService.get(`/api/membership/membershipBanner/${currentTier}`);
	}

	static getMembershipPresaleCode(jwt, currentTier) {
		return FetchService.get(`/api/presale/${currentTier}`, { "Authorization": `Bearer ${jwt}`});
	}
}