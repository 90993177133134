var escape = require("../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".InfoBlock-module__InfoBlock--ZkHo2 {\n  color: #4d4d4d;\n  font-family: \"USSF90MinText\", helvetica, arial, sans-serif;\n  font-size: 1.6rem;\n  letter-spacing: 0.03rem;\n  line-height: 1.5;\n  margin-bottom: 20px;\n  z-index: 2; }\n  .InfoBlock-module__InfoBlock--ZkHo2 h2 {\n    color: #212844;\n    font-size: 2em;\n    font-weight: 300;\n    margin-bottom: 2rem;\n    text-transform: uppercase; }\n  .InfoBlock-module__InfoBlock--ZkHo2 a {\n    color: #C2002F;\n    display: inline-block;\n    font-family: \"USSF90MinDisplay\", helvetica, arial, sans-serif;\n    font-size: 1.3rem;\n    letter-spacing: 0.02em;\n    margin-top: 2.5rem;\n    position: relative;\n    text-decoration: none;\n    text-transform: uppercase; }\n    .InfoBlock-module__InfoBlock--ZkHo2 a:after {\n      background: url(" + escape(require("../../assets/img/cta-arrow.svg")) + ") center center no-repeat;\n      background-size: contain;\n      content: \"\";\n      height: calc(100% + 0.4rem);\n      position: absolute;\n      right: -3.3rem;\n      top: -0.3rem;\n      width: 4.3rem; }\n\n@media (min-width: 1025px) {\n  .InfoBlock-module__InfoBlock--ZkHo2 {\n    font-size: 1.334rem;\n    letter-spacing: 0.02rem;\n    max-width: 37.5rem; }\n    .InfoBlock-module__InfoBlock--ZkHo2 h2 {\n      font-size: 2.5rem;\n      line-height: 1.2;\n      margin-bottom: 2.083rem; }\n    .InfoBlock-module__InfoBlock--ZkHo2 > a {\n      font-size: 1.2rem;\n      margin-top: 4rem; } }\n", ""]);

// exports
exports.locals = {
	"InfoBlock": "InfoBlock-module__InfoBlock--ZkHo2"
};