/* eslint-disable no-restricted-globals */
import React, { Fragment } from "react";
import styles from "./PlayerGrid.module.scss";
import { Placeholder, withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { isDesktop, isServer } from "~/utilities";
import { withSectionHeader, withErrorHandling } from "~/hoc";
import { Carousel } from "~/shared-components";
import PlayerThumbnail from "~/components/PlayerThumbnail"; // eslint-disable-line
import { compose } from "recompose";

const options = {
	modifierClassName: 'imagePaddingHeight',
	imageSrcSet: [{mw: 330}, {mw: 768}],
	imageSizes: '(min-width: 768px) 330px, 768px',
	showNumberOfStats: 3
};

const PlayerGrid = ({ rendering, sitecoreContext: { pageEditing } }) => {

	const { teamLink } = rendering;

	return (
		<>
			{teamLink  ? (
				<>
					{(isServer || pageEditing) ? (
						<section className={styles.container} >
							<div className="grid gridContainer">
								<div className="row">
									{teamLink.fields.members.map((player, index) => (
										<div key={index} className="col col--xs-6 col--sm-4 col--md-3">
											<PlayerThumbnail fields={{ itemLink: player }} />
										</div>
									))}
								</div>
							</div>
						</section>
					) : (
						<>
							<section className={styles.container}>
								<div className="grid gridContainer">
									<div className="row">
										{teamLink.fields.members.map((player, index) => (
											<div key={index} className="col col--xs-6 col--sm-4 col--md-3">
												<PlayerThumbnail fields={{ itemLink: player }} />
											</div>
										))}
									</div>
								</div>
							</section>
						</>
					)}
				</>
			) : (
				<>
					{(isServer || pageEditing) ? (
						<>
							<section className={styles.container}>
								<div className="grid gridContainer">
									<div className="row">
										<Placeholder
											name="jss-player-grid-content"
											rendering={rendering}
											renderEach={(component, index) => {

												const newProps = {options, ...component.props};
												const newComponent = React.cloneElement(component, newProps);

												return (
													<div className={`col col--xs-6 col--sm-4 col--md-3 ${styles.column}`} key={index}>
														{newComponent}
													</div>
												);
											}}/>
									</div>
								</div>
							</section>
						</>
					) : (
						<>
							{(isDesktop) ? (
								<section className={styles.container}>
									<div className="grid gridContainer">
										<div className="row">
											<Placeholder
												name="jss-player-grid-content"
												rendering={rendering}
												renderEach={(component, index) => {

													const newProps = {options, ...component.props};
													const newComponent = React.cloneElement(component, newProps);

													return (
														<div className={`col col--xs-6 col--sm-4 col--md-3 ${styles.column}`} key={index}>
															{newComponent}
														</div>
													);
												}}/>
										</div>
									</div>
								</section>
							) : (
								<section className={styles.carousel}>
									<Placeholder
										name="jss-player-grid-content"
										rendering={rendering}
										render={components => {
											if (components.length === 0) return <div></div>;

											return (
												<Carousel>
													{components.map((component, index) => (
														<Fragment key={index}>
															<CarouselItem component={component} />
														</Fragment>
													))}
												</Carousel>
											);
										}}/>
								</section>
							)}
						</>
					)}
				</>
			)}
		</>
	);
};

/**
 * Single carousel item.
 *
 * @param {object} component - The component object.
 */
const CarouselItem = ({component}) => {
	const newProps = {options, ...component.props};
	const newComponent = React.cloneElement(component, newProps);

	return (
		<div className={styles.item}>{newComponent}</div>
	);
};

export default compose(
	withSitecoreContext(),
	withSectionHeader(),
	withErrorHandling()
)(PlayerGrid);