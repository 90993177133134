// REDESIGN 2022 //

import React from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import Button from "~/shared-components/Button";

const getVariant = (fields) => {
	let variant = '';

	if (!fields.white.value && !fields.alternate.value && !fields.secondary.value) {
		variant = 'primary';
	}
	
	if (!fields.white.value && !fields.alternate.value && fields.secondary.value) {
		variant = 'secondary';
	} 
	
	if (!fields.white.value && fields.alternate.value && fields.secondary.value) {
		variant = 'secondaryAlternate';
	}
		
	if (fields.white.value && !fields.secondary.value) {
		variant = 'primaryReversed'
	}

	if (fields.white.value && fields.secondary.value) {
		variant = 'secondaryReversed';
	}

	return variant;
}

const CallToActionButton = ({ fields }) => (
	<>
		{fields && (
			<Button
				link={fields.link.value.href}
				text={fields.text.value}
				size={fields.size.value ? 'small' : 'large'}
				variant={getVariant(fields)}
			></Button>
		)}
	</>
);

export default compose(
	// other hocs goes here
	withErrorHandling()
)(CallToActionButton);