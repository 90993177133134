exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".TwoColumnsLayout-module__twoColumnsLayout--2r1gu {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-direction: column;\n      flex-direction: column;\n  margin: 0 auto 0; }\n  .TwoColumnsLayout-module__twoColumnsLayout--2r1gu .TwoColumnsLayout-module__sidebar--1UTo7 {\n    padding: 0 3rem;\n    width: 100%; }\n  .TwoColumnsLayout-module__twoColumnsLayout--2r1gu .TwoColumnsLayout-module__content--RYPM2 {\n    overflow: hidden;\n    padding: 0 3rem;\n    width: 100%; }\n\n@media (min-width: 1025px) {\n  .TwoColumnsLayout-module__twoColumnsLayout--2r1gu {\n    -ms-flex-direction: row;\n        flex-direction: row;\n    margin: 7.084rem auto 0;\n    max-width: calc(1440px + 16.667rem);\n    padding-left: 8.634rem; }\n    .TwoColumnsLayout-module__twoColumnsLayout--2r1gu .TwoColumnsLayout-module__sidebar--1UTo7 {\n      display: block;\n      padding: 0 7.084rem 0 0;\n      width: 33.334%; }\n    .TwoColumnsLayout-module__twoColumnsLayout--2r1gu .TwoColumnsLayout-module__content--RYPM2 {\n      padding: 0;\n      width: calc(100% - 33.334%); } }\n", ""]);

// exports
exports.locals = {
	"twoColumnsLayout": "TwoColumnsLayout-module__twoColumnsLayout--2r1gu",
	"sidebar": "TwoColumnsLayout-module__sidebar--1UTo7",
	"content": "TwoColumnsLayout-module__content--RYPM2"
};