import React from "react";
import styles from "./FeaturedStoriesGrid.module.scss";
import { Placeholder, withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { withSectionHeader } from "~/hoc";
import { compose } from "recompose";
import { placeholderChildrenLength } from "~/utilities";
import { StoryCard } from "~/shared-components";

const optionsMainColumn = {
	modifierClassName: 'featuredLarge',
	imageSrcSet: [{mw: 700}, {mw: 768}],
	imageSizes: '(min-width: 768px) 700px, 768px'
};

const optionsColumns = {
	modifierClassName: 'featuredSmall',
	imageSrcSet: [{mw: 340}, {mw: 768}],
	imageSizes: '(min-width: 768px) 340px, 768px'
};

/**
 * Placeholder for default coloumns used to fill missing autorhed sotires.
 */
const defaultColumns = {
	mainColumn: undefined,
	remainingColumns: undefined
};

/**
 * Placeholder to check if rendering has any placholder child item.
 */
let noPlaceholderChildren = false;

/**
 * Function to populate defaults columns
 *
 * @param {array} stories - an array of story object
 */
function populateDefaultColumns(stories) {
	
	if (!stories) return;
	
	if (noPlaceholderChildren) {
		const [mainDefaultStory, ...restStories] = stories;

		defaultColumns.mainColumn = mainDefaultStory;
		defaultColumns.remainingColumns = restStories;
	} else {
		defaultColumns.remainingColumns = stories;
	}
}

const FeaturedStoriesGrid = ({ rendering, sitecoreContext: { pageEditing } }) => (
	<><>
	<div className={styles.featuredStoriesGrid}>
		{pageEditing ? (
			<div className="grid redesignGridContainer">
				<div className="row">
					<Placeholder
						name="jss-featured-stories-grid-content"
						rendering={rendering}
						renderEach={(component, index) => (
							<div className="col col--sm-3" key={index}>
								{component}
							</div>
						)}/>
				</div>
			</div>
		) : (
			<Placeholder
				name="jss-featured-stories-grid-content"
				rendering={rendering}
				render={components => {

					if (placeholderChildrenLength(rendering, "jss-featured-stories-grid-content") === 0) noPlaceholderChildren = true;

					populateDefaultColumns(rendering.stories);
					if (components.length === 0 && !defaultColumns.remainingColumns)
						return <div></div>;

					const [firstComp, ...restComps] = components;

					return (
						<>
						<div>
							<div id="div-gpt-ad-2897257-2"></div>
						</div>
						<div className="grid redesignGridContainer">
							<div className="row">
								{!noPlaceholderChildren && (
									<MainColumn mainComp={firstComp}/>
								)}
								{defaultColumns.mainColumn && (
									<DefaultMainColumn story={defaultColumns.mainColumn} />
								)}
								<div className={`${styles.smallCards} col col--sm-6 col--no-pad`}>
									<div className="row">
										{!noPlaceholderChildren && (
											<Columns columns={restComps}/>
										)}
										{defaultColumns.remainingColumns && (
											<DefaultColumns stories={defaultColumns.remainingColumns} />
										)}
									</div>
								</div>
							</div>
						</div>
						</>
					);
				}}/>
		)}
	</div>
	</>

	</>
);

const MainColumn = ({mainComp}) => {
	if (!mainComp) {
		return <></>;
	}
	const newMainProps = Object.assign({ options: optionsMainColumn }, mainComp.props);
	const newMainComponent = React.cloneElement(mainComp, newMainProps);

	return (
		<div className={`col col--sm-6 ${styles.mainColumn}`}>
			{newMainComponent}
		</div>
	);
};

const Columns = ({columns}) => (
	columns.map((col, index) => {

		// No more than 5 thumbnails allowed
		if (index > 3) return;

		const newProps = Object.assign({ options: optionsColumns }, col.props);
		const newComponent = React.cloneElement(col, newProps);

		return (
			<div key={index} className={`col col--xs-6 ${styles.columns}`}>
				{newComponent}
			</div>
		);
	})
);

const DefaultMainColumn = ({ story }) => (
	<div className={`col col--sm-6 ${styles.mainColumn}`}>
		<DefaultStoryCard {...story} {...optionsMainColumn} />
	</div>
);

const DefaultColumns = ({ stories }) => (
	stories.map((story, index) => (
		<div key={index} className={`col col--xs-6 ${styles.columns}`}>
			<DefaultStoryCard {...story} {...optionsColumns} />
		</div>
	))
);

const DefaultStoryCard = story => (
	<StoryCard
		{...{
			options: {
				modifierClassName: story.modifierClassName
			},
			fields: {
				heading: story.heading,
				image: story.image,
				date: story.date,
				categoryValue: story.category?.value,
				categoryKey: story.categoryKey,
				categoryUrl: story.categoryUrl,
				imageText: story.imageText,
				link: { value: { href: story.link } }
			}
		} } />
);

export default compose(
	withSitecoreContext(),
	withSectionHeader()
)(FeaturedStoriesGrid);