import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import styles from "./LoginAvatar.module.scss";
import loggedOutAvatar from "~/assets/svg/logged-out-avatar.svg";
import { GigyaConsumer } from "~/lib/gigya";

class LoginAvatar extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { loginPage, profilePage } = this.props; 
		return (
			<GigyaConsumer>
				{({ isLoggedIn, profile }) => 
					<div className={styles.loginAvatar}>
						<NavLink to={isLoggedIn ? profilePage.value.href : loginPage.value.href}>
							<img 
								src={profile?.photoURL || loggedOutAvatar} 
								alt={isLoggedIn ? profilePage.value.text : loginPage.value.text}
								title={isLoggedIn ? "My Account" : "Log In"} />
						</NavLink>
					</div>
				}
			</GigyaConsumer>
		);
	}
}
export default LoginAvatar;