/* eslint-disable no-restricted-globals */
import React, {useEffect} from "react";
import {SitecoreContext} from "@sitecore-jss/sitecore-jss-react";
import {Route, Switch} from "react-router-dom";
import TagManager from 'react-gtm-module';
import componentFactory from "~/temp/componentFactory";
import SitecoreContextFactory from "~/lib/SitecoreContextFactory";
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import RouteHandler from "~/RouteHandler";
import {GigyaConsumer, GigyaProvider} from "~/lib/gigya";
import {ScrollProvider} from "~/providers";
import {LoginRoute, PrivateRoute} from "~/shared-components/PrivateRoute";
import {getEnv} from "~/utilities/helpers";
import osano from "~/configs/osano.json";
import {QueryClient, QueryClientProvider,} from '@tanstack/react-query';
import getBodyClassNames from "~/utilities/getBodyClassNames";
import LogRocket from 'logrocket';

const queryClient = new QueryClient();

// This is the main JSX entry point of the app invoked by the renderer (server or client rendering).
// By default the app's normal rendering is delegated to <RouteHandler> that handles the loading of JSS route data.

// support languages in the URL prefix
// e.g. /da-DK/path, or /en/path, or /path
export const routePatterns = [
	"/:lang([a-z]{2}-[A-Z]{2})/:sitecoreRoute*",
	"/:lang([a-z]{2})/:sitecoreRoute*",
	"/:sitecoreRoute*",
];

export const privateRoutePatterns = [
	"/:lang([a-z]{2}-[A-Z]{2})/profile/:sitecoreRoute*",
	"/:lang([a-z]{2})/profile/:sitecoreRoute*",
	"/profile/:sitecoreRoute*",
];

export const loginRoutePatterns = [
	"/:lang([a-z]{2}-[A-Z]{2})/login",
	"/:lang([a-z]{2})/login",
	"/login",
];

// wrap the app with:
// ApolloProvider: provides an instance of Apollo GraphQL client to the app to make Connected GraphQL queries.
//    Not using GraphQL? Not needed if not using connected GraphQL.
// SitecoreContext: provides component resolution and context services via withSitecoreContext
// Router: provides a basic routing setup that will resolve Sitecore item routes and allow for language URL prefixes.
const AppRoot = ({ path, Router /*, graphQLClient */ }) => {
	const routeRenderFunction = props => <GigyaConsumer>{(gigya) => <RouteHandler path={path} route={props} gigya={gigya} />}</GigyaConsumer>;

	useEffect(() => {
		if (typeof window === 'undefined') {
			return;
		}
		window.onload = () => {
			let oldHref = document.location.href;
			// const body = document.querySelector("body");
			const body = document.body;
			const observer = new MutationObserver(mutations => {
				mutations.forEach(() => {
					if (oldHref !== document.location.href) {
						oldHref = document.location.href;
						// document.body.className = '';
						// document.body.classList.add(...getBodyClassNames());
						body.className = '';
						body.classList.add(...getBodyClassNames());
					}
				});
			});
			observer.observe(body, {childList: true, subtree: true});
			// document.body.classList.add(...getBodyClassNames());
			body.classList.add(...getBodyClassNames());
			LogRocket.init('piecba/website-xt2pf');
		};
	});

	useEffect(() => {
		if (typeof document !== 'undefined') {
			const head = document.getElementsByTagName("head")[0];
			const script = document.createElement('script');
			script.type = 'text/javascript';
			script.src = osano.scriptUrl;

			head.insertBefore(script, head.firstChild);
		}

		if (typeof window !== 'undefined') {
			const env = getEnv();

			if (!env) {
				return;
			}

			const tagManagerArgs = {
				gtmId: env === 'qa' ? 'GTM-KSM7XNJ' : 'GTM-NP2GKLD',
				dataLayerName: 'dataLayer'
			};

			TagManager.initialize(tagManagerArgs);
		}
	}, []);

	return (
		// <ApolloProvider client={graphQLClient}>
		<GigyaProvider>
			<ScrollProvider>
				<QueryClientProvider client={queryClient}>
					<SitecoreContext componentFactory={componentFactory} contextFactory={SitecoreContextFactory}>
						<Router location={path} context={{}}>
							{/* use <PrivateRoute> here for anything with "/profile", <LoginRoute> for "/login" */}
							<Switch>
								{loginRoutePatterns.map((loginRoutePattern) => (
									<LoginRoute key={loginRoutePattern} path={loginRoutePattern} render={routeRenderFunction} />
								))}
								{privateRoutePatterns.map((privRoutePattern) => (
									<PrivateRoute key={privRoutePattern} path={privRoutePattern} render={routeRenderFunction} />
								))}
								{routePatterns.map((routePattern) => (
									<Route key={routePattern} path={routePattern} render={routeRenderFunction} />
								))}
							</Switch>
						</Router>
					</SitecoreContext>
					<ReactQueryDevtools initialIsOpen={false} />
				</QueryClientProvider>
			</ScrollProvider>
		</GigyaProvider>
		//</ApolloProvider>
	);
};

export default AppRoot;
