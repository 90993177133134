exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ShowMoreButton-module__container--3uYvj {\n  color: #C2002F;\n  cursor: pointer;\n  display: block;\n  margin: 0 auto;\n  text-align: center;\n  text-transform: uppercase; }\n  .ShowMoreButton-module__container--3uYvj.ShowMoreButton-module__visible--2vf-c {\n    display: block; }\n  .ShowMoreButton-module__container--3uYvj.ShowMoreButton-module__hide--cd5bP {\n    display: none; }\n  .ShowMoreButton-module__container--3uYvj svg {\n    height: 1.666rem;\n    -webkit-transform: rotate(90deg);\n            transform: rotate(90deg);\n    width: 2rem; }\n    .ShowMoreButton-module__container--3uYvj svg path {\n      stroke: #C2002F !important; }\n  .ShowMoreButton-module__container--3uYvj .ShowMoreButton-module__name--2iVEh {\n    display: block; }\n", ""]);

// exports
exports.locals = {
	"container": "ShowMoreButton-module__container--3uYvj",
	"visible": "ShowMoreButton-module__visible--2vf-c",
	"hide": "ShowMoreButton-module__hide--cd5bP",
	"name": "ShowMoreButton-module__name--2iVEh"
};