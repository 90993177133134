import React, { Component, useState } from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { withErrorHandling } from "~/hoc";
import { fieldHasValue } from "~/utilities";
import { compose } from "recompose";
import MatchesGrid from "~/shared-components/MatchesGrid";
import MatchFilter from "~/shared-components/MatchFilter";
import HelperCommentEdit from "~/experience-editor/HelperCommentEdit";
import { MatchService } from "~/services/matchService";
import moment from "moment";
import BannerAd from "../../BannerAd";

const defaultTeamName = "All";

// GET: /api/matches/upcoming/{contestantId}
const MatchesGridUpcoming = ({
	fields,
	rendering: { matches, teams },
	sitecoreContext: { pageEditing }
}) => {
	const [state, setState] = useState({
		filteredMatches: [],
	});
	const allTeams = [
		{ name: "All" },
		...teams
	];
	const getDefaultTeam = () => {
		const firstTeam = fields.teamLink ? fields.teamLink[0]?.fields?.optaTeamId?.value : "";
		let defaultTeam = firstTeam && teams ? teams.filter(t => t?.optaId === firstTeam)[0] : "";

		if (fields.teamLink.length > 1) {
			defaultTeam = { name: defaultTeamName };
		}

		return defaultTeam;
	}

	const updatedFilters = filters => {
		if (filters.team.optaId) {
			fetchMatchesByTeam(filters.team.optaId);
		} else {
			showAllMatches();
		}
	}
	const handleResponse = response => {
		const res = response;
		const sortedResponse = res.sort((a, b) => {
			const aDate = moment(a.date);
			const bDate = moment(b.date);

			return aDate - bDate;
		});

		setState({ filteredMatches: sortedResponse });
	}

	const fetchMatchesByTeam = contestantId => {
		if (contestantId) {
			MatchService.getTeamUpcomingMatches(contestantId)
				.then(response => {
					handleResponse(response);
				});
		}
	}
	const showAllMatches = () => {
		setState({ filteredMatches: matches });
	}

	const { filteredMatches } = state;
	const defaultTeam = getDefaultTeam();

	React.useEffect(() => {
		if (defaultTeam.name === defaultTeamName) {
			showAllMatches();
		}
		if (!teams) {
			showAllMatches();
		}
		else {
			fetchMatchesByTeam(defaultTeam.optaId);
		}
	}, [defaultTeam]);
	

	return (
		<>
			{pageEditing && !matches && (
				<HelperCommentEdit endingEditComment="and select a team to render the grid" />
			)}
			{matches && (
				<>
					{teams && (
						<MatchFilter
							defaultTeam={defaultTeam}
							allMatches={matches}
							teams={allTeams}
							updatedFilters={updatedFilters}
						/>
					)}
					<MatchesGrid
						matches={filteredMatches}
						type={"upcoming"}
						pageEditing={pageEditing}
					/>
				</>
			)}

			{(fieldHasValue(fields, "heading") || pageEditing) && (
				<BannerAd fields={fields} />
			)}
		</>
	);
}

export default compose(
	// other hocs goes here
	withSitecoreContext(),
	withErrorHandling()
)(MatchesGridUpcoming);
