import React from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import styles from "./GenericTable.module.scss";

const GenericTable = ({ heading, rows }) => {

	return (
		<div className={styles.tableContainer}>
			{(heading) && (
				<h3 className={styles.heading}>{heading}</h3>
			)}
			<div className={styles.tableOverflow}>
				{(rows) && (
					<table className={styles.table}>
						<tbody>
							{
								rows.map((rowData, index) => (
									<Row key={index} data={rowData} />
								))
							}
						</tbody>
					</table>
				)}
			</div>
		</div>
	);
};

const Row = ({ data: { columns, isTableHeader, isBold } }) => (
	<>
		<tr className={isTableHeader ? styles.rowHeaderStyle : styles.defaultRowStyle}>
			{columns.map((columnData, index) => (
				<>
					<Column key={index} data={columnData} setFontWeight={isBold} />
				</>
			))}
		</tr>
	</>
);



const Column = ({ data, setFontWeight }) => (
	<>
		<td className={setFontWeight ? styles.isBold : ""} colSpan={data.columnSpan ? data.columnSpan : 1}> 
			{data.value}
		</td>
	</>
);

export default compose(
	withErrorHandling()
)(GenericTable);