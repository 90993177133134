import React from "react";
import { Text } from "~/patches/components";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import ArrowLink from "~/shared-components/ArrowLink";
import styles from "./TicketTextBlock.module.scss";

const TicketTextBlock = ({ fields }) => (
	<>
		{fields && (
			<div className={styles.TicketTextBlock}>
				<Text tag="h2" field={fields.heading} />
				<Text tag="p" field={fields.description} />
				<ArrowLink link={fields.link} linkText={fields.link.value.text} />
			</div>
		)}
	</>
);

export default compose(
	// other hocs goes here
	withErrorHandling()
)(TicketTextBlock);