import React from "react";
import { isExperienceEditorActive } from "@sitecore-jss/sitecore-jss-react";
import { membershipLevel } from "~/utilities/constants";

export function withMembershipVisibility() {
	return function withMembershipVisibilityHoc(WrappedComponent) {
		return function WithMembershipVisibility(props) {

			const { rendering: { visibleTo, requireLogin } } = props;
			const Component = <WrappedComponent {...props} />;
			const pageEditing = isExperienceEditorActive();

			// TODO: Should come from Gigya
			const usersMembershipLevel = membershipLevel.standard; // 0 = Standard, 1 = Premium, 2 = Family, 3 = Vip, 4 = Circle
			const isLoggedIn = false;

			if (visibleTo && visibleTo.length > 0 && !visibleTo.includes(usersMembershipLevel) && !pageEditing) return <></>;
			if (requireLogin && !isLoggedIn && !pageEditing) return <></>;

			return (
				<>
					{Component}
				</>
			);
		};
	};
}