import { useEffect, useState } from 'react';

import { screensizes } from "~/site-settings.json";
import { isServer } from "~/utilities";

export const useGetDeviceType = () => {
	const [deviceState, setDeviceState] = useState(
		{
			isMobile: false,
			isTablet: false,
			isDesktop: false,
		});

	const isDesktop = !isServer ? window.matchMedia(`(min-width: ${screensizes.sm})`).matches : false; // eslint-disable-line
	const isMobile = !isDesktop;
	const isTablet = !isServer ? window.matchMedia(`(min-width: ${screensizes.sm})`).matches && window.matchMedia(`(max-width: ${screensizes.lg})`).matches : false; // eslint-disable-line

	useEffect(() => {
		setDeviceState({ isMobile, isTablet, isDesktop });
	}, [isMobile, isTablet, isDesktop]);

	return deviceState;
};