exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ContactUs-module__ContactUs--1rC8F {\n  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.04)), to(rgba(0, 0, 0, 0)));\n  background: -o-linear-gradient(top, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0));\n  background: linear-gradient(180deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0)); }\n  .ContactUs-module__ContactUs--1rC8F .ContactUs-module__container--3vwRQ {\n    margin: auto;\n    max-width: 68.333rem;\n    padding: 0 2rem; }\n  .ContactUs-module__ContactUs--1rC8F h3 {\n    color: #212844;\n    font-size: 2.5rem;\n    font-weight: 700;\n    letter-spacing: 0.05em;\n    line-height: 1.12;\n    margin: 0;\n    padding-top: 5rem;\n    text-transform: uppercase; }\n  .ContactUs-module__ContactUs--1rC8F ul {\n    margin: 2rem 0 0 0; }\n\n@media (min-width: 1025px) {\n  .ContactUs-module__ContactUs--1rC8F .ContactUs-module__container--3vwRQ {\n    padding: 0 20px; }\n  .ContactUs-module__ContactUs--1rC8F h3 {\n    letter-spacing: 0.02em;\n    line-height: 1.3; }\n  .ContactUs-module__ContactUs--1rC8F ul {\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-pack: justify;\n        justify-content: space-between;\n    margin-top: 0; } }\n", ""]);

// exports
exports.locals = {
	"ContactUs": "ContactUs-module__ContactUs--1rC8F",
	"container": "ContactUs-module__container--3vwRQ"
};