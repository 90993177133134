/* eslint-disable */
import React, { Fragment } from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { Text } from "~/patches/components";
import styles from "./SectionLabel.module.scss";

const SectionLabel = ({ sectionLabel, pageEditing, plainText }) => {

	if (!sectionLabel && !pageEditing) {
		return <Fragment></Fragment>;
	}

	return (
		<div className={styles.SectionLabel}>
			{plainText ?
				<span>{sectionLabel}</span>
				:
				<span><Text field={sectionLabel}/></span>
			}
		</div>
	);
};

export default withSitecoreContext()(SectionLabel);