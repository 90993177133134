
import React from "react";
import { compose } from "recompose";
import { withErrorHandling } from "~/hoc";
import { optaApi } from "~/utilities/constants";
import styles from "./BroadcasterLinks.module.scss";


const BroadcasterLinks = ({ match, isInitialDoubleHeader, isListView, matchType,ticketMasterIcon }) => {
	const isP1 = matchType === "P1" || matchType === "P1Competition";
	return (
		<div className={`${styles.broadcastLinkContainer} ${isP1 ? styles.broadcastLinkContainerP1 : isListView ? styles.listView : !isInitialDoubleHeader ? styles.notATopDoubleHeader : ""} ${ticketMasterIcon ? styles.broadcastLinkOverride : ''}`}>
			{/* Broadcaster links */}
			{!!(match?.sitecoreData?.broadcasterLinks &&
				match?.sitecoreData?.broadcasterLinks.length &&
				match?.status !== optaApi.matchStatus.completed) && (
					<>
						{match.sitecoreData?.broadcasterLinks.map(broadcaster =>
							broadcaster?.webUrl?.value ?
								<a href={broadcaster.webUrl.value} target="_blank" rel="noopener noreferrer" className={`${styles.broadcastLink} ${isP1 && styles.broadcastLinkP1}`}>
									<img src={broadcaster.broadcasterLogo.value.src} alt={broadcaster.broadcasterLogo.value.alt ? broadcaster.broadcasterLogo.value.alt : "broadcast logo"} />
								</a>
								: broadcaster?.broadcasterLogo?.value?.src
									? <span className={`${styles.broadcastLink} ${isP1 && styles.broadcastLinkP1}`}>
										<img src={broadcaster.broadcasterLogo.value.src} alt={broadcaster.broadcasterLogo.value.alt ? broadcaster.broadcasterLogo.value.alt : "broadcast logo"} />
									</span>
									: ""
						)}
					</>
				)
			}
			{/*matchStub broadcasters*/}
			{!!(match?.broadcastLinks &&
				match?.broadcastLinks.length
				&& match.matchId && match.matchId.toString().includes("MatchStub") && match.status !== optaApi.matchStatus.completed) && (
					<>
						{match?.broadcastLinks.map(broadcaster =>
							broadcaster?.broadcastURLWeb ?
								<a href={broadcaster.broadcastURLWeb} target="_blank" rel="noopener noreferrer" className={`${styles.broadcastLink} ${isP1 && styles.broadcastLinkP1}`}>
									<img src={broadcaster.imageURL} alt={broadcaster.imageAltText ? broadcaster.imageAltText : "broadcast logo"} />
								</a>
								: broadcaster?.imageURL
									? <span className={`${styles.broadcastLink} ${isP1 && styles.broadcastLinkP1}`}>
										<img src={broadcaster.imageURL} alt={broadcaster.imageAltText ? broadcaster.imageAltText : "broadcast logo"} />
									</span>
									: ""
						)}
					</>
				)
			}
		</div>
	);
};

export default compose(
	withErrorHandling()
)(BroadcasterLinks);