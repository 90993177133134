exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SplitPage-module__splitPageMain--SOml7 {\n  background-position: center;\n  background-repeat: no-repeat;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-flow: row;\n      flex-flow: row; }\n  @media (max-width: 767px) {\n    .SplitPage-module__splitPageMain--SOml7 {\n      -ms-flex-flow: column;\n          flex-flow: column; } }\n  @media (min-width: 768px) {\n    .SplitPage-module__splitPageMain--SOml7.SplitPage-module__displayMobileOnly--14l4b {\n      display: none; } }\n  @media (max-width: 767px) {\n    .SplitPage-module__splitPageMain--SOml7.SplitPage-module__displayDesktopOnly--2j0xa {\n      display: none; } }\n  .SplitPage-module__splitPageMain--SOml7 .SplitPage-module__splitPageLeft--3qaIS {\n    -ms-flex-line-pack: center;\n        align-content: center;\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-direction: column;\n        flex-direction: column;\n    -ms-flex-pack: center;\n        justify-content: center;\n    padding: 1em 1em 1em 1em; }\n    @media (max-width: 767px) {\n      .SplitPage-module__splitPageMain--SOml7 .SplitPage-module__splitPageLeft--3qaIS {\n        width: 100% !important; } }\n  .SplitPage-module__splitPageMain--SOml7 .SplitPage-module__splitPageRight--18ocR {\n    -ms-flex-line-pack: center;\n        align-content: center;\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-direction: column;\n        flex-direction: column;\n    -ms-flex-pack: center;\n        justify-content: center;\n    padding: 1em 1em 1em 1em; }\n    @media (max-width: 767px) {\n      .SplitPage-module__splitPageMain--SOml7 .SplitPage-module__splitPageRight--18ocR {\n        width: 100% !important; } }\n", ""]);

// exports
exports.locals = {
	"splitPageMain": "SplitPage-module__splitPageMain--SOml7",
	"displayMobileOnly": "SplitPage-module__displayMobileOnly--14l4b",
	"displayDesktopOnly": "SplitPage-module__displayDesktopOnly--2j0xa",
	"splitPageLeft": "SplitPage-module__splitPageLeft--3qaIS",
	"splitPageRight": "SplitPage-module__splitPageRight--18ocR"
};