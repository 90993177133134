import React from "react";
import { Text, Link } from "~/patches/components";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import { Link as RouterLink } from "react-router-dom";
import styles from "./FourColumnLinks.module.scss";


const FourColumnLinks = ({ fields, rendering: { categoryLinks1, categoryLinks2, categoryLinks3, categoryLinks4 }}) => (
	<>
		{fields &&
			<div className={styles.FourColumnLinks}>
				<div className={styles.columns}>
					<div className={styles.column}>
						<div className={styles.heading}>
							{fields?.columnHeadingLink1?.value
								? <Link field={fields?.columnHeadingLink1}>
									<Text field={fields?.columnHeadingText1} />
								</Link>
								: <Text field={fields?.columnHeadingText1} />
							}
						</div>

						<ul className={styles.links}>
							{fields?.columnLinks1.length > 0 &&
							fields.columnLinks1.map((link, index) => (
								<li className={styles.link} key={`columnLinks1-${index}`}>
									<RouterLink to={link.url}>
										{link.name}
									</RouterLink>
								</li>
							))}

							{categoryLinks1.length > 0 &&
							categoryLinks1.map((link, index) => (
								<li className={styles.link} key={`categoryLinks1-${index}`}>
									<RouterLink  to={link.url} >
										<Text tag="span" field={link.title} />
									</RouterLink>
								</li>
							))}
						</ul>
					</div>

					<div className={styles.column}>
						<div className={styles.heading}>
							{fields?.columnHeadingLink2?.value
								? <Link field={fields?.columnHeadingLink2}>
									<Text field={fields?.columnHeadingText2} />
								</Link>
								: <Text field={fields?.columnHeadingText2} />
							}
						</div>

						<ul className={styles.links}>
							{fields?.columnLinks2.length > 0 &&
							fields.columnLinks2.map((link, index) => (
								<li className={styles.link} key={`columnLinks2-${index}`}>
									<RouterLink to={link.url}>
										{link.name}
									</RouterLink>
								</li>
							))}

							{categoryLinks2.length > 0 &&
							categoryLinks2.map((link, index) => (
								<li className={styles.link} key={`categoryLinks2-${index}`}>
									<RouterLink  to={link.url} >
										<Text tag="span" field={link.title} />
									</RouterLink>
								</li>
							))}
						</ul>
					</div>

					<div className={styles.column}>
						<div className={styles.heading}>
							{fields?.columnHeadingLink3?.value
								? <Link field={fields?.columnHeadingLink3}>
									<Text field={fields?.columnHeadingText3} />
								</Link>
								: <Text field={fields?.columnHeadingText3} />
							}
						</div>

						<ul className={styles.links}>
							{fields?.columnLinks3.length > 0 &&
							fields.columnLinks3.map((link, index) => (
								<li className={styles.link} key={`columnLinks3-${index}`}>
									<RouterLink to={link.url}>
										{link.name}
									</RouterLink>
								</li>
							))}

							{categoryLinks3.length > 0 &&
							categoryLinks3.map((link, index) => (
								<li className={styles.link} key={`categoryLinks3-${index}`}>
									<RouterLink  to={link.url} >
										<Text tag="span" field={link.title} />
									</RouterLink>
								</li>
							))}
						</ul>
					</div>

					<div className={styles.column}>
						<div className={styles.heading}>
							{fields?.columnHeadingLink4?.value
								? <Link field={fields?.columnHeadingLink4}>
									<Text field={fields?.columnHeadingText4} />
								</Link>
								: <Text field={fields?.columnHeadingText4} />
							}
						</div>

						<ul className={styles.links}>
							{fields?.columnLinks4.length > 0 &&
							fields.columnLinks4.map((link, index) => (
								<li className={styles.link} key={`columnLinks4-${index}`}>
									<RouterLink to={link.url}>
										{link.name}
									</RouterLink>
								</li>
							))}

							{categoryLinks4.length > 0 &&
							categoryLinks4.map((link, index) => (
								<li className={styles.link} key={`categoryLinks4-${index}`}>
									<RouterLink  to={link.url} >
										<Text tag="span" field={link.title} />
									</RouterLink>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		}
	</>
);

export default compose(
	// other hocs goes here
	withErrorHandling()
)(FourColumnLinks);