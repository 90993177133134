import React from "react";
import { Link } from "react-scroll";

/**
 *
 * @param {{activeClass: string, to: string, spy: boolean, smoothe: boolean|string, hashSpy: boolean isDynamic: boolean offset: number, delay: number, onSetActive: callback duration: number}} props
 */
export const ScrollLink = props => (
	<Link
		activeClass={props.activeClass}
		to={props.to}
		spy={props.spy || false}
		smooth={props.smooth || "easeInOutCubic"}
		hashSpy={props.hashSpy || true} // this working on route change as it is not triffering scroll. But keept on to handle route change hash reset
		isDynamic={props.isDynamic || false}
		offset={props.offset || 0}
		delay={props.delay || 0}
		onSetActive={props.onSetActive}
		duration={props.duration || 500}>
		{props.children}
	</Link>
);
