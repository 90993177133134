import React from "react";
import { NavLink } from "react-router-dom";
import { compose } from "recompose";
import { withNamespaces } from "react-i18next";
import Modal from "~/shared-components/Modal";
import styles from "./LogoutModal.module.scss";
import { withGigya } from "~/hoc";
import { knownRoutes } from "~/utilities/constants";

//requires a boolean value for showModal and a function for hideModal that sets state for parent to false causing the modal to close

const LogoutModal = ({
	showModal,
	onClose,
	t,
	gigya: { logout }
}) => (
	<Modal active={showModal} type={"logout"}>
		<div className={styles.LogoutModal}>
			<h2 className={styles.text}>{t("logout_confirmation")}</h2>

			<div className={styles.buttonContainer}>
				<button className={styles.button} onClick={onClose}>
					{t("logout_no")}
				</button>

				<NavLink to={knownRoutes.home}>
					<div className={styles.button} onClick={() => logout()}>
						{t("logout_yes")}
					</div>
				</NavLink>
			</div>
		</div>
	</Modal>
);

export default compose(
	withNamespaces(),
	withGigya()
)(LogoutModal);