import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Text, Image} from "~/patches/components";
import styles from "./TeamThumbnail.module.scss";

const TeamThumbnail = ({ url, teamName, thumbnailImage }) => (
	<div className={styles.TeamThumbnail}>
		{url && (
			<RouterLink to={url}>
				<Image imageParams={ {mw: 680} } field={thumbnailImage} />
				<div className={styles.content}>
					<Text tag="span" field={teamName} />
				</div>
			</RouterLink>
		)}
	</div>
);

export default TeamThumbnail;