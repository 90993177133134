import React, { PureComponent } from "react";
import { compose } from "recompose";
import TagManager from 'react-gtm-module';
import { withNamespaces } from "react-i18next";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import 'moment-timezone';
import { withGigya } from "~/hoc";
import GigyaScreenSet from "~/shared-components/GigyaScreenSet";
import { BrazeService } from "~/services/brazeService";
import styles from "./Billing.module.scss";
import CheckoutForm from "~/shared-components/CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

class Billing extends PureComponent {
	constructor(props) {
		super(props);

		const {
			fields,
			sitecoreContext: {
				membershipLevels
			}
		} = this.props;

		const billingTierLevel = fields.membershipLevel?.fields?.level?.value;
		const formSubmitLink = fields.confirmationLink?.value;

		this.state = {
			clientSecret: null,
			errorMessage: null,
			isError: false,
			isProRate: false,
			proRateCredit: 0,
			proRatePayment: billingTierLevel > 0 ? parseInt(membershipLevels[billingTierLevel]?.price?.value.replace(',', '').slice(1)) : 0,
			insiderAccountRegistrationGTMCalled: false,
			isSuccess: false,
			isAlreadyReceivingBenefits: false,
			isLoading: false,
			submitRedirectLink: formSubmitLink,
			showCardOnFileRadioSet: false,
			useCardOnFile: false,
			cardBrand: null,
			cardLast4: null,
			showBillingInformation: true,
			stripePromise: null,
			preVerificationGTMCalled: false
		};
	}

	componentDidMount = async () => {
		const { fields, gigya: { isLoggedIn, data }, sitecoreContext: { stripe: { apiKey } }, updateBillingInfo } = this.props;
		if (this.state.stripePromise === null) {
			const stripePromise = loadStripe(apiKey);
			this.setState({ stripePromise });
		}

		if (isLoggedIn && !updateBillingInfo && data?.membershipTier >= fields?.membershipLevel?.fields?.level?.value) {
			this.setState({ isAlreadyReceivingBenefits: true });
		}
	}

	componentDidUpdate = prevProps => {
		const { gigya: prevGigya } = prevProps;
		const { fields, gigya: { isLoggedIn, data, profile, UID }, updateBillingInfo } = this.props;

		if (isLoggedIn && typeof window !== 'undefined') {
			BrazeService.changeUser(profile?.email, UID);
		}

		if (prevGigya.data !== data && isLoggedIn && !updateBillingInfo && data?.membershipTier >= fields?.membershipLevel?.fields?.level?.value) {
			this.setState({ isAlreadyReceivingBenefits: true });
		}
	}

	setGTMRegistrationOrUpgradeEvent = (tier, isUpgrade = false) => {
		let eventName = '';
		const tierString = tier.toString();

		switch (tierString) {
			case '0':
				eventName = 'insiderStandard';
				break;
			case '1':
				eventName = 'insiderPremium';
				break;
			case '2':
				eventName = 'insiderPremiumFamily';
				break;
			case '3':
				eventName = 'insiderVip';
				break;
			case '4':
				eventName = 'insiderCircle';
				break;
			case '5':
				eventName = 'insiderSupporter';
				break;
			case '6':
				eventName = 'insiderCaptain';
				break;
			case '7':
				eventName = 'insiderCoach';
				break;
			case '8':
				eventName = 'insiderPresident';
				break;
		}

		if (isUpgrade) {
			eventName = `${eventName}Upgrade`;
		} else {
			eventName = `${eventName}Registration`;
		}

		const insiderAccountRegistrationOrUpgradeArgs = {
			dataLayer: {
				event: eventName,
				tier: tierString
			}
		};

		TagManager.dataLayer(insiderAccountRegistrationOrUpgradeArgs);
	}

	showErrorsInDialog = error => {
		this.setState({
			disabled: false,
			errorMessage: error.message,
			isError: true,
			isLoading: false
		});
	}

	gigyaHandlers = {
		onAfterSubmit: event => this.gigyaOnAfterSubmitCallback(event),
		onAfterValidation: event => {
			if (Object.entries(event.formErrors).length > 0) {
				this.showErrorsInDialog({ message: this.props.t("billingmodal_address-validation-error") });
			}
			else {
				this.setState({ isError: false });
			}
		}
	};

	gigyaHandlersOnRegistration = {
		onAfterSubmit: event => {
			if (event?.response?.errorCode !== 0) {
				return;
			}

			if (!this.state.insiderAccountRegistrationGTMCalled) {
				// Always send '0', Standard, for the part of the registration flow where
				// the credit card hasn't been entered yet, regardless of the selected tier
				this.setGTMRegistrationOrUpgradeEvent('0');
				this.setState({ insiderAccountRegistrationGTMCalled: true });
			}
		},
		onSubmit: () => {
			this.setState({ disabled: true });
			if (!this.state.preVerificationGTMCalled) {
				const gigyaPreVerificationArgs = {
					dataLayer: {
						event: 'gigyaPreVerificationRecordCreated'
					}
				};

				TagManager.dataLayer(gigyaPreVerificationArgs);
				this.setState({ preVerificationGTMCalled: true });
			}
		}
	};

	gigyaAddressScreenSetDivLocator = domNode => {
		this.gigyaAddressScreenSetDivDOMNode = domNode;
	}

	// SUBMIT STEP 1 - bound to the submit.click()
	submit = async event => {
		event?.preventDefault();
		const gigyaAddressSubmitButton = this.gigyaAddressScreenSetDivDOMNode.getElementsByClassName('gigya-input-submit')[0];
		if (gigyaAddressSubmitButton) {
			this.setState({ isLoading: true, disabled: true });
			await gigyaAddressSubmitButton.click();	 // this simulates clicking the Gigya Screen-Set Submit button
		}

		return this.state.isError;
	}

	// SUBMIT STEP 2 - bound to the onAfterSubmit callback of the Gigya Screen-Set,
	// invoked async via gigyaAddressSubmitButton.click() in the submit method above.
	gigyaOnAfterSubmitCallback = async event => {
		if (event?.response?.errorCode !== 0) {
			this.setState({ isLoading: false });
			// no need to set errors, the Gigya Screen-Set provides validation feedback
			return;
		}
	}

	handleOptionChange = event => {
		if (event.target.value === "1") {
			this.setState({ showBillingInformation: false });
			this.setState({ useCardOnFile: true });
		} else if (event.target.value === "0") {
			this.setState({ showBillingInformation: true });
			this.setState({ useCardOnFile: false });
		} else {
			this.setState({ showBillingInformation: false });
			this.setState({ useCardOnFile: true });
		}
	}

	render() {
		const {
			fields,
			gigya: { isLoggedIn, data },
			t
		} = this.props;

		const {
			proRatePayment,
			isSuccess,
			isAlreadyReceivingBenefits,
			useCardOnFile,
			showCardOnFileRadioSet,
			cardBrand,
			cardLast4,
			stripePromise
		} = this.state;

		const selectedTier = fields.membershipLevel?.fields?.level?.value;

		const amount = Math.ceil(proRatePayment * 100);
		const options = {
			mode: 'payment',
			amount,
			currency: 'usd',
			setupFutureUsage: 'off_session',
			paymentMethodTypes: [
				"card",
				"us_bank_account"
			]
		};

		return (
			<div className={styles.Billing}>
				{isLoggedIn
					? <>
						{!isAlreadyReceivingBenefits && !isSuccess
							? <>
								<div className={styles.addressInfo}>
									<div className={styles.formHeading}>
										<span>{t("billingmodal_address-info")}</span>
										<span>{t("billingmodal_address-info")}</span>
									</div>
									<GigyaScreenSet
										screenSetId={"Default-ProfileUpdate"}
										startScreenId={"shipping-address-screen"}
										handlers={this.gigyaHandlers}
										domNodeLocator={this.gigyaAddressScreenSetDivLocator} />
								</div>
								{showCardOnFileRadioSet ?
									<>
										<div className={styles.cardInfo}>
											<div className={styles.formHeading}>
												<span>&nbsp;</span>
												<span>Card on file: &nbsp;&nbsp;</span>
												<span className={styles.cardOnFile}>{cardBrand} <span className={styles.last4}> -ending in</span> {cardLast4}</span>
											</div>
											<form>
												<div>
													<label className={styles.containRadioInput}>
														Use card on file
														<input type="radio" name="useFileCard" onChange={this.handleOptionChange} value="1" checked={useCardOnFile} className={styles.radioInput} />
														<span className={styles.radioDot}></span>
													</label>
												</div>
												<div>
													<label className={styles.containRadioInput}>
														Enter new card information
														<input type="radio" name="useFileCard" onChange={this.handleOptionChange} value="0" checked={!useCardOnFile} className={styles.radioInput} />
														<span className={styles.radioDot}></span>
													</label>
												</div>
											</form>
										</div>
									</>
									:
									<>
									</>
								}
							</>
							: !isSuccess
								? data?.membershipTier === 0
									?
									<>
										<div className={`${styles.alertCard} ${styles.success}`}>
											<h2>{t("billingmodal_signup-success")}</h2>
										</div>
										<a className={styles.viewProfileButton} href="/profile/my-profile">view profile</a>
									</>
									: <>
										<div className={`${styles.alertCard} ${styles.success}`}>
											<h2>{t("billingmodal_already-receiving-benefits")}</h2>
										</div>
										<a className={styles.viewProfileButton} href="/profile/my-profile">view profile</a>
									</>
								: ""}
					</>
					: <GigyaScreenSet
						handlers={this.gigyaHandlersOnRegistration}
						screenSetId={"USSF-RegistrationLogin"}
						startScreenId={"gigya-register-screen"}
					/>
				}
				{stripePromise && options && selectedTier > 0 &&
					<Elements stripe={stripePromise} options={options}>
						<CheckoutForm 
							billingProps={{ ...this.props, selectedTier }} 
							state={{ ...this.state }} 
							setState={this.setState.bind(this)} 
							gigyaSubmitHandler={this.submit.bind(this)}
							GTMHandler={this.setGTMRegistrationOrUpgradeEvent.bind(this)}
						/>
					</Elements>
				}
			</div>
		);
	}
}

export default compose(
	withGigya(),
	withNamespaces(),
	withSitecoreContext(),
)(Billing);