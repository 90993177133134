exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".CompetitionMatchList-module__competitionMatchList--1ZzSZ {\n  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.04)), to(rgba(0, 0, 0, 0)));\n  background: -o-linear-gradient(top, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0));\n  background: linear-gradient(180deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0));\n  font-size: 1.7rem;\n  line-height: 2.4rem; }\n\n.CompetitionMatchList-module__sectionLabelContainer--15wmM {\n  margin: 0 auto 2rem;\n  max-width: 1440px;\n  padding: 0 2rem;\n  width: 100%; }\n\n.CompetitionMatchList-module__text--1Dfq_ {\n  color: #C2002F;\n  font-size: 2rem;\n  margin: 10rem auto;\n  text-align: center;\n  text-transform: uppercase; }\n\n@media (min-width: 1025px) {\n  .CompetitionMatchList-module__sectionLabelContainer--15wmM {\n    margin-bottom: 2.5rem;\n    padding: 0 8.333rem; } }\n\n@media (min-width: 1440px) {\n  .CompetitionMatchList-module__sectionLabelContainer--15wmM {\n    max-width: 1640px; } }\n", ""]);

// exports
exports.locals = {
	"competitionMatchList": "CompetitionMatchList-module__competitionMatchList--1ZzSZ",
	"sectionLabelContainer": "CompetitionMatchList-module__sectionLabelContainer--15wmM",
	"text": "CompetitionMatchList-module__text--1Dfq_"
};