import React from "react";
import { isExperienceEditorActive } from "@sitecore-jss/sitecore-jss-react";
import { Image as SitecoreImage } from "./OriginalImage";
import config from "~/temp/config";
import { getIEVersion } from "~/utilities";

/**
 * This Image handler is an extension of sitecore image.
 *
 * @param {object} props - Component props
 */
export const CustomImage = (props) => {
	if (isExperienceEditorActive()) {
		return <SitecoreImage {...props} />;
	}

	const newProps = props;
	if (props.field?.value?.src) {
		if (config.mode === "connected" && !props.field.value.src.startsWith("http")) {
			newProps.field.value.src = config.sitecoreApiHost + props.field.value.src;
		}
	}

	if (getIEVersion() <= 11 && props.field?.value) {
		return <img
			src={newProps.field.value.src}
			alt={newProps.field.value.alt}
			title={newProps.field.value.title}
			className={newProps.className}
			height={newProps.field.value.height}
			width={newProps.field.value.width} />;
	}

	return <SitecoreImage {...newProps} height="" width="" />;
};