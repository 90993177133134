exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PlayerCarousel-module__container--3cXSy {\n  margin-bottom: 5rem; }\n  .PlayerCarousel-module__container--3cXSy .flickity-viewport {\n    margin: 0 auto; }\n    .PlayerCarousel-module__container--3cXSy .flickity-viewport:before, .PlayerCarousel-module__container--3cXSy .flickity-viewport:after {\n      background: url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 100' preserveAspectRatio='none'%3E%3Cpolygon fill='%23FFFFFF' points='10,100 0,100 0,0 10,0 1,50'/%3E%3C/svg%3E\") left center/100% 100%;\n      bottom: 0;\n      content: '';\n      left: 0;\n      position: absolute;\n      top: 0;\n      width: 1.2rem;\n      z-index: 2; }\n    .PlayerCarousel-module__container--3cXSy .flickity-viewport:after {\n      left: auto;\n      right: 0;\n      -webkit-transform: rotate(180deg);\n              transform: rotate(180deg); }\n  .pageEditing .PlayerCarousel-module__container--3cXSy .PlayerCarousel-module__item--2nrHp {\n    width: 100%; }\n\n.PlayerCarousel-module__item--2nrHp {\n  padding: 0 1.3rem 0;\n  width: 50%; }\n  @media (min-width: 480px) {\n    .PlayerCarousel-module__item--2nrHp {\n      width: 50%; } }\n  @media (min-width: 768px) {\n    .PlayerCarousel-module__item--2nrHp {\n      width: 33.333%; } }\n  @media (min-width: 992px) {\n    .PlayerCarousel-module__item--2nrHp {\n      width: 25%; } }\n  @media (min-width: 1025px) {\n    .PlayerCarousel-module__item--2nrHp {\n      width: 20%; } }\n  @media (min-width: 1440px) {\n    .PlayerCarousel-module__item--2nrHp {\n      width: 16.666%; } }\n\n@media (min-width: 1025px) {\n  .PlayerCarousel-module__container--3cXSy:before, .PlayerCarousel-module__container--3cXSy:after {\n    width: 1rem; }\n  .PlayerCarousel-module__item--2nrHp {\n    padding: 0 1.08rem 0; } }\n", ""]);

// exports
exports.locals = {
	"container": "PlayerCarousel-module__container--3cXSy",
	"item": "PlayerCarousel-module__item--2nrHp"
};