import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import styles from "../MultiLevelMenu.module.scss";
import { compose } from "recompose";
import { withErrorHandling } from "~/hoc";
import { withNamespaces } from "react-i18next";
import Link from "../Link";
import { hasSubMenuItems } from "../helpers";
import PrevButton from "../PrevButton";
import LinkName from "../LinkName";
import LogoutButton from "~/shared-components/LogoutButton";

const MenuLevel = props => {
	const { state, setState, childs, currentPage, prevMenu, renderLogout } = props;
	let  { level, index } = props;
	level = level || 0;
	index = index || 0;
	const menuLevel = `level-${level}-${index}`;

	if (!childs) return <Fragment key={index}></Fragment>;

	return (
		<Fragment key={index}>
			<ul className={`${menuLevel} ${level !== 0 ? styles.lowerLevelMenu : ''}`}>
				{currentPage && currentPage.id && (
					<li>
						{currentPage.url ? (
							<NavLink
								id={`link-${currentPage.id}`}
								activeClassName={styles.active}
								exact
								to={currentPage.url}>
								<LinkName link={currentPage.sidebarMenuTitle} fallbackLinkName={currentPage.menuTitle} />
							</NavLink>
						) : (
							<LinkName link={currentPage.sidebarMenuTitle} fallbackLinkName={currentPage.menuTitle} />
						)}
					</li>
				)}
				{prevMenu && (
					<li className={styles.goToPrev}>
						<PrevButton {...{
							setState,
							prevMenu,
							menuLevel,
							goNext: false,
							goBack: true
						}} />
					</li>
				)}
				{childs.map((item, index) => {
					// TODO (tyler): remove temp fix (hiding "More Teams" and "Youth Teams" from Stats sidebar nav) when data is made available on those pages. The issue is that sidebar nav doesn't know if the route pages have data or not. "More Teams" and "Youth Teams" have submenu items with routes, but all of those route pages are missing data which is not something submenu nav has means of knowing. I think the most logical solution here is to not author links if the pages themselve don't have data/content.
					if (item?.url ||
						(!item?.url &&
							hasSubMenuItems(item) &&
							item?.sidebarMenuTitle?.value !== "More Teams" &&
							item?.sidebarMenuTitle?.value !== "Youth Teams")) {
						return (
							<li key={index}>
								<Link {...{
									setState,
									link: item,
									level,
									index,
									currentMenu: menuLevel}}/>
							</li>
						);}
				})}
				{renderLogout && <LogoutButton />}
			</ul>
			{childs.map((item, index) => (
				<Fragment key={index}>
					{hasSubMenuItems(item) && (
						<MenuLevel
							state={state}
							setState={setState}
							childs={item.links}
							level={level + 1}
							index={index}
							key={index}
							prevMenu={{id: item.id, menuLevel, menuTitle: item.menuTitle, url: item.url, renderAsLink: item.shouldLinkToSelf}}
						/>
					)}
				</Fragment>
			))}

		</Fragment>
	);
};

export default compose(
	withNamespaces(),
	withErrorHandling()
)(MenuLevel);