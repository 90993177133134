import React, { useState, useEffect } from "react";
import {
	Placeholder,
	withSitecoreContext
} from "@sitecore-jss/sitecore-jss-react";
import { withErrorHandling } from "~/hoc";
import RedesignSection from "../../shared-components/RedesignSection";
import { compose } from "recompose";
import styles from "./RedesignCarousel.module.scss";
import { CarouselRedesign } from "~/shared-components/Carousel";

function RedesignCarousel({
	fields,
	rendering,
	sitecoreContext: { pageEditing }
}) {
	// eslint-disable-next-line no-restricted-globals
	const [isMobile, setIsMobile] = useState(typeof window !== 'undefined' && window.innerWidth <= 1024);

	useEffect(() => {
		function handleResize() {
			// eslint-disable-next-line no-restricted-globals
			setIsMobile(typeof window !== 'undefined' && window.innerWidth <= 1024);
		}

		// eslint-disable-next-line no-restricted-globals
		window.addEventListener("resize", handleResize);
		// eslint-disable-next-line no-restricted-globals
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<>
			{fields && (
				<div className={styles.carousel}>
					<RedesignSection
					title={fields?.title?.value}
					link={fields?.link?.value?.href}
					linkText={fields?.linkText?.value}
					padding={isMobile ? 0 : fields?.heightPadding?.value}
					subHead={fields?.subHead?.value}
					background={fields?.background?.value}
					columns={fields?.columns?.value}
					desktopImage={fields?.backgroundImageDesktop?.value?.src}
					mobileImage={fields.backgroundImageMobile?.value?.src}
					backgroundCover={fields?.backgroundCover?.value}
				>
					<CarouselRedesign>
						<Placeholder
							name="jss-titled-section"
							rendering={rendering}
							render={components => (
								<>
									{pageEditing ? (
										<div>
											Add content
											{components}
										</div>
									) : (
										<>
											{components?.map((child, index) => (
												<div className={styles.item} key={index}>
													{child}
												</div>
											))}
										</>
									)}
								</>
							)}
						/>
					</CarouselRedesign>
				</RedesignSection>
				</div>
			)}
		</>
	);
}

export default compose(
	// other hocs goes here
	withSitecoreContext(),
	withErrorHandling()
)(RedesignCarousel);
