import React from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import styles from "./LatestStoryThumbLarge.module.scss";
import moment from "moment/moment";

const LatestStoryThumbLarge = ({story}) => {
	const wrapStyles = {};
	if (story?.ThumbnailImage) {
		wrapStyles.backgroundImage = `url(${story.ThumbnailImage})`;
	}

	return (
		<div className={styles.wrap} style={wrapStyles}>
			<a className={styles.inner} href={story.Url}>
				<div className={styles.meta}>
					{story?.Category && (
						<div className={styles.category}>{story.Category}</div>
					)}
					<div className={styles.postedDate}>
						{moment(story.PostedDate).format("MMMM D, YYYY")}
					</div>
				</div>
				<h3 className={styles.title}>{story.Title}</h3>
			</a>
		</div>
	);
};

export default compose(
	withErrorHandling()
)(LatestStoryThumbLarge);
