import React from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { withErrorHandling, withSectionHeader } from "~/hoc";
import { compose } from "recompose";
import { Carousel } from "~/shared-components";
import GalleryThumbnail from "~/shared-components/GalleryThumbnail";
import styles from "./GalleryCarousel.module.scss";
import HelperCommentEdit from "~/experience-editor/HelperCommentEdit";
import { getGalleryThumbnailImages } from "~/utilities/helpers";

/**
 * Function to determine if the setion header should render.
 * @param {object} props
 */
function isVisible(props) {
	const {rendering: { galleryPages }, sitecoreContext: { pageEditing }} = props;

	if (pageEditing) return { visible: true };

	if (!galleryPages || galleryPages?.length === 0) return { visible: false };

	return {
		visible: true
	};
}

const GalleryCarousel = ({ sitecoreContext: { pageEditing }, rendering: { galleryPages } }) => (
	<section className={styles.GalleryCarousel}>
		{/* If in experience editor render this section */}
		{pageEditing ? (
			<section className="grid gridContainer">
				{galleryPages.length === 0 && (
					<HelperCommentEdit endingEditComment="and select the desired gallery pages (make sure only Gallery Page Types are selected)" />
				)}
				{galleryPages.length > 0 && (
					<div className="row">
						{galleryPages.map((item, index) => (
							<div key={index} className={`col col--xs-6 col--md-4 ${styles.columns}`}>
								<div className={styles.item}>
									<GalleryThumbnail key={index}
										{...{
											heading: item.heading,
											categoryValue: item.category?.value,
											categoryKey: item.categoryKey,
											date: item.date,
											images: getGalleryThumbnailImages(item.galleryItems),
											url: item.url
										}} />
								</div>
							</div>
						))}
					</div>
				)}
			</section>
		) : (
			galleryPages?.length > 0 && (
				<div className="grid gridContainer">
					<div className="row">
						<div className="col">
							<Carousel>
								{galleryPages.map((item, index) => (
									<div key={index} className={styles.item}>
										<GalleryThumbnail
											{...{
												heading: item.heading,
												categoryValue: item.category?.value,
												categoryKey: item.categoryKey,
												date: item.date,
												images: getGalleryThumbnailImages(item.galleryItems),
												url: item.url
											}} />
									</div>
								))}
							</Carousel>
						</div>
					</div>
				</div>
			)
		)}
	</section>
);

export default compose(
	// other hocs goes here
	withSitecoreContext(),
	withSectionHeader(isVisible),
	withErrorHandling()
)(GalleryCarousel);