import { FetchService } from "./fetchService";

export class MatchService {
	// possible to add another method here depending on return object
	
	static getScoreboardByContestants(contestantIds) {
		return FetchService.get(`/api/scoreboard/contestant/${contestantIds}`);
	}
	static getScoreboardByMatches(matchIds) {
		return FetchService.get(`/api/scoreboard/match/${matchIds}`);
	}

	static getMatch(matchId) {
		return FetchService.get(`/api/matches/${matchId}`);
	}

	static getTeamUpcomingMatches(contestantId) {
		return FetchService.get(`/api/matches/upcoming/contestant/${contestantId}`);
	}

	static getTeamResultsMatches(contestantId, year) {
		return FetchService.get(`/api/matches/results/contestant/${contestantId}/${year}`);
	}

	static getTournamentUpcomingMatches(tournamentId) {
		return FetchService.get(`/api/matches/upcoming/tournament/${tournamentId}`);
	}

	static getTournamentResultsMatches(tournamentId, year) {
		return FetchService.get(`/api/matches/results/tournament/${tournamentId}/${year}`);
	}

	static getPresaleMatches() {
		return FetchService.get(`/api/presale`);
	}
}