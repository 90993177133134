import React from "react";
import { withErrorHandling } from "~/hoc/withErrorHandling";
import { compose } from "recompose";
import { withNamespaces } from "react-i18next";
import ArrowLink from "~/shared-components/ArrowLink";

const TagLink = ({link, t}) =>  (
	<ArrowLink link={{ value: { href: link, text: t("arrow_link_view_all")}	}} />
);


export default compose(
	withNamespaces(),
	withErrorHandling()
)(TagLink);