exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Trophies-module__Trophies--1KVrk {\n  margin: 5rem auto 4.5rem;\n  overflow: visible; }\n\n.Trophies-module__Trophies--1KVrk .flickity-viewport:hover .Trophies-module__trophyItem--2bG8Z {\n  opacity: 0.5; }\n\n.Trophies-module__trophyItem--2bG8Z {\n  font-size: 1.44rem;\n  letter-spacing: 0.05em;\n  opacity: 1;\n  padding: 0 1rem;\n  text-align: center;\n  text-transform: uppercase;\n  -webkit-transition: opacity 200ms ease;\n  -o-transition: opacity 200ms ease;\n  transition: opacity 200ms ease;\n  width: 50%; }\n  .Trophies-module__trophyItem--2bG8Z img {\n    height: 20.8rem;\n    margin: 0 auto 1rem;\n    max-width: none;\n    width: auto; }\n  .Trophies-module__trophyItem--2bG8Z span {\n    display: block;\n    padding: 0 2rem; }\n  .Trophies-module__trophyItem--2bG8Z:hover {\n    opacity: 1 !important; }\n    .Trophies-module__trophyItem--2bG8Z:hover span {\n      color: #C2002F; }\n\n@media (min-width: 768px) {\n  .Trophies-module__Trophies--1KVrk {\n    max-width: 100%; }\n  .Trophies-module__trophyItem--2bG8Z {\n    width: 33.33%; } }\n\n@media (min-width: 992px) {\n  .Trophies-module__Trophies--1KVrk {\n    width: calc(100% - 16.67rem); } }\n\n@media (min-width: 1025px) {\n  .Trophies-module__Trophies--1KVrk {\n    max-width: 1440px;\n    padding: 0 1rem; }\n  .Trophies-module__trophyItem--2bG8Z {\n    margin: 0;\n    padding: 0;\n    width: 20%; } }\n\n@media (min-width: 1440px) {\n  .Trophies-module__Trophies--1KVrk {\n    padding: 0; } }\n", ""]);

// exports
exports.locals = {
	"Trophies": "Trophies-module__Trophies--1KVrk",
	"trophyItem": "Trophies-module__trophyItem--2bG8Z"
};