/* eslint-disable no-restricted-globals */
import React, { Component } from "react";
import { withScroll, withErrorHandling } from "~/hoc";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { isServer } from "~/utilities";
import { compose } from "recompose";
import styles from "./Parallaxer.module.scss";

class Parallaxer extends Component {

	constructor(props) {
		super(props);
		const { sitecoreContext: { pageEditing } } = props;

		this.state = {
			disabled: isServer || pageEditing
		};

		if (!this.state.disabled) {
			this.parallaxElmRef = React.createRef();
			this.viewportHeight = window.innerHeight;
			this.scrollAmount = 0;
		}
	}

	componentDidMount() {
		this.scrollAmount = this.setScroll();
		this.forceUpdate();
	}

	componentDidUpdate() {
		this.scrollAmount = this.setScroll();
	}

	setScroll() {
		let scrollAmount = this.scrollAmount;

		if (!this.state.disabled) {
			const { rate, reverse, bleed = 0 } = this.props;

			const bounding = this.parallaxElmRef.current.getBoundingClientRect();
			const isInViewport =  bounding.top >= 0 - bounding.height - bleed && bounding.bottom <= (window.innerHeight  || document.documentElement.clientHeight) + bounding.height + bleed;

			if (this.parallaxElmRef !== null && isInViewport) {
				const percentageScrolled = 1 - (bounding.top / this.viewportHeight);
				scrollAmount = (rate * percentageScrolled * (reverse ? -1 : 1)).toFixed(2);
			}
		}
		return scrollAmount;
	}

	render() {
		const { children } = this.props;

		if (this.state.disabled) {
			return (
				<div>
					{children}
				</div>
			);
		}

		return (
			<div className={styles.Parallaxer} ref={this.parallaxElmRef} style={{ transform: `translate3d(0, ${this.scrollAmount}px, 0)` }}>
				{children}
			</div>
		);
	}
}


export default compose(
	withSitecoreContext(),
	withScroll(),
	withErrorHandling()
)(Parallaxer);