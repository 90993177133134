exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".MatchList-module__MatchList--3YhMa {\n  display: block;\n  margin-bottom: 5rem; }\n\n.MatchList-module__viewMoreContainer--mxnRd {\n  font-size: 1rem; }\n", ""]);

// exports
exports.locals = {
	"MatchList": "MatchList-module__MatchList--3YhMa",
	"viewMoreContainer": "MatchList-module__viewMoreContainer--mxnRd"
};