exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".StatsOverview-module__teamTitle--PwhUD {\n  color: #212844;\n  font-size: 1.8rem;\n  font-weight: 700;\n  letter-spacing: 0.05rem;\n  line-height: 1.278;\n  margin-bottom: 2rem;\n  text-transform: uppercase; }\n\n.StatsOverview-module__teamContainer--3YIcx {\n  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.04)), to(rgba(0, 0, 0, 0)));\n  background: -o-linear-gradient(top, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0));\n  background: linear-gradient(180deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0));\n  border-top: 0.1rem solid rgba(0, 0, 0, 0.07);\n  padding-top: 4.8rem; }\n\n@media (min-width: 1025px) {\n  .StatsOverview-module__teamTitle--PwhUD {\n    line-height: 1.296;\n    margin-bottom: 2.083rem; }\n  .StatsOverview-module__teamContainer--3YIcx {\n    border-top: 0.084rem solid rgba(0, 0, 0, 0.07);\n    padding-top: 4rem; } }\n", ""]);

// exports
exports.locals = {
	"teamTitle": "StatsOverview-module__teamTitle--PwhUD",
	"teamContainer": "StatsOverview-module__teamContainer--3YIcx"
};