exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".AdBlock-module__adContainer--3ZT-j {\n  margin-bottom: 20px; }\n\n.AdBlock-module__adBlock--2qDHU:not(:empty) {\n  height: 250px;\n  margin: 0 auto 20px;\n  width: 300px; }\n", ""]);

// exports
exports.locals = {
	"adContainer": "AdBlock-module__adContainer--3ZT-j",
	"adBlock": "AdBlock-module__adBlock--2qDHU"
};