/* eslint-disable no-restricted-globals */

/**
 * Idea from: https://stackoverflow.com/a/7627603
 *
 * @param string
 * @returns {*}
 */
export const makeSafeForCss = string => {
	return string
		.toLowerCase()
		// This will replace the first slash since all URLs start with it.
		.replace('/', '')
		// All other slashes indicate a "section" so we will replace them with
		// double dash.
		.replaceAll('/', '--')
		// Normalize the rest of the URL, so it can be used as a CSS class.
		.replaceAll(/[^a-z0-9]/g, s => {
			const c = s.charCodeAt(0);
			if (c === 32 || c === 45) return `-`;
			if (c >= 65 && c <= 90) return s.toLowerCase();

			return ``;
		});
};

/**
 * Get body class names for the current URL.
 *
 * @returns {*[]}
 */
export default function getBodyClassNames() {
	const classes = [];

	if (window !== undefined) {
		let excludeUrlFromTheList = false;

		const homeUrls = [
			`/`,
			// The same page is available on 2 URLs due to the way Sitecore router
			// works. This way we support both of them.
			`/redesign/new-home`,
			`/redesign/new%20home`,
		];

		// Home page(s).
		if (homeUrls.includes(window.location.pathname)) {
			classes.push(`page--home`);
			excludeUrlFromTheList = true;
		} else {
			classes.push(`page--not-home`);
		}

		// Stories.
		if (window.location.pathname.slice(0, 9) === `/stories/`) {
			classes.push(`page--single-story`);
			excludeUrlFromTheList = true;
		}

		// Add the URL itself.
		if (!excludeUrlFromTheList) {
			classes.push(`page--${makeSafeForCss(window.location.pathname)}`);
		}
	}

	return classes;
}
