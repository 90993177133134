/* eslint-disable */
import React, { Component } from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { withErrorHandling } from "~/hoc";
import { Arrow } from "~/shared-components/SvgIcons";
import { compose } from "recompose";
import moment from "moment";
import { withNamespaces } from "react-i18next";
import { USTeamNames } from "~/utilities/constants";
import styles from "./CompetitionsFilter.module.scss";
import { isRedesign } from "~/utilities/helpers";

const all = {
	key: "all",
	value: null
};

class CompetitionsFilter extends Component {
	constructor(props) {
		super(props);

		all.value = this.props.t("competitionsfilter_all");

		this.state = {
			isCompetitionNameExpanded: false,
			isTeamExpanded: false,
			isYearExpanded: false,
			selectedCompetitionName: all.key,
			selectedTeam: all.key,
			selectedYear: all.key,
			competitionNames: [],
			teams: [],
			years: []
		};

		this.filterCompetitions();
	}

	componentDidMount() {
		this.setState({
			selectedCompetitionName: all.key,
			selectedTeam: all.key,
			selectedYear: all.key
		});
		this.getOptions();
	}

	//dynamically create team and year collections that will populate filter dropdowns
	getOptions = () => {
		const { allCompetitions } = this.props;
		let years = [];
		let teams = [];
		let competitionNames = [];

		allCompetitions?.map(c => {
			const competitionName = competitionNames.filter(o => o === c.optaCompetitionName);
			const team = teams.filter(t => t === USTeamNames[c?.sitecoreData?.contestantOptaId]);
			const year = years.filter(y => y === c.year);

			if (competitionName.length === 0 && c?.optaCompetitionName) {
				competitionNames.push(c.optaCompetitionName);
			}

			if (team.length === 0 && c?.sitecoreData?.contestantOptaId) {
				teams.push(USTeamNames[c.sitecoreData.contestantOptaId]);
			}

			if (year.length === 0) {
				years.push(c.year);
			}
		});

		competitionNames
			.sort()
			.unshift(all.key);

		teams
			.sort()
			.reverse()
			.unshift(all.key);

		years
			.sort()
			.reverse()
			.unshift(all.key);

		this.setState({
			competitionNames: competitionNames,
			teams: teams,
			years: years
		});
	}

	toggleCompetitionNameDropdown = () => {
		this.setState(prevState => ({
			isCompetitionNameExpanded: !prevState.isCompetitionNameExpanded,
			isTeamExpanded: false,
			isYearExpanded: false
		}));
	}

	toggleTeamDropdown = () => {
		this.setState(prevState => ({
			isCompetitionNameExpanded: false,
			isTeamExpanded: !prevState.isTeamExpanded,
			isYearExpanded: false
		}));
	}

	toggleYearDropdown = () => {
		this.setState(prevState => ({
			isCompetitionNameExpanded: false,
			isTeamExpanded: false,
			isYearExpanded: !prevState.isYearExpanded
		}));
	}

	getSelectedCompetitionName = competitionName => {
		this.setState({
			selectedCompetitionName: competitionName,
			isCompetitionNameExpanded: false
		}, () => this.filterCompetitions());
	}

	getSelectedTeam = team => {
		this.setState({
			selectedTeam: team,
			isTeamExpanded: false
		}, () => this.filterCompetitions());
	}

	getSelectedYear = year => {
		this.setState({
			selectedYear: year,
			isYearSelected: true,
			isYearExpanded: false
		}, () => this.filterCompetitions());
	}

	filterCompetitions = () => {
		const { selectedTeam, selectedYear, selectedCompetitionName } = this.state;
		const { allCompetitions, updatedFilteredCompetitions } = this.props;
		let filteredCompetitions = [];

		allCompetitions.filter(selectedYear === all.key ? (c => c.year !== null) : (c => c.year === selectedYear))
			.filter(selectedTeam === all.key ? c => USTeamNames[c?.sitecoreData?.contestantOptaId] !== null : (c => USTeamNames[c?.sitecoreData?.contestantOptaId] === selectedTeam))
			.filter(selectedCompetitionName === all.key ? (o => o.optaCompetitionName !== null) : (o => o.optaCompetitionName === selectedCompetitionName))
			.map(c => filteredCompetitions.push(c));

		filteredCompetitions.sort((a, b) => {
			const aDate = moment(a.endDate);
			const bDate = moment(b.endDate);
			return bDate - aDate;
		});

		return updatedFilteredCompetitions(filteredCompetitions);
	}

	clearFilter = () => {
		this.setState({
			selectedCompetitionName: all.key,
			selectedTeam: all.key,
			selectedYear: all.key
		}, () => this.filterCompetitions());
	}

	render() {
		const { t } = this.props;
		const {
			isCompetitionNameExpanded,
			isTeamExpanded,
			isYearExpanded,
			selectedCompetitionName,
			selectedTeam,
			selectedYear,
			competitionNames,
			teams,
			years
		} = this.state;

		return (
			<div className={styles.CompetitionsFilter}>
				{competitionNames && (
					<div className={styles.filterDropdown}>
						<span>{t("competitionsfilter_competition")}</span>
						<div className={styles.dropdownButton} onClick={() => this.toggleCompetitionNameDropdown()}>
							{selectedCompetitionName === all.key
								? all.value
								: selectedCompetitionName}
							<Arrow />
						</div>

						<div className={`${styles.dropdownMenuCollapsed} ${isCompetitionNameExpanded ? styles.showFilters : ""}`}>
							<ul className={`${isRedesign ? styles.itemContainerRedesign : styles.itemContainer}`}>
								{competitionNames.map((competitionName, index) =>
									<li className={`${isRedesign ? styles.itemRedesign : styles.item}`} key={index} onClick={() => this.getSelectedCompetitionName(competitionName)}>
										{competitionNames === all.key
											? all.value
											: competitionName}
									</li>
								)}
							</ul>
						</div>
					</div>
				)}

				{teams && (
					<div className={styles.filterDropdown}>
						<span>{t("competitionsfilter_team")}</span>
						<div className={styles.dropdownButton} onClick={() => this.toggleTeamDropdown()}>
							{/* TODO: this logic will need to be updated when data for other teams is available */}
							{selectedTeam === all.key
								? all.value
								: selectedTeam}
							<Arrow />
						</div>

						<div className={`${styles.dropdownMenuCollapsed} ${isTeamExpanded ? styles.showFilters : ""}`}>
							<ul className={`${isRedesign ? styles.itemContainerRedesign : styles.itemContainer}`}>
								{teams.map((team, index) =>
									<li className={`${isRedesign ? styles.itemRedesign : styles.item}`} key={index} onClick={() => this.getSelectedTeam(team)}>
										{/* TODO: this logic will need to be updated when data for other teams is available */}
										{team === all.key
											? all.value
											: team}
									</li>
								)}
							</ul>
						</div>
					</div>
				)}

				{years && (
					<div className={styles.filterDropdown}>
						<span>{t("competitionsfilter_year")}</span>
						<div className={styles.dropdownButton} onClick={() => this.toggleYearDropdown()}>
							{selectedYear === all.key
								? all.value
								: selectedYear}
							<Arrow />
						</div>

						<div className={`${styles.dropdownMenuCollapsed} ${isYearExpanded ? styles.showFilters : ""}`}>
							<ul className={`${isRedesign ? styles.itemContainerRedesign : styles.itemContainer}`}>
								{years.map((year, index) =>
									<li className={`${isRedesign ? styles.itemRedesign : styles.item}`} key={index} onClick={() => this.getSelectedYear(year)}>
										{year === all.key
											? all.value
											: year}
									</li>
								)}
							</ul>
						</div>
					</div>
				)}

				<div className={styles.clearButton} onClick={() => this.clearFilter()}><span>{t("competitionsfilter_clear")}</span></div>
			</div>
		);
	}
};

export default compose(
	// other hocs goes here
	withNamespaces(),
	withSitecoreContext(),
	withErrorHandling()
)(CompetitionsFilter);