import React from "react";
import { experienceEditorImages } from "~/utilities/constants";
import styles from "./HelperCommentEdit.module.scss";

const HelperCommentEdit = ({ endingEditComment }) => (
	<div className={styles.container}>
		<p>Go to edit by clicking the pencil icon <img src={experienceEditorImages.editPencil} alt="edit pencil" title="Edit" /> {endingEditComment}. Then click save for the changes to take effect.</p>
	</div>
);

export default HelperCommentEdit;