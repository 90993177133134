exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".EmailVerificationAlertCard-module__success--2KY2T {\n  color: green;\n  display: inline; }\n", ""]);

// exports
exports.locals = {
	"success": "EmailVerificationAlertCard-module__success--2KY2T"
};