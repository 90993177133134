import React from "react";
import { NavLink } from "react-router-dom";
import { Image, Text } from "~/patches/components";
import { withErrorHandling } from "~/hoc";
import { Swoosh } from "~/shared-components/SvgIcons";
import styles from "./SecondaryNav.module.scss";

const SecondaryNav = ({ menu1, menu2, feature, isEditing }) => (
	<ul className={styles.secondaryNav} itemScope itemType="http://schema.org/SiteNavigationElement">

		{(menu1 || isEditing) && menu1.map(item => (
			<li key={item.id} itemProp="name">
				<NavLink to={item.url} activeClassName={styles.active} itemProp="url">
					<Text field={item.fields.menuTitle}/>
				</NavLink>
			</li>
		))}

		<li itemProp="name"><a target="_blank" rel="external noopener noreferrer" href="https://store.ussoccer.com/?utm_source=us_soccer&utm_medium=website&utm_campaign=shoptab" itemProp="url">shop</a></li>

		<li className={styles.separator}>&nbsp;</li>

		{(menu2 || isEditing) && menu2.map(item => (
			<li key={item.id} itemProp="name">
				<NavLink to={item.url} activeClassName={styles.active} itemProp="url">
					<Text field={item.fields.menuTitle}/>
				</NavLink>
			</li>
		))}

		<li itemProp="name"><a target="_blank" rel="external noopener noreferrer" href="https://www.safesoccer.com/" itemProp="url">Safe Soccer</a></li>

		<li className={styles.separator}>&nbsp;</li>

		{(feature.link || isEditing) && (
			<li key={feature.link.value.id} itemProp="name">
				<NavLink to={feature.link.value.href} activeClassName={styles.active} itemProp="url">
					{feature.image?.value
						? (
							<span className={styles.iconContainer} style={!feature.link.value.text ? { width: "auto" } : { width: "40px" } }>
								<Image field={feature.image} />
							</span>
						)
						: ""
					}
					<span>{feature.link.value.text}</span>
				</NavLink>
			</li>
		)}
		<li className={styles.separator}>&nbsp;</li>

		<li className={styles.nikeSponsor}>
			<Swoosh />
		</li>
	</ul>
);

export default withErrorHandling()(SecondaryNav);