import React from "react";
import styles from "./PoweredByTicketmaster.module.scss";
import FullTicketmasterLogo from "~/assets/svg/ticketmaster-logo-black.svg";
import TTicketmasterLogo from "~/assets/svg/ticketmaster-t-white.svg";

const PoweredByTicketmaster = ({showPoweredByText, showFullLogo, showTlogo}) => (
	<div className={`${styles.wrap} ${showTlogo ? styles.wrapTonly : ''}`} title={`Powered by Ticketmaster`}>
		{showPoweredByText && (
			<span className={styles.poweredByText}>Powered by</span>
		)}
		{showFullLogo && (
			<img src={FullTicketmasterLogo} alt={`Ticketmaster logo`} className={styles.logoFull} />
		)}
		{showTlogo && (
			<img src={TTicketmasterLogo} alt={`Ticketmaster logo`} className={styles.logoT} />
		)}
	</div>
);

export default PoweredByTicketmaster;