/* eslint-disable react/jsx-key */
import React from "react";
import {Link} from '@sitecore-jss/sitecore-jss-react';
import {InstagramAlt, FacebookAlt, TwitterAlt} from "~/shared-components/SvgIcons"
import { optaApi } from "~/utilities/constants";
import { competitions } from "~/utilities/competitions";
import MatchInfo from "~/shared-components/RedesignMatchInfo";
import MatchCountdown from "~/shared-components/RedesignMatchCountdown";
import BroadcasterLinks from "~/shared-components/RedesignBroadcasterLinks";
import MatchButtons from "~/shared-components/RedesignMatchButtons";
import { MatchPostGame } from "./MatchPostGame";
import styles from "./RedesignFeaturedMatch.module.scss"
import { useFetchMatch } from "~/hooks/match";

const RedesignFeaturedMatch = (args) => {
	const { matchId, appLink, teamFormationImage, pageEditing, isFeaturedMatchOnlyLive, rendering } = args;
	const match = useFetchMatch(matchId);
	const isOpenCup = match?.competition?.id === competitions.openCup;
	const teamOptaId = isOpenCup ? match?.contestants[0].id : match?.contestants?.filter(team => team.code === 'USA')[0].id;
	const teamData = rendering?.fields?.teams?.filter(team => team.fields.optaTeamId.value === teamOptaId)[0];

	return (
		<div className={styles.scorebar}>
			{match && (
				<>
					<div className={isFeaturedMatchOnlyLive ? styles.featuredMatchOnlyLive : styles.featuredMatch}>
						<div className={match.status === optaApi.matchStatus.live ? styles.liveMatchInfo : match.status === optaApi.matchStatus.completed ? match.status === optaApi.matchStatus.completed ? styles.completedFeaturedMatchInfo : styles.completedMatchInfo : styles.matchInfo}>
							{match && match.status === optaApi.matchStatus.live && (
								<div className={styles.whereToWatchLive}>
									<BroadcasterLinks match={match} />
								</div>
							)}
							<div className={styles.matchDetails}>
								<MatchInfo match={match} isFeaturedMatchOnlyLive={isFeaturedMatchOnlyLive} pageEditing={pageEditing} type="P1"/>
							</div>
							{match?.status === optaApi.matchStatus.completed && (
								<MatchPostGame matchId={matchId} teamData={teamData} match={match} />
							)}
							{match && match.status === optaApi.matchStatus.upcoming && (
								<div className={styles.matchCountdown}>
									<MatchCountdown datetime={new Date(match.dateTime)} type="P1" />
								</div>
							)}
							{match.status === optaApi.matchStatus.upcoming && match.sitecoreData && (
								<div className={styles.matchButtons}>
									<MatchButtons event={match} pageEditing={pageEditing} type="P1" />
								</div>
							)}
							{ isFeaturedMatchOnlyLive &&
								<div className={styles.socialAndAction}>
									{ teamData &&
										<div className={styles.social}>
											{ teamData?.fields?.instagramLink?.value?.href && <a href={teamData.fields.instagramLink.value.href} rel="noopener noreferrer" target="_blank" title={`${teamData.name} Instagram`} className={styles.socialLink}><div className={styles.iconContainer}><InstagramAlt/></div><span>{teamData?.fields?.instagramLink?.value?.text ?? "USSoccer"}</span></a> }
											{ teamData?.fields?.facebookLink?.value?.href && <a href={teamData.fields.facebookLink.value.href} rel="noopener noreferrer" target="_blank" title={`${teamData.name} Facebook`} className={styles.socialLink}><div className={styles.iconContainer}><FacebookAlt/></div><span>{teamData?.fields?.facebookLink?.value?.text ?? "USSoccer"}</span></a> }
											{ teamData?.fields?.twitterLink?.value?.href && <a href={teamData.fields.twitterLink.value.href} rel="noopener noreferrer" target="_blank" title={`${teamData.name} Twitter`} className={styles.socialLink}><div className={styles.iconContainer}><TwitterAlt/></div><span>{teamData?.fields?.twitterLink?.value?.text ?? "USSoccer"}</span></a> }
										</div>
									}
									{ teamFormationImage?.value?.src && appLink?.value?.href &&
										<div className={styles.action}>
											<Link field={appLink} className={styles.actionLink}>
												<div className={styles.actionText}>TRACK ALL THE ACTION WITH MATCH INSIGHTS</div>
												<div className={styles.actionImage} style={{backgroundImage: `url(${teamFormationImage.value.src})`}}></div>
											</Link>
										</div>
									}
								</div>
							}
						</div>
						{match && (match.status === optaApi.matchStatus.upcoming || match.status === optaApi.matchStatus.completed) && (
							<div>
								<BroadcasterLinks match={match} />
							</div>
						)}
					</div>
				</>
			)}
		</div>
	);
};

export default RedesignFeaturedMatch;
