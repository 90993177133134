exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".CategoriesSidebar-module__container--3UNtq {\n  width: 100%; }\n  .pageEditing .CategoriesSidebar-module__container--3UNtq {\n    min-height: 17rem; }\n\n.CategoriesSidebar-module__ad--1nro0 {\n  display: none; }\n\n@media (min-width: 1240px) {\n  .ad-loaded .CategoriesSidebar-module__adContainer--3jV5I .CategoriesSidebar-module__ad--1nro0 {\n    display: block;\n    margin: 2rem auto;\n    width: 300px; } }\n", ""]);

// exports
exports.locals = {
	"container": "CategoriesSidebar-module__container--3UNtq",
	"ad": "CategoriesSidebar-module__ad--1nro0",
	"adContainer": "CategoriesSidebar-module__adContainer--3jV5I"
};