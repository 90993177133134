import React, { Fragment } from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import { Placeholder, withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import SharedTabs from "~/shared-components/SharedTabs";
import SharedTabContent from "~/shared-components/SharedTabs/SharedTabContent";
import SectionLabel from "~/shared-components/SectionLabel";
import { fieldHasValue, isPlaceholderEmptyOrUndefined } from "~/utilities";
import styles from "./Tabs.module.scss";
import SharedTab from "~/shared-components/SharedTabs/SharedTab";

const Tabs = ({fields, rendering, sitecoreContext: { pageEditing } }) => {

	const selectedBackground = {
		background: fields.selectedBackground?.value,
		border: `none`
	};
	
	const setColor = fields?.selectedBackground?.value ? selectedBackground : undefined;

	return (
		<section className={styles.container} style={setColor}>
			{(fieldHasValue(fields, "sectionLabel") || pageEditing) && (
				<div className={styles.sectionLabelContainer}>
					<SectionLabel sectionLabel={fields.sectionLabel} />
				</div>
			)}
			{pageEditing && (
				<SharedTabs render={tabHandler => (
					<Placeholder
						name="jss-tabs-content"
						rendering={rendering}
						renderEach={(component, index) => (
							<Fragment key={index}>
								<SharedTab
									{...tabHandler}
									label={component.props?.fields?.tabTitle}
									index={index}>
								</SharedTab>
								<SharedTabContent
									key={index}
									{...tabHandler}>
									{component}
								</SharedTabContent>
							</Fragment>
						)}
					/>
				)}/>
			)}

			{!pageEditing && !isPlaceholderEmptyOrUndefined(rendering, "jss-tabs-content") && (
				<Placeholder
					name="jss-tabs-content"
					rendering={rendering}
					render={components => {
						const tabNames = components?.map(x => x.props?.fields?.tabTitle);

						if (!components) return <></>;

						return (
							<SharedTabs labels={tabNames} render={tabHandler => (
								components.map((component, index) => (
									<SharedTabContent
										key={index}
										index={index}
										{...tabHandler}>
										{component}
									</SharedTabContent>
								))
							)}/>
						);
					}}
				/>
			)}
		</section>
	);
};

export default compose(
	// other hocs goes here
	withSitecoreContext(),
	withErrorHandling()
)(Tabs);