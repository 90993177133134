import React, { PureComponent } from "react";
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import styles from "./PlayerThumbnail.module.scss";
import { Image, Text, RouterLink } from "~/patches/components";
import { fieldHasValue } from "~/utilities";
import { compose } from "recompose";
import { withErrorHandling } from "~/hoc";

class PlayerThumbnail extends PureComponent {

	constructor(props) {
		super(props);

		this.options = {
			modifierClassName: "",
			imageSizes: undefined,
			imageSrcSet: undefined,
			showNumberOfStats: 3,

		};

		if (props.options) this.setOptionsValues(props.options);
	}

	setOptionsValues(options) {
		this.options.modifierClassName = options.modifierClassName ? styles[options.modifierClassName] : "";
		this.options.imageSizes = options.imageSizes && options.imageSizes;
		this.options.imageSrcSet = options.imageSrcSet && options.imageSrcSet;
		this.options.showNumberOfStats = options.showNumberOfStats && options.showNumberOfStats;
	}

	render() {
		let {fields: {itemLink}} = this.props;

		/* Check if item link comes as an array */
		if (itemLink?.length > 0)
			itemLink = itemLink[0];

		const maxStats = this.options.showNumberOfStats;
		const statsLength = itemLink?.fields?.stats?.length || 0;
		const statsCss = `stats${statsLength > maxStats ? maxStats : statsLength}`;
		return (
			<>
				<section className={`${itemLink?.fields?.stats?.length > 0 ? styles.PlayerThumbnailStats : styles.PlayerThumbnail} ${this.options.modifierClassName}`}>
					{itemLink && itemLink.fields && (
						<RouterLink className={styles.link} to={itemLink.url}>
							{(itemLink?.fields?.image) && (
								<div className={styles.image}>
									<Image
										field={itemLink.fields?.image}
										srcSet={this.options.imageSrcSet}
										sizes={this.options.imageSizes}
									/>
								</div>
							)}

							<div className={styles.content}>
								{(itemLink?.fields?.firstName || itemLink?.fields?.lastName) && (
									<div className={styles.playerName}>
										{fieldHasValue(itemLink.fields, "number") && itemLink.fields.number?.value !== "0" && (
											<>
												<Text field={itemLink.fields.number} /> {" "}
											</>
										)}
										{fieldHasValue(itemLink.fields, "firstName") && (
											<>
												<Text field={itemLink.fields.firstName} /> {" "}
											</>
										)}
										<Text field={itemLink.fields.lastName} />
									</div>
								)}

								{itemLink?.fields?.position?.fields?.positionName?.value && (
									<div className={styles.playerPosition}>
										<Text field={itemLink.fields.position?.fields?.positionName} />
									</div>
								)}
							</div>

							{itemLink?.fields?.stats?.length > 0 && (
								<ul className={`${styles.stats} ${statsCss in styles ?  styles[statsCss] : ""}`}>
									{itemLink.fields.stats.slice(0, maxStats).map((stat, index) => (
										<StatItem key={index} {...{stat}} />
									))}
								</ul>
							)}
						</RouterLink>
					)}
				</section>
			</>
		);
	}
}

const StatItem = ({ stat }) => (
	<li className={styles.statsItem}>
		<div className={styles.statsValue}><Text field={stat.fields.statValue} /></div>
		<div className={styles.statsName}>{getFieldValue(stat.fields.statType?.fields, "statName")}</div>
	</li>
);

export default compose(
	withErrorHandling()
)(PlayerThumbnail);