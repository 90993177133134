
import React, { Component } from "react";
import styles from "./MemberLink.module.scss";
import { Link as RouterLink } from "react-router-dom";
import { compose } from "recompose";
import { withErrorHandling, withGigya } from "~/hoc";

class MemberLink extends Component {
	constructor(props) {
		super(props);
		const { gigya: { isLoggedIn }} = this.props;

		this.state = {
			isUserLoggedIn: isLoggedIn
		};
	}

	componentDidUpdate(prevProps) {
		const { gigya: prevGigya } = prevProps;
		const { gigya: { isLoggedIn } } = this.props;

		if (prevGigya.isLoggedIn !== isLoggedIn) {
			this.setState({
				isUserLoggedIn: isLoggedIn
			});
		}
	}

	render() {
		const {
			becomeAMember,
			memberBenefits,
			collapseNavigation
		} = this.props;
		const { isUserLoggedIn } = this.state;
		const signupLink = (isUserLoggedIn ? memberBenefits : becomeAMember);

		return (
			<div className={styles.memberLink}>
				{signupLink &&
					<RouterLink className={styles.link} to={signupLink.value.href} onClick={() => collapseNavigation()}>
						{signupLink.value.text}
					</RouterLink>
				}
			</div>
		);
	}
}

export default compose(
	withGigya(),
	withErrorHandling()
)(MemberLink);