export const getDoubleHeaderMatches = (matches) => {
	const doubleHeadersByMatchId = {};
	const doubleHeaderMatches = [];

	// Get the child double header match. Will most likely be non-US match.
	const filterDoubleHeaderMatches = (doubleHeaderMatchId) => {
		const childDoubleHeaderMatch = matches.find(match => match.matchId === doubleHeaderMatchId);

		// Chances are the below is unnecessary until we start displaying non-US matches individually (outside of double headers),
		// When we do so, we won't want to show them at the individual match level AND inside of a double header. 
		// So, tag the parent match of the double header so we don't display that as an individual match on the MatchListItem or MatchCard components.
		if (childDoubleHeaderMatch) {
			childDoubleHeaderMatch.isPartOfADoubleHeaderMatch = true;
		}

		return childDoubleHeaderMatch;
	};

	const buildDoubleHeaderMatches = (match) => {
		if (match?.sitecoreData?.doubleHeaderMatchId?.value) {
			// Allow content publishers to add multiple double headers
			// by making it a comma delimited string. Should probably change the
			// field name on the backend and here from doubleHeaderMatchId to doubleHeaderMatchIds.
			const doubleHeaderIds = match.sitecoreData.doubleHeaderMatchId.value.split(",")?.map(id => id.trim());
			const childDoubleHeaderMatches = doubleHeaderIds.map(filterDoubleHeaderMatches).filter(Boolean);
			doubleHeaderMatches.push({ [match.matchId]: childDoubleHeaderMatches });
		}
	};

	matches.map(buildDoubleHeaderMatches);

	const doubleHeaderMatchesObject = doubleHeaderMatches.reduce((_, match) => {
		const matchId = Object.keys(match)[0];
		doubleHeadersByMatchId[matchId] = match[matchId];

		return doubleHeadersByMatchId;
	}, null);

	return doubleHeaderMatchesObject || {};
};

export const getDoubleHeaderMessage = (numberOfMatches) => {
	switch (numberOfMatches) {
		// Taking into account the number of child
		// matches we have in addition to the parent match
		case 1: // Plus 1 parent makes 2
			return "DOUBLE HEADER";
		case 2: // Plus 1 parent makes 3
			return "TRIPLE HEADER";
		default:
			return "MULTI HEADER";
	}
};