import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { withGigya } from "~/hoc";
import { Text } from "~/patches/components";
import { Arrow } from "~/shared-components/SvgIcons";
import styles from "./GigyaScreenSetAccordion.module.scss";
import GigyaScreenSet from "~/shared-components/GigyaScreenSet";
import { membershipName } from "~/utilities/helpers";

const GetCommonHeader = ({ collapsedText, isCollapsed, setIsCollapsed }) => (
	<div className={`${styles.accordionCTA} ${isCollapsed ? styles.show : styles.hide}`} {...(setIsCollapsed && { onClick: () => setIsCollapsed(false) })}>
		<span className={styles.ctaText}>{collapsedText?.value}</span>
		<div className={styles.arrow}><Arrow/></div>
	</div>
);

const GetHeader = ({ collapsedText, heading, href, isCollapsed, setIsCollapsed }) => (
	<div className={styles.headingBar}>
		<h1 className={styles.heading}>{heading?.value}</h1>
		{href ? (
			<a href={href}>
				<GetCommonHeader collapsedText={collapsedText} isCollapsed={isCollapsed} />
			</a>
		) : 
			<GetCommonHeader collapsedText={collapsedText} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
		}
	</div>
);

const GigyaScreenSetAccordion = ({ fields, gigya, sitecoreContext }) => {
	const [isCollapsed, setIsCollapsed] = useState(fields.startCollapsed?.value);
	const [profile, setProfile] = useState({});
	const { pageEditing } = { sitecoreContext };

	const {
		startCollapsed,
		collapsedText,
		screenSetId,
		startScreenId,
		heading
	} = fields;

	const displayableStartScreenIds = ["gigya-change-password-screen", "gigya-update-profile-screen", "update-extended-profile", "email-preferences"];

	const href = startScreenId?.value === "membership-info" ? "/profile/account-and-billing" : null;

	// We only want to display the Gigya Accordion component for certain start screen
	// IDs (configured in Gigya) OR to show a user's membership info if they are a paid tier
	const isDisplayable = (gigya, startScreenId) => 
		displayableStartScreenIds.includes(startScreenId?.value)
	|| (startScreenId?.value === "membership-info" && gigya.data?.membershipTier && gigya.data.membershipTier > 0);

	const handlers = () => ({
		onHide: () => setIsCollapsed(true),
		onSubmit: (context) => {
			setProfile(context.formModel.profile);
			setIsCollapsed(true);
		}
	});

	useEffect(() => {
		setProfile(gigya.userInfo);
		setIsCollapsed(true);
	}, [gigya.userInfo]);

	return (
		<>
		{isDisplayable(gigya, startScreenId) && (
			<div className={styles.gigyaScreenSetAccordion}>
				<GetHeader 
					collapsedText={collapsedText} 
					heading={heading} 
					href={href} 
					isCollapsed={isCollapsed} 
					setIsCollapsed={setIsCollapsed} 
				/>
				<div className={styles.inner}>
					{!pageEditing ? (
					<>
						{startScreenId?.value === "gigya-update-profile-screen" &&
							<div className={`${styles.userInfo} ${isCollapsed ? styles.showUserInfo : styles.hide}`}>
								<div className={styles.userInfoLeft}>
									{(profile?.firstName || profile?.lastName) && <p>{profile?.firstName} {profile?.lastName}</p>}
									{profile?.email && <p>{profile?.email}</p>}
								</div>
								<div className={styles.userInfoRight}>
									{profile?.address && <p>{profile?.address}</p>}
									{profile?.city && <p>{profile?.city}</p>}
									{(profile?.state || profile?.zip) && <p>{profile?.state} {profile?.zip}</p>}
									{profile?.country && <p>{profile?.country}</p>}
								</div>
							</div>}
							{startScreenId?.value === "membership-info" &&
							<div className={`${styles.userInfo} ${isCollapsed ? styles.showUserInfo : styles.hide}`}>
								<div className={styles.userInfoLeft}>
									<p>{membershipName(gigya.data.membershipTier)} Membership ends on {' '}
										{
											new Date(gigya.data.membershipSubscription?.end)
												.toLocaleDateString('en-US', { year: "numeric", month: "short", day: "numeric"})
										}
									</p>
								</div>
								<div className={styles.userInfoRight}>
									<p>Auto-Renewal {gigya.data.membershipCanceled ? 'OFF' : 'ON'}</p>
								</div>
							</div>}
						<div className={isCollapsed ? styles.hide : styles.showScreenSet}>
							<GigyaScreenSet screenSetId={screenSetId?.value} startScreenId={startScreenId?.value} handlers={handlers()} />
						</div>
					</>
					) :	(
						<div>
							<h2>Gigya Screen-Set Accordion</h2>
							<p>Start Collapsed (change in Content Editor): <strong>{startCollapsed?.value}</strong></p>
							<p>Collapsed Text: <strong><Text field={collapsedText} /></strong></p>
							<p>Screen-Set: <strong><Text field={screenSetId} /></strong></p>
							<p>Start Screen: <strong><Text field={startScreenId} /></strong></p>
						</div>
					)}
				</div>
			</div>
		)}
		</>
	);
};

export default compose(
	withGigya(),
	withSitecoreContext()
)(GigyaScreenSetAccordion);

