exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".pageEditing .SharedTabs-module__SharedTabs--27W5r .SharedTabs-module__tabContent--1B1Jn {\n  display: block;\n  margin-bottom: 3rem; }\n\n.SharedTabs-module__tabHeader--d11Vw {\n  border-bottom: 0.2rem solid rgba(0, 0, 0, 0.07);\n  margin-bottom: 4rem; }\n\n.SharedTabs-module__inner--3UvQu {\n  margin: 0 auto;\n  max-width: 1440px;\n  padding: 0 2rem;\n  width: 100%; }\n  .SharedTabs-module__inner--3UvQu button + button {\n    margin-left: calc(3.47222vw + 10px); }\n    @media (max-width: 576px) {\n      .SharedTabs-module__inner--3UvQu button + button {\n        margin-left: 30px; } }\n    @media (min-width: 1440px) {\n      .SharedTabs-module__inner--3UvQu button + button {\n        margin-left: 60px; } }\n\n@media (min-width: 992px) {\n  .SharedTabs-module__sectionLabelContainer--T0cFX {\n    margin: 0 auto 4rem; } }\n\n@media (min-width: 1025px) {\n  .SharedTabs-module__inner--3UvQu {\n    padding: 0 8.333rem; } }\n\n@media (min-width: 1440px) {\n  .SharedTabs-module__inner--3UvQu {\n    max-width: 1640px; } }\n", ""]);

// exports
exports.locals = {
	"SharedTabs": "SharedTabs-module__SharedTabs--27W5r",
	"tabContent": "SharedTabs-module__tabContent--1B1Jn",
	"tabHeader": "SharedTabs-module__tabHeader--d11Vw",
	"inner": "SharedTabs-module__inner--3UvQu",
	"sectionLabelContainer": "SharedTabs-module__sectionLabelContainer--T0cFX"
};