exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".AllPlayersTemplate-module__header--3NUXp {\n  padding: 4.16667rem 0; }\n  .AllPlayersTemplate-module__header--3NUXp h1 {\n    font-weight: 300;\n    margin: 0;\n    text-transform: uppercase; }\n", ""]);

// exports
exports.locals = {
	"header": "AllPlayersTemplate-module__header--3NUXp"
};