import { useEffect, useState } from 'react';
import moment from "moment";
import { MatchService } from "~/services/matchService";
import { optaApi, validDateTimeFormat } from "~/utilities/constants";

export const useFetchLiveMatchData = (match, setIsValidMatchDateTime, setUpdatedMatch, getTotalGoals = null) => {
	const fetchLiveMatchData = (match) => {
		if (match) {
			MatchService.getMatch(match.matchId)
				.then(response => {
					if (response?.contestants) {
						setUpdatedMatch(response);

						if (getTotalGoals) {
							getTotalGoals(response.goals?.length);
						}
					}
				}).catch((err) => {
					console.error(`Error fetching live match data: ${err}`);
				});
		}
	};

	useEffect(() => {
		const timeoutDelay = 1000 * 15;
		let intervalID = 0;

		setUpdatedMatch(match);

		if (!moment(match?.dateTime, validDateTimeFormat, true).isValid()) {
			setIsValidMatchDateTime(false);
		} else {
			const hoursSinceMatch = (moment() - moment(match.dateTime)) / 1000 / 60 / 60;
			if (match.status === optaApi.matchStatus.live || (hoursSinceMatch < 24 && hoursSinceMatch > -2)) {
				fetchLiveMatchData(match);
				intervalID = setInterval(() => {
					fetchLiveMatchData(match);
				}, timeoutDelay);
			}
		}

		return () => {
			clearInterval(intervalID);
		};
	}, []);

	useEffect(() => {
		if (!moment(match.dateTime, validDateTimeFormat, true).isValid()) {
			setIsValidMatchDateTime(false);
		}

		setUpdatedMatch(match);
	}, [match]);
};

/*
 * This hook will handle the case where a double header match
 * is the last element in the matches to display array on the schedule
 * list or view page. This will bump the display by 1 so that the entire 
 * double header is shown and not just the top/first match.
 */
export const useIncrementDisplayMatchesBasedOnDoubleHeader = (doubleHeaderMatches, matchesToDisplay, numberOfMatchesToDisplay, setNumberOfMatchesToDisplay, lengthOfMatches) => {
	useEffect(() => {
		if (doubleHeaderMatches) {
			const lastMatch = matchesToDisplay[matchesToDisplay?.length - 1];
			const doubleHeaderMatchIds = Object.keys(doubleHeaderMatches);

			if (numberOfMatchesToDisplay < lengthOfMatches && doubleHeaderMatchIds.includes(lastMatch?.matchId)) {
				setNumberOfMatchesToDisplay(numberOfMatchesToDisplay + 1);
			}
		}
	}, [doubleHeaderMatches, matchesToDisplay, numberOfMatchesToDisplay]);
};

export const useFetchMatch = (matchId) => {
	const [match, setMatch] = useState(null);

	useEffect(() => {
		
		const fetchMatch = (matchId) =>
			matchId
				? MatchService.getMatch(matchId)
				: null;

		fetchMatch(matchId)?.then(m => setMatch(m))
			?.catch(console.error);
	}, [matchId]);

	return match;
};
