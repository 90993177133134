import React from "react";
import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";
import { Text } from "~/patches/components";
import { GigyaConsumer } from "~/lib/gigya";

const RedesignProfile = ({ loginPage, profilePage }) => (
	<GigyaConsumer>
		{({ isLoggedIn, profile }) => (
			<>
				{isLoggedIn && (
					<Link to={profilePage.value.href}>
						<Text field={{value: `Hi, ${profile?.firstName}`}} />
					</Link>
				)}
				{!isLoggedIn && (
					<Link to={loginPage.value.href}>
						<Text tag='span' field={{value: 'Login'}} />
					</Link>
				)}
			</>
		)}
	</GigyaConsumer>
);

RedesignProfile.propTypes = {
	loginPage: PropTypes.object.isRequired,
	profilePage: PropTypes.object.isRequired
};

export default RedesignProfile;