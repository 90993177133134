exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".StatsSidebarNav-module__container--2aB2e {\n  width: 100%; }\n", ""]);

// exports
exports.locals = {
	"container": "StatsSidebarNav-module__container--2aB2e"
};