import React from "react";
import { compose } from "recompose";
import styles from "./AllMatchesTab.module.scss";

const AllMatchesTab = ({ label, activeTab, onClick }) => (
	<li className={activeTab === label ? styles.AllMatchesTabActive : styles.AllMatchesTab} onClick={() => onClick(label)}>
		{label}
	</li>
);

export default compose(
	// other hocs goes here
)(AllMatchesTab);