import React from "react";
import { compose } from "recompose";
import { withErrorHandling } from "~/hoc";
import { Text } from "~/patches/components";
import styles from "./SharedAccordionTab.module.scss";

const SharedAccordionTab = props => {
	const { openTab, setOpenTab, index, label} = props;

	return (
		<>
			<section className={openTab[index] ? styles.containerActive : styles.container} >
				<div className={styles.header} onClick={() => setOpenTab(index)}>
					<Text tag="h3" field={label} />
				</div>
				<div className={styles.content}>
					{props.children}
				</div>
			</section>
		</>
	);
};

export default compose(
	withErrorHandling()
)(SharedAccordionTab);