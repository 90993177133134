import React from "react";
import { withErrorHandling } from "~/hoc";
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { compose } from "recompose";
import GenericTable from "~/shared-components/GenericTable";

const Table = ({rendering: { tableData }}) => {

	const heading = tableData.heading;
	const rows = tableData.rows;

	return (
		<>
			<GenericTable heading={heading} rows={rows} />
		</>
	)
};

export default compose(
	// other hocs goes here
	withErrorHandling(),
	withSitecoreContext()
)(Table);