import React, { Component } from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import TableMatches from "~/shared-components/TableMatches";
import TableFilter from "~/shared-components/TableFilter";
import TableSeasonRecord from "~/shared-components/TableSeasonRecord";

class TeamStats extends Component {
	constructor(props) {
		super(props);

		this.state = {
			filteredYear: 2019
		};
	}

	render() {
		const { rendering: { teamStats } } = this.props;
		const { filteredYear } = this.state;

		// TODO: remove when years array becomes part of data package
		const years = [
			2019,
			2018,
			2017,
			2016,
			2015,
			2014,
			2013,
			2012,
			2011,
			2010
		];

		return (
			<>
				{teamStats && 
					<div>
						<TableFilter years={years} updatedFilteredYear={year => this.setState({ filteredYear: year })} />
						{teamStats.seasonRecords.filter(s => s.year === filteredYear)[0] && 
							<TableSeasonRecord heading="Record" seasonRecord={teamStats.seasonRecords.filter(s => s.year === filteredYear)[0]} />
						}
						{teamStats.matches.years.filter(m => m.year === filteredYear)[0] && 
							<TableMatches heading="Matches" matches={teamStats.matches.years.filter(m => m.year === filteredYear)[0].matches} />
						}
					</div>
				}
			</>
		);
	}
}

export default compose(
	// other hocs goes here
	withErrorHandling()
)(TeamStats);