import React from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { withErrorHandling } from "~/hoc";
import MatchCard from "~/shared-components/MatchCard";
import { isMobile, isTablet } from "~/utilities";
import { compose } from "recompose";
import Carousel from "../Carousel";
import moment from "moment";
import styles from "./MatchScheduleCarousel.module.scss";
import HelperCommentEdit from "~/experience-editor/HelperCommentEdit";

const MatchScheduleCarousel = ({ upcomingMatches, matchResults, sitecoreContext: { pageEditing } }) => {
	const cardsPerDot = isMobile ? 1 : isTablet ? 2 : 3;
	const indexUpcoming = upcomingMatches?.length > 0 ? Math.floor(matchResults.length / cardsPerDot) : 0;

	const sortMatches = matches => (
		matches?.sort((a,b) => {
			const aDate = moment(a.date);
			const bDate = moment(b.date);
			return aDate - bDate;
		})
	);

	const liveMatch = matchResults?.filter(m => m.status === 2)[0];
	const liveMatchIndex = sortMatches(matchResults)?.indexOf(liveMatch);

	return (
		<>
		{(matchResults?.length > 0 || upcomingMatches?.length > 0 || pageEditing) && (
			<div className={styles.MatchScheduleCarousel}>
				{pageEditing && !matchResults && !upcomingMatches && (
					<HelperCommentEdit endingEditComment="and select a team and competition (optional). If a competition isn't selected then all matches for the selcted team will be rendered " />
				)}
				<Carousel initialIndex={liveMatchIndex > -1 ? liveMatchIndex : indexUpcoming}>
					{matchResults && (
						sortMatches(matchResults).map((match, index) =>
							<div className={styles.matchCardContainer} key={index} >
								<MatchCard match={match} inCarousel />
							</div>
						)
					)}
					{upcomingMatches && (
						sortMatches(upcomingMatches).map((match, index) =>
							<div className={styles.matchCardContainer} key={index} >
								<MatchCard match={match} inCarousel ticketMasterIcon />
							</div>
						)
					)}
				</Carousel>
			</div>
		)}
		</>
	);
};

export default compose(
	// other hocs goes here
	withSitecoreContext(),
	withErrorHandling()
)(MatchScheduleCarousel);