import React, { PureComponent } from "react";
import { withErrorHandling, withGigya } from "~/hoc";
import { MembershipService } from "~/services/membershipService";
import { withNamespaces } from "react-i18next";
import { membershipName } from "~/utilities/helpers";
import Modal from "~/shared-components/Modal";
import { compose } from "recompose";
import moment from "moment";
import 'moment-timezone';
import styles from "./CancelMembershipModal.module.scss";

class CancelMembershipModal extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			fetching: false
		};
	}

	getJWT = async () => new Promise((res, rej) =>
		this.props.gigya.getJWT(
			gigyaRes => gigyaRes.errorCode
				? rej(gigyaRes.errorMessage)
				: res(gigyaRes.id_token)
		)).catch(err => console.log(err)); //eslint-disable-line

	cancel = async event => {
		const { onClose, gigya: { refreshUserData } } = this.props;
		this.setState({ fetching: true });
		event.preventDefault();
		const jwt = await this.getJWT();

		const res = await MembershipService.cancelSubscription(jwt);

		if (res.statusCode !== 200) {
			console.error(res); //eslint-disable-line
		}

		if (typeof onClose === "function") {
			onClose();
		}

		this.setState({ fetching: false });
		refreshUserData();
	}

	render() {
		const { active, onClose, gigya: { data }, t } = this.props;
		const { fetching } = this.state;
		const levelName = data?.membershipTier ? membershipName(parseInt(data.membershipTier)) : "";
		const endDate = data?.membershipSubscription?.end 
			? moment(data.membershipSubscription.end)
				.tz(moment.tz.guess())
				.format("MMM DD YYYY")
			: "";

		return (
			<Modal active={active} type={"cancel"}>
				<div className={`${styles.modalContent} ${fetching ? styles.disabled : ""}`}>
					<button disabled={fetching} onClick={onClose} className={styles.closeButton}></button>
		
					<div className={styles.textContainer}>
						<h2>{t("cancelmodal_want-to-cancel")}</h2>
						<p>{t("cancelmodal_remain")} {levelName} {t("cancelmodal_until")} {endDate} {t("cancelmodal_enjoy-standard")}</p>
						{/*TODO (tyler): temporarily using translate labels. 
							-	Should this be authorable? If so, what changes need to happen?
							- What should this COPY be? */}
					</div>
		
					<div className={styles.buttonContainer}>
						<button disabled={fetching} onClick={this.cancel}>{t("cancelmodal_cancel")}</button>
						<button disabled={fetching} onClick={onClose}>{t("cancelModal_back")}</button>
					</div>
				</div>
			</Modal>
		);
	}
}

export default compose(
	withNamespaces(),
	withErrorHandling(),
	withGigya()
)(CancelMembershipModal);