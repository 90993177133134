exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".BrightcoveVideoPlayer-module__container--1wsok {\n  margin: 0 auto;\n  max-width: 1440px;\n  position: relative;\n  z-index: 1;\n  margin-bottom: calc(3.47222vw + 10px); }\n  @media (max-width: 576px) {\n    .BrightcoveVideoPlayer-module__container--1wsok {\n      margin-bottom: 30px; } }\n  @media (min-width: 1440px) {\n    .BrightcoveVideoPlayer-module__container--1wsok {\n      margin-bottom: 60px; } }\n\n.BrightcoveVideoPlayer-module__video--29Af9 {\n  bottom: 0px;\n  height: 100%;\n  left: 0px;\n  position: absolute;\n  right: 0px;\n  top: 0px;\n  width: 100%; }\n", ""]);

// exports
exports.locals = {
	"container": "BrightcoveVideoPlayer-module__container--1wsok",
	"video": "BrightcoveVideoPlayer-module__video--29Af9"
};