import React from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import styles from "./TwoColumnsLayout.module.scss";

const TwoColumnsLayout = ({ rendering }) => (
	<div className={styles.twoColumnsLayout}>
		<div className={styles.sidebar}>
			<Placeholder name="jss-layout-two-columns-sidebar" rendering={rendering} />
		</div>
		<div className={styles.content}>
			<Placeholder name="jss-layout-two-columns-content" rendering={rendering} />
		</div>
	</div>
);

export default compose(
	// other hocs goes here
	withErrorHandling()
)(TwoColumnsLayout);