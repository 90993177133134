/* eslint-disable */
import React, { Component, useState, useEffect } from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { withErrorHandling } from "~/hoc";
import { Arrow } from "~/shared-components/SvgIcons";
import { withNamespaces } from "react-i18next";
import { compose } from "recompose";
import styles from "./MatchFilter.module.scss";
import { isRedesign } from "~/utilities/helpers";

const MatchFilter = (props) => {
	const initialFilterYear = props.defaultYear
		? props.defaultYear
		: props.years
			? props.years[0]
			: null;
	const [state, setState] = useState({
		isTeamExpanded: false,
		isYearExpanded: false,
		filterYear: initialFilterYear,
		filterTeam: props.defaultTeam ? props.defaultTeam : null
	});
	const {
		defaultTeam,
		teams,
		years,
		autoWidth,
		t,
		updatedFilters
	} = props;
	const {
		isTeamExpanded,
		isYearExpanded,
		filterTeam,
		filterYear
	} = state;

	const toggleTeamDropdown = () => {
		setState(prevState => ({...state, isTeamExpanded: !prevState.isTeamExpanded, isYearExpanded: false }));
	};

	const toggleYearDropdown = () => {
		setState(prevState => ({...state, isYearExpanded: !prevState.isYearExpanded, isTeamExpanded: false }));
	};

	const getFilterTeam = team => {
		const filters = {
			team: team,
			year: filterYear
		};
		setState({...state, filterTeam: team, isTeamExpanded: false });
		updatedFilters(filters);
	};

	const getFilterYear = year => {
		const filters = {
			team: filterTeam,
			year: year
		};
		setState({...state, filterYear: year, isYearExpanded: false });
		updatedFilters(filters);
	};

	useEffect(() => {
		getFilterTeam(defaultTeam);
		return undefined;
	}, [defaultTeam]);

	return (
		<div className={`${styles.MatchFilter} ${autoWidth ? styles.autoWidth : ""}`}>
			{teams && (
				<div className={styles.filterDropdown}>
					<div className={`${styles.dropdown} ${isTeamExpanded ? styles.openDropdown : styles.closeDropdown}`}>
						<span>{t("matchesfilter_team")}</span>
						<div className={styles.dropdownButton} onClick={toggleTeamDropdown}>
							{filterTeam?.name}
							<Arrow />
						</div>

						<div className={`${styles.dropdownMenuCollapsed} ${isTeamExpanded ? styles.showFilters : ""}`}>
							<ul className={`${isRedesign ? styles.itemContainerRedesign : styles.itemContainer}`}>
								{teams.map((team, index) =>
									<li className={`${isRedesign ? styles.itemContainer : styles.item}`} key={index} onClick={() => getFilterTeam(team)}>
										{team?.name}
									</li>
								)}
							</ul>
						</div>
					</div>
				</div>
			)}

			{years && (
				<div className={styles.filterDropdown}>
					<div className={`${styles.dropdown} ${isYearExpanded ? styles.openDropdown : styles.closeDropdown}`}>
						<span>{t("matchesfilter_year")}</span>
						<div className={styles.dropdownButton} onClick={toggleYearDropdown}>
							{filterYear}
							<Arrow />
						</div>

						<div className={`${styles.dropdownMenuCollapsed} ${isYearExpanded ? styles.showFilters : ""}`}>
							<ul className={`${isRedesign ? styles.itemContainerRedesign : styles.itemContainer}`}>
								{years.map((year, index) =>
									<li className={`${isRedesign ? styles.itemRedesign : styles.item}`} key={index} onClick={() => getFilterYear(year)}>
										{year}
									</li>
								)}
							</ul>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default compose(
	// other hocs goes here
	withNamespaces(),
	withSitecoreContext(),
	withErrorHandling()
)(MatchFilter);
