import React from "react";
import { compose } from "recompose";
import { withErrorHandling } from "~/hoc";
import ScoreboardThumbnail from "../ScoreboardThumbnail";
import { optaApi } from "~/utilities/constants";

const ScoreboardThumbnailContainer = match => {
	let links = [];
	let ticketsLink;

	if (match.matchFeedUrl)
		links.push({ value: { href: match.matchFeedUrl, text: match.feedUrlName}});

	if (match.sitecoreData?.ticketsLink?.value?.href && match.status !== optaApi.matchStatus.live)
		ticketsLink = match.sitecoreData.ticketsLink;

	if (match.sitecoreData?.link1?.value?.href && match.status !== 0)
		links.push(match.sitecoreData.link1);

	if (match.sitecoreData?.link2?.value?.href && match.status !== 0)
		links.push(match.sitecoreData.link2);

	if (links.length > 3) {
		links = links.slice(0, 3);
	}

	return (
		<ScoreboardThumbnail {...{
			match,
			matchId : match?.matchID,
			broadcastLinks : match?.sitecoreData?.broadcasterLinks,
			matchUrl: match.matchFeedUrl,
			competitionId: match.competition?.id,
			competitionName: match.competition?.name,
			time: match.lengthMin,
			links,
			ticketsLink,
			contestants: match.contestants,
			matchType: match.status,
			dateTime: match.dateTime,
			currentPeriod: match.currentPeriod,
			penaltyShots: match.penaltyShots,
			venue: match.venue,
			redesign: match.redesign
		}} />
	);
};

export default compose(
	withErrorHandling()
)(ScoreboardThumbnailContainer);