import React from "react";
import { Link as RouterLink, withRouter } from "react-router-dom";
import { withErrorHandling, withGigya } from "~/hoc";
import { compose } from "recompose";
import styles from "./MembershipCard.module.scss";
import { withNamespaces } from "react-i18next";

const MembershipCard = ({fields, gigya, t}) => {
	const {
		membershipData,
		membershipProfilePage
	} = fields;

	const cardTitle = membershipData?.fields?.title?.value;
	const Description = membershipData?.fields?.description?.value;

	return (
		<>
		{fields && (
			<div className={styles.membershipCardWrapper}>
				<div className={styles.membershipCard}>
					<div className={styles.cardLevel}>{membershipData?.fields?.name?.value}</div>
					<MembershipCardPriceDisplay
						membershipLevel={membershipData?.fields?.membershipLevel}
						gigya={gigya}
						t={t} />
					<div className={styles.cardTitle}>{cardTitle}</div>
					<div className={styles.description}>
						{Description.split("\r\n").map((line, index) => (
							<div className={styles.paragraphBody} key={index}>{line}<br /></div>
						))}
					</div>
					<MembershipCardButton
						membershipData={membershipData}
						membershipProfilePage={membershipProfilePage}
						gigya={gigya}
						t={t} />
				</div>
			</div>
		)}
		</>
	);
};

const MembershipCardPriceDisplay = ({ membershipLevel, gigya, t }) => {

	const userTier = gigya?.data?.membershipTier;
	const cardMembershipTier = membershipLevel?.fields?.level?.value;
	const cardPrice = membershipLevel?.fields?.price?.value;

	return (
		<>
			{gigya.isLoggedIn && (parseInt(cardMembershipTier) === userTier)
				? <div className={`${styles.price} ${styles.currentPrice}`}>{t("membershipbanner__currentlevel_label")}*</div>
				: <div className={`${styles.price} ${styles.moneyPrice}`}>{cardPrice === (`Free`) ? cardPrice : `${cardPrice}/YR`}</div>
			}
		</>
	);
};

const MembershipCardButton = ({ membershipData, membershipProfilePage , gigya, t }) =>
{
	const userTier = gigya?.data?.membershipTier;
	const cardTier = parseInt(membershipData?.fields?.membershipLevel?.fields?.level?.value);

	const isDowngrade = gigya?.isLoggedIn && (cardTier < userTier);
	const isCurrentLevel = gigya?.isLoggedIn && (cardTier === userTier);
	const isUpgrade = gigya?.isLoggedIn && !isDowngrade && !isCurrentLevel;

	const learnMoreCta = membershipData?.fields?.learnMoreCta;
	const signupCta = membershipData?.fields?.signupCta;

	const signupButtonText = gigya?.isLoggedIn ? t("membershipbanner__upgrade_label") : t("membershipbanner__signup_label");

	return (
		<>
		{	isDowngrade && (
			<div className={`${styles.button} ${styles.learnMoreButton}`}>
				<div>
					<RouterLink to={learnMoreCta?.value?.href}>{learnMoreCta.value.text}</RouterLink>
				</div>
			</div>
		)}
		{ isCurrentLevel && (
			<div className={`${styles.button} ${styles.viewProfileButton}`}>
				<div>
					<RouterLink to={membershipProfilePage?.url}>{t("membershipbanner__view_profile")}</RouterLink>
				</div>
			</div>
		)}
		{ (isUpgrade || !gigya.isLoggedIn) && (
			<div className={`${styles.button} ${styles.signupButton}`}>
				<div>
					<RouterLink to={signupCta?.value?.href}>{signupButtonText}</RouterLink>
				</div>
			</div>
		)}
		</>
	);
};

export default compose(
	withNamespaces(),
	withErrorHandling(),
	withRouter,
	withGigya()
)(MembershipCard);