import React, { Component } from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import TablePlayers from "~/shared-components/TablePlayers";
import TableFilter from "~/shared-components/TableFilter";

class PlayerStats extends Component {
	constructor(props) {
		super(props);

		this.state = {
			filteredYear: 2019
		};
	}

	render() {
		const { rendering: { playerStats, years } } = this.props;
		const { filteredYear } = this.state;

		return (
			<>
				{playerStats &&
					<div>
						<TableFilter years={years} updatedFilteredYear={year => this.setState({ filteredYear: year })} />
						<TablePlayers heading="All Players" playerStats={playerStats.find(s => s.year === filteredYear).players} />
						<TablePlayers heading="Attackers" playerStats={playerStats.find(s => s.year === filteredYear).players.filter(p => p.position.toUpperCase() === "ATTACKER")} />
						<TablePlayers heading="Midfielders" playerStats={playerStats.find(s => s.year === filteredYear).players.filter(p => p.position.toUpperCase() === "MIDFIELDER")} />
						<TablePlayers heading="Defenders" playerStats={playerStats.find(s => s.year === filteredYear).players.filter(p => p.position.toUpperCase() === "DEFENDER")} />
						<TablePlayers heading="GoalKeepers" playerStats={playerStats.find(s => s.year === filteredYear).players.filter(p => p.position.toUpperCase() === "GOALKEEPER")} />
					</div>
				}
			</>
		);
	}
}

export default compose(
	// other hocs goes here
	withErrorHandling()
)(PlayerStats);