import React from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import { Image, RichText, Text } from "~/patches/components";
import ArrowLink from "~/shared-components/ArrowLink";
import styles from "./BannerAd.module.scss";

const BannerAd = ({ fields }) => (
	<>
		{fields && (
			<div className={styles.BannerAd}>
				<div className={styles.imageDesktop}>
					<Image field={fields.imageDesktop} srcSet={[{ mw: 1440 }, { mw: 300 }]} sizes={"(min-width: 768px) 2560px, 768px"}  />
				</div>
				<div className={styles.imageMobile}>
					<Image field={fields.imageMobile} srcSet={[{ mw: 1440 }, { mw: 300 }]} sizes={"(min-width: 768px) 2560px, 768px"} />
				</div>
				<div className={styles.copyContainer}>
					<div className={styles.copyWrapper}>
						<h2>
							<Text field={fields.heading} />
						</h2>
						<p>
							<RichText field={fields.description} />
						</p>
						<div className={styles.cta}>
							<ArrowLink
								link={fields.sectionLink}
								linkText={fields.sectionLink?.value?.text}
							/>
						</div>
					</div>
				</div>
			</div>
		)}
	</>
);

export default compose(
	// other hocs goes here
	withErrorHandling()
)(BannerAd);
