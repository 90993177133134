/* eslint-disable no-restricted-globals */
import {InfoCircle} from "~/shared-components/SvgIcons";
import React, {useEffect, useRef, useState} from "react";
import styles from "./ButtonWithPopover.module.scss";
import {isMobile} from "react-device-detect";
import PropTypes from "prop-types";

export default function ButtonWithPopover({text = '', size = 'medium'}) {
	const [openState, setOpenState] = useState(null);

	const handleClick = e => {
		e.preventDefault();
		if (!isMobile) return;
		setOpenState(!openState);
	};

	const handleHover = () => {
		if (isMobile) return;
		setOpenState(true);
	};

	const handleMouseOut = () => {
		if (isMobile) return;
		setOpenState(false);
	};

	const buttonRef = useRef(null);
	useEffect(() => {
		const handleClickOutside = e => {
			if (!buttonRef.current || buttonRef.current.contains(e.target)) {
				return;
			}
			setOpenState(false);
		};
		document.addEventListener('mousedown', handleClickOutside);
		document.addEventListener('touchstart', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
			document.removeEventListener('touchstart', handleClickOutside);
		};
	}, [buttonRef]);

	return (
		<button onClick={handleClick} onMouseOver={handleHover} onMouseOut={handleMouseOut} className={`${styles.popoverInfoButton} size-${size}`} ref={buttonRef}>
			<div className={styles.popoverContent} style={{display: openState ? `flex` : `none`}}>
				{text}
			</div>
			<InfoCircle />
		</button>
	);
}

ButtonWithPopover.defaultProps = {
	text: '',
	size: 'medium',
};

ButtonWithPopover.propTypes = {
	text: PropTypes.string.isRequired,
	size: PropTypes.oneOf(['small', 'medium']),
};
