import React from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";

const AllMatchesTemplate = ({ rendering }) => (
	<main>
		<Placeholder name="jss-layout-all-matches-template" rendering={rendering} />
	</main>
);

export default compose(
	// other hocs goes here
	withErrorHandling()
)(AllMatchesTemplate);