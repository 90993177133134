import React from "react";
import { Text } from "~/patches/components";
import styles from "./QuoteBox.module.scss";

const QuoteBox = ({ fields }) => (
	<div className={styles.QuoteBox}>
		<div className={styles.quoteContent}>
			<Text field={fields.quote} />
			<Text tag="span" field={fields.quoteName} />
		</div>
	</div>
);

export default QuoteBox;