exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".CareerMoment-module__CareerMoment--PpB-k {\n  -ms-flex-align: center;\n      align-items: center;\n  border-bottom: solid 1px rgba(0, 0, 0, 0.07);\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: justify;\n      justify-content: space-between;\n  padding: 2rem 0 2.5rem 0; }\n\n.CareerMoment-module__content--c5jZt {\n  -ms-flex: 1 1;\n      flex: 1 1;\n  text-transform: uppercase; }\n\n.CareerMoment-module__link--1rVdD {\n  float: right;\n  margin-left: 2.5rem;\n  text-align: right; }\n\n.CareerMoment-module__text--qnu2j {\n  color: #15192B; }\n  .CareerMoment-module__text--qnu2j + .CareerMoment-module__text--qnu2j {\n    font-weight: 700; }\n\n@media (min-width: 768px) {\n  .CareerMoment-module__CareerMoment--PpB-k {\n    display: block; }\n    .CareerMoment-module__CareerMoment--PpB-k .CareerMoment-module__content--c5jZt {\n      display: inline; }\n  .CareerMoment-module__text--qnu2j {\n    display: inline-block;\n    width: 21.4rem; }\n    .CareerMoment-module__text--qnu2j + .CareerMoment-module__text--qnu2j {\n      margin-left: 3.2rem; } }\n\n@media (min-width: 1025px) {\n  .CareerMoment-module__CareerMoment--PpB-k {\n    padding: 1.83rem 0 2rem 0; }\n  .CareerMoment-module__link--1rVdD {\n    margin-left: 2.08rem; } }\n", ""]);

// exports
exports.locals = {
	"CareerMoment": "CareerMoment-module__CareerMoment--PpB-k",
	"content": "CareerMoment-module__content--c5jZt",
	"link": "CareerMoment-module__link--1rVdD",
	"text": "CareerMoment-module__text--qnu2j"
};