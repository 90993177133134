import React, { Fragment } from "react";
import styles from "./SocialLinks.module.scss";
import { urlFor } from "~/utilities/sanityImage";
import { useFooter } from "../../hooks/useFooter";
import { useTeam } from "~/hooks/useTeam";

const SocialLinks = ({ socialLinks, light, alignLeft }) => (
	<Fragment>
		<div
			className={`${light ? styles.SocialLinksLight : styles.SocialLinks} ${
				alignLeft ? styles.alignLeft : "" }`}
		>
			{
				socialLinks.map((socialLink, key) => (
					<a
						key={key}
						href={socialLink.link.external}
						target="_blank"
						rel="noopener noreferrer"
					>
						{socialLink.icons[0] && <img
							className={socialLink.title.includes('Discord') ? styles.discord : styles.iconImage}
							src={urlFor(socialLink.icons[0].asset._ref)}
						/>}
					</a>

				))
			}	
		</div>
	</Fragment>
);

export const SocialLinksFooter = (props) => {
	const { data } = useFooter();
	if (data) {
		return (
			<SocialLinks {...props} socialLinks={data.socialLinks} />
		);
	}
	return null;
};

export const SocialLinksTeam = (props) => {
	const { optaTeamId } = props;
	const { socialLinks } = useTeam({optaTeamId});
	return (
		<SocialLinks {...props} socialLinks={socialLinks} />
	);
};
