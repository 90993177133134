exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PoweredByTicketmaster-module__wrap--2E0D1 {\n  width: 100%;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: end;\n      align-items: flex-end;\n  -ms-flex-pack: end;\n      justify-content: end;\n  position: relative; }\n\n.PoweredByTicketmaster-module__wrapTonly--OB70m {\n  -ms-flex-align: center;\n      align-items: center;\n  -ms-flex-pack: center;\n      justify-content: center; }\n\n.PoweredByTicketmaster-module__logoFull--2MCQI {\n  display: block;\n  margin-left: 8px;\n  margin-bottom: 2px;\n  width: 90px; }\n\n.PoweredByTicketmaster-module__poweredByText--2XV1D {\n  font-size: 10px;\n  font-weight: 500;\n  line-height: 10px;\n  letter-spacing: 0.07em;\n  color: #4d4d4d;\n  text-transform: uppercase;\n  white-space: nowrap; }\n", ""]);

// exports
exports.locals = {
	"wrap": "PoweredByTicketmaster-module__wrap--2E0D1",
	"wrapTonly": "PoweredByTicketmaster-module__wrapTonly--OB70m",
	"logoFull": "PoweredByTicketmaster-module__logoFull--2MCQI",
	"poweredByText": "PoweredByTicketmaster-module__poweredByText--2XV1D"
};