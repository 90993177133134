import React, { useState } from "react";
import { useFetchLiveMatchData } from "~/hooks/match";
import { Image } from "~/patches/components";
import BroadcasterLinks from "~/shared-components/BroadcasterLinks";
import MatchButtons from "~/shared-components/MatchButtons";
import styles from "./MatchListItem.module.scss";
import { optaApi, USTeamNames, womensTeams, validDateTimeFormat } from "~/utilities/constants";
import {allFlags, openCupFlags} from "~/utilities/flags";
import { withNamespaces } from "react-i18next";
import { MatchListBanner } from "~/shared-components/SvgIcons";
import { fieldObjHasValue } from "~/utilities";
import { getDoubleHeaderMessage } from "~/utilities/matches";
import { compose } from "recompose";
import { withErrorHandling } from "~/hoc";
import moment from "moment";
import 'moment-timezone';
import {competitions} from "~/utilities/competitions";

const valueOrDefault = (thing, fallback = null) =>
	thing === undefined || thing === null
		? fallback
		: thing;

const isUsTeamCode = (code) => code === "USA";
const isUsTeam = (team) => isUsTeamCode(team?.code);
const UsTeamName = (team) => USTeamNames[team?.id];
const usTeamPlaying = (match) => match.contestants?.filter(isUsTeam)?.[0];
const usTeamPlayingId = (match) => usTeamPlaying(match)?.id;
const TeamName = (team) => isUsTeam(team) ? UsTeamName(team) : team?.code;

const penaltyShotsScored = (contestantId, match) => {
	const { penaltyShots } = match;
	const penaltyShotsScored = penaltyShots?.filter(pk => pk.contestantId === contestantId && pk.outcome === "scored").length;

	return penaltyShotsScored;
};

const wasMadeByTeam = (shot, teamId) => shot?.contestantId === teamId;
const wasSuccessful = (shot) => shot?.outcome === "scored";
const penaltyShotsScoredByTeam = (penaltyShots, teamId) => penaltyShots?.filter(shot => wasMadeByTeam(shot, teamId) && wasSuccessful(shot)).length;

const buildMatchTeamData = (match) => {
	const homeTeam = valueOrDefault(match?.contestants?.[0]);
	const awayTeam = valueOrDefault(match?.contestants?.[1]);
	const homeName = TeamName(homeTeam);
	const awayName = TeamName(awayTeam);
	const homePenaltyShotsScored = valueOrDefault(penaltyShotsScoredByTeam(match?.penaltyShots, homeTeam?.id));
	const awayPenaltyShotsScored = match.currentPeriod === optaApi.periodType.penaltyShootout ||
		match.penaltyShots?.length > 0
		? penaltyShotsScored(match?.penaltyShots, awayTeam?.id)
		: null;

	return {
		away: awayTeam,
		awayName,
		awayPenaltyShotsScored,
		home: homeTeam,
		homeName,
		homePenaltyShotsScored,
	};
};

const MatchListItem = ({ doubleHeaderMatches, match, t, showDynamicScore = true, showButtons = true }) => {
	const [isValidMatchDateTime, setIsValidMatchDateTime] = useState(true);
	const [updatedMatch, setUpdatedMatch] = useState(match);

	useFetchLiveMatchData(match, setIsValidMatchDateTime, setUpdatedMatch);

	if (!updatedMatch) return null; // Check this now to avoid ? operators later

	const isDoubleHeader = !!doubleHeaderMatches?.length;
	const usTeamId = usTeamPlayingId(updatedMatch);
	const listItemStyle = !usTeamId
		? styles.nonUS
		: womensTeams.includes(usTeamId)
			? styles.women
			: styles.men;

	const renderDoubleHeader = (doubleHeaderMatch, key) => {
		if (!doubleHeaderMatch) return null;
		const isValidDoubleHeaderMatchDateTime = moment(doubleHeaderMatch.dateTime, validDateTimeFormat, true).isValid();
		return <MatchItem key={key} match={doubleHeaderMatch} isDoubleHeader isValidMatchDateTime={isValidDoubleHeaderMatchDateTime} showDynamicScore={showDynamicScore} showButtons={showButtons} />;
	};
	const renderDoubleHeaders = (doubleHeaderMatches, startingKey) => doubleHeaderMatches.map((match, key) => renderDoubleHeader(match, key + startingKey));

	return (
		<div className={`${styles.MatchListItem} ${listItemStyle}`} >
			<div className={styles.banner} >
				{/* background svg banner positioned absolutely at top with negative z-index */}
				<div className={styles.bannerGraphic}>
					<MatchListBanner isDoubleHeader={isDoubleHeader} />
				</div>

				{/* Match Venue, Date/Time, and Status */}
				<div className={styles.eventDetailsContainer}>
					{/* display date/time for upcoming/completed statuses, display match progress for live status */}
					<MatchStatus doubleHeaderMatches={doubleHeaderMatches} match={updatedMatch} isValidMatchDateTime={isValidMatchDateTime} t={t} />

					<Venue match={updatedMatch} />
				</div>
			</div>

			<div className={styles.matchContent}>
				<div className={styles.inner}>
					<MatchItem match={updatedMatch} isDoubleHeader={doubleHeaderMatches?.length} isValidMatchDateTime={isValidMatchDateTime} key={0} showDynamicScore={showDynamicScore} showButtons={showButtons} />
					{isDoubleHeader && renderDoubleHeaders(doubleHeaderMatches, 1)}
				</div>
			</div>
		</div>
	);
};

const MatchItem = ({ match, isValidMatchDateTime, isDoubleHeader, showDynamicScore, showButtons }) => {
	if (!match) return null;

	const {
		away,
		awayName,
		awayPenaltyShotsScored,
		home,
		homeName,
		homePenaltyShotsScored,
	} = buildMatchTeamData(match);

	let competitionName = match?.competition?.name;

	if (isDoubleHeader) {
		const individualMatchTime = !isValidMatchDateTime ? "TBD" : `${moment(match.dateTime).tz(moment.tz.guess()).format('h:mmA')} ${moment(match.dateTime).tz(moment.tz.guess()).zoneAbbr()}`;
		competitionName = `${individualMatchTime} - ${competitionName}`;
	}

	const scoreInformation = (match, home, away, showDynamicScore) => {
		const isComplete = match.status === optaApi.matchStatus.completed;
		const isUpcomingMatch = match.status === optaApi.matchStatus.upcoming;

		// We're using lists to get around the fact JS doesn't have tuples.
		// This is tricking ESLint into thinking we're rendering the component inside a list when we're not.
		/* eslint-disable react/jsx-key */
		return showDynamicScore && !isUpcomingMatch
			? [styles.teamVs, <Score homeScore={home.score} awayScore={away.score} isComplete={isComplete} />]
			: [styles.teamVsUpcoming, <Versus />];
		/* eslint-enable react/jsx-key */
	};
	const [teamInformationClassName, scoreComponent] = scoreInformation(match, home, away, showDynamicScore);

	return (
		<div className={styles.scoreContainer}>
			{/* Competition Info */}
			<div className={styles.eyebrowContainer}>
				{fieldObjHasValue(match?.sitecoreData?.matchLogo, "image") &&
					<Image field={match.sitecoreData?.listViewLogo} className={styles.matchLogo} />
				}
				<div className={styles.title}>{competitionName}</div>
			</div>

			{/* Team Information */}
			<div className={teamInformationClassName}>
				<TeamInformation match={match} team={home} teamName={homeName} />
				{scoreComponent}
				<TeamInformation match={match} team={away} teamName={awayName} flagOnTheRight />
			</div>

			{/* Sponsor Information */}
			<div className={styles.sponsor}>
				{(match?.currentPeriod === optaApi.periodType.penaltyShootout ||
					match?.currentPeriod === optaApi.periodType.fullTime &&
					match?.penaltyShots?.length > 0) &&
					<div className={styles.penaltyScores}>
						{match?.currentPeriod === optaApi.periodType.penaltyShootout
							? `(${home.code} ${homePenaltyShotsScored} - ${awayPenaltyShotsScored} ${away.code})`
							: homePenaltyShotsScored > awayPenaltyShotsScored
								? `(${home.code} wins ${homePenaltyShotsScored} - ${awayPenaltyShotsScored} on penalties)`
								: `(${away.code} wins ${awayPenaltyShotsScored} - ${homePenaltyShotsScored} on penalties)`
						}
					</div>
				}
				{match.sponsor}
			</div>

			{/* Second Column */}
			<div className={styles.broadcasterLinksAndButtons}>
				<BroadcasterLinks match={match} isListView={true} />
				{showButtons && <MatchButtons event={match} type="card" eventType="match" isListView={true} />}
			</div>
		</div>
	);
};

const CanceledStatus = () => (
	<>
		<span>Cancelled</span>
	</>
);

const DateStatus = ({ doubleHeaderMatches, matchDate }) => (
	matchDate
		? <>
			<span>{matchDate} - {doubleHeaderMatches?.length ? getDoubleHeaderMessage(doubleHeaderMatches.length) : "TBD"}</span>
		</>
		: <>
			<span>TBD</span>
		</>
);

const LiveStatus = ({ match, t }) => {
	const isHalftime = match.currentPeriod === optaApi.periodType.halfTime;
	const isExtraTime = match.currentPeriod === optaApi.periodType.endSecondHalfBeforeExtraTime ||
		match.currentPeriod === optaApi.periodType.extraTimeHalfTime ||
		match.currentPeriod === optaApi.periodType.endExtraTimeBeforePenalties;
	const isPenaltyShootout = match.currentPeriod === optaApi.periodType.penaltyShootout;
	const isFullTime = match.currentPeriod === optaApi.periodType.fullTime; // Is this the same as over?

	return (
		<div className={styles.liveStatus}>
			{isHalftime
				? t("scoreboard_halftime")
				: isExtraTime
					? `Extra Time`
					: isPenaltyShootout
						? `Penalties`
						: isFullTime
							? `Full Time`
							: `Live - ${match.time}’`
			}
		</div>
	);
};

const MatchStatus = ({ doubleHeaderMatches, match, isValidMatchDateTime, t }) => {
	const FormatMatchDate = (match) => moment(match.date).isValid() ? moment(match.date).format('MMM DD YYYY') : "TBD";

	return (
		<div className={styles.dateTime}>
			{match.status === optaApi.matchStatus.live
				? <LiveStatus match={match} t={t} />
				: match.cancelled
					? <CanceledStatus />
					: isValidMatchDateTime
						? <TimeStatus doubleHeaderMatches={doubleHeaderMatches} match={match} />
						: <DateStatus doubleHeaderMatches={doubleHeaderMatches} matchDate={FormatMatchDate(match)} />
			}
		</div>
	);
};

const TeamInformation = ({match, team, teamName, flagOnTheRight = false}) => {
	const flagsRepository = match.competition.id === competitions.openCup ? openCupFlags : allFlags;

	const flagComponent = <div className={styles.teamFlag}>{flagsRepository[team?.code]}</div>;
	const teamComponent = <div className={styles.team}>{teamName}</div>;
	return flagOnTheRight
		? <>{teamComponent}{flagComponent}</>
		: <>{flagComponent}{teamComponent}</>;
};

const Versus = () => <div className={styles.vs}>VS</div>;

const Score = ({ homeScore, awayScore, isComplete }) => (
	<div className={styles.scores}>
		<span>{homeScore}</span>
		<div className={styles.ft}>
			{isComplete ? "FT" : <>&ndash;</>}
		</div>
		<span>{awayScore}</span>
	</div>
);

const TimeStatus = ({ doubleHeaderMatches, match }) => doubleHeaderMatches?.length
	? moment(match.dateTime).tz(moment.tz.guess()).format('MMM DD YYYY').concat(` - ${getDoubleHeaderMessage(doubleHeaderMatches.length)}`)
	: `${moment(match.dateTime).tz(moment.tz.guess()).format('MMM DD YYYY - h:mmA')} ${moment(match.dateTime).tz(moment.tz.guess()).zoneAbbr()}`;

const Venue = ({ match }) => {
	const venueName = !match?.venue?.longName || match?.venue?.longName === "TBD"
		? "TBD"
		: <>{match?.venue?.longName}, {match?.venue?.location}, {match?.venue?.country}</>;

	return <div className={styles.venue}>{venueName}</div>;
};

export default compose(
	withNamespaces(),
	withErrorHandling()
)(MatchListItem);