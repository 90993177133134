import React from "react";
import { Redirect } from "react-router-dom";
// import styles from "./SetHyperlinksToRedirectWrapper.scss"

class SetHyperlinksToRedirectWrapper extends React.Component {

	/**
	 * State to handle redirect uri.
	 *
	 * @type {{redirectTo: string}}
	 */
	state = {
		redirectTo: "",
	};


	/**
	 * Handle click event with bubbling.
	 *
	 * @param {Event} event
	 */
	handleEvent = event => {
		if (this.isAnchorClicked(event)) {
			const href = event.target.getAttribute("href");
			const target = event.target.getAttribute("target");
			const external = href.indexOf("http") === 0;

			if (href && href.indexOf("mailto:") === 0) {
				return;
			}
			if (href && href.indexOf("tel:") === 0) {
				return;
			}

			event.preventDefault();

			if (external) {
				window.open(href, target);  // eslint-disable-line no-restricted-globals
				return;
			}

			if (href) {
				this.setState(() => ({ redirectTo: href }));
				/* Set history state for navigation */
				window.history.pushState(null, null, href); // eslint-disable-line no-restricted-globals
			}
		}
	};


	/**
	 * Check if we have a valid anchor click.
	 *
	 * @param {Event} event
	 * @returns {boolean}
	 */
	isAnchorClicked = event => (
		event.target.tagName === "A" && //detect if anchor is clicked.
			!event.defaultPrevented && // onClick prevented default
			event.button === 0 && // ignore everything but left clicks
			(!this.props.target || this.props.target === "_self") && // let browser handle "target=_blank" etc.
			!this.isModifiedEvent(event)
	);


	/**
	 * Check if event is modified with key controls.
	 *
	 * @param {Event} event
	 * @returns {boolean}
	 */
	isModifiedEvent = event => (
		!!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)
	);


	/**
	 * Render HTML and children as this is a wrapper component.
	 * @returns {*}
	 */
	render() {
		const { redirectTo } = this.state;
		const { children } = this.props;

		return (
			<>
				{redirectTo !== '' &&
					<Redirect to={redirectTo}/>
				}
				<span onClick={this.handleEvent}>
					{children}
				</span>
			</>
		);
	}
}

export default SetHyperlinksToRedirectWrapper;