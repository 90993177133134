exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".BroadcasterLinks-module__broadcastLink--u9rwv {\n  border: 0;\n  margin-right: 0.83rem;\n  padding: 0;\n  width: auto;\n  z-index: 1; }\n  .BroadcasterLinks-module__broadcastLink--u9rwv:last-child {\n    margin-right: 0; }\n  .BroadcasterLinks-module__broadcastLink--u9rwv img {\n    height: 2.167rem; }\n\n.BroadcasterLinks-module__broadcastLinkP1--2g2w2 {\n  padding: 0 0.33rem 0 0;\n  -webkit-filter: brightness(0) invert(1);\n          filter: brightness(0) invert(1); }\n  .BroadcasterLinks-module__broadcastLinkP1--2g2w2::-webkit-filter {\n    -webkit-filter: brightness(0) invert(1);\n            filter: brightness(0) invert(1); }\n\n.BroadcasterLinks-module__broadcastLinkContainer--2aHiY {\n  display: -ms-flexbox;\n  display: flex;\n  height: 2.167rem;\n  -ms-flex-pack: center;\n      justify-content: center;\n  left: 0;\n  margin: 0 1.667rem 0 0;\n  position: absolute;\n  right: 0;\n  width: 100%; }\n\n.BroadcasterLinks-module__broadcastLinkContainerP1--2IoUW {\n  bottom: 0;\n  margin-bottom: 2.667rem;\n  position: static; }\n\n.BroadcasterLinks-module__notATopDoubleHeader--3TTbS {\n  bottom: 17%; }\n  @media (min-width: 1025px) {\n    .BroadcasterLinks-module__notATopDoubleHeader--3TTbS {\n      top: 100%; } }\n\n@media screen and (min-width: 834px) {\n  .BroadcasterLinks-module__broadcastLinkOverride--LDffa {\n    top: 93%; } }\n\n@media screen and (max-width: 1024px) {\n  .BroadcasterLinks-module__broadcastLinkOverride--LDffa {\n    top: 70%; } }\n\n.BroadcasterLinks-module__listView--2m5Bx {\n  -ms-flex-pack: end;\n      justify-content: flex-end;\n  position: relative;\n  top: 0.5rem; }\n", ""]);

// exports
exports.locals = {
	"broadcastLink": "BroadcasterLinks-module__broadcastLink--u9rwv",
	"broadcastLinkP1": "BroadcasterLinks-module__broadcastLinkP1--2g2w2",
	"broadcastLinkContainer": "BroadcasterLinks-module__broadcastLinkContainer--2aHiY",
	"broadcastLinkContainerP1": "BroadcasterLinks-module__broadcastLinkContainerP1--2IoUW",
	"notATopDoubleHeader": "BroadcasterLinks-module__notATopDoubleHeader--3TTbS",
	"broadcastLinkOverride": "BroadcasterLinks-module__broadcastLinkOverride--LDffa",
	"listView": "BroadcasterLinks-module__listView--2m5Bx"
};