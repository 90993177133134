import React, { useState } from "react";
import { compose } from "recompose";
import { withErrorHandling } from "~/hoc/";
import MatchListItem from "./MatchListItem";
import MatchListItemRedesign from "./MatchListItemRedesign";
import { useIncrementDisplayMatchesBasedOnDoubleHeader } from "~/hooks/match";
import ShowMoreButton from "~/shared-components/ShowMoreButton";
import styles from "./MatchList.module.scss";
import { optaApi } from "~/utilities/constants";
import { getDoubleHeaderMatches } from "~/utilities/matches";
import { isRedesign } from "~/utilities/helpers";
import Carousel from "../Carousel";

const MatchList = ({ matches, type }) => {
	const [numberOfMatchesToDisplay, setNumberOfMatchesToDisplay] = useState(20);

	const incrementMatchesToDisplay = () => {
		const increment = 20;
		const sum = numberOfMatchesToDisplay + increment;

		setNumberOfMatchesToDisplay(sum);
	};
	const filteredMatches = type === 'results' ? matches.filter(match => match.status === 0) : type === 'upcoming' ? matches.filter(match => match.status > 0) : [];
	const matchesToDisplay = filteredMatches && filteredMatches.length ? filteredMatches.slice(0, numberOfMatchesToDisplay) : matches.slice(0, numberOfMatchesToDisplay);
	const doubleHeaderMatches = getDoubleHeaderMatches(matchesToDisplay);
	useIncrementDisplayMatchesBasedOnDoubleHeader(doubleHeaderMatches, matchesToDisplay, numberOfMatchesToDisplay, setNumberOfMatchesToDisplay, filteredMatches.length);
	if(isRedesign)
	{
		return (
			<div className={styles.MatchList}>
				<div className={styles.matchesContainer}>
					<Carousel>
						{matchesToDisplay?.map((match, i) => {
							if ((type === "results" && match.status === optaApi.matchStatus.completed || type === "upcoming" && match.status === optaApi.matchStatus.upcoming)
								&& !match.isPartOfADoubleHeaderMatch) {
									return <MatchListItemRedesign key={i} doubleHeaderMatches={doubleHeaderMatches[match.matchId]} match={match} />;
							}
						})}
					</Carousel>
				</div>
				<div className={styles.viewMoreContainer}>
					<ShowMoreButton
						showMoreHandler={incrementMatchesToDisplay}
						hideButton={numberOfMatchesToDisplay >= filteredMatches.length}
					/>
				</div>
			</div>
		);
		}
	else
	{
		return (
			<div className={styles.MatchList}>
				<div className={styles.matchesContainer}>
					{matchesToDisplay?.map((match, i) => {
						if ((type === "results" && match.status === optaApi.matchStatus.completed || type === "upcoming" && match.status === optaApi.matchStatus.upcoming)
							&& !match.isPartOfADoubleHeaderMatch) {
							return <MatchListItem key={i} doubleHeaderMatches={doubleHeaderMatches[match.matchId]} match={match} />;
						}
					})}
				</div>
				<div className={styles.viewMoreContainer}>
					<ShowMoreButton
						showMoreHandler={incrementMatchesToDisplay}
						hideButton={numberOfMatchesToDisplay >= filteredMatches.length}
					/>
				</div>
			</div>
		);
		}		
};

export default compose(
	withErrorHandling()
)(MatchList);