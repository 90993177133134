import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { PropTypes } from "prop-types";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import TextLink from "~/shared-components/TextLink";
import { ArrowRight } from "~/shared-components/SvgIcons";
import styles from "./RedesignSection.module.scss";

const RedesignSection = ({ arrowLink, title, link, linkText, columns, subHead, children, background, desktopImage, padding, mobileImage, showMobileOnly, showDesktopOnly, backgroundCover }) => {

	const windowWidth = useWindowWidth();

	const displayOnlyStyle = (showDesktopOnly === true && showMobileOnly === false) ? `${styles.displayDesktopOnly}`
		: (showDesktopOnly === false && showMobileOnly === true) ? `${styles.displayMobileOnly}` : "";

	const backgroundUrl = windowWidth
		? windowWidth >= 768
			? desktopImage
			: mobileImage
		: "";

	const areaStyling = {
		backgroundImage: backgroundUrl ? `url(${backgroundUrl})` : `none`,
		backgroundSize: backgroundCover,
		backgroundColor: background,
		padding: `${padding}`,
		width: `100%`
	};

	const sectionHeader = () => {
		if (link && arrowLink) {
			return (
				<Link to={link} className={styles.arrowLinkTitleText}>
					<Text field={{value: title}} />
					<ArrowRight className={arrowLink} />
				</Link>
			);
		}

		return (
			<>
				<div>
					<h2 className={styles.title}>
						<Text field={{value: title}} />
					</h2>
					{subHead && (
						<p className={styles.subHead}>
							<strong><Text field={{value: subHead}} /></strong>
						</p>
					)}
				</div>
				{link && linkText && (
					<div className={subHead ? styles.viewMore : ''}>
						<TextLink link={link} text={linkText} variant="largeBoldBlue" />
					</div>
				)}
			</>
		);
	};

	return (
		<section className={`${styles.main} ${displayOnlyStyle}`} style={areaStyling}>
			<div className='redesignGridContainer'>
				<div>
					<section className={`${(subHead || !title) ? styles.titledSectionSubheadOrNoTitle : styles.titleContainer}`}>
						{sectionHeader()}
					</section>
					<div className={`${styles.children} ${columns === '2' ? styles.columns2 : columns === '4' ? styles.columns4 : styles.columns1}`}>
						{children}
					</div>
				</div>
			</div>
		</section>
	);
};

const useWindowWidth = () => {
	const [windowWidth, setWindowWidth] = useState(undefined); // eslint-disable-line

	useEffect(() => {

		const handleWindowResize = () => {
			setWindowWidth(window.innerWidth); // eslint-disable-line
		};

		window.addEventListener('resize', handleWindowResize); // eslint-disable-line

		handleWindowResize();

		return () => window.removeEventListener('resize', handleWindowResize); // eslint-disable-line

	}, []);

	return windowWidth;
};

RedesignSection.propTypes = {
	arrowLink: PropTypes.bool,
	title: PropTypes.string,
	link: PropTypes.string,
	linkText: PropTypes.string,
	subHead: PropTypes.string,
	children: PropTypes.objectOf(PropTypes.element),
	columns: PropTypes.string,
	background: PropTypes.string,
	desktopImage: PropTypes.string,
	mobileImage: PropTypes.string,
	showMobileOnly: PropTypes.bool,
	showDesktopOnly: PropTypes.bool,
	backgroundCover: PropTypes.oneOf(['cover', 'contain', false]),
	padding: PropTypes.string
}

export default RedesignSection;