exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".P1Text-module__heading--QgkUs {\n  font-size: 5rem;\n  font-weight: 700;\n  letter-spacing: 0.05em;\n  margin-bottom: 5rem;\n  text-transform: uppercase; }\n", ""]);

// exports
exports.locals = {
	"heading": "P1Text-module__heading--QgkUs"
};