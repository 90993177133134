exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".MatchCountdown-module__MatchCountdown--lc-4D {\n  color: #15192B;\n  margin: 0;\n  padding: 0 2.5rem; }\n  .MatchCountdown-module__MatchCountdown--lc-4D h4 {\n    font-size: 1.2rem;\n    letter-spacing: 0.05em;\n    margin: 0 auto 1rem;\n    text-align: center;\n    text-transform: uppercase; }\n  .MatchCountdown-module__MatchCountdown--lc-4D dt {\n    font-family: \"USSF90MinDisplay\", helvetica, arial, sans-serif;\n    font-size: 0.9rem;\n    font-weight: 700;\n    letter-spacing: 0.05em;\n    text-transform: uppercase; }\n  .MatchCountdown-module__MatchCountdown--lc-4D dd {\n    margin: auto;\n    position: relative; }\n    .MatchCountdown-module__MatchCountdown--lc-4D dd + dt + dd:before {\n      background: #212844;\n      bottom: .1rem;\n      content: \"\";\n      height: 3.3rem;\n      left: -1.35rem;\n      opacity: .5;\n      position: absolute;\n      top: -1.5rem;\n      width: .1rem; }\n\n.MatchCountdown-module__timeTable--2IdA1 {\n  -webkit-columns: 4;\n     -moz-columns: 4;\n          columns: 4;\n  font-family: \"USSF90MinDisplay\", helvetica, arial, sans-serif;\n  font-size: 2.5rem;\n  letter-spacing: 0.05em;\n  line-height: 2.2rem;\n  margin: 0 auto;\n  position: relative;\n  text-align: center;\n  text-transform: uppercase; }\n\n@media (min-width: 768px) {\n  .MatchCountdown-module__MatchCountdown--lc-4D h4 {\n    display: none; } }\n\n.MatchCountdown-module__message--1qjjW {\n  letter-spacing: 0.05em;\n  line-height: 1.6;\n  margin-top: .5rem;\n  text-align: center;\n  text-transform: uppercase; }\n\n.MatchCountdown-module__reloadMessage--32du8 {\n  color: #C2002F;\n  cursor: pointer;\n  display: block; }\n\n@media (min-width: 992px) {\n  .MatchCountdown-module__MatchCountdown--lc-4D {\n    padding: 0 5rem; } }\n", ""]);

// exports
exports.locals = {
	"MatchCountdown": "MatchCountdown-module__MatchCountdown--lc-4D",
	"timeTable": "MatchCountdown-module__timeTable--2IdA1",
	"message": "MatchCountdown-module__message--1qjjW",
	"reloadMessage": "MatchCountdown-module__reloadMessage--32du8"
};