
import React from "react";
import { compose } from "recompose";
import { withErrorHandling } from "~/hoc";
import { optaApi } from "~/utilities/constants";
import styles from "~/shared-components/RedesignMiniFeaturedMatch/RedesignMiniFeaturedMatch.module.scss";


const MiniBroadcasterLinks = ({ match }) => {
	const blankBG = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
	return (
		<>
			{match?.sitecoreData?.broadcasterLinks?.length && match?.status !== optaApi.matchStatus.completed && (
				<div className={styles.whereToWatchSection}>
					<div className={styles.whereToWatchHeading}>Where<br/> to Watch</div>
					<div className={styles.broadcasters}>
						{match?.sitecoreData?.broadcasterLinks.map((broadcaster, index) =>
							broadcaster?.webUrl?.value && broadcaster?.broadcasterLogo?.value?.src ?
								<a key={index} href={broadcaster.webUrl.value} target="_blank" rel="noopener noreferrer" className={styles.broadcaster}>
									<img style={{backgroundImage: `url(${broadcaster.broadcasterLogo.value.src})`}} src={blankBG} alt={broadcaster.broadcasterLogo.value.alt ? broadcaster.broadcasterLogo.value.alt : "broadcast logo"} />
								</a>
								: broadcaster?.broadcasterLogo?.value?.src ?
									<span key={index} className={styles.broadcaster}>
										<img style={{backgroundImage: `url(${broadcaster.broadcasterLogo.value.src})`}} src={blankBG} alt={broadcaster.broadcasterLogo.value.alt ? broadcaster.broadcasterLogo.value.alt : "broadcast logo"} />
									</span>
									: ""
						)}
					</div>
				</div>
			)
			}
		</>
	);
};

export default compose(
	withErrorHandling()
)(MiniBroadcasterLinks);