import React from "react";
import styles from "./TertiaryNav.module.scss";
//import memberIcon from "~/assets/img/member.png";
import { LoginAvatar } from "~/shared-components";
import SearchForm from "~/shared-components/SearchForm";
/**
 * @typedef {{href: string, text: string}} link
 * @typedef {{value: link, editable: string}} field
 * @param {{loginPage: field, searchPage: field, isEditing: boolean}} props
 */
const TertiaryNav = ({ loginPage, profilePage, isEditing }) => (
	<ul className={styles.tertiaryNav}>
		<li className={styles.language}>
		</li>
		<li className={styles.search}>
			<SearchForm />
		</li>
		{loginPage && loginPage.value && (
			<li className={styles.login}>
				<LoginAvatar loginPage={loginPage} profilePage={profilePage} isEditing={isEditing} />
			</li>
		)}
	</ul>
);

export default TertiaryNav;