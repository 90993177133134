import React from "react";
import { compose } from "recompose";
import { withErrorHandling } from "~/hoc";
import styles from "./SharedTabContent.module.scss";

const SharedTabContent = tab => (
	<div key={tab.index} className={`${styles.container} ${tab.index === tab.activeTab ? styles.active : ""}`}>
		{tab.children}
	</div>
);

export default compose(
	withErrorHandling()
)(SharedTabContent);