import React from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import HighlightStats from "~/shared-components/HighlightStats";
import JsonLd from "~/components/JsonLd";
import styles from "./P1Team.module.scss";
import { SocialLinksTeam } from "~/shared-components/SocialLinks";
import { Image, Text, Link } from "~/patches/components";
import { compose } from "recompose";
import { withErrorHandling } from "~/hoc";

const P1Team = ({ fields, sitecoreContext: { route: { fields: contextFields }} }) => {
	
	const dataObject = {
		"@context": "http://schema.org",
		"@type": "SportsTeam",
		"name": contextFields.teamName.value,
		"sport": "Soccer"
	};

	return (
		<section className={styles.p1Team}>
			<div className={styles.header}>
				<div className={styles.title}>
					<Text tag="h1" field={contextFields.teamName} />
				</div>
			</div>
			<div className={styles.imageContainer}>
				<Image
					className={styles.image}
					field={fields.backgroundImage}
					srcSet={[{mw: 2560}, {mw: 768}]}
					sizes={"(min-width: 768px) 2560px, 768px"}/>
			</div>
			<div className={styles.postImageContent + (contextFields.stats.length > 0 ? '' : ` ${  styles.center}`)}>
				<HighlightStats stats={contextFields.stats} heading={contextFields.statsTitle}/>
				<div className={styles.socialContainer}>
					<SocialLinksTeam
						light
						alignLeft
						p1Team={true}
						optaTeamId={contextFields.optaTeamId.value}
					/>
				</div>
				{fields.featuredLink?.value?.href &&
					<div className={styles.featuredLinkContainer}>
						<Link field={fields.featuredLink}>
							<Image field={fields.featuredImage} />
						</Link>
					</div>
				}
			</div>
			<JsonLd data={dataObject} />
		</section>
	);
};

export default compose(
	withSitecoreContext(),
	withErrorHandling()
)(P1Team);
