/**
 * Hardcoded list of competitions.
 *
 * This is (hopefully) just a temporary solution until we start storing this
 * information in Sanity or somewhere more accessible than Sitecore.
 */
export const competitions = {
	openCup: `2bmwykmdlcc2u1c40ytoc39vy`,
};

export const invalidCompetitionTypes = [
	"287tckirbfj9nb8ar2k9r60vn", // MLS
	"e6rl4hongahbihxd3tpudespd", // Concacaf Champions Cup
];
