import { FetchService } from "./fetchService";

export class StoryService {
	// possible to add another method here depending on return object

	static getAllStories(storiesPerPage, pageNumber) {
		return FetchService.get(`/api/stories/${storiesPerPage}/${pageNumber}`);
	}

	static getAllStoriesByTag(tag, storiesPerPage, pageNumber) {
		return FetchService.get(`/api/stories/tag/${tag}/${storiesPerPage}/${pageNumber}`);
	}

	static getStoriesByCategory(category, storiesPerPage, pageNumber) {
		return FetchService.get(`/api/stories/category/${category}/${storiesPerPage}/${pageNumber}`);
	}
}