import React, { useState } from "react";
import { compose } from "recompose";
import { withErrorHandling } from "~/hoc";
import MatchCard from "~/shared-components/MatchCard";
import styles from "./MatchGrid.module.scss";
import { useIncrementDisplayMatchesBasedOnDoubleHeader } from "~/hooks/match";
import { optaApi } from "~/utilities/constants";
import { getDoubleHeaderMatches } from "~/utilities/matches";
import ShowMoreButton from "~/shared-components/ShowMoreButton";

const MatchGrid = ({ matches, type, pageEditing }) => {
	const [numberOfMatchesToDisplay, setNumberOfMatchesToDisplay] = useState(20);

	const incrementMatchesToDisplay = () => {
		const increment = 20;
		const sum = numberOfMatchesToDisplay + increment;

		setNumberOfMatchesToDisplay(sum);
	};

	const filteredMatches = type === 'results' ? matches.filter(match => match.status === 0) : type === 'upcoming' ? matches.filter(match => match.status > 0) : [];
	const matchesToDisplay = filteredMatches && filteredMatches.length ? filteredMatches.slice(0, numberOfMatchesToDisplay) : matches.slice(0, numberOfMatchesToDisplay);
	const doubleHeaderMatches = getDoubleHeaderMatches(matchesToDisplay);
	useIncrementDisplayMatchesBasedOnDoubleHeader(doubleHeaderMatches, matchesToDisplay, numberOfMatchesToDisplay, setNumberOfMatchesToDisplay, filteredMatches.length);

	return (
		<div className={styles.MatchGrid}>
			<div className={styles.matchesContainer} >
				{matchesToDisplay?.map((match, i) => {
					if ((type === "results" && match.status === optaApi.matchStatus.completed || type === "upcoming" && match.status === optaApi.matchStatus.upcoming)
						&& !match.isPartOfADoubleHeaderMatch) {
						return <MatchCard key={i} match={match} doubleHeaderMatches={doubleHeaderMatches[match.matchId]} pageEditing={pageEditing} ticketMasterIcon={true} />;
					}
				})}
			</div>
			<div className={styles.viewMoreContainer} >
				<ShowMoreButton
					showMoreHandler={incrementMatchesToDisplay}
					hideButton={numberOfMatchesToDisplay >= filteredMatches.length}
				/>
			</div>
		</div>
	);
};

export default compose(
	withErrorHandling()
)(MatchGrid);