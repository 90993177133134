exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".MatchScheduleCarousel-module__MatchScheduleCarousel--QMUgi {\n  margin: 2rem auto;\n  max-width: 1440px;\n  padding: 0 2.5rem;\n  width: 100%; }\n\n.MatchScheduleCarousel-module__matchCardContainer--38kDp {\n  min-width: 33.5rem;\n  padding: 1rem;\n  width: 33.33%; }\n\n@media (min-width: 1025px) {\n  .MatchScheduleCarousel-module__matchCardContainer--38kDp {\n    min-width: 28rem; }\n  .MatchScheduleCarousel-module__MatchScheduleCarousel--QMUgi .flickity-viewport {\n    margin: 0;\n    min-height: 41rem; }\n  .MatchScheduleCarousel-module__MatchScheduleCarousel--QMUgi .flickity-prev-next-button.previous {\n    left: -7.66rem; }\n  .MatchScheduleCarousel-module__MatchScheduleCarousel--QMUgi .flickity-prev-next-button.next {\n    right: -7.66rem; } }\n\n@media (min-width: 1440px) {\n  .MatchScheduleCarousel-module__MatchScheduleCarousel--QMUgi .flickity-prev-next-button.previous {\n    left: -10.66rem; }\n  .MatchScheduleCarousel-module__MatchScheduleCarousel--QMUgi .flickity-prev-next-button.next {\n    right: -10.66rem; } }\n", ""]);

// exports
exports.locals = {
	"MatchScheduleCarousel": "MatchScheduleCarousel-module__MatchScheduleCarousel--QMUgi",
	"matchCardContainer": "MatchScheduleCarousel-module__matchCardContainer--38kDp"
};