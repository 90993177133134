import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import { Arrow } from "~/shared-components/SvgIcons";
import styles from "./TabularData.module.scss";

class TabularData extends PureComponent {

	render() { 
		
		const { headings, rows } = this.props;
		
		return (
			<div className={styles.TabularData}>
				<div className={styles.row}>
					{headings.map((heading, index) => (
						<div className={styles.heading} key={index}>
							{heading}
						</div>
					))}
					<div></div>
				</div>
				{rows.map((row, index) => (
					<div className={styles.row} key={index} onClick={() => this.props.history.push(row.url)}>
						{row.content.map((cell, index) => (
							<div className={styles.cell} key={index}>
								{cell}
							</div>
						))}
						<div className={styles.linkCell}>
							<Arrow />
						</div>
					</div>
				))}
			</div>
		);
	}
}
export default withRouter(TabularData);