import React from "react";
import styles from "./ReactErrorHandler.module.scss";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";

const ReactErrorHandler = ({error, component, sitecoreContext: { env, role } }) => {
	const componetName = component.displayName || component.name || "Component";
	global.errorCount = (global.errorCount || 0) + 1;
	console.error(`Server Error in ${componetName}\n`, error); // eslint-disable-line no-console

	if (env === "prod" && role === "contentdelivery") {
		return <></>;
	}

	return (
		<div className={styles.reactErrorHandler}>
			<h3>Server Error in {componetName}</h3>
			<div className={styles.message}>
				{error.message}
			</div>
			<div dangerouslySetInnerHTML={{ __html: `<!-- ${error.stack} -->` }}></div>
		</div>
	);
};

export default withSitecoreContext()(ReactErrorHandler);