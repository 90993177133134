// REDESIGN 2022 //

import React from "react";
import { Text, Link } from "~/patches/components";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import styles from "./TextLink.module.scss";

const getStyles = (fields, styles) => {
	const cn = [styles.TextLink];

	cn.push(styles.TextLink);

	if (!fields.color || !fields.color.value) {
		cn.push(styles.TextLinkBlack);
	}
	else if (fields.color.value.toLowerCase() === 'black') {
		cn.push(styles.TextLinkBlack);
	}
	else if (fields.color.value.toLowerCase() === 'blue') {
		cn.push(styles.TextLinkBlue);
	}
	else if (fields.color.value.toLowerCase() === 'red') {
		cn.push(styles.TextLinkRed);
	}
	else {
		cn.push(styles.TextLinkBlack);
	}

	if (!fields.size || !fields.size.value) {
		cn.push(styles.TextLinkMedium);
	}
	else if (fields.size.value.toLowerCase() === 'small') {
		cn.push(styles.TextLinkSmall);
	}
	else if (fields.size.value.toLowerCase() === 'medium') {
		cn.push(styles.TextLinkMedium);
	}
	else if (fields.size.value.toLowerCase() === 'large') {
		cn.push(styles.TextLinkLarge);
	}
	else {
		cn.push(styles.TextLinkMedium);
	}

	if (fields.upper && fields.upper.value) {
		cn.push(styles.TextLinkUpper);
	}
	
	return cn.join(' ');
}

const TextLink = ({ fields, params }) => (
	<>
		{fields && (
			<Link className={getStyles(fields, styles)} field={fields.link}>
				<Text field={fields.text} />
			</Link>
		)}
	</>
);

export default compose(
	withErrorHandling()
)(TextLink);