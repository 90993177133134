exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".P1Image-module__P1Image--3J0LW {\n  background: #F7F7F7;\n  line-height: 0;\n  overflow: hidden;\n  position: relative; }\n  .P1Image-module__P1Image--3J0LW:before {\n    position: absolute;\n    left: 50%;\n    top: 50%;\n    -webkit-transform: translate(-50%, -50%);\n            transform: translate(-50%, -50%);\n    background: rgba(0, 0, 0, 0.2);\n    content: '';\n    height: 100%;\n    width: 100%;\n    z-index: 2; }\n  .P1Image-module__P1Image--3J0LW img {\n    left: 0;\n    top: 0;\n    width: 100%;\n    z-index: 2; }\n\n.P1Image-module__addMarginTop--kyyNi {\n  margin-top: 6rem; }\n\n.P1Image-module__textContainer--353q8 {\n  color: #FFFFFF;\n  font-size: 2.5rem;\n  font-weight: 700;\n  left: 0;\n  letter-spacing: 0.05em;\n  line-height: 2.8rem;\n  padding: 2rem;\n  position: absolute;\n  text-transform: uppercase;\n  top: 0;\n  width: 100%;\n  z-index: 4; }\n\n.P1Image-module__textContainerInvert--3bVSL {\n  color: #212844;\n  font-size: 2.5rem;\n  font-weight: 700;\n  left: 0;\n  letter-spacing: 0.05em;\n  line-height: 2.8rem;\n  padding: 2rem;\n  position: absolute;\n  text-transform: uppercase;\n  top: 0;\n  width: 100%;\n  z-index: 1; }\n\n.P1Image-module__inner--4-ESq {\n  margin: 0 auto;\n  max-width: 100%;\n  width: 1440px; }\n\n.P1Image-module__imageDesktop--C8VIE {\n  display: none; }\n\n@media (min-width: 834px) {\n  .P1Image-module__imageDesktop--C8VIE {\n    display: block; }\n  .P1Image-module__imageMobile--3QwtA {\n    display: none; } }\n\n@media (min-width: 1025px) {\n  .P1Image-module__P1Image--3J0LW {\n    padding-top: 0;\n    top: 0; }\n  .P1Image-module__addMarginTop--kyyNi {\n    margin-top: 0; }\n  .P1Image-module__textContainer--353q8 {\n    font-size: 5rem;\n    font-weight: 500;\n    line-height: normal;\n    padding: 5rem 8.333rem; }\n  .P1Image-module__textContainerInvert--3bVSL {\n    font-size: 5rem;\n    font-weight: 500;\n    line-height: normal;\n    padding: 5rem 8.333rem; } }\n", ""]);

// exports
exports.locals = {
	"P1Image": "P1Image-module__P1Image--3J0LW",
	"addMarginTop": "P1Image-module__addMarginTop--kyyNi",
	"textContainer": "P1Image-module__textContainer--353q8",
	"textContainerInvert": "P1Image-module__textContainerInvert--3bVSL",
	"inner": "P1Image-module__inner--4-ESq",
	"imageDesktop": "P1Image-module__imageDesktop--C8VIE",
	"imageMobile": "P1Image-module__imageMobile--3QwtA"
};