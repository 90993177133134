import React from "react";
import { usePlayerOfTheMatch } from "~/hooks/usePlayerOfTheMatch";
import { optaApi } from "~/utilities/constants";
import greenLight from "~/assets/img/GreenLight.png";
import styles from "./MatchPostGame.module.scss";
import scoreboardStyles from "../../components/ScoreBarFeatured/ScoreBarFeatured.module.scss";

const PhoneImage = () => (
	<div className={styles.potmThumbnail}>
		<img src="https://cdn.sanity.io/images/oyf3dba6/production/0519bf95e90d595fbac765da478ad6f31c9606e3-345x556.png?h=280&fit=scale" />
	</div>
);

const MatchInsightsPhoneImage = () => (
	<div className={styles.potmThumbnail}>
		<img src="https://cdn.sanity.io/images/oyf3dba6/production/e3fd497b1e9d1e7c80e1dc73525702891d5311ff-340x547.png?h=280&fit=scale" />
	</div>
)


export const MatchPostGame = ({ matchId, teamData, match }) => {
	const { playerOfTheMatch, isError, isLoading } = usePlayerOfTheMatch({ matchId });
	const isSeniorTeam = ['USMNT', 'USWNT'].includes(teamData?.name);
	if (!playerOfTheMatch && !isLoading) {
		return (
			<>
				<div className={match.status === optaApi.matchStatus.live ? styles.potmInfoLive : styles.potmInfo}>
					<div className={styles.potmCaption}>
						<div className={styles.potmCaptionTextWrapper}>
							<span>
								{(isSeniorTeam && !isError) && <img className={`${scoreboardStyles.liveTimeGreenLight} ${styles.blink}`} src={greenLight} />}
							</span>
							{isSeniorTeam && !isError && <span className={styles.potmCaptionText}>
								VOTE NOW FOR <span className={styles.textBold}>INSIDERS</span>
								<br />
								<span className={styles.textBold}>POTM</span> EXCLUSIVELY IN
								<br />
								OUR OFFICIAL APP!
							</span>}
							{(isError ?? !isSeniorTeam) && <span className={styles.potmCaptionText}>
								TRACK ALL
								<br />
								THE ACTION
								<br />
								WITH MATCH
								<br />
								INSIGHTS IN
								<br />
								OUR APP
							</span>}
						</div>
					</div>
					{isSeniorTeam ? <PhoneImage /> : <MatchInsightsPhoneImage />}
				</div>
				{isSeniorTeam && <div className={styles.mobilePotmVoting}>
					<div className={styles.potmCaptionTextWrapper}>
						<span>
							<img className={`${scoreboardStyles.liveTimeGreenLight} ${styles.blink}`} src={greenLight} />
						</span>
						<span className={styles.potmCaptionTextMobile}>
						POTM VOTING IS LIVE - VOTE NOW!
						</span>
					</div>
				</div>}
			</>
		);
	}

	if (playerOfTheMatch) {
		return (
			<>
				<div className={styles.potmContainer}>
					<div className={styles.potmPlayerContainer}>
						<span className={styles.insidersPotmLabel}>INSIDERS POTM:</span>
						<div className={styles.potm}>
							<img className={styles.pitchAvatarImage} src={playerOfTheMatch.PitchAvatarImage} height={27} width={27} />
							<span className={styles.potmName}>
								{playerOfTheMatch.FirstName}
								<br />
								{playerOfTheMatch.LastName}
							</span>
						</div>
					</div>
					<span className={styles.potmCaptionText}>
						VOTE
						<br />
						EXCLUSIVELY
						<br />
						ON OUR
						<br />
						OFFICIAL APP!
					</span>
					<div className={styles.potmPhoneImage}>
						<PhoneImage />
					</div>
				</div>
				<div className={styles.mobilePotm}>
					<div className={styles.potm}>
						<img className={styles.pitchAvatarImage} src={playerOfTheMatch.PitchAvatarImage} height={27} width={27} />
						<span className={styles.potmName}>
							<span className={styles.potmLabelMobile}>INSIDERS POTM:</span>
							<br />
							{playerOfTheMatch.FirstName}
							{" "}
							{playerOfTheMatch.LastName}
						</span>
					</div>
				</div>
			</>

		);
	}
	return null;

} 