import React, { PureComponent } from "react";
import { compose } from "recompose";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { Text } from "~/patches/components";
import GigyaScreenSet from "~/shared-components/GigyaScreenSet";
import styles from "./GigyaScreenSetHost.module.scss";

class GigyaScreenSetHost extends PureComponent {

	render() {
		const { 
			fields: { screenSetId, startScreenId }, 
			sitecoreContext: { pageEditing }
		} = this.props;

		return (
			<>
				{!pageEditing
					? <div className={styles.gigyaScreenSetHost}>
						<GigyaScreenSet screenSetId={screenSetId?.value} startScreenId={startScreenId?.value} />
					</div>
					: <div>
						<h2>Gigya Screen-Set Host</h2>
						<p>Screen-Set: <strong><Text field={screenSetId} /></strong></p>
						<p>Start Screen: <strong><Text field={startScreenId} /></strong></p>
					</div>
				}
			</>
		);
	}
}

export default compose(
	withSitecoreContext()
)(GigyaScreenSetHost);

