import React, { Fragment } from "react";
import { Parallaxer } from "~/shared-components";
import QuoteBox from "./QuoteBox";
import { fieldHasValue } from "~/utilities";
import { Placeholder, withSitecoreContext, withPlaceholder } from "@sitecore-jss/sitecore-jss-react";
import styles from "./FeaturedContent.module.scss";
import { compose } from "recompose";
import { withErrorHandling } from "~/hoc";

const FeaturedContent = ({ fields, sitecoreContext: { pageEditing }, featuredContentInfoBlocks, rendering }) => {

	if (!pageEditing && (fields && !fields.chevronImages)) {
		return <div data-attribute="Error: No Chevron Images or Featured Info Blocks Selected"></div>;
	}

	const { backgroundImages } = fields;
	const infoBlockCol = featuredContentInfoBlocks/*.filter(x => x.props && x.props.type !== "text/sitecore")*/.map((infoBlock,index) => <div className={styles.leftColInfoBlock} key={index}>{infoBlock}</div>); //constructInfoBlockCol(featuredContentInfoBlocks, fields);
	const infoBlockOverflow = infoBlockCol - fields.chevronImages.length;

	return (
		<>
			{fields && (
				<div className={styles.FeaturedContent}>
					<div className={styles.colLeft}>
						{fields.chevronImages.map((image, index) =>
							<Fragment key={`info-block-1-${index}`}>
								<div className={index % 2 === 0 ? styles.chevronImage : styles.chevronImageReverse} key={index}>
									<Parallaxer reverse={true} rate={80 - (index * 2)} bleed={100}>
										<div className={styles.parallaxBg} style={{ backgroundImage: `url(${`${image.src}?mw=800`})`}}></div>
									</Parallaxer>
								</div>
								{!pageEditing &&
									<>{infoBlockCol[index]}</>
								}

								{index === 0 && fieldHasValue(fields, "quote") &&
									<div className={styles.quoteContainer}>
										<QuoteBox fields={fields} />
									</div>
								}
							</Fragment>
						)}

						{!pageEditing && infoBlockOverflow > 0 && infoBlockCol.slice(infoBlockOverflow * -1).map(infoBlock => infoBlock)}
					</div>

					<div className={styles.colRight}>
						<div className={styles.rowContainer}>
							<Placeholder
								name="jss-featured-content-info-blocks"
								rendering={rendering}
								renderEach={(component, index) => (
									<div key={index} className={styles.row}>
										{component}
									</div>
								)} />
						</div>

						<div className={styles.stripeQuoteContainer}>
							<div className={styles.backgroundStripeContainer}>
								<Parallaxer reverse={false} rate={40}>
									<div className={backgroundImages[0] ? styles.backgroundStripeWithImage : styles.backgroundStripe}>
										{backgroundImages[0] &&
											<img 
												src={`${backgroundImages[0].src}?mw=1500`}
												alt={backgroundImages[0].alt}
												title={backgroundImages[0]?.title ? backgroundImages[0].title : ""} />
										}
									</div>
								</Parallaxer>
							</div>

							{fieldHasValue(fields, "quote") && infoBlockCol.length >= 2 &&
								<div className={styles.quoteContainer}>
									<QuoteBox fields={fields} />
								</div>
							}


							{(fields.chevronImages.length >= 2 || featuredContentInfoBlocks.length >= 2) &&
								<div className={styles.backgroundStripeContainerReverse}>
									<Parallaxer reverse={false} rate={70}>
										<div className={backgroundImages[0] ? styles.backgroundStripeWithImage : styles.backgroundStripe}>
											{backgroundImages[1] &&
												<img
													src={`${backgroundImages[1].src}?mw=1500`}
													alt={backgroundImages[1].alt}
													title={backgroundImages[1]?.title ? backgroundImages[1].title : ""} />}
										</div>
									</Parallaxer>
								</div>
							}
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default compose(
	withSitecoreContext(),
	withPlaceholder({ placeholder: "jss-featured-content-info-blocks", prop: "featuredContentInfoBlocks" }),
	withErrorHandling()
)(FeaturedContent);