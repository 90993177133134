import React from "react";
import styles from "./SubMenu.module.scss";
import { NavLink } from "react-router-dom";
import { Text } from "~/patches/components";
import { NavContext } from "../NavContext";
import { compose } from "recompose";
import { withErrorHandling } from "~/hoc";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { ScrollLink } from "~/patches/components/ScrollLink";

const SubMenu = ({id, subMenu, url, sitecoreContext}) => {
	const isCurrentPage = `{${sitecoreContext?.itemId}}` === id?.toLowerCase();

	return (
		<NavContext.Consumer>
			{(context) => (
				subMenu && subMenu.length > 0 && (
					<ul className={`${styles.subMenu} ${context.state.isHovering ? styles.fadeIn : ''}`}>
						{subMenu.map((item, index) => (
							<li key={`sub-menu-key-${index}`}>
								{isCurrentPage ? (
									<ScrollLink
										to={`${item.renderingId}`}
										offset={-185}
										delay={100}
										isDynamic={true}
										duration={300}>
										<span><Text field={item.sectionLabel}/></span>
									</ScrollLink>
								) : (
									<NavLink to={`${url}#${item.renderingId}`}>
										<span><Text field={item.sectionLabel}/></span>
									</NavLink>
								)}
							</li>
						))}
					</ul>
				))}
		</NavContext.Consumer>
	);
};

export default compose(
	withSitecoreContext(),
	withErrorHandling()
)(SubMenu);
