import React, { useEffect, useState } from "react";
import { withErrorHandling, withGigya } from "~/hoc";
import { compose } from "recompose";
import { Link } from "~/patches/components";
import styles from './MembershipButtonGroup.module.scss';
import ButtonRedBorder from "~/shared-components/ButtonRedBorder";
import ButtonRedFill from "~/shared-components/ButtonRedFill";
import { MembershipService } from "~/services/membershipService";
import { withNamespaces } from "react-i18next";


const MembershipButtonGroup = ({ fields, gigya, t }) => {

	const {
		authenticatedSecondaryLink,
		unauthenticatedPrimaryLink,
		unauthenticatedSecondaryLink,
		loginLink
	} = fields;

	const [upgradeLink, setUpgradeLink] = useState(null);

	useEffect(() => {
		if (gigya?.data && !upgradeLink) {
			MembershipService.getMembershipBannerUpgrade(gigya?.data?.membershipTier).then((membershipBanner) =>
			{
				setUpgradeLink({ value: {
					href: `${membershipBanner.learnMoreCta}`,
					text: `Upgrade to ${membershipBanner.name}`,
					linktype: 'internal',
					title: `Upgrade to ${membershipBanner.name}`
				}});
			});
		}
	});

	const buttonPair = (primaryLink, secondaryLink) =>
		<div className={styles.buttonPair}>
			<div className={styles.buttonShadow}>
				{ primaryLink ? <ButtonRedFill field={primaryLink} /> : "" }
			</div>
			<div className={styles.buttonShadow}>
				<ButtonRedBorder field={secondaryLink} />
			</div>
		</div>;

	const loginDiv = (notLoggedIn) =>
	{
		if (notLoggedIn) {
			return	<div className={styles.login}>
				{t("membershipbuttongroup_already_an_insider")}
				<Link className={styles.link} field={loginLink} />
			</div>;
		}
	};

	return (
	<>
		{fields && (
			<div className={styles.membershipButtonGroup}>
				{ !gigya.isLoggedIn
					? buttonPair(unauthenticatedPrimaryLink, unauthenticatedSecondaryLink)
					: buttonPair(upgradeLink, authenticatedSecondaryLink)}
				{ loginDiv(!gigya.isLoggedIn)}
			</div>
		)}
	</>
	);
};



export default compose(
	withErrorHandling(),
	withGigya(),
	withNamespaces(),
)(MembershipButtonGroup);