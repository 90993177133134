import React from "react";
import { fieldObjHasValue } from "~/utilities";
import { compose } from "recompose";
import { withErrorHandling } from "~/hoc";
import { Text } from "~/patches/components";

const LinkName = ({link, fallbackLinkName}) => (
	<>
		{fieldObjHasValue(link) && link.value !== "$name" ? (
			<Text field={link}/>
		) : (
			fallbackLinkName
		)}
	</>
);

export default compose(
	withErrorHandling()
)(LinkName);