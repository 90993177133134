import React, { Component } from "react";
import SectionLabel from "~/shared-components/SectionLabel";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import { isDesktop } from "~/utilities";
import { allFlags } from "~/utilities/flags";
import { MatchService } from "~/services/matchService";
import { optaApi, cardType } from "~/utilities/constants";
import { withNamespaces } from "react-i18next";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { ArrowSubIn, ArrowSubOut, YellowCard, RedCard, SecondYellowCard } from "~/shared-components/SvgIcons";
import HelperCommentEdit from "~/experience-editor/HelperCommentEdit";

import styles from "./Lineups.module.scss";

class Lineups extends Component {

	constructor(props) {
		super(props);

		this.timeoutDelay = 1000 * 30;
		this.matchInfoRequestInterval = 0;
		this.state = {
			updatedMatch: {}
		};
	}

	// TODO update realtime interval check for new matchinfo
	fetchLiveMatchData = matchOptaId => {
		if (matchOptaId) {
			MatchService.getMatch(matchOptaId)
				.then(response => {
					this.setState({ updatedMatch: response });
				});
		}
	}

	initialMatchFetch() {
		MatchService.getMatch(this.props.rendering.matchOptaId)
			.then(response => {
				this.setState({ updatedMatch: response });

				if (response.status === optaApi.matchStatus.live) {
					this.fetchLiveMatchData(this.props.rendering.matchOptaId);
					this.matchInfoRequestInterval = setInterval(() => {
						this.fetchLiveMatchData(this.props.rendering.matchOptaId);
					}, this.timeoutDelay);
				}
			});
	}

	componentDidMount() {
		this.initialMatchFetch();
	}

	componentWillUnmount() {
		clearInterval(this.matchInfoRequestInterval);
	}

	displayCards = playerId => {
		const { updatedMatch: { cards } } = this.state;
		const playerCards = cards ? cards.filter(card => card.playerId === playerId) : [];
		let cardOutput = null;

		if (playerCards.length === 1) { //player receives a single yellow or red card
			cardOutput = playerCards[0].cardType === cardType.red
				? <RedCard />
				: <YellowCard />;
		}
		else if (playerCards.length === 2) { //player receives a yellow card, then a second yellow (soft red) or a red
			cardOutput = playerCards[1].cardType === cardType.secondYellow
				? <SecondYellowCard />
				: <RedCard />;
		}

		return cardOutput;
	}

	render() {
		const { fields, rendering: { lineup }, t,  sitecoreContext: { pageEditing } } = this.props;

		if (!lineup && pageEditing) return <HelperCommentEdit />;
		if (!lineup && !pageEditing) return <></>;
		const { updatedMatch: { substitutions } } = this.state;
		const home = lineup?.contestants[1];
		const away = lineup?.contestants[0];
		const homeStarters = home?.players.filter(x => x.position.toLowerCase() !== "substitute");
		const awayStarters = away?.players.filter(x => x.position.toLowerCase() !== "substitute");
		const homeSubstitutes = home?.players.filter(x => x.position.toLowerCase() === "substitute");
		const awaySubstitutes = away?.players.filter(x => x.position.toLowerCase() === "substitute");
		const homeCountry = home?.country;
		const awayCountry = away?.country;
		const homeFlag = allFlags[homeCountry?.code] ? allFlags[homeCountry.code] : allFlags.PLACEHOLDER;
		const awayFlag = allFlags[awayCountry?.code ? awayCountry.code : "PLACEHOLDER"];

		const homeCoach = home?.manager;
		const awayCoach = away?.manager;

		return (
			<>
				{home && away &&
					<section className={styles.Lineups}>
						{fields?.sectionLabel && (
							<div className={styles.sectionLabelContainer}>
								<SectionLabel sectionLabel={fields.sectionLabel} />
							</div>
						)}

						<div className={styles.teams}>
							<div className={styles.inner}>
								<div className={styles.awayTeam}>
									<div className={styles.flagWrapper}>{awayFlag}</div>
									<span className={styles.teamNames}>
										{isDesktop ? awayCountry?.name : awayCountry?.code}
									</span>
								</div>
								<span className={styles.vs}>vs</span>
								<div className={styles.homeTeam}>
									<div className={styles.flagWrapper}>{homeFlag}</div>
									<span className={styles.teamNames}>
										{isDesktop ? homeCountry?.name : homeCountry?.code}
									</span>
								</div>
							</div>
						</div>

						<div className={styles.lineupsContainer}>
							<div className={styles.winLossRecords}>
								<div className={styles.inner}>
									<span className={styles.awayTeamRecord}>{away?.name}</span>
									<span className={styles.starting11}>{t("lineups_starting11")}</span>
									<span className={styles.homeTeamRecord}>{home?.name}</span>
								</div>
							</div>

							<div className={styles.columnsContainer}>
								<ul>
									{awayStarters?.map((player, index) => (
										<li className={styles.awayPlayer} key={`awayStartingPlayer-${index}`}>
											<div className={styles.playerInfo}>
												<span>{player?.matchName}</span>
												<span>{player?.position}</span>
											</div>
											<span className={styles.shirtNumber}>{player?.shirtNumber}</span>
											<div className={styles.iconContainer}>
												{substitutions && substitutions.some(sub => sub.playerOffId === player?.id) &&
													<div className={styles.substitution}>
														<ArrowSubOut />
														<span className={styles.subOff} >
															{`${substitutions.filter(sub => sub.playerOffId === player?.id)[0]?.timeMin}'`}
														</span>
													</div>
												}
												{this.displayCards(player?.id)}
											</div>
										</li>
									))}
								</ul>
								<ul>
									{homeStarters?.map((player, index) => (
										<li className={styles.homePlayer} key={`homeStartingPlayer-${index}`}>
											<div className={styles.iconContainer}>
												{this.displayCards(player?.id)}
												{substitutions && substitutions.some(sub => sub.playerOffId === player?.id) &&
													<div className={styles.substitution}>
														<ArrowSubOut />
														<span className={styles.subOff} >
															{`${substitutions.filter(sub => sub.playerOffId === player?.id)[0]?.timeMin}'`}
														</span>
													</div>
												}
											</div>
											<span className={styles.shirtNumber}>{player?.shirtNumber}</span>
											<div className={styles.playerInfo}>
												<span>{player?.matchName}</span>
												<span>{player?.position}</span>
											</div>
										</li>
									))}
								</ul>
							</div>

							<div className={styles.substitutePlayerContainer}>
								<div className={styles.headingContainer}>
									<span className={styles.heading}>{t("lineups_substitutes")}</span>
								</div>

								<div className={styles.columnsContainer}>
									<ul className={styles.awaySubstitutePlayers}>
										{awaySubstitutes?.map((player, index) => (
											<li className={styles.awayPlayer} key={`awayStartingPlayer-${index}`}>
												<div className={styles.playerInfo}>
													<span>{player?.matchName}</span>
													<span>{player?.position}</span>
												</div>
												<span className={styles.shirtNumber}>{player?.shirtNumber}</span>
												<div className={styles.iconContainer}>
													{substitutions && substitutions.some(sub => sub.playerOnId === player?.id) &&
														<div className={styles.substitution}>
															<ArrowSubIn />
															<span className={styles.subOn} >
																{`${substitutions.filter(sub => sub.playerOnId === player?.id)[0]?.timeMin}'`}
															</span>
														</div>
													}
													{this.displayCards(player?.id)}
												</div>
											</li>
										))}
									</ul>
									<ul className={styles.homeSubstitutePlayers}>
										{homeSubstitutes?.map((player, index) => (
											<li className={styles.homePlayer} key={`awayStartingPlayer-${index}`}>
												<div className={styles.iconContainer}>
													{this.displayCards(player?.id)}
													{substitutions && substitutions.some(sub => sub.playerOnId === player?.id) &&
														<div className={styles.substitution}>
															<ArrowSubIn />
															<span className={styles.subOn} >
																{`${substitutions.filter(sub => sub.playerOnId === player?.id)[0]?.timeMin}'`}
															</span>
														</div>
													}
												</div>
												<span className={styles.shirtNumber}>{player?.shirtNumber}</span>
												<div className={styles.playerInfo}>
													<span>{player?.matchName}</span>
													<span>{player?.position}</span>
												</div>
											</li>
										))}
									</ul>
								</div>
							</div>

							<div className={styles.coachesContainer}>
								<div className={styles.headingContainer}>
									<span className={styles.heading}>{t("lineups_coaches")}</span>
								</div>

								<div className={styles.columnsContainer}>
									<div className={styles.awayCoach}>{awayCoach?.firstName} {awayCoach?.firstName?.includes('Twila') ? awayCoach?.lastName?.replace('Kaufman', 'Kilgore') : awayCoach?.lastName}</div>
									<div className={styles.homeCoach}>{homeCoach?.firstName} {homeCoach?.firstName?.includes('Twila') ? homeCoach?.lastName?.replace('Kaufman', 'Kilgore') : homeCoach?.lastName}</div>
								</div>
							</div>
						</div>
					</section>
				}
			</>
		);
	}
}

export default compose(
	withSitecoreContext(),
	withNamespaces(),
	withErrorHandling()
)(Lineups);