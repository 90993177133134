/* eslint-disable no-restricted-globals */
import React, {PureComponent} from "react";
import originalStyles from "./ScoreboardThumbnail.module.scss";
import redesignStyles from "./RedesignScoreboardThumbnail.module.scss";
import { optaApi, USTeamNames } from "~/utilities/constants";
import {allFlags, openCupFlags} from "~/utilities/flags";
import { Link } from "~/patches/components";
import {Link as RouterLink} from "react-router-dom";
import { compose } from "recompose";
import { withNamespaces } from "react-i18next";
import { withErrorHandling } from "~/hoc";
import moment from "moment";
import 'moment-timezone';
import ButtonWithPopover from "~/shared-components/ButtonWithPopover";
import {competitions} from "~/utilities/competitions";
import {getPenaltiesForMatch} from "~/utilities/getPenaltiesForMatch";
import styles from "~/shared-components/RedesignMatchInfo/MatchInfo.module.scss";

function penaltyShotsScored(penaltyShots, contestantId) {
	const penaltyShotsScored = penaltyShots.filter(pk => pk.contestantId === contestantId && pk.outcome === "scored").length;

	return penaltyShotsScored;
}

function checkWinner(contestants, penaltyShots, matchType) {
	const home = contestants[0];
	const away = contestants[1];
	if (matchType === optaApi.matchStatus.completed) {
		const homePenaltyShotsScored = penaltyShotsScored(penaltyShots, home.id);
		const awayPenaltyShotsScored = penaltyShotsScored(penaltyShots, away.id);

		home.score > away.score ||
		home.score === away.score &&
		homePenaltyShotsScored > awayPenaltyShotsScored
			? home.winner = true
			: away.winner = true;
	} else {
		home.winner = false;
		away.winner = false;
	}
}

const winnerText = {fontWeight: 700};

class ScoreboardThumbnail extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			homeFlag: allFlags.PLACEHOLDER,
			awayFlag: allFlags.PLACEHOLDER,
		};
	}

	componentDidMount() {
		// Check from which repository we need to pull in the flags.
		//
		// Open Cup is currently the only competition where domestic teams are
		// participating. All other competitions are international, so we need a
		// way to distinguish country flags vs team flags (e.g. CHI can be both
		// Chicago and Chile).
		const flagsRepository = this.props.competitionId === competitions.openCup ? openCupFlags : allFlags;
		if (flagsRepository[this.props.contestants[0].code]) {
			this.setState({
				homeFlag: flagsRepository[this.props.contestants[0].code]
			});
		}
		if (flagsRepository[this.props.contestants[1].code]) {
			this.setState({
				awayFlag: flagsRepository[this.props.contestants[1].code]
			});
		}
	}

	render() {
		const {
			match,
			matchId,
			broadcastLinks,
			competitionName,
			contestants,
			links,
			matchType,
			matchUrl,
			penaltyShots,
			ticketsLink,
			venue,
			redesign
		} = this.props;
		// We use logo alt text as the label for broadcaster. This means that if
		// there's no logo, we don't have the text to show as a label for that
		// broadcast. This check is performed after the <li> element is included
		// in DOM so broadcasts without the logo alt text will be displayed as
		// empty <li> items that take space on the screen and have no link.
		const broadcastLinksWithAltText = broadcastLinks?.filter(link => link.broadcasterLogo.value.alt);
		const {
			homeFlag,
			awayFlag
		} = this.state;
		const styles = redesign ? redesignStyles : originalStyles;

		// Check how many links do we have to show. We'll use this to apply
		// different styling in case we have 4 links. We won't have styles
		// for more than 4 links though as that's very rare and would
		// require different design.
		let linkCount = links.length;
		if (matchType === optaApi.matchStatus.upcoming) {
			if (ticketsLink) {
				linkCount++;
			}
			// These take only 1 row.
			if (broadcastLinksWithAltText) {
				linkCount++;
			}
		}

		const isUpcomingMatch = matchType === optaApi.matchStatus.upcoming;

		const isOpenCupMatch = this.props.competitionId === competitions.openCup;

		const penalties = getPenaltiesForMatch({
			match,
			home: match.contestants[0],
			away: match.contestants[1],
		});

		return (
			<section className={`${ isUpcomingMatch ? styles.ScoreboardThumbnailUpcoming : styles.ScoreboardThumbnail} ${isOpenCupMatch ? styles.ScoreboardThumbnailOpenCup : ''}`}>
				<MatchStatus {...this.props} />
				<div className={styles.content}>
					<RouterLink to={matchUrl ? matchUrl : "" }>
						<span>{matchId}</span>
						<div className={`${styles.match} ${penalties.shouldDisplay ? styles.matchWithPenalties : ''}`}>
							{contestants && (
								checkWinner(contestants, penaltyShots, matchType),
								contestants.map((team, index) => {
									const showPopoverButton = redesign && team?.name && team.code !== 'USA';
									const homeOrAway = team?.id === match.contestants[0]?.id ? 'home' : 'away';
									return (
										<div key={index} className={`${styles.team} ${penalties.shouldDisplay ? styles.teamWithPenalties : ''}`} style={team.winner ? winnerText : {}}>
											{index === 0 ? homeFlag : awayFlag}
											<div className={`${styles.teamName} ${showPopoverButton ? styles.teamNameWithPopover : ''}`}>
												{team.code === 'USA' ? USTeamNames[team.id] : team.code}
												{showPopoverButton && (
													<div className={isUpcomingMatch ? styles.popoverUpcoming : styles.popover}>
														<ButtonWithPopover text={team.name} size={isUpcomingMatch ? 'medium' : 'small'} />
													</div>
												)}
											</div>
											{(matchType !== optaApi.matchStatus.upcoming) && (
												<div className={`${styles.score} ${penalties.shouldDisplay ? styles.scoreWithPenalties : ''}`}>
													{team.score}
													{penalties.shouldDisplay && (
														<div className={styles.penaltyScore}>({penalties[homeOrAway]})</div>
													)}
												</div>
											)}
										</div>
									);
								})
							)}
						</div>
						{matchType === optaApi.matchStatus.upcoming && (
							<div className={styles.competitionInfo}>
								<div className={styles.upcomingCompetitionName}>{competitionName}</div>
								<div className={styles.upcomingVenueLocation}>{venue.location}</div>
							</div>
						)}
					</RouterLink>
				</div>
				<ul className={`${redesign ? styles.linksRedesign : styles.links} ${ matchType === optaApi.matchStatus.upcoming ? styles.linksUpcoming : styles.links} ${linkCount > 3 ? styles.condensedList : ``}`}>
					{links.map((link, index) => (
						<li key={index}><Link field={link}/></li>
					))}
					{(ticketsLink && matchType === optaApi.matchStatus.upcoming) &&
						<li><Link field={ticketsLink}/></li>
					}
					{(broadcastLinksWithAltText && broadcastLinksWithAltText.length > 0 && matchType === optaApi.matchStatus.upcoming) && (
						<li className={`${styles.broadcastLinks} ${broadcastLinksWithAltText.length > 1 ? styles.broadcastLinksMultiple : ``}`}>
							{broadcastLinksWithAltText.map((link, index) => (
								link.broadcasterLogo.value.alt &&
								<Link key={index} field={{value: {href: link.webUrl.value, text: link.broadcasterLogo.value.alt}}} />
							))}
						</li>
					)}
				</ul>
			</section>
		);
	}
}

const MatchStatus = ({ redesign, competitionName, venue, matchType, time, dateTime, currentPeriod, t }) => {
	const styles = redesign ? redesignStyles : originalStyles;
	switch (matchType) {
		case optaApi.matchStatus.live:
			return (
				<div className={styles.liveStatusRedesign}>
					<div className={styles.competition}>{competitionName}</div>
					<div className={styles.mark}>
						{currentPeriod === optaApi.periodType.halfTime
							? t("scoreboard_halftime")
							: currentPeriod === optaApi.periodType.endSecondHalfBeforeExtraTime ||
							currentPeriod === optaApi.periodType.extraTimeHalfTime ||
							currentPeriod === optaApi.periodType.endExtraTimeBeforePenalties
								? `Extra Time`
								: currentPeriod === optaApi.periodType.penaltyShootout
									? `Penalties`
									: currentPeriod === optaApi.periodType.fullTime
										? `Full Time`
										: `Live - ${time}’`

						}
					</div>
				</div>
			);

		case optaApi.matchStatus.upcoming:
			return (
				<div className={`${redesign ? styles.statusRedesign : styles.status}`}>
					{dateTime.includes('TBD')
						? `${moment(dateTime.replace('TBD', '00:00:00')).tz(moment.tz.guess()).format("MMM DD")} - TBD`
						: moment(dateTime).tz(moment.tz.guess()).format("MMM DD, h:mm A")
					}
				</div>
			);

		case optaApi.matchStatus.completed:
			return (
				<div className={styles.completedStatusRedesign}>
					<div className={styles.competition}>{competitionName}</div>
					<div className={styles.info}>
						Full time, {moment(dateTime).tz(moment.tz.guess()).format("MMM DD")} | {venue.location}
					</div>
				</div>
			);
	}
};

export default compose(
	withNamespaces(),
	withErrorHandling()
)(ScoreboardThumbnail);
