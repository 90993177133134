import React from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import styles from "./StatsSidebarNav.module.scss";
import MultiLevelMenu from "~/shared-components/MultiLevelMenu";

const SidebarNav = ({ rendering: { sidebarNav, currentPageId } }) => (
	<div className={styles.container}>
		<MultiLevelMenu {...{...sidebarNav, currentPageId}} />
	</div>
);

export default compose(
	// other hocs goes here
	withErrorHandling()
)(SidebarNav);