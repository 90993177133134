import React from "react";
import { StoryCard } from "~/shared-components";
import { compose } from "recompose";
import { withErrorHandling } from "~/hoc";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { generateCateoryUrl } from "~/utilities";

const StoryThumbnail = props => {
	const { sitecoreContext: { settings }, fields } = props;
	const category = GetCategoryObj(fields);
	const categoryUrl = getCategoryUrl(category, settings);

	if (categoryUrl) {
		props.fields.category = category;
		props.fields.categoryUrl = categoryUrl;
	}

	return (
		<DefaultStoryCard {...props} />
	);
};

const GetCategoryObj = fields => {
	if (!fields) return undefined;

	const itemLink = fields?.itemLink ? fields?.itemLink[0] : {};
	const itemLinkFields = itemLink?.fields || {};
	return fields.category ? fields.category : itemLinkFields?.category;
};

/**
 * Get url for category page by category name
 *
 * @param {Object} fields
 * @param {Object} settings - SitecoreContext settings object
 */
const getCategoryUrl = (category, settings) => {
	if (!category || !settings) return undefined;
	const categoryName = typeof category === "string" ? category : category.name;

	return generateCateoryUrl(categoryName, settings);
};

//following same convention as StoryGrid by adding this component at bottom of file
const DefaultStoryCard = props => {
	if (!props?.fields) {
		return <></>;
	}

	return  (
		<StoryCard {...{
			options: {
				modifierClassName: props.options?.modifierClassName
			},
			fields: {
				heading: props.fields.heading,
				image: props.fields.image,
				date: props.fields.date,
				categoryValue: props.fields.category?.fields?.categoryName?.value,
				categoryKey: props.fields.category?.name,
				categoryUrl: props.fields.categoryUrl,
				imageText: props.fields.imageThumbnailText,
				link: props.fields.link,
				itemLink: props.fields.itemLink
			}
		}} />
	);
};


export default compose(
	withSitecoreContext(),
	withErrorHandling()
)(StoryThumbnail);