import React from "react";
import styles from "./MegaMenu.module.scss";
import { Text } from "~/patches/components";
import { NavLink } from "react-router-dom";
import { ArrowRight } from "~/shared-components/SvgIcons";
import { NavContext } from "../NavContext";
import PoweredByTicketmaster from "~/shared-components/PoweredByTicketmaster";

const MegaMenu = ({ id, mega, url, primaryNavIndexKey, isHovering ,index }) => (
	<NavContext.Consumer>
		{context => {
			const isCurrentMegaMenu = isHovering ? styles.columnsFadeIn : '';
			const setSlideAnimation = isCurrentMegaMenu ?
				styles.clipContainerActive
				: context.state.wasHovering && context.state.previouslyActiveKey === primaryNavIndexKey ?
					styles.clipContainerWasActive
					: '';

			const hasSubMenus = mega.sections.some(section => section.columns[0].links.length >= 2);

			// If there is a mega menu item on the second half  of the tablet navbar that also has a submenu section,
			// it will get positioned to the left to accomodate for screen realestate resctrictions when the submenu comes out.

			return (
				<section className={`${hasSubMenus && index === 0 ? styles.leftMegaMenu : hasSubMenus && index === 2 ? styles.leftMegaMenu2  : styles.megaMenu} ${isCurrentMegaMenu} ${(url === "/teams" || url === "/teams/") ? styles.condensedLayout : ""}`}>
					<div className={`${styles.clipContainer} ${context.state.isHovering && context.state.previouslyActiveKey ? styles.disableTransitions : ''} ${setSlideAnimation}`}>
						{mega && mega.sections && mega.sections.length > 0 && (
							<>
								<div className={`${mega?.sections.length > 6 ? styles.menuSplit : styles.menu}`}>
									{mega.sections.map((section, index) => {
										if (section.title.value === 'https://www.developmentfund.ussoccer.com/') {
											section.title.value = '';
											section.columns[0].links.push({url:'https://www.developmentfund.ussoccer.com/'});
											section.columns[0].links[0].text = {value:'Development Fund'};

										}
										const subMenuNavKey = `sub-${id}-${index}`;
										return (
											<div
												className={styles.section}
												onMouseEnter={context.isMouseEntering.bind(this, {primary: context.state.activeKey, sub: subMenuNavKey})}
												onMouseLeave={context.isMouseLeaving}
												key={subMenuNavKey}>
												{section.title.value && section.columns?.[0]?.links?.[0]?.url !== 'https://www.developmentfund.ussoccer.com/' ?
													''
													:
													<a
														className={styles.sectionLink}
														to={section?.columns[0]?.links[0]?.url}
														href={section?.columns[0]?.links[0]?.url}
														target={section.columns[0].links?.[0]?.url === 'https://www.developmentfund.ussoccer.com/' ? '_blank' : '_self'}
														rel="noopener noreferrer"
													>
														<Text field={section.columns?.[0]?.links?.[0]?.text} />
														{/*
															This does NOT feel right, but it's the only way
															we can easily add the Ticketmaster logo here.
														*/}
														{section.columns?.[0]?.links?.[0].text?.value === 'Schedule & Tickets' && (
															<span className={styles.ticketmasterLogo}>
																<PoweredByTicketmaster showTlogo />
															</span>
														)}
													</a>
												}
												{section.title.value && (
													<span className={styles.sectionLink}>
														<Text tag="span" field={section.title}/><ArrowRight />
													</span>
												)}
											</div>
										)})}
								</div>
								{mega.sections && (
									mega.sections && mega.sections.map((section, index) => (
										section.title.value && section.columns.length > 0 && (
											<div key={index} className={`${styles.submenu} ${context.state.subActiveKey === `sub-${id}-${index}` ? styles.subMenuActivated : ''}`} onMouseEnter={context.isMouseEntering.bind(this, {primary: context.state.activeKey, sub: `sub-${id}-${index}`})} onMouseLeave={context.isMouseLeaving}>
												{section && section.columns.map((column, index) => (
													<div key={index} className={styles.column}>
														<Text className={styles.columnTitle} tag="span" field={column.title}/>
														<div className={`${column?.links?.length > 6 ? styles.columnItemsSplit : styles.columnItems}`}>
															{column && column.links && column.links.map((link, index) => {
																return (
																	<NavLink key={index} to={link.url} onClick={() => context.linkClicked()} itemProp="url">
																		<Text tag="span" field={link.text}/>
																	</NavLink>
																)
															})}
														</div>
													</div>
												))}
											</div>)
									))
								)}
							</>
						)}
					</div>
				</section>
			);
		}}
	</NavContext.Consumer>
);

export default MegaMenu;
