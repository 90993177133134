import React, { Component, Fragment } from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import TableMatches from "~/shared-components/TableMatches";
import TableSeasonRecord from "~/shared-components/TableSeasonRecord";
import TableFilter from "~/shared-components/TableFilter";
import styles from "./StatsOverview.module.scss";

class StatsOverview extends Component {
	constructor(props) {
		super(props);

		this.state = {
			filteredYear: 2019
		};
	}

	render() {
		const { rendering: { statsData: { statsOverview, years } } } = this.props;
		const { filteredYear } = this.state;

		return (
			<div className={styles.StatsOverview}>
				<TableFilter years={years} updatedFilteredYear={year => this.setState({ filteredYear: year })} />

				{statsOverview && statsOverview.map((team, index) => (
					<Fragment key={index}>
						{team && (
							<>
								<div className={styles.teamTitle}>{team.name}</div>
								<div className={styles.teamContainer}>
									{team.seasonRecords && <TableSeasonRecord heading="Record" seasonRecord={team.seasonRecords.find(s => s.year === filteredYear)} />}
									{team.matches?.years && <TableMatches heading="Matches" matches={team.matches.years.find(m => m.year === filteredYear)?.matches} />}
								</div>
							</>
						)}
					</Fragment>
				))}
			</div>
		);
	}
}

export default compose(
	// other hocs goes here
	withErrorHandling()
)(StatsOverview);