import config from "~/temp/config";

export class FormService {
	static post(url, data, headers = {}) {

		if (url.indexOf("/") === 0) {
			url = `${config.sitecoreApiHost}${url}`; // eslint-disable-line
		}
		
		return fetch(url, { 
			method: "POST",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
				...headers
			}
		});
	}

	/*
	Returns status code 200-OK if processing of message was successful.
	Returns status code 403-Forbidden if reCaptcha validation fails.
	Returns status code 502-Bad Gateway if SendGrid fails to process the message or anything else goes wrong.
	*/
	static postGeneralForm(formId, formData, reCaptchaToken)
	{
		formData.reCaptchaToken = reCaptchaToken;
		return this.post(`/api/forms/post/${formId}`, formData, { "sc_apikey": `BFD4F7BAA0D84E5B940182E404F7BB1E` });
	}
}