var escape = require("../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SectionLabel-module__SectionLabel--31zpo {\n  -ms-flex-align: center;\n      align-items: center;\n  background: url(" + escape(require("./tab.svg")) + ") no-repeat center 0;\n  background-size: 100%;\n  color: #FFFFFF;\n  display: -ms-flexbox;\n  display: flex;\n  font-size: .9rem;\n  font-weight: 700;\n  height: 5rem;\n  -ms-flex-pack: center;\n      justify-content: center;\n  padding: 0 1.5rem;\n  text-align: center;\n  text-transform: uppercase;\n  width: 7.4rem; }\n  .SectionLabel-module__SectionLabel--31zpo span {\n    -webkit-transform: translate(0, -0.5rem);\n            transform: translate(0, -0.5rem); }\n  @media (min-width: 1025px) {\n    .SectionLabel-module__SectionLabel--31zpo {\n      font-size: 0.8rem;\n      height: 5.16rem;\n      padding: 0 1.5rem 1.8rem;\n      width: 8.66rem; }\n      .SectionLabel-module__SectionLabel--31zpo span {\n        -webkit-transform: translate(0, 0.5rem);\n                transform: translate(0, 0.5rem); } }\n", ""]);

// exports
exports.locals = {
	"SectionLabel": "SectionLabel-module__SectionLabel--31zpo"
};