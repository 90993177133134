import React, { Fragment } from "react";
import { Image, Text } from "~/patches/components";
import { withErrorHandling } from "~/hoc";
import { fieldHasValue } from "~/utilities";
import { compose } from "recompose";
import styles from "./ImageBlock.module.scss";
import { withSitecoreContext, Link } from "@sitecore-jss/sitecore-jss-react";

const ImageBlock = ({ fields, sitecoreContext: { pageEditing } }) => (
	<Fragment>
		{fields && (
			<div className={styles.ImageBlock}>
				{/*
					To avoid affecting other components or content that might rely on
					this, we will add a static CSS class name that we can target through
					the Story component and avoid unnecessary image padding.
				*/}
				<div className={`${styles.imageGrid} imageGrid`}>
					<WithLink link={fields.link} >
						<div className={styles.image}>
							<Image field={fields.image} />
						</div>
					</WithLink>
					<WithLink link={fields.link} >
						<div className={styles.imageMobile}>
							<Image field={fields.imageMobile} />
						</div>
					</WithLink>
					<div className={styles.imageText}>
						{(fieldHasValue(fields, "imageText") || pageEditing) && (
							<Text field={fields.imageText} />
						)}
					</div>
				</div>
			</div>
		)}
	</Fragment>
);

const WithLink = ({ link, children }) => (
	link?.value?.href
		? <Link field={link}>
			{children}
		</Link>
		: children
);

export default compose(
	withSitecoreContext(),
	withErrorHandling()
)(ImageBlock);