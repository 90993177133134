import React from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import { Link, RichText, Text } from "~/patches/components";
import { IBadge } from "~/shared-components/SvgIcons";
import styles from "./AlertCard.module.scss";

const AlertCard = ({ fields, children }) => (
	<>
		{fields && (
			<div className={styles.AlertCard}>
				<IBadge />
				<Text tag="p" field={fields.heading} className={styles.heading} />
				<RichText field={fields.description} className={styles.richText} />
				<Link field={fields.link} />
				{children}
			</div>
		)}
	</>
);

export default compose(
	// other hocs goes here
	withErrorHandling()
)(AlertCard);