exports = module.exports = require("../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Layout-module__main--192FR {\n  min-height: 60vh; }\n  @media (min-width: 1240px) {\n    .Layout-module__main--192FR {\n      margin-top: 0;\n      padding-top: 11rem; } }\n\n.Layout-module__mainRedesign--wsOxG {\n  min-height: 60vh;\n  padding-top: 0px; }\n  @media screen and (min-width: 834px) {\n    .Layout-module__mainRedesign--wsOxG {\n      margin-top: 0;\n      padding-top: 135px; } }\n\n.ad-loaded .Layout-module__main--192FR {\n  padding-top: 15.7rem; }\n  @media (min-width: 776px) {\n    .ad-loaded .Layout-module__main--192FR {\n      padding-top: 19.6rem; } }\n  @media (min-width: 1025px) {\n    .ad-loaded .Layout-module__main--192FR {\n      padding-top: 18.167rem; } }\n  @media (min-width: 1240px) {\n    .ad-loaded .Layout-module__main--192FR {\n      padding-top: 22.7rem; } }\n\n.pageEditing .Layout-module__main--192FR {\n  padding-top: 0; }\n\n.Layout-module__main--192FR #div-gpt-ad-desktop {\n  text-align: center; }\n\n.Layout-module__main--192FR #div-gpt-ad-desktop iframe {\n  margin: 2.083rem auto; }\n\n.Layout-module__adContainer--1Uyxx {\n  display: none; }\n", ""]);

// exports
exports.locals = {
	"main": "Layout-module__main--192FR",
	"mainRedesign": "Layout-module__mainRedesign--wsOxG",
	"adContainer": "Layout-module__adContainer--1Uyxx"
};