import React from "react";
import { Image, Link, Text } from "~/patches/components";
import styles from "./MembershipCallout.module.scss";
import { NavContext } from "../NavContext";
import { SetHyperlinksToRedirectWrapper } from "~/utilities";
import { GigyaConsumer } from "~/lib/gigya";

const MembershipCallout = ({ membershipCallout }) => (
	membershipCallout && membershipCallout.image && (
		<NavContext.Consumer>
			{(context) => (
				<GigyaConsumer>
					{({ isLoggedIn }) => (
						!isLoggedIn && (
							<div
								className={`${styles.membershipCallout} ${context.state.isHovering ? styles.membershipCalloutSlideDown : ''}`}>
								<div className={styles.imageWrapper}>
									<Image field={membershipCallout.image}/>
								</div>
								<div className={styles.content}>
									<div className={styles.contentInner}>
										<Text field={membershipCallout.text}/>
										<div>
											<SetHyperlinksToRedirectWrapper>
												<Link field={membershipCallout.link}/>
											</SetHyperlinksToRedirectWrapper>
											<div className={styles.arrow}>
												<svg viewBox="0 0 8 22" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M1 1L7 11L1 21" stroke="white"/>
												</svg>
											</div>
										</div>
									</div>
								</div>
							</div>
						)
					)}
				</GigyaConsumer>
			)}
		</NavContext.Consumer>
	)
);

export default MembershipCallout;