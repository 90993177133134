exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".CategoryPage-module__container--Ill9i {\n  margin: 0 auto;\n  margin-bottom: 5rem;\n  max-width: 1440px; }\n  .CategoryPage-module__container--Ill9i .CategoryPage-module__row---Yyy7 {\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-direction: row;\n        flex-direction: row;\n    -ms-flex-wrap: wrap;\n        flex-wrap: wrap;\n    margin-bottom: 4.333rem;\n    width: 100%; }\n  .CategoryPage-module__container--Ill9i .CategoryPage-module__col--1uL8j {\n    -ms-flex: 0 0 auto;\n        flex: 0 0 auto;\n    margin-bottom: 2rem;\n    padding: 0 1rem;\n    width: 100%; }\n  @media (min-width: 480px) {\n    .CategoryPage-module__container--Ill9i .CategoryPage-module__col--1uL8j {\n      width: 50%; } }\n  @media (min-width: 1025px) {\n    .CategoryPage-module__container--Ill9i .CategoryPage-module__col--1uL8j {\n      width: 33.333%; } }\n", ""]);

// exports
exports.locals = {
	"container": "CategoryPage-module__container--Ill9i",
	"row": "CategoryPage-module__row---Yyy7",
	"col": "CategoryPage-module__col--1uL8j"
};