import React from "react";
import { Image } from "~/patches/components";
import { withErrorHandling } from "~/hoc";
import { withNamespaces } from "react-i18next";
import { compose } from "recompose";
import ArrowLink from "~/shared-components/ArrowLink";
import styles from "./StadiumInfo.module.scss";

const StadiumInfo = ({ t, fields, rendering }) => {
	const { shortName, location, country } = rendering?.venue || {};

	return (
		<>
			{fields && (
				<div className={styles.stadiumInfo}>
					<div className={styles.contentWrapper}>
						<div className={styles.contentContainer}>
							<h2 className={styles.heading}>{t("stadium_info_label")}</h2>
							{shortName && <h3 className={styles.headline}>{shortName}</h3>}
							<div className={styles.address}>
								{ /** TODO: location and country should be address, we are waiting on address from backend */}
								{location && <><span>{location}</span><br/></>}
								{country && <span>{country}</span>}
							</div>
							{fields.directionsLink &&
							<div className={styles.linkWrapper}>
								<ArrowLink link={fields.directionsLink} />
							</div>}
						</div>
					</div>
					{fields.stadiumImage &&
					<div className={styles.imageWrapper}>
						<div className={styles.imageContainer}>
							<Image field={fields.stadiumImage} />
						</div>
					</div>}
				</div>
			)}
		</>
	);
};

StadiumInfo.defaultProps = {
	fields: {},
	rendering: {}
};

export default compose(
	withNamespaces(),
	withErrorHandling()
)(StadiumInfo);