import React, { PureComponent } from "react";
import PerkCard from "../PerkCard";
import { withErrorHandling, withGigya } from "~/hoc";
import { compose } from "recompose";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";

class MembershipUpgradeCard extends PureComponent {
	render() {
		const {
			fields,
			sitecoreContext: { membershipLevels },
			gigya: { data }
		} = this.props;

		const tiers = ["Standard", "Premium", "Family", "VIP", "Circle", "Supporter", "Captain", "Coach", "President"];
		const upgradeTier = tiers[data?.membershipTier];
		const upgradeTierEnum = data?.membershipTier === 1 || data?.membershipTier === 2
			? 3
			: data.membershipTier === 3
				? 5
				: data.membershipTier + 1;
		const upgradeDescription = fields[`description${upgradeTier}`];
		const upgradeHeading = fields[`heading${upgradeTier}`];
		const upgradeImage = data?.membershipTier === 4 || data?.membershipTier === 8
			? membershipLevels[data?.membershipTier]?.cardImage
			: membershipLevels[upgradeTierEnum]?.cardImage;
		const upgradeLink = fields[`link${upgradeTier}`];
		const upgradeRate = fields[`rate${upgradeTier}`];

		const upgradeTierInfo = {
			description: upgradeDescription,
			heading: upgradeHeading,
			image: upgradeImage,
			link: upgradeLink,
			rate: upgradeRate
		};

		return (
			<PerkCard fields={upgradeTierInfo} headingColor={upgradeTierEnum === 1 || upgradeTierEnum === 2 ? "dark" : "light"} />
		);
	}
}

export default compose(
	withGigya(),
	withSitecoreContext(),
	withErrorHandling()
)(MembershipUpgradeCard);