/**
 * Source: https://stackoverflow.com/a/50035797/1520477
 *
 * @returns {{majorRelease: string, majorReleaseNumeric: number, userAgent: string, asReported: string, fullRelease: string, fullReleaseNumeric: number}|undefined}
 */
export default function iOSversionCheck() {
	if (typeof navigator === 'undefined') {
		return undefined;
	}
	if (navigator.userAgent.match(/ipad|iphone|ipod/i)){ //if the current device is an iDevice
		return {
			userAgent: navigator.userAgent,
			asReported: (navigator.userAgent).match(/OS (\d)?\d_\d(_\d)?/i)[0],
			majorRelease: (navigator.userAgent).match(/OS (\d)?\d_\d(_\d)?/i)[0].split('_')[0],
			fullRelease: (navigator.userAgent).match(/OS (\d)?\d_\d(_\d)?/i)[0].replace(/_/g,"."),
			majorReleaseNumeric: +(navigator.userAgent).match(/OS (\d)?\d_\d(_\d)?/i)[0].split('_')[0].replace("OS ",""),
			// //converts versions like 4.3.3 to numeric value 4.33 for ease of numeric comparisons
			fullReleaseNumeric: +(navigator.userAgent).match(/OS (\d)?\d_\d(_\d)?/i)[0].replace("_",".").replace("_","").replace("OS ",""),
		};
	}

	return undefined;
}
