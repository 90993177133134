exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ButtonWithPopover-module__popoverInfoButton--1-7e2 {\n  width: 16px;\n  height: 16px;\n  padding: 0; }\n  .ButtonWithPopover-module__popoverInfoButton--1-7e2 svg {\n    opacity: 0.2;\n    -webkit-box-shadow: none !important;\n            box-shadow: none !important; }\n  .ButtonWithPopover-module__popoverInfoButton--1-7e2.size-small svg {\n    width: 14px !important;\n    height: 14px !important; }\n  .ButtonWithPopover-module__popoverInfoButton--1-7e2.size-medium svg {\n    width: 16px !important;\n    height: 16px !important; }\n  .ButtonWithPopover-module__popoverInfoButton--1-7e2 .ButtonWithPopover-module__popoverContent--MOGL5 {\n    background: rgba(0, 0, 0, 0.85);\n    color: #FFF;\n    padding: 4px 6px;\n    border-radius: 2px;\n    -ms-flex-pack: center;\n        justify-content: center;\n    position: absolute;\n    top: -23px;\n    left: -1px;\n    white-space: nowrap;\n    line-height: 1; }\n    .ButtonWithPopover-module__popoverInfoButton--1-7e2 .ButtonWithPopover-module__popoverContent--MOGL5:after {\n      width: 0;\n      height: 0;\n      border-left: 6px solid transparent;\n      border-right: 6px solid transparent;\n      border-top: 6px solid rgba(0, 0, 0, 0.85);\n      position: absolute;\n      bottom: -4px;\n      left: 3px;\n      content: '';\n      display: block; }\n", ""]);

// exports
exports.locals = {
	"popoverInfoButton": "ButtonWithPopover-module__popoverInfoButton--1-7e2",
	"popoverContent": "ButtonWithPopover-module__popoverContent--MOGL5"
};