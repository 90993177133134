import React, { PureComponent } from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import ArrowLink from "~/shared-components/ArrowLink";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import styles from "./MediaThumbnail.module.scss";
import LinkWrapper from "~/utilities/LinkWrapper";
import { Image, Text } from "~/patches/components";
import { fieldHasValue } from "~/utilities";
import playButton from "~/assets/svg/play-button.svg";


class MediaThumbnail extends PureComponent {

	constructor(props) {
		super(props);

		this.videoRef = React.createRef();

		this.setOptions(props.options);

		this.state = {
			playing: false
		};
	}

	_options = {
		classModifier: '',
		srcSet: [],
		sizes: ''
	};

	componentDidMount() {
		const video = this.videoRef?.current;

		if (video) {
			video.play();
			const listener = () => {
				video.pause();
				video.currentTime = 0;
				video.removeEventListener("canplay", listener);
			};
			video.addEventListener("canplay", listener);
		}
	}

	setOptions = options => {
		this._options.classModifier = options && options.classModifier ? options.classModifier : '';
		this._options.srcSet = options && options.srcSet ? options.srcSet : [{ mw: 1340 }, { mw: 768 }];
		this._options.sizes = options && options.sizes ? options.sizes : "(min-width: 768px) 1340px, 768px";
	};

	toggleVideo = () => {
		const video = this.videoRef?.current;

		if (video) {
			if (video.paused) {
				video.play();
				this.setState({ playing: true });
			} else {
				video.pause();
				this.setState({ playing: false });
			}
		}
	}


	render() {
		const { fields, sitecoreContext: { pageEditing } } = this.props;
		const { playing } = this.state;

		return (
			<>
				{fields && (
					<div className={`${styles.MediaThumbnail} ${this._options.classModifier} ${(fields.theme && fields.theme.fields && fields.theme.fields.cssName && fields.theme.fields.cssName.value) ? styles[fields.theme.fields.cssName.value] : ""}`}>
						{(fields.video && fields.video.value) ?
							// link.value.href not wrappable without actual href
							<LinkWrapper link={fieldHasValue(fields, "link") && fields.link}>
								<div className={styles.videoContainer} onClick={this.toggleVideo}>
									<video className={styles.content} ref={this.videoRef} webkit-playsinline="true" playsinline="true" loop>
										<source src={fields.video.value.src} type="video/mp4" />
										Your browser does not support video playback.
									</video>
									{!playing &&
									<div className={styles.playButton}>
										<img
											src={playButton}
											alt="Play button"
											title="Play" />
									</div>}
								</div>
							</LinkWrapper>
							:
							<>
								{fieldHasValue(fields, "image") &&
									<div className={`${styles.imageContainer} ${styles.desktopImage}`}>
										<Image
											className={styles.content}
											field={fields.image}
											srcSet={this._options.srcSet}
											sizes={this._options.sizes} />
									</div>
								}
								{fieldHasValue(fields, "imageMobile") &&
									<div className={`${styles.imageContainer} ${styles.mobileImage}`}>
										<Image
											className={styles.content}
											field={fields.imageMobile}
											srcSet={this._options.srcSet}
											sizes={this._options.sizes} />
									</div>
								}
							</>
						}
						{fields.cta &&
							<div className={styles.storyLeft}>
								{(fieldHasValue(fields, "title") || pageEditing) && (
									<div className={styles.title}>
										<Text field={fields.title} />
									</div>
								)}
								{(fieldHasValue(fields, "caption") || pageEditing) && (
									<div className={styles.caption}>
										<Text field={fields.caption} />
									</div>
								)}
								{(fieldHasValue(fields, "link", "link") || pageEditing) && (
									<div className={styles.cta}>
										<ArrowLink link={fields.link} linkText={fieldHasValue(fields, "cta") && fields.cta.value} />
									</div>
								)}
							</div>
						}
					</div>
				)}
			</>
		);
	}
}


export default compose(
	withErrorHandling(),
	withSitecoreContext()
)(MediaThumbnail);