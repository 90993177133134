import React from "react";
import { compose } from "recompose";
import { Link as RouterLink } from "react-router-dom";
import { withErrorHandling } from "~/hoc";
import { Image } from "~/patches/components";
import styles from "./LineupThumbnail.module.scss";
import placeholderSrc from "~/assets/img/story-thumbnail-placeholder.jpg";

const LineupThumbnail = ({ player }) => (
	<>
		{player &&
			<div className={`${styles.LineupThumbnail} ${!player.url ? styles.noPointer : ""}`}>
				{player.url ?
					<RouterLink className={styles.link} to={player.url}>
						<PlayerLayout player={player} />
					</RouterLink>
					:
					<PlayerLayout player={player} />
				}
			</div>
		}
	</>
);

const PlayerLayout = ({ player }) => {
	const statsKeys = Object.keys(player.sitecoreData?.stats || {});
	const statsCss = `stats${statsKeys.length > 3 ? 3 : statsKeys.length}`;

	return (
		<>
			<div className={styles.image}>
				{player?.sitecoreData?.image?.value?.src ?
					<Image
						field={player.sitecoreData.image.value}
						srcSet={[{mw: 330}, {mw: 768}]}
						sizes={"(min-width: 768px) 330px, 768px"}
					/>
					: <img
						src={placeholderSrc}
						alt="" />
				}
			</div>

			<div className={styles.content}>
				{player?.firstName && player?.lastName &&
					<div className={`${styles.playerName} ${!player.url ? styles.grayName : ""}`}>
						{player.shirtNumber} {player.firstName} {player.lastName}
					</div>
				}

				{player?.position &&
					<div className={styles.playerPosition}>
						{player.position}
					</div>
				}
			</div>

			{/* TODO: stats go here */}
			{statsKeys.length > 0 && (
				<ul className={`${styles.stats} ${statsCss in styles ?  styles[statsCss] : ""}`}>
					{statsKeys.slice(0, 3).map((statKey, index) => (
						<StatItem key={index} statName={statKey} statValue={player.sitecoreData.stats[statKey]} />
					))}
				</ul>
			)}
		</>
	);
};

const StatItem = ({ statName, statValue }) => (
	<li>
		<div className={styles.statsValue}>{statValue}</div>
		<div className={styles.statsName}>{statName}</div>
	</li>
);

export default compose(
	// other hocs goes here
	withErrorHandling()
)(LineupThumbnail);