import React, { Component } from "react";
import GigyaUtil from "./GigyaUtil";

const GigyaContext = React.createContext();

export class GigyaProvider extends Component {
	constructor(props) {
		super(props);
		this.state = { isLoading: true };		// TODO: https://developers.gigya.com/display/GD/Web+SDK
	}

	componentDidMount() {
		this.loginSubId = GigyaUtil.subscribe("login", (event) => this.onLogin(event));
		this.logoutSubId = GigyaUtil.subscribe("logout", (event) => this.onLogout(event));
	}

	componentWillUnmount() {
		if (this.loginSubId) GigyaUtil.unsubscribe("login", this.loginSubId);
		if (this.logoutSubId) GigyaUtil.unsubscribe("logout", this.logoutSubId);
	}

	onLogin(loginData) {
		this.setState({ isLoading: false, isLoggedIn: true, ...loginData });
	}

	onLogout(loginData) {
		this.setState({ isLoading: false, isLoggedIn: false, ...loginData });
	}

	render() {
		return (
			<GigyaContext.Provider value={{
				...this.state,
				mountScreenSet: GigyaUtil.mountScreenSet,
				getJWT: GigyaUtil.getJWT,
				logout: GigyaUtil.logout,
				refreshUserData: GigyaUtil.refreshUserData
			}}>
				{this.props.children}
			</GigyaContext.Provider>
		);
	}
}


export const GigyaConsumer = GigyaContext.Consumer;