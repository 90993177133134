/* eslint-disable no-restricted-globals */
import React, { Component } from "react";
import ScoreboardThumbnailContainer from "./ScoreboardThumbnailContainer";
import { withErrorHandling } from "~/hoc";
import { Arrow } from "~/shared-components/SvgIcons";
import { compose } from "recompose";
import { isServer } from "~/utilities";
import { withNamespaces } from "react-i18next";
import { MatchService } from "~/services/matchService";
import {allTeams, optaApi, stanzaUrl, youthTeams} from "~/utilities/constants";
import originalStyles from "./ScoreboardBanner.module.scss";
import redesignStyles from "./RedesignScoreboardBanner.module.scss";
import iOSversionCheck from "~/utilities/iOSversionCheck";
import {competitions, invalidCompetitionTypes} from "~/utilities/competitions";
import {Link} from "react-router-dom";
import _ from "lodash";

import { CompletedMatches } from './Matches/CompletedMatches/index';
import PoweredByTicketmaster from "~/shared-components/PoweredByTicketmaster";

class ScoreboardBanner extends Component {

	/**
	 * @namespace
	 * @property {array} liveMatches
	 * @property {array} upcomingMatches
	 * @property {array} completedMatches
	 *
	 */
	_matches = {
		liveMatches: [],
		upcomingMatches: [],
		completedMatches: [],
	};

	timeout;
	timeoutDelay = 1000 * 30;

	tilePositions = [];
	carouselOffset = 15;

	// Carousel position adjustment.
	//
	// We will use this to adjust the current position of the scoreboard.
	// This is important in case the user zoomed out, because the exact position
	// of the carousel might be off for a fraction of the pixel (and sometimes
	// 1px) so by having this we make sure that the component is still accurate.
	carouselPositionAdjustment = 2;

	constructor (props) {
		super(props);

		this.scoreboardCardRef = React.createRef();
		this.liveCardRef = React.createRef();
		this.scoreboardContainerRef = React.createRef();
		this.previousMatchesRef = React.createRef();
		this.liveMatchesRef = React.createRef();
		this.upcomingMatchesRef = React.createRef();
		this.isUnmounted = false;

		this.assignToMatchType(props.data);

		const hasMatches = {
			uswnt: false,
			usmnt: false,
			youth: false,
			openCup: false,
		};
		const liveMatches =  _.uniqBy(this._matches.liveMatches, "matchId").filter(match => !invalidCompetitionTypes.includes(match.competition.id));
		const upcomingMatches =  _.uniqBy(this._matches.upcomingMatches, "matchId").filter(match => !invalidCompetitionTypes.includes(match.competition.id));
		const completedMatches =  _.uniqBy(this._matches.completedMatches, "matchId").filter(match => !invalidCompetitionTypes.includes(match.competition.id));
		const allMatches = [
			...liveMatches,
			...upcomingMatches,
			...completedMatches,
		];
		Object.keys(hasMatches).forEach(teamGroup => {
			hasMatches[teamGroup] = allMatches.reduce((acc, curr) => {
				// This will stop the iterations early if one of the matches belongs to
				// the group we are checking for.
				return acc || this.getTeamTypeFromMatch(curr) === teamGroup;
			}, false);
		});

		this.state = {
			filterMatchesBy: `all`,
			liveMatches,
			upcomingMatches,
			completedMatches,
			hideArrows: false,
			prevDisabled: false,
			nextDisabled: false,
			hasMatches,
		};
	}

	/**
	 * Push object to array and reset it if needed.
	 *
	 * @param {array} arrayName - Name of variable array is stored in.
	 * @param {object} item - Object to push to array.
	 * @param {number} index - Iterator index to check if array should be cleared.
	 */
	pushToArray (arrayName, item, arrayIsReset) {

		if (!arrayIsReset)
			this._matches[arrayName] = [];

		this._matches[arrayName].push(item);
	}

	/**
	 * Assign match data to the correct array according to its match type.
	 *
	 * @param {object} data
	 */
	assignToMatchType(data) {
		const filteredData = data.filter(match => match.matchId !== this.props.featuredMatchId);
		let liveMatchesIsReset = false;
		let upcomingMatchesIsReset = false;
		let completedMatchesIsReset = false;

		for (const item of filteredData) {

			switch (item.status) {
				case optaApi.matchStatus.completed:
					this.pushToArray("completedMatches", item, completedMatchesIsReset);
					completedMatchesIsReset = true;
					break;

				case optaApi.matchStatus.upcoming:
					this.pushToArray("upcomingMatches", item, upcomingMatchesIsReset);
					upcomingMatchesIsReset = true;
					break;

				case optaApi.matchStatus.live:
					this.pushToArray("liveMatches", item, liveMatchesIsReset);
					liveMatchesIsReset = true;
					break;
			}
		}

		/* If live match isn't reset it means it is empty then set the livematches array to empty */
		if (!liveMatchesIsReset)
			this._matches.liveMatches = [];

		this.sortMatchesByDate();
	}

	sortAscending = (match1, match2, dateKey) => match1[dateKey].localeCompare(match2[dateKey]);

	/**
	 * Sorts an array by a date field
	 *
	 * @param {array} array - the array whose items contain date fields
	 * @param {string} dateKey - the name of the date property to sort on
	 * @param {number} maxLength - the max length of array
	 */
	sortMatchArrayByDate(array, dateKey, maxLength) {
		if (array && array.length > 0) {
			array.sort((match1, match2) => this.sortAscending(match1, match2, dateKey));
		}

		if (array.length > maxLength) {
			array.splice(0, (array.length - maxLength));
		}
	}

	/**
	 * Sorts all the matches by a date property
	 */
	sortMatchesByDate = () => {
		const { liveMatches, upcomingMatches, completedMatches } = this._matches;
		const dateKey = "dateTime";
		const path = isServer ? "" : window.location.pathname; //eslint-disable-line
		const limit = path === "/" ? 60 : 30;

		const maxLiveMatches = liveMatches.length;
		let diff = limit - maxLiveMatches;
		let maxUpcomingMatches;
		let maxCompletedMatches;

		if (upcomingMatches.length >= diff) {
			maxUpcomingMatches = diff;
			maxCompletedMatches = 0;
		} else {
			maxUpcomingMatches = upcomingMatches.length;
			diff -= maxUpcomingMatches;

			if (completedMatches.length >= diff) {
				maxCompletedMatches = diff;
			} else {
				maxCompletedMatches = completedMatches.length;
			}
		}

		this.sortMatchArrayByDate(liveMatches, dateKey, maxLiveMatches);
		this.sortMatchArrayByDate(upcomingMatches, dateKey, maxUpcomingMatches);
		this.sortMatchArrayByDate(completedMatches, dateKey, maxCompletedMatches);

		this.setState({
			liveMatches: this._matches.liveMatches,
			upcomingMatches: this._matches.upcomingMatches,
			completedMatches: this._matches.completedMatches,
		});
	}

	/**
	 * Fetch match data
	 *
	 * @param {string} contestantId
	 */
	fetchLiveMatchData() {
		if (this.props.matchIds) {
			MatchService.getScoreboardByMatches(this.props.matchIds)
				.then(response => {
					const filteredResponse0 = response.filter(match => match.matchId === this.props.featuredMatchId);
					const filteredResponse = _.uniqBy(filteredResponse0, "matchId").filter(match => !invalidCompetitionTypes.includes(match.competition.id));
					this.assignToMatchType(filteredResponse);
					this.setState({
						liveMatches: this._matches.liveMatches,
						upcomingMatches: this._matches.upcomingMatches,
						completedMatches: this._matches.completedMatches
					});

					if (!this.isUnmounted) {
						this.timeout = setTimeout(() => {
							this.fetchLiveMatchData();
						}, this.timeoutDelay);
					}
				});
		}
		else {
			MatchService.getScoreboardByContestants(this.props.contestantIds)
				.then(response => {
					const filteredResponse = _.uniqBy(response, "matchId").filter(match => !invalidCompetitionTypes.includes(match.competition.id));
					this.assignToMatchType(filteredResponse);
					this.setState({
						liveMatches: this._matches.liveMatches,
						upcomingMatches: this._matches.upcomingMatches,
						completedMatches: this._matches.completedMatches
					});

					if (!this.isUnmounted) {
						this.timeout = setTimeout(() => {
							this.fetchLiveMatchData();
						}, this.timeoutDelay);
					}
				});
		}
	}

	/**
	 * Method for calculating the next position of the carousel.
	 *
	 * It will compare the current position within the carousel to the list where
	 * we store starting positions of all tiles. Based on this, the method will
	 * return the exact position of the next or previous tile the user should
	 * see. It will always scroll 1 tile at a time, and it will always show the
	 * full tile, even when the tiles are of different widths.
	 */
	calculateNextPosition = (forward = false) => {
		const tilePositions = forward ? [...this.tilePositions] : [...this.tilePositions].reverse();
		// Rounding the current position is important in case the user zoomed out
		// and current position is a float.
		let currentPosition = Math.round(this.scoreboardContainerRef.current.scrollLeft);
		if (forward) {
			currentPosition += this.carouselPositionAdjustment;
		} else {
			currentPosition -= this.carouselPositionAdjustment;
		}
		for (let i = 0; i < tilePositions.length; i++) {
			const newTilePosition = tilePositions[i];
			if (
				(forward && newTilePosition > currentPosition) ||
				(!forward && newTilePosition < currentPosition)
			) {
				return newTilePosition;
			}
		}

		return currentPosition;
	}

	moveScoreboard = forward => {
		requestAnimationFrame(() => {
			this.scoreboardContainerRef.current.scrollLeft = this.calculateNextPosition(forward);
		}, 40);
	}

	componentDidMount() {
		if (this.state.liveMatches.length > 0 || this.state.upcomingMatches.length > 0) {
			this.fetchLiveMatchData();
		}

		this.setInitialScrollPosition();
	}

	setInitialScrollPosition = (animate = false) => {
		let previousWidth = 0,
			previousPaddingRight = 0,
			previousTotalWidth = 0,
			liveWidth = 0,
			livePaddingRight = 0,
			liveTotalWidth = 0,
			upcomingWidth = 0,
			upcomingPaddingRight = 0,
			upcomingTotalWidth = 0;

		if (this.previousMatchesRef.current) {
			const computedStyle = getComputedStyle(this.previousMatchesRef.current);

			previousWidth = parseInt(computedStyle.width, 10);
			previousPaddingRight = parseInt(computedStyle.paddingRight, 10);
			previousTotalWidth = previousWidth + previousPaddingRight;

			this.setTilePositionsInSection(this.previousMatchesRef);
		}

		if (this.liveMatchesRef.current) {
			const computedStyle = getComputedStyle(this.liveMatchesRef.current);

			liveWidth = parseInt(computedStyle.width, 10);
			livePaddingRight = parseInt(computedStyle.paddingRight, 10);
			liveTotalWidth = liveWidth + livePaddingRight;

			this.setTilePositionsInSection(this.liveMatchesRef);
		}

		if (this.upcomingMatchesRef.current) {
			const computedStyle = getComputedStyle(this.upcomingMatchesRef.current);

			upcomingWidth = parseInt(computedStyle.width, 10);
			upcomingPaddingRight = parseInt(computedStyle.paddingRight, 10);
			upcomingTotalWidth = upcomingWidth + upcomingPaddingRight;

			this.setTilePositionsInSection(this.upcomingMatchesRef);
		}

		if (this.props.hideArrows) {
			// If we're getting from props that the arrows should be hidden, hide them.
			this.setState({
				hideArrows: true
			});
		}

		if ((previousTotalWidth + liveTotalWidth + upcomingTotalWidth) < this.scoreboardContainerRef.current.clientWidth) {
			// if the width of the scoreboard contents is less than the scrollable area, don't show arrows.
			this.setState({
				hideArrows: true
			});
		}

		const defaultPosition = this.getDefaultCarouselPosition();
		if (animate) {
			requestAnimationFrame(() => {
				this.scoreboardContainerRef.current.scrollLeft = defaultPosition;
			}, 40);
		} else {
			this.scoreboardContainerRef.current.scrollLeft = defaultPosition;
		}
	}

	/**
	 * Method for storing tile positions in given section.
	 *
	 * This is then used in calculateNextPosition() for determining which tile
	 * should the user see when they use the carousel controls.
	 *
	 * Tile widths are calculated on the fly. The only hardcoded value is the
	 * carousel offset, which is the distance between the tiles.
	 *
	 * @param sectionRef
	 */
	setTilePositionsInSection = sectionRef => {
		// Offset of each tile is relative to the parent element (ie section the
		// tile is displayed in) so we have to add position of the section itself
		// in order to get the position within the whole carousel.
		const parentOffset = sectionRef.current.offsetLeft;
		[...sectionRef.current.children].map(child => {
			const sectionTiles = child.querySelectorAll(`[data-match-tile]`);
			if (sectionTiles === null) {
				return;
			}
			sectionTiles.forEach((tile, index) => {
				let tilePosition = parentOffset + tile.offsetLeft;
				// This will offset the position of the carousel only for the first
				// tile. This is necessary so the tile and sticky section label
				// align vertically.
				if (index === 0) {
					tilePosition -= this.carouselOffset;
				}
				// All other tiles should be moved to the left, so there is some
				// space between the parent container and tile itself.
				else {
					tilePosition += this.carouselOffset;
				}
				this.tilePositions.push(tilePosition);
			});
		});
	}

	/**
	 * Get the offset for initial section we should show in the scoreboard.
	 *
	 * If the default section to show in the scoreboard needs to be changed,
	 * this is the place to do so.
	 *
	 * We will first check to see if there are any live matches, then upcoming,
	 * and finally previous matches, and based on that return the starting
	 * position of that section.
	 *
	 * @returns {number|number|*|number}
	 */
	getOffsetForInitialSectionToShow() {
		if (this.liveMatchesRef.current) {
			return this.liveMatchesRef.current.offsetLeft;
		}
		else if (this.upcomingMatchesRef.current) {
			return this.upcomingMatchesRef.current.offsetLeft;
		}
		else if (this.previousMatchesRef.current) {
			return this.previousMatchesRef.current.offsetLeft;
		}

		return 0;
	}

	/**
	 * Calculate default carousel position.
	 *
	 * This will check which matches are available and based on that get the
	 * offset for the carousel, so it shows that group of matches by default.
	 */
	getDefaultCarouselPosition() {
		const mobileScreen = window.innerWidth < 834;

		// On mobile devices we will align the start of the carousel to the left
		// edge of the screen. There isn't any adjustment if the section is the
		// first one in the carousel (ie offset === 0).
		const offset = this.getOffsetForInitialSectionToShow();
		const mobileAdjustment = (mobileScreen && offset !== 0) ? 15 : 0;

		return offset + mobileAdjustment;
	}

	componentDidUpdate(prevProps, prevState) {
		const { data: prevData } = prevProps;
		const { data } = this.props;
		const {
			filterMatchesBy,
			liveMatches,
			upcomingMatches,
			completedMatches,
		} = this.state;

		if (prevData !== data) {
			this.assignToMatchType(data);
		}

		// We will re-filter the matches only if there has been a change in the
		// filter we need to use, or the change in matches we need to show (ie
		// when they are refreshed and there are new ones to show).
		if (
			prevState.filterMatchesBy !== filterMatchesBy ||
			prevState.liveMatches.length !== liveMatches.length ||
			prevState.upcomingMatches.length !== upcomingMatches.length ||
			prevState.completedMatches.length !== completedMatches.length
		) {
			const matchFilter = match => (
				filterMatchesBy === `all` || this.getTeamTypeFromMatch(match) === filterMatchesBy
			);
			this.setState({
				liveMatches: this._matches.liveMatches.filter(matchFilter),
				upcomingMatches: this._matches.upcomingMatches.filter(matchFilter),
				completedMatches: this._matches.completedMatches.filter(matchFilter),
			}, () => {
				this.setInitialScrollPosition(true);
			});
		}
	}

	componentWillUnmount() {
		clearTimeout(this.timeout);
		this.isUnmounted = true;
	}

	getTeamTypeFromMatch(match) {
		const teamId = match?.contestants.filter(contestant => contestant.code === `USA`)[0]?.id;

		if (match?.competition?.id === competitions.openCup) {
			return `openCup`;
		}
		if (teamId === allTeams.senior.wnt) {
			return `uswnt`;
		}
		if (teamId === allTeams.senior.mnt) {
			return `usmnt`;
		}
		if (youthTeams.includes(teamId)) {
			return `youth`;
		}

		return undefined;
	}

	/**
	 * Scroll handler will disable/enable buttons depending on the position of
	 * the carousel.
	 *
	 * We assume that the scrollbar will always have enough elements to show on
	 * the screen so users can scroll left and right.
	 *
	 * @param el
	 */
	scoreboardScrollHandler(el) {
		const scrollPosition = el.target.scrollLeft;
		const {prevDisabled, nextDisabled} = this.state;

		// Logic for disabling the previous button.
		if (scrollPosition <= 0 && prevDisabled === false) {
			this.setState({prevDisabled: true});
		}
		if (scrollPosition > 0 && prevDisabled === true) {
			this.setState({prevDisabled: false});
		}

		// Logic for disabling the next button.
		if (scrollPosition <= 0 && nextDisabled === true) {
			this.setState({nextDisabled: true});
		}
		if ((scrollPosition + el.target.offsetWidth) >= el.target.scrollWidth && nextDisabled === false) {
			this.setState({nextDisabled: true});
		} else if ((scrollPosition + el.target.offsetWidth) < el.target.scrollWidth && nextDisabled === true) {
			this.setState({nextDisabled: false});
		}
	}

	render () {
		const { t, redesign, showFilters } = this.props;
		const { hideArrows, prevDisabled, nextDisabled } = this.state;
		const styles = redesign ? redesignStyles : originalStyles;

		const iosVersion = iOSversionCheck();

		// Check if all matches are from Open Cup.
		//
		// If yes, we will hide the controls since there is no point in showing the
		// filters and schedule/calendar links in that case.
		//
		// There don't seem to be any controls in Sitecore we can use for this, so
		// we have to hardcode the condition. Hopefully this gets refactored, and
		// we can then use a more modular and abstract approach.
		const isOnlyOpenCup = [
			...this._matches.upcomingMatches,
			...this._matches.liveMatches,
			...this._matches.completedMatches,
		].find(match => match?.competition?.id !== competitions.openCup) === undefined;

		return (
			<div className={`${styles.wrap} ${isOnlyOpenCup ? styles.wrapWithoutFiltersAndControls : ''}`}>
				<section className={`${hideArrows ? styles.fullScoreboard : styles.scoreboardContainer} ${iosVersion?.majorReleaseNumeric !== 15 ? styles.withAnimation : ''} ${isOnlyOpenCup ? styles.fullScoreboardWithoutControls : ''}`} ref={this.scoreboardContainerRef} onScroll={this.scoreboardScrollHandler.bind(this)}>
					{!hideArrows && <button className={`${styles.previous} ${prevDisabled ? styles.disabled : ``}`} onClick={() => this.moveScoreboard()}><Arrow /></button>}
					<div className={styles.ScoreboardBanner}>
						{this.state.completedMatches && this.state.completedMatches.length > 0 && (
							<aside className={`${styles.section} ${isOnlyOpenCup ? styles.sectionWithoutFiltersAndControls : ``} ${styles.completedSection}`} ref={this.previousMatchesRef}>
								<header>
									{t('scoreboard_recent-results')}
								</header>
								<div className={styles.content}>
									<CompletedMatches {...{completedMatches: this.state.completedMatches, t, scoreboardCardRef: this.scoreboardCardRef}} />
								</div>
							</aside>
						)}
						{this.state.liveMatches && this.state.liveMatches.length > 0 && (
							<aside className={`${styles.section} ${isOnlyOpenCup ? styles.sectionWithoutFiltersAndControls : ``} ${styles.liveSection}`} ref={this.liveMatchesRef}>
								<header>
									{t('scoreboard_current-match')}
								</header>
								<div className={styles.content}>
									{this.state.liveMatches.map((item, index) => (
										<div key={`${item.matchId}-${index}`} className={styles.item} ref={this.liveCardRef} data-match-tile={true}>
											<ScoreboardThumbnailContainer redesign={redesign} {...{...item, feedUrlName: t("scoreboard_current_feed_link_name")}} />
										</div>
									))}
								</div>
							</aside>
						)}
						{this.state.upcomingMatches && this.state.upcomingMatches.length > 0 && (
							<aside className={`${styles.section} ${isOnlyOpenCup ? styles.sectionWithoutFiltersAndControls : ``} ${styles.upcomingSection}`} ref={this.upcomingMatchesRef}>
								<header>
									<span>
										{t('scoreboard_upcoming-matches')}
										&nbsp;&nbsp;-&nbsp;&nbsp;
									</span>
									<div className={styles.poweredByTicketmasterWrap}>
										<PoweredByTicketmaster showPoweredByText showFullLogo />
									</div>
								</header>
								<div className={styles.content}>
									{this.state.upcomingMatches.map((item, index) => (
										<div key={`${item.matchId}-${index}`} className={styles.item} data-match-tile={true}>
											<ScoreboardThumbnailContainer redesign={redesign} {...{...item, feedUrlName: t("scoreboard_upcoming_feed_link_name")}} />
										</div>
									))}
								</div>
							</aside>
						)}
					</div>
					{!hideArrows && <button className={`${styles.next} ${nextDisabled ? styles.disabled : ``}`} onClick={() => this.moveScoreboard(true)}><Arrow /></button>}
				</section>
				{redesign && showFilters && (
					<div className={styles.filtersWrap}>
						<div className={styles.arrowFilterContainer}>
							<div className={styles.arrowContainer}>
								<button className={`${styles.desktopPrevious} ${prevDisabled ? styles.disabled : ``}`} onClick={() => this.moveScoreboard()}>
									<Arrow />
								</button>
								<button className={`${styles.desktopNext} ${nextDisabled ? styles.disabled : ``}`} onClick={() => this.moveScoreboard(true)}>
									<Arrow />
								</button>
							</div>
							{!isOnlyOpenCup && (
								<div style={{display:'flex'}}>
									<label htmlFor={`filter-select`}>Filter</label>
									<select name={`filter-select`} onChange={e => this.setState({filterMatchesBy: e.target.value})}>
										<option value={`all`}>ALL</option>
										<option disabled={!this.state.hasMatches.uswnt} value={`uswnt`}>USWNT</option>
										<option disabled={!this.state.hasMatches.usmnt} value={`usmnt`}>USMNT</option>
										<option disabled={!this.state.hasMatches.youth} value={`youth`}>YOUTH</option>
										{this.state.hasMatches.openCup && (
											<option disabled={!this.state.hasMatches.openCup} value={`openCup`}>OPEN CUP</option>
										)}
									</select>
								</div>
							)}
						</div>
						{!isOnlyOpenCup && (
							<>
								<div><Link to={`/all-matches`}>View Schedules</Link></div>
								<div><a href={stanzaUrl} target={`_blank`} rel={`noopener noreferrer"`}>Sync Calendar</a></div>
							</>
						)}
					</div>
				)}
			</div>
		);
	}
}

export default compose(
	withNamespaces(),
	withErrorHandling()
)(ScoreboardBanner);