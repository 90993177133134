import React from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import styles from "./LatestStories.module.scss";
import LatestStoryThumbLarge from "~/components/LatestStories/LatestStoryThumbLarge";
import LatestStoryThumbSmall from "~/components/LatestStories/LatestStoryThumbSmall";
import {ArrowRight} from "~/shared-components/SvgIcons";
import {Link} from "react-router-dom";

const LatestStories = ({ stories }) => {
	if (stories === undefined || stories.length === 0) {
		return <></>;
	}

	return (
		<div className={styles.wrap}>
			<h2 className={styles.sectionTitle}>
				<Link to={`/stories`}>
					Latest Stories <ArrowRight />
				</Link>
			</h2>
			<div className={styles.inner}>
				{stories[0] !== undefined && (
					<div className={styles.left}>
						<LatestStoryThumbLarge key={stories[0].Id} story={stories[0]} />
					</div>
				)}
				{stories[1] !== undefined && (
					<div className={styles.right}>
						{stories.map((story, index) => {
							if (index === 0) return <></>;
							return <LatestStoryThumbSmall key={story.Id} story={story} withSpacer={index !== (stories.length - 1)} />;
						})}
					</div>
				)}
			</div>
		</div>
	);
};

export default compose(
	withErrorHandling()
)(LatestStories);
