import React, { PureComponent } from "react";
import styles from "./ScoreboardThumbnail.module.scss";
import { optaApi, USTeamNames } from "~/utilities/constants";
import { allFlags } from "~/utilities/flags";
import { Link } from "~/patches/components";
import {Link as RouterLink} from "react-router-dom";
import { compose } from "recompose";
import { withNamespaces } from "react-i18next";
import { withErrorHandling } from "~/hoc";
import moment from "moment";
import 'moment-timezone';

function penaltyShotsScored(penaltyShots, contestantId) {
	const penaltyShotsScored = penaltyShots.filter(pk => pk.contestantId === contestantId && pk.outcome === "scored").length;

	return penaltyShotsScored;
}

function checkWinner(contestants, penaltyShots, matchType) {
	const home = contestants[0];
	const away = contestants[1];
	if (matchType === optaApi.matchStatus.completed) {
		const homePenaltyShotsScored = penaltyShotsScored(penaltyShots, home.id);
		const awayPenaltyShotsScored = penaltyShotsScored(penaltyShots, away.id);

		home.score > away.score ||
		home.score === away.score &&
		homePenaltyShotsScored > awayPenaltyShotsScored
			? home.winner = true
			: away.winner = true;
	} else {
		home.winner = false;
		away.winner = false;
	}
}

const winnerText = {fontWeight: 700};

class ScoreboardThumbnail extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			homeFlag: allFlags.PLACEHOLDER,
			awayFlag: allFlags.PLACEHOLDER
		};
	}

	componentDidMount() {
		if (allFlags[this.props.contestants[0].code]) {
			this.setState({
				homeFlag: allFlags[this.props.contestants[0].code]
			});
		}

		if (allFlags[this.props.contestants[1].code]) {
			this.setState({
				awayFlag: allFlags[this.props.contestants[1].code]
			});
		}
	}

	render() {
		const {
			competitionName,
			contestants,
			currentPeriod,
			dateTime,
			links,
			matchType,
			matchUrl,
			penaltyShots,
			ticketsLink
		} = this.props;
		const {
			homeFlag,
			awayFlag
		} = this.state;

		return (
			<section className={styles.ScoreboardThumbnail}>
				<div className={styles.content}>
					<RouterLink to={matchUrl ? matchUrl : "" }>
						<MatchStatus {...this.props} />
						<div className={styles.match}>
							{contestants && (
								checkWinner(contestants, penaltyShots, matchType),
								contestants.map((team, index) => (
									<div
										key={index}
										className={styles.team}
										style={team.winner ? winnerText : {}}>
										{index === 0 ? homeFlag : awayFlag}
										<div className={styles.teamName}>{team.code === "USA" ? USTeamNames[team.id] : team.code}</div>
										{new Date(dateTime) < Date.now() &&
											<div className={styles.score}>
												{team.score}
												{(currentPeriod === optaApi.periodType.penaltyShootout ||
												penaltyShots?.length > 0) &&
													<span className={styles.penaltyShotsScored}>
														{` (${penaltyShotsScored(penaltyShots, team.id)})`}
													</span>
												}
											</div>
										}
									</div>
								))
							)}
						</div>
						<div className={styles.availability}>
							{competitionName}
						</div>
					</RouterLink>
				</div>
				<ul className={styles.links}>
					{links.map((link, index) => (
						<li key={index}><Link field={link}/></li>
					))}
					{(ticketsLink && matchType === optaApi.matchStatus.upcoming) &&
						<li><Link field={ticketsLink}/></li>
					}
				</ul>
			</section>
		);
	}
}

const MatchStatus = ({ matchType, time, dateTime, currentPeriod, t }) => {
	
	switch (matchType) {
		case optaApi.matchStatus.live:
			return (
				<div className={styles.liveStatus}>
					<div className={styles.mark}>
						{currentPeriod === optaApi.periodType.halfTime
							? t("scoreboard_halftime")
							: currentPeriod === optaApi.periodType.endSecondHalfBeforeExtraTime ||
							currentPeriod === optaApi.periodType.extraTimeHalfTime ||
							currentPeriod === optaApi.periodType.endExtraTimeBeforePenalties
								? `Extra Time`
								: currentPeriod === optaApi.periodType.penaltyShootout
									? `Penalties`
									: currentPeriod === optaApi.periodType.fullTime
										? `Full Time`
										: `Live - ${time}’`

						}
					</div>
				</div>
			);

		case optaApi.matchStatus.upcoming:
			return (
				<div className={styles.status}>
					{dateTime.includes('TBD')
						? `${moment(dateTime.replace('TBD', '00:00:00')).tz(moment.tz.guess()).format("MMM DD")} - TBD`
						: moment(dateTime).tz(moment.tz.guess()).format("MMM DD, h:mm A")
					}
				</div>
			);

		case optaApi.matchStatus.completed:
			return (
				<div className={styles.status}>
					Full time, {moment(dateTime).tz(moment.tz.guess())
						.format("MMM DD")}
				</div>
			);
	}
};

export default compose(
	withNamespaces(),
	withErrorHandling()
)(ScoreboardThumbnail);