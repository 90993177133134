exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".MatchButtons-module__liveText--embvn {\n  color: #000000;\n  font-family: \"USSF90MinDisplay\", helvetica, arial, sans-serif;\n  font-size: 1.2rem;\n  font-style: normal;\n  font-weight: 500;\n  letter-spacing: 0.05em;\n  color: #FFFFFF;\n  margin-right: 10px;\n  -webkit-animation: MatchButtons-module__pulsate--3ZVup 1.5s ease-in-out;\n          animation: MatchButtons-module__pulsate--3ZVup 1.5s ease-in-out;\n  -webkit-animation-iteration-count: infinite;\n          animation-iteration-count: infinite; }\n  @media screen and (max-width: 833px) {\n    .MatchButtons-module__liveText--embvn {\n      line-height: 1;\n      padding-top: 0.15rem;\n      display: -ms-flexbox;\n      display: flex;\n      -ms-flex-align: center;\n          align-items: center; } }\n  .MatchButtons-module__liveText--embvn:before {\n    background-color: #27AE3D;\n    border-radius: 50%;\n    -webkit-box-shadow: 0 0 0 1px inset #FFFFFF;\n            box-shadow: 0 0 0 1px inset #FFFFFF;\n    content: '';\n    display: inline-block;\n    height: 10px;\n    margin-right: 10px;\n    width: 10px; }\n\n@-webkit-keyframes MatchButtons-module__pulsate--3ZVup {\n  0% {\n    opacity: 0.25; }\n  50% {\n    opacity: 1; }\n  100% {\n    opacity: 0.25; } }\n\n@keyframes MatchButtons-module__pulsate--3ZVup {\n  0% {\n    opacity: 0.25; }\n  50% {\n    opacity: 1; }\n  100% {\n    opacity: 0.25; } }\n", ""]);

// exports
exports.locals = {
	"liveText": "MatchButtons-module__liveText--embvn",
	"pulsate": "MatchButtons-module__pulsate--3ZVup"
};