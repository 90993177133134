import React, { Component } from "react";
import { Link as RouterLink } from "react-router-dom";
import styles from "./MobileMegaNav.module.scss";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { compose } from "recompose";
import { withErrorHandling } from "~/hoc";
import loggedOutAvatar from "~/assets/svg/logged-out-avatar.svg";
import MenuLevel from "../MenuLevel";
import { GigyaConsumer } from "~/lib/gigya";
import { Text, Image} from "~/patches/components";

const initialState = {
	goToMenu: undefined,
	goBack: false,
	goNext: true,
	prevMenu: undefined,
	stateIsReset: false
};
class MobileMegaNav extends Component {

	constructor(props) {
		super(props);

		this.navigation = this.setNavigationStructure();

		this.state = initialState;
	}

	componentDidUpdate() {
		/* If the menu is active, make it possible to reset its state so we can reset to navigation and its animations */
		if (this.props.isActive && this.state.stateIsReset) {
			this.setState(initialState);
		}

		/* If the menu is isn't, reset the state */
		if (!this.props.isActive && !this.state.stateIsReset) {
			//eslint-disable-next-line
			document.body.classList.remove("overflowHidden");
			this.setState({
				...initialState,
				stateIsReset: true,
			});
		}
	}

	setNavigationStructure() {
		const navArray = [];
		const { fields, burgerMenuItemsTop, burgerMenuItemsBottom } = this.props;
		/* Set all first level items for the menu */
		if (burgerMenuItemsTop) {
			for (let i = 0; i < burgerMenuItemsTop.length ; i += 1) {
				const item = burgerMenuItemsTop[i];

				if (item) {
					navArray.push(item);
				}
			}
		}

		/* Set member link for the first level of the menu */
		if (fields.becomeAMemberLink) {
			navArray.push({memberLink: { becomeAMember: fields.becomeAMemberLink, memberBenefits: fields.memberBenefitsLink }});
		}

		/* Get all first level items for the menu */
		if (burgerMenuItemsBottom) {
			for (let i = 0; i < burgerMenuItemsBottom.length ; i += 1) {
				const item = burgerMenuItemsBottom[i];

				if (item) {
					navArray.push(item);
				}
			}
		}

		return navArray;
	}

	render() {
		const { fields, isActive } = this.props;
		return (
			<div className={styles.originalMobileMegaNav}>
				<div className={isActive ? styles.MobileMegaNavActive : styles.MobileMegaNav}>
					<div className={styles.container}>
						<div className={styles.gradientBg}/>
						<div className={styles.tertiaryMenu}>
							<GigyaConsumer>
								{({ isLoggedIn, profile }) =>
									<RouterLink to={isLoggedIn ? fields.profileLink.value.href : fields.loginPage.value.href}>
										<img
											className={(!isLoggedIn || !profile?.photoURL) ? styles.baseIcon : ""}
											src={profile?.photoURL || loggedOutAvatar}
											alt={isLoggedIn ? fields.profileLink.value.text : fields.loginPage.value.text}
											title={isLoggedIn ? "My Account" : "Log In"} />
									</RouterLink>
								}
							</GigyaConsumer>
							{fields.searchLink.value.href && (
								//eslint-disable-next-line
								<RouterLink to={fields.searchLink.value.href} onClick={() => document.body.classList.remove("overflowHidden")}>
									<svg width="28" height="28" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12.3636" cy="12.3636" r="6.6136" stroke="#FFFFFF" strokeWidth="1.5"/><path d="M17.0908 17.0908l5.9091 5.9091" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round"/></svg>
								</RouterLink>
							)}
						</div>
						<div className={styles.primary}>
							<MenuLevel {...{childs: this.navigation, state: this.state, setState: this.setState.bind(this), ...this.props}} />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

class MobileMegaNavRedesign extends Component {

	constructor(props) {
		super(props);

		this.navigation = this.setNavigationStructure();

		this.state = initialState;
	}

	componentDidUpdate() {
		/* If the menu is active, make it possible to reset its state so we can reset to navigation and its animations */
		if (this.props.isActive && this.state.stateIsReset) {
			this.setState(initialState);
		}

		/* If the menu is isn't, reset the state */
		if (!this.props.isActive && !this.state.stateIsReset) {
			//eslint-disable-next-line
			document.body.classList.remove("overflowHidden");
			this.setState({
				...initialState,
				stateIsReset: true,
			});
		}
	}

	setNavigationStructure() {
		const navArray = [];
		const { fields, burgerMenuItemsTop, burgerMenuItemsBottom } = this.props;
		/* Set all first level items for the menu */
		if (burgerMenuItemsTop) {
			for (let i = 0; i < burgerMenuItemsTop.length ; i += 1) {
				const item = burgerMenuItemsTop[i];

				if (item) {
					navArray.push(item);
				}
			}
		}

		/* Set member link for the first level of the menu */
		if (fields.becomeAMemberLink) {
			navArray.push({memberLink: { becomeAMember: fields.becomeAMemberLink, memberBenefits: fields.memberBenefitsLink }});
		}

		/* Get all first level items for the menu */
		if (burgerMenuItemsBottom) {
			for (let i = 0; i < burgerMenuItemsBottom.length ; i += 1) {
				const item = burgerMenuItemsBottom[i];

				if (item) {
					navArray.push(item);
				}
			}
		}

		return navArray;
	}

	render() {
		const { fields, isActive } = this.props;

		return (
			<div className={ styles.MobileMegaRedesignWrap }>
				<div className={isActive ? styles.MobileMegaNavActive : styles.MobileMegaNav}>
					<div className={styles.container}>
						<div className={styles.primary}>
							<MenuLevel {...{childs: this.navigation, state: this.state, setState: this.setState.bind(this), ...this.props, fields: {...fields}}} />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const RenderMobileMegaNav = props => {
	return ( props.fields.redesign.value ? <MobileMegaNavRedesign {...props} /> : <MobileMegaNav {...props} />)
};

export default compose(
	withSitecoreContext(),
	withErrorHandling()
)(RenderMobileMegaNav);