exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".FeaturedStoriesGrid-module__featuredStoriesGrid--2AbFd {\n  display: -ms-flexbox;\n  display: flex;\n  margin-bottom: 5rem; }\n  .FeaturedStoriesGrid-module__featuredStoriesGrid--2AbFd .col--xs-6 {\n    -ms-flex: 0 0 50%;\n        flex: 0 0 50%;\n    max-width: 50%; }\n    .FeaturedStoriesGrid-module__featuredStoriesGrid--2AbFd .col--xs-6 h4 {\n      font-size: 1.4rem; }\n  .FeaturedStoriesGrid-module__featuredStoriesGrid--2AbFd .FeaturedStoriesGrid-module__columns--2DaZR:nth-child(n+5) {\n    display: none; }\n\n.FeaturedStoriesGrid-module__mainColumn--baVSI > div {\n  height: 100% !important; }\n\n.FeaturedStoriesGrid-module__featuredStoriesGrid--2AbFd .FeaturedStoriesGrid-module__columns--2DaZR > div {\n  height: 100% !important; }\n\n.FeaturedStoriesGrid-module__smallCards--1ag5Q > div {\n  height: 100% !important; }\n\n.FeaturedStoriesGrid-module__mainColumn--baVSI,\n.FeaturedStoriesGrid-module__columns--2DaZR {\n  margin-bottom: 1.67rem; }\n\n.FeaturedStoriesGrid-module__mainColumn--baVSI {\n  display: -ms-flexbox;\n  display: flex; }\n\n@media (min-width: 768px) {\n  .FeaturedStoriesGrid-module__mainColumn--baVSI {\n    margin-bottom: 0; }\n  .FeaturedStoriesGrid-module__columns--2DaZR:nth-of-type(3), .FeaturedStoriesGrid-module__columns--2DaZR:nth-of-type(4) {\n    margin-bottom: 0; }\n  .FeaturedStoriesGrid-module__columns--2DaZR > section {\n    margin-bottom: 0; }\n  .FeaturedStoriesGrid-module__featuredStoriesGrid--2AbFd .col--xs-6 h4 {\n    font-size: 1.2rem; } }\n\n@media (min-width: 1240px) {\n  .FeaturedStoriesGrid-module__featuredStoriesGrid--2AbFd .col--xs-6 h4 {\n    font-size: 1.4rem; } }\n", ""]);

// exports
exports.locals = {
	"featuredStoriesGrid": "FeaturedStoriesGrid-module__featuredStoriesGrid--2AbFd",
	"columns": "FeaturedStoriesGrid-module__columns--2DaZR",
	"mainColumn": "FeaturedStoriesGrid-module__mainColumn--baVSI",
	"smallCards": "FeaturedStoriesGrid-module__smallCards--1ag5Q"
};