import React, { useState } from "react";
import { useFetchLiveMatchData } from "~/hooks/match";
import { Image } from "~/patches/components";
import BroadcasterLinks from "~/shared-components/BroadcasterLinks";
import MatchButtonsRedesign from "~/shared-components/MatchButtonsRedesign";
import styles from "./MatchListItemRedesign.module.scss";
import { optaApi, USTeamNames, womensTeams, mensTeams, validDateTimeFormat } from "~/utilities/constants";
import { allFlags } from "~/utilities/flags";
import { allFlagsSmall } from "~/utilities/flagsSmall";
import { withNamespaces } from "react-i18next";
import { MatchListBanner } from "~/shared-components/SvgIcons";
import { fieldObjHasValue } from "~/utilities";
import { getDoubleHeaderMessage } from "~/utilities/matches";
import { compose } from "recompose";
import { withErrorHandling } from "~/hoc";
import moment from "moment";
import 'moment-timezone';
import { isRedesign } from "~/utilities/helpers";

const valueOrDefault = (thing, fallback = null) =>
	thing === undefined || thing === null
		? fallback
		: thing;

const isUsTeamCode = (code) => code === "USA";
const isUsTeam = (team) => isUsTeamCode(team?.code);
const UsTeamName = (team) => USTeamNames[team?.id];
const usTeamPlaying = (match) => match.contestants?.filter(isUsTeam)?.[0];
const usTeamPlayingId = (match) => usTeamPlaying(match)?.id;
const TeamName = (team) => team?.name;

const penaltyShotsScored = (contestantId, match) => {
	const { penaltyShots } = match;
	const penaltyShotsScored = penaltyShots?.filter(pk => pk.contestantId === contestantId && pk.outcome === "scored").length;

	return penaltyShotsScored;
};

const wasMadeByTeam = (shot, teamId) => shot?.contestantId === teamId;
const wasSuccessful = (shot) => shot?.outcome === "scored";
const penaltyShotsScoredByTeam = (penaltyShots, teamId) => penaltyShots?.filter(shot => wasMadeByTeam(shot, teamId) && wasSuccessful(shot)).length;

const buildMatchTeamData = (match) => {
	const homeTeam = valueOrDefault(match?.contestants?.[0]);
	const awayTeam = valueOrDefault(match?.contestants?.[1]);
	const homeName = TeamName(homeTeam);
	const awayName = TeamName(awayTeam);
	const homePenaltyShotsScored = valueOrDefault(penaltyShotsScoredByTeam(match?.penaltyShots, homeTeam?.id));
	const awayPenaltyShotsScored = match.currentPeriod === optaApi.periodType.penaltyShootout ||
		match.penaltyShots?.length > 0
		? penaltyShotsScored(match?.penaltyShots, awayTeam?.id)
		: null;

	return {
		away: awayTeam,
		awayName,
		awayPenaltyShotsScored,
		home: homeTeam,
		homeName,
		homePenaltyShotsScored,
	};
};

const MatchListItemRedesign = ({ doubleHeaderMatches, match, t, showDynamicScore = true, showButtons = true }) => {
	const [isValidMatchDateTime, setIsValidMatchDateTime] = useState(true);
	const [updatedMatch, setUpdatedMatch] = useState(match);

	useFetchLiveMatchData(match, setIsValidMatchDateTime, setUpdatedMatch);

	if (!updatedMatch) return null; // Check this now to avoid ? operators later

	const isDoubleHeader = !!doubleHeaderMatches?.length;
	const usTeamId = usTeamPlayingId(updatedMatch);
	const listItemStyle = !usTeamId
		? styles.nonUS
		: womensTeams.includes(usTeamId)
			? styles.women
			: styles.men;

	const renderDoubleHeader = (doubleHeaderMatch, key) => {
		if (!doubleHeaderMatch) return null;
		const isValidDoubleHeaderMatchDateTime = moment(doubleHeaderMatch.dateTime, validDateTimeFormat, true).isValid();
		return <MatchItem key={key} match={doubleHeaderMatch} isDoubleHeader isValidMatchDateTime={isValidDoubleHeaderMatchDateTime} showDynamicScore={showDynamicScore} showButtons={showButtons} />;
	};
	const renderDoubleHeaders = (doubleHeaderMatches, startingKey) => doubleHeaderMatches.map((match, key) => renderDoubleHeader(match, key + startingKey));

	return (
		<div className={styles.matchContent}>
			<div className={styles.inner}>
				<MatchItem match={updatedMatch} isDoubleHeader={doubleHeaderMatches?.length} isValidMatchDateTime={isValidMatchDateTime} key={0} showDynamicScore={showDynamicScore} showButtons={showButtons} t={t} doubleHeaderMatches={doubleHeaderMatches} />
				{isDoubleHeader && renderDoubleHeaders(doubleHeaderMatches, 1)}
			</div>
		</div>
	);
};

const MatchItem = ({ match, isValidMatchDateTime, isDoubleHeader, showDynamicScore, showButtons, t, doubleHeaderMatches }) => {
	if (!match) return null;

	const isCompleteMatch = match.status === optaApi.matchStatus.completed;
	const isUpcomingMatch = match.status === optaApi.matchStatus.upcoming;
	const isLiveMatch = match.status === optaApi.matchStatus.live;

	const {
		away,
		awayName,
		awayPenaltyShotsScored,
		home,
		homeName,
		homePenaltyShotsScored,
	} = buildMatchTeamData(match);

	let competitionName = match?.competition?.name;

	if (isDoubleHeader) {
		const individualMatchTime = !isValidMatchDateTime ? "TBD" : `${moment(match.dateTime).tz(moment.tz.guess()).format('h:mmA')} ${moment(match.dateTime).tz(moment.tz.guess()).zoneAbbr()}`;
		competitionName = `${individualMatchTime} - ${competitionName}`;
	}

	const scoreInformation = (match, home, away, showDynamicScore) => {
		// We're using lists to get around the fact JS doesn't have tuples.
		// This is tricking ESLint into thinking we're rendering the component inside a list when we're not.
		/* eslint-disable react/jsx-key */
		return showDynamicScore && !isUpcomingMatch
			? [styles.teamVs, <Score homeScore={home.score} awayScore={away.score} isCompleteMatch={isCompleteMatch} />]
			: [styles.teamVsUpcoming, <Versus />];
		/* eslint-enable react/jsx-key */
	};
	const [teamInformationClassName, scoreComponent] = scoreInformation(match, home, away, showDynamicScore);

	return (
		<div className={styles.scoreContainer}>
			{isLiveMatch &&
				<>
					<div className="row">
						<div className={`${isLiveMatch ? styles.titleLive : ''} ${styles.title}`}>
							<div className={styles.titleName}>{competitionName}</div>
							<div className={styles.titleStatus}>
								<MatchStatus doubleHeaderMatches={doubleHeaderMatches} match={match} isValidMatchDateTime={isValidMatchDateTime} t={t} />
							</div>
						</div>
					</div>
				</>
			}

			{/* Team Information */}
			{isCompleteMatch &&
				<div className={styles.scoreContainer}>
					<div className={styles.homeSection}>
						<TeamInformation team={home} teamName={homeName} />
					</div>
					<div className={styles.scoreSection}>
						{scoreComponent}
					</div>
					<div className={styles.awaySection}>
						<TeamInformation team={away} teamName={awayName} />
					</div>
					<div className={styles.infoSection}>
						{
							((match?.currentPeriod === optaApi.periodType.penaltyShootout ||
								match?.currentPeriod === optaApi.periodType.fullTime &&
								match?.penaltyShots?.length > 0)
								|| match.sponsor) &&
							<Sponsor match={match} homePenaltyShotsScored={homePenaltyShotsScored} awayPenaltyShotsScored={awayPenaltyShotsScored} home={home} away={away} />
						}
						<Broadcast match={match} showButtons={showButtons} />
					</div>
				</div>
			}
			{isLiveMatch &&
				<div className={styles.scoreContainer}>
					<div className={styles.homeSection}>
						<TeamInformation team={home} teamName={homeName} />
					</div>
					<div className={styles.scoreSection}>
						{scoreComponent}
					</div>
					<div className={styles.awaySection}>
						<TeamInformation team={away} teamName={awayName} />
					</div>
					<div className={styles.infoSection}>
						{
							((match?.currentPeriod === optaApi.periodType.penaltyShootout ||
								match?.currentPeriod === optaApi.periodType.fullTime &&
								match?.penaltyShots?.length > 0)
								|| match.sponsor) &&
							<Sponsor match={match} homePenaltyShotsScored={homePenaltyShotsScored} awayPenaltyShotsScored={awayPenaltyShotsScored} home={home} away={away} />
						}
						<Broadcast match={match} showButtons={showButtons} />
					</div>
				</div>
			}
			{isUpcomingMatch &&
				<>
					<div className={styles.scoreContainer}>
						<div className={styles.leftSection}>
							<TeamInformationUpcoming team={home} teamName={homeName} />
							<br />
							<TeamInformationUpcoming team={away} teamName={awayName} />
							<br />
						</div>
						<div className={styles.rightSection}>
							{
								((match?.currentPeriod === optaApi.periodType.penaltyShootout ||
									match?.currentPeriod === optaApi.periodType.fullTime &&
									match?.penaltyShots?.length > 0)
									|| match.sponsor) &&
								<Sponsor match={match} homePenaltyShotsScored={homePenaltyShotsScored} awayPenaltyShotsScored={awayPenaltyShotsScored} home={home} away={away} />
							}
							<Broadcast match={match} showButtons={showButtons} />
						</div>
					</div>
					<div className={styles.scoreContainer}>
						<div className={styles.upcomingName} >{match.competition.name}</div>
						<div className={styles.upcomingLocation} >{match.description}</div>
					</div>
				</>
			}
		</div>
	);
};
const Broadcast = ({ match, showButtons }) => {
	return (
		<div className={styles.broadcasterLinksAndButtons}>
			{(match?.sitecoreData?.broadcasterLinks &&
				match?.sitecoreData?.broadcasterLinks.length &&
				match?.status !== optaApi.matchStatus.completed) &&
				(
					<BroadcasterLinks match={match} isListView={true} />
				)
			}
			{showButtons && <MatchButtonsRedesign event={match} type="card" eventType="match" isListView={true} />}
		</div>);
};

const Sponsor = ({ match, homePenaltyShotsScored, awayPenaltyShotsScored, home, away }) => {
	return (
		<>
			<div className={styles.sponsor}>
				{(match?.currentPeriod === optaApi.periodType.penaltyShootout ||
					match?.currentPeriod === optaApi.periodType.fullTime &&
					match?.penaltyShots?.length > 0) &&
					<div className={styles.penaltyScores}>
						{match?.currentPeriod === optaApi.periodType.penaltyShootout
							? `(${home.code} ${homePenaltyShotsScored} - ${awayPenaltyShotsScored} ${away.code})`
							: homePenaltyShotsScored > awayPenaltyShotsScored
								? `(${home.code} wins ${homePenaltyShotsScored} - ${awayPenaltyShotsScored} on penalties)`
								: `(${away.code} wins ${awayPenaltyShotsScored} - ${homePenaltyShotsScored} on penalties)`
						}
					</div>
				}
				{match.sponsor}
			</div>
		</>
	);
};

const CanceledStatus = () => (
	<>
		<span>Cancelled</span>
	</>
);

const DateStatus = ({ doubleHeaderMatches, matchDate }) => (
	matchDate
		? <>
			<span>{matchDate} - {doubleHeaderMatches?.length ? getDoubleHeaderMessage(doubleHeaderMatches.length) : "TBD"}</span>
		</>
		: <>
			<span>TBD</span>
		</>
);

const LiveStatus = ({ match, t }) => {
	const isHalftime = match.currentPeriod === optaApi.periodType.halfTime;
	const isExtraTime = match.currentPeriod === optaApi.periodType.endSecondHalfBeforeExtraTime ||
		match.currentPeriod === optaApi.periodType.extraTimeHalfTime ||
		match.currentPeriod === optaApi.periodType.endExtraTimeBeforePenalties;
	const isPenaltyShootout = match.currentPeriod === optaApi.periodType.penaltyShootout;
	const isFullTime = match.currentPeriod === optaApi.periodType.fullTime; // Is this the same as over?

	return (
		<div className={styles.liveStatus}>
			{isHalftime
				? t("scoreboard_halftime")
				: isExtraTime
					? `Extra Time`
					: isPenaltyShootout
						? `Penalties`
						: isFullTime
							? `Full Time`
							: `Live - ${match.time}’`
			}
		</div>
	);
};

const MatchStatus = ({ doubleHeaderMatches, match, isValidMatchDateTime, t }) => {
	const FormatMatchDate = (match) => moment(match.date).isValid() ? moment(match.date).format('MMM DD YYYY') : "TBD";

	return (
		<>
			{match.status === optaApi.matchStatus.live
				? <LiveStatus match={match} t={t} />
				: match.cancelled
					? <CanceledStatus />
					: isValidMatchDateTime
						? <TimeStatus doubleHeaderMatches={doubleHeaderMatches} match={match} />
						: <DateStatus doubleHeaderMatches={doubleHeaderMatches} matchDate={FormatMatchDate(match)} />
			}
		</>
	);
};

const TeamInformationUpcoming = ({ team, teamName }) => {
	return (
		<>
			<div className={styles.teamFlagSmall}>{allFlagsSmall[team?.code]}</div>
			<div className={styles.teamSmall}>{teamName}</div>
		</>
	);
};

const TeamInformation = ({ team, teamName }) => {
	return (
		<>
			<div className={styles.teamFlag}>{allFlags[team?.code]}</div>
			<div className={styles.team}>{teamName}</div>
		</>
	);
};

const Versus = () => <div className={styles.vs}>VS</div>;

const Score = ({ homeScore, awayScore, isCompleteMatch }) => (
	<div className={styles.scores}>
		<span>{homeScore}</span>
		<div className={styles.ft}>
			{isCompleteMatch ? "FT" : <>&ndash;</>}
		</div>
		<span>{awayScore}</span>
	</div>
);

const TimeStatus = ({ doubleHeaderMatches, match }) => doubleHeaderMatches?.length
	? moment(match.dateTime).tz(moment.tz.guess()).format('MMM DD YYYY').concat(` - ${getDoubleHeaderMessage(doubleHeaderMatches.length)}`)
	: `${moment(match.dateTime).tz(moment.tz.guess()).format('MMM DD YYYY - h:mmA')} ${moment(match.dateTime).tz(moment.tz.guess()).zoneAbbr()}`;

const Venue = ({ match }) => {
	const venueName = !match?.venue?.longName || match?.venue?.longName === "TBD"
		? "TBD"
		: <>{match?.venue?.longName}, {match?.venue?.location}, {match?.venue?.country}</>;

	return <div className={styles.venue}>{venueName}</div>;
};

export default compose(
	withNamespaces(),
	withErrorHandling()
)(MatchListItemRedesign);