import React, { PureComponent, Fragment } from "react";
import SectionLabel from "~/shared-components/SectionLabel";
import CareerMoment from "./CareerMoment";
import { withSitecoreContext, resetExperienceEditorChromes } from "@sitecore-jss/sitecore-jss-react";
import { Text } from "~/patches/components";
import styles from "./Career.module.scss";

class Career extends PureComponent {

	constructor(props) {
		super(props);

		this.state = {
			activeTab: 1
		};
	}

	setActiveState(index) {
		this.setState({activeTab: index});
		resetExperienceEditorChromes();
	}

	setActiveClass(index) {
		return index === this.state.activeTab ? styles.active : "";
	}

	render () {
		const { fields, sitecoreContext: { pageEditing } } = this.props;

		return (
			<Fragment>
				{pageEditing && (
					<section className={styles.Career}>
						<div className={styles.sectionLabelContainer}>
							<SectionLabel sectionLabel={fields.sectionLabel} />
						</div>
						<section className={styles.tabHeader}>
							<div className="grid gridContainer">
								<div className="row">
									<div className="col">
										<button className={this.setActiveClass(1)} onClick={this.setActiveState.bind(this, 1)}>
											<Text field={fields.nationalTeamsTabLabel} />
										</button>
									</div>
								</div>
							</div>
						</section>
						<div className="grid gridContainer">
							<div className="row">
								<div className="col">
									<section className={`${styles.tabContent} ${this.setActiveClass(1)}`}>
										{fields.nationalTeams.map((item, index) => (
											<CareerMoment key={`national-team-${index}`} careerItem={item} />
										))}
									</section>
								</div>
							</div>
						</div>
						<section className={styles.tabHeader}>
							<div className="grid gridContainer">
								<div className="row">
									<div className="col">
										<button className={this.setActiveClass(2)} onClick={this.setActiveState.bind(this, 2)}>
											<Text field={fields.clubTeamsTabLabel} />
										</button>
									</div>
								</div>
							</div>
						</section>
						<div className="grid gridContainer">
							<div className="row">
								<div className="col">
									<section className={`${styles.tabContent} ${this.setActiveClass(2)}`}>
										{fields.clubTeams.map((item, index) => (
											<CareerMoment key={`club-team-${index}`} careerItem={item} />
										))}
									</section>
								</div>
							</div>
						</div>
					</section>
				)}

				{!pageEditing && (
					<section className={styles.Career}>
						<div className={styles.sectionLabelContainer}>
							<SectionLabel sectionLabel={fields.sectionLabel} />
						</div>
						<section className={styles.tabHeader}>
							<div className="grid gridContainer">
								<div className="row">
									<div className="col">
										<button className={this.setActiveClass(1)} onClick={this.setActiveState.bind(this, 1)}>
											<Text field={fields.nationalTeamsTabLabel} />
										</button>
										<button className={this.setActiveClass(2)} onClick={this.setActiveState.bind(this, 2)}>
											<Text field={fields.clubTeamsTabLabel} />
										</button>
									</div>
								</div>
							</div>
						</section>
						<div className="grid gridContainer">
							<div className="row">
								<div className="col">
									<section className={`${styles.tabContent} ${this.setActiveClass(1)}`}>
										{fields.nationalTeams.map((item, index) => (
											<CareerMoment key={`national-team-${index}`} careerItem={item} />
										))}
									</section>
									<section className={`${styles.tabContent} ${this.setActiveClass(2)}`}>
										{fields.clubTeams.map((item, index) => (
											<CareerMoment key={`club-team-${index}`} careerItem={item} />
										))}
									</section>
								</div>
							</div>
						</div>
					</section>
				)}
			</Fragment>
		);
	}
}

export default withSitecoreContext()(Career);