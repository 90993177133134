import React from "react";
import { Placeholder, withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { Text } from "~/patches/components";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import { fieldHasValue } from "~/utilities";
import styles from "./AllPlayersTemplate.module.scss";

const AllPlayersTemplate = ({ rendering, sitecoreContext: { route: { fields: contextFields }, pageEditing } }) => (
	<main>
		{(fieldHasValue(contextFields, "headline") || pageEditing)  && (
			<div className={styles.header}>
				<div className="grid gridContainer">
					<div className="row">
						<div className="col">
							<Text field={contextFields.headline} tag="h1" />
						</div>
					</div>
				</div>
			</div>
		)}
		<div>
			<Placeholder name="jss-layout-all-players-template" rendering={rendering} />
		</div>
	</main>
);

export default compose(
	// other hocs goes here
	withSitecoreContext(),
	withErrorHandling()
)(AllPlayersTemplate);