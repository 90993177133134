import React, { Fragment } from "react";
import { Placeholder, withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import styles from "./MediaCarousel.module.scss";
import { Carousel } from "~/shared-components";
import HelperCommentEmptyPlaceholder from "~/experience-editor/HelperCommentEmptyPlaceholder";
import { isPlaceholderEmptyOrUndefined } from "~/utilities";

const options = {
	modifierClassName: 'imagePaddingHeight',
	imageSrcSet: [{mw: 1340}, {mw: 768}],
	imageSizes: '(min-width: 768px) 1340px, 768px'
};

// TODO: remove grid classes alltogether or at least the padding inherited from them
const MediaCarousel = ({ rendering, sitecoreContext: { pageEditing } }) => (
	<section className={styles.MediaCarousel}>

		{pageEditing ? (
			<div className="grid gridContainer">
				<div className="row">
					{isPlaceholderEmptyOrUndefined(rendering, "jss-media-carousel-content") && (
						<div className="col col--sm-6">
							<HelperCommentEmptyPlaceholder />
						</div>
					)}
					<Placeholder
						name="jss-media-carousel-content"
						rendering={rendering}
						renderEach={(component, index) => (
							<div className="col col--sm-6" key={index}>
								<CarouselItem component={component} />
							</div>
						)}/>
				</div>
			</div>
		) : (
			<Placeholder
				name="jss-media-carousel-content"
				rendering={rendering}
				render={components => {
					if (components.length === 0) return <div></div>;

					return (
						<div className="grid gridContainer">
							<div className="row">
								<div className="col">
									<Carousel>
										{components.map((component, index) => (
											<Fragment key={index}>
												<CarouselItem component={component} />
											</Fragment>
										))}
									</Carousel>
								</div>
							</div>
						</div>
					);
				}}/>
		)}
	</section>
);

/**
 * Single carousel item.
 *
 * @param {object} component - The component object.
 */
const CarouselItem = ({component}) => {
	const newProps = {options, ...component.props};
	const newComponent = React.cloneElement(component, newProps);

	return (
		<div className={styles.item}>{newComponent}</div>
	);
};

export default compose(
	// other hocs goes here
	withSitecoreContext(),
	withErrorHandling()
)(MediaCarousel);