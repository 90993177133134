import React from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { Text } from "~/patches/components";
import styles from "./QuoteBlock.module.scss";
import { compose } from "recompose";
import { withErrorHandling } from "~/hoc";
import { fieldHasValue } from "~/utilities";

const QuoteBlock = ({ fields, sitecoreContext: { pageEditing } }) => {

	const inlineStyles =  {
		backgroundColor: !pageEditing && fields?.backgroundColor?.fields?.colorCode?.value ? fields.backgroundColor.fields.colorCode.value : "",
		backgroundImage: !pageEditing && fields?.backgroundImage?.value?.src ? `url(${fields.backgroundImage.value.src})` : ""
	};

	return (
		<>
		{fields && (
			<div className={`${styles.QuoteBlock}`} style={inlineStyles}>
				<div className={styles.firstQuote}></div>
				<div className={styles.quoteContent}>
					{fieldHasValue(fields, "quote") && <Text field={fields.quote} />}
				</div>
				<div className={styles.originator}>
					{fieldHasValue(fields, "originator") && <span><Text field={fields.originator} /></span>}
				</div>
				<div className={styles.lastQuote}></div>
			</div>
		)}
		</>
	);
};

export default compose(
	// other hocs goes here
	withSitecoreContext(),
	withErrorHandling()
)(QuoteBlock);