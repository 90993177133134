import React, { Component } from "react";
import styles from "./PageLoader.module.scss";

class PageLoader extends Component {
	componentDidUpdate(prevProps) {
		if (prevProps.state.routeData.sitecore.route.name !== this.props.state.routeData.sitecore.route.name) {
			window.scrollTo(0, 0); // eslint-disable-line
		}
	}

	render() {
		const { state, children } = this.props;
		const isLoading = state.isFetchingRouteData;

		return (
			<div className={styles.container}>
				<div className={`${styles.loader} ${isLoading ? styles.showLoader : ""}`}></div>
				{/* <div className={`${styles.background} ${isLoading ? styles.showBackground : ""}`}></div> */}
				{children}
			</div>
		);
	}
}

export default PageLoader;