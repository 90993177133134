import React from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import { fieldHasValue } from "~/utilities";
import { RichText, Text } from "~/patches/components";
import styles from "./TextBlockWithAd300x600.module.scss";

const TextBlockWithAd300x600 = ({ fields }) => (
	<section className={styles.TextBlockWithAd300x600} >
		{fieldHasValue(fields, "heading") && <Text className={styles.heading} tag="h2" field={fields.heading} />}
		<div className={styles.adContainer}>
			<div id="div-gpt-ad-textblock-300x600" className={styles.ad}></div>
		</div>
		{fieldHasValue(fields, "bodyText") && 
			<div className={styles.bodyText}>
				<RichText field={fields.bodyText} />
			</div>
		}
	</section>
);

export default compose(
	// other hocs goes here
	withErrorHandling()
)(TextBlockWithAd300x600);