exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".RedesignGoogleAd-module__googleAdWrap728--36PNB {\n  background-color: rgba(0, 0, 0, 0.025);\n  width: 728px;\n  height: 90px;\n  margin: 0 auto;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: center;\n      justify-content: center;\n  -ms-flex-align: center;\n      align-items: center; }\n  @media (max-width: 991px) {\n    .RedesignGoogleAd-module__googleAdWrap728--36PNB {\n      display: none; } }\n\n.RedesignGoogleAd-module__googleAdWrap320--2BBKX {\n  display: none; }\n  @media (max-width: 991px) {\n    .RedesignGoogleAd-module__googleAdWrap320--2BBKX {\n      background-color: rgba(0, 0, 0, 0.025);\n      height: 50px;\n      width: 320px;\n      margin: 0 auto;\n      display: -ms-flexbox;\n      display: flex;\n      -ms-flex-align: center;\n          align-items: center;\n      -ms-flex-pack: center;\n          justify-content: center; } }\n\n.RedesignGoogleAd-module__square--36ORQ {\n  background-color: rgba(0, 0, 0, 0.025);\n  margin: 0 auto;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: center;\n      justify-content: center;\n  -ms-flex-align: center;\n      align-items: center;\n  width: 300px;\n  height: 250px;\n  aspect-ratio: auto 30 / 25; }\n", ""]);

// exports
exports.locals = {
	"googleAdWrap728": "RedesignGoogleAd-module__googleAdWrap728--36PNB",
	"googleAdWrap320": "RedesignGoogleAd-module__googleAdWrap320--2BBKX",
	"square": "RedesignGoogleAd-module__square--36ORQ"
};