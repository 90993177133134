import React from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import { Link as RouterLink } from "react-router-dom";
import styles from "./Error404.module.scss";

/* this is a static component that will not be authored. */

const Error404 = () => (
	<>
			<div className={styles.Error404}>
				<div className={styles.textContainer}>
					<div className={styles.heading}>
						<span>{"404"}</span> <span>{"Red Card"}</span>
					</div>
					<p className={styles.subheading}><span>{"This page is out of the game."}</span> <span>{"Head to our homepage for all the action."}</span></p>
					<RouterLink to={"/"}>
						<div className={styles.button} onClick>{"let's go"}</div>
					</RouterLink>
				</div>
			</div>
	</>
);

export default compose(
	// other hocs goes here
	withErrorHandling()
)(Error404);