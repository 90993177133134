import React, { Fragment, PureComponent } from "react";
import { Link as RouterLink } from "react-router-dom";
import styles from "../MobileMegaNav/MobileMegaNav.module.scss";
import { Text, Image } from "~/patches/components";
import { compose } from "recompose";
import { withErrorHandling } from "~/hoc";
import FeaturedLink from "../FeaturedLink";
import { SocialLinksFooter } from "~/shared-components/SocialLinks";import MemberLink from "../MemberLink";
import Link from "../Link";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import iOSversionCheck from "~/utilities/iOSversionCheck";
import PoweredByTicketmaster from "~/shared-components/PoweredByTicketmaster";


/**
 *
 * @param {number} menuIndex - Index of the current multi-level menu.
 * @returns {string} - All classes to set.
 */
const handleMultiLevelClasses = (menuIndex, isActive, state) => {
	const iosVersion = iOSversionCheck();

	let classNames = '';

	/* Set initial state for multi-level navigation */
	if (menuIndex === "level-0-0" && state.goToMenu === undefined)
		classNames += ` ${styles.current}`;

	/* Reset classes if menu isn't active */
	if (!isActive)
		return '';

	/* Set current class */
	if (state.goToMenu === menuIndex)
		classNames += ` ${styles.current}`;

	// Set classes that will animate the submenus.
	//
	// Important: iOS version 15 has an issue with rendering these animations, so
	// we will simply disable them for visitors using this specific version.
	if (iosVersion?.majorReleaseNumeric !== 15) {
		// Slide out to left if new level is up
		if (state.prevMenu === menuIndex && state.goNext)
			classNames += ` ${styles.animateOutToLeft}`;

		// Slide out to right if new level is down
		if (state.prevMenu === menuIndex && state.goBack)
			classNames += ` ${styles.animateOutToRight}`;

		// Slide in from right if new level is down
		if (state.goToMenu === menuIndex && state.goNext)
			classNames += ` ${styles.animateInFromRight}`;

		// Slide in from left if new level is up
		if (state.goToMenu === menuIndex && state.goBack)
			classNames += ` ${styles.animateInFromLeft}`;
	}

	// Reset none active levels (Levels that are not current or previous)
	if (state.currentMenu !== menuIndex && state.previousMenu !== menuIndex && state.currentMenu !== undefined)
		classNames = '';

	return classNames;
};

class MenuLevel extends PureComponent {

	render() {
		const { fields, childs, prevMenu, splitSubmenuItemsInTwoColumns, isActive, state, setState, sitecoreContext } = this.props;
		const { level: levelProp, index: indexProp } = this.props;
		const level = levelProp || 0;
		const index = indexProp || 0;
		const menuLevel = `level-${level}-${index}`;

		return (
			<Fragment key={`menu-${level}-${index}`}>
				<ul key={`${menuLevel}-${index}`} className={`${handleMultiLevelClasses(menuLevel, isActive, state)} ${level !== 0 ? styles.lowerLevelMenu : ''} ${splitSubmenuItemsInTwoColumns ? styles.twoColumns : ''}`}>
					<li><RouterLink to="/">HOME</RouterLink></li>
					{prevMenu &&
						<li>
							<button onClick={() => setState({
								goToMenu: prevMenu.menuLevel,
								prevMenu: menuLevel,
								goNext: false,
								goBack: true
							})}>
							</button>
							<Text field={prevMenu.menuTitleMobile}/>
						</li>
					}
					{childs.map((item, index) => (
						<Fragment key={index}>
							{item.memberLink ? (
								<>
									<li><a target="_blank" rel="external noopener noreferrer" className="Andrew" href="https://store.ussoccer.com/?utm_source=us_soccer&utm_medium=website&utm_campaign=shoptab">shop</a></li>
									<li key={`mobile-mega-menu-${item.memberLink.becomeAMember.value.id}-${index}`} className={styles.noBorder}>
										<MemberLink {...{...item.memberLink, ...this.props}} />
									</li>
								</>
							) : (
								<li key={`mobile-mega-menu-${item.id}-${index}`}>
									<Link {...{item, level, index, menuLevel, fields: {...fields} }} setState={setState} />
								</li>
							)}
						</Fragment>
					))}
					{level === 0 && (
						<li>
							<FeaturedLink {...this.props} />
						</li>
					)}
					{level === 0 && (
						<li className={styles.socialLinks}>
							<SocialLinksFooter
								light={true}
							/>
						</li>
					)}
				</ul>

				{childs.map((item, index) => (
					item && item.subMenuItems && item.subMenuItems.length !== 0 && (
						<MenuLevel
							key={index}
							{...{
								childs: item.subMenuItems,
								level: level + 1,
								index,
								prevMenu: { menuLevel, menuTitleMobile: item.menuTitleMobile },
								splitSubmenuItemsInTwoColumns: item.splitSubmenuItemsInTwoColumns,
								isActive,
								state,
								setState}
							} />
					)
				))}

			</Fragment>

		);
	}
}


class MenuLevelRedesign extends PureComponent {

	render() {
		const { fields, childs, prevMenu, splitSubmenuItemsInTwoColumns, isActive, state, setState, sitecoreContext } = this.props;
		const { level: levelProp, index: indexProp } = this.props;
		const level = levelProp || 0;
		const index = indexProp || 0;
		const menuLevel = `level-${level}-${index}`;

		return (
			<Fragment key={`menu-${level}-${index}`}>
				<ul key={`${menuLevel}-${index}`} className={`${handleMultiLevelClasses(menuLevel, isActive, state)} ${level !== 0 ? styles.lowerLevelMenu : ''} ${splitSubmenuItemsInTwoColumns ? styles.twoColumns : ''}`}>
					{prevMenu &&
						<li>
							<button className={styles.goToPrev} onClick={() => setState({
								goToMenu: prevMenu.menuLevel,
								prevMenu: menuLevel,
								goNext: false,
								goBack: true
							})}>
								<div className={styles.textPadding}>
									<Text field={prevMenu.menuTitleMobile}/>
								</div>
							</button>
						</li>
					}
					{childs.map((item, index) => {
						switch (true) {
							case (item.url === '/developmentfund'): return (
								<>
									<li><a target="_blank" rel="external noopener noreferrer" className="Andrew" href="https://www.developmentfund.ussoccer.com/">Development Fund</a></li>
								</>
							)
							case (Boolean(item.memberLink) || item.url === '/shop'): return (
								<>
									<li><a target="_blank" rel="external noopener noreferrer" className="Andrew" href="https://store.ussoccer.com/?utm_source=us_soccer&utm_medium=website&utm_campaign=shoptab">{item?.menuTitle?.value ?? "shop"}</a></li>
								</>
							)
							default: {
								const showTm = (
									level === 1 &&
									item.menuTitle.value === 'Schedule & Tickets'
								);

								return (
									<li key={`mobile-mega-menu-${item.id}-${index}`} className={showTm ? styles.linkWithTicketmaster : ''}>
										<Link {...{item, level, index, menuLevel, fields: {...fields}}} setState={setState}>
											{/*
												This does NOT feel right, but it's the only way we can
												easily add the Ticketmaster logo here.
											*/}
											{showTm && (
												<span className={styles.ticketmasterLogo}>
													<PoweredByTicketmaster showTlogo />
												</span>
											)}
										</Link>
									</li>
								);
							}
						}
					})}
					{level === 0 && (
						<li>
							<FeaturedLink {...this.props} />
						</li>

					)}
					{level === 0 && (
						<li className={`${styles.mobileNavLinks}`}>
							<div className={styles.leftLinks}>
								<div>
									<RouterLink className={styles.worldCupLink} to={fields.worldCupLink.value.href}>
										<img src={`https://cdn.sanity.io/images/oyf3dba6/production/bf72ee7b299178803e565d6c872cdb4baf2aef2d-640x877.png?fit=max&w=100&h=100`} alt={`Participant Safety logo`} />
										<span>Participant Safety Hub</span>
									</RouterLink>
								</div>
								<div>
									<RouterLink className={styles.openCupLink} to={fields.openCupLink.value.href}>
										<img src={`https://cdn.sanity.io/images/oyf3dba6/production/7a1442d7cd0cd1effe41f58c1bd53a9019dd2f73-512x698.png?fit=max&w=100&h=100`} alt={`Open Cup logo`} />
										<span>Open Cup</span>
									</RouterLink>
								</div>
							</div>
							<span className={styles.linkBorder}></span>
							<div className={styles.rightLinks}>
								<div>
									<RouterLink className={styles.sponsorLink2} to={fields.sponsorLink2.value.href}>
										<img src={`https://cdn.sanity.io/images/oyf3dba6/production/9e4a4c219f2b1261b944e811e4d090afc59b7a88-512x213.png?fit=max&w=100&h=100`} alt={`Nike logo`} />
									</RouterLink>
								</div>
								<div>
									<RouterLink className={styles.sponsorLink1} to={fields.sponsorLink1.value.href}>
										<img src={`https://cdn.sanity.io/images/oyf3dba6/production/24816f3583a740f42387eb263aca3023165ea277-512x536.png?fit=max&w=100&h=100`} alt={`Volkswagen logo`} className={styles.vwImage} />
									</RouterLink>
								</div>
							</div>
						</li>

					)}
				</ul>



				{childs.map((item, index) => (
					item && item.subMenuItems && item.subMenuItems.length !== 0 && (
						<MenuLevelRedesign
							key={index}
							{...{
								childs: item.subMenuItems,
								level: level + 1,
								index,
								prevMenu: { menuLevel, menuTitleMobile: item.menuTitleMobile },
								splitSubmenuItemsInTwoColumns: item.splitSubmenuItemsInTwoColumns,
								isActive,
								state,
								setState,
								fields: fields}
							} />
					)
				))}



			</Fragment>

		);
	}
}

const RenderMenuLevel = props => {
	return ( props.fields.redesign.value ? <MenuLevelRedesign {...props} /> : <MenuLevel {...props} />)
};



export default compose(
	withErrorHandling(),
	withSitecoreContext()
)(RenderMenuLevel);