import React, { PureComponent } from "react";
import { withErrorHandling, withGigya } from "~/hoc";
import { withNamespaces } from "react-i18next";
import { compose } from "recompose";
import styles from "./MembershipChart.module.scss";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { Text } from "~/patches/components";
import HelperCommentEdit from "~/experience-editor/HelperCommentEdit";

class MembershipChart extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			selectedTier: null
		};
	}

	render() {
		const {
			fields,
			sitecoreContext: { pageEditing },
			rendering: { rows },
			gigya
		} = this.props;
		const currentMembershipTier = (gigya?.data?.membershipTier !== undefined && gigya?.data?.membershipTier !== null) ? gigya?.data?.membershipTier : "empty";
		const standardTier = fields?.standardPriceTier?.fields?.level?.value ? fields?.standardPriceTier?.fields?.level?.value : null;
		const premiumTier = fields?.premiumPriceTier?.fields?.level?.value ? fields?.premiumPriceTier?.fields?.level?.value : null;
		const premiumFamilyTier = fields?.premiumFamilyPriceTier?.fields?.level?.value ? fields?.premiumFamilyPriceTier?.fields?.level?.value : null;
		const vipPriceTier = fields?.vipPriceTier?.fields?.level?.value ? fields?.vipPriceTier?.fields?.level?.value : null;
		const circleInsiderPriceTier = fields?.circleInsiderPriceTier?.fields?.level?.value ? fields?.circleInsiderPriceTier?.fields?.level?.value : null;

		const standardHighlight = (parseInt(currentMembershipTier) === parseInt(standardTier)) ? styles.currentTier : "";
		const premiumHighlight = (parseInt(currentMembershipTier) === parseInt(premiumTier)) ? styles.currentTier : "";
		const premiumFamilyHighlight = (parseInt(currentMembershipTier) === parseInt(premiumFamilyTier)) ? styles.currentTier : "";
		const vipHighlight = (parseInt(currentMembershipTier) === parseInt(vipPriceTier)) ? styles.currentTier : "";
		const circleInsiderHighlight = (parseInt(currentMembershipTier) === parseInt(circleInsiderPriceTier)) ? styles.currentTier : "";

		return (
			<>
				{fields && rows ? (
					<div className={styles.MembershipChart}>
						<table>
							<thead>
								<tr>
									<th className={styles.headline} >
										<div>
											<Text field={fields.heading} />
										</div>
									</th>
									<th className={styles.tier}>
										<div><Text field={fields.standardPriceTierName} /></div>
										{pageEditing ? (
											<p><Text field={fields.standardPriceTierPrice} /></p>
										) : (
											<p>{fields.standardPriceTierPrice.value.replace("{price}", fields.standardPriceTier?.fields?.price.value)}</p>
										)}
									</th>
									<th className={styles.tier}>
										<div><Text field={fields.premiumPriceTierName} /></div>
										{pageEditing ? (
											<p><Text field={fields.premiumPriceTierPrice} /></p>
										) : (
											<p>{fields.premiumPriceTierPrice.value.replace("{price}", fields.premiumPriceTier?.fields?.price.value)}</p>
										)}
									</th>
									<th className={styles.tier}>
										<div><Text field={fields.premiumFamilyPriceTierName} /></div>
										{pageEditing ? (
											<p><Text field={fields.premiumFamilyPriceTierPrice} /></p>
										) : (
											<p>{fields.premiumFamilyPriceTierPrice.value.replace("{price}", fields.premiumFamilyPriceTier?.fields?.price.value)}</p>
										)}
									</th>
									<th className={styles.tier}>
										<div><Text field={fields.vipPriceTierName} /></div>
										{pageEditing ? (
											<p><Text field={fields.vipPriceTierPrice} /></p>
										) : (
											<p>{fields.vipPriceTierPrice.value.replace("{price}", fields.vipPriceTier?.fields?.price.value)}</p>
										)}
									</th>
									<th className={styles.tier}>
										<div><Text field={fields.circleInsiderPriceTierName} /></div>
										{pageEditing ? (
											<p><Text field={fields.circleInsiderPriceTierPrice} /></p>
										) : (
											<p>{fields.circleInsiderPriceTierPrice.value.replace("{price}", fields.circleInsiderPriceTier?.fields?.price.value)}</p>
										)}
									</th>
								</tr>
							</thead>
							<tbody>
								{rows.map((row, index) => (
									<tr key={index}>
										<td className={styles.benefit}>
											<div><Text field={row.title} /></div>
										</td>
										<td className={`${styles.benefitData} ${standardHighlight}`}>
											{row.standard > 0 && (
												<>
													<div>&bull;</div>
													{row.standard > 1 && (
														<span>X{row.standard}</span>
													)}
												</>
											)}
										</td>
										<td className={`${styles.benefitData} ${premiumHighlight}`}>
											{row.premium > 0 && (
												<>
													<div>&bull;</div>
													{row.premium > 1 && (
														<span>X{row.premium}</span>
													)}
												</>
											)}
										</td>
										<td className={`${styles.benefitData} ${premiumFamilyHighlight}`}>
											{row.premiumFamily > 0 && (
												<>
													<div>&bull;</div>
													{row.premiumFamily > 1 && (
														<span>X{row.premiumFamily}</span>
													)}
												</>
											)}
										</td>
										<td className={`${styles.benefitData} ${vipHighlight}`}>
											{row.vip > 0 && (
												<>
													<div>&bull;</div>
													{row.vip > 1 && (
														<span>X{row.vip}</span>
													)}
												</>
											)}
										</td>
										<td className={`${styles.benefitData} ${circleInsiderHighlight}`}>
											{row.circleInsider > 0 && (
												<>
													<div>&bull;</div>
													{row.circleInsider > 1 && (
														<span>X{row.circleInsider}</span>
													)}
												</>
											)}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				) : (
					<HelperCommentEdit />
				)}
			</>
		);
	}
}

export default compose(
	withSitecoreContext(),
	withErrorHandling(),
	withNamespaces(),
	withGigya()
)(MembershipChart);