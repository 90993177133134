import React from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import { Arrow } from "~/shared-components/SvgIcons";
import { Link as RouterLink } from "react-router-dom";
import styles from "./TagsBlock.module.scss";

const TagsBlock = ({ rendering: { tags } }) => (
	<>
		{tags && tags.length > 0 &&
			<div className={styles.mainContainer}>
				<div className={styles.sectionName}>Go Deeper</div>
				<div className={styles.TagsBlock}>
					<div className={styles.inner}>
						{tags.map((tag, index) =>
							<RouterLink className={styles.link} key={index} to={tag.url}>
								<div className={styles.tag}>
									<span>{tag.tagName}</span> <Arrow />
								</div>
							</RouterLink>
						)}
					</div>
				</div>
			</div>
		}
	</>
);

export default compose(
	// other hocs goes here
	withErrorHandling()
)(TagsBlock);
