exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SharedTabContent-module__container--2YA9R {\n  display: none; }\n  .pageEditing .SharedTabContent-module__container--2YA9R {\n    display: block;\n    min-height: 15rem; }\n  .SharedTabContent-module__container--2YA9R.SharedTabContent-module__active---J9CR {\n    display: block; }\n", ""]);

// exports
exports.locals = {
	"container": "SharedTabContent-module__container--2YA9R",
	"active": "SharedTabContent-module__active---J9CR"
};