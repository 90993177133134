import { screensizes } from "~/site-settings.json";
import * as mediaManager from "./mediaManager";
import { animateScroll as scroll } from "react-scroll";

export { default as SetHyperlinksToRedirectWrapper } from "./SetHyperlinksToRedirectWrapper";
export { mediaManager };
export const isServer = (() => !(typeof window !== "undefined" && window.document))(); // eslint-disable-line no-restricted-globals

// These could potentionally gives styling problems as they are false in SSR
export const isDesktop = !isServer ? window.matchMedia(`(min-width: ${screensizes.sm})`).matches : false; // eslint-disable-line
export const isMobile = !isDesktop;
export const isTablet = !isServer ? window.matchMedia(`(min-width: ${screensizes.sm})`).matches && window.matchMedia(`(max-width: ${screensizes.lg})`).matches : false; // eslint-disable-line


/**
 * Used to check if field has a value
 * @param {object} fields
 * @param {string} fieldName
 * @param {string} type
 * @returns {boolean}
 */
export function fieldHasValue(fields, fieldName, type = "text") {
	if (fields && fieldName in fields) {
		const field = fields[fieldName];
		type = type.toLowerCase(); // eslint-disable-line no-param-reassign

		return fieldObjHasValue(field, type);
	}

	return false;
}

/**
 * Used to check if field has a value
 * @param {object} field
 * @param {string} type
 * @returns {boolean}
 */
export function fieldObjHasValue(field, type = "text") {
	if (field) {
		type = type.toLowerCase(); // eslint-disable-line no-param-reassign

		if (field.value) {
			switch (type) {
				case "link":
					if (field.value.href) {
						return true;
					}
					break;
				case "image":
				case "file":
					if (field.value.src) {
						return true;
					}
					break;
				case "date":
					if (field.value !== "0001-01-01T00:00:00Z" && field.value !== "1900-12-31T23:00:00Z") {
						return true;
					}
					break;
				default:
					return true;
			}
		}
	}

	return false;
}


/**
 * Function to determine whether a JSS image is empty in Sitecore Experience Editor
 *
 * @param {object} image - Sitecore Jss image object
 */
export function isExperienceEditorEmptyImage(image) {

	if (image?.value?.class === "scEmptyImage") return true;

	return false;
}

/**
 * Function to determine whether a placeholder contains any components
 *
 * @param {object} rendering - Sitecore rendering object
 * @param {string} placeholderName - Name of the placeholder
 */
export function isPlaceholderEmptyOrUndefined(rendering, placeholderName) {
	const components = rendering?.placeholders[placeholderName]?.filter(x => x?.componentName);

	if (components && components.length === 0) return true;

	return false;
}

/**
 * Function to get the length of a placeholders children
 *
 * @param {object} rendering - Sitecore rendering object
 * @param {string} placeholderName - Name of the placeholder
 */
export function placeholderChildrenLength(rendering, placeholderName) {

	if (!rendering || !placeholderName) return 0;

	const components = rendering?.placeholders[placeholderName];

	if (components && components.length === 0) return 0;

	return components.length;
}

/**
 * Function to convert a string into an url ready string
 *
 * @param {string} url
 */
export function wildcardUrlDecode(url) {

	if (!url) return "";

	return url.toLowerCase().replace(/ /g, "-");
}



let _ieVersion = undefined;

/**
 * Function to get IE version.
 * Returns 0 if it's not IE.
 *
 * @returns {number}
 */
export function getIEVersion() {
	if (!_ieVersion && !isServer) {
		const ua = window.navigator.userAgent;  // eslint-disable-line no-restricted-globals

		const msie = ua.indexOf("MSIE ");
		if (msie > 0) {
			// IE 10 or older => return version number
			return _ieVersion = parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
		}

		const trident = ua.indexOf("Trident/");
		if (trident > 0) {
			// IE 11 => return version number
			const rv = ua.indexOf("rv:");
			return _ieVersion = parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
		}

		const edge = ua.indexOf("Edge/");
		if (edge > 0) {
			// Edge (IE 12+) => return version number
			return _ieVersion = parseInt(ua.substring(edge + 5, ua.indexOf(".", edge)), 10);
		}
	}

	return _ieVersion;
}

/**
 * smooth scroll to element
 * @typedef {{duration: number, delay: number, smooth: boolean|string, offset: number, windowOffset: number}} options
 * @param {string} identifier
 * @param {options=} options
 * @param {Event=} event
 */
export function scrollToSmooth(identifier, options, event) {

	if (isServer) return;

	/**
	 * @type {HTMLElement}
	 */
	const element = document.querySelector(identifier); // eslint-disable-line no-restricted-globals

	if (!element) return;

	const _options = {
		duration: options?.duration || 300,
		delay: options?.delay || 100,
		smooth: options?.smooth || "easeInOutCubic",
		offset: options?.offset || 0
	};

	if (options?.windowOffset && options.windowOffset > element.offsetTop) return;

	if (event) event.preventDefault();

	scroll.scrollTo(element.offsetTop + (_options.offset), _options);
}

/**
 * Get url for category page by category name
 *
 * @param {string} category - Category Name
 * @param {Object} settings - SitecoreContext Settings item
 */
export function generateCateoryUrl(category, settings) {
	if (!category || !settings) return undefined;

	return `${settings?.fields?.allStoriesPage?.url}/${category.toLowerCase().replace(/ /g, "-").replace(/^-/, '')}`;
}

/**
 * Utility function to filter a single type array or an array of objects
 *
 * @param {array} array - Array to perform filtering on
 * @param {string} propToFilterBy - Property to filter on if objects are included in the array
 */
export const filterUnique = (array, propToFilterBy = null) => array.filter((value, index, self) => {
	if (!propToFilterBy) {
		return self.indexOf(value) === index;
	}

	if (!(propToFilterBy in value)) {
		// If property doesn't exist, just return something truthy
		// so we don't get perform invalid filtering
		return value;
	}

	return self.findIndex(elem => (elem[propToFilterBy] === value[propToFilterBy])) === index;
});