exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".AllMatchesTabsWithFilter-module__AllMatchesTabsWithFilter--1VLTD {\n  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.04)), to(rgba(0, 0, 0, 0)));\n  background: -o-linear-gradient(top, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0));\n  background: linear-gradient(180deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0));\n  font-size: 1.7rem;\n  line-height: 2.4rem; }\n\n.AllMatchesTabsWithFilter-module__sectionLabelContainer--ug3CA {\n  margin: 0 auto 2rem;\n  max-width: 1440px;\n  padding: 0 2rem;\n  width: 100%;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: justify;\n      justify-content: space-between;\n  -ms-flex-align: end;\n      align-items: flex-end; }\n\n.AllMatchesTabsWithFilter-module__text--3HkQt {\n  color: #C2002F;\n  font-size: 2rem;\n  margin: 10rem auto;\n  text-align: center;\n  text-transform: uppercase; }\n\n@media (min-width: 1025px) {\n  .AllMatchesTabsWithFilter-module__sectionLabelContainer--ug3CA {\n    margin-bottom: 2.5rem;\n    padding: 0 8.333rem; } }\n\n@media (min-width: 1440px) {\n  .AllMatchesTabsWithFilter-module__sectionLabelContainer--ug3CA {\n    max-width: 1640px; } }\n\n.AllMatchesTabsWithFilter-module__ticketmasterWrap--B5RGn {\n  margin-bottom: 8px; }\n  @media screen and (max-width: 1239px) {\n    .AllMatchesTabsWithFilter-module__ticketmasterWrap--B5RGn {\n      margin-bottom: 26px; } }\n", ""]);

// exports
exports.locals = {
	"AllMatchesTabsWithFilter": "AllMatchesTabsWithFilter-module__AllMatchesTabsWithFilter--1VLTD",
	"sectionLabelContainer": "AllMatchesTabsWithFilter-module__sectionLabelContainer--ug3CA",
	"text": "AllMatchesTabsWithFilter-module__text--3HkQt",
	"ticketmasterWrap": "AllMatchesTabsWithFilter-module__ticketmasterWrap--B5RGn"
};