import React from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { withErrorHandling } from "~/hoc";
import { Link, Image, Text } from "~/patches/components";
import { compose } from "recompose";
import styles from "./LinkBox.module.scss";

const LinkBox = ({ fields, sitecoreContext: { pageEditing } }) => (
	fields?.text?.value &&
	<li className={styles.LinkBox}>
		<Link field={fields?.link}>
			{fields?.icon && <Image field={fields?.icon} />}
			<div>
				{fields?.title && 
					<h4>
						<Text field={fields?.title} />
					</h4>}
				{pageEditing && fields?.text?.value
					? <p>
						<Text field={fields?.text} />
					</p>
					: <p
						dangerouslySetInnerHTML={{
							__html: fields?.text?.value.replace(/\n/g, "<br />")
						}}
					/>
				}
			</div>
		</Link>
	</li>
);

export default compose(
	withSitecoreContext(),
	withErrorHandling()
)(LinkBox);
