// REDESIGN 2022 //

import React, { useState, useEffect} from "react";
import { Text, RichText, Image } from "~/patches/components";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import styles from "./HeroHeader.module.scss";
import TextLink from "~/shared-components/TextLink";
import Button from "~/shared-components/Button";
import { useCallToActionBanner } from "~/hooks/useCallToActionBanner";
import { urlFor } from "~/utilities/sanityImage";


const HeroHeader = (props) => {
	let overlayStyle = {};
	let defaultStyle = {};
	let callToAction;
	const { fields, rendering } = props;

	const [variant, setVariant] = React.useState("largeRegularArrowRed");
	useEffect(() => {
		function handleResize() {
			if (window.innerWidth < 768) {
				setVariant("largeRegularArrowRed");
			} else {
				setVariant("mediumRegularArrowRed");
			}
		}
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);
	

	{/* Hero Styling Conditional Logic */}
	if (fields.gradient?.value) {
		overlayStyle = { 
			backgroundImage: `linear-gradient(90deg, rgba(21,25,43,1) 40%, 
			rgba(0,212,255,0) 100%), url('${fields.image.value.src}')`,
		};
	} else if (!fields.gradient?.value) {
		defaultStyle = { 
			backgroundColor: `#f7f7f7` 
		};
	}

	const { data } = useCallToActionBanner({ sitecoreItemId: rendering.dataSource });

	{/* Call to Action Conditional Logic */}
	if (fields.gradient?.value) {
		callToAction = <Button link={{value:data.internal.slug?.current}} text={{value: data.linkTo?.title}} size="large" variant="secondaryAlternate" />
	} else if (!fields.gradient?.value && fields.link?.value?.href) {
		callToAction = <TextLink link={fields.link.value.href} text={fields.text.value} variant={variant} />;
	}


	

	if (data) {
		const index = data.headline.indexOf("The");
		const firstPartDesktop =  data.headline.slice(0, index + 12); // include "the"
		const secondPartDesktop =  data.headline.slice(index + 13); // exclude "the" and space

		const indexMobile = data.mobileHeadline.indexOf("The");
		const firstPartMobile =  data.mobileHeadline.slice(0, indexMobile + 3); // include "the"
		const secondPartMobile =  data.mobileHeadline.slice(indexMobile + 4); // exclude "the" and space
		return (
		<>
		{fields && (
			<div>
				<div className={styles.forDesktop}>
					
					<div style={!fields.gradient?.value ? defaultStyle : overlayStyle} className={`${!fields.gradient?.value ? styles.Hero : styles.GradientHero}`}>
						<div className="redesignGridContainer">
							<div className={styles.heroContainer}>
								<div className={styles.heroContent}>
									<h2 className={styles.headlineDesktop}>
										<Text field={{value: firstPartDesktop}} />
									</h2>
									
									<h2 className={styles.headlineDesktop}>
										<Text field={{value:secondPartDesktop}} />
									</h2>
									<h2 className={styles.headlineMobile}>
										<Text field={{ value: firstPartMobile}} />
									</h2>
									<h2 className={styles.headlineMobile}>
										<Text field={{ value: secondPartMobile}} />
									</h2>

									<p className={styles.subtext}>
										{data.subheadline}
									</p>
									<p className={styles.callToAction}>
										{callToAction}
									</p>
								</div>

								{/* Desktop Image */}
								<div className={styles.heroImage}>
									{/* <Image field={fields.image}/> */}
									<img src={urlFor(data.bannerImage).maxWidth(300).url()} />
								</div>
								{/* Tablet Image */}
								<div className={styles.heroMobileImage}>
									{/* <Image field={fields.mobileImage}/> */}
									<img src={urlFor(data.tabletBannerImage).maxWidth(300).url()} />
								</div>
							</div>
						</div>
					</div>
					
				</div>
				<div  className={`${!fields.gradient?.value ? styles.Hero : styles.GradientHero} ${styles.forMobile}`}>
					<div style={!fields.gradient?.value ? defaultStyle : overlayStyle} className={styles.mobileImageWrap}>
						<div className={styles.heroContainer}>
							<div className="redesignGridContainer">
								<div className={styles.heroImage}>
									<img src={urlFor(data.bannerImage).maxWidth(300).url()} />
								</div>
							</div>
						</div>
					</div>
					<div className="redesignGridContainer">
						<div className={styles.heroContent}>
							<h2 className={styles.headlineMobile}>
								<Text field={{ value: firstPartMobile}} />
							</h2>
							<h2 className={styles.headlineMobile}>
								<Text field={{ value: secondPartMobile}} />
							</h2>

							<p className={styles.subtext}>
								{data.subheadline}
							</p>
							<p className={styles.callToAction}>
								{callToAction}
							</p>
						</div>
					</div>
					
				</div>
			</div>
		)}
	</>
		);
	}

	return (
	<>
		{fields && (
			<div>
				<div className={styles.forDesktop}>
					
					<div style={!fields.gradient?.value ? defaultStyle : overlayStyle} className={`${!fields.gradient?.value ? styles.Hero : styles.GradientHero}`}>
						<div className="redesignGridContainer">
							<div className={styles.heroContainer}>
								<div className={styles.heroContent}>
									<h2>
										<Text field={fields.title} />
									</h2>

									<p>
										<RichText field={fields.description} />
									</p>
									
									{callToAction}
								</div>

								{/* Desktop Image */}
								<div className={styles.heroImage}>
									<Image field={fields.image}/>
								</div>
								{/* Tablet Image */}
								<div className={styles.heroMobileImage}>
									<Image field={fields.mobileImage}/>
								</div>
							</div>
						</div>
					</div>
					
				</div>
				<div  className={`${!fields.gradient?.value ? styles.Hero : styles.GradientHero} ${styles.forMobile}`}>
					<div style={!fields.gradient?.value ? defaultStyle : overlayStyle} className={styles.mobileImageWrap}>
						<div className={styles.heroContainer}>
							<div className="redesignGridContainer">
								<div className={styles.heroImage}>
									<Image field={fields.image}/>
								</div>
							</div>
						</div>
					</div>
					<div className="redesignGridContainer">
						<div className={styles.heroContent}>
							<h2>
								<Text field={fields.title} />
							</h2>

							<p>
								<RichText field={fields.description} />
							</p>
							
							{callToAction}
						</div>
					</div>
					
				</div>
			</div>
		)}
	</>
	);
};

export default compose(
	// other hocs goes here
	withErrorHandling()
)(HeroHeader);