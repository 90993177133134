exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ImplicitGrid-module__grid--30Zt9 {\n  padding: .5em;\n  width: 100%; }\n  .ImplicitGrid-module__grid--30Zt9 .ImplicitGrid-module__implicitGrid--Rj3tP {\n    -ms-flex-align: start;\n        align-items: start;\n    grid-column-gap: 20px;\n    -webkit-column-gap: 20px;\n       -moz-column-gap: 20px;\n            column-gap: 20px;\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    max-width: 980px;\n    grid-row-gap: 20px;\n    row-gap: 20px; }\n    .ImplicitGrid-module__grid--30Zt9 .ImplicitGrid-module__implicitGrid--Rj3tP > * {\n      height: 100%; }\n    @media (min-width: 992px) {\n      .ImplicitGrid-module__grid--30Zt9 .ImplicitGrid-module__implicitGrid--Rj3tP.ImplicitGrid-module__threeCol--2UHJJ {\n        grid-template-columns: repeat(3, 1fr); } }\n    @media (max-width: 768px) {\n      .ImplicitGrid-module__grid--30Zt9 .ImplicitGrid-module__implicitGrid--Rj3tP {\n        grid-template-columns: 1fr; } }\n", ""]);

// exports
exports.locals = {
	"grid": "ImplicitGrid-module__grid--30Zt9",
	"implicitGrid": "ImplicitGrid-module__implicitGrid--Rj3tP",
	"threeCol": "ImplicitGrid-module__threeCol--2UHJJ"
};