import React from "react";
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Text, Image } from "~/patches/components";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import styles from "./P1Image.module.scss";
import { fieldObjHasValue } from "~/utilities";

const P1Image = ({
	fields,
	rendering: { customData },
	sitecoreContext: { route, pageEditing }
}) => {

	const inTwoColumnsLayout = route?.placeholders["jss-main"].filter(c => c.componentName === "Layouts_TwoColumnsLayout").length === 1;

	const data = customData ? customData : fields;
	const headline = fieldObjHasValue(fields?.text) ? fields.text : customData?.text;
	
	if (!data) return null;

	let desktopImage = data.image;
	let mobileImage = data.imageMobile;
	
	let hasDesktopImage = fieldObjHasValue(desktopImage, "image");
	let hasMobileImage = fieldObjHasValue(mobileImage, "image");

	// fallback each image
	if (hasDesktopImage && !hasMobileImage) {
		mobileImage = desktopImage;
	}
	if (hasMobileImage && !hasDesktopImage) {
		desktopImage = mobileImage;
	}

	// check for values again
	hasDesktopImage = fieldObjHasValue(desktopImage, "image");
	hasMobileImage = fieldObjHasValue(mobileImage, "image");

	return (
		<>
			{(data && (hasDesktopImage || hasMobileImage) || pageEditing) &&
				<div className={`${styles.P1Image} ${inTwoColumnsLayout ? styles.addMarginTop : ""}`}>
					<div className={data?.image?.value?.src ? styles.textContainer : styles.textContainerInvert}>
						<div className={styles.inner}>
							<Text field={headline} />
						</div>
					</div>

					{(hasDesktopImage || pageEditing) && (
						<Image
							className={styles.imageDesktop}
							field={desktopImage}
							srcSet={[{mw: 2560}, {mw: 768}]}
							sizes={"(min-width: 768px) 2560px, 768px"} />
					)}
					{(hasMobileImage || pageEditing) && (
						<Image field={mobileImage}
							className={styles.imageMobile}
							srcSet={[{mw: 768}]} />
					)}
				</div>
			}
		</>
	);
};

export default compose(
	withSitecoreContext(),
	withErrorHandling()
)(P1Image);