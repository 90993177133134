import React, { useState } from "react";
import LinkName from "~/shared-components/MultiLevelMenu/LinkName";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import LogoutModal from "~/shared-components/LogoutModal";
import { withNamespaces } from 'react-i18next';

const LogoutButton = ({ t }) => {
	const [showModal, setShowModal] = useState(false);

	return (
		<li >
			<button onClick={() => setShowModal(true)}>
				<LinkName link={t("logout_label")} fallbackLinkName={t("logout_label")} />
			</button>
			<LogoutModal showModal={showModal} onClose={() => setShowModal(false)} />
		</li>
	);
};

export default compose(
	withErrorHandling(),
	withNamespaces(),
)(LogoutButton);