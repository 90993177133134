exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".pageEditing .MediaCarousel-module__MediaCarousel--wiTcf {\n  min-height: 200px; }\n\n.MediaCarousel-module__MediaCarousel--wiTcf .flickity-prev-next-button.next {\n  right: -7.75rem; }\n\n.MediaCarousel-module__MediaCarousel--wiTcf .flickity-prev-next-button.previous {\n  left: -7.75rem; }\n\n.MediaCarousel-module__item--FRFDK {\n  width: 100%; }\n", ""]);

// exports
exports.locals = {
	"MediaCarousel": "MediaCarousel-module__MediaCarousel--wiTcf",
	"item": "MediaCarousel-module__item--FRFDK"
};