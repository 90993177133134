import React, { Fragment }  from "react";
import { ScrollContext } from "~/providers/ScrollProvider";
import { isServer } from "~/utilities";

export function withScroll() {
	return function withScrollHoc(WrappedComponent) {
		return function WithScroll(props) {
			return (
				<Fragment>
					{!isServer ? (
						<ScrollContext.Consumer>
							{({ scroll }) => (
								<WrappedComponent {...props} scroll={scroll}  />
							)}
						</ScrollContext.Consumer>
					) : (
						<WrappedComponent {...props} />
					)}

				</Fragment>
			);
		};
	};
}