import React, { PureComponent } from "react";
import styles from "./MatchCountdown.module.scss";

class MatchCountdown extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {};
	}

	updateTime = matchDateTime => {
		const timeLeft = matchDateTime - Date.now();
		const obj = {};

		obj.daysLeft = Math.floor(timeLeft / (24 * 60 * 60 * 1000));
		obj.hoursLeft = Math.floor((timeLeft - 24) / (60 * 60 * 1000) % 24);
		obj.minutesLeft = Math.floor((timeLeft - 60) / (60 * 1000) % 60);
		obj.secondsLeft = Math.floor((timeLeft - 60) / 1000) % 60;

		for (const key in obj) {
			if (obj.hasOwnProperty(key)) {
				if (obj[key] < 0) {
					obj[key] = 0;
				}
			}
		}

		this.setState(obj);
	};

	componentDidMount() {
		this.updateTime(this.props.datetime);
		this.interval = setInterval(() => this.updateTime(this.props.datetime), 1000);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	render() {
		const { datetime } = this.props;
		const { daysLeft, hoursLeft, minutesLeft, secondsLeft } = this.state;

		return (
			<>
				{datetime &&
					<div className={styles.matchCountdown}>
						{(daysLeft + hoursLeft + minutesLeft + secondsLeft === 0) ?
							<div className={styles.message}>The Match is about to start.  <span className={styles.reloadMessage} onClick={() => location.reload()}>Refresh your browser for live updates.</span></div> //eslint-disable-line
							:
							<>
								<div className={styles.timeIncrement}>
									<div className={styles.label}>Days</div>
									<div className={styles.value}>{daysLeft}</div>
								</div>
								<div className={styles.timeIncrement}>
									<div className={styles.label}>Hours</div>
									<div className={styles.value}>{hoursLeft}</div>
								</div>
								<div className={styles.timeIncrement}>
									<div className={styles.label}>Minutes</div>
									<div className={styles.value}>{minutesLeft}</div>
								</div>
								<div className={styles.timeIncrement}>
									<div className={styles.label}>Seconds</div>
									<div className={styles.value}>{secondsLeft}</div>
								</div>
							</>
						}
					</div>
				}
			</>
		);
	}
}

export default MatchCountdown;