import React from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react"; // eslint-disable-line no-restricted-imports
import { Text, Image, Link } from "~/patches/components";
import { Link as RouterLink, NavLink } from "react-router-dom";
import { SocialLinksFooter } from "~/shared-components/SocialLinks";import styles from "./Footer.module.scss";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import { withNamespaces } from "react-i18next";

const FooterOld = ({ fields, sitecoreContext: { settings }, t, rendering: { links } }) => (
	<footer data-component="Footer"  className={styles.Footer}>
		<div className={styles.primaryContainer}>
			<div className={`${styles.childContainer} ${styles.logoContainer}`}>
				<Image field={fields.logo} />
			</div>
			<div className={`${styles.childContainer} ${styles.linkContainer}`}>
				<div className={styles.subContainer}>
					<FooterLinksList
						links={links?.aboutUSSoccer}
						classNames={{ ul: styles.subnavItems }}
						heading={fields?.aboutUSSoccerHeading} 
						headingLink={fields?.aboutUSSoccerLink} />
				</div>
				<div className={styles.subContainer}>
					<FooterLinksList
						links={links?.federationPrograms}
						classNames={{ ul: styles.subnavItems }}
						heading={fields?.federationProgramsHeading}
						headingLink={fields?.federationProgramsLink} />
				</div>
			</div>

			<div className={`${styles.childContainer} ${styles.membersGroup}`}>
				<FooterLinksList
					links={links?.additionalLinks || []}
					classNames={{ ul: styles.members }} >
					<li className={styles.storeLinks}>
						<p>{t("footer_app-store-text")}</p>
						<div className={styles.storeLinksContainer}>
							<a href={settings.fields.appStoreUrl.value}>
								<Image field={settings.fields.appStoreImage} />
							</a>
							<a href={settings.fields.googlePlayStoreUrl.value}>
								<Image field={settings.fields.googlePlayStoreImage} />
							</a>
						</div>
					</li>
				</FooterLinksList>
				<div className={styles.socialLinks}>
					<SocialLinksFooter
						light
						alignLeft
					/>
				</div>
			</div>
		</div>
		<div className={styles.secondaryContainer}>
			<div className={styles.innerContainer}>
				<div className={styles.childContainer}>
					<div className={styles.copyright}>
						<div className={styles.copyrightItem} test-id={`footer-copyrightContainer-copyrightLabel`}><Text field={fields.copyrightLabel} /> {new Date().getFullYear()}</div>
						<div className={styles.copyrightItem} test-id={`footer-copyrightContainer-allRightsReservedLabel`}><Text field={fields.allRightsReservedLabel} /></div>
					</div>
				</div>
				<div className={styles.childContainer}>

					<FooterLinksList
						links={links?.legalLinks}
						classNames={{ul: styles.legalLinks, li: styles.legalLink}} />
				</div>
			</div>
		</div>
	</footer>
);

const FooterRedesign = ({ fields, sitecoreContext: { settings }, t, rendering: { links } }) => {
	
	return (
		<footer  data-component="Footer"  className={styles.FooterRedesign}>
			<div className={`${styles.primaryContainer} redesignGridContainer`}>
				<div className={`${styles.childContainer} ${styles.logoContainer}`}>
					<Image field={fields.logo} />
				</div>
				<div className={`${styles.childContainer} ${styles.linkContainer}`}>
					<RedesignFooterLinksList
						links={[...links?.aboutUSSoccer, ...links?.additionalLinks]}
						classNames={{ ul: styles.subnavItemsRedesign }}
						heading={fields?.aboutUSSoccerHeading} 
						headingLink={fields?.aboutUSSoccerLink} />
				</div>
				<div className={styles.socialAndStore}>
					<div className={`${styles.childContainer} ${styles.membersGroup}`}>
						<Text field={fields.socialConnectedText} tag="div" className={styles.socialConnectText}></Text>
						<div className={styles.socialLinks}>
							
							<SocialLinksFooter light alignLeft />
							
						</div>
					</div>
					<div className={styles.storeLinks}>
						<p>{t("footer_app-store-text")}</p>
						<div className={styles.storeLinksContainer}>
							<a href={settings.fields.appStoreUrl.value}>
								<Image field={settings.fields.appStoreImage} />
							</a>
							<a href={settings.fields.googlePlayStoreUrl.value}>
								<Image field={settings.fields.googlePlayStoreImage} />
							</a>
						</div>
					</div>
				</div>
				<div className={styles.insidersContainer}>
					<div className={styles.insidersImageWrap}>
						<Image field={fields.insidersSectionBackgroundImage} className={styles.insidersImage} />
						<div className={styles.bracketedTextSection}>
							<Image field={fields.insidersBracketText} />
						</div>
					</div>
					<div className={styles.insiderSection}>
						<div>
							<Text field={fields.insidersHeading} className={styles.insidersHeadingText} tag="div"/>
							<div className={styles.usersSection}>

								<RouterLink className={styles.signUpButton} to={fields.signUpButtonLink.value.href}>
									<Text field={fields.signUpButtonText}  tag="span"/>
								</RouterLink>
								
								<div className={styles.alreadySignedUp}>	
									<Text field={fields.alreadySignedUpText} tag="span" />
									<RouterLink to={fields.logInLinkPath.value.href}>
										<Text field={fields.logInLinkText}  tag="span"/>
									</RouterLink>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div className={styles.secondaryContainer}>
				<div className={`${styles.copyrightSection} redesignGridContainer`}>
					<div className={styles.childContainer}>
						<div className={styles.copyright}>
							<div className={styles.copyrightItem} test-id={`footer-copyrightContainer-copyrightLabel`}><Text field={fields.copyrightLabel} /> {new Date().getFullYear()}</div>
							<div className={styles.copyrightItem} test-id={`footer-copyrightContainer-allRightsReservedLabel`}><Text field={fields.allRightsReservedLabel} /></div>
						</div>
					</div>
					<div className={styles.legalLinks}>

						<RedesignFooterLinksList
							links={links?.legalLinks} />
					</div>
				</div>
			</div>
		</footer>
);
}
/**
 * @typedef {{ul: string, li: string}} classNames
 * @typedef {{links: Array<Object>, classNames: classNames, heading: string}} props
 * @param {props} props
 */

 const FooterLinksList = props => {
	const {links, classNames, heading, headingLink} = props;
	const headingObject = headingLink;
	if (headingObject) {
		headingObject.value.text = heading.value;
	}
	return  (
		<>
			{links && (
				<>
					{headingObject?.value.href 
						? <h5>
							<FooterLink {...{link: headingObject}} />
						</h5>
						: headingObject && 
							<h5>{headingObject.value.text}</h5>
					}
					<ul className={classNames?.ul}>
						{links.map((link, index) => (
							<li className={classNames?.li} key={index}>
								<FooterLink {...link} />
							</li>
						))}
						{props.children}
					</ul>
				</>
			)}
		</>
	);
};

const RedesignFooterLinksList = props => {
	const {links, heading, headingLink} = props;
	const headingObject = headingLink;

	if (headingObject) {
		headingObject.value.text = heading.value;
	}
	return  (
		<>
			{links && (
				<>
					{headingObject?.value.href 
						? <div className={styles.linkHeadingContainer}>
							<FooterLink {...{link: headingObject}} />
						</div>
						: headingObject && 
							<div className={styles.linkHeadingContainer}>{headingObject.value.text}</div>
					}
					{links.map((link, index) => (
						<div className={styles.linkItemContainer} key={index}>
							<FooterLink {...link} />
						</div>
					))}
					{props.children}
				</>
			)}
		</>
	);
};

const FooterLink = ({link, url, menuTitle, menuTitleMobile }) => (
	<>
		{link ? (		
			<Link className={styles.member} field={link} />
		) : (
			<RouterLink className={styles.member} to={url}>
				<Text tag="span" field={menuTitle} className={styles.desktop}/>
				<Text tag="span" field={menuTitleMobile} className={styles.mobile}/>
			</RouterLink>
		)}
	</>
);

const Footer = props => {
	return ( props.fields.redesign.value ? <FooterRedesign {...props} /> : <FooterOld {...props} />)
};

export default compose(
	withSitecoreContext(),
	withNamespaces(),
	withErrorHandling()
)(Footer);