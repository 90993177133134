import React from "react";

/**
 * Render a unique id wrapped around a component e.g. for the horizantal navigation to identify the location of a component.
 */
export function withUniqueRenderingId () {
	return function withUniqueRenderingIdHoc(WrappedComponent) {
		return function WithUniqueRenderingId(props) {
			return (
				<div id={`${props?.rendering?.uniqueName}`} className={`${props?.rendering?.uniqueName}`}>
					<WrappedComponent {...props} />
				</div>
			);
		};
	};
}