exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".TextBlockWithAd300x250-module__TextBlockWithAd300x250--3OahP {\n  color: #4d4d4d;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-direction: column;\n      flex-direction: column;\n  font-family: \"USSF90MinText\", helvetica, arial, sans-serif;\n  font-size: 1.6rem;\n  letter-spacing: 0.03rem;\n  line-height: 1.5;\n  margin: 0 auto 4rem;\n  max-width: 83.75rem;\n  padding: 0 20px; }\n  @media (min-width: 1025px) {\n    .TextBlockWithAd300x250-module__TextBlockWithAd300x250--3OahP {\n      display: block;\n      font-size: 1.334rem;\n      letter-spacing: 0.02rem; } }\n\n.TextBlockWithAd300x250-module__heading--3UEMR {\n  color: #15192B;\n  font-size: 2.5rem;\n  font-weight: 700;\n  margin: 0 0 2rem 0;\n  -ms-flex-order: 1;\n      order: 1;\n  text-transform: uppercase; }\n\n.TextBlockWithAd300x250-module__adContainer--Y5UWT {\n  -ms-flex-order: 3;\n      order: 3; }\n  @media (min-width: 1025px) {\n    .TextBlockWithAd300x250-module__adContainer--Y5UWT {\n      float: right;\n      -ms-flex-order: 2;\n          order: 2; } }\n\n.TextBlockWithAd300x250-module__bodyText--1F5bR {\n  -ms-flex-order: 2;\n      order: 2; }\n  @media (min-width: 1025px) {\n    .TextBlockWithAd300x250-module__bodyText--1F5bR {\n      -ms-flex-order: 3;\n          order: 3; } }\n\n.TextBlockWithAd300x250-module__ad--2I_f6 {\n  display: none; }\n\n.ad-loaded .TextBlockWithAd300x250-module__adContainer--Y5UWT {\n  padding: 2rem; }\n  .ad-loaded .TextBlockWithAd300x250-module__adContainer--Y5UWT .TextBlockWithAd300x250-module__ad--2I_f6 {\n    background: #BFBFBF;\n    display: block;\n    height: 250px;\n    margin: 0 auto;\n    width: 300px; }\n", ""]);

// exports
exports.locals = {
	"TextBlockWithAd300x250": "TextBlockWithAd300x250-module__TextBlockWithAd300x250--3OahP",
	"heading": "TextBlockWithAd300x250-module__heading--3UEMR",
	"adContainer": "TextBlockWithAd300x250-module__adContainer--Y5UWT",
	"bodyText": "TextBlockWithAd300x250-module__bodyText--1F5bR",
	"ad": "TextBlockWithAd300x250-module__ad--2I_f6"
};