exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".CallToActionBlock-module__CallToActionBlock--TCAeI {\n  background-color: #F7F7F7;\n  color: #212844;\n  overflow: hidden;\n  padding: 5rem 2rem; }\n  .CallToActionBlock-module__CallToActionBlock--TCAeI h2 {\n    font-size: 2.5rem;\n    font-weight: 700;\n    letter-spacing: 0.05em;\n    line-height: 1.12;\n    margin: 0 0 2rem;\n    text-transform: uppercase; }\n  .CallToActionBlock-module__CallToActionBlock--TCAeI p {\n    font-size: 1.5rem;\n    font-weight: 500;\n    letter-spacing: 0.01em;\n    line-height: 1.266;\n    margin: 2rem 0 3rem; }\n  .CallToActionBlock-module__CallToActionBlock--TCAeI a {\n    border: 0.1rem solid #212844;\n    border-radius: 0.3rem;\n    color: #212844;\n    display: block;\n    font-size: 1.2rem;\n    font-weight: 700;\n    letter-spacing: 0.05em;\n    margin-top: 3rem;\n    padding: 1.2rem 0 1rem;\n    text-align: center;\n    text-transform: uppercase; }\n    .CallToActionBlock-module__CallToActionBlock--TCAeI a:hover {\n      background-color: #212844;\n      color: #FFFFFF; }\n\n@media (min-width: 1025px) {\n  .CallToActionBlock-module__CallToActionBlock--TCAeI {\n    padding-left: 25.916rem;\n    padding-right: 25.916rem; }\n    .CallToActionBlock-module__CallToActionBlock--TCAeI h2 {\n      letter-spacing: 0.02em;\n      line-height: 1.3; }\n    .CallToActionBlock-module__CallToActionBlock--TCAeI p {\n      font-size: 1.25rem;\n      letter-spacing: 0.03em; }\n    .CallToActionBlock-module__CallToActionBlock--TCAeI a {\n      border-radius: 0.25rem;\n      font-size: 1rem;\n      max-width: 14.58rem;\n      padding: 1.1rem 0 0.9rem; } }\n", ""]);

// exports
exports.locals = {
	"CallToActionBlock": "CallToActionBlock-module__CallToActionBlock--TCAeI"
};