import React, { useState, Component } from "react";
import { Text } from "~/patches/components";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import styles from "./ScoreBarMini.module.scss";
import { MatchService } from "~/services/matchService";
import { optaApi, validDateTimeFormat } from "~/utilities/constants";
import { allFlags } from "~/utilities/flags";
import greenLight from "~/assets/img/GreenLight.png";
import MatchesResultsGrid from "../Matches/MatchesResultsGrid";

class ScoreBarMini extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isDataLoaded: false,
			matches: []
		};
	}

	componentDidMount = () => {
		if(this.props.fields.matchLink)
		{
			MatchService.getScoreboardByMatches(this.props.fields.matchLink)
				.then(response => {
					if (response && response.length > 0) {
						const matches = this.state.matches.slice();
						response.map(match => {
							matches.push(match);
						});
						this.setState({ matches: matches, isDataLoaded: true });
					}
				});
		}
		else
		{
			if (this.props.fields) {
					this.props.fields.teams.map(team => {
						MatchService.getScoreboardByContestants(team.fields.optaTeamId.value)
							.then(response => {
								if (response && response.length > 0) {
									const matches = this.state.matches.slice();
									response.map(match => {
										matches.push(match);
									});
									this.setState({ matches: matches, isDataLoaded: true });
								}
							});
					});
				}
			}
	}

	componentWillUnmount = () => {
	}

	render() {
		const { isDataLoaded, matches } = this.state;

		return (
			<>
				{isDataLoaded && matches && (
					<>
						{matches.map((match, index) =>
							<React.Fragment key={index}>
								{match.status === optaApi.matchStatus.live &&
									(
										<>
											<br/>
											<LiveBar match={match} />
										</>
									)
								}
								{match.status === optaApi.matchStatus.upcoming &&
									(
										<>
											<br/>
											<UpcomingBar match={match} />
										</>
									)
								}
							</React.Fragment>
						)};
					</>
				)}
			</>
		);
	}
}
const LiveBar = ({ match }) => {
	return (
		<>
			<ul className={styles.liveBarArea}>
				<li className={styles.liveBarLeft}>
					<LiveTime time={match.time} />
				</li>
				<li className={styles.liveBarMiddle}>
					<MatchStatus match={match} />
				</li>
				<li className={styles.liveBarRight}>
					<Broadcast broadcastLinks={match.broadcastLinks} status={match.status} />
				</li>
			</ul>
		</>
	)
}
const UpcomingBar = ({ match }) => {
	return (
		<>
			<ul className={styles.upcomingBarArea}>
				<li className={styles.upcomingBarLeft}>
					<MatchDate match={match} />
				</li>
				<li className={styles.upcomingBarMiddle}>
					<MatchData match={match} />
				</li>
				<li className={styles.upcomingBarRight}>
					<Broadcast broadcastLinks={match.broadcastLinks} status={match.status} />
				</li>
			</ul>
		</>
	)
}

const Broadcast = ({ broadcastLinks, status }) => {
	return (
		<>
			<div className={styles.broadcastArea}>
				<ul className={styles.broadcastLinks}>
					<li key='99999' className={styles.broadcastHeader}>
						WHERE<br />TO WATCH
					</li>
					{broadcastLinks.filter(broadcasterLink => broadcasterLink.imageURL !== 'string')
					.filter((broadcasterLink, index) =>
					(status === optaApi.matchStatus.upcoming && index < 2) 
					|| (status !== optaApi.matchStatus.upcoming && index < 3))
					.map((broadcasterLink, index) =>
						<li key={index} className={styles.broadcastLink}>
							<a href={broadcasterLink.broadcastURLWeb} target="_blank" rel="noopener noreferrer">
								{broadcasterLink.imageURL
									? <img src={broadcasterLink.imageURL} alt={broadcasterLink.imageAltText} />
									: <span>{broadcasterLink.imageAltText}</span>
								}
							</a>
						</li>
					)}
					{status === optaApi.matchStatus.upcoming &&
						(
							<li key='99998' className={styles.broadcastBuyTickets}>
								BUY TICKETS
							</li>
						)}
					<li key='99997' className={styles.broadcastMatchHub}>
						MATCH HUB
					</li>
				</ul>
			</div>
		</>
	);
};

const LiveTime = ({ time }) => {
	const t = time.substring(0, 8).split(':');
	t[1] = +t[1] + (60 * +t[0])
	return (
		<>
			<ul className={styles.liveTimeArea}>
				<li className={styles.liveTime}>
					<img className={styles.liveTimeGreenLight} src={greenLight} />
					<span>LIVE&nbsp;-&nbsp;</span>
					{t[1] > 0 && (
						<span>{t[1]}&apos;&nbsp;</span>
					)}
					{t[2] > 0 && (
						<span>{t[2]}&quot;&nbsp;</span>
					)}
					<span className={styles.liveTimeSpacer} >|</span>
				</li>
			</ul>
		</>
	);
}

const MatchStatus = ({ match }) => {
	return (
		<>
			<ul className={styles.matchStatusArea}>
				<li>
					<TeamInfo team={match.contestants[0]} homeAway='home' />
				</li>
				<li className={styles.matchStatusDash}>-</li>
				<li>
					<TeamInfo team={match.contestants[1]} homeAway='away' />
				</li>
			</ul>
		</>
	)
}

const MatchData = ({ match }) => {
	return (
		<>
			<ul className={styles.matchStatusArea}>
				<li>
					<TeamData team={match.contestants[0]} />
				</li>
				<li className={styles.matchStatusDash}>&nbsp;</li>
				<li>
					<TeamData team={match.contestants[1]} />
				</li>
			</ul>
		</>
	)
}

const MatchDate = ({ match }) => {
	const d = new Date(match.dateTime.replace(/TBD/gi, '00:00:00Z'));
	const month = d.toLocaleString('default', { month: 'long' }).substring(0, 3).toUpperCase();
	const day = `0${d.getDay()}`.substring(-2, 2);
	const year = d.getFullYear();
	let ampm = 'AM';
	let h = d.getHours();
	if (h > 11) {
		if (h > 12)
			h = h - 12;
		ampm = 'PM';
	}
	const hour = `0${h}`.substring(-2, 2);
	const minute = `0${d.getMinutes()}`.substring(-2, 2);
	let time = `${month} ${day} ${year}`;
	if (match.dateTime.indexOf('TBD') > 0)
		time = `${time} TBD`;
	else if (minute === 0)
		time = `${time} ${hour} ${ampm} CST`;
	else if (minute === 0)
		time = `${time} ${hour}:${minute} ${ampm} CST`;
	else if (minute === 0)
		time = `${time} ${hour}:${minute} ${ampm} CST`;
	return (
		<>
			<ul className={styles.nextMatchArea}>
				<li className={styles.nextMatchHeader}>
					NEXT MATCH
				</li>
				<li className={styles.nextMatchTime}>
					{time}
				</li>
			</ul>
		</>
	)
}

const TeamInfo = ({ team, homeAway }) => {
	return (
		<>
			<ul className={homeAway === 'home' ? styles.teamInfoHome : styles.teamInfoAway}>
				<li className={`${homeAway === 'home' ? styles.teamInfoNameHome : styles.teamInfoNameAway} ${styles.teamInfoName}`}>{team.name}</li>
				<li className={styles.teamInfoFlag}>{allFlags[team.code]}</li>
				<li className={styles.teamInfoScore}>{team.score}</li>
			</ul>
		</>
	);
};

const TeamData = ({ team }) => {
	return (
		<>
			<ul className={styles.teamInfoAway}>
				<li className={`${styles.teamInfoNameAway} ${styles.teamInfoName}`}>{team.name}</li>
				<li className={styles.teamInfoFlag}>{allFlags[team.code]}</li>
			</ul>
		</>
	);
};

export default compose(
	withErrorHandling()
)(ScoreBarMini);