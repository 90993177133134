import React, { PureComponent } from "react";
import { compose } from "recompose";
import { withNamespaces } from "react-i18next";
import { withErrorHandling } from "~/hoc";
import Thumbnail from "./Thumbnail.js";
import RedesignThumbnail from "./RedesignThumbnail.js";

class ScoreboardThumbnail extends PureComponent {
	constructor(props) {
		super(props);
	}

	render() {
		return this.props.redesign ? 
			<RedesignThumbnail {...this.props} /> : 
			<Thumbnail {...this.props} />;
	}	
}

export default compose(
	withNamespaces(),
	withErrorHandling()
)(ScoreboardThumbnail);