import {useQuery} from '@tanstack/react-query';

export const usePlayersByName = ({ playerNames }) => {
	const { data, isLoading, isError } = useQuery({ 
		queryKey: ["usePlayerByName", { playerNames }],
		queryFn: async () => {
			const playerInfos = await Promise.allSettled(
				playerNames.map(playerName => fetch(`https://api.ussoccer.com/api/playerinfo/byname/${playerName}`).then((r) => r.json()).catch((e) => console.error(e)))
			).then((results) => results.filter((r) => r.status === 'fulfilled').map((r) => r.value));

			const playerInfosWithUrl = playerInfos
				.filter(playerInfo => (playerInfo.FirstName && playerInfo.LastName))
				.map(playerInfo => {
					const playerPageUrl = `/players/${playerInfo.LastName[0].toLowerCase()}/${playerInfo.FirstName.toLowerCase()}-${playerInfo.LastName.toLowerCase()}`;
					
					return {
						...playerInfo,
						playerPageUrl
					};
				});

			return playerInfosWithUrl;
		}
	});

	return { 
		playerInfosWithUrl: data,
		isLoading,
		isError
	};
};