import React, { Fragment } from "react";
import AlignedTextWithImage from "~/shared-components/AlignedTextWithImage";
import { compose } from "recompose";
import { withErrorHandling } from "~/hoc";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";

const options = {
	classModifier: "right",
	srcSet: [{mw: 720}, {mw: 768}],
	sizes: "(min-width: 768px) 720px, 768px"
};

const ImageWithTextRight = props => (
	<Fragment>
		<AlignedTextWithImage {...{options, ...props}} />
	</Fragment>
);


export default compose(
	withSitecoreContext(),
	withErrorHandling(),
)(ImageWithTextRight);