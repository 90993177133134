exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".BrightcoveFilmstrip-module__BrightcoveFilmstripContainer--zRqfA .BrightcoveFilmstrip-module__FilmStrip--O7RFV {\n  margin: 0 auto; }\n", ""]);

// exports
exports.locals = {
	"BrightcoveFilmstripContainer": "BrightcoveFilmstrip-module__BrightcoveFilmstripContainer--zRqfA",
	"FilmStrip": "BrightcoveFilmstrip-module__FilmStrip--O7RFV"
};