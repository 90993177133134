exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SharedAccordion-module__container--2f8jg {\n  position: relative; }\n\n@media (min-width: 1025px) {\n  .SharedAccordion-module__container--2f8jg {\n    margin: 0 auto 5rem auto;\n    max-width: 1440px; }\n    .SharedAccordion-module__container--2f8jg:before {\n      display: none; } }\n", ""]);

// exports
exports.locals = {
	"container": "SharedAccordion-module__container--2f8jg"
};