import { useQuery } from '@tanstack/react-query';

export const useLatestVideos = ({ currentVideoIds = [] }) => {
	const { data, isLoading, isError } = useQuery({
		queryKey: ["useLatestVideos", { currentVideoIds }],
		queryFn: async () => {
			const latestVideos = await fetch(`https://api.ussoccer.com/api/mobile/videos?limit=5&offset=0`)
				.then((r) => r.json())
				.catch((e) => console.error(e));

			return latestVideos
				.sort((a, b) => (new Date(b.PublishDate) - new Date(a.PublishDate)))
				.filter(video => !currentVideoIds.includes(video.EmbeddedCode))
				?.slice(0, 3);
		}
	});

	return {
		latestVideos: data,
		isLoading,
		isError
	};
};
