exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".AlertCard-module__AlertCard--22Z0R {\n  background-color: #F7F7F7;\n  border-radius: 0.3rem;\n  -webkit-box-shadow: 0 0 0.1rem 0 #BFBFBF;\n          box-shadow: 0 0 0.1rem 0 #BFBFBF;\n  margin: 2rem 0;\n  overflow: hidden;\n  padding: 0 2rem 2rem; }\n  .AlertCard-module__AlertCard--22Z0R svg {\n    margin-bottom: 1.7rem; }\n  .AlertCard-module__AlertCard--22Z0R .AlertCard-module__richText--2Epn8 {\n    color: #4d4d4d;\n    font-size: 1rem !important;\n    letter-spacing: 0.05rem !important;\n    line-height: 1.5; }\n  .AlertCard-module__AlertCard--22Z0R p {\n    font-size: 1rem;\n    letter-spacing: 0.01em;\n    line-height: 1.3;\n    margin-bottom: 1.4rem; }\n    .AlertCard-module__AlertCard--22Z0R p .AlertCard-module__header--fO9ih {\n      font-weight: bold;\n      text-transform: uppercase; }\n  .AlertCard-module__AlertCard--22Z0R a {\n    color: #C2002F;\n    cursor: pointer;\n    text-decoration: underline; }\n\n@media (min-width: 1025px) {\n  .AlertCard-module__AlertCard--22Z0R {\n    border-radius: 0.25rem;\n    max-width: 50.667rem; }\n    .AlertCard-module__AlertCard--22Z0R svg {\n      height: auto; } }\n", ""]);

// exports
exports.locals = {
	"AlertCard": "AlertCard-module__AlertCard--22Z0R",
	"richText": "AlertCard-module__richText--2Epn8",
	"header": "AlertCard-module__header--fO9ih"
};