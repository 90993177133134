import React from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import BrightcoveVideoPlayer from "../BrightcoveVideoPlayer";

const BrightcoveVideo = ({ fields }) => {
	const videoEmbedCode = fields.videoId ? fields.videoId.value : fields.refId.value;
	const playerId = fields.playerId ? fields.playerId.value : "default";

	const pickedBrightcoveVideo = (videoId, playerId) => ({
		pickedBrightcoveVideo: {
			fields: {
				embedCode: { value: videoId },
				videoPlayerId: { value: playerId }
			}
		}
	});

	return (
		<>
			{videoEmbedCode && (
				<BrightcoveVideoPlayer fields={pickedBrightcoveVideo(videoEmbedCode, playerId)}></BrightcoveVideoPlayer>
			)}
		</>
	);
};

export default compose(
	// other hocs goes here
	withErrorHandling()
)(BrightcoveVideo);