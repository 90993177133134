import React from "react";
import { Link as RouterLink } from 'react-router-dom';
import { withErrorHandling, withSectionHeader } from "~/hoc";
import { compose } from "recompose";
import PlayerYearTable from "./PlayerYearTable";
import { Text } from "~/patches/components";

const PlayerYearStats = ({ fields, rendering: { yearStats, /*playerId, */playerStatsUrl } }) => (
	<>
		<PlayerYearTable yearStats={yearStats} />
		{playerStatsUrl && (
			<RouterLink to={playerStatsUrl}>
				<Text field={fields.buttonText} className="btn btn-red" />
			</RouterLink>
		)}
	</>
);

export default compose(
	withSectionHeader(({fields, rendering: { playerStatsUrl }}) => {
		if (!playerStatsUrl) return;
		fields.sectionLink.value.href = playerStatsUrl;
	}),
	withErrorHandling()
)(PlayerYearStats);