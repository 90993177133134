import React, { useEffect } from "react";
import { compose } from "recompose";
import { withErrorHandling, withScroll } from "~/hoc";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import SharedScoreboardBanner from "~/shared-components/ScoreboardBanner";
import RedesignFeaturedMatch from "~/shared-components/RedesignFeaturedMatch";
import RedesignMiniFeaturedMatch from "~/shared-components/RedesignMiniFeaturedMatch";
import originalStyles from "./ScoreboardBanner.module.scss";
import redesignStyles from "./RedesignScoreboardBanner.module.scss";
import { optaApi } from "~/utilities/constants";

const ScoreboardBanner = ({ scroll, fields, fields: { appLink, teamFormationImage, includeSpaceAbove, redesign, reference }, rendering, rendering: { scoreboardBanner, contestantIds, matchIds }, sitecoreContext, sitecoreContext: {pageEditing} }) => {
	const styles = redesign?.value ? redesignStyles : originalStyles;
	const isFeaturedMatchLive = scoreboardBanner?.filter(match => match.matchId === reference?.fields?.optaMatchId?.value).filter(match => match.status === optaApi.matchStatus.live).length !== 0;
	const isSingleLiveMatch = scoreboardBanner?.filter(match => match.status === optaApi.matchStatus.live).length === 1;
	const isFeaturedMatchOnlyLive = isSingleLiveMatch && isFeaturedMatchLive;
	const scrollAmount = 200;
	let isMini = false;
	isMini = scroll > scrollAmount;

	return (
		(contestantIds?.length > 0 || matchIds?.length > 0) && (
			<div className={`${styles.scoreboard} ${reference?.fields?.optaMatchId?.value ? styles.hasFeaturedMatch : styles.noFeaturedMatch } ${isFeaturedMatchOnlyLive ? styles.fullsizeLiveFeatured : ""} ${includeSpaceAbove?.value ? styles.addTopMargin : ""}`}>

				{!redesign?.value && (
					<>
						<SharedScoreboardBanner data={scoreboardBanner} contestantIds={contestantIds} matchIds={matchIds} redesign={redesign?.value} />
						<div className={styles.adContainer}>
							<div id="div-gpt-ad-scoreboard" className={styles.ad} />
						</div>
					</>
				)}

				{redesign?.value && (
					<>
						{reference?.fields?.optaMatchId?.value && (
							<div className={styles.featuredMatchWrapper}>
								<RedesignFeaturedMatch isMini={false} appLink={appLink} teamFormationImage={teamFormationImage} matchId={reference.fields.optaMatchId.value} isFeaturedMatchOnlyLive={isFeaturedMatchOnlyLive} pageEditing={pageEditing} rendering={rendering} />
								<RedesignMiniFeaturedMatch miniVisible={isMini} matchId={reference.fields.optaMatchId.value} pageEditing={pageEditing} rendering={rendering} />
							</div>
						)}
						{!isFeaturedMatchOnlyLive && (
							<div className={styles.scoreboardBannerWrapper}>
								<SharedScoreboardBanner data={scoreboardBanner} contestantIds={contestantIds} matchIds={matchIds} featuredMatchId={reference?.fields?.optaMatchId?.value} redesign={redesign.value} showFilters={true} />
							</div>
						)}
					</>
				)}
			</div>
		)
)};

export default compose(
	withScroll(),
	withSitecoreContext(),
	withErrorHandling()
)(ScoreboardBanner);