import React, { Component } from "react";
import { compose } from "recompose";
import { withErrorHandling } from "~/hoc";
import { Text, Image } from "~/patches/components";
import { RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { fieldHasValue } from "~/utilities";
import { MapMarker, SiteIcon, Mail, Phone, Arrow } from "~/shared-components/SvgIcons"; // TODO: utilize new icons from Osamu.   @wessw 4/9
import styles from "./OrganizationsMembersAccordionTab.module.scss";

class SharedAccordionTab extends Component {
	constructor(props) {
		super(props);

		this.state = {
			openTab: false
		};
	}

	toggleAccordion = () => {
		this.setState(prevState => ({ 
			openTab: !prevState.openTab
		}));
	}

	componentDidUpdate(prevProps) {
		const { stateName: prevStateName } = prevProps;
		const { stateName } = this.props;

		if (prevStateName !== stateName) {
			this.setState({
				openTab: false
			});
		}
	}

	render() {
		const { label, labelAddition, content, sitecoreContext: { pageEditing } } = this.props;
		const { openTab } = this.state;

		return (
			<div className={openTab ? styles.containerActive : styles.container} >
				<div className={styles.header} onClick={() => this.toggleAccordion()}>
					<Text tag="h3" field={label} />
					<Text className={styles.city} tag="small" field={labelAddition} />
					<div className={styles.arrow}>
						<Arrow />
					</div>
				</div>
				<div className={styles.content}>
					<div className={styles.intro}>
						<div><Image field={content.image} /></div>
						{/* TODO: swap in new generic leadership position fields when BE is done @wessw 4/9*/}
						<dl>
							{(fieldHasValue(content, "presidentNameLabel") || pageEditing) && (
								<dt className={styles.title}><Text field={content.presidentNameLabel} /></dt>
							)}
							<dd className={styles.name}><Text field={content.presidentName} /></dd>
							{(fieldHasValue(content, "executiveDirectorNameLabel") || pageEditing) && (
								<dt className={styles.title}><Text field={content.executiveDirectorNameLabel} /></dt>
							)}
							<dd className={styles.name}><Text field={content.executiveDirectorName} /></dd>
						</dl>
					</div>

					<RichText field={content.bodyText} />

					{(fieldHasValue(content, "mailingAddress") || fieldHasValue(content, "website") || fieldHasValue(content, "email") || fieldHasValue(content, "phone")) &&
						<div className={styles.contact}>
							<ul>
								{fieldHasValue(content, "mailingAddress") &&
									<li>
										<MapMarker />
										<h5>Mailing Address</h5>
										{content.mailingAddress.value}
									</li>
								}

								{fieldHasValue(content, "website") &&
									<li>
										<SiteIcon />
										<h5>website</h5>
										<a href={content.website.value} target="_blank" rel="noopener noreferrer">{content.website.value}</a>
									</li>
								}

								{fieldHasValue(content, "email") &&
									<li>
										<Mail />
										<h5>Email</h5>
										<a href={`mailto:${content.email.value}`}>{content.email.value}</a>
									</li>
								}

								{fieldHasValue(content, "phone") &&
									<li>
										<Phone />
										<h5>Phone</h5>
										<a href={`tel:${content.phone.value}`}>{content.phone.value}</a>
									</li>
								}
							</ul>
						</div>
					}
				</div>
			</div>
		);
	}
}

export default compose(
	withSitecoreContext(),
	withErrorHandling()
)(SharedAccordionTab);