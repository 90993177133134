import React from "react";
import { Placeholder, withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { withErrorHandling, withGigya } from "~/hoc";
import { compose } from "recompose";

const LoginGatedContent = ({ rendering, gigya, sitecoreContext: { pageEditing }}) => 
	
	<>
		{(gigya.isLoggedIn || pageEditing) && (
			<div>
				<Placeholder name="jss-layout-gated" rendering={rendering} />
			</div>
		)}
		{(!gigya.isLoggedIn || pageEditing) && (
			<div>
				<Placeholder name="jss-layout-public" rendering={rendering} />
			</div>
		)}
	</>
;

export default compose(
	// other hocs goes here
	withErrorHandling(),
	withGigya(),
	withSitecoreContext(),
)(LoginGatedContent);