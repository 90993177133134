import React from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { Text } from "~/patches/components";
import ArrowLink from "~/shared-components/ArrowLink";
import styles from "./CareerMoment.module.scss";
import { withNamespaces } from 'react-i18next';
import { compose } from "recompose";
import { withErrorHandling } from "~/hoc";

const CareerMoment = ({ careerItem, t, sitecoreContext: { pageEditing } }) => (
	<>
		{careerItem && (
			<div className={styles.CareerMoment}>
				<div className={styles.content}>
					<Text tag="div" className={styles.text} field={careerItem.fields.dateRange} />
					<Text tag="div" className={styles.text} field={careerItem.fields.name} />
				</div>
				<div className={styles.link}>
					{careerItem.fields?.pivotalMoments?.value?.href && careerItem.fields?.pivotalMoments?.value?.href !== "/" &&
						<ArrowLink link={careerItem.fields.pivotalMoments} linkText={pageEditing ? "" : t("career_moments_link_name")} />}
				</div>
			</div>
		)}
	</>
);

export default compose(
	withSitecoreContext(),
	withNamespaces(),
	withErrorHandling(),
)(CareerMoment);