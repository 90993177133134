exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".OrganizationsMembersAccordion-module__OrganizationsMembersAccordion--1tblL {\n  margin-bottom: 10rem; }\n\n@media (min-width: 1025px) {\n  .OrganizationsMembersAccordion-module__OrganizationsMembersAccordion--1tblL {\n    margin: 0 8rem 5rem 0; } }\n", ""]);

// exports
exports.locals = {
	"OrganizationsMembersAccordion": "OrganizationsMembersAccordion-module__OrganizationsMembersAccordion--1tblL"
};