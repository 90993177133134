import React, { useState } from "react";
import moment from "moment";
import { Text } from "~/patches/components";
import JsonLd from "~/components/JsonLd";
import styles from "./MatchInfo.module.scss";
import { optaApi, USTeamNames } from "~/utilities/constants";
import {allFlags, openCupFlags} from "~/utilities/flags";
import { withErrorHandling } from "~/hoc";
import { useFetchLiveMatchData } from "~/hooks/match";
import { fieldObjHasValue } from "~/utilities";
import { compose } from "recompose";
import MatchButtons from "~/shared-components/RedesignMatchButtons";
import "moment-timezone";
import {getPenaltiesForMatch} from "~/utilities/getPenaltiesForMatch";
import {competitions} from "~/utilities/competitions";

const MatchInfo = ({ isFeaturedMatchOnlyLive,  doubleHeaderMatches, getTotalGoals, match, pageEditing, type }) => {
	const [isValidMatchDateTime, setIsValidMatchDateTime] = useState(true);
	const [updatedMatch, setUpdatedMatch] = useState(null);
	useFetchLiveMatchData(match, setIsValidMatchDateTime, setUpdatedMatch, getTotalGoals);

	const buildMatchTeamData = (updatedMatch) => {
		const matchDate = updatedMatch?.date && moment(updatedMatch.date).isValid() ? moment(updatedMatch.date).format('MMM DD YYYY') : "TBD";
		const dataObjectStartDate = isValidMatchDateTime && updatedMatch ? updatedMatch.dateTime : updatedMatch?.date;
		const home = updatedMatch?.contestants?.[0] ? updatedMatch.contestants[0] : null;
		const away = updatedMatch?.contestants?.[1] ? updatedMatch.contestants[1] : null;
		const USTeam = updatedMatch?.contestants?.filter(contestant => contestant.code === "USA")[0];
		const USTeamId = USTeam?.id;
		const USTeamName = USTeamNames[USTeamId];

		// Creates a matching youth prefix for the opponent if the US Team name is youth
		const isYouthMatch = USTeamName?.includes("U-");
		const youthLevel = isYouthMatch ? USTeamName?.slice(0, 4) : "";

		const dataObject = {
			"@context": "http://schema.org",
			"@type": "SportsEvent",
			"name": home?.id === USTeamId ? `${USTeamName} v. ${away?.name}` : `${home?.name} v. ${USTeamName}`,
			"description": updatedMatch?.competition?.name,
			"startDate": dataObjectStartDate,
			"location": {
				"@type": "Place",
				"name": `${updatedMatch?.venue?.longName}`,
				"address": `${updatedMatch?.venue?.location}, ${updatedMatch?.venue?.country}`
			},
			"competitor": [
				{
					"@type": "SportsTeam",
					"name": home?.id === USTeamId ? USTeamName : home?.name
				},
				{
					"@type": "SportsTeam",
					"name": away?.id === USTeamId ? USTeamName : away?.name
				}
			],
			"url": updatedMatch?.mathchFeedUrl
		};

		return {
			away,
			awayPenaltyShotsScored,
			dataObject,
			home,
			homePenaltyShotsScored,
			matchDate,
			USTeamName,
			isYouthMatch,
			youthLevel
		};
	};

	const builtMatchTeamData = buildMatchTeamData(updatedMatch);
	const hasBroadcastLinks = match?.sitecoreData?.broadcasterLinks?.length || match?.broadcastLinks?.length;
	const stageName = updatedMatch?.stageName;
	const {
		away,
		awayPenaltyShotsScored,
		dataObject,
		home,
		homePenaltyShotsScored,
		matchDate,
		USTeamName,
		isYouthMatch,
		youthLevel
	} = builtMatchTeamData;

	const matchOpponentsDisplayProps = {
		away,
		awayPenaltyShotsScored,
		doubleHeaderMatches,
		home,
		homePenaltyShotsScored,
		matchDate,
		isValidMatchDateTime,
		type,
		updatedMatch,
		stageName,
		USTeamName,
		isYouthMatch,
		youthLevel
	};

	return (
		updatedMatch && (
			<div className={`${isFeaturedMatchOnlyLive ? styles.featuredMatchIsOnlyLiveInfo : ''} ${(type === "P1" || type === "P1Competition") ? styles.MatchInfoP1 : styles.MatchInfo} ${updatedMatch.status === optaApi.matchStatus.live ? styles.liveMatch : styles.match}`}>
				<MatchOpponentsDisplay {...matchOpponentsDisplayProps} />

				{match.status === optaApi.matchStatus.completed && match.sitecoreData && (
					<div className={styles.matchButtons} style={{marginTop: '-1.5rem'}}>
						<MatchButtons event={match} pageEditing={pageEditing} type="P1" />
					</div>
				)}

				<JsonLd data={dataObject} />
			</div>
		)
	);
};

const MatchOpponentsDisplay = ({ away, home, pageEditing, type, isValidMatchDateTime, updatedMatch, USTeamName, matchDate, stageName, isYouthMatch, youthLevel }) => {
	const penalties = getPenaltiesForMatch({match: updatedMatch, away, home});

	const flagsRepository = updatedMatch.competition.id === competitions.openCup ? openCupFlags : allFlags;
	const flagsRepositoryStyling = updatedMatch.competition.id === competitions.openCup ? styles.openCupLogo : styles.teamFlag;

	return (
		<>
			{updatedMatch.status !== optaApi.matchStatus.upcoming && (
				<>

					<div className={`${styles.liveCompetitionStage} ${updatedMatch.status === optaApi.matchStatus?.live ? styles.nopadding : ''}`}>
						{updatedMatch.competition?.isFriendly && updatedMatch.competition?.name === "International Friendly"
							? updatedMatch.competition.name
							: USTeamName ? `${USTeamName} - ${stageName}` : stageName}
						{updatedMatch.status === optaApi.matchStatus.completed && updatedMatch.sitecoreData && (
							<div className={styles.sponsorText}>
								{(fieldObjHasValue(updatedMatch.sitecoreData?.sponsorText) || pageEditing) ? (
									<Text field={updatedMatch.sitecoreData.sponsorText} />
								) : (
									<>{updatedMatch.sponsor}</>
								)}
							</div>
						)}
					</div>
					{updatedMatch.status === optaApi.matchStatus.live && (
						<>
							<div className={styles.sponsorText}>
								{(updatedMatch.sitecoreData?.sponsorText || pageEditing) ? (
									<Text field={updatedMatch.sitecoreData.sponsorText} />
								) : (
									<span>{updatedMatch.sponsor}</span>
								)}
							</div>
						</>
					)}

				</>
			)}
			<div className={updatedMatch.status === optaApi.matchStatus.live ? styles.teamVs : styles.teamVsUpcoming}>

				<div className={styles.teamHome}>
					<div className={flagsRepositoryStyling}>
						{flagsRepository[home.code]}
					</div>
					{home?.code === 'USA' ?
						<div className={styles.teamCode}>{USTeamName ? USTeamName : home.code}</div> :
						<div className={styles.teamCode}>{isYouthMatch ? `${youthLevel} ${home.code}` : home.name}</div>
					}
				</div>

				<div className={styles.matchData}>
					{updatedMatch.status === optaApi.matchStatus.upcoming
						? (
							<div className={styles.competitionStage}>
								<div>
									{type === "P1" &&
									updatedMatch?.sitecoreData?.competition?.competitionUrl
										? <a href={updatedMatch.sitecoreData?.competition?.competitionUrl}>
											<div className={styles.title}>{updatedMatch.competition.name}</div>
										</a>
										: <div className={styles.title}>{updatedMatch.competition.name}</div>
									}
								</div>

								<div>
									{updatedMatch.competition.isFriendly && updatedMatch.competition.name === "International Friendly"
										? USTeamName
										: stageName}
								</div>
							</div>
						) : (
							<>
								<div className={styles.liveMatchData}>
									<span className={styles.score}>
										{home.score}
										{penalties.shouldDisplay && (
											<div className={styles.penaltyScore}>({penalties.home})</div>
										)}
									</span>
									{updatedMatch.status === optaApi.matchStatus.completed ?
										<div className={styles.ft}>FT</div> :
										<div className={styles.to}></div>
									}
									<span className={styles.score}>
										{away.score}
										{penalties.shouldDisplay && (
											<div className={styles.penaltyScore}>({penalties.away})</div>
										)}
									</span>
								</div>
							</>
						)
					}
					{updatedMatch.status === optaApi.matchStatus.upcoming && (
						isValidMatchDateTime ?
							<>
								{(moment(updatedMatch.dateTime) < moment() && updatedMatch.status === optaApi.matchStatus.upcoming)
									? <div className={styles.awaiting}>Awaiting Kickoff</div>
									: <div className={styles.startDate}>
										{updatedMatch.status !== optaApi.matchStatus.live
											?
											updatedMatch.cancelled
												? <><span>Cancelled</span></>
												: <>{moment(updatedMatch.dateTime).tz(moment.tz.guess()).format('MMM DD YYYY - h:mmA')} {moment(updatedMatch.dateTime).tz(moment.tz.guess()).zoneAbbr()}</>
											: <></>
										}
									</div>
								}
							</>
							:
							<>
								<div className={styles.startDate}>
									{updatedMatch.status === optaApi.matchStatus.upcoming ?
										<>
											{matchDate
												?
												<>
													<span>{matchDate} - TBD</span>
												</>
												:
												<>
													<span>TBD</span>
												</>
											}
										</>
										:
										<>
											<span>TBD</span>
										</>
									}
								</div>
							</>
					)}
					{updatedMatch.status === optaApi.matchStatus.upcoming &&
						<div className={styles.location}>
							{!updatedMatch?.venue?.longName || updatedMatch?.venue?.longName === "TBD" ?
								"TBD" :
								<>
									<span className={styles.venueItem}>{updatedMatch?.venue?.longName}, </span>
									<span className={styles.venueItem}>{updatedMatch?.venue?.location}</span>
								</>
							}
						</div>
					}
					{updatedMatch.status === optaApi.matchStatus.upcoming && updatedMatch.sitecoreData && (
						<div className={styles.sponsorText}>
							{(fieldObjHasValue(updatedMatch.sitecoreData?.sponsorText) || pageEditing) ? (
								<Text field={updatedMatch.sitecoreData.sponsorText} />
							) : (
								<>{updatedMatch.sponsor}</>
							)}
						</div>
					)}
				</div>

				<div className={styles.teamAway}>
					<div className={flagsRepositoryStyling}>
						{flagsRepository[away.code]}
					</div>
					{away?.code === 'USA' ?
						<div className={styles.teamCode}>{USTeamName ? USTeamName : away.code}</div> :
						<div className={styles.teamCode}>{isYouthMatch ? `${youthLevel} ${away.code}` : away.name}</div>
					}
				</div>
			</div>
			{updatedMatch.status === optaApi.matchStatus.live && (
				<div className={styles.liveMatchButtons}>
					<MatchButtons event={updatedMatch} pageEditing={pageEditing} type="P1" />
				</div>
			)}
		</>
	);
};

export default compose(
	// other hocs goes here
	withErrorHandling()
)(MatchInfo);