import React from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import { Image, Link, Text } from "~/patches/components";
import styles from "./TicketOptionsCard.module.scss";
import { withNamespaces } from "react-i18next";
import { fieldHasValue } from "~/utilities";

const TicketOptionsCard = card => (
	<section className={styles.TicketOptionsCard}>
		<Image field={card.image} />
		<Text tag="h4" field={card.heading} />
		<Text tag="p" field={card.description} />
		<CallToActionLink {...card} />
	</section>
);

const CallToActionLink = card => {
	const {
		t,
		sitecoreContext: { pageEditing }
	} = card;

	if (!fieldHasValue(card, "link", "link") && !pageEditing) return <></>;

	return (
		<>
			{card?.renderLinkAsButton?.value ? (
				<div className={styles.button}>
					<Link field={card.link}>{t("ticket_options_buy_now")}</Link>
				</div>
			) : (
				<div className={styles.link}>
					<Link field={card.link} />
				</div>
			)}
		</>
	);
};

export default compose(
	// other hocs goes here
	withSitecoreContext(),
	withNamespaces(),
	withErrorHandling()
)(TicketOptionsCard);
