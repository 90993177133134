import {useQuery} from '@tanstack/react-query';

export const useNextMatch = (queryKey) => {
    const { contestantId } = queryKey;
	const { data, isLoading, isError } = useQuery({ 
		queryKey: ["useNextMatch", queryKey],
		queryFn: async () => {
			if (!contestantId) return null;
			const matches = await fetch(`https://api.ussoccer.com/api/match?contestantId=${contestantId}&year=0&upcoming=all&includeCurrent=true&past=0`).then((r) => r.json()).catch((e) => console.error(e));
            const nextMatch = matches?.[matches.length - 1];
            return nextMatch;
		}
	});

	return { 
		nextMatch: data,
		isLoading,
		isError
	};
}