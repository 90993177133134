import React, { Component } from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import { fieldHasValue } from "~/utilities";
import { Image, Text, RichText } from "~/patches/components";
import { ChevronButton } from "~/shared-components/SvgIcons";
import SectionLabel from "~/shared-components/SectionLabel";
import styles from "./MatchPreview.module.scss";

class MatchPreview extends Component {
	constructor(props) {
		super(props);

		this.textContainerRef = React.createRef();
		this.state = {
			isTruncated: true
		};
	}

	componentDidMount() {
		const containerHeight = this.textContainerRef.current.clientHeight;
		const scrollHeight = this.textContainerRef.current.scrollHeight;

		if (scrollHeight <= containerHeight) {
			this.setState({ isTruncated: false });
		}
	}

	render() {
		const { fields, sitecoreContext: { pageEditing } } = this.props;
		const { isTruncated } = this.state;

		return (
			<>
				{fields && (
					<div className={styles.MatchPreview}>
						<div className={styles.heading}>
							{(fields.sectionLabel?.value || pageEditing) && (
								<div className={styles.sectionLabelContainer}>
									{fields.sectionLabel && <SectionLabel sectionLabel={fields.sectionLabel} />}
								</div>
							)}

							{(fieldHasValue(fields.title) || pageEditing) &&
								<div className={styles.titleContainer}>
									<h1><Text field={fields.title} /></h1>
								</div>
							}
						</div>

						<div className={styles.imageContainer}>
							{(fieldHasValue(fields, "image") || pageEditing) &&
								<div className={styles.desktop}>
									<Image
										field={fields.image}
										srcSet={[{mw: 440}, {mw: 300}]}
										sizes={"(min-width: 768px) 440px, 300px"} />
								</div>
							}
							{(fieldHasValue(fields, "imageMobile") || pageEditing) &&
								<div className={styles.mobile}>
									<Image
										field={fields.imageMobile}
										srcSet={[{mw: 440}, {mw: 300}]}
										sizes={"(min-width: 768px) 440px, 300px"} />
								</div>
							}
						</div>

						<div className={styles.summaryContainer}>
							<div className={isTruncated && !pageEditing ? styles.textTruncated : styles.text} ref={this.textContainerRef}>
								<RichText field={fields.summary} />
							</div>
							{(!pageEditing && isTruncated) &&
								<div className={styles.chevButton} onClick={() => this.setState({ isTruncated: false })}>
									<ChevronButton />
								</div>
							}
						</div>
					</div>
				)}
			</>
		);
	}
}

export default compose(
	withSitecoreContext(),
	withErrorHandling()
)(MatchPreview);