exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".TicketTextBlock-module__TicketTextBlock--3eN5N {\n  margin: 2rem; }\n  .TicketTextBlock-module__TicketTextBlock--3eN5N h2 {\n    color: #212844;\n    font-size: 2.5rem;\n    font-weight: 700;\n    letter-spacing: 0.05rem;\n    line-height: 1.12;\n    text-transform: uppercase; }\n  .TicketTextBlock-module__TicketTextBlock--3eN5N p {\n    color: #4d4d4d;\n    font-family: \"USSF90MinText\", helvetica, arial, sans-serif;\n    font-size: 1.5rem;\n    line-height: 1.267; }\n\n@media (min-width: 768px) {\n  .TicketTextBlock-module__TicketTextBlock--3eN5N {\n    margin: 2rem auto;\n    max-width: 56%; } }\n", ""]);

// exports
exports.locals = {
	"TicketTextBlock": "TicketTextBlock-module__TicketTextBlock--3eN5N"
};