exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".TextBlockWithAd300x600-module__TextBlockWithAd300x600--1EDH0 {\n  color: #4d4d4d;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-direction: column;\n      flex-direction: column;\n  font-family: \"USSF90MinText\", helvetica, arial, sans-serif;\n  font-size: 1.6rem;\n  letter-spacing: 0.03rem;\n  line-height: 1.5;\n  margin: 0 auto 4rem;\n  max-width: 83.75rem;\n  padding: 0 20px; }\n  @media (min-width: 1025px) {\n    .TextBlockWithAd300x600-module__TextBlockWithAd300x600--1EDH0 {\n      display: block;\n      font-size: 1.334rem;\n      letter-spacing: 0.02rem; } }\n\n.TextBlockWithAd300x600-module__heading--3wDlo {\n  color: #15192B;\n  font-size: 2.5rem;\n  font-weight: 700;\n  margin: 0 0 2rem 0;\n  -ms-flex-order: 1;\n      order: 1;\n  text-transform: uppercase; }\n\n.TextBlockWithAd300x600-module__adContainer--1RNdY {\n  -ms-flex-order: 3;\n      order: 3; }\n  @media (min-width: 1025px) {\n    .TextBlockWithAd300x600-module__adContainer--1RNdY {\n      float: right;\n      -ms-flex-order: 2;\n          order: 2; } }\n\n.TextBlockWithAd300x600-module__bodyText--3ao3- {\n  -ms-flex-order: 2;\n      order: 2; }\n  @media (min-width: 1025px) {\n    .TextBlockWithAd300x600-module__bodyText--3ao3- {\n      -ms-flex-order: 3;\n          order: 3; } }\n\n.TextBlockWithAd300x600-module__ad--3QlTq {\n  display: none; }\n\n.ad-loaded .TextBlockWithAd300x600-module__adContainer--1RNdY {\n  padding: 2rem; }\n  .ad-loaded .TextBlockWithAd300x600-module__adContainer--1RNdY .TextBlockWithAd300x600-module__ad--3QlTq {\n    background: #BFBFBF;\n    display: block;\n    height: 600px;\n    margin: 0 auto;\n    width: 300px; }\n", ""]);

// exports
exports.locals = {
	"TextBlockWithAd300x600": "TextBlockWithAd300x600-module__TextBlockWithAd300x600--1EDH0",
	"heading": "TextBlockWithAd300x600-module__heading--3wDlo",
	"adContainer": "TextBlockWithAd300x600-module__adContainer--1RNdY",
	"bodyText": "TextBlockWithAd300x600-module__bodyText--3ao3-",
	"ad": "TextBlockWithAd300x600-module__ad--3QlTq"
};