import React, { useEffect } from "react";
import { Image } from "~/patches/components";
import { withErrorHandling, withGigya } from "~/hoc";
import { compose } from "recompose";
import moment from "moment";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { withNamespaces } from "react-i18next";
import { membershipName } from "~/utilities/helpers";
import { PaymentIntentMessage } from "./PaymentIntentMessage";
import styles from "./P1Membership.module.scss";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const P1Membership = ({ t, fields, sitecoreContext: { membershipLevels, stripe: { apiKey } }, gigya: { data, profile } }) => {
	const stripePromise = loadStripe(apiKey);
	let tierName = membershipName(data?.membershipTier);

	useEffect(() => {
		tierName = membershipName(data?.membershipTier);
	}, [data]);

	return (
		<Elements stripe={stripePromise}>
			{fields && data && (
				<div className={styles.P1Membership}>
					<div className={`${styles.textContainer} ${(data?.membershipTier === 1 || data?.membershipTier === 2) ? styles.darkColor : ""}`}>
						<div className={styles.textWrapper}>
							<div>{profile?.firstName} {profile?.lastName}</div>
							<div>
								<span>{tierName}&nbsp;</span>
								<span>{data?.membershipBegin && `${t("p1membership_since")} ${moment(data?.membershipBegin).format("YYYY")}`}&nbsp;</span>
								<PaymentIntentMessage />
							</div>
						</div>
						<div className={styles.caps}>
							<div>{data?.caps}</div>
							<div>{Object.keys(data).filter(k => k.toUpperCase() === "CAPS")[0]}</div>
						</div>
					</div>
					<Image field={membershipLevels && membershipLevels[data?.membershipTier]?.bannerImage} />
				</div>
			)}
		</Elements>
	);
};

export default compose(
	withGigya(),
	withNamespaces(),
	withSitecoreContext(),
	withErrorHandling()
)(P1Membership);