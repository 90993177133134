/* eslint-disable react/jsx-key */
import React from "react";
import moment from "moment";
import MatchButtons from "~/shared-components/MatchButtons";
import MatchCountdown from "~/shared-components/MatchCountdown";
import styles from "./P1Event.module.scss";
import { compose } from "recompose";
import { withErrorHandling } from "~/hoc";

const P1Event = ({ children, event, customCalendarEvent, type, pageEditing, completed, goalTotal }) => {

	const startTime = new Date(type === "competition" ? event.startDate : event.dateTime);
	// set absolutely positioned white background height dynamically based on total number of goals
	const whiteBackgroundHeight = (goalTotal * 42) + 50;
	return	(
		<>
			{event &&
				<div
					style={event.sitecoreData?.brandedBackground?.value.src ? { backgroundImage: `url(${event.sitecoreData.brandedBackground.value.src})` } : {}}
					className={`${completed ? styles.P1EventCompleted : styles.P1Event} ${type === "competition" ? styles.competition : styles.notCompetition}`}>

					<div className={styles.inner}>

						<div className={`${styles.content} ${type === "competition" && !event.sitecoreData?.brandedBackground?.value?.src ? styles.extendedBackground : ""}`} >
							{children}
						</div>

						{event.cancelled 
							? <>
								{
									<div className={styles.countdownContainerCancelled}>&nbsp;</div>
								}
							</>
							:
							<>
								{startTime && (moment(startTime) > moment()) && (
									<div className={styles.countdownContainer}>
										<MatchCountdown datetime={startTime} type={type} />
									</div>
								)}
								<div className={styles.buttonsContainer}>
									<MatchButtons customCalendarEvent={customCalendarEvent} event={event} pageEditing={pageEditing} type="P1" eventType={type} />
								</div>
							</>
						}
					</div>
					<div className={type === "competition" ? styles.competitionWhiteBackground : styles.matchWhiteBackground} style={type === "competition" ? { height: `${whiteBackgroundHeight}px` } : { height: `40rem` } }></div>
				</div>
			}
		</>
	);
};

export default compose(
	withErrorHandling()
)(P1Event);