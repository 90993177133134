import React from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import SharedAccordion from "~/shared-components/SharedAccordion";
import SharedAccordionTab from "~/shared-components/SharedAccordion/SharedAccordionTab";
import { isPlaceholderEmptyOrUndefined } from "~/utilities";
import HelperCommentEmptyPlaceholder from "~/experience-editor/HelperCommentEmptyPlaceholder";

const Accordion = ({ rendering }) => (
	<>
		{rendering && (
			<SharedAccordion render={tabHandler => (
				<>
					{isPlaceholderEmptyOrUndefined(rendering, "jss-accordion-content") && (
						<HelperCommentEmptyPlaceholder />
					)}
					<Placeholder
						name="jss-accordion-content"
						rendering={rendering}
						renderEach={(component, index) => (
							<SharedAccordionTab
								key={index}
								{...tabHandler}
								label={component.props?.fields?.heading}
								index={index}>
								{component}
							</SharedAccordionTab>
						)}/>
				</>
			)}/>
		)}
	</>
);

export default compose(
	// other hocs goes here
	withErrorHandling()
)(Accordion);