import React, { useState} from "react";
import { compose } from "recompose";
import { withErrorHandling } from "~/hoc";
import styles from "./SharedAccordion.module.scss";

function SharedAccordion(props) {
	const [openTab, setOpenTabs] = useState({ 0 : false });

	function setOpenTab(index) {
		const isOpen = !!openTab[index];
		setOpenTabs({ [index]: !isOpen });
	}

	return (
		<>
			<section className={styles.container}>
				{props.render({
					openTab,
					setOpenTab
				})}
			</section>
		</>
	);
}

export default compose(
	withErrorHandling()
)(SharedAccordion);