/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { MembershipService } from "~/services/membershipService";
import { IBadge } from "~/shared-components/SvgIcons";
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import styles from "~/components/Billing/Billing.module.scss";

export default function CheckoutForm(props) {
	const stripe = useStripe();
	const elements = useElements();

	const {
		billingProps: {
			gigya,
			gigya: {
				data,
				isLoggedIn,
				userInfo
			},
			selectedTier,
			t,
			updateBillingInfo
		},
		state: {
			errorMessage,
			isAlreadyReceivingBenefits,
			isError,
			isLoading,
			isProRate,
			isSuccess,
			proRateCredit,
			proRatePayment,
			showBillingInformation,
			useCardOnFile
		},
		setState,
		gigyaSubmitHandler,
		GTMHandler
	} = props;

	useEffect(() => {
		if (isLoggedIn && selectedTier > data?.membershipTier) {
			getProration();
		}

		if (data?.membershipTier === 0 || updateBillingInfo) {
			setState({
				useCardOnFile: false
			});
		}
	}, [isLoggedIn, selectedTier]);

	const getProration = async () => {
		setState({ 
			isLoading: true
		});

		if (selectedTier !== "1" && parseInt(data?.membershipTier) >= 1) {
			const jwt = await getJWT();
			const proRate = await MembershipService.getProrationPreview(jwt, selectedTier);

			// content may not exist on proRate if calling localhost backend
			const proRateCreditAmount = proRate?.content?.CreditAmount || 0;
			const proRateNewPlanAmount = proRate?.content?.PaymentAmount || 0;
			const cardBrand = proRate?.content?.Brand ?? proRate?.BankName;
			const cardLastFour = proRate?.content?.LastFour || "";

			const cardOnFile = cardBrand !== "" && cardLastFour !== "";

			if (proRateCreditAmount > 0) {
				setState({
					proRateCredit: (proRateCreditAmount / 100).toFixed(2),
					proRatePayment: (Math.ceil(proRateNewPlanAmount - proRateCreditAmount) / 100).toFixed(2),
					isProRate: data?.membershipTier > 0,
					showCardOnFileRadioSet: cardOnFile,
					useCardOnFile: cardOnFile,
					showBillingInformation: !cardOnFile,
					cardBrand,
					cardLast4: cardLastFour
				});
			}
		}

		setState({ 
			isLoading: false
		});
	};

	const getJWT = async () => new Promise((res, rej) =>
		gigya.getJWT(
			gigyaRes => gigyaRes.errorCode
				? rej(gigyaRes.errorMessage)
				: res(gigyaRes.id_token)
	)).catch(err => console.log("1: ", err)); //eslint-disable-line

	const handleError = (error) => {
		setState({ 
			disabled: false,
			isLoading: false,
			errorMessage: error.message,
			isSuccess: false
		});
	};

	const handleMembershipServiceResult = (result) => {
		// statusCode may not exist on result if calling localhost backend
		if (result.statusCode !== 200) {
			handleError(result);
			return;
		} else {
			setState({
				disabled: true,
				isError: false,
				showBillingInformation: false,
				showCardOnFileRadioSet: false
			});

			// Only fire Google Tag Manager callback on upgrade
			!updateBillingInfo ?? GTMHandler(selectedTier, true);
		}

		return result;
	};

	const handleSubmit = async (event) => {
		// We don't want to let default form submission happen here,
		// which would refresh the page.
		event.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js hasn't yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		// Trigger Gigya validation
		const gigyaFormHasError = await gigyaSubmitHandler(event);
		if (gigyaFormHasError) {
			handleError({ message: t("billingmodal_address-validation-error") });
			return;
		}

		// Trigger Stripe validation
		if (!useCardOnFile && !gigyaFormHasError) {
			// Trigger form validation and wallet collection
			const {error: submitError} = await elements.submit();
			if (submitError) {
				handleError(submitError);
				return;
			}
			else {
				// if this hits, there were no errors with gigya or stripe
				await confirmPayment();
			}
		}
		else if (useCardOnFile && !gigyaFormHasError) {
			await confirmPayment();
		}
	};

	const confirmPayment = async () => {
		// Update billing info or update the subscription
		const jwt = await getJWT();

		const { ClientSecret: clientSecret, PaymentMethod: paymentMethod } = updateBillingInfo ? 
			await MembershipService.createSetupIntent(jwt)
				.then((res) => handleMembershipServiceResult(res))
				.then((result) => result?.content)
				.catch((err) => handleError(err)) :
			await MembershipService.upgradeMembershipTier(jwt, selectedTier)
				.then((res) => handleMembershipServiceResult(res))
				.then((result) => result?.content?.SubscriptionCreateResponse)
				.catch((err) => handleError(err));
	
		const confirmIntent = updateBillingInfo ? stripe.confirmSetup : stripe.confirmPayment;
	
		if (!useCardOnFile) {
			const {error} = await confirmIntent({
				elements,
				clientSecret,
				confirmParams: {
					// eslint-disable-next-line camelcase
					// stripe does not currently allow dynamic urls, so we're just hardcoding this for now
					return_url: `https://www.ussoccer.com/profile/my-profile`,
				},
				redirect: updateBillingInfo ? "if_required" : "always"
			});

			if (error) {
				// This point is only reached if there's an immediate error when confirming the Intent.
				// Show the error to your customer (for example, "payment details incomplete").
				handleError(error);
			} else {
				setState({ isSuccess: true });
			}

			setState({ isLoading: false });
		}
		else {
			const {error} = await confirmIntent({
				clientSecret,
				confirmParams: {
					payment_method: paymentMethod,
					// stripe does not currently allow dynamic urls, so we're just hardcoding this for now
					return_url: `https://www.ussoccer.com/profile/my-profile`,
				},
				redirect: updateBillingInfo ? "if_required" : "always"
			});

			if (error) {
				// This point is only reached if there's an immediate error when confirming the Intent.
				// Show the error to your customer (for example, "payment details incomplete").
				handleError(error);
			} else {
				setState({ isSuccess: true });
			}
			
			setState({ isLoading: false });
		}
	};

	const options = {
		defaultValues: {
			billingDetails: {
				name: userInfo?.nickname,
				email: userInfo?.email,
				address: {
					// eslint-disable-next-line camelcase
					postal_code: userInfo?.zip
				}
			}
		}
	};

	return (
		<div className={styles.cardInfo}>
			{isProRate && !isSuccess && !isError &&
				<div className={styles.alertCard}>
					<IBadge />
					<p>{t("billingmodal_prorate-part1")}&nbsp;${proRateCredit}&nbsp;{t("billingmodal_prorate-part2")}</p>
				</div>
			}
			<form onSubmit={handleSubmit}>
				<div className={!isLoggedIn || !showBillingInformation || isLoading || isAlreadyReceivingBenefits ? styles.hidePaymentForm : ''}>
					<div className={styles.formHeading}>
						<span>{t("billingmodal_secure")}</span>
						<span>{t("billingmodal_billing-info")}</span>
					</div>
					<PaymentElement options={options}/>
				</div>
				{isError &&
					<div className={styles.alertCard}>
						<IBadge />
						<p>{errorMessage}</p>
					</div>
				}
				{!isLoading 
					? <>
						{!isAlreadyReceivingBenefits && !isSuccess && isLoggedIn &&
							<button className={styles.billingButton} type="submit" disabled={!stripe || isLoading}>
								{!updateBillingInfo
									? <>{t("billingmodal_pay")} ${proRatePayment}</>
									: t("billingmodal_update-card")}
							</button>
						}
					</>
					: <div className={styles.loader}></div>
				}
			</form>
			
		</div>
	);
}