/* eslint-disable no-restricted-globals */
import React, {useEffect, useRef, useState} from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import styles from "./StoryTemplate.module.scss";
import {useLatestStories} from "~/hooks/useLatestStories";
import {useLatestVideos} from "~/hooks/useLatestVideos";
import LatestStories from "~/components/LatestStories";
import LatestStoryThumbSmall from "~/components/LatestStories/LatestStoryThumbSmall";
import LatestVideos from "~/components/LatestVideos";
import _ from "lodash";
import {usePlayersByName} from "~/hooks/usePlayersByName";
import PlayerThumbnailStory from "~/components/PlayerThumbnail/PlayerThumbnailStory";
import {useRelatedArticlesByTags} from "~/hooks/useRelatedArticlesByTags";
import {Arrow, ArrowDown} from "~/shared-components/SvgIcons";
import BrightcoveVideo from "~/components/BrightcoveVideo";
import {Link} from "react-router-dom";

const SidebarBlockHeading = ({text}) => (
	<h2 className={styles.sidebarBlockHeading}>{text}</h2>
);



const StoryTemplate = ({ rendering }) => {
	const allTags = rendering
		?.placeholders?.['jss-layout-story-template']
		.find(component => component.componentName === 'TagsBlock')
		?.tags;
	const playerNamesToCheck = allTags
		.map(tag => tag.tagName)
		.filter(tag => tag.split(' ').length === 2);

	const { relatedArticles } = useRelatedArticlesByTags({ tags: allTags });
	const { playerInfosWithUrl } = usePlayersByName({ playerNames: playerNamesToCheck });

	const story = _.cloneDeep(rendering)
		?.placeholders['jss-layout-story-template']
		.find(i => i.componentName === 'P1Story')
		?.story;
	let listOfLatestStories = [];
	if (story) {
		const { latestStories } = useLatestStories({storyLink: story.link});
		listOfLatestStories = latestStories;
	}

	// Get 3 latest videos from /api/mobile/videos but exclude video included in
	// the story if there is one
	// TODO add support for up to 2 videos.
	const videos = _.cloneDeep(rendering)
		?.placeholders['jss-layout-story-template']
		.filter(i => i.componentName === 'BrightcoveVideo')
		.map(component => component?.fields)
		.filter(video => video?.videoId.value !== '');
	const videoIdsArray = videos?.map(video => video.videoId.value);

	let listOfLatestVideos = [];
	const { latestVideos } = useLatestVideos({currentVideoIds: videoIdsArray});
	listOfLatestVideos = latestVideos;

	/**
	 * Logic for handling stories with short (or no) text and a Brightcove video.
	 */
	const textComponents = _.cloneDeep(rendering);
	textComponents.placeholders['jss-layout-story-template'] = rendering?.
		placeholders['jss-layout-story-template']
		.filter(item => item.componentName === 'TextBlock');

	// Stories with short text.
	//
	// To avoid additional complexity, we are counting the length of the whole
	// body which includes all HTML tags and markup that is not necessarily
	// text. We will just add enough of a safety margin to account for this.
	let totalCharCount = 0;
	textComponents.placeholders['jss-layout-story-template'].forEach(component => {
		totalCharCount += component.fields.bodyText.value.length;
	});

	// Determine whether we should show the featured video in the sidebar.
	//
	// For short articles we will disable this, so the video can appear in the
	// article content instead. This way we will show the video together with the
	// text. It usually happens that the text just describes the video, so it
	// does not make sense to move the video to the sidebar and leave the
	// description in the middle.
	const showVideoInSidebar = totalCharCount > 400;

	const componentsToExcludeFromBody = [
		'StoryGrid',
		'TagsBlock',
	];

	const filteredRendering = _.cloneDeep(rendering);
	filteredRendering.placeholders['jss-layout-story-template'] = rendering?.
		placeholders['jss-layout-story-template'].filter(item => !componentsToExcludeFromBody.includes(item.componentName));

	/**
	 * In order to keep the aspect ratio of YouTube embeds, we will iterate
	 * through the text block component and check all iframe elements we can
	 * find.
	 *
	 * If the source attribute of iframe contains a YouTube URL, we will add an
	 * extra CSS class to the <center> parent element.
	 *
	 * The reason we perform so many checks is to limit this only to YouTube
	 * videos and avoid affecting Twitter, Instagram and embeds from other
	 * platforms.
	 *
	 * This does feel hacky though, so if anyone can think of a better and more
	 * comprehensive solution, feel free to rewrite it :)
	 */
	useEffect(() => {
		// YouTube videos.
		document
			.querySelectorAll('main div[class^="text-block"] iframe')
			.forEach(iframe => {
				const src = iframe.getAttribute('src');
				// Skip iframes that don't have the source or don't reference a YouTube
				// URL.
				//
				// We are on purpose checking only "youtu" in the source, since embeds
				// can point to "youtu.be" as well.
				if (src === null || !src.includes('youtu')) {
					return;
				}
				// We will affect only iframes that are direct children of "center"
				// elements. This seems to always be the case when editors are pasting
				// the YouTube embed code.
				if (iframe.parentElement.tagName === 'CENTER') {
					// Add an extra class to the parent.
					iframe.parentElement.classList.add('youtube-embed');
					iframe.classList.add('youtube-embed-iframe');
				}
			});
		// Brightcove videos.
		document
			.querySelectorAll('main div[class^="body-text"] iframe')
			.forEach(iframe => {
				const src = iframe.getAttribute('src');
				// Skip iframes that don't have the source or don't reference a
				// Brightcove URL.
				if (src === null || !src.includes('brightcove')) {
					return;
				}
				// Add an extra class to the parent.
				iframe.parentElement.classList.add('brightcove-embed');
				iframe.classList.add('brightcove-embed-iframe');
			});
	}, []);

	// Extract tags and remove the tags which represent players for which we have
	// dedicated pages.
	const tagsRendering = _.cloneDeep(rendering);
	tagsRendering.placeholders['jss-layout-story-template'] = rendering?.
		placeholders['jss-layout-story-template']
		.filter(item => item.componentName === 'TagsBlock');
	let tags = tagsRendering?.placeholders?.['jss-layout-story-template'][0]?.tags;
	if (playerInfosWithUrl !== undefined) {
		const playerNames = playerInfosWithUrl.map(playerInfo => `${playerInfo.FirstName} ${playerInfo.LastName}`);
		tags = tags.filter(tag => !playerNames.includes(tag.tagName));
	}

	const initialSidebar = useRef(null);
	const stickySidebar = useRef(null);

	/**
	 * Sidebar transition effect.
	 */
	useEffect(() => {
		// How many pixels will we wait before showing the initial sidebar again.
		const headerHeight = 135;
		const initialSidebarLimit = initialSidebar.current ? (initialSidebar.current.offsetTop + initialSidebar.current.offsetHeight - headerHeight) : 0;
		const showElement = el => {
			el.classList.remove(styles.fadeIn, styles.fadeOut);
			el.classList.add(styles.fadeIn);
		};
		const hideElement = el => {
			el.classList.remove(styles.fadeIn, styles.fadeOut);
			el.classList.add(styles.fadeOut);
		};
		const showCorrectSidebar = () => {
			const scrollPosition = window.scrollY;
			if (scrollPosition >= initialSidebarLimit) {
				hideElement(initialSidebar.current);
				showElement(stickySidebar.current);
			} else {
				showElement(initialSidebar.current);
				hideElement(stickySidebar.current);
			}
		};
		window.addEventListener('scroll', showCorrectSidebar);
		showCorrectSidebar();

		return () => {
			window.removeEventListener('scroll', showCorrectSidebar);
		};
	});

	const [playersBlockOpen, setPlayersBlockOpen] = useState(false);
	const [tagsBlockOpen, setTagsBlockOpen] = useState(false);

	const RelatedStories = ({stories, topAlignment = false}) => {
		return (
			<div className={`${styles.relatedStoriesWrap} ${topAlignment ? styles.topAlignment : ''}`}>
				<SidebarBlockHeading text={`Related Stories`} />
				{stories.map((story, index) => (
					<LatestStoryThumbSmall key={story.Id} story={story} showImage={false} withSpacer={index < 2} />
				))}
			</div>
		);
	};

	const FeaturedVideo = ({ videos }) => (
		<div className={styles.featuredVideoWrap}>
			<SidebarBlockHeading text={`Featured`} />
			{videos.map((video, index) => (
				<BrightcoveVideo key={index} fields={video} />
			))}
		</div>
	);

	const TagLink = ({tag = {}, visible = true}) => (
		<Link to={tag.url} className={`${styles.tagLink} ${!visible ? styles.tagLinkHidden : ''}`}>
			<span>{tag.tagName}</span><Arrow />
		</Link>
	);

	return (
		<div className={`${styles.wrap} ${showVideoInSidebar ? styles.wrapWithVideoInSidebar : ''}`}>
			<div className={styles.top}>

				{/* Story content with all elements added through Sitecore. */}
				<main>
					<div className={styles.inner}>
						<Placeholder name="jss-layout-story-template" rendering={filteredRendering} />
						{/* Horizontal tags. */}
						{tags.length > 0 && (
							<div className={`${styles.tagsWrap} ${styles.tagsWrapHorizontal}`}>
								<h2>Go Deeper</h2>
								<div className={styles.tagsHorizontalInner}>
									{tags.map((tag, index) => (
										<Link to={tag.url} key={index} className={styles.tagLink}>
											<span>{tag.tagName}</span><Arrow />
										</Link>
									))}
								</div>
							</div>
						)}
					</div>
				</main>

				{/* Sidebar. */}
				<aside>
					{/* Initial sidebar. */}
					<div className={styles.visibleInitialSidebar} ref={initialSidebar}>
						<div id={`story--initial-ad`} className={styles.advertising} />
						{/* Related stories. */}
						{relatedArticles && relatedArticles.length > 0 && (
							<RelatedStories stories={relatedArticles} blockTitle={`Related Stories`} />
						)}
						{/* Players mentioned in the story. */}
						{playerInfosWithUrl && playerInfosWithUrl.length > 0 && (
							<div className={`${styles.playersWrap} ${styles.expandableSectionWrap}`}>
								<SidebarBlockHeading text={'In This Article'} />
								<div className={`${styles.playersInner} ${styles.expandableSectionInner}`}>
									{playerInfosWithUrl.map((player, index) => (
										<PlayerThumbnailStory key={player.PersonId} player={player} visible={playersBlockOpen || index < 3} />
									))}
									{playerInfosWithUrl.length > 3 && playersBlockOpen && (
										<button onClick={() => setPlayersBlockOpen(false)} className={styles.viewLessSvg}>
											Show Less <ArrowDown />
										</button>
									)}
									{playerInfosWithUrl.length > 3 && !playersBlockOpen && (
										<button onClick={() => setPlayersBlockOpen(true)} className={styles.viewMoreSvg}>
											Show More <ArrowDown />
										</button>
									)}
								</div>
							</div>
						)}
						{/* Story tags, expandable style. */}
						<div className={`${styles.tagsWrap} ${styles.expandableSectionWrap}`}>
							{tags.length > 0 && (
								<>
									<SidebarBlockHeading text={'Go Deeper'} />
									<div className={`${styles.tagsInner} ${styles.expandableSectionInner}`}>
										{tags.map((tag, index) => <TagLink tag={tag} key={index} visible={tagsBlockOpen || index < 3} />)}
										{tags.length > 3 && tagsBlockOpen && (
											<button onClick={() => setTagsBlockOpen(false)} className={styles.viewLessSvg}>
												Show Less <ArrowDown />
											</button>
										)}
										{tags.length > 3 && !tagsBlockOpen && (
											<button onClick={() => setTagsBlockOpen(true)} className={styles.viewMoreSvg}>
												Show More <ArrowDown />
											</button>
										)}
									</div>
								</>
							)}
						</div>
						{showVideoInSidebar && videos && videos.length > 0 && (
							<FeaturedVideo videos={videos} />
						)}
					</div>
					{/* Sticky sidebar. */}
					<div className={styles.visibleStickySidebar} ref={stickySidebar}>
						{relatedArticles && (
							<RelatedStories stories={relatedArticles} topAlignment={true} />
						)}
						<div id={`story--sticky-ad`} className={styles.advertising} />
					</div>
				</aside>

			</div>
			<div className={styles.bottom}>
				<div className={styles.bottomInner}>
					<LatestStories stories={listOfLatestStories} />
					{listOfLatestVideos && listOfLatestVideos.length > 0 && (
						<LatestVideos videos={listOfLatestVideos} />
					)}
				</div>
			</div>
		</div>
	);
};

export default compose(
	// other hocs goes here
	withErrorHandling()
)(StoryTemplate);