import React, { Component } from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import DropdownFilter from "~/shared-components/DropdownFilter";
import { Text } from "~/patches/components";
import styles from "./OrganizationsMembersAccordion.module.scss";
import OrganizationsMembersAccordionTab from "./OrganizationsMembersAccordionTab";

class OrganizationsMembersAccordion extends Component {

	constructor(props) {
		super(props);

		this.state = {
			activeFilter: ""
		};
	}

	render() {

		const { fields, rendering: { statesAndMembers } } = this.props;
		const { activeFilter } = this.state;

		return (
			<>
				{fields && (
					<div className={styles.OrganizationsMembersAccordion}>
						<Text tag="h2" field={fields.heading} />
						<Text tag="p" field={fields.description} />

						{statesAndMembers && statesAndMembers.length > 0 && (
							<>
								{statesAndMembers.length > 1 && (
									<DropdownFilter
										filters={statesAndMembers.map(member => member.name)}
										setFilter={filter => this.setState({ activeFilter: filter })}
										selectedFilter={activeFilter}
										defaultText="All States"
										title="state"
									/>
								)}

								{statesAndMembers
									.filter(state => !activeFilter || state.name === activeFilter)
									.map(state => (
										state?.organizationMembers?.map((member, index) => (
											<OrganizationsMembersAccordionTab
												key={index}
												label={{ value: member?.organizationName?.value ? member?.organizationName?.value : member?.name }} //send as object to mimic sitecore text component
												labelAddition={{value: `${member?.city?.value}${member?.city?.value && member?.stateShorthand?.value && ", "}${member?.stateShorthand?.value}`}} //send as object to mimic sitecore text component
												content={member}
												stateName={state.name}
											/>
										))))}
							</>
						)}
					</div>
				)}
			</>
		);
	}
}


export default compose(
	// other hocs goes here
	withErrorHandling()
)(OrganizationsMembersAccordion);