exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".MatchCountdown-module__matchCountdown--2i5kG {\n  display: grid;\n  grid-area: cd;\n  grid-auto-columns: 1fr;\n  grid-auto-flow: column; }\n\n.MatchCountdown-module__timeIncrement--a1_SA {\n  border-left: 1px solid rgba(255, 255, 255, 0.5);\n  text-align: center; }\n  .MatchCountdown-module__timeIncrement--a1_SA:first-child {\n    border: none; }\n\n.MatchCountdown-module__label--2BGWw {\n  color: #000000;\n  font-family: \"USSF90MinDisplay\", helvetica, arial, sans-serif;\n  font-size: 0.8rem;\n  font-style: normal;\n  font-weight: 500;\n  letter-spacing: 0.07em;\n  color: #FFFFFF;\n  margin-bottom: 1.6px;\n  text-transform: uppercase; }\n\n.MatchCountdown-module__value--1cdLy {\n  color: #000000;\n  font-family: \"USSF90MinDisplay\", helvetica, arial, sans-serif;\n  font-size: 2rem;\n  font-style: normal;\n  font-weight: 700;\n  letter-spacing: 0.05em;\n  color: #FFFFFF; }\n", ""]);

// exports
exports.locals = {
	"matchCountdown": "MatchCountdown-module__matchCountdown--2i5kG",
	"timeIncrement": "MatchCountdown-module__timeIncrement--a1_SA",
	"label": "MatchCountdown-module__label--2BGWw",
	"value": "MatchCountdown-module__value--1cdLy"
};