import React from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import PlayerMatchTable from "../PlayerMatchStats/PlayerMatchTable";
import PlayerYearTable from "../PlayerYearStats/PlayerYearTable";

// Endpoints available for filtering:
// /api/stats/playermatchstats/{optaPlayerId}/{numberOfResults}
// /api/stats/playermatchstats/{optaPlayerId}/{numberOfResults}/{year}/byYear

const PlayerCareerStatistics = ({ rendering: { yearStats, matchStats, /*playerId, playerStatsUrl, yearsAvailable*/} }) => (
	<>
		<PlayerMatchTable matchStats={matchStats} />
		<PlayerYearTable yearStats={yearStats} />
	</>
);

export default compose(
	// other hocs goes here
	withErrorHandling()
)(PlayerCareerStatistics);