import { useQuery } from '@tanstack/react-query';

export const useRelatedArticlesByTags = ({ tags }) => {
	const { data, isLoading, isError } = useQuery({
		queryKey: ["useRelatedArticlesByTags", { tags }],
		queryFn: async () => {
			const articlesByTags = await Promise.allSettled(tags.map(async tag => {
				return await fetch(`https://api.ussoccer.com/api/mobile/stories/${tag.tagName}?limit=5&offset=0`).then((r) => r.json()).catch((e) => console.error(e));
			}))
				.then(results => results
					.filter(result => result.status === 'fulfilled')
					.flatMap(result => result.value)
					.sort((a, b) => (new Date(b.PostedDate) - new Date(a.PostedDate)))
					.reduce((acc, currentArticle, _curIdx, _array) => {
						const { Url, Id } = currentArticle;

						const articlePath = Url.replace("https://www.ussoccer.com", "");
						const isCurrentArticle = articlePath === window.location.pathname;
						const isCurrentArticleInAcc = Boolean(acc.find(a => a.Id === Id));

						if (isCurrentArticle) return acc;
						if (isCurrentArticleInAcc) return acc;

						return [...acc, currentArticle];

					}, [])?.slice(0, 3)

				);
			const articleData = await Promise.allSettled(articlesByTags.map(async article => {
				return await fetch(`https://api.ussoccer.com/api/mobile/story/${article.Id}`)
					.then(r => r.json()).catch((e) => console.error(e));
			}))
				.then(results => results
					.filter(result => result.status === 'fulfilled')
					.flatMap(result => result.value));
			return articlesByTags.map(a => {
				const storyData = articleData.find(ad => ad.ItemId === a.Id);
				return {
					...a,
					StoryHeading: storyData?.Components?.find(c => c.Name === "StoryBanner")?.Fields?.StoryHeading
				};
			});
		}
	});

	return {
		relatedArticles: data,
		isLoading,
		isError
	};
}