/* eslint-disable no-restricted-globals */
import { isServer } from "~/utilities";
import LogRocket from "logrocket";

class GigyaUtil {
	constructor() {
		this.subscribers = {
			login: {},
			logout: {}
		};
		this.lastSubId = 0;

		if (!isServer && window.gigya) {
			window.gigya.accounts.addEventHandlers({
				onLogin: this.onLogin,
				onLogout: this.onLogout
			});
		}
	}

	getJWT(callback) {
		if (!isServer && window.gigya) {
			window.gigya.accounts.getJWT({
				callback,
				fields: "profile.email"
			});
		}
	}

	logout(callback) {
		if (!isServer && window.gigya) {
			window.gigya.accounts.logout({
				callback
			});
		}
	}

	mountScreenSet(screenSetId, startScreenId, divId, handlers) {
		if (!isServer && window.gigya) {
			window.gigya.accounts.showScreenSet({
				screenSet: screenSetId,
				startScreen: startScreenId,
				containerID: divId,
				...(handlers || {}) // https://developers.gigya.com/display/GD/accounts.showScreenSet+JS
			});
		}
	}

	refreshUserData = () => {
		if (!isServer && window.gigya) {
			window.gigya.accounts.getAccountInfo({
				include: "data, profile, userInfo",
				callback: event => (event.errorCode === 0)
					? this.onLogin(event)
					: this.onLogout()
			});
		}
	}

	onLogin = event => {
		this.emit('login', {
			UID: event.UID,
			UIDSignature: event.UIDSignature,
			signatureTimestamp: event.signatureTimestamp,
			userInfo: event.userInfo,
			data: createMemberData(event.data),
			profile: createProfileData(event.profile)
		});

		LogRocket.identify(event.UID, {
			name: event.profile.firstName.concat(" ", event.profile.lastName),
			email: event.profile.email,
		});
	}

	onLogout = () => {
		this.emit('logout', getLoggedOutState());
	}

	emit(eventName, payload) {
		const subscribers = this.subscribers[eventName] || {};
		for (const subId of Object.keys(subscribers)) {
			const callback = subscribers[subId];
			callback(payload);
		}
	}

	subscribe(eventName, callback) {
		this.lastSubId++;
		this.subscribers[eventName][this.lastSubId] = callback;
		if (eventName === "login") setTimeout(() => this.refreshUserData(), 0); 
		return this.lastSubId;
	}

	unsubscribe(eventName, subId) {
		if (this.subscribers[eventName] && this.subscribers[eventName][subId])
			delete this.subscribers[eventName][subId];
	}
}

function getLoggedOutState() {
	return {
		UID: null,
		UIDSignature: null,
		signatureTimestamp: null,
		userInfo: {},
		data: {},
		profile: {}
	};
}

function createMemberData(data) {
	return {
		caps: data?.caps?.length || 0,
		membershipActive: data?.membership?.active,
		// eslint-disable-next-line camelcase
		membershipBegin: data?.member_since || data?.membership?.begin,
		membershipCanceled: data?.membership?.canceled,
		membershipSubscription: {
			begin: data?.membership?.subscriptions?.begin,
			end: data?.membership?.subscriptions?.end,
			id: data?.membership?.subscriptions?.id
		},
		membershipTier: parseInt(data?.membership?.tier) || 0
	};
}

function createProfileData(profile) {
	return {
		address: profile?.address,
		email: profile?.email,
		firstName: profile?.firstName,
		lastName: profile?.lastName
	};
}


const gigyaUtilSingleton = new GigyaUtil(); // singleton for shared pub/sub registry
export default gigyaUtilSingleton;