exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".CompetitionStandings-module__Standings--3mjLN {\n  margin: 0 auto 5rem;\n  max-width: 100%;\n  overflow: hidden; }\n  @media (min-width: 1440px) {\n    .CompetitionStandings-module__Standings--3mjLN {\n      width: 1440px; } }\n", ""]);

// exports
exports.locals = {
	"Standings": "CompetitionStandings-module__Standings--3mjLN"
};