import {isServer} from "~/utilities/index";
import {isMobile} from "react-device-detect";

const MIN_DESKTOP_WIDTH = 1440;

export const isInViewport = elem => {
	const bounding = elem.getBoundingClientRect();

	return (
		bounding.top >= 0 - bounding.height &&
      bounding.bottom <= (window.innerHeight  || document.documentElement.clientHeight) + bounding.height // eslint-disable-line no-restricted-globals
	);
};

export const debounce = (fn, time) => {
	let timeout;

	return function(args) {
		const functionCall = () => fn.apply(this, args);
		clearTimeout(timeout);
		timeout = setTimeout(functionCall, time);
	};
};

export const isRedesign = (() => {
		return false //process.env.REACT_APP_REDESIGN && process.env.REACT_APP_REDESIGN.toUpperCase() === 'Y'
})(); 

export const getEnv = () => {
	const isDev = window.location.href.indexOf('http://dev') > -1 || window.location.href.indexOf('http://local') > -1;
	const isQA = window.location.href.indexOf('https://qa') > -1;
	const isProdStg = window.location.href.indexOf('prod-ussf-pdx-cd-staging') > -1;
	const isAuthor = window.location.href.indexOf('https://author') > -1;

	if (isQA) {
		return 'qa';
	}

	if (!isDev && !isQA && !isProdStg && !isAuthor) {
		return 'prod';
	}

	return null;
};

export const getPlatform = () => {
	if (isMobile) return "mobile";
	if (isServer) return "server"; // Needed to make sure window is defined
	return window.innerWidth >= MIN_DESKTOP_WIDTH
		? "desktop"
		: "tablet";
}

export const throttled = (fn, delay) => {
	let lastCall = 0;

	return function (...args) {
		const now = (new Date).getTime();

		if (now - lastCall < delay) {
			return;
		}

		lastCall = now;
		return fn(...args);
	};
};

export const truncateWithEllipses = (text, charLimit) => (
	`${text.substr(0, text.lastIndexOf(" ", charLimit))} ...`
);

// for table components only
export const getTableColumnWidth = (data, accessor, headerText, itemWidth) => {
	if (typeof accessor === 'string' || accessor instanceof String) {
		accessor = d => d[accessor]; // eslint-disable-line no-param-reassign
	}
	const maxWidth = 600;
	const cellLength = Math.max(
		...data.map(row => (`${accessor(row)}` || '').length),
		headerText.length,
	);

	return Math.min(maxWidth, cellLength * itemWidth);
};

export function whichTransitionEndEvent(element) {
	const transitions = {
		'transition':'transitionend',
		'OTransition':'oTransitionEnd',
		'MozTransition':'transitionend',
		'WebkitTransition':'webkitTransitionEnd'
	};

	for (const t in transitions) {
		if (element.style[t] !== undefined) {
			return transitions[t];
		}
	}
}

export const membershipName = int => {
	const tiers = {
		tier0: "Standard Insider",
		tier1: "Premium Insider",
		tier2: "Premium Family",
		tier3: "VIP Insider",
		tier4: "Circle Insider",
		tier5: "Supporter Circle",
		tier6: "Captain Circle",
		tier7: "Coach Circle",
		tier8: "President Circle"
	};
	return tiers[`tier${int}`];
};

/**
 * Get jss values for images for gallery thumbnail
 *
 * @param {array} items
 */
export const getGalleryThumbnailImages = items => {
	const images = [];

	if (!items) return images;

	for (let index = 0; index < items.length; index += 1) {
		const item = items[index];

		images.push(item.image);
	}

	return images;
};