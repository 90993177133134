exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".MembershipPerks-module__membershipPerksContainer--DIFIw {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: center;\n      justify-content: center; }\n  .MembershipPerks-module__membershipPerksContainer--DIFIw .MembershipPerks-module__membershipPerks--2ILaA {\n    color: #4d4d4d;\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-direction: column;\n        flex-direction: column;\n    height: 100%;\n    -ms-flex-pack: center;\n        justify-content: center;\n    line-height: 1.5;\n    padding: 2rem; }\n    .MembershipPerks-module__membershipPerksContainer--DIFIw .MembershipPerks-module__membershipPerks--2ILaA .MembershipPerks-module__perksIntro--1Ez_8 {\n      color: #4d4d4d;\n      font-family: USSF90MinDisplayBold, helvetica, arial, sans-serif;\n      font-size: 2rem;\n      font-weight: 700;\n      padding-bottom: 3rem;\n      text-transform: uppercase; }\n    .MembershipPerks-module__membershipPerksContainer--DIFIw .MembershipPerks-module__membershipPerks--2ILaA .MembershipPerks-module__description--3q0WM {\n      font-family: USSF90MinTextMedium, helvetica, arial, sans-serif;\n      font-size: 1.33rem;\n      padding-bottom: 3rem; }\n      .MembershipPerks-module__membershipPerksContainer--DIFIw .MembershipPerks-module__membershipPerks--2ILaA .MembershipPerks-module__description--3q0WM .MembershipPerks-module__paragraphBody--3yD6I {\n        margin-bottom: 1.33rem; }\n    .MembershipPerks-module__membershipPerksContainer--DIFIw .MembershipPerks-module__membershipPerks--2ILaA .MembershipPerks-module__disclaimer--33HuV {\n      font-size: 1rem; }\n", ""]);

// exports
exports.locals = {
	"membershipPerksContainer": "MembershipPerks-module__membershipPerksContainer--DIFIw",
	"membershipPerks": "MembershipPerks-module__membershipPerks--2ILaA",
	"perksIntro": "MembershipPerks-module__perksIntro--1Ez_8",
	"description": "MembershipPerks-module__description--3q0WM",
	"paragraphBody": "MembershipPerks-module__paragraphBody--3yD6I",
	"disclaimer": "MembershipPerks-module__disclaimer--33HuV"
};