exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".AllStories-module__container--3nTlP {\n  margin: 0 auto;\n  margin-bottom: 5rem;\n  width: 100%; }\n  .AllStories-module__container--3nTlP h2:not(:empty) {\n    font-size: 2rem;\n    font-weight: 700;\n    margin: 0 0 2rem 0;\n    text-transform: uppercase; }\n  .AllStories-module__container--3nTlP h4 {\n    font-size: 1.4rem; }\n  .AllStories-module__container--3nTlP .AllStories-module__row--3Apwg {\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-direction: row;\n        flex-direction: row;\n    -ms-flex-wrap: wrap;\n        flex-wrap: wrap;\n    margin-bottom: 4.333rem;\n    width: 100%; }\n    .AllStories-module__container--3nTlP .AllStories-module__row--3Apwg.AllStories-module__noMargin--2V1xE {\n      margin: 0; }\n  .AllStories-module__container--3nTlP .AllStories-module__col--1RKu-, .AllStories-module__container--3nTlP .AllStories-module__colFullWidth--2Pt2u {\n    -ms-flex: 0 0 auto;\n        flex: 0 0 auto;\n    margin-bottom: 2rem;\n    padding: 0 1rem;\n    width: 100%; }\n  @media (min-width: 480px) {\n    .AllStories-module__container--3nTlP .AllStories-module__col--1RKu- {\n      width: 50%; } }\n  @media (min-width: 1025px) {\n    .AllStories-module__container--3nTlP .AllStories-module__col--1RKu- {\n      width: 33.333%; } }\n", ""]);

// exports
exports.locals = {
	"container": "AllStories-module__container--3nTlP",
	"row": "AllStories-module__row--3Apwg",
	"noMargin": "AllStories-module__noMargin--2V1xE",
	"col": "AllStories-module__col--1RKu-",
	"colFullWidth": "AllStories-module__colFullWidth--2Pt2u"
};