exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".MatchModule-module__sectionLabelContainer--3dFt5 {\n  margin: 0 0 4rem;\n  padding: 0 2rem; }\n\n@media (min-width: 768px) {\n  .MatchModule-module__sectionLabelContainer--3dFt5 {\n    margin: 0 auto 4rem; } }\n\n@media (min-width: 992px) {\n  .MatchModule-module__sectionLabelContainer--3dFt5 {\n    margin: 0 auto 4rem;\n    max-width: 97.2rem;\n    padding: 0 1rem; } }\n\n@media (min-width: 1025px) {\n  .MatchModule-module__sectionLabelContainer--3dFt5 {\n    max-width: 100%;\n    padding: 0 9.167rem; } }\n\n@media (min-width: 1440px) {\n  .MatchModule-module__sectionLabelContainer--3dFt5 {\n    max-width: 136.67rem; } }\n", ""]);

// exports
exports.locals = {
	"sectionLabelContainer": "MatchModule-module__sectionLabelContainer--3dFt5"
};