import React from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { Text } from "~/patches/components";
import { compose } from "recompose";
import { withErrorHandling } from "~/hoc";
import styles from "./QABlock.module.scss";

const QABlock = ({ rendering: { qaBlock } }) => (
	<>
		{qaBlock && typeof qaBlock.title !== "undefined" && typeof qaBlock.questions !== "undefined" ? (
			<div className={styles.QABlock}>
				<div className={styles.container}>
					<div className={styles.qaTitle}>
						<Text field={qaBlock.title} />
					</div>
					<div className={styles.qaColumn}>
						{qaBlock.questions.map((qaElement, index) => (
							<div key={index}>
								<div className={styles.question}>
									<Text field={qaElement.question}/>
								</div>
								<div className={styles.answer}>
									<Text field={qaElement.answer}/>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		) : console.warn("QA Block was not rendered correct.")} {/* eslint-disable-line */}
	</>
);

export default compose(
	withSitecoreContext(),
	withErrorHandling()
)(QABlock);