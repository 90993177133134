import { mediaManager } from "~/utilities";
import PropTypes from "prop-types";
import React from "react";
import ReactDOMServer from "react-dom/server";
import { convertAttributesToReactProps } from "../../utils";

/*
 * NOTE: This is copied from https://github.com/Sitecore/jss/blob/dev/packages/sitecore-jss-react/src/components/Image.tsx (v11.0.2)
 * This is only copied to use our own 'mediaManager' instead of their 'mediaApi'
 * The reason for that, is to preserve the revision number in querystring, when setting 'srcSet' and 'imageParams'.
 */

const getEditableWrapper = (editableMarkup, ...otherProps) => (
	// create an inline wrapper and use dangerouslySetInnerHTML.
	// if we try to parse the EE value, the parser will strip invalid or disallowed attributes from html elements - and EE uses several
	<span
		className="sc-image-wrapper"
		{...otherProps}
		dangerouslySetInnerHTML={{ __html: editableMarkup }}
	/>
);

const getImageAttrs = (
	{
		src,
		srcSet,
		...otherAttrs
	},
	imageParams
) => {
	if (!src) {
		return null;
	}

	const newAttrs = {
		...otherAttrs,
	};

	// update image URL for jss handler and image rendering params
	const resolvedSrc = mediaManager.updateImageUrl(src, imageParams);
	if (srcSet) {
		// replace with HTML-formatted srcset, including updated image URLs
		newAttrs.srcSet = mediaManager.getSrcSet(resolvedSrc, srcSet, imageParams);
	} else {
		newAttrs.src = resolvedSrc;
	}
	return newAttrs;
};

export const Image = ({
	media,
	editable,
	imageParams,
	field,
	...otherProps
}) => {
	// allows the mistake of using 'field' prop instead of 'media' (consistent with other helpers)
	if (field && !media) {
		media = field; // eslint-disable-line no-param-reassign
	}

	const dynamicMedia = media;

	if (!media || (!dynamicMedia.editable && !dynamicMedia.value && !dynamicMedia.src)) {
		return null;
	}

	// we likely have an experience editor value, should be a string
	if (editable && dynamicMedia.editable) {
		const foundImg = mediaManager.findEditorImageTag(dynamicMedia.editable);
		if (!foundImg) {
			return getEditableWrapper(dynamicMedia.editable);
		}

		const foundImgProps = convertAttributesToReactProps(foundImg.attrs);
		// Note: otherProps may override values from foundImgProps, e.g. `style`, `className` prop
		// We do not attempt to merge.
		const imgAttrs = getImageAttrs({ ...foundImgProps, ...otherProps }, imageParams);
		if (!imgAttrs) {
			return getEditableWrapper(dynamicMedia.editable);
		}

		const imgHtml = ReactDOMServer.renderToStaticMarkup(<img {...imgAttrs} />); //eslint-disable-line
		const editableMarkup = dynamicMedia.editable.replace(foundImg.imgTag, imgHtml);
		return getEditableWrapper(editableMarkup);
	}

	// some wise-guy/gal is passing in a 'raw' image object value
	const img = dynamicMedia.src ? media : dynamicMedia.value;
	if (!img) {
		return null;
	}

	const attrs = getImageAttrs({ ...img, ...otherProps }, imageParams);
	if (attrs) {
		return <img {...attrs} />; //eslint-disable-line
	}

	return null; // we can't handle the truth
};

Image.propTypes = {
	media: PropTypes.oneOfType([
		PropTypes.shape({
			src: PropTypes.string,
		}),
		PropTypes.shape({
			value: PropTypes.object,
			editable: PropTypes.string,
		}),
	]),
	editable: PropTypes.bool,
	imageParams: PropTypes.object,
};

Image.defaultProps = {
	editable: true,
};

Image.displayName = "Image";