import React, { Fragment } from "react";
import { Placeholder, withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { withSectionHeader, withErrorHandling } from "~/hoc";
import { Carousel, StoryCard } from "~/shared-components";
import styles from "./StoryGrid.module.scss";
import { compose } from "recompose";

const options = {
	modifierClassName: 'small',
	imageSrcSet: [{mw: 460}, {mw: 768}],
	imageSizes: '(min-width: 768px) 460px, 768px'
};

const StoryGrid = ({ rendering, sitecoreContext: { pageEditing } }) => (
	<Fragment>
		<section className={styles.StoryGrid}>
			{pageEditing && (
				<Placeholder
					name="jss-story-grid-content"
					rendering={rendering}
					renderEach={(component, index) => {
						const newProps = Object.assign({index, options}, component.props);
						const newComponent = React.cloneElement(component, newProps);

						return (
							<div className={`col col--xs-6 col--md-4 ${styles.columns}`} key={index}>
								{newComponent}
							</div>
						);
					}}
				/>
			)}
			{!pageEditing && (
				<>
					<div className={`${styles.hideOnMobile} grid redesignGridContainer`}>
						<div className="row">
							<Placeholder
								name="jss-story-grid-content"
								rendering={rendering}
								render={components => (
									<>
										{components.map((component, index) => {
											const newProps = Object.assign({index, options}, component.props);
											const newComponent = React.cloneElement(component, newProps);

											return (
												<div className={`col col--xs-6 col--md-4 ${styles.columns}`} key={`story-grid-content-${index}`}>
													{newComponent}
												</div>
											);
										})}
										{rendering?.stories && (
											<DefaultGridItems stories={rendering.stories} />
										)}
									</>
								)}
							/>
						</div>
					</div>
					<div className={styles.carousel}>
						<Placeholder
							name="jss-story-grid-content"
							rendering={rendering}
							render={components => (
								<Carousel>
									{components.map((component, index) => {
										const newProps = Object.assign({index, options}, component.props);
										const newComponent = React.cloneElement(component, newProps);
										return (
											<div className={styles.item} key={index}>{newComponent}</div>
										);
									})}
									{rendering?.stories && (
										<DefaultCarouselItems stories={rendering.stories} />
									)}
								</Carousel>
							)}/>
					</div>
				</>
			)}
		</section>
	</Fragment>
);

const DefaultCarouselItems = ({stories}) => (
	stories.map((story, index) => (
		<div className={styles.item} key={index}>
			<DefaultStoryCard {...story} />
		</div>
	))
);

const DefaultGridItems = ({stories}) => (
	stories.map((story, index) => (
		<div className={`col col--xs-6 col--md-4 ${styles.columns}`} key={`default-grid-items-${index}`}>
			<DefaultStoryCard {...story} />
		</div>
	))
);

const DefaultStoryCard = story => (
	<StoryCard {...{
		options: {
			modifierClassName: options.modifierClassName
		},
		fields: {
			heading: story.heading,
			image: story.image,
			date: story.date,
			categoryValue: story.category?.value,
			categoryKey: story.categoryKey,
			categoryUrl: story.categoryUrl,
			imageText: story.imageText,
			link: { value: { href: story.link } }
		}
	}} />
);

export default compose(
	withSitecoreContext(),
	withSectionHeader(),
	withErrorHandling()
)(StoryGrid);