exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PageNavigation-module__container--tEuYH {\n  border-bottom: solid 1px #E6E6E6;\n  position: relative; }\n  .PageNavigation-module__container--tEuYH:after {\n    background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(#fff));\n    background: -o-linear-gradient(left, rgba(255, 255, 255, 0), #fff);\n    background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff);\n    content: \"\";\n    height: 100%;\n    position: absolute;\n    right: 0;\n    top: 0;\n    width: 6rem; }\n  .PageNavigation-module__container--tEuYH ul {\n    display: -ms-flexbox;\n    display: flex;\n    overflow: scroll; }\n    .PageNavigation-module__container--tEuYH ul li {\n      display: inline-block;\n      -ms-flex: 0 0 auto;\n          flex: 0 0 auto; }\n  .PageNavigation-module__container--tEuYH a {\n    color: #808080;\n    cursor: pointer;\n    display: inline-block;\n    padding: 1.5rem 1.7rem;\n    text-transform: uppercase; }\n    .PageNavigation-module__container--tEuYH a:hover, .PageNavigation-module__container--tEuYH a.PageNavigation-module__active--QNpYh {\n      color: #C2002F; }\n  .PageNavigation-module__container--tEuYH .PageNavigation-module__inner--toYDJ {\n    margin: 0 auto;\n    padding: 0; }\n  @media (min-width: 1025px) {\n    .PageNavigation-module__container--tEuYH {\n      display: block; }\n      .PageNavigation-module__container--tEuYH ul {\n        overflow: auto; }\n      .PageNavigation-module__container--tEuYH a {\n        padding: 1.5rem 2.5rem; }\n      .PageNavigation-module__container--tEuYH .PageNavigation-module__inner--toYDJ {\n        padding: 0 20px 0 5rem; } }\n", ""]);

// exports
exports.locals = {
	"container": "PageNavigation-module__container--tEuYH",
	"active": "PageNavigation-module__active--QNpYh",
	"inner": "PageNavigation-module__inner--toYDJ"
};