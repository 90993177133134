// REDESIGN 2022 //

import React from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";

//Redesign Component Imports
import Button from "~/shared-components/Button";
import TextLink from "~/shared-components/TextLink";

const gridStyle = {
	col: {
		backgroundColor: 'pink',
		padding: 30,
		marginRight: 24,
		
	}
}

const StyleGuide = () => (
	
	<> 
	<div className="grid redesignGridContainer">
		<h2><u>USSF Redesign Style Guide</u></h2>
		<div>
			<h1>USSF Redesign Type for Desktop & Mobile</h1>

			<h1>Heading 1</h1>
			<h2>Heading 2</h2>
			<h3>Heading 3</h3>
			<h4>Heading 4</h4>
			<h5>Heading 5</h5>
			<h6>Heading 6</h6>
			<p>
			Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Turpis massa sed elementum tempus egestas sed sed risus pretium. Et magnis dis parturient montes nascetur. Ac tortor dignissim convallis aenean et. Condimentum vitae sapien pellentesque habitant morbi tristique senectus et. Ornare massa eget egestas purus viverra accumsan. Nibh ipsum consequat nisl vel. Sed faucibus turpis in eu mi. Auctor eu augue ut lectus arcu bibendum at varius. Facilisi etiam dignissim diam quis enim. Amet purus gravida quis blandit turpis cursus in. Suspendisse faucibus interdum posuere lorem ipsum. Non odio euismod lacinia at quis risus.
			</p>
		</div>

		<div className="row">
			<div className="col--md-2" style={gridStyle.col}>
				<p>1 of 4</p>
			</div>
			<div className="col--md-4" style={gridStyle.col}>
				<p>2 of 4</p>
			</div>
			<div className="col--md-2" style={gridStyle.col}>
				<p>3 of 4</p>
			</div>
			<div className="col--md-2" style={gridStyle.col}>
				<p>4 of 4</p>
			</div>
		</div>

		<div>
			<h2>Button Component</h2>
			<h4>Primary</h4>
			<Button text="Large" link="/" size="large" variant="primary"></Button><br /><br />
			<Button text="Small" link="/" size="small" variant="primary"></Button><br /><br />
			<Button text="Large" link="/" size="largeCondensed" variant="primary"></Button><br /><br />
			<Button text="Small" link="/" size="smallCondensed" variant="primary"></Button><br /><br />
			
			<h4>Secondary</h4>
			<Button text="Large" link="/" size="large" variant="secondary"></Button><br /><br />
			<Button text="Alternate Large" link="/" size="large" variant="secondaryAlternate"></Button><br /><br />
			<Button text="Small" link="/" size="small" variant="secondary"></Button><br /><br />
			<Button text="Alternate Small" link="/" size="small" variant="secondaryAlternate"></Button><br /><br />

			<div style={{color: 'white', background: 'black', padding: '3em'}}>
				<h4>Primary Reversed</h4>
				<Button text="Large" link="/" size="large" variant="primaryReversed"></Button><br /><br />
				<Button text="Small" link="/" size="small" variant="primaryReversed"></Button><br /><br />
				
				<h4>Secondary Reversed</h4>
				<Button text="Large" link="/" size="large" variant="secondaryReversed"></Button><br /><br />
				<Button text="Small" link="/" size="small" variant="secondaryReversed"></Button><br /><br />
			</div>
		</div>
		<div>
			<h2>Text Link Component</h2>
			<TextLink link="/" text="Small Bold Red" variant="smallBoldRed"></TextLink><br /><br />
			<TextLink link="/" text="Medium Regular Arrow Red" variant="mediumRegularArrowRed"></TextLink><br /><br />
			<TextLink link="/" text="Large Bold Blue" variant="largeBoldBlue"></TextLink><br /><br />
			<TextLink link="/" text="Medium Bold Blue" variant="mediumBoldBlue"></TextLink><br /><br />
			<TextLink link="/" text="Medium Regular Blue" variant="mediumRegularBlue"></TextLink><br /><br />

			<div style={{color: 'white', background: 'black', padding: '3em'}}>
				<TextLink link="/" text="Small Bold Reversed" variant="smallBoldReversed"></TextLink><br /><br />
				<TextLink link="/" text="Medium Regular Arrow Reversed" variant="mediumRegularArrowReversed"></TextLink><br /><br />
				<TextLink link="/" text="Large Bold Reversed" variant="largeBoldReversed"></TextLink><br /><br />
				<TextLink link="/" text="Medium Bold Reversed" variant="mediumBoldReversed"></TextLink><br /><br />
				<TextLink link="/" text="Medium Regular Reversed" variant="mediumRegularReversed"></TextLink><br /><br />
			</div>
		</div>
	</div>
	</>
);

export default compose(
	// other hocs goes here
	withErrorHandling()
)(StyleGuide);