import React from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { Text, Link, RichText } from "~/patches/components";
import { fieldHasValue } from "~/utilities";
import { compose } from "recompose";
import { withErrorHandling } from "~/hoc";
import styles from "./InfoBlock.module.scss";

const InfoBlock = ({ fields, sitecoreContext: { pageEditing } }) => (
	<>
		{fields &&
			<div className={styles.InfoBlock}>
				{pageEditing
					? <Text field={fields.heading} tag="h2" />
					: fieldHasValue(fields, "heading") && (
						<h2 dangerouslySetInnerHTML={{ __html: fields.heading.value.replace(/\.\s/g, '.<br />')}} />
					)
				}
				<RichText field={fields.description} />
				{fieldHasValue(fields, "infoLink", "link") && (
					<Link field={fields.infoLink} />
				)}
			</div>
		}
	</>
);

export default compose(
	withSitecoreContext(),
	withErrorHandling()
)(InfoBlock);