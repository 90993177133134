import React, { useState } from "react";
import { Text } from "~/patches/components";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import { StoryCard } from "~/shared-components";
import styles from "./AllStories.module.scss";
import { StoryService } from "~/services/storyService";
import { fieldObjHasValue } from "~/utilities";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import ShowMoreButton from "~/shared-components/ShowMoreButton";

/**
 * Placeholder for the amount of stories to be fetched per page
 */
const storiesPerPage = 12;

const optionsColumns = {
	modifierClassName: 'small'
};

const AllStories = props => {
	const {fields, rendering: { allStories, tagTitle, tagName }, sitecoreContext: { pageEditing }} = props;
	if (!allStories) return <></>;

	const [stories, setStories] = useState(allStories);
	const [pageNumber, setPageNumber] = useState(1);
	const [hideButton, setHideButton] = useState(allStories?.length < storiesPerPage);

	/**
	 * Handler to concat new fetched stories to stories state
	 */
	function handleStoriesChange() {
		const newPageNumber = pageNumber + 1;

		setPageNumber(newPageNumber);

		const promise = tagName
			? StoryService.getAllStoriesByTag(tagName, storiesPerPage, newPageNumber)
			: StoryService.getAllStories(storiesPerPage, newPageNumber);

		promise.then(response => {
			if (response?.stories) {
				setStories(stories.concat(response.stories));
				setHideButton(response.stories.length < storiesPerPage);
			}
		});
	}

	return (
		<>
			{stories && stories.length > 0 && (
				<div className={styles.container}>
					{(fieldObjHasValue(fields?.heading) || pageEditing) ? (
						<div className={`${styles.row} ${styles.noMargin}`}>
							<div className={styles.colFullWidth}>
								<Text tag="h2" field={fields.heading} />
							</div>
						</div>
					) : (
						tagTitle &&
							<div className={`${styles.row} ${styles.noMargin}`}>
								<div className={styles.colFullWidth}>
									<h2>{tagTitle}</h2>
								</div>
							</div>
					)}
					<div className={styles.row}>
						{stories.map((story, index) => (
							<div key={index} className={styles.col}>
								<StoryCardContainer {...story} />
							</div>
						))}
					</div>
					<ShowMoreButton showMoreHandler={() => handleStoriesChange()} {...{hideButton}} />
				</div>
			)}
		</>
	);
};

const StoryCardContainer = story => (
	<StoryCard {...{
		options: {
			modifierClassName: optionsColumns.modifierClassName
		},
		fields: {
			categoryValue: story.category?.value,
			categoryKey: story.categoryKey,
			categoryUrl: story.categoryUrl,
			date: story.date,
			heading: story.heading,
			image: story.image,
			imageText: story.imageText,
			link: { value: { href: story?.link }}
		}}
	} />
);

export default compose(
	// other hocs goes here
	withSitecoreContext(),
	withErrorHandling()
)(AllStories);