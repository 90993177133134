import React from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import styles from "./EditionStoryGrid.module.scss";

const options = {
	imageSrcSet: [{mw: 410}]
};

const EditionStoryGrid = ({ rendering }) => (
	<>
		<section className={styles.EditionStoryGrid}>
			<div className={styles.inner}>
				{/* TODO:  translate using t()  */}
				<h2>In This Edition Of The Corner</h2>
				<div className={styles.grid}>
					<Placeholder
						name="jss-edition-story-grid-content"
						rendering={rendering}
						renderEach={(component, index) => {

							const newComponent = React.cloneElement(component, {options, ...component.props});

							return (
								<div className={styles.storyItem} key={index}>
									{newComponent}
								</div>
							);
						}}/>
				</div>
			</div>
		</section>
	</>
);

export default compose(
	withErrorHandling()
)(EditionStoryGrid);