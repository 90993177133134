/* eslint-disable no-restricted-globals */
import React, { Component, createContext } from "react";
import { compose } from "recompose";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";

export const NavContext = createContext();

class NavProvider extends Component {
	state = {
		isHovering: false,
		wasHovering: false,
		activeKey: undefined,
		previouslyActiveKey: undefined,
		isMouseLeaving: false,
		menuItemClicked: false,
		subActiveKey: undefined
	};

	mouseLeavingTimeout = undefined;
	mouseLeavingDelay = this.props.sitecoreContext?.pageEditing ? 200 : 0;

	render() {
		return (
			<NavContext.Provider value={{
				state: this.state,
				isMouseEntering: (index) => {
					window.clearTimeout(this.mouseLeavingTimeout);
					this.setState({
						isHovering: true,
						wasHovering: false,
						isMouseLeaving: false,
						activeKey: index.primary,
						previouslyActiveKey: this.state.activeKey,
						subActiveKey: index.sub,
					});
				},			
				isMouseLeaving: () => {
					this.mouseLeavingTimeout = window.setTimeout(() => {
						this.setState({
							isHovering: false,
							wasHovering: true,
							isMouseLeaving: true,
							activeKey: undefined,
							previouslyActiveKey: this.state.activeKey,
							subActiveKey: undefined,
						});
					}, this.mouseLeavingDelay);
				},
				linkClicked: primaryNav => {
					this.setState({
						menuItemClicked: true
					}, () => setTimeout(() => {
						this.setState({
							menuItemClicked: false
						});
					}, primaryNav ? 1000 : 300));
				}
			}}>
				{this.props.children}
			</NavContext.Provider>
		);
	}
}

export default compose(
	withSitecoreContext()
)(NavProvider);