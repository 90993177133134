// REDESIGN 2022 //

import React from "react";
import { PropTypes } from "prop-types";
import { Text, Link } from "~/patches/components";
import { ArrowRight } from "~/shared-components/SvgIcons";
import styles from "./TextLink.module.scss";

const TextLink = ({ link, text, variant}) => {

	const linkStyles = [];
	linkStyles.push(
		variant === 'smallBoldRed' ? styles.smallBoldRed : 
		variant === 'mediumRegularBlue' ? styles.mediumRegularBlue : 
		variant === 'mediumBoldBlue' ? styles.mediumBoldBlue : 
		variant === 'largeBoldBlue' ? styles.largeBoldBlue : 
		variant === 'mediumRegularArrowRed' ? styles.mediumRegularArrowRed : 
		variant === 'largeRegularArrowRed' ? styles.largeRegularArrowRed : 
		variant === 'smallBoldReversed' ? styles.smallBoldReversed : 
		variant === 'mediumRegularReversed' ? styles.mediumRegularReversed : 
		variant === 'mediumBoldReversed' ? styles.mediumBoldReversed : 
		variant === 'largeBoldReversed' ? styles.largeBoldReversed : 
		variant === 'mediumRegularArrowReversed' ? styles.mediumRegularArrowReversed : '');
	
	return (
		<Link className={linkStyles.join(' ')} field={{value: {href: link}}}>
			<span>
				<Text field={{value: text}} />
				{(variant === 'mediumRegularArrowReversed' || variant === 'mediumRegularArrowRed' || variant === 'largeRegularArrowRed') ? 
					<ArrowRight /> : 
				""}
			</span>
		</Link>
	)
};

TextLink.propTypes = {
	link: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	variant: PropTypes.oneOf([
		'smallBoldRed',
		'mediumRegularBlue',
		'mediumBoldBlue',
		'largeBoldBlue',
		'mediumRegularArrowRed',
		'largeRegularArrowRed',
		'smallBoldReversed',
		'mediumRegularReversed', 
		'mediumBoldReversed', 
		'largeBoldReversed', 
		'mediumRegularArrowReversed']).isRequired
}

export default TextLink;