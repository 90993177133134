exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".CallToActionBlockWithIcon-module__CallToActionBlockWithIcon--lv0XC {\n  background: #F7F7F7;\n  border-radius: 3px;\n  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);\n          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);\n  display: -ms-flexbox;\n  display: flex;\n  margin: 0 auto 2rem;\n  max-width: 65rem;\n  padding: 3rem 2.4rem;\n  width: calc(100% - 4rem); }\n  .CallToActionBlockWithIcon-module__CallToActionBlockWithIcon--lv0XC .CallToActionBlockWithIcon-module__image--3ICE8 {\n    margin-right: 2.6rem; }\n  .CallToActionBlockWithIcon-module__CallToActionBlockWithIcon--lv0XC h4 {\n    font-size: 1.8rem;\n    font-weight: 500;\n    letter-spacing: 0.05em;\n    margin: 0 0 1.5rem;\n    text-transform: uppercase; }\n  .CallToActionBlockWithIcon-module__CallToActionBlockWithIcon--lv0XC p {\n    color: #4d4d4d;\n    font-size: 1.5rem;\n    margin-bottom: 1.5rem; }\n  .CallToActionBlockWithIcon-module__CallToActionBlockWithIcon--lv0XC a {\n    color: #C2002F;\n    font-size: 1.2rem;\n    letter-spacing: 0.05em;\n    text-transform: uppercase; }\n\n@media (min-width: 1025px) {\n  .CallToActionBlockWithIcon-module__CallToActionBlockWithIcon--lv0XC {\n    padding: 3rem; }\n    .CallToActionBlockWithIcon-module__CallToActionBlockWithIcon--lv0XC .CallToActionBlockWithIcon-module__image--3ICE8 {\n      margin: 0 7.5rem; }\n    .CallToActionBlockWithIcon-module__CallToActionBlockWithIcon--lv0XC p {\n      font-size: 1.25rem; }\n    .CallToActionBlockWithIcon-module__CallToActionBlockWithIcon--lv0XC a {\n      font-size: 1rem; } }\n", ""]);

// exports
exports.locals = {
	"CallToActionBlockWithIcon": "CallToActionBlockWithIcon-module__CallToActionBlockWithIcon--lv0XC",
	"image": "CallToActionBlockWithIcon-module__image--3ICE8"
};