exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".MembershipButtonGroup-module__membershipButtonGroup--3c0Bn {\n  margin: auto;\n  max-width: 68.333rem;\n  padding: 0 20px 20px 20px; }\n  @media (min-width: 1px) and (max-width: 767px) {\n    .MembershipButtonGroup-module__membershipButtonGroup--3c0Bn {\n      max-width: none;\n      width: 100%; } }\n  .MembershipButtonGroup-module__membershipButtonGroup--3c0Bn .MembershipButtonGroup-module__buttonPair--3zobh {\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-flow: column;\n        flex-flow: column;\n    max-width: 30em; }\n    @media (min-width: 1px) and (max-width: 767px) {\n      .MembershipButtonGroup-module__membershipButtonGroup--3c0Bn .MembershipButtonGroup-module__buttonPair--3zobh {\n        max-width: none;\n        width: 100%; } }\n  .MembershipButtonGroup-module__membershipButtonGroup--3c0Bn .MembershipButtonGroup-module__buttonShadow--j_NBm {\n    -webkit-box-shadow: 0.1rem 0.1rem 1rem rgba(230, 230, 230, 0.2);\n            box-shadow: 0.1rem 0.1rem 1rem rgba(230, 230, 230, 0.2);\n    padding-bottom: 1.3em; }\n  .MembershipButtonGroup-module__membershipButtonGroup--3c0Bn .MembershipButtonGroup-module__login--YGNEi {\n    color: #808080;\n    font-weight: 700;\n    margin: 1em 0;\n    max-width: 30em;\n    text-align: center; }\n    @media (min-width: 1px) and (max-width: 767px) {\n      .MembershipButtonGroup-module__membershipButtonGroup--3c0Bn .MembershipButtonGroup-module__login--YGNEi {\n        max-width: none;\n        width: 100%; } }\n    .MembershipButtonGroup-module__membershipButtonGroup--3c0Bn .MembershipButtonGroup-module__login--YGNEi .MembershipButtonGroup-module__link--H3K4G {\n      color: #C2002F;\n      margin: 0 0 0 0.5em;\n      text-transform: uppercase; }\n", ""]);

// exports
exports.locals = {
	"membershipButtonGroup": "MembershipButtonGroup-module__membershipButtonGroup--3c0Bn",
	"buttonPair": "MembershipButtonGroup-module__buttonPair--3zobh",
	"buttonShadow": "MembershipButtonGroup-module__buttonShadow--j_NBm",
	"login": "MembershipButtonGroup-module__login--YGNEi",
	"link": "MembershipButtonGroup-module__link--H3K4G"
};