import React, { useState, useRef, useEffect } from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import styles from "./Menu.module.scss";
import { Arrow } from "~/shared-components/SvgIcons";
import { isServer } from "~/utilities";

const Menu = props => {
	const {
		resultTypes,
		storiesCount,
		playersCount,
		galleriesCount,
		resultCount,
		activeResultText,
		handlerActiveResultTypeChange,
		handlerActiveResultTextChange,
		getTypeName
	} = props;

	const menuList = useRef(undefined);
	const [activeMenu, setActiveMenu] = useState(false);
	const [menuHeight, setMenuHeight] = useState(0);

	useEffect(() => {
		handleUrlHash();
	}, []);

	function toggleMenu() {

		setActiveMenu(!activeMenu);

		activeMenu
			? setMenuHeight(0)
			: setMenuHeight(menuList?.current?.clientHeight);

	}

	function handleUrlHash() {

		if (isServer) return;

		/* eslint-disable-next-line */
		const hash = window.location.hash;

		if (!hash) return;

		const type = hash.replace("#", "");

		if (!resultTypes.hasOwnProperty(type)) return;

		handlerActiveResultTypeChange(resultTypes[type]);
		handlerActiveResultTextChange(getTypeName(resultTypes[type]));
	}

	return (
		<aside className={`${styles.Menu} ${activeMenu ? styles.active : ""}`}>
			<header
				className={styles.header}>
				<button onClick={() => toggleMenu()}>
					{activeResultText}
					<Arrow />
				</button>
			</header>
			<div className={styles.inner} style={{height: `${menuHeight}px`}}>
				<ul ref={menuList}>
					<Button type={resultTypes.all} count={resultCount} toggleMenu={toggleMenu} {...props} />
					<Button type={resultTypes.players} count={playersCount} toggleMenu={toggleMenu} {...props} />
					<Button type={resultTypes.stories} count={storiesCount} toggleMenu={toggleMenu} {...props} />
					<Button type={resultTypes.galleries} count={galleriesCount} toggleMenu={toggleMenu} {...props} />
				</ul>
			</div>
		</aside>
	);
};

const Button = ({ type, count, handlerActiveResultTypeChange, activeResultType, getTypeName, toggleMenu, handlerActiveResultTextChange }) => {
	const text = `${getTypeName(type)} (${count || 0})`;

	return (
		<li className={activeResultType === type ? styles.active : ""}>
			<button onClick={() => {
				handlerActiveResultTypeChange(type);
				handlerActiveResultTextChange(getTypeName(type));
				toggleMenu();
			}}>
				{text}
			</button>
		</li>
	);
};

export default compose(
	// other hocs goes here
	withErrorHandling()
)(Menu);