import React from "react";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import styles from "./AdBlock.module.scss";

const AdBlock = () => (
	<div className={styles.adContainer}>
		<div id="div-gpt-ad-body" className={styles.adBlock}></div>
	</div>
);

export default compose(
	// other hocs goes here
	withErrorHandling()
)(AdBlock);