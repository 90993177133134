exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Modal-module__Modal--2sKDX {\n  background: transparent;\n  bottom: 0;\n  left: 0;\n  position: fixed;\n  right: 0;\n  top: 0;\n  z-index: 20; }\n\n.Modal-module__content--GD6cG {\n  background: #FFFFFF;\n  height: 100%;\n  overflow: auto;\n  padding: 2rem 2rem 5rem 2rem; }\n\n.Modal-module__billing--1wUiM .Modal-module__content--GD6cG {\n  padding: 0; }\n\n@media (min-width: 768px) {\n  .Modal-module__content--GD6cG {\n    background: rgba(0, 0, 0, 0.35); } }\n", ""]);

// exports
exports.locals = {
	"Modal": "Modal-module__Modal--2sKDX",
	"content": "Modal-module__content--GD6cG",
	"billing": "Modal-module__billing--1wUiM"
};