import React from "react";
import { Text } from "~/patches/components";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import styles from './VideoList.module.scss';
import BrightcoveVideo from "../BrightcoveVideo";

const VideoList = ({ fields }) =>
	(
		<>
		<div className={styles.container}>
			{ fields && <Text tag="h2" field={fields.heading} className={styles.heading}/> }
			{(fields &&
				fields.VideoItems?.map((video, i) => (
					<section key={i} className={styles.videoWrapper}>
						<div className={styles.videoText}>
							<h3>{video.fields?.title?.value}</h3>
							<p>{video.fields?.summary?.value}</p>
						</div>
						<div className={styles.videoThumbnail}>
							<BrightcoveVideo {...video}/>
						</div>
					</section>
				))
			)}
		</div>
		</>
	);


export default compose(
	// other hocs goes here
	withErrorHandling()
)(VideoList);