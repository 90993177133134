import React, { useState, Component } from "react";
import { Text } from "~/patches/components";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import styles from "./ScoreBarFeatured.module.scss";
import { MatchService } from "~/services/matchService";
import { optaApi, validDateTimeFormat } from "~/utilities/constants";
import { allFlags } from "~/utilities/flags";
import greenLight from "~/assets/img/GreenLight.png";
import instagram from "~/assets/img/Instagram.png";
import twitter from "~/assets/img/Twitter.png";

class ScoreBarFeatured extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isDataLoaded: false,
			matches: []
		};
	}

	componentDidMount = () => {
		if(this.props.fields.matchLink)
		{
			MatchService.getScoreboardByMatches(this.props.fields.matchLink)
				.then(response => {
					if (response && response.length > 0) {
						const matches = this.state.matches.slice();
						response.map(match => {
							matches.push(match);
						});
						this.setState({ matches: matches, isDataLoaded: true });
					}
				});
		}
		else
		{
				if (this.props.fields) {
				this.props.fields.teams.map(team => {
					MatchService.getScoreboardByContestants(team.fields.optaTeamId.value)
						.then(response => {
							if (response && response.length > 0) {
								const matches = this.state.matches.slice();
								response.filter(match => match.status === optaApi.matchStatus.live).map(match => {
									matches.push(match);
								});
								this.setState({ matches: matches, isDataLoaded: true });
							}
						});
				});
			}
		}
	}

	componentWillUnmount = () => {
	}

	render() {
		const { isDataLoaded, matches } = this.state;

		return (
			<>
				{isDataLoaded && matches && (
					<>
						{matches.map((match, index) =>
							<ul key={index} className={styles.featuredBarArea}>
								<li className={styles.featuredBarLeft}>
									<Broadcast broadcastLinks={match.broadcastLinks} status={match.status} />
								</li>
								<li className={styles.featuredBarMiddle}>
								</li>
								<li className={styles.featuredBarRight}>
									<MatchDisplay match={match} />
								</li>
							</ul>
						)};
					</>
				)};
			</>
		);
	}
}

const Broadcast = ({ broadcastLinks, status }) => {
	return (
		<>
			<div className={styles.broadcastArea}>
				<div className={styles.broadcastHeader}>Watch Live</div>
				<ul className={styles.broadcastLinks}>
					{broadcastLinks.filter(broadcasterLink => broadcasterLink.imageURL !== 'string').map((broadcasterLink, index) =>
						<li key={index} className={status === optaApi.matchStatus.upcoming ? styles.broadcastLinkBlack : styles.broadcastLink}>
							<a href={broadcasterLink.broadcastURLWeb} target="_blank" rel="noopener noreferrer">
								{broadcasterLink.imageURL
										? <img src={broadcasterLink.imageURL} alt={broadcasterLink.imageAltText} />
										: <span>{broadcasterLink.imageAltText}</span>
									}
							</a>
						</li>
					)}
				</ul>
			</div>
		</>
	);
};

const MatchDisplay = ({ match }) => {
	return (
		<>
			<ul className={styles.matchDisplayArea}>
				<li className={styles.matchDisplaySponsor}>
					{match.sponsor}
				</li>
				<li className={styles.matchDisplayTeams}>
					<MatchStatus match={match} />
				</li>
				<li className={styles.matchDisplayTime}>
					<LiveTime time={match.time} />
				</li>
			</ul>
		</>
	)
}

const MatchStatus = ({ match }) => {
	return (
		<>
			<ul className={styles.matchStatusArea}>
				<li>
					<TeamInfo team={match.contestants[0]} homeAway='home' />
				</li>
				<li className={styles.matchStatusDash}>-</li>
				<li>
					<TeamInfo team={match.contestants[1]} homeAway='away' />
				</li>
			</ul>
		</>
	)

}

const LiveTime = ({ time }) => {
	const t = time.substring(0, 8).split(':');
	t[1] = +t[1] + (60 * +t[0])
	return (
		<>
			<ul className={styles.featuredTimeArea}>
				<li className={styles.featuredTime}>
					<img className={styles.featuredTimeGreenLight} src={greenLight} />
					<span>LIVE&nbsp;-&nbsp;</span>
					{t[1] > 0 && (
						<span>{t[1]}&apos;&nbsp;</span>
					)}
					{t[2] > 0 && (
						<span>{t[2]}&quot;&nbsp;</span>
					)}
					<span className={styles.featuredTeamMatchHub}>MATCH HUB</span>
				</li>
			</ul>
		</>
	);
}

const TeamInfo = ({ team, homeAway }) => {
	return (
		<>
			<ul className={homeAway === 'home' ? styles.teamInfoHome : styles.teamInfoAway}>
				<li className={styles.teamInfoFlag}>{allFlags[team.code]}</li>
				<li className={styles.teamInfoScore}>{team.score}</li>
			</ul>
			<ul className={homeAway === 'home' ? styles.teamInfoHome : styles.teamInfoAway}>
				<li className={`${homeAway === 'home' ? styles.teamInfoNameHome : styles.teamInfoNameAway} ${styles.teamInfoName}`}>{team.name}</li>
			</ul>
		</>
	);
};

export default compose(
	withErrorHandling()
)(ScoreBarFeatured);