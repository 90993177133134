import React from "react";
import { Text, Link } from "~/patches/components";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import styles from "./RedesignAngleImage.module.scss";
import { useCallToActionBanner } from "~/hooks/useCallToActionBanner";
import { urlFor } from "~/utilities/sanityImage";
import Button from "~/shared-components/Button";

const blankBG = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

const RedesignAngleImage = ({ rendering }) => {
	const { data, isLoading, isError } = useCallToActionBanner({ sitecoreItemId: rendering.dataSource });

	if (isLoading) {
		return (
			<>
				<div className={styles.link}>
					<img  className={styles.image} src={"https://cdn.sanity.io/images/oyf3dba6/production/31f2802ce8f12a576180f5656393e5a6d3372e67-603x332.png"} />
					<div className={styles.overlay}></div>
				</div>
			</>
		);
	}

	if (data) {
		return (
			<a href={data.linkTo?.external ?? data.linkTo?.sitecore}>
				<div className={styles.link}>

					<img className={styles.image} src={urlFor(data.bannerImage).maxWidth(300).url()} />

					{ data.tabletBannerImage ? 
						<img className={styles.tabletImage} src={urlFor(data.tabletBannerImage).maxWidth(300).url()} />
						: <img className={styles.tabletImage} src={urlFor(data.bannerImage).maxWidth(300).url()} />
					}

					<div className={data.bannerType === 'angled' ? styles.angleVariantTextContainer : styles.textContainer}>
						<Text tag="div" className={data.bannerType === 'angled' ? styles.angleHeadline : styles.headline} field={{value:data.headline}} />
						<Text tag="div" className={data.bannerType === 'angled' ? styles.angleSubHeadline : styles.subHeadline} field={{value:data.subheadline}} />
						{data.linkTo?.title && <Button link={data.linkTo?.external ?? data.linkTo?.sitecore} size='large' text={data.linkTo?.title} variant='secondaryAlternate' />}
					</div>

					<div className={data.bannerType === 'angled' ? styles.angleOverlay : styles.overlay}></div>
				</div>
			</a>
		);
	}
	if (isError) {
		return (
			<>
				<div className={styles.link}>
					<img  className={styles.image} src={"https://cdn.sanity.io/images/oyf3dba6/production/31f2802ce8f12a576180f5656393e5a6d3372e67-603x332.png"} />
					<div className={styles.textContainer}>
						<Text tag="div" className={styles.headline} field={{value: "There was a problem"}} />
						<Text tag="div" className={styles.subHeadline} field={{value:"Try to refresh the page to load this component"}} />
					</div>
					<div className={styles.overlay}></div>
				</div>
			</>
		);
	}

	return null;
};

export default compose(
	// other hocs goes here
	withErrorHandling()
)(RedesignAngleImage);