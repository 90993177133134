import React, { Component } from "react";
import { compose } from "recompose";
import { withErrorHandling } from "~/hoc";
import MatchesGrid from "~/shared-components/MatchesGrid";
import { isServer } from "~/utilities";
import moment from "moment";
import SectionLabel from "~/shared-components/SectionLabel";
import MatchList from "~/shared-components/MatchList";
import styles from "./CompetitionMatchList.module.scss";
import CompetitionMatchTabs from "./CompetitionMatchTabs";

class CompetitionMatchList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isListView: true,
			isDesktop: false
		};
	}

	componentDidMount = () => {
		if (!isServer) {
			this.setState({
				isDesktop: window.innerWidth > 1440 //eslint-disable-line
			});
			window.addEventListener("resize", this.updateView); //eslint-disable-line
		}
	}

	componentWillUnmount = () => {
		if (!isServer) {
			window.removeEventListener("resize", this.updateView); //eslint-disable-line
		}
	}

	//sort matches in ascending order by date
	sortMatches = (matches, direction) => {
		const sortedMatches = matches.sort((a, b) => {
			const aDate = moment(a.date);
			const bDate = moment(b.date);

			if (direction === "ascending") {
				return aDate - bDate;
			}

			if (direction === "descending") {
				return bDate - aDate;
			}
		});

		return sortedMatches;
	}

	showList = boolean => {
		const { isListView } = this.state;

		if (boolean && !isListView) {
			this.setState({
				isListView: true
			});
		}

		if (!boolean && isListView) {
			this.setState({
				isListView: false
			});
		}
	}

	updateView = () => {
		this.setState({
			isDesktop: window.innerWidth > 1440 //eslint-disable-line
		});
	}

	render() {
		const { rendering: { matchResults } } = this.props;
		const { isListView, isDesktop } = this.state;
		const upcomingMatches = this.sortMatches(matchResults.filter(match => match.status === 1), "ascending");
		const completedMatches = this.sortMatches(matchResults.filter(match => match.status === 0), "descending");
		return (
			<>
				<section className={styles.competitionMatchList}>
					<div className={styles.sectionLabelContainer}>
						<SectionLabel sectionLabel={{ value: "matches" }} />
					</div>
					<CompetitionMatchTabs
						isListView={isListView}
						showList={this.showList}>
						<div label="upcoming matches">
							{isListView && isDesktop
								? <MatchList
									matches={upcomingMatches}
									type={"upcoming"}
								/>
								: <MatchesGrid
									matches={upcomingMatches}
									type={"upcoming"}
								/>
							}
						</div>
						<div label="results">
							{isListView && isDesktop
								? <MatchList
									matches={completedMatches}
									type={"results"}
								/>
								: <MatchesGrid
									matches={completedMatches}
									type={"results"}
								/>
							}
						</div>
					</CompetitionMatchTabs>
				</section>
			</>
		);
	}
}

export default compose(
	// other hocs goes here
	withErrorHandling()
)(CompetitionMatchList);