import React, { Component } from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { withErrorHandling } from "~/hoc";
import { USTeamNames } from "~/utilities/constants";
import { compose } from "recompose";
import MatchesGrid from "~/shared-components/MatchesGrid";
import MatchFilter from "~/shared-components/MatchFilter";
import { MatchService } from "~/services/matchService";
import moment from "moment";
import styles from "./MatchesResultsGrid.module.scss";
import HelperCommentEdit from "~/experience-editor/HelperCommentEdit";

// GET: /api/matches/results/{contestantId}/{year?} where year is optional

const defaultTeamName = "All";
class MatchesResultsGrid extends Component {
	constructor(props) {
		super(props);

		this.state = {
			filteredMatches: [],
			loading: false
		};
	}

	componentDidMount = () => {
		const { years } = this.props.rendering;
		const defaultYear = years[0];

		this.showAllMatchesByYear(defaultYear);
	}

	updatedFilteredMatches = filters => {
		const {team: {name, optaId}, year} = filters;

		if (optaId && year) {
			MatchService.getTeamResultsMatches(optaId, year)
				.then(response => {
					this.handleMatches(response.matches);
				});

		} else if (name === "All" && year) {
			this.showAllMatchesByYear(year);
		}
	}

	updatedFilters = filters => {
		this.updatedFilteredMatches(filters);
	}

	showAllMatchesByYear = year => {
		const allTeamIds = Object.keys(USTeamNames);
		this.setState({
			loading: true
		});
		Promise.all(allTeamIds.map(teamId => MatchService.getTeamResultsMatches(teamId, year)))
			.then(response => {
				const allMatches = [];

				response.map(teamMatches => teamMatches.matches.map(match => allMatches.push(match)));
				this.setState({
					loading: false
				});
				this.handleMatches(allMatches);
			});
	}

	sortMatches = matches => {
		const sortedMatches = matches.sort((a,b) => {
			const aDate = moment(a.date);
			const bDate = moment(b.date);
			return aDate - bDate;
		});

		return sortedMatches;
	}

	handleMatches = matches => {
		const sortedMatches = this.sortMatches(matches);

		this.setState({ filteredMatches: sortedMatches });
	}

	getDefaultTeam = () => {
		const { fields, rendering: { teams } } = this.props;
		const firstTeam = fields.teamLink?.fields?.optaTeamId?.value;
		let defaultTeam = firstTeam && teams ? teams.filter(t => t?.optaId === firstTeam)[0] : "";

		if (fields.teamLink.length > 1) {
			defaultTeam = { name: defaultTeamName };
		}

		return defaultTeam;
	}

	render() {
		const {
			rendering: { matches, teams, years },
			sitecoreContext: { pageEditing }
		} = this.props;
		const { filteredMatches, loading } = this.state;
		const allTeams = [];
		allTeams.push({ name: "All"});
		teams.map(team => allTeams.push(team));

		return (
			<section className={styles.container}>
				{pageEditing && !matches && (
					<HelperCommentEdit endingEditComment="and select a team to render the grid" />
				)}
				{matches &&
					<>
						<MatchFilter
							defaultTeam={{ name: "All"}}
							allMatches={matches}
							teams={allTeams}
							years={years}
							updatedFilters={this.updatedFilters}
						/>

						{!loading ?
							<MatchesGrid
								matches={filteredMatches}
								type={"results"}
								pageEditing={pageEditing}
							/>
							:
							<div className={styles.loading}>loading...</div>
						}
					</>
				}
			</section>
		);
	}
}

export default compose(
	// other hocs goes here
	withSitecoreContext(),
	withErrorHandling()
)(MatchesResultsGrid);