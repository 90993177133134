/* eslint-disable react/jsx-key */
import React from "react";
import CompetitionCardModule from "~/shared-components/CompetitionCardModule";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";

const CompetitionCard = ({ rendering: {competitionData} }) => (
	<>
		<CompetitionCardModule
			competitionData={competitionData}
		/>
	</>
);

export default compose(
	// other hocs goes here
	withErrorHandling()
)(CompetitionCard);
