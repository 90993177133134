import { useSanity } from '../useSanity';


export const useFooter = () => {
	const groqQuery = `
    *[_id == '8727df51-1528-459e-aad1-308b108080eb'][0]{
        socialLinks[]->
      }
      
	`;
	const queryKey = ['useFooter'];
	const {
		isError,
		loading,
		data
	} = useSanity({ groqQuery, queryKey });

	return {
		isError,
		loading,
		data
	}
}