import React from "react";
import { Placeholder, withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { withErrorHandling } from "~/hoc";
import { withNamespaces } from 'react-i18next';
import { compose } from "recompose";
import { NavLink } from "react-router-dom";
import { Image, Text } from "~/patches/components";
import { fieldHasValue } from "~/utilities";
import { TheCorner } from "~/shared-components/SvgIcons";
import styles from "./EditionTemplate.module.scss";

const EditionTemplate = ({ fields: dataSourceFields, sitecoreContext: { route: { fields: contextFields } }, rendering, t }) => {

	const fields = dataSourceFields || contextFields;

	return (
		<main>
			<div className={styles.container}>
				<div className={styles.editionMeta}>
					<TheCorner />
					<span className={styles.editionNo}>
						<Text field={fields.editionNo} />
					</span>
					<span className={styles.editionName}>
						<Text field={fields.editionName} />
					</span>
				</div>
				<div className={styles.stories}>
					{fields.coverStory && (
						<div className={styles.coverStory}>
							{fieldHasValue(fields, "coverImage", "image") &&
								<div className={styles.desktopImageContainer}>
									<Image
										field={fields.coverImage}
										srcSet={[{mw: 2560}, {mw: 768}]}
										sizes={"(min-width: 768px) 2560px, 768px"}/>
								</div>
							}
							{fieldHasValue(fields, "coverImageMobile", "image") &&
								<div className={styles.mobileImageContainer}>
									<Image
										field={fields.coverImageMobile}
										srcSet={[{mw: 768}, {mw: 440}]}
										sizes={"(min-width: 440px) 768px, 440px"}/>
								</div>
							}
							<div className={styles.text}>
								<div className={styles.hat}>
									Cover Story
								</div>
								<Text tag="h1" field={fields.coverStory.fields.storyHeading} />
								<Text tag="p" field={fields.coverStory.fields.storyPreview} />
								<NavLink to={fields.coverStory.url}>
									{t("p1_announcement_read_story")}
								</NavLink>
							</div>
						</div>
					)}
					<Placeholder name="jss-layout-edition-template" rendering={rendering} />
				</div>
			</div>
		</main>
	);
};

export default compose(
	// other hocs goes here
	withNamespaces(),
	withSitecoreContext(),
	withErrorHandling()
)(EditionTemplate);