import React from "react";
import { Image, Text } from "~/patches/components";
import { withErrorHandling } from "~/hoc";
import { compose } from "recompose";
import styles from "./IconCard.module.scss";
import PoweredByTicketmaster from "~/shared-components/PoweredByTicketmaster";

const IconCard = ({ fields }) => {

	//truncated for visual character limit
	fields.bodyText.value = `${fields.bodyText.value}`.substring(0,120);

	return (
		<div className={styles.iconCardsWrapper}>
			<div className={styles.iconCards}>
				<div className={styles.imageContainer}>
					<Image
						field={fields.iconImage}
					/>
				</div>
				<div className={styles.contentContainer}>
					<Text tag="h2" field={fields.heading}/>
					<Text tag="p" field={fields.bodyText} />
					{/* This is horrible, but currently the only (fast) way around it. */}
					{fields.heading.value.toLowerCase() === 'presale tickets' && (
						<div className={styles.ticketmasterWrap}>
							<PoweredByTicketmaster showPoweredByText showFullLogo />
						</div>
					)}
				</div>
			</div>
		</div>

	);
};

export default compose(
	// other hocs goes here
	withErrorHandling()
)(IconCard);