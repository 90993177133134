import React, { Fragment } from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { Text, Image } from "~/patches/components";
import SectionLabel from "~/shared-components/SectionLabel";
import { SocialLinksFooter } from "~/shared-components/SocialLinks";import HighlightStats from "~/shared-components/HighlightStats";
import JsonLd from "~/components/JsonLd";
import styles from "./P1Player.module.scss";
import { withNamespaces } from 'react-i18next';
import { fieldHasValue } from '~/utilities';
import { compose } from "recompose";
import moment from "moment";
import 'moment-timezone';
import { withErrorHandling } from "~/hoc";

const P1Player = ({ fields, t, sitecoreContext: { pageEditing, route: { fields: contextFields } } }) => {
	
	const dataObject = {
		"@context": "http://schema.org",
		"@type": "Person",
		"givenName": contextFields.firstName?.value,
		"familyName": contextFields.lastName?.value,
		"height": contextFields.height?.value,
		"birthDate": moment(contextFields.dateOfBirth?.value).utcOffset(contextFields.dateOfBirth?.value).format("YYYY-MM-DD"),
		"affiliation": {
			"@type": "SportsTeam",
			"name": contextFields.club?.value,
			"member": {
				"@type": "OrganizationRole",
				"roleName": contextFields.position?.name,
				"numberedPosition": contextFields.number?.value
			}
		}
	};

	return (
		<div className={styles.P1Player}>
			{contextFields && (
				<Fragment>
					<div className={styles.mediaColumn}>
						{fieldHasValue(fields, "playerVideo") && (
							<video width="720" height="844" autoPlay muted playsInline loop>
								<source src={fields.playerVideo.value.src} type="video/mp4" />
								Your browser does not support the video tag.
							</video>
						)}
						{((!fieldHasValue(fields, "playerVideo") && fieldHasValue(fields, "playerImage")) || pageEditing) && (
							<Image
								srcSet={[{ mw: 1440 }, { mw: 440 }]}
								sizes={"(min-width: 1440px) 720px, 440px"}
								field={fields.playerImage}
							/>
						)}
						<div className={styles.socialLinksMobileContainer}>
							<SocialLinksFooter />
						</div>
					</div>

					<div className={styles.textColumn}>
						<div className={styles.sectionLabelContainer}>
							<SectionLabel sectionLabel={{ value: "Profile" }} />
						</div>
						<h1><Text field={contextFields.firstName} /><br /><Text field={contextFields.lastName} /></h1>
						<div className={styles.socialLinksDesktopContainer}>
							<SocialLinksFooter />
						</div>
						<ul>
							{(contextFields.position?.fields?.positionName) &&
								<li>{t("player_label_position")} <span><Text field={contextFields.position.fields.positionName} /></span></li>
							}
							{(fieldHasValue(contextFields, "number") || pageEditing) && (
								<li>{t("player_label_number")} <span><Text field={contextFields.number} /></span></li>
							)}
							{(fieldHasValue(contextFields, "dateOfBirth") || pageEditing) && (
								<li>{t("player_label_date_of_birth")} <span>{moment(contextFields.dateOfBirth.value).utcOffset(contextFields.dateOfBirth.value).format("MMM DD YYYY")}</span></li> //eslint-disable-line
							)}
							{(fieldHasValue(contextFields, "homeTown") || pageEditing) && (
								<li>{t("player_label_hometown")} <span><Text field={contextFields.homeTown} /></span></li>
							)}
							{(fieldHasValue(contextFields, "height") || pageEditing) && (
								<li>{t("player_label_height")} <span><Text field={contextFields.height} /></span></li>
							)}
							{(fieldHasValue(contextFields, "club") || pageEditing) && (
								<li>{t("player_label_club")} <span><Text field={contextFields.club} /></span></li>
							)}
						</ul>
						<div className={styles.highlightStatsContainer}>
							<HighlightStats stats={contextFields.stats} />
						</div>
					</div>
				</Fragment>
			)}
			
			<JsonLd data={dataObject} />

		</div>
	);
};

export default compose(
	withNamespaces(),
	withSitecoreContext(),
	withErrorHandling()
)(P1Player);