exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SmartMembershipCard-module__cardWrapper--3ZVoC {\n  background-color: #FFFFFF;\n  border-radius: .5rem;\n  margin: auto;\n  max-width: 25em; }\n  @media (max-width: 768px) {\n    .SmartMembershipCard-module__cardWrapper--3ZVoC {\n      max-width: 100%; } }\n", ""]);

// exports
exports.locals = {
	"cardWrapper": "SmartMembershipCard-module__cardWrapper--3ZVoC"
};